/* eslint-disable react/jsx-key */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    BasicCard,
    BasicForm,
    BasicTab,
    DangerAlert,
    Empty,
    HorizontalMenu,
    Icon,
    IconName,
    IconTextButton,
    IMenuItem,
    IStatisticProps,
    notification,
    RowLayout,
    SelectFormItem,
    showConfirmDialog,
    showInformationDialog,
    Skeleton,
    Spinner,
    Statistic,
    TextFieldItem,
    Typography,
    useForm,
} from 'ui-lib';
import { Route } from 'core';
import deviceCss from './devices.css';
import { useAuthStore, useDeviceStore, usePackagesStore, useStores } from '../../../../store';
import { deviceActionConfirm } from '../../admin/utils';
import { DeviceSCRFileListingTable } from './scr-file-listing-table';
import { DeviceCryptoReportComponent } from './crypto-report';
import { DeviceLogsStore } from '../../../../store/device-details/device-logs-store';
import { DeviceLogsComponent } from './device-log-table';
import { observer } from 'mobx-react';
import { DeviceDetailEntryStore } from '../../../../store/device-details/device-detail-entry-store';
import { DevicePackagesTableComponent } from './device-package-table';
import { DevicePackagesStore } from '../../../../store/device-details/device-packages-store';
import { DeviceSCRFileListingStore } from '../../../../store/device-details/device-scr-file-listing-store';
import { DeviceCryptoReportStore } from '../../../../store/device-details/device-crypto-report-store';
import { DeviceDeploymentsTable } from './device-deployment-table';
import { DeviceJobsStore } from '../../../../store/device-details/device-jobs-store';
import { DeviceInfosStore } from '../../../../store/device-details/device-info-store';
import { DeviceJobTable } from './device-job-table';
import { DeviceDeploymentsStore } from '../../../../store/device-details/device-deployments-store';
import cx from 'classnames';

import {
    DEVICE_STATUS,
    DeviceAttributeName,
    IConnectionSummary,
    IDeviceInventoryStateOptions,
    SCR_TAMPER_STATUS
} from '../../../../dto/device-dto';
import { MESSAGE_TYPE, NOTIFICATION_TYPE } from '../../../../dto/notifications-dto';
import { IVarAccountProps, VarModalComponent } from '../../components/var-select-modal';
import { isNullOrEmpty, sleep, TIMEZONES } from 'common-utils';
import { KEY_ROUTE_CODES } from '../../keys';
import { hasPermission } from '../../utils';
import { inputAlphanumericRule, maxLengthRule } from '../../components/form-utils';
import { DEVICES_ROUTE_CODES } from '../';
import { isVARAdmin } from '../../../../store/role-store';
import { isOnlineDevice } from '../../../../common-utils/device-utils';
import { DeviceScrInfoComponent } from './scr-info';
import { isString } from 'lodash';
import { HorizontalCollapseLayout } from 'ui-lib/components/layout/horizontal-collapse-layout';
import { COMMISSION_STATUS } from '../commissioning-upload';
import { isCommissionable } from './utils';

export interface ISubCarProps {
    code: string;
    title: React.ReactNode | string;
    contents: React.ReactNode[] | string[];
    loading?: boolean;
    numOfRows?: number;
}

interface IEditableDetailComponent {
    code: string;
    attributeName: DeviceAttributeName;
    afterFinish?: (updated: boolean, value?: string) => void;
    title?: string;
    value?: string;
    comboboxType?: boolean;
    dataSource?: any[];
}

export interface IDeviceTableProps<T extends DeviceDetailEntryStore<any>> {
    store: T;
}

const SubCardComponent = (props: ISubCarProps) => {
    const { code: key, title, contents } = props;
    return (
        <div key={key} className={deviceCss['sub-card']}>
            <h3 style={{ width: 'max-content' }}>
                <span className={deviceCss['sub-card-title']}>{title}</span>
            </h3>
            <Skeleton loading={props.loading} noOfRows={props.numOfRows}>
                {contents?.map((content, index) => isString(content) ? (
                        <span key={`span-${key}-${index}`}>{content}</span>) :
                    <React.Fragment key={`span-${key}-${index}`}>{content}</React.Fragment>)}
            </Skeleton>
        </div>
    );
};

const StatisticItem = (props: IStatisticProps) => {
    return (
        <Statistic
            className={deviceCss['detail-statistic']}
            valueStyle={props.valueStyle}
            title={props.title}
            value={props.value || ' '}
            suffix={props.suffix || ' '}
            groupSeparator=""
            valueRender={props.valueRender}
        />
    );
};

interface IExecuteCommandProps {
    payload: { commandCode: string; targetDeviceNames: string[] };
    executeTitle: string;
    finishTitle: (status: string) => string;
}

interface ITextFieldValidation {
    status: 'error' | 'success';
    message?: string;
}

const CONNECTIVITY = 'connectivity';

interface IDeviceDetailsProps extends IVarAccountProps {
}

const DeviceDetails = observer((props: IDeviceDetailsProps) => {
    const { varOrClientAccountId: accountId } = props;

    const devicesStore = useDeviceStore();
    const packagesStore = usePackagesStore();
    const state: IDeviceInventoryStateOptions = useLocation().state || {};
    const uid = useParams()?.uid;
    const { currentUser } = useAuthStore();
    const navigate = useNavigate();
    const { socketClientStore, notificationsStore } = useStores();

    const stores = useStores();
    const { routeStore } = stores;
    const deviceInventoryRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.INVENTORY);
    const decommissioningRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.DECOMMISSIONING);
    const fileTransferRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.FILE_TRANSFER);
    const shellCommandRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.SHELL_COMMAND);
    const keyDistributionRoute = routeStore.getRoute(KEY_ROUTE_CODES.DISTRIBUTION);

    const [serialNumber, setSerialNumber] = React.useState<string>();

    const devicelogsStore = React.useMemo(
        () => new DeviceLogsStore({ accountId, uid, serialNumber, devicesStore }),
        [accountId, serialNumber],
    );

    const devicePackagesStore = React.useMemo(
        () => new DevicePackagesStore({ accountId, uid, serialNumber, devicesStore }, packagesStore),
        [accountId, serialNumber],
    );
    const deviceSCRFileListingStore = React.useMemo(
        () => new DeviceSCRFileListingStore({ accountId, uid, serialNumber, devicesStore }),
        [accountId, serialNumber],
    );
    const deviceCryptoReportStore = React.useMemo(
        () => new DeviceCryptoReportStore({ accountId, uid, serialNumber, devicesStore }),
        [accountId, serialNumber],
    );

    const deviceJobsStore = React.useMemo(
        () => new DeviceJobsStore({ accountId, uid, serialNumber, devicesStore }),
        [accountId, serialNumber],
    );
    const deploymentsStore = React.useMemo(
        () => new DeviceDeploymentsStore({ accountId, uid, serialNumber, devicesStore }),
        [accountId, serialNumber],
    );
    const deviceInfosStore = React.useMemo(
        () => new DeviceInfosStore({ accountId, uid, devicesStore }),
        [accountId],
    );
    const socketClient = socketClientStore.socketClient;

    const [loading, setLoading] = React.useState<boolean>(true);
    const [validDevice, setValidDevice] = React.useState<boolean>(false);
    const [timezonesDisplay, setTimezonesDisplay] = React.useState([]);

    const [form] = useForm();

    React.useEffect(() => {
        if (!accountId) return;

        (async () => {
            setLoading(true);
            try {
                await packagesStore.loadEntities(accountId);
                const device = await devicesStore.getDevicesByName(accountId, uid);
                setValidDevice(!!device);
                if (!device) return;
                setSerialNumber(device.serialNumber);
                // Calling async to do not block the UI
                deviceInfosStore.initializeData(device);
                setTimezonesDisplay(TIMEZONES);
            } finally {
                setLoading(false);
            }
        })();
    }, [accountId]);

    React.useEffect(() => {
        if (!socketClient) return;

        let mounted = true;

        socketClient.reconnect(() => mounted && socketClient.emitMessage(CONNECTIVITY, { uid, enable: true }));
        socketClient.emitMessage(CONNECTIVITY, { uid, enable: true });
        socketClient.listenOn<{ connectivity: IConnectionSummary }>(CONNECTIVITY, (response) => {
            if (deviceInfosStore.loading) return;
            deviceInfosStore.updateConnectivityStatus(response.connectivity);
        });

        return () => {
            mounted = false;
            socketClient.emitMessage(CONNECTIVITY, { uid, enable: false });
        }; // Call to server to remove timer.
    }, [socketClient]);

    // ------------------------------------------------ UTILS ------------------------------------------------------//

    const executeCommand = async (options: IExecuteCommandProps): Promise<void> => {
        notification['info']({
            message: options.executeTitle,
            description: 'Please wait...',
            placement: 'topRight',
        });

        await notificationsStore.create({
            content: {
                title: 'Remote command',
                text: options.executeTitle,
                messageType: MESSAGE_TYPE.REMOTE_COMMAND,
            },
            type: NOTIFICATION_TYPE.PERSONAL,
            receiverUserUuid: currentUser.uuid,
            sender: currentUser.userName,
        });

        const result = await devicesStore.triggerRemoteCommand(accountId, options.payload);
        await sleep(10000); // not ideal, temporary fix until we refactor I will redesign this
        const data = await devicesStore.getRemoteCommandStatusByExecutionId(accountId, result.executionId);
        await deviceJobsStore.fetchLatestData(true);
        notification['info']({
            message: options.finishTitle(data.status) + ` for device serial ${deviceInfosStore.serialNumber}`,
            description: 'Please check device jobs for more information...',
            placement: 'topRight',
        });

        await notificationsStore.create({
            content: {
                title: 'Remote command',
                text: options.finishTitle(data.status) + ` for device serial ${deviceInfosStore.serialNumber}`,
                messageType: MESSAGE_TYPE.REMOTE_COMMAND,
            },
            type: NOTIFICATION_TYPE.PERSONAL,
            receiverUserUuid: currentUser.uuid,
            sender: currentUser.userName,
        });
    };

    const reboot = async () => {
        const commandPayload = {
            commandCode: 'reboot',
            targetDeviceNames: [uid],
        };
        await executeCommand({
            executeTitle: `Restart is executing for device serial ${deviceInfosStore?.serialNumber}`,
            finishTitle: (status: string) => `Restart status: ${status}`,
            payload: commandPayload,
        });
        await notificationsStore.create({
            content: {
                title: 'Restart device',
                text: `Restart for serial number: ${deviceInfosStore?.serialNumber} and eSN: ${deviceInfosStore?.esn} has completed`,
                messageType: MESSAGE_TYPE.DEVICE,
            },
            type: NOTIFICATION_TYPE.PERSONAL,
            receiverUserUuid: currentUser.uuid,
            sender: currentUser.userName,
        });
    };

    const restartDevice = () => {
        deviceActionConfirm('restart', reboot, accountId);
    };

    const ping = async () => {
        const commandPayload = {
            commandCode: 'ping',
            targetDeviceNames: [uid],
        };
        await executeCommand({
            executeTitle: `Ping is executing for device serial ${deviceInfosStore?.serialNumber}`,
            finishTitle: (status: string) => `Ping status: ${status}`,
            payload: commandPayload,
        });
    };

    const pingDevice = () => {
        deviceActionConfirm('ping', ping, accountId);
    };

    // ------------------------------------------------ PAGE ELEMENTS ------------------------------------------------------//

    const buildEditableComponents = (props: IEditableDetailComponent) => {
        const [edit, setEdit] = React.useState<boolean>(false);
        const [attributeValue, setAttributeValue] = React.useState<string>(props.value);
        const [textFieldValidation, setTextFieldValidation] = React.useState<ITextFieldValidation>({
            status: 'success',
        });
        const textFieldCode = `${props.code}-value`;

        React.useEffect(() => {
            if (!edit) return;
            return () => {
                form.resetFields([textFieldCode]);
                setTextFieldValidation({ status: 'success' });
            };
        }, [edit]);

        const onlyLengthRule = maxLengthRule(32);
        const alphanumericRule = inputAlphanumericRule(undefined, ['UNDERSCORE']);
        const attributeRule = inputAlphanumericRule(800, ['UNDERSCORE']);

        const onOk = async () => {
            if (props.attributeName === 'timezone' && attributeValue == '') {
                setAttributeValue(props.value);
                setEdit(false);
                return showInformationDialog({
                    modalType: 'error',
                    title: 'Error',
                    content: 'Cannot set an empty timezone.',
                });
            }

            const result = await deviceInfosStore.editAttribute(props.attributeName, attributeValue);
            props.afterFinish && props.afterFinish(result.updated, attributeValue);
            if (result.updated) return setEdit(false);
            setTextFieldValidation({
                status: 'error',
                message: result.reason,
            });
        };

        const onCancel = () => {
            setAttributeValue(props.value);
            setEdit(false);
        };

        const onSaveAttribute = () => {
            if (!deviceInfosStore.hasValueChange(props.attributeName, attributeValue)) return setEdit(false);

            showConfirmDialog({
                title: 'Confirmation',
                content: 'Do you want to save your change?',
                okText: 'Save',
                onOk,
                onCancel,
            });
        };
        return {
            edit,
            EditButton: (
                <IconTextButton
                    key={`${props.code}-action`}
                    className={deviceCss.editBtn}
                    onClick={() => setEdit(!edit)}
                    iconName="edit"
                />
            ),
            Component: props.comboboxType ? (
                <div style={{ display: 'inline-block', width: '100%' }}>
                    <SelectFormItem
                        code={props.code}
                        dataSource={props.dataSource}
                        selectedValue={props.value}
                        showSearch={true}
                        onChange={(value) => setAttributeValue(value)}
                    />
                    <IconTextButton
                        className={deviceCss['attributeComponent']}
                        type="primary"
                        label="Ok"
                        onClick={onSaveAttribute}
                    />
                </div>
            ) : (
                <TextFieldItem
                    code={textFieldCode}
                    onPressEnter={() => {
                        if (
                            (props.attributeName === 'esn' || props.attributeName === 'serialNumber') &&
                            (!attributeValue || !onlyLengthRule.pattern.test(attributeValue) || !alphanumericRule.pattern.test(attributeValue))
                        ) {
                            return;
                        }
                        if (attributeValue !== '' && !attributeRule.pattern.test(attributeValue)) {
                            return;
                        }
                        onSaveAttribute();
                    }}
                    onChange={(values) => {
                        setTextFieldValidation({ status: 'success' });
                        setAttributeValue(values.target.value);
                    }}
                    value={attributeValue}
                    rule={
                        (props.attributeName === 'esn' || props.attributeName === 'serialNumber')
                            ? [alphanumericRule, onlyLengthRule]
                            : attributeRule
                    }
                    customizeValidate={{
                        status: textFieldValidation.status,
                        message: textFieldValidation.message,
                    }}
                    initialValue={props.value}
                    isRequired={(props.attributeName === 'esn' || props.attributeName === 'serialNumber') && !!props.value}
                />
            ),
        };
    };

    const DeviceBaseDetails = observer(() => {
        const StatisticEditableItem = observer((props: IEditableDetailComponent) => {
            const editableComponents = buildEditableComponents(props);
            return (
                <Statistic
                    className={cx(deviceCss['detail-statistic'], deviceCss['edit-detail-statistic'])}
                    title={
                        <div style={{ display: 'flex' }}>
                            <div className={deviceCss['edit-statistic-title']}>{props.title}</div>
                            {editableComponents.EditButton}
                        </div>
                    }
                    value={props.value}
                    formatter={(value: string) =>
                        editableComponents.edit ? editableComponents.Component : value || ' '
                    }
                />
            );
        });
        const buildSubMenuItems = (): IMenuItem[] => {
            const _createSubMenuItem = (subMenuItems: IMenuItem[], route: Route, onClick: () => void, label?: string): void => {
                if (!hasPermission(route.code, stores)) return;

                subMenuItems.push({
                    code: route.code,
                    label: () => label || route.label,
                    iconName: route.iconName,
                    onClick,
                });
            };

            const subMenuItems: IMenuItem[] = [
                {
                    code: 'checkHealth',
                    label: () => 'Check health',
                    iconName: 'circle-check',
                    onClick: pingDevice,
                },
                {
                    code: 'restartDevice',
                    label: () => 'Restart',
                    iconName: 'arrows-rotate',
                    onClick: restartDevice,
                },
            ];

            const _onAction = (path: string, setSelectedOrganisation = true) => {
                if (setSelectedOrganisation) {
                    stores.commonStore.setSelectedOrganisation(accountId);
                }
                navigate(path, { state: { uid: uid } });
            };

            _createSubMenuItem(subMenuItems, shellCommandRoute, () => _onAction(shellCommandRoute.path));
            _createSubMenuItem(subMenuItems, fileTransferRoute, () => _onAction(fileTransferRoute.path));
            _createSubMenuItem(subMenuItems, keyDistributionRoute, () => _onAction(keyDistributionRoute.path), 'Resend device keys');
            _createSubMenuItem(subMenuItems, decommissioningRoute, () => _onAction(decommissioningRoute.path));

            if (currentUser.roles.some((role) => isVARAdmin(role))) {
                subMenuItems.push({
                    code: 'distributeGPGKeys',
                    label: () => 'Resend GPG keys',
                    iconName: 'key' as IconName,
                    onClick: async () => {
                        try {
                            notification['info']({
                                message: 'Distribute GPG keys job actioned',
                                description: 'Please wait...',
                                placement: 'topRight',
                            });
                            const excutionId = await devicesStore.distributeGPGKeys(accountId, uid);
                            notification['info']({
                                message: 'Distribute GPG keys actions have been issued',
                                description: `Please refresh and check the Device Jobs table with id: ${excutionId}`,
                                placement: 'topRight',
                            });
                            await notificationsStore.create({
                                content: {
                                    title: 'GPG keys',
                                    text: 'Distribute GPG keys actions have been issued',
                                    messageType: MESSAGE_TYPE.GPG_KEYS,
                                },
                                type: NOTIFICATION_TYPE.PERSONAL,
                                receiverUserUuid: currentUser.uuid,
                                sender: currentUser.userName,
                            });
                        } catch (error) {
                            notification['error']({
                                message: 'Distribute GPG keys - error occurred.',
                                description: `${error.message}`,
                                placement: 'topRight',
                            });
                            await notificationsStore.create({
                                content: {
                                    title: 'GPG keys',
                                    text: 'Distribute GPG keys - error occurred.',
                                    messageType: MESSAGE_TYPE.GPG_KEYS,
                                },
                                type: NOTIFICATION_TYPE.PERSONAL,
                                receiverUserUuid: currentUser.uuid,
                                sender: currentUser.userName,
                            });
                        }
                    },
                });
            }

            return subMenuItems;
        };

        const subMenuItems: IMenuItem[] = buildSubMenuItems();

        const ActionsMenu = () => {
            return (
                <HorizontalMenu
                    className={deviceCss['actions-menu']}
                    menuItems={[
                        {
                            code: 'actionsMenu',
                            iconName: 'gear',
                            subMenuItems,
                        },
                    ]}
                />
            );
        };

        const items = [
            <StatisticEditableItem
                code="attribute-serialNumber"
                title="Serial Number"
                attributeName="serialNumber"
                value={deviceInfosStore.serialNumber}
            />,
            <StatisticEditableItem
                code="attribute-esn"
                title="eSN"
                attributeName="esn"
                value={deviceInfosStore.esn}
            />,
            <StatisticEditableItem
                code="attribute-name"
                title="Name"
                attributeName="name"
                value={deviceInfosStore.name}
            />,
            <StatisticItem
                title="Firmware Version"
                value={deviceInfosStore.secureVersion}
                suffix={<div className={deviceCss['suffixDetails']}>{`${deviceInfosStore.managedState}`}</div>}
            />,
            <StatisticItem
                valueStyle={{ color: isOnlineDevice(deviceInfosStore.status) ? '#52AB98' : '#d61346', fontWeight: 700 }}
                title="Status"
                value={isCommissionable(deviceInfosStore.status as DEVICE_STATUS, deviceInfosStore.tamperInfo?.status) ? COMMISSION_STATUS.COMMISSIONABLE : deviceInfosStore.status}
                valueRender={(node): React.ReactNode => isNullOrEmpty(deviceInfosStore.status) ?
                    <Spinner className={deviceCss['status-spinner']} size="medium"/> : node}
            />,
            <StatisticItem
              title='Last Online'
              value={isCommissionable(deviceInfosStore.status as DEVICE_STATUS, deviceInfosStore.tamperInfo?.status) ? '' : deviceInfosStore.lastOnline}
              valueRender={(node): React.ReactNode => isNullOrEmpty(deviceInfosStore.status) ? <Spinner className={deviceCss['status-spinner']} size='medium' /> : node}
            />,
            <StatisticItem title='Last Updated' value={deviceInfosStore.lastUpdated} />,
            <StatisticItem title='Actions' suffix={<ActionsMenu />} />,
        ];

        return (
            <BasicForm
                form={form}
                items={[
                    <div className={deviceCss.baseDetails}>
                        {deviceInfosStore.error != undefined && (
                            <DangerAlert closable={false} message="Errors" description={deviceInfosStore.error}/>
                        )}
                        <div className={deviceCss['base-details-rows']}>
                            <RowLayout childItems={items} childCols={[4, 3, 4, 2, 2, 4, 4, 1]} />
                        </div>
                    </div>,
                ]}
            />
        );
    });

    const BaseDetailTitle = () => {
        return (
            <div className={deviceCss['details-title']}>
                <div className={deviceCss['left']}>Device Details</div>
                <div className={deviceCss['right']}>Device UID: {uid}</div>
            </div>
        );
    };

    const SubCardEditableComponent = observer((props: IEditableDetailComponent) => {
        const editableComponents = buildEditableComponents(props);
        return (
            <div key={props.code} className={deviceCss['sub-card']}>
                <h3>
                    <span className={deviceCss['sub-card-title']}>{props.title}</span>
                    {editableComponents.EditButton}
                </h3>
                {editableComponents.edit ? (
                    editableComponents.Component
                ) : (
                    <span key={`span-${props.code}`}>{props.value}</span>
                )}
            </div>
        );
    });

    const displayBatteryTemperature = () => {
        const deviceDetails: (string | JSX.Element)[] = [
            <span key="scr-battery-span">
                SCR Battery (mV):{' '}
                {deviceInfosStore.info?.deviceInfo?.scrInfo?.hardware?.battery_mV || 'information not found'}
            </span>,
            <span key="scr-temp-span">
                SCR Temp (&#x2103;):{' '}
                {deviceInfosStore.info?.deviceInfo?.scrInfo?.hardware?.temperature_C || 'information not found'}
            </span>,
            <span key="taipan-battery-span">
                Taipan Battery (mV):{' '}
                {deviceInfosStore.taipanBatteryInfo?.batteryInfo?.voltage || 'information not found'}
            </span>,
        ];
        const tamperDetails = deviceInfosStore.tamperInfo?.tamperedList;
        if (!isNullOrEmpty(tamperDetails) && isOnlineDevice(deviceInfosStore.status)) {
            const tamper = tamperDetails.join(', ');
            deviceDetails.push(
                <div>
                    <span className={deviceCss.tamperDetails}>Hardware Tamper Present: </span>
                    {tamper}
                </div>,
            );
        }
        return deviceDetails;
    };

    const leftPanelDetails = (<BasicForm
        className={deviceCss['left-panel-details-form']}
        form={form}
        items={[
            <SubCardComponent
                code="group"
                title="Device Group"
                contents={[deviceInfosStore.groupLabel]}
            />,
            <SubCardComponent
                code="deviceType"
                title="Device Type"
                contents={[
                    deviceInfosStore.info?.deviceTypeName ||
                    'information not found',
                ]}
            />,
            <SubCardComponent
                code="keyInventory"
                title="Key Inventory"
                contents={deviceInfosStore.displayKeyInventory.keys}
                loading={deviceInfosStore.loading}
                numOfRows={2}
            />,
            <SubCardComponent
                code="batteryTemperature"
                title="Battery and Temperature"
                loading={deviceInfosStore.loading}
                contents={displayBatteryTemperature()}
                numOfRows={4}
            />,
            <SubCardEditableComponent
                code="attribute-timezone"
                attributeName="timezone"
                title="Timezone"
                afterFinish={(updated: boolean, value?: string): void => {
                    if (!updated) return;
                    if (!isOnlineDevice(deviceInfosStore.status)) {
                        notification['info']({
                            message:
                                'Time zone will be updated when device is online.',
                            placement: 'topRight',
                        });
                    } else {
                        deviceInfosStore.setTimezone(value);
                    }
                }}
                comboboxType={true}
                dataSource={[{ value: '', label: '' }, ...timezonesDisplay]}
                value={deviceInfosStore.timezone || ''}
            />,
            <SubCardEditableComponent
                code="attribute-location"
                title="Location"
                attributeName="location"
                value={deviceInfosStore.location}
            />,
            <SubCardComponent
                code="emvVersion"
                title="EMV Version"
                contents={[deviceInfosStore.emvVersion]}
            />,
            <SubCardComponent
                code="mspVersion"
                title="MSP Version"
                contents={[
                    deviceInfosStore.displayMspVersion.version ||
                    'information not found',
                ]}
                loading={deviceInfosStore.loading}
                numOfRows={1}
            />,
            <SubCardComponent
                code="scrStatus"
                title="SCR Status"
                contents={[
                    <span>
                        <strong style={{
                            textTransform: 'capitalize',
                            color: deviceInfosStore.tamperInfo?.status == SCR_TAMPER_STATUS.SECURE ? '#52AB98' : '#d61346'
                        }}>
                            {deviceInfosStore.tamperInfo?.status || 'information not found'}
                        </strong>
                    </span>
                ]}
            />,
        ]}
    />);

    const rightPanelDetails = (<BasicTab
        defaultActiveIndex={0}
        children={[
            {
                label: <Typography.Text strong>Deployments</Typography.Text>,
                pane: <DeviceDeploymentsTable store={deploymentsStore} accountId={accountId}/>,
            },
            {
                label: <Typography.Text strong>Jobs</Typography.Text>,
                pane: <DeviceJobTable store={deviceJobsStore}/>,
            },
            {
                label: <Typography.Text strong>Packages</Typography.Text>,
                pane: <DevicePackagesTableComponent store={devicePackagesStore}/>,
            },
            {
                label: <Typography.Text strong>SCR Files</Typography.Text>,
                pane: <DeviceSCRFileListingTable store={deviceSCRFileListingStore}/>,
            },
            {
                label: <Typography.Text strong>Crypto Report</Typography.Text>,
                pane: <DeviceCryptoReportComponent store={deviceCryptoReportStore}/>,
            },
            {
                label: <Typography.Text strong>Logs</Typography.Text>,
                pane: <DeviceLogsComponent store={devicelogsStore}/>,
            },
            {
                label: <Typography.Text strong>SRH</Typography.Text>,
                pane: <DeviceScrInfoComponent accountId={accountId} uid={uid}/>,
            },
        ]}
    />);

    return (
        <div className={deviceCss['device-details']}>
            <Skeleton
                loading={loading}
                children={
                    <div>
                        {!validDevice ? (
                            <Empty
                                className={deviceCss.emptyComponent}
                                description={
                                    <h3>{`Can't find the device with uid: ${uid} serial: ${deviceInfosStore.serialNumber || ''
                                    } esn: ${deviceInfosStore.esn || ''} `}</h3>
                                }
                            />
                        ) : (
                            <React.Fragment key="device-details-fragment">
                                <BasicCard
                                    className={deviceCss.device}
                                    title={<BaseDetailTitle/>}
                                    bordered={false}
                                    contents={<DeviceBaseDetails/>}
                                    size={'default'}
                                />
                                <HorizontalCollapseLayout
                                    defaultExpand={true}
                                    expandIconPosition="end"
                                    expandIcon={(panelProps) => <div style={{ marginTop: '5px' }}><Icon iconPrefix="fas"
                                                                                                        iconName={panelProps.isActive ? 'caret-left' : 'caret-right'}/>
                                    </div>}
                                    panelClassName={deviceCss['horizontal-collapse-panel']}
                                    leftPanelClassName={deviceCss['left-panel-details']}
                                    leftPanel={leftPanelDetails}
                                    rightPanelClassName={deviceCss['right-panel-details']}
                                    rightPanel={rightPanelDetails}
                                />
                            </React.Fragment>
                        )}
                        <IconTextButton
                            type="primary"
                            className={deviceCss.backBtn}
                            label="Back to Device Inventory"
                            onClick={() =>
                                navigate(deviceInventoryRoute.path, {
                                    state: { ...state, accountId: accountId },
                                })
                            }
                        />
                    </div>
                }
            />
        </div>
    );
});

export const VarModalDeviceDetails = VarModalComponent(DeviceDetails);
