import { showConfirmDialog } from 'ui-lib';
import { IDeviceGroupOptions } from '../../../dto/device-group-dto';

import * as utils from '../utils';

const CANCEL_POPCONFIRM_CONTENT = 'Your changes will be lost. Do you want to cancel your editing?';

export function confirmCancel(action: () => void | Promise<void>) {
    utils.confirmPopup('Confirm', CANCEL_POPCONFIRM_CONTENT, undefined, action);
}

const confirmDeleteDeviceGroup = (content: string, action: any, accountId: string | number, deviceGroup: IDeviceGroupOptions) => {
    showConfirmDialog({
        title: 'Confirm Delete',
        content,
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: () => action(accountId, deviceGroup),
    });
};

export const deleteDeviceGroupConfirm = (action: any, accountId: string | number, deviceGroup: IDeviceGroupOptions) => {
    confirmDeleteDeviceGroup('Do you want to delete this device group?', action, accountId, deviceGroup);
};

export function confirmDelete(code: string | number, content: string, action: any) {
    utils.confirmDelete(code, content, action);
}

export function confirmSave(code: string | number, content: string, action: any) {
    utils.confirmPopup('Confirm Save', content, code, action);
}

export function deleteAccount(id: number, action: any) {
    confirmDelete(id, 'Do you want to delete this account?', action);
}

export const confirmAction = (commandPayload: any, actionName: string, content: string, action: any) => {
    showConfirmDialog({
        title: `Confirm ${actionName}`,
        content,
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: () => action(commandPayload),
    });
};

export const deviceActionConfirm = (actionName: string, action: any, payload: any) => {
    confirmAction(payload, actionName, `This action will ${actionName} the device. Do you wish to proceed?`, action);
};

export function deleteUser(userId: string, action: any) {
    confirmDelete(userId, 'Do you want to delete this user?', action);
}

export function deleteDeploymentGroups(id: number, action: any) {
    confirmDelete(id, 'Do you want to delete this deployment groups?', action);
}

export const buildPath = (path: string[]): string => {
    const safePath = path.filter((item) => item != undefined);
    const stringPath = safePath.join('/');
    return window.btoa(stringPath);
};
