import * as React from 'react';
import { BasicTable, IBasicTableColumn } from '../table';
import { TransferItem } from 'antd/lib/transfer';
import { TableRowSelection } from 'antd/lib/table/interface';
import { difference } from 'lodash';
import { ITransferBodyProps as ITransferBodyProps, Transfer, ITransferProps } from './transfer';

export interface ITransferWithTableProps<T> extends ITransferProps<T> {
    columns?: IBasicTableColumn[];
    tableExtraOptions?: any;
    hideRightTableColumns?: string[];
}

export function TransferWithContentTable<T>(props: ITransferWithTableProps<T>) {
    return (
        <Transfer {...props}>
            {(bodyProps: ITransferBodyProps<T>) => {
                const rowSelection: TableRowSelection<TransferItem> = {
                    getCheckboxProps: (item) => ({ disabled: bodyProps.disabled || item.disabled }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
                        const diffKeys = selected
                            ? difference(treeSelectedKeys, bodyProps.selectedKeys)
                            : difference(bodyProps.selectedKeys, treeSelectedKeys);
                        bodyProps.onItemSelectAll(diffKeys as string[], selected);
                    },
                    onSelect({ key }, selected) {
                        bodyProps.onItemSelect(key as string, selected);
                    },
                    selectedRowKeys: bodyProps.selectedKeys,
                };

                const columns = bodyProps.direction === 'left' ? props.columns : props.columns.filter((column => (
                    !props.hideRightTableColumns?.includes(column.code)
                )));
                if (columns.length === 1) {
                    columns[0].width = '100%';
                }

                return (
                    <BasicTable
                        rowSelection={rowSelection}
                        columns={columns}
                        extraOptions={props.tableExtraOptions}
                        dataSource={bodyProps.filteredItems}
                    />
                );
            }}
        </Transfer>
    );
}
