import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DevicesInventoryDashboard } from './devices-inventory/devices-inventory-dashboard';
import { useAuthStore, useRoutesStore, useStores } from '../../../store';
import { PermissionPage, Error404 } from 'ui-lib';

import { CommissioningWizard } from './wizard';
import { hasPermission } from '../utils';
import shellPromptCss from './shell-prompt/shellprompt.css';
import { CommissioningUpload, CommissioningUploadReview } from './commissioning-upload';

import { RemoteCommands } from './remote-commands/remote-commands';
import commissioningUploadCss from './commissioning-upload/commissioning-upload.css';
import { DecommissioningDevice } from './decommissioning';
import { ShellCommandExecution } from './shell-prompt/shellprompt';
import { VarModalDeviceDetails } from './devices-inventory/device-details';
import { FileTransfer } from './file-transfer/file-transfer';
import { ManageDeviceGroupsPage } from './device-groups';
import { DeviceReset } from './device-reset/device-reset';

export enum DEVICES_ROUTE_CODES {
    ROOT = 'DEVICES',
    DETAILS = 'DEVICES_DETAILS',
    INVENTORY = 'DEVICES_INVENTORY',
    COMMISSIONING = 'DEVICES_COMMISSIONING_WIZARD',
    COMMISSIONING_UPLOAD = 'DEVICES_COMMISSIONING_UPLOAD',
    COMMISSIONING_UPLOAD_REVIEW = 'DEVICES_COMMISSIONING_UPLOAD_REVIEW',
    SHELL_COMMAND = 'DEVICES_SHELL_COMMAND',
    FILE_TRANSFER = 'DEVICES_FILE_TRANSFER',
    BULK_REMOTE_COMMAND = 'DEVICES_REMOTE_COMMAND',
    DECOMMISSIONING = 'DEVICES_DECOMMISSIONING',
    COMMISSIONED = 'DEVICES_COMMISSIONED',
    DEVICE_GROUPS = 'DEVICES_DEVICE_GROUPS',
    DEVICE_RESET = 'DEVICES_DEVICE_RESET',
}

enum EXCLUDE_CODES {
    COMMISSIONED = 'commissioned',
}

export function DevicePages() {
    const { isAuthenticated } = useAuthStore();
    const routeStore = useRoutesStore();
    const stores = useStores();

    const canAccessCommissioning = React.useMemo(
        () => isAuthenticated && hasPermission(DEVICES_ROUTE_CODES.COMMISSIONING, stores),
        [],
    );

    const rootRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.ROOT);

    return (
        <Routes>
            <Route
                path={routeStore.getRoute(DEVICES_ROUTE_CODES.INVENTORY).trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={stores.permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title='Inventory'
                    >
                        <DevicesInventoryDashboard />
                    </PermissionPage>
                }
            />
            <Route
                path={routeStore.getRoute(DEVICES_ROUTE_CODES.SHELL_COMMAND).trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={stores.permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title='Shell command'
                        className={shellPromptCss.shell_prompt}
                    >
                        <ShellCommandExecution />
                    </PermissionPage>
                }
            />
            <Route
                path={routeStore.getRoute(DEVICES_ROUTE_CODES.COMMISSIONING).trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={stores.permissionStore.permissionCodes}
                        routes={routeStore.routes}
                    >
                        <CommissioningWizard canAccess={canAccessCommissioning} />
                    </PermissionPage>
                }
            />
            <Route
                path={routeStore.getRoute(DEVICES_ROUTE_CODES.COMMISSIONING_UPLOAD).trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={stores.permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        className={commissioningUploadCss.form}
                    >
                        <CommissioningUpload />
                    </PermissionPage>
                }
            />
            <Route
                path={routeStore.getRoute(DEVICES_ROUTE_CODES.COMMISSIONING_UPLOAD_REVIEW).trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={stores.permissionStore.permissionCodes}
                        routes={routeStore.routes}
                    >
                        <CommissioningUploadReview />
                    </PermissionPage>
                }
            />
            <Route
                path={routeStore.getRoute(DEVICES_ROUTE_CODES.FILE_TRANSFER).trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        title='File transfer'
                        permissionCodes={stores.permissionStore.permissionCodes}
                        routes={routeStore.routes}
                    >
                        <FileTransfer />
                    </PermissionPage>
                }
            />
            <Route
                path={routeStore.getRoute(DEVICES_ROUTE_CODES.DETAILS).trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={stores.permissionStore.permissionCodes}
                        routes={routeStore.routes}
                    >
                        <VarModalDeviceDetails />
                    </PermissionPage>
                }
            />
            <Route
                path={routeStore.getRoute(DEVICES_ROUTE_CODES.DECOMMISSIONING).trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        title='Decommissioning'
                        permissionCodes={stores.permissionStore.permissionCodes}
                        routes={routeStore.routes}
                    >
                        <DecommissioningDevice />
                    </PermissionPage>
                }
            />
            <Route
                path={`${routeStore.getRoute(DEVICES_ROUTE_CODES.DEVICE_GROUPS).trimRootPath(rootRoute.path)}/*`}
                element={
                    <PermissionPage
                        title={'Device groups'}
                        routes={routeStore.routes}
                        permissionCodes={stores.permissionStore.permissionCodes}
                    >
                        <ManageDeviceGroupsPage />
                    </PermissionPage>

                }
            />
            <Route
                path={`${routeStore.getRoute(DEVICES_ROUTE_CODES.DEVICE_RESET).trimRootPath(rootRoute.path)}/*`}
                element={
                    <PermissionPage
                        title={'Device reset'}
                        routes={routeStore.routes}
                        permissionCodes={stores.permissionStore.permissionCodes}
                    >
                        <DeviceReset />
                    </PermissionPage>
                }
            />
            {Object.keys(EXCLUDE_CODES).map((excludeCode) => (
                <Route key={excludeCode} path={EXCLUDE_CODES[excludeCode]} element={<Error404 message='Page Not Found' />} />
            ))}
            <Route path='*' element={<Error404 message='Page Not Found' />} />
        </Routes>
    );
}
