import * as React from 'react';
import { observer } from 'mobx-react';
import assignDeviceCss from './assign-device.css';
import { Label, TransferWithContentTable, Skeleton, IBasicTableColumn, Empty } from 'ui-lib';
import { TStoresContext, useStores } from 'store';
import { IDisplayDeviceProperties } from '../../../../store/assign-devices-store';
import { useManageDeviceGroupContext } from '.';
import { DeviceUuidHyperlink } from 'pages/private/components/device-uuid-hyperlink-component';
import { hasContainsIgnoreCase, localeCompare } from 'common-utils/string-utils';

interface IAssignDeviceColumnsProps {
    accountId: number;
    includeDeviceType?: boolean;
    includeSerialNumberColumn?: boolean;
    includeESNColumn?: boolean;
}
export const getAssignDeviceColumns = (options: IAssignDeviceColumnsProps): IBasicTableColumn[] => {
    const columns: IBasicTableColumn[] = [
        {
            code: 'UID',
            title: 'Device UID',
            dataIndex: 'UID',
            width: '60%',
            onFilter: (text: string, record: IDisplayDeviceProperties) => hasContainsIgnoreCase(record.UID, text),
            textSearchPlaceholder: 'Search UID',
            render: (uid: string, record: any) => <DeviceUuidHyperlink uid={uid} accountId={options.accountId} />,
            sorter: (a: IDisplayDeviceProperties, b: IDisplayDeviceProperties) => localeCompare(a.UID, b.UID),
        }
    ];

    if (options.includeSerialNumberColumn) {
        columns.push({
            code: 'serialNumber',
            title: 'Serial Number',
            dataIndex: 'serialNumber',
            onFilter: (text: string, record: IDisplayDeviceProperties) => hasContainsIgnoreCase(record.serialNumber, text),
            textSearchPlaceholder: 'Search Serial Number',
            sorter: (a: IDisplayDeviceProperties, b: IDisplayDeviceProperties) => localeCompare(a.serialNumber, b.serialNumber)
        });
    }

    if (options.includeESNColumn) {
        columns.push({
            code: 'esn',
            title: 'eSN',
            dataIndex: 'esn',
            onFilter: (text: string, record: IDisplayDeviceProperties) => hasContainsIgnoreCase(record.esn, text),
            textSearchPlaceholder: 'Search eSN',
            sorter: (a: IDisplayDeviceProperties, b: IDisplayDeviceProperties) => localeCompare(a.esn, b.esn)
        });
    }

    if (options.includeDeviceType) {
        columns.push({
            code: 'deviceType',
            title: 'Device Type',
            dataIndex: 'deviceType',
            width: '25%',
            onFilter: (text: string, record: IDisplayDeviceProperties) => hasContainsIgnoreCase(record.deviceType, text),
            textSearchPlaceholder: 'Search Type',
            sorter: (a: IDisplayDeviceProperties, b: IDisplayDeviceProperties) => localeCompare(a.deviceType, b.deviceType),
        });
    }

    columns.push({
        code: 'deviceGroupLabel',
        title: 'Device Group',
        dataIndex: 'deviceGroupLabel',
        width: '40%',
        onFilter: (text: string, record: IDisplayDeviceProperties) => hasContainsIgnoreCase(record.deviceGroupLabel, text),
        textSearchPlaceholder: 'Search Device Group',
        sorter: (a: IDisplayDeviceProperties, b: IDisplayDeviceProperties) => localeCompare(a.deviceGroupLabel, b.deviceGroupLabel),
    });
    return columns;
};

export const AssignDevicesComponent = observer(() => {
    const { assignDevicesStore: store } = useManageDeviceGroupContext();
    const stores = useStores();

    return (
        <Skeleton key='devicesTransferSkeleton' loading={store.loading}>
            <React.Fragment key='transferDevices'>
                <div className={assignDeviceCss.deviceGroupLabel}>
                    <Label
                        code='selectDeviceDescription'
                        label='Select devices to assign to the device group'
                        strong
                    />
                </div>
                <TransferWithContentTable
                    code='devicesTransfer'
                    className={assignDeviceCss.transfer}
                    dataSource={store.displayDeviceDataSource}
                    targetKeys={store.targetDevices}
                    selectedKeys={[...store.sourceSelectedDevices, ...store.targetSelectedDevices]}
                    operations={['Add', 'Remove']}
                    titles={[
                        <Label key='availableDevicesLabel' label='Available Devices' strong />,
                        <Label key='devicesInDeviceGroupLabel' label='Devices in Device Group' strong />,
                    ]}
                    onSelectChange={(sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
                        store.setSourceSelectedDevices(sourceSelectedKeys);
                        store.setTargetSelectedDevices(targetSelectedKeys);
                    }}
                    onChange={(targetKeys: string[], direction: string, moveKeys: any) => {
                        store.setTargetDevices(targetKeys);
                    }}
                    filterOption={(inputValue: string, item: IDisplayDeviceProperties) =>
                        item.UID.toLowerCase().includes(inputValue.toLowerCase()) ||
                        item.deviceGroupLabel?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    showSearch
                    columns={getAssignDeviceColumns({ accountId: stores.commonStore.selectedOrganisation })}
                    tableExtraOptions={{
                        scroll: { y: 500 },
                        locale: {
                            emptyText: (
                                <Empty
                                    image='simple'
                                    description='The device UID or device group could not be found'
                                />
                            ),
                        },
                    }}
                    hideRightTableColumns={['deviceGroupLabel']}
                />
            </React.Fragment>
        </Skeleton>
    );
});
