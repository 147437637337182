import * as React from 'react';
import { useAuthStore, useDeploymentStore } from '../../../../store';
import { TDisplayDeploymentProps } from '../../../../store/deployment-store';
import { isNumber } from 'lodash';

export interface IDeploymentsWithGroupProps {
    selectedGroupId?: number;
    onGroupChange: (groupId: number) => void;
    onFilter: (searchText: string) => void;
    deployments: TDisplayDeploymentProps[];
    loadDeployments: (force?: boolean) => Promise<void>;
}

const isValidId = (id: number | undefined | null): boolean => isNumber(id) && id > 0;
// The component use these states need to a observer component.
export const useDeploymentStates = (buildDeployments = false): IDeploymentsWithGroupProps => {
    const [selectedDeploymentGroupId, setSelectedDeploymentGroupId] = React.useState<number>(null);
    const { assureApiAccount } = useAuthStore();
    const deploymentStore = useDeploymentStore();
    const [filterText, setFilterText] = React.useState<string>('');

    const deploymentDataSource = React.useMemo<TDisplayDeploymentProps[]>(() => {
        if (!isValidId(selectedDeploymentGroupId)) return [];
        return deploymentStore.displayDeployments
            .filter(deployment => deployment.deploymentGroupId == selectedDeploymentGroupId)
            .filter(deployment => deployment.label.toLowerCase().includes(filterText.toLowerCase()));
    }, [selectedDeploymentGroupId, deploymentStore.displayDeployments, filterText]);

    React.useEffect(() => {
        loadDeployments(buildDeployments);
    }, [selectedDeploymentGroupId]);

    const loadDeployments = async (force = false) => {
        if (!isValidId(selectedDeploymentGroupId)) return;
        await deploymentStore.loadDeploymentsByGroupId(assureApiAccount.accountId, selectedDeploymentGroupId, force);
    };

    return {
        selectedGroupId: selectedDeploymentGroupId,
        onGroupChange: setSelectedDeploymentGroupId,
        deployments: deploymentDataSource,
        onFilter: setFilterText,
        loadDeployments,
    };
};
