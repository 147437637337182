import * as React from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    BasicForm,
    Error401,
    IFormItemValidation,
    IconTextButton,
    Page,
    PasswordFieldItem,
    showInformationDialog,
} from 'ui-lib';
import { getErrorMessage } from 'common-utils';
import { passwordValidator } from 'utils/common-utils';
import { useAuthStore } from '../../../store';

import formCss from './login.css';

const title = 'First login';

export const FirstLoginPageWrapper = observer(() => {
    const navigate = useNavigate();
    const authStore = useAuthStore();

    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    const [confirmNewPasswordValidation, setConfirmNewPasswordValidation] = React.useState<IFormItemValidation>();

    const state: { temporaryPassword?: string; } = useLocation().state || {};
    const newPasswordValidator = (rule, value, callback) => {
        if (value == state.temporaryPassword)
            return callback('You used this password recently. Please choose a different one.');
        return passwordValidator(rule, value, callback);
    };

    const onLogOut = (): void => {
        authStore.logOut();
        navigate('/');
    };

    const onSubmit = async (values): Promise<void> => {
        setConfirmNewPasswordValidation(null);
        if (values.newPassword != values.confirmNewPassword) {
            setConfirmNewPasswordValidation({
                status: 'error',
                message: 'Passwords do not match!',
            });
            return;
        }

        try {
            setIsProcessing(true);
            await authStore.firstLogin(values.newPassword);
            showInformationDialog({
                title,
                modalType: 'success',
                content: 'Password has been successfully changed. Please login with your new password.',
                afterClose: onLogOut,
            });
        } catch (err) {
            let errorMessage = getErrorMessage(err);
            const sessionExpired = errorMessage.includes('session is expired');
            if (sessionExpired) errorMessage = `${errorMessage} Please login again`;
            showInformationDialog({
                title,
                modalType: 'error',
                content: errorMessage,
                afterClose: !sessionExpired ? null : onLogOut,
            });
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <Page className={formCss.first_login}>
            {!authStore.currentUser.isActive ? (
                <BasicForm
                    cardPros={{ title: <h2>Change password</h2> }}
                    items={[
                        <div key='description' className={formCss.description}>This is your first login. Please change your password and login again.</div>,
                        <PasswordFieldItem
                            key='newPassword'
                            code={'newPassword'}
                            label={'New password'}
                            isRequired={true}
                            labelColSpan={6}
                            validator={newPasswordValidator}
                        />,
                        <PasswordFieldItem
                            key='confirmNewPassword'
                            code={'confirmNewPassword'}
                            label={'Confirm password'}
                            isRequired={true}
                            labelColSpan={6}
                            onChange={(): void => setConfirmNewPasswordValidation(null)}
                            customizeValidate={confirmNewPasswordValidation}
                        />,
                        <IconTextButton
                            key='submit'
                            loading={isProcessing}
                            label='Change password'
                            type={'primary'}
                            block={true}
                            htmlType={'submit'}
                        />
                    ]}
                    onSubmit={onSubmit}
                />
            ) : (
                <Error401 message="User doesn't have access to this page" />
            )}
        </Page>
    );
});
