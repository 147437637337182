import * as React from 'react';
import { observer } from 'mobx-react';

import { intersection } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    BasicForm,
    ICardProps,
    useForm,
    TextAreaFieldItem,
    IconTextButton,
    Spinner,
    showConfirmDialogWithoutCancel,
    showInformationDialog,
    ITreeSelectProps,
    AdvanceTable,
} from 'ui-lib';

import { OrganisationSelectFormItem } from '../../components/organisation-component';
import { useAuthStore, useDeviceStore, useRoutesStore, useStores } from '../../../../store';
import { getErrorMessage } from 'common-utils';
import shellPromptCss from './shellprompt.css';
import { DeviceGroupSelectItem, DeviceIdentifierItem } from '../../components/device-groups-select';
import { IShellCommandExecutionResponse } from '../../../../dto/device-dto';
import { DEVICES_ROUTE_CODES } from '../';
import { useDeviceGroupCommonStates } from '../../devices/device-groups';
import { DeviceUuidHyperlink } from 'pages/private/components/device-uuid-hyperlink-component';
import { DeviceGroupHyperlink } from 'pages/private/components/device-group-hyperlink-component';

interface IShellPromptOptions {
    deviceName: string;
    deviceGroup: string;
    shellCommand: string;
}
interface IShellPromptComponentProps {
    isEdit?: boolean;
    uid?: string;
    deviceGroupName?: string;
    cardPros?: ICardProps;
    shellPromptInfo: IShellPromptOptions;
}

export const ShellPromptExecution = observer((props: IShellPromptComponentProps) => {
    const { currentUser } = useAuthStore();
    const devicesStore = useDeviceStore();
    const {
        treeSelectableDataSource: deviceGroupsTree,
        deviceGroupLoading,
        onAccountChanged,
    } = useDeviceGroupCommonStates();
    const stores = useStores();
    const [form] = useForm();
    const navigate = useNavigate();
    const [displayCommandOutput, setDisplayCommandOutput] = React.useState<IShellCommandExecutionResponse[]>([]);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const state: Partial<IShellPromptComponentProps> = useLocation().state || {};
    const [selectedDeviceGroup, setSelectedDeviceGroup] = React.useState(state.deviceGroupName || '');
    const [deviceGroupToLink, setDeviceGroupToLink] = React.useState('');

    const routeStore = useRoutesStore();
    const shellCommandRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.SHELL_COMMAND);

    React.useEffect(() => {
        if (!stores.commonStore.selectedOrganisation) return;
        onAccountChanged(stores.commonStore.selectedOrganisation);
    }, [stores.commonStore.selectedOrganisation]);

    const deviceGroupsSelectProps: ITreeSelectProps = {
        dataSource: deviceGroupsTree,
        expandAll: true,
        allowClear: true,
        onChange: (value) => {
            setDeviceGroupToLink('');
            setSelectedDeviceGroup(value || '');
        },
    };

    const onSave = async (values: any) => {
        try {
            setIsProcessing(true);
            if (
                (!values.deviceIdentifierField && !values.deviceGroupField) ||
                (values.deviceIdentifierField && values.deviceGroupField)
            ) {
                return showConfirmDialogWithoutCancel({
                    title: 'Incorrect input',
                    content: 'Please provide either device details or device group details',
                    okText: 'OK',
                    onOk: () => navigate(shellCommandRoute.path),
                });
            }

            const shellPromptInfo: IShellPromptOptions = {
                deviceName: values.deviceIdentifierField,
                deviceGroup: values.deviceGroupField,
                shellCommand: values.shellCommandField,
            };

            const result = await devicesStore.shellCommandExecution(stores.commonStore.selectedOrganisation, {
                deviceName: shellPromptInfo.deviceName,
                deviceGroup: shellPromptInfo.deviceGroup,
                command: shellPromptInfo.shellCommand,
            });

            setDisplayCommandOutput(result);
            if(selectedDeviceGroup) {
                setDeviceGroupToLink(selectedDeviceGroup);
            };
            return showConfirmDialogWithoutCancel({
                title: 'Success',
                content: 'Command was completed successfully',
                okText: 'OK',
                onOk: () => navigate(shellCommandRoute.path),
            });
        } catch (err) {
            return showInformationDialog({
                modalType: 'error',
                title: 'Error',
                content: getErrorMessage(err),
            });
        } finally {
            setIsProcessing(false);
        }
    };

    const deviceNameField = (
        <DeviceIdentifierItem
            labelAlign='right'
            initialValue={state.uid ? state.uid : props.shellPromptInfo.deviceName}
        />
    );

    const selectOrganisationField = (
        <OrganisationSelectFormItem
            code='organisation'
            isRequired={true}
            labelAlign='right'
            accountType={currentUser.accountType}
            selectedValue={stores.commonStore.selectedOrganisation}
            onChange={(value) => {
                setSelectedDeviceGroup('');
                setDeviceGroupToLink('');
                form.setFieldsValue({ ['deviceGroupField']: '' }), stores.commonStore.setSelectedOrganisation(value);
            }}
        />
    );

    const DeviceGroupField = observer(() => {
        return (
            <DeviceGroupSelectItem
                code='deviceGroupField'
                label='Device group'
                labelAlign='right'
                isRequired={false}
                initialValue={selectedDeviceGroup}
                selectProps={deviceGroupsSelectProps}
            />
        );
    });

    const shellCommandField = (
        <TextAreaFieldItem
            labelColSpan={5}
            code='shellCommandField'
            label='Command'
            labelAlign='right'
            isRequired={true}
            initialValue={props.shellPromptInfo.shellCommand}
            textAreaProps={{ placeholder: 'shell prompt', autoSize: { minRows: 5, maxRows: 10 } }}
        />
    );

    const submitButtonField = (
        <IconTextButton
            className={shellPromptCss.control}
            key='save'
            label='Send command'
            type='primary'
            htmlType='submit'
            loading={isProcessing}
        />
    );

    return (
        <React.Fragment>
            <Spinner className={shellPromptCss.spinner} spinning={isProcessing || deviceGroupLoading}>
                <BasicForm
                    form={form}
                    key='addOrEdit'
                    className={shellPromptCss.save_form}
                    items={[
                        selectOrganisationField,
                        deviceNameField,
                        <DeviceGroupField />,
                        shellCommandField,
                        submitButtonField,
                    ]}
                    onSubmit={onSave}
                />
                <AdvanceTable
                    title='Shell command output'
                    table={{
                        columns: [
                            {
                                code: 'deviceName',
                                title: 'Device Name',
                                dataIndex: 'deviceName',
                                render: (uid: string, record: any) => (
                                    <DeviceUuidHyperlink uid={uid} accountId={stores.commonStore.selectedOrganisation} />
                                ),
                            },
                            {
                                code: 'status',
                                title: 'Status',
                                dataIndex: 'status',
                            },
                            {
                                code: 'output',
                                title: 'Output',
                                dataIndex: 'output',
                            },
                        ],
                        dataSource: displayCommandOutput || [],
                    }}
                    loadingState={false}
                />
                {deviceGroupToLink && (
                    <DeviceGroupHyperlink deviceGroupName={deviceGroupToLink} />
                )},
            </Spinner>
        </React.Fragment>
    );
});

export function ShellCommandExecution() {
    return (
        <ShellPromptExecution
            shellPromptInfo={{
                deviceName: '',
                deviceGroup: '',
                shellCommand: '',
            }}
        />
    );
}
