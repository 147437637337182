import React from 'react';
import { Statistic } from 'antd';

const { Countdown } = Statistic;

export interface ICountdownProps {
    className?: string;
    title?: string;
    value: number;
    format?: string;
    suffix?: React.ReactNode;
    prefix?: React.ReactNode;
    onChange?: (value) => void;
    onFinish?: () => void;
}

export const CountdownStatistic = (props: ICountdownProps) => {
    return (
        <Countdown
            className={props.className}
            title={props.title}
            value={props.value}
            format={props.format}
            onFinish={props.onFinish}
            prefix={props.prefix}
            suffix={props.suffix}
        />
    );
};
