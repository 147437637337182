/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import * as React from 'react';
import cx from 'classnames';
import { Upload } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { IconTextButton } from '../buttons';

import uploadCss from './file-manual-upload.css';

export interface IExtraValidatorResult {
    status?: 'error' | 'wrarning' | undefined;
    response?: string;
}

export interface ISimpleFileUploadProps {
    className?: string;
    fileList: any[];
    setFileList: any;
    limitFileSize?: number; // Kilobytes
    allowedFileTypes?: string[];
    multiple?: boolean;
    setFileError?: (hasError: boolean) => void;
    button?: {
        label: string;
        type?: ButtonType;
    }
    onPreview?: (file) => void;
    onRemove?: (fileToRemove, newFileList) => void;
    extraValidator?: (file: File) => IExtraValidatorResult;
    beforeUploadChecking?: (currentFiles: File[], newFiles: File[]) => File[]; // return valid files
    afterUploadChecking?: (files: File[]) => Promise<void>;
}
const FILE_TYPE_ERROR = (allowedFileTypes: string[]) => `Only allow {${allowedFileTypes.join(', ')}} type(s).`;
const LIMIT_SIZE_ERROR = (kilobytes: number) => `The maximum upload limit is ${kilobytes}KB`;
const convertKBtoBytes = (kilobytes: number) => kilobytes * 1024;

export const SimpleFileUpload = <T extends unknown>(config: ISimpleFileUploadProps) => {
    const limitFileSizeInBytes = config.limitFileSize ? convertKBtoBytes(config.limitFileSize) : undefined;
    const [fileList, setFileList] = [config.fileList, config.setFileList];

    const updateFileList = (files: File[]): void => {
        const checkedFiles = files?.map((file) => {
            const extension = file.name.substring(file.name.lastIndexOf('.'));
            if (
                config.allowedFileTypes?.length > 0 &&
                !(config.allowedFileTypes.includes(file.type) || config.allowedFileTypes.includes(extension))
            )
                return Object.assign(file, { status: 'error', response: FILE_TYPE_ERROR(config.allowedFileTypes) });

            if (limitFileSizeInBytes && file.size > limitFileSizeInBytes)
                return Object.assign(file, { status: 'error', response: LIMIT_SIZE_ERROR(config.limitFileSize) });

            return Object.assign(file, !config.extraValidator ? {} : config.extraValidator(file));
        });
        if (config.setFileError) config.setFileError(checkedFiles.some((file) => file.status == 'error'));

        setFileList(checkedFiles);
    };

    const beforeUpload = async (_file, _fileList): Promise<boolean> => {
        if (config.beforeUploadChecking) {
            _fileList = config.beforeUploadChecking(fileList, _fileList);
        }
        const files = [...fileList, ..._fileList];
        updateFileList(files);
        config.afterUploadChecking && await config.afterUploadChecking(files);
        return false;
    };

    const onRemove = (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        if(config.onRemove){
            config.onRemove(file, newFileList);
        }
        updateFileList(newFileList);
        return false;
    };

    const uploadProps = {
        onRemove,
        onPreview: config.onPreview,
        beforeUpload,
        fileList,
        multiple: config.multiple,
        accept: config.allowedFileTypes?.join(', '),
    };

    return (
        <div className={cx(uploadCss.uploadBox, config.className)}>
            <Upload {...uploadProps} className={uploadCss.spacer}>
                <IconTextButton iconName={'upload'} label={config.button?.label || 'Select files'} type={config.button?.type} />
            </Upload>
        </div>
    );
};
