/* eslint-disable react/jsx-key */
import * as React from 'react';
import { observer } from 'mobx-react';

import {
    BasicForm,
    DangerAlert,
    IconTextButton,
    ITreeSelectProps,
    notification,
    SelectFormItem,
    showConfirmDialog,
    showInformationDialog,
    Spinner,
    useForm,
} from 'ui-lib';

import { DECOMMISSION_TYPE } from '../../../../dto/device-dto';
import { useAuthStore, useDeviceStore, useNotificationsStore, useStores, } from '../../../../store';
import { DeviceGroupSelectItem, DeviceIdentifierItem } from '../../components/device-groups-select';

import decommissioningCss from './decommissioning.css';
import { MESSAGE_TYPE, NOTIFICATION_TYPE } from '../../../../dto/notifications-dto';
import { useLocation } from 'react-router-dom';
import { OrganisationSelectFormItem } from '../../components/organisation-component';
import { getErrorMessage } from 'common-utils';
import { useDeviceGroupCommonStates } from '../device-groups';
import { DeviceUuidHyperlink } from '../../components/device-uuid-hyperlink-component';
import { DeviceGroupHyperlink } from '../../components/device-group-hyperlink-component';

const DECOMMISSION_DATASOURCE = Object.values(DECOMMISSION_TYPE).map((type) => ({
    value: type,
    label: type,
}));
const TARGET_REQUIRED = 'Must input data for device or device group';

export const DecommissioningDevice = observer(() => {
    const { currentUser } = useAuthStore();
    const stores = useStores();
    const deviceStore = useDeviceStore();
    const notificationsStore = useNotificationsStore();
    const location = useLocation();
    const state: { uid?: string; deviceGroupName?: string } = location.state || {};
    const [selectedDevice, setSelectedDevice] = React.useState<string>(state.uid);
    const [selectedType, setSelectedType] = React.useState<DECOMMISSION_TYPE>(DECOMMISSION_TYPE.PARTIAL);
    const [deviceUidToLink, setDeviceUidToLink] = React.useState('');
    const [deviceGroupToLink, setDeviceGroupToLink] = React.useState('');

    const {
        treeSelectableDataSource: deviceGroupsTree,
        deviceGroupLoading,
        onAccountChanged
    } = useDeviceGroupCommonStates();

    const [form] = useForm();
    const [errors, setErrors] = React.useState<string[]>([]);

    const [processing, setProcessing] = React.useState<boolean>(false);

    const [selectedDeviceGroup, setSelectedDeviceGroup] = React.useState<string>(() => {
        form.setFieldsValue({ 'device-group-select-item': state.deviceGroupName });
        return state.deviceGroupName;
    });

    React.useEffect(() => {
        window.history.replaceState({}, document.title);
        if (!stores.commonStore.selectedOrganisation) return;
        onAccountChanged(stores.commonStore.selectedOrganisation);
    }, [stores.commonStore.selectedOrganisation]);

    const deviceGroupsSelectProps: ITreeSelectProps = {
        dataSource: deviceGroupsTree,
        expandAll: true,
        allowClear: true,
        onChange: (value) => {
            clearProcessedData();
            setSelectedDeviceGroup(value || '');
        }
    };

    const hasInputFields = (): boolean => !!selectedDevice || !!selectedDeviceGroup;
    const clearProcessedData = () => {
        setDeviceUidToLink('');
        setDeviceGroupToLink('');
    };
    const clientOrDeviceGroupValidator = (rule, value, callback) =>
        callback(hasInputFields() ? undefined : TARGET_REQUIRED);

    const submitDecommissioningDevice = async () => {
        setErrors([]);
        setProcessing(true);
        try {
            const result = await deviceStore.decommissionDevices(stores.commonStore.selectedOrganisation, {
                deviceName: selectedDevice,
                deviceGroupName: selectedDeviceGroup,
                type: selectedType,
            });
            const errors = result.devices
                .filter((device) => !device.success)
                .map((device) => `${device.identifier}: ${device.error}`);
            if (errors.length > 0) {
                setErrors(errors);
                return;
            }
            notification['info']({
                message: 'Decommissioned',
                description: 'The device or device group has been decommissioned successfully',
                placement: 'topRight',
            });
            await notificationsStore.create({
                content: {
                    title: 'Decommissioned',
                    text: 'The device or device group has been decommissioned successfully',
                    messageType: MESSAGE_TYPE.DEVICE,
                },
                type: NOTIFICATION_TYPE.PERSONAL,
                receiverUserUuid: currentUser.uuid,
                sender: currentUser.userName,
            });

            if (selectedDevice) {
                setDeviceUidToLink(result.devices[0].identifier);
            } else if (selectedDeviceGroup) {
                setDeviceGroupToLink(selectedDeviceGroup);
            }

        } catch (err) {
            setErrors([getErrorMessage(err)]);
        } finally {
            setProcessing(false);
        }
    };

    const formSubmit = () => {
        if (selectedDevice && selectedDeviceGroup)
            return showInformationDialog({
                modalType: 'error',
                title: 'Error',
                content: 'Cannot use the device, device group field at the same time.',
            });
        if (selectedType === DECOMMISSION_TYPE.FULL) {
            return showConfirmDialog({
                title: 'Warning',
                content: (
                    <React.Fragment>
                        <p>Are you sure you want to decommission the device?</p>
                        <p>This action:</p>
                        <p>&#8226; removes firmware, keys, certificates from it;</p>
                        <p>&#8226; keeps it assigned to Client account with DECOMMISSIONED status;</p>
                        <p>&#8226; disables re-assigning it to any Client.</p>
                        <p>Please proceed by clicking Ok or ignore the action by clicking Cancel button.</p>
                    </React.Fragment>
                ),
                okText: 'OK',
                cancelText: 'Cancel',
                onOk: submitDecommissioningDevice,
            });
        }
        return showConfirmDialog({
            title: 'Confirmation',
            content: 'Are you sure you want to decommission this device or device group?',
            okText: 'OK',
            cancelText: 'Cancel',
            onOk: submitDecommissioningDevice,
        });
    };

    return (
        <Spinner spinning={deviceGroupLoading || processing} title="" className={decommissioningCss['spinner']}>
            {errors.length > 0 && (
                <DangerAlert
                    className={decommissioningCss['errors-alert']}
                    message="Errors"
                    description={
                        <React.Fragment>
                            {errors.map((error, index) => (
                                <p key={`error-${index}`}>{error}</p>
                            ))}
                        </React.Fragment>
                    }
                />
            )}
            <BasicForm
                className={decommissioningCss['form']}
                form={form}
                onSubmit={formSubmit}
                title="Decommissioning"
                items={[
                    <OrganisationSelectFormItem
                        key="organisation"
                        code="organisation"
                        isRequired={true}
                        labelAlign="right"
                        accountType={currentUser.accountType}
                        selectedValue={stores.commonStore.selectedOrganisation}
                        onChange={(value) => {
                            form.resetFields(['device-group-select-item']);
                            form.setFieldsValue({ 'deviceIdentifierField': '' });
                            clearProcessedData();
                            setSelectedDevice(() => undefined);
                            setSelectedDeviceGroup(undefined);
                            stores.commonStore.setSelectedOrganisation(value);
                        }}
                    />,
                    <DeviceIdentifierItem
                        key="device-identifier-item"
                        labelAlign="right"
                        initialValue={selectedDevice}
                        extraValidator={hasInputFields}
                        onChange={(value) => {
                            clearProcessedData();
                            setSelectedDevice(value.target.value);
                        }}
                    />,
                    <DeviceGroupSelectItem
                        key="device-group-select-item"
                        code="device-group-select-item"
                        labelAlign={'right'}
                        label="Device Group:"
                        validator={clientOrDeviceGroupValidator}
                        selectProps={deviceGroupsSelectProps}
                    />,
                    <SelectFormItem
                        key="decommission-select"
                        code="decommission-select"
                        label="Type:"
                        labelAlign="right"
                        dataSource={DECOMMISSION_DATASOURCE}
                        selectedValue={selectedType}
                        onChange={setSelectedType}
                    />,
                    <IconTextButton
                        key="submit-decommission-button"
                        className={decommissioningCss['submit-button']}
                        type="primary"
                        htmlType="submit"
                        label="Decommission"
                        disabled={!(selectedDevice || selectedDeviceGroup)}
                        loading={processing}
                    />,
                ]}
            />
            {deviceUidToLink && (
                <DeviceUuidHyperlink
                    uid={deviceUidToLink}
                    type="primary"
                    label="Go to Device Details"
                    accountId={stores.commonStore.selectedOrganisation}
                />
            )}
            {deviceGroupToLink && (
                <DeviceGroupHyperlink deviceGroupName={deviceGroupToLink}/>
            )}
        </Spinner>
    );
});
