import * as React from 'react';
import { Badge, Typography } from 'antd';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, library, SizeProp } from '@fortawesome/fontawesome-svg-core';
import type { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import iconCss from './icon.css';

import {
    faAngleDown,
    faAngleRight,
    faArchive,
    faArrowRotateRight,
    faArrowsRotate,
    faAt,
    faBan,
    faBell,
    faBolt,
    faBoxesStacked,
    faBoxOpen,
    faBriefcase,
    faBug,
    faBullseyeArrow,
    faCamera,
    faCertificate,
    faChartLineUp,
    faChartNetwork,
    faCircleCheck,
    faCircleExclamation,
    faCircleXmark,
    faClipboardList,
    faClose,
    faCloudArrowDown,
    faCloudArrowUp,
    faCopy,
    faCubes,
    faDebug,
    faDownload,
    faEdit,
    faFacePensive,
    faFileCertificate,
    faFileExport,
    faFilePlus,
    faGear,
    faGears,
    faInbox,
    faInfoCircle,
    faKey,
    faListRadio,
    faLock,
    faMapLocationDot,
    faMicrochip,
    faMinus,
    faMobileScreenButton,
    faObjectGroup,
    faObjectUngroup,
    faOtter,
    faPaperPlane,
    faPlus,
    faQuestionCircle,
    faRightFromBracket,
    faRightToBracket,
    faSearch,
    faSensorCloud,
    faShield,
    faShieldExclamation,
    faSignalBars,
    faSignalBarsSlash,
    faSignIn,
    faSignOut,
    faSirenOn,
    faSquareList,
    faSquareTerminal,
    faTableList,
    faTools,
    faUpload,
    faUser,
    faUserPlus,
    faUsers,
    faUsersCog,
    faXmark,
} from '@fortawesome/pro-regular-svg-icons';

import {
    faCaretLeft as solidFaCaretLeft,
    faCaretRight as solidFaCaretRight,
    faCheckCircle as solidFaCheckCircle,
    faPaperclip,
    faPauseCircle as solidFaPauseCircle,
    faPlayCircle as solidFaPlayCircle,
    faXmarkCircle as solidXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';

export interface IIconProps {
    iconPrefix?: IconPrefix;
    className?: string;
    iconClassName?: string;
    iconName: IconName;
    text?: string;
    size?: 'SMALL' | 'REGULAR' | 'LARGE' | 'LG' | 'X-LARGE';
    asPrefix?: boolean;
    danger?: boolean;
    spin?: boolean;
    onClick?: (event?: any) => void;
}

export interface IBadgeProps {
    className?: string;
    count?: React.ReactNode;
    overFlowCount?: number;
}

export interface IBadgeIconProps extends IIconProps {
    badge: IBadgeProps;
}

library.add(
    faEdit,
    faBan,
    faShieldExclamation,
    faSirenOn,
    faCircleCheck,
    faInfoCircle,
    faOtter,
    faFacePensive,
    faBug,
    faInbox,
    faUpload,
    faUser,
    faLock,
    faAt,
    faArrowRotateRight,
    faPlus,
    faMinus,
    faSearch,
    faChartNetwork,
    faListRadio,
    faBullseyeArrow,
    faCloudArrowUp,
    faSignIn,
    faSensorCloud,
    faArrowsRotate,
    faBolt,
    faTableList,
    faFilePlus,
    faSquareList,
    faFileExport,
    faBoxOpen,
    faSquareTerminal,
    faGears,
    faCircleXmark,
    faCloudArrowDown,
    faCamera,
    faObjectGroup,
    faObjectUngroup,
    faBriefcase,
    faUser,
    faCertificate,
    faChartLineUp,
    faRightFromBracket,
    faBell,
    faKey,
    faPaperPlane,
    faGear,
    faCircleCheck,
    faClipboardList,
    faCopy,
    faShield,
    faArchive,
    faMicrochip,
    faTools,
    faCubes,
    faFileCertificate,
    faMapLocationDot,
    faUsers,
    faDebug,
    faRightToBracket,
    faUserPlus,
    faSignOut,
    faMobileScreenButton,
    faSignalBars,
    faSignalBarsSlash,
    faCircleExclamation,
    faBoxesStacked,
    solidFaPlayCircle as IconDefinition,
    solidFaCheckCircle as IconDefinition,
    solidFaPauseCircle as IconDefinition,
    solidXmarkCircle as IconDefinition,
    solidFaCaretLeft as IconDefinition,
    solidFaCaretRight as IconDefinition,
    faAngleDown,
    faAngleRight,
    faBell,
    faXmark,
    faClose,
    faPaperclip as IconDefinition,
    faUsersCog,
    faQuestionCircle,
    faDownload,
);

interface ISizeMap {
    [sizeName: string]: { ICON: SizeProp, FONT: string };
}

const SIZE_MAP: ISizeMap = {
    SMALL: { ICON: '1x', FONT: '' },
    REGULAR: { ICON: '2x', FONT: '' },
    LARGE: { ICON: '4x', FONT: '' },
    LG: { ICON: 'lg', FONT: '' },
    'X-LARGE': { ICON: '6x', FONT: '' },
};

export function Icon(props: IIconProps): JSX.Element {
    const size = SIZE_MAP[props.size || 'REGULAR'];
    return (
        <div className={cx(iconCss.iconWrapper, {
            [iconCss.asPrefix]: props.asPrefix,
            [iconCss.danger]: props.danger
        }, props.className)}>
            {props.iconName ?
                <FontAwesomeIcon className={props.iconClassName} icon={[props.iconPrefix ?? 'far', props.iconName]}
                                 size={size.ICON} spin={props.spin} onClick={props.onClick}/> : null}
            {props.text ? <Typography.Text className={iconCss.label}>{props.text}</Typography.Text> : null}
        </div>
    );
}

export function BadgeIcon(props: IBadgeIconProps) {
    const size = SIZE_MAP[props.size || 'REGULAR'];

    const { badge } = props;
    return (
        <div className={cx(iconCss.iconWrapper, {
            [iconCss.asPrefix]: props.asPrefix,
            [iconCss.danger]: props.danger
        }, props.className)}>
            <Badge className={badge.className} count={badge.count} overflowCount={badge.overFlowCount}>
                {props.iconName ?
                    <FontAwesomeIcon className={props.iconClassName} icon={[props.iconPrefix ?? 'far', props.iconName]}
                                     size={size.ICON} spin={props.spin}/> : null}
                {props.text ? <Typography.Text className={iconCss.label}>{props.text}</Typography.Text> : null}
            </Badge>
        </div>
    );
}

export type { IconName };
