import * as React from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { storesContext, createStores, TStoresContext, initializeData } from './store';
import { Pages } from './pages';
import { LoadingComponent } from './pages/loading/loading';

const store = createStores();

@observer
export default class App extends React.Component<any, any, TStoresContext> {
    componentDidMount(): void {
        (async (): Promise<void> => {
            const authUser = await store.authStore.getUserAuthenticated();
            const isAutheticated = !!authUser;
            store.authStore.setIsAuthenticated(isAutheticated, authUser);
            if (isAutheticated) {
                await initializeData(store);
            }

            store.commonStore.finishLoading();
        })();
    }

    render(): JSX.Element {
        return store.commonStore.isLoading ? (
            <LoadingComponent title='Loading, please wait...' />
        ) : (
            <storesContext.Provider value={store}>
                <Router>
                    <React.StrictMode>
                        <Pages />
                    </React.StrictMode>
                </Router>
            </storesContext.Provider>
        );
    }
}
