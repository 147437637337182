import React from 'react';
import { observer } from 'mobx-react';
import { Modal, SearchField, IconTextButton, DangerAlert, showInformationDialog, Typography } from 'ui-lib';
import { getErrorMessage, getDisplayDeviceStatus, isNullOrEmpty, toDeviceInfo } from 'common-utils';
import { useStores } from '../../../store';
import { DevicesStore } from '../../../store/devices-store';
import { DEVICE_CONNECTION_STATUS, IDeviceAttributesResult, IDeviceInfo, ISearchDeviceInfo } from '../../../dto/device-dto';
import { AccountType, IAccount } from '../../../dto/access-management/account-dto';
import {
    DEFAULT_DEVICE_COLUMN,
    DEFAULT_EMPTY_TEXT_FOR_SEARCH,
    DevicesTableComponent,
    SEARCH_COLUMN,
} from './devices-table-component';
import componentCss from './component.css';
import { usePromiseMemo } from 'utils/common-utils';
import { isUndefined } from 'lodash';

export interface ISearchDevicesComponentProps {
    accountId: number;
    searchOptions: {
        key?: string;
        label?: string;
        placeholder?: string;
        allowClear?: boolean;
        columns?: SEARCH_COLUMN[];
    };
    table?: {
        width?: number;
        scroll?: {
            x?: number;
            y?: number;
        };
    };
}

interface ISearchDeviceInfoRow extends IDeviceInfo {
    accountId: number;
    firmwareVersion?: string;
}

export class SearchDevicesHandler {
    private readonly _deviceStore: DevicesStore;
    private readonly _searchColumns: SEARCH_COLUMN[];

    constructor(deviceStore: DevicesStore, searchColumns?: SEARCH_COLUMN[]) {
        this._deviceStore = deviceStore;
        this._searchColumns = searchColumns;
    }

    public async getAllDevices(account: IAccount, searchValue?: string): Promise<ISearchDeviceInfo[]> {
        if (account.accountType == AccountType.DPU) return [];
        const params = {};
        if (searchValue) params['searchValue'] = searchValue;
        if (this._searchColumns) {
            params['searchFields'] = this._searchColumns.map((item) =>
                item === SEARCH_COLUMN.UID ? 'deviceName' : item.toString(),
            );
        }
        return this._deviceStore.searchDevices(account.id, params);
    }
}

export const SearchDevicesComponent = observer((props: ISearchDevicesComponentProps): JSX.Element => {
    const { deviceStore, accountStore, deviceGroupsStore } = useStores();
    const account = accountStore.findAccount(props.accountId);

    const [devices, setDevices] = React.useState<ISearchDeviceInfo[]>([]);
    const [showDevicesResult, setShowDevicesResult] = React.useState(false);

    const deviceAttributesResult = usePromiseMemo<IDeviceAttributesResult[]>(undefined, async () => {
        if (!showDevicesResult) return undefined;
        try {
            const deviceGroups = deviceGroupsStore.getDevicesGroupDetails(props.accountId);
            if (isNullOrEmpty(deviceGroups)) return undefined;
            const results = await Promise.all(deviceGroups.filter(group => !!group).map(async group => deviceGroupsStore.getDeviceAttributes(props.accountId, group.deviceGroupName)));
            return results.flat();
        } catch (err) {
            setErrorMessage(`Error while fetching device statuses: ${getErrorMessage(err)}`);
            return [];
        }
    }, [showDevicesResult, deviceGroupsStore.allDeviceGroups, props.accountId]);

    const dataSource = React.useMemo<ISearchDeviceInfoRow[]>(() => {
        const toSearchDeviceInfoRow = (device: ISearchDeviceInfo, deviceAttributes?: IDeviceAttributesResult): ISearchDeviceInfoRow => {
            const status = deviceAttributes ? getDisplayDeviceStatus({ status: device.status, connectivity: deviceAttributes.connectivity }) : DEVICE_CONNECTION_STATUS.LOADING;
            return {
                ...toDeviceInfo(device),
                accountId: account?.id,
                firmwareVersion: device.firmwareVersion,
                status
            };
        };

        if (isNullOrEmpty(devices)) return [];
        if (isUndefined(deviceAttributesResult))
            return devices.map((device) => toSearchDeviceInfoRow(device));
        return devices.map(device => toSearchDeviceInfoRow(device, deviceAttributesResult.find(item => item.deviceName == device.deviceName)));
    }, [devices, deviceAttributesResult]);

    const [isLoadingDevices, setIsLoadingDevices] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const searchDevicesStore = React.useMemo(
        () => new SearchDevicesHandler(deviceStore, props.searchOptions?.columns),
        [],
    );

    const onSearch = async (searchValue: string) => {
        try {
            if (!searchValue.match('^([A-Za-z0-9_]+)$') && !(isNullOrEmpty(searchValue))) {
                return showInformationDialog({
                    modalType: 'error',
                    title: 'Error',
                    content: 'Please provide a valid Device UID, serial number or eSN.',
                });
            }
            if (!account) {
                return showInformationDialog({
                    modalType: 'error',
                    title: 'Error',
                    content: 'Cannot get the information for the selected account',
                });
            }
            setErrorMessage(null);
            setIsLoadingDevices(true);
            setShowDevicesResult(true);
            setDevices(await searchDevicesStore.getAllDevices(account, searchValue));
        } catch (err) {
            setErrorMessage(getErrorMessage(err));
        } finally {
            setIsLoadingDevices(false);
        }
    };

    const tableWidth = props.table?.width || 1200;
    const tableScroll = { x: tableWidth - 100 };
    const placeholder = props.searchOptions?.placeholder || 'Search Devices';

    return (
        <div className={componentCss.searchDevices}>
            {props.searchOptions.label && (
                <Typography.Text className={componentCss.label}>{props.searchOptions.label}</Typography.Text>
            )}
            <SearchField
                key={props.searchOptions?.key || 'searchField'}
                allowClear={props.searchOptions?.allowClear}
                placeholder={placeholder}
                onSearch={onSearch}
            />
            <Modal width={tableWidth} isVisible={showDevicesResult}>
                <React.Fragment>
                    {errorMessage ? <DangerAlert message={errorMessage} /> : null}
                    <DevicesTableComponent<ISearchDeviceInfoRow>
                        title={`Search Results on ${account?.name}`}
                        dataSource={dataSource}
                        loadingState={isLoadingDevices}
                    defaultSortColumn={{
                        key: DEFAULT_DEVICE_COLUMN.LAST_UPDATE,
                        sortOrder: 'descend',
                    }}
                    defaultEmptyText={DEFAULT_EMPTY_TEXT_FOR_SEARCH}
                    buildDeviceLinkProps={(record) => ({ accountId: record.accountId })}
                    displayStatus={true}
                    tableScroll={tableScroll}
                    extraItems={[
                        <IconTextButton
                            key='close'
                            type='primary'
                            label='Close'
                            onClick={() => setShowDevicesResult(false)}
                        />,
                    ]}
                    />
                </React.Fragment>
            </Modal>
        </div>
    );
});
