import * as React from 'react';
import { AssignDevicesStore } from 'store/assign-devices-store';
import { DangerTag, IconTextButton, Progress, SuccessTag, Typography, WarningTag, showConfirmDialog } from 'ui-lib';
import { IImportDeviceProps, MoveDeviceStatus } from 'dto/device-dto';
import { getErrorMessage } from 'common-utils';
import { AssignDeviceSummaryTable } from './assign-devices-summary-table';
import { observer } from 'mobx-react';

export interface IMoveDevicesSummaryProps {
    assignDeviceStore: AssignDevicesStore;
    deviceGroupName?: string;
    onSave?: () => Promise<unknown>;
    onClose: (hasChange?: boolean) => void;
}

enum ModalState {
    PREVIEW = 'PREVIEW',
    PROGRESS = 'PROGRESS',
    FINISH = 'FINISH'
}

const MOVE_DEVICE_STATUSES = Object.values(MoveDeviceStatus);

const isProgress = (state: ModalState): boolean => state === ModalState.PROGRESS;

export const MoveDevicesSummaryTable = observer((props: IMoveDevicesSummaryProps): JSX.Element => {
    const { assignDeviceStore, deviceGroupName } = props;
    const [deviceDataSource, setDeviceDataSource] = React.useState<IImportDeviceProps[]>([]);

    const [modalState, setModalState] = React.useReducer((_, state: ModalState): ModalState => {
        if (state === ModalState.PROGRESS) {
            assignDeviceStore.updateProgressPercent(0);
        }
        return state;
    }, ModalState.PREVIEW);

    React.useEffect(() => {
        setDeviceDataSource(
            [
                ...assignDeviceStore.toClientGroupDevicesList.map(item => ({...item, status: MoveDeviceStatus.REMOVE_FROM_GROUP})),
                ...assignDeviceStore.toSelectedGroupDevicesList.map(item => ({...item, status: MoveDeviceStatus.ADD_TO_GROUP})),
            ]
        );
    }, []);

    React.useEffect(() => {
        if (!isProgress(modalState)) {
            return;
        }
        setDeviceDataSource((dataSource) => {
            return dataSource.map(item => ({ ...item, status: MoveDeviceStatus.UPDATING }));
        });

        (async (): Promise<void> => {
            if (props.onSave) {
                await props.onSave();
            }
            try {
                const result = await assignDeviceStore.updateDeviceGroupForDevices(deviceGroupName);
                setDeviceDataSource(source => {
                    return assignDeviceStore.displayDeviceDataSource
                        .filter(item => source.some(device => device.UID === item.UID))
                        .map(item => {
                            if (result.succeeded.includes(item.UID)) {
                                return { ...item, status: MoveDeviceStatus.UPDATED };
                            }

                            const found = result.errors.find(err => err.identifier === item.UID);
                            if (found) {
                                return { ...item, status: MoveDeviceStatus.ERROR, comment: found.error };
                            }
                            return item;
                        });
                });
            } catch (err) {
                const errMsg = getErrorMessage(err);
                setDeviceDataSource(source => source.map(item => ({ ...item, status: MoveDeviceStatus.ERROR, comment: errMsg })));
            } finally {
                setModalState(ModalState.FINISH);
            }
        })();
    }, [modalState]);

    const extraItems: React.ReactNode[] = [];
    if (modalState === ModalState.PREVIEW) {
        extraItems.push(<IconTextButton
            key='update-btn'
            label='Update'
            onClick={(): void => showConfirmDialog({
                title: 'Confirmation',
                content: `Are you sure to update ${deviceDataSource.length} devices?`,
                okText: 'Yes',
                onOk: () => setModalState(ModalState.PROGRESS),
            })}
            iconName='plus'
            type='primary'
        />);
    } else if (modalState === ModalState.PROGRESS) {
        extraItems.push(<Progress
            key='progress'
            percent={assignDeviceStore.progressPercent}
            type='circle'
            extraProps={{ width: 40 }}
        />);
    } else {
        extraItems.push(null);
    }
    extraItems.push(<IconTextButton
        key='import-btn'
        label='Close'
        disabled={isProgress(modalState)}
        onClick={(): void => props.onClose(modalState === ModalState.FINISH)}
        iconName='cancel'
        type='default'
    />);

    return (
        <AssignDeviceSummaryTable
            clientAccountId={assignDeviceStore.clientAccount.accountId}
            header={<Typography.Title level={3}>Update devices</Typography.Title>}
            title={`Updating ${deviceDataSource.length} Devices`}
            dataSource={deviceDataSource}
            statusColumn={{
                filterKeys: MOVE_DEVICE_STATUSES,
                renderTag: (text: string): React.ReactNode => {
                    if (text === MoveDeviceStatus.ERROR) {
                        return <DangerTag value={text} />;
                    } else if (text === MoveDeviceStatus.REMOVE_FROM_GROUP) {
                        return <WarningTag value={text} />;
                    }
                    return <SuccessTag value={text} />;
                }
            }}
            extraItems={extraItems}
        />
    );
});
