import * as React from 'react';
import { Button as AntButton, Space, Tooltip as AntdTooltip } from 'antd';
import type {IconName} from '@fortawesome/fontawesome-common-types';
import { Icon } from '../icon';
import {IBaseButtonProps} from './base-button';
import cx from 'classnames';
import iconTextButtonCss from './icon-text-button.css';

export interface IIconTextButtonProps extends IBaseButtonProps {
    iconName?: IconName;
}

export function IconTextButton(props: IIconTextButtonProps) {

    const isLarge = props.size === 'LARGE';

    return (
        <AntdTooltip title={props.tooltip}>
            <AntButton
                type={props.type ? props.type : 'default'}
                htmlType={props.htmlType ? props.htmlType : 'button'}
                size={isLarge ? 'large' : null}
                loading={props.loading}
                className={cx(iconTextButtonCss.iconTextButton, props.className)}
                disabled={props.disabled}
                block={props.block}
                onClick={props.onClick}>
                    <Space size="small">
                        {props.iconName ? <Icon iconName={props.iconName} size="SMALL" asPrefix /> : null}
                        {props.label}
                    </Space>
            </AntButton>
        </AntdTooltip>
    );
}
