import { IResponseOptions } from 'api/assure-base-api-client';
import { BaseApiClient, IApiClientOptions } from './base-api.client';

export interface IConfirmForgotPasswordOptions {
    username: string;
    password: string;
    confirmationCode: string;
}

export class AuthApiClient extends BaseApiClient {
    constructor(options: IApiClientOptions) {
        super('auth', options);
    }

    public async login(email: string, password: string): Promise<IResponseOptions> {
        const requestOptions = {
            url: '/auth/login',
            params: {
                email: email,
                password: password,
            },
        };

        return this.post(requestOptions);
    }

    public async logout(): Promise<IResponseOptions> {
        return this.post({
            url: '/auth/logout',
        });
    }

    public async getUserAuthenticated(): Promise<IResponseOptions> {
        return this.get({
            url: '/auth/authenticated',
        });
    }

    public firstLogin(password: string): Promise<IResponseOptions> {
        const requestOptions = {
            url: '/auth/first-login',
            params: { newPassword: password },
        };

        return this.post(requestOptions);
    }

    public async forgotPassword(email: string): Promise<IResponseOptions> {
        const requestOptions = {
            url: '/auth/forgot-password',
            params: {
                email,
            },
        };

        return this.get(requestOptions);
    }

    public async confirmForgotPassword(params: IConfirmForgotPasswordOptions): Promise<IResponseOptions> {
        const requestOptions = {
            url: '/auth/confirm-forgot-password',
            params,
        };

        return this.put(requestOptions);
    }
}
