import React from 'react';

import repositoryCss from './repository.css';

import { PackageGroupsWrapper } from './package-groups';
import { PackagesWrapper } from './packages';
import { UploadPackagesWrapper } from './upload-packages';
export interface IErrorProps {
    key: number | string;
    message: string;
}

export enum IRepositoryComponent {
    PACKAGES = 'PACKAGES',
    UPLOAD_PACKAGES = 'UPLOAD_PACKAGES',
    MANAGE_PACKAGE_GROUPS = 'MANAGE_PACKAGE_GROUPS',
}

export const DPU_REPOSITORY_TYPES_SOURCE = [
    { value: 'ALM', label: 'ALM' },
    { value: 'SCR', label: 'SCR' },
    { value: 'VOS', label: 'VOS' },

];

interface IRepositoryContextProps {
    toPackageGroupsTable: () => void;
    toUploadPackage: () => void;
    toPackagesTable: () => void;
}

const repositoryContext = React.createContext<IRepositoryContextProps>(null);
export const useRepositoryContext = () => React.useContext(repositoryContext);

export const SoftwareRepositoryPage = () => {
    const [componentState, setComponentState] = React.useState<IRepositoryComponent>(IRepositoryComponent.PACKAGES);

    const navigationProps: IRepositoryContextProps = {
        toPackageGroupsTable: () => setComponentState(IRepositoryComponent.MANAGE_PACKAGE_GROUPS),
        toUploadPackage: () => setComponentState(IRepositoryComponent.UPLOAD_PACKAGES),
        toPackagesTable: () => setComponentState(IRepositoryComponent.PACKAGES),
    };

    return (
        <repositoryContext.Provider value={navigationProps}>
            <div className={repositoryCss.pane}>
                {componentState == IRepositoryComponent.PACKAGES && <PackagesWrapper />}
                {componentState == IRepositoryComponent.MANAGE_PACKAGE_GROUPS && <PackageGroupsWrapper />}
                {componentState == IRepositoryComponent.UPLOAD_PACKAGES && <UploadPackagesWrapper />}
            </div>
        </repositoryContext.Provider>
    );
};
