import {
    DEVICE_CONNECTION_STATUS,
    DEVICE_STATUS,
    IConnectionSummary,
    IDeviceInfo,
    ISearchDeviceInfo,
} from '../dto/device-dto';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { formatDate } from 'common-utils/date-utils';

dayjs.extend(duration);

export const MAX_TIMES_TO_RE_FETCH_DATA = 2;
export const TIME_BEFORE_RE_FETCH_IN_SECONDS = 15;
export const MAX_DEVICE_IDENTIFIER_LENGTH = 32;

export type DISPLAY_DEVICE_STATUS = Omit<DEVICE_STATUS, 'ASSIGNED'> | DEVICE_CONNECTION_STATUS;

export const DISPLAY_STATUS_LIST = [
    ...Object.values(DEVICE_STATUS).filter(v => v != DEVICE_STATUS.ASSIGNED),
    ...Object.values(DEVICE_CONNECTION_STATUS).filter(v => v != DEVICE_CONNECTION_STATUS.LOADING)
];

const LAST_ONLINE_STUB_MESSAGE = 'More than an hour ago';

export function getDisplayDeviceStatus(device: { status: DEVICE_STATUS; connectivity?: IConnectionSummary; }): string {
    switch (device.status) {
        case DEVICE_STATUS.UNASSIGNED:
        case DEVICE_STATUS.DECOMMISSIONED:
            return device.status;
        case DEVICE_STATUS.ASSIGNED:
        default:
            if (!device.connectivity) return DEVICE_CONNECTION_STATUS.LOADING;
            return device.connectivity?.connected ? DEVICE_CONNECTION_STATUS.ONLINE : DEVICE_CONNECTION_STATUS.OFFLINE;
    }
}

export function getDeviceLastOnline(connectivity: IConnectionSummary): string {
    if (connectivity.connected) {
        return formatDate(Date.now()).toString();
    } else if (connectivity.timestamp && connectivity.timestamp > 0) {
        return formatDate(connectivity.timestamp);
    } else {
        return LAST_ONLINE_STUB_MESSAGE;
    }
}

export function isDecommissionedDevice(status: DISPLAY_DEVICE_STATUS): boolean {
    return (status === DEVICE_STATUS.UNASSIGNED || status === DEVICE_STATUS.DECOMMISSIONED);
}

export function isOnlineDevice(status: DISPLAY_DEVICE_STATUS): boolean {
    return status === DEVICE_CONNECTION_STATUS.ONLINE;
}

export function formatElapsedTime(seconds: number): string {
    if (!seconds)
        return undefined;
    const duration = dayjs.duration(seconds, 'seconds');
    const build = (num: number): string => (num < 10 ? '0' : '') + Math.round(num);
    return `${build(duration.days())} ${build(duration.hours())}:${build(duration.minutes())}:${build(duration.seconds())}`;
}

export function toDeviceInfo(device: ISearchDeviceInfo): IDeviceInfo {
    return {
        UID: device.deviceName,
        deviceGroupLabel: device.deviceGroupLabel,
        esn: device.esn,
        type: device.deviceTypeName,
        lastUpdated: device.lastJobSuccessAt || 0,
        serialNumber: device.serialNumber || '',
        status: device.status,
    };
}

export const getMovingToDeviceGroupMessage = (count: number) => (
    `${count > 1 ? `${count} devices are` : '1 device is'} still in the process of moving to the new device group.`
);
