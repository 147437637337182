import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { isUndefined, orderBy } from 'lodash';
import {
    BasicCard,
    BasicCollapse,
    Icon,
    IconButton,
    IconTextButton,
    notification,
    RowLayout,
    Select,
    showInformationDialog,
    Skeleton,
    Statistic,
    Typography,
} from 'ui-lib';
import {
    useAuthStore,
    useDeviceGroupsStore,
    useDeviceStore,
    useNotificationsStore,
    useRoutesStore,
    useStores
} from '../../../../store';
import { SearchDevicesComponent } from '../../components/search-devices-component';
import { DEFAULT_DEVICE_COLUMN, DevicesTableComponent, ISelectedRows } from '../../components/devices-table-component';
import {
    DEVICE_CONNECTION_STATUS,
    IDeviceAttributesResult,
    IDeviceInfo,
    IDeviceInventoryStateOptions,
    ISearchDeviceInfo
} from '../../../../dto/device-dto';
import { IDeviceGroupOptions } from '../../../../dto/device-group-dto';
import { AccountType } from '../../../../dto/access-management/account-dto';
import { REPORT_DATASOURCE } from '../../admin/reports/reports';
import deviceInventoryCss from './devices.css';
import { getDisplayDeviceStatus, toDeviceInfo } from '../../../../common-utils/device-utils';
import { buildNavigatePath } from '../../utils';
import { usePromiseMemo } from '../../../../utils/common-utils';
import { EXPORT_TYPES, exportFile, getErrorMessage, isNullOrEmpty } from 'common-utils';
import { showConfirmation, showNotification } from './utils';
import { MESSAGE_TYPE, NOTIFICATION_TYPE } from 'dto/notifications-dto';
import { ADMIN_ROUTE_CODES } from 'pages/private/admin';
import { Tooltip } from 'ui-lib/components/data-display';
import { DEVICES_ROUTE_CODES } from 'pages/private/devices';
import { DevicesStore } from '../../../../store/devices-store';

interface IBasicInformationProps {
    deviceGroups: number;
    devices: {
        numberOfCommissionedDevices: number;
        numberOfProvisionedDevices: number;
    },
    onlineDevices: number;
    offlineDevices: number;
    errorReports: number;
    reports: number;
    maintenanceUpdates: number;
}

interface IClientGroupsInformation {
    deviceGroupName: string;
    deviceGroupLabel: string;
    devices: IDeviceInfo[];
    online: number;
    offline: number;
}

interface IClientCollapseComponentProps {
    deviceStore: DevicesStore;
    managedDeviceGroupDetails: IDeviceGroupOptions[];
    devices: ISearchDeviceInfo[];
    endpointAccountId: number;
    activeKey: any;
    onSelectedRowsChanged: (value: any) => void;
}

interface IDeviceGroupCollapseProps {
    endpointAccountId: number;
    clientDeviceGroupName: string;
    childrenGroups: IClientGroupsInformation[];
    activeKey: any;
    onSelectedRowsChanged: (value: any) => void;
}

interface IDevicesTableOfGroupProps {
    endpointAccountId: number;
    clientDeviceGroupName: string;
    deviceGroupName: string;
    devices: IDeviceInfo[];
    onSelectedRowsChanged: (value: any) => void;
}

interface ISelectedDevice {
    [key: string]: string[] | number[];
}

interface IBasicDeviceProps {
    onlineDevices?: number;
    offlineDevices?: number;
    commissioned?: ISearchDeviceInfo[];
    provisioned?: ISearchDeviceInfo[];
}

export const DevicesInventoryDashboard: React.FC = observer(() => {
    const { currentUser } = useAuthStore();
    const deviceStore = useDeviceStore();
    const notificationsStore = useNotificationsStore();
    const deviceGroupsStore = useDeviceGroupsStore();
    const [numberOfOnlineDevices, setNumberOfOnlineDevices] = React.useState<number>(0);
    const stores = useStores();
    const commonStore = stores.commonStore;

    const [loading, setLoading] = React.useState<boolean>(false);
    const state: IDeviceInventoryStateOptions = useLocation().state || {};
    const [selectedDevices, setSelectedDevices] = React.useState<ISelectedDevice>({});

    const varOrClientAccountId = commonStore.selectedOrganisation;

    const managedDeviceGroupDetails = React.useMemo(
        () => deviceGroupsStore.getDevicesGroupDetails(varOrClientAccountId)?.filter((item) => !!item),
        [varOrClientAccountId, deviceGroupsStore.allDeviceGroups],
    );

    const devices = usePromiseMemo<IBasicDeviceProps>({ provisioned: [], commissioned: [] }, async () => {
            try {
                if (!varOrClientAccountId) return { commissioned: [], provisioned: [] };
                setLoading(true);
                setSummaries(undefined);
                const isClient = currentUser.accountType == AccountType.CLIENT;
                return await deviceStore.getAllDevices(varOrClientAccountId, { includeProvisionedDevices: !isClient });
            } catch (error) {
                notification.error({
                    message: getErrorMessage(error),
                });
                return { commissioned: [], provisioned: [] };
            } finally {
                setLoading(false);
            }
        },
        [varOrClientAccountId],
    );
    const [deviceSummaries, setSummaries] = React.useState<{
        [deviceName: string]: IDeviceAttributesResult
    }>(undefined);
    React.useEffect(() => {
        (async () => {
            const clientGroups = managedDeviceGroupDetails.filter(item => item.deviceGroupType == 'isClient');
            if (isNullOrEmpty(clientGroups)) return;
            try {
                const deviceAttributesByGroups = {};
                await Promise.all(clientGroups.map(async group => {
                    const result = await deviceGroupsStore.getDeviceAttributes(varOrClientAccountId, group.deviceGroupName);
                    Object.assign(deviceAttributesByGroups, result.reduce((obj, item) => {
                        obj[item.deviceName] = item;
                        return obj;
                    }, {}));
                }));

                setSummaries(deviceAttributesByGroups);
            } catch (error) {
                notification.error({
                    message: getErrorMessage(error),
                });
            }
        })();
    }, [managedDeviceGroupDetails]);

    const commissionedDevices = React.useMemo<ISearchDeviceInfo[]>(() => {
        if (isNullOrEmpty(devices.commissioned)) return [];
        const buildDeviceStatusFn = (() => {
            if (!deviceSummaries)
                return (device: ISearchDeviceInfo) => ({
                    status: getDisplayDeviceStatus({ status: device.status }),
                });

            return (device: ISearchDeviceInfo) => {
                const deviceSummary = deviceSummaries[device.deviceName];

                return {
                    status: getDisplayDeviceStatus({
                        status: device.status,
                        connectivity: deviceSummary?.connectivity
                    }),
                };
            };
        })();

        return devices.commissioned.map((device => ({ ...device, ...(buildDeviceStatusFn(device) as any) })));
    }, [deviceSummaries, devices]);

    React.useEffect(() => {
        setNumberOfOnlineDevices(commissionedDevices.filter(item => item.status as any == DEVICE_CONNECTION_STATUS.ONLINE).length);
    }, [commissionedDevices]);

    const totalDeviceGroups = managedDeviceGroupDetails.reduce((total, group) => {
        return total + (isNullOrEmpty(group.childrenGroups) ? 0 : group.childrenGroups.length);
    }, 0);

    const notificationRestart = (title: string, message: string, description?: string): Promise<any> => {
        showNotification('info', message, description);
        return notificationsStore.create({
            content: {
                title,
                text: message,
                messageType: MESSAGE_TYPE.DEVICE,
            },
            type: NOTIFICATION_TYPE.PERSONAL,
            receiverUserUuid: currentUser.uuid,
            sender: currentUser.userName,
        });
    };

    return (
        <React.Fragment>
            <div className={deviceInventoryCss.deviceInventory}>
                <div className={deviceInventoryCss.label}>
                    <Typography.Text>The inventory lists all the devices that you can manage.</Typography.Text>
                </div>
                <div className={deviceInventoryCss.organisation}>
                    <SearchDevicesComponent
                        accountId={varOrClientAccountId}
                        key="searchDevicesComponent"
                        searchOptions={{ label: 'Search devices:', placeholder: 'Search UID, serial number or eSN' }}
                    />
                    {currentUser.accountType === AccountType.DPU && (
                        <Select
                            className={deviceInventoryCss.select}
                            code="organisation"
                            placeholder="Organisation"
                            selectedValue={commonStore.selectedOrganisation}
                            dataSource={commonStore.organisations}
                            onChange={(value: number): void => commonStore.setSelectedOrganisation(value)}
                            disabled={loading}
                        />
                    )}
                </div>
                <Skeleton loading={loading}>
                    <BasicInformation
                        deviceGroups={totalDeviceGroups}
                        devices={{
                            numberOfCommissionedDevices: commissionedDevices?.length ?? 0,
                            numberOfProvisionedDevices: devices.provisioned?.length,
                        }}
                        onlineDevices={numberOfOnlineDevices}
                        offlineDevices={commissionedDevices?.length - numberOfOnlineDevices || 0}
                        errorReports={0}
                        reports={REPORT_DATASOURCE.length}
                        maintenanceUpdates={notificationsStore.releaseUpcomingUpdates.length}
                    />
                    <IconTextButton
                        className={deviceInventoryCss.rightButton}
                        label="Restart all"
                        type="primary"
                        onClick={(): any => {
                            const title = 'Restart all selected devices';
                            if (
                                !Object.values(selectedDevices).some(
                                    (deviceNames: string[]) => !isNullOrEmpty(deviceNames),
                                )
                            )
                                return showInformationDialog({
                                    modalType: 'info',
                                    title,
                                    content: 'No selected device',
                                });

                            return showConfirmation('Do you want restart all selected devices?', (): Promise<any> => {
                                if (!selectedDevices) return;
                                return Promise.all(
                                    Object.values(selectedDevices).map(async (deviceNames: string[]) => {
                                        if (isNullOrEmpty(deviceNames)) return;
                                        notificationRestart(
                                            title,
                                            'Restarting all selected devices.',
                                            'Please wait...',
                                        );
                                        await deviceStore.triggerRemoteCommand(varOrClientAccountId, {
                                            commandCode: 'reboot',
                                            targetDeviceNames: deviceNames,
                                        });
                                        notificationRestart(title, 'Restart all selected devices has been completed.');
                                    }),
                                );
                            });
                        }}
                    />
                    <ClientCollapseComponent
                        managedDeviceGroupDetails={managedDeviceGroupDetails}
                        devices={commissionedDevices}
                        endpointAccountId={varOrClientAccountId}
                        activeKey={state.activeKey}
                        onSelectedRowsChanged={setSelectedDevices}
                        deviceStore={deviceStore}
                    />
                </Skeleton>
            </div>
        </React.Fragment>
    );
});

const BasicInformation = (props: IBasicInformationProps): JSX.Element => {
    const navigate = useNavigate();
    const routeStore = useRoutesStore();
    const maintenanceUpdatesRoute = routeStore.getRoute(ADMIN_ROUTE_CODES.MAINTENANCE_UPDATES);
    const deviceGroupsRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.DEVICE_GROUPS);
    const reportRoute = routeStore.getRoute(ADMIN_ROUTE_CODES.REPORTS);

    const items = [
        <BasicCard
            key="device-groups"
            className={deviceInventoryCss.basicIcon}
            title={<Icon iconName="object-ungroup"/>}
            size="small"
            contents={
                <Statistic
                    className={deviceInventoryCss.statisticBasic}
                    value={props.deviceGroups}
                    suffix="Device groups"
                    valueStyle={{ color: '#2B6777' }}
                />
            }
            onClick={(): void => navigate(buildNavigatePath(deviceGroupsRoute.path))}
        />,
        <BasicCard
            key="all-devices"
            className={deviceInventoryCss.basicIcon}
            title={<Icon iconName="mobile-screen-button"/>}
            size="small"
            contents={
                <Statistic
                    className={deviceInventoryCss.statisticBasic}
                    value={''}
                    formatter={(): React.ReactNode => {
                        const devices = props.devices;
                        if (isUndefined(devices.numberOfProvisionedDevices)) return devices.numberOfCommissionedDevices;
                        return (
                            <span>
                                <Tooltip overlay="Commissioned Devices">{devices.numberOfCommissionedDevices}</Tooltip>/<Tooltip
                                overlay="Total Devices">{devices.numberOfCommissionedDevices + devices.numberOfProvisionedDevices}</Tooltip>
                            </span>
                        );
                    }}
                    suffix="Devices"
                    valueStyle={{ color: '#2B6777' }}
                />
            }
        />,
        <BasicCard
            key="device-offline"
            className={deviceInventoryCss.offlineIcon}
            title={<Icon iconName="signal-bars-slash"/>}
            size="small"
            contents={
                <Statistic
                    className={deviceInventoryCss.statisticBasic}
                    value={props.offlineDevices}
                    suffix="Offline"
                    valueStyle={{ color: '#EC6C4C' }}
                />
            }
        />,
        <BasicCard
            key="device-online"
            className={deviceInventoryCss.onlineIcon}
            title={<Icon iconName="signal-bars"/>}
            size="small"
            contents={
                <Statistic
                    className={deviceInventoryCss.statisticBasic}
                    value={props.devices.numberOfCommissionedDevices - props.offlineDevices}
                    suffix="Online"
                    valueStyle={{ color: '#52AB98' }}
                />
            }
        />,
        <BasicCard
            key="reports"
            className={deviceInventoryCss.basicIcon}
            title={<Icon iconName="chart-line-up"/>}
            size="small"
            contents={
                <Statistic
                    className={deviceInventoryCss.statisticBasic}
                    value={props.reports}
                    suffix="Reports"
                    valueStyle={{ color: '#2B6777' }}
                />
            }
            onClick={(): void => navigate(buildNavigatePath(reportRoute.path))}
        />,
        <BasicCard
            key="maintenance-updates"
            className={deviceInventoryCss.basicIcon}
            title={<Icon iconName="circle-info"/>}
            size="small"
            contents={
                <Statistic
                    className={deviceInventoryCss.statisticBasic}
                    value={props.maintenanceUpdates}
                    suffix="Maintenance updates"
                    valueStyle={{ color: '#2B6777' }}
                />
            }
            onClick={(): void => navigate(buildNavigatePath(maintenanceUpdatesRoute.path))}
        />,
    ];

    return (
        <div className={deviceInventoryCss['deviceInventory-statistic-container']}>
            <RowLayout gutter={8} childItems={items} childCols={[4, 4, 4, 4, 4, 4]}/>
        </div>
    );
};

const DevicesTableOfGroupComponent = observer((props: IDevicesTableOfGroupProps) => {
    const navigate = useNavigate();
    const state: IDeviceInventoryStateOptions = useLocation().state || {};
    const routeStore = useRoutesStore();
    const [selectedRows, setSelectedRows] = React.useState<ISelectedRows>();
    const [filters, setFilters] = React.useState<{ [key: string]: string[] }>(
        (state.accountId === props.endpointAccountId && state.activeKey.clientDeviceGroupName === props.clientDeviceGroupName && state.activeKey.deviceGroup === props.deviceGroupName)
            ? (state.filters || {})
            : {}
    );

    return (
        <DevicesTableComponent
            title={<div className={deviceInventoryCss.title}>{'All Devices'}</div>}
            defaultSortColumn={{
                key: DEFAULT_DEVICE_COLUMN.LAST_UPDATE,
                sortOrder: 'descend',
            }}
            dataSource={props.devices}
            rowSelection={{
                selectedRows,
                onChange: (selectedRows: ISelectedRows): void => {
                    setSelectedRows(selectedRows);
                    props.onSelectedRowsChanged((value: ISelectedDevice) => {
                        const copiedData = Object.assign({}, value);
                        copiedData[props.deviceGroupName] = selectedRows.selectedKeys;
                        return copiedData;
                    });
                },
            }}
            buildDeviceLinkProps={_ => ({
                accountId: props.endpointAccountId,
                state: {
                    activeKey: {
                        clientDeviceGroupName: props.clientDeviceGroupName,
                        deviceGroup: props.deviceGroupName,
                    },
                    filters: filters,
                },
            })}
            displayStatus={true}
            hiddenDefaultColumns={[DEFAULT_DEVICE_COLUMN.DEVICE_GROUP]}
            onTableChange={(pagination: any, filters: any, sorter: any, extra: any) => {
                setFilters(filters || {});
            }}
            defaultFilters={filters}
        />
    );
});

const DeviceGroupCollapseComponent = (props: IDeviceGroupCollapseProps): JSX.Element => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [deviceGroupDetails, setDeviceGroupDetails] = React.useState([]);
    React.useEffect(() => {
        setLoading(true);
        if (isNullOrEmpty(props.childrenGroups)) {
            setLoading(false);
            return;
        }

        const panels = props.childrenGroups.map((group) => {
            const groupName = group.deviceGroupLabel || group.deviceGroupName;
            const DeviceGroupCollapseHeader = (): JSX.Element => {
                return (
                    <React.Fragment>
                        <Icon iconName="object-ungroup" size="SMALL"/>
                        <span className={deviceInventoryCss.deviceGroupCollapseTitleHeader}>{groupName}</span>
                        <span>
                            ({group.online}/{group.devices.length} Online)
                        </span>
                    </React.Fragment>
                );
            };

            return {
                header: <DeviceGroupCollapseHeader/>,
                key: `${group.deviceGroupName}`,
                content: (
                    <DevicesTableOfGroupComponent
                        endpointAccountId={props.endpointAccountId}
                        clientDeviceGroupName={props.clientDeviceGroupName}
                        deviceGroupName={group.deviceGroupName}
                        devices={group.devices}
                        onSelectedRowsChanged={props.onSelectedRowsChanged}
                    />
                ),
            };
        });
        setDeviceGroupDetails(panels);
        setLoading(false);
    }, [props.childrenGroups]);

    return (
        <Skeleton loading={loading}>
            <BasicCollapse
                className={deviceInventoryCss.deviceGroupCollapse}
                defaultActiveKey={props.activeKey?.deviceGroup}
                expandIconPosition="start"
                panels={deviceGroupDetails}
            />
        </Skeleton>
    );
};

const ClientCollapseComponent = (props: IClientCollapseComponentProps): JSX.Element => {
    const { endpointAccountId, managedDeviceGroupDetails, devices } = props;
    const [loading, setLoading] = React.useState<string[]>([]);

    const getNumberOfDevices = (group: IClientGroupsInformation): number => isNullOrEmpty(group?.devices) ? 0 : group.devices.length;
    const _buildDeviceGroupInformation = (deviceGroup: IDeviceGroupOptions): IClientGroupsInformation => {
        let deviceOnline = 0;
        const devicesOfGroup = (devices ?? [])
            .filter((device) => device.deviceGroupNames.at(-1) === deviceGroup.deviceGroupName)
            .map((device) => {
                if (device.status === DEVICE_CONNECTION_STATUS.ONLINE.toString()) deviceOnline++;
                return toDeviceInfo(device);
            });

        const isClientGroup = (deviceGroup: IDeviceGroupOptions): boolean => deviceGroup.deviceGroupType === 'isClient';

        return {
            deviceGroupName: isClientGroup(deviceGroup)
                ? `${deviceGroup.deviceGroupName}-NoGroup`
                : deviceGroup.deviceGroupName,
            deviceGroupLabel: isClientGroup(deviceGroup) ? 'No Device Group' : deviceGroup.deviceGroupLabel,
            devices: devicesOfGroup,
            online: deviceOnline,
            offline: devicesOfGroup.length - deviceOnline,
        };
    };

    const clientGroups = orderBy(
        managedDeviceGroupDetails?.filter((group) => group.deviceGroupType === 'isClient'),
        [(group): string => (group.deviceGroupLabel || group.deviceGroupName).toLowerCase()],
    );

    const onDownloadButtonClick = async (accountUuid: string, accountName: string) => {
        try {
            const result = await props.deviceStore.getDerivationIds(endpointAccountId, accountUuid);
            exportFile(
                `${accountName}-derivation-ids.csv`,
                result.derivationIds.join('\n'),
                EXPORT_TYPES.CSV.type,
            );
        } catch (err) {
            return notification.error({
                message: getErrorMessage(err),
                placement: 'topRight',
            });
        }
    };

    const clientPanels = clientGroups?.map((clientGroup) => {
        const noGroups = _buildDeviceGroupInformation(clientGroup);
        const childGroupsInfo = orderBy(clientGroup.childrenGroups?.map(_buildDeviceGroupInformation), [
            (group): string => (group.deviceGroupLabel || group.deviceGroupName).toLowerCase(),
        ]);
        const totalDevicesNumber = childGroupsInfo.reduce(
            (total, group) => total + getNumberOfDevices(group),
            getNumberOfDevices(noGroups),
        );

        childGroupsInfo.unshift(noGroups);
        const clientHeader = (
            <div>
                <span className={deviceInventoryCss.clientCollapseTitleHeader}>{clientGroup.deviceGroupLabel}</span>
                <span>(Total:{' '}{totalDevicesNumber})</span>
            </div>
        );

        return {
            header: clientHeader,
            key: `${clientGroup.deviceGroupName}`,
            content: (
                <DeviceGroupCollapseComponent
                    clientDeviceGroupName={clientGroup.deviceGroupName}
                    childrenGroups={childGroupsInfo}
                    endpointAccountId={endpointAccountId}
                    activeKey={props.activeKey}
                    onSelectedRowsChanged={props.onSelectedRowsChanged}
                />
            ),
            extra: totalDevicesNumber > 0 ? (
                <IconButton
                    key="downloadDerivationIds"
                    iconName="download"
                    onClick={async (e) => {
                        e.stopPropagation();
                        if (loading.includes(clientGroup.accountUuid)) {
                            return false;
                        }
                        setLoading(prev => [...prev, clientGroup.accountUuid]);
                        await onDownloadButtonClick(clientGroup.accountUuid, clientGroup.deviceGroupName);
                        setLoading(prev => prev.filter(accountUuid => accountUuid !== clientGroup.accountUuid));
                    }}
                    loading={loading.includes(clientGroup.accountUuid)}
                    type="link"
                    className={deviceInventoryCss.downloadIcon}
                />
            ) : null,
        };
    });

    return (
        <BasicCollapse
            className={deviceInventoryCss.clientCollapse}
            defaultActiveKey={props.activeKey?.clientDeviceGroupName}
            expandIconPosition="start"
            panels={clientPanels}
        />
    );
};
