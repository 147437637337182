import { IRoutes } from 'core';

export const PRIVATE_ROUTES: IRoutes = {
    DEVICES: {
        mainMenuOnClick: true,
        hasMainMenuEntry: true,
        path: '/devices',
        label: 'Devices',
        subRouteCodes: [
            'DEVICES_INVENTORY',
            'DEVICES_DEVICE_GROUPS',
            'DEVICES_COMMISSIONING_WIZARD',
            'DEVICES_COMMISSIONING_UPLOAD',
            'DEVICES_SHELL_COMMAND',
            'DEVICES_FILE_TRANSFER',
            'DEVICES_DEVICE_RESET',
            'DEVICES_DECOMMISSIONING',
        ],
        permissionCodes: [
            'Device Inventory',
            'Commissioning Wizard',
            'Shell Command',
            'File Transfer',
            'Remote Command',
            'Decommissioning',
            'Device Groups',
            'Reset Device',
        ],
    },
    DEVICES_INVENTORY: {
        path: '/devices/inventory',
        label: 'Inventory',
        iconName: 'square-list',
        permissionCodes: ['Device Inventory'],
    },
    DEVICES_COMMISSIONING_WIZARD: {
        path: '/devices/commissioning-wizard',
        label: 'Commissioning',
        iconName: 'box-open',
        permissionCodes: ['Commissioning Wizard'],
    },
    DEVICES_COMMISSIONING_UPLOAD: {
        path: '/devices/commissioning-upload',
        label: 'Commissioning upload',
        iconName: 'boxes-stacked',
        permissionCodes: ['Commissioning Upload'],
    },
    DEVICES_COMMISSIONING_UPLOAD_REVIEW: {
        path: '/devices/commissioning-upload-review',
        label: 'Commissioning upload review',
        permissionCodes: ['Commissioning Upload'],
    },
    DEVICES_SHELL_COMMAND: {
        path: '/devices/shell-command',
        label: 'Shell command',
        iconName: 'square-terminal',
        permissionCodes: ['Shell Command'],
    },
    DEVICES_FILE_TRANSFER: {
        path: '/devices/file-transfer',
        label: 'File transfer',
        iconName: 'file-export',
        permissionCodes: ['File Transfer'],
    },
    DEVICES_DECOMMISSIONING: {
        path: '/devices/decommissioning',
        label: 'Decommissioning',
        iconName: 'circle-xmark',
        permissionCodes: ['Decommissioning'],
    },
    DEVICES_DETAILS: {
        path: '/devices/:uid',
        label: 'Device details',
        permissionCodes: ['Device Details'],
    },
    DEVICES_DEVICE_GROUPS: {
        path: '/devices/device-groups',
        label: 'Device groups',
        iconName: 'object-ungroup',
        permissionCodes: ['Device Groups'],
    },
    DEVICES_DEVICE_RESET: {
        path: '/devices/reset',
        label: 'Device reset',
        iconName: 'microchip',
        permissionCodes: ['Reset Device'],
    },

    SOFTWARE_MANAGEMENT: {
        mainMenuOnClick: true,
        hasMainMenuEntry: true,
        path: '/software',
        label: 'Software',
        subRouteCodes: [
            'SOFTWARE_MANAGEMENT_DEPLOYMENTS_VIEW',
            'SOFTWARE_MANAGEMENT_REPOSITORY',
            'SOFTWARE_MANAGEMENT_SNAPSHOTS_VIEW',
            'SOFTWARE_MANAGEMENT_DEPLOYMENTS_GROUPS_VIEW',
        ],
        permissionCodes: [
            'Deployments View',
            'Deployments Modify',
            'Repository',
            'Snapshots View',
            'Snapshots Modify',
            'Deployment Groups View',
            'Deployment Groups Modify',
        ],
    },
    SOFTWARE_MANAGEMENT_DEPLOYMENTS_VIEW: {
        path: '/software/deployments',
        label: 'Deployments',
        iconName: 'cloud-arrow-down',
        permissionCodes: ['Deployments View'],
    },
    SOFTWARE_MANAGEMENT_DEPLOYMENTS_MODIFY: {
        path: '/software/deployments',
        label: 'Deployments',
        iconName: 'cloud-arrow-down',
        permissionCodes: ['Deployments Modify'],
    },
    SOFTWARE_MANAGEMENT_DEPLOYMENT_ADD: {
        path: '/software/deployment/add-deployment',
        label: 'Add deployment',
        iconName: 'cloud-arrow-down',
        permissionCodes: ['Create Deployment'],
    },
    SOFTWARE_MANAGEMENT_REPOSITORY: {
        path: '/software/repository',
        label: 'Repository',
        iconName: 'upload',
        permissionCodes: ['Repository'],
    },
    SOFTWARE_MANAGEMENT_SNAPSHOTS_VIEW: {
        path: '/software/snapshots',
        label: 'Releases',
        iconName: 'camera',
        permissionCodes: ['Snapshots View'],
    },
    SOFTWARE_MANAGEMENT_SNAPSHOTS_MODIFY: {
        path: '/software/snapshots',
        label: 'Releases',
        iconName: 'camera',
        permissionCodes: ['Snapshots Modify'],
    },
    SOFTWARE_MANAGEMENT_DEPLOYMENTS_GROUPS_VIEW: {
        path: '/software/deployment-groups',
        label: 'Deployment groups',
        iconName: 'object-group',
        permissionCodes: ['Deployment Groups View'],
    },
    SOFTWARE_MANAGEMENT_DEPLOYMENTS_GROUPS_MODIFY: {
        path: '/software/deployment-groups',
        label: 'Deployment groups',
        iconName: 'object-group',
        permissionCodes: ['Deployment Groups Modify'],
    },

    KEYS: {
        mainMenuOnClick: true,
        hasMainMenuEntry: true,
        path: '/keys',
        label: 'Keys',
        subRouteCodes: ['KEYS_INVENTORY', 'KEYS_DISTRIBUTION', 'KEYS_VERIFY'],
        permissionCodes: ['Keys Inventory', 'Keys Distribution', 'Verify Keys'],
    },
    KEYS_INVENTORY: {
        path: '/keys/inventory',
        label: 'Inventory',
        iconName: 'key',
        permissionCodes: ['Keys Inventory'],
    },
    KEYS_DISTRIBUTION: {
        path: '/keys/distribution',
        label: 'Distribution',
        iconName: 'paper-plane',
        permissionCodes: ['Keys Distribution'],
    },
    KEYS_VERIFY: {
        path: '/keys/kcv',
        label: 'Verify keys',
        iconName: 'square-list',
        permissionCodes: ['Verify Keys'],
    },

    ADMIN: {
        mainMenuOnClick: true,
        hasMainMenuEntry: true,
        path: '/admin',
        label: 'Admin',
        subRouteCodes: [
            'ADMIN_CREATE_META_PACKAGE',
            'ADMIN_MAINTENANCE_UPDATES',
            'ADMIN_REPORTS',
            'ADMIN_CONFIGURATION_SIGN',
            'ADMIN_USERS',
            'ADMIN_ACCOUNTS',
            'ADD_NOTIFICATIONS',
            'ADMIN_USER_ROLE_PERMISSIONS',
        ],
        permissionCodes: [
            'Settings',
            'Maintenance Updates',
            'Add Notifications',
            'Reports',
            'Configuration Signing',
            'Accounts',
            'Users',
            'User Role Permissions',
        ],
    },
    ADMIN_CREATE_META_PACKAGE: {
        path: '/admin/metapackage',
        label: 'Create meta package',
        iconName: 'briefcase',
        permissionCodes: ['Create meta package'],
    },
    ADMIN_MAINTENANCE_UPDATES: {
        path: '/admin/maintenance-updates',
        label: 'Maintenance updates',
        iconName: 'circle-info',
        permissionCodes: ['Maintenance Updates'],
    },
    ADD_NOTIFICATIONS: {
        path: '/admin/notifications',
        label: 'Add notifications',
        iconName: 'circle-info',
        permissionCodes: ['Add Notifications'],
    },
    ADMIN_ACCOUNTS: {
        path: '/admin/accounts',
        label: 'Accounts',
        iconName: 'briefcase',
        permissionCodes: ['Accounts'],
    },
    ADMIN_ACCOUNTS_ADD: {
        path: '/admin/accounts/add',
        label: 'Add account',
        permissionCodes: ['Accounts'],
    },
    ADMIN_ACCOUNTS_EDIT: {
        path: '/admin/accounts/edit/:id',
        label: 'Edit account',
        permissionCodes: ['Accounts'],
    },
    ADMIN_USERS: {
        path: '/admin/users',
        label: 'Users',
        iconName: 'user',
        permissionCodes: ['Users'],
    },
    ADMIN_USERS_ADD: {
        path: '/admin/users/add',
        label: 'Add user',
        permissionCodes: ['Users'],
    },
    ADMIN_USERS_EDIT: {
        path: '/admin/users/edit/:id',
        label: 'Edit user',
        permissionCodes: ['Users'],
    },
    ADMIN_CONFIGURATION_SIGN: {
        path: '/admin/configuration-signing',
        label: 'Configuration signing',
        iconName: 'certificate',
        permissionCodes: ['Configuration Signing'],
    },
    ADMIN_REPORTS: {
        path: '/admin/reports',
        label: 'Reports',
        iconName: 'chart-line-up',
        permissionCodes: ['Reports'],
    },
    // ADMIN_REPORTS_OPERATIONAL: {
    //     path: '/admin/reports/operational',
    //     label: 'Operational report',
    //     permissionCodes: ['Reports'],
    // },
    ADMIN_REPORTS_DEPLOYMENTS: {
        path: '/admin/reports/deployments',
        label: 'Deployments report',
        permissionCodes: ['Reports'],
    },
    ADMIN_REPORTS_FIRMWARE_VERSIONS: {
        path: '/admin/reports/firmware-versions',
        label: 'Firmware versions report',
        permissionCodes: ['Reports'],
    },
    ADMIN_REPORTS_SNAPSHOT_COMPARISON: {
        path: '/admin/reports/snapshot-comparison',
        label: 'Release comparison report',
        permissionCodes: ['Reports'],
    },
    ADMIN_USER_ROLE_PERMISSIONS: {
        path: '/admin/role-permissions',
        label: 'User role permissions',
        permissionCodes: ['User Role Permissions'],
        iconName: 'users-cog',
    },

    FIRST_LOGIN: {
        path: '/first-login',
        label: 'First login',
    },
};
