import * as React from 'react';
import { observer } from 'mobx-react';
import { useAuthStore, useStores } from 'store';
import { stageKey } from 'pages';
import { isNumber } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { coerce, prerelease, valid as semverValid } from 'semver';
import {
    BasicForm,
    BasicTable,
    CheckboxField,
    IBasicTableColumn,
    IconTextButton,
    ISimpleFileUploadProps,
    notification,
    SelectFormItem,
    showInformationDialog,
    SimpleFileUploadItem,
    TextFieldItem,
    useForm,
    VerticalList
} from 'ui-lib';
import {
    showDuplicatePackagesModal,
    showMissingPackageDependenciesModal
} from 'pages/private/components/package-modal-components';
import { useSpinnerComponent } from 'pages/private/components/loading-component';

import { TDisplayPackageProps } from 'store/packages-store';
import { ISnapshotProps, METAPACKAGE_TYPE, METAPACKAGE_TYPES, SNAPSHOT_STATUS } from 'dto/software-snapshot-dto';
import { IPackageProps, PACKAGE_ARCH } from 'dto/packages-dto';

import { isNullOrEmpty } from 'common-utils';
import { confirmCancel } from '../utils';
import { VersionComparison } from 'common-utils/debian-version-utils';
import { hasContainsIgnoreCase } from 'common-utils/string-utils';
import { PackageRowComponent, useUploadFilesCheckingStates } from 'pages/private/software/repository/package-utils';
import metaPackageCss from './metapackage.css';

type IPackageRecord = TDisplayPackageProps & { inLatestSnapshot: boolean; };

interface ISimpleFileUploadStates {
    fileUploadProps: ISimpleFileUploadProps;
    hasFileError: boolean;
    uploadPackages: File[];
    onClear: () => void;
}

const showSavingErrorDialog = (errors: React.ReactNode | string[]): void => {
    let content: React.ReactNode = errors;
    if (errors instanceof Array) {
        content = <VerticalList className={metaPackageCss['vertical-list']}
                                listItems={errors.map((item, index) => ({ key: index, title: '', content: item }))}/>;
    }
    showInformationDialog({
        modalType: 'error',
        title: 'Error',
        width: '500px',
        content: content
    });
};

const formatBuildNumber = (buildNumber: number): string => `${buildNumber}.${stageKey?.toLowerCase() ?? 'test'}`;
const toSemanticVersioning = (fullVersion: string): string => {
    return coerce(fullVersion)?.version;
};

const nextVersionBuildNumber = (versionWithBuildNumber: string, nextVersion: string): string => {
    if (!versionWithBuildNumber || (toSemanticVersioning(versionWithBuildNumber) !== toSemanticVersioning(nextVersion))) {
        return formatBuildNumber(1000);
    }
    const currentBuild = prerelease(versionWithBuildNumber)?.[0];
    if (!isNumber(currentBuild)) {
        return formatBuildNumber(1000);
    }
    return formatBuildNumber((Number(currentBuild) || 0) + 1000);
};

const isBuildValid = (buildNumber: string, currentVersionAndBuildNumber, nextVersion): boolean => {
    const stage = stageKey?.toLowerCase() ?? 'test';
    const currentVersion = currentVersionAndBuildNumber.substring(0, currentVersionAndBuildNumber.indexOf('-'));
    const currentBuildNumber = Number(currentVersionAndBuildNumber.substring(currentVersionAndBuildNumber.indexOf('-') + 1, currentVersionAndBuildNumber.lastIndexOf('.')));
    const firstPartString = buildNumber.substring(0, buildNumber.indexOf('.'));
    const firstPartNumber = Number(firstPartString);
    const lastPart = buildNumber.substring(buildNumber.indexOf('.') + 1);
    if (isNaN(firstPartNumber)
      || !Number.isInteger(firstPartNumber / 1000)
      || firstPartString?.[0] === '0'
      || firstPartString?.[0] === '-'
      || firstPartString?.[0] === '+'
      || (firstPartNumber <= currentBuildNumber && currentVersion === nextVersion)) {
        return false;
    }
    return lastPart === stage;
};

const useSimpleFileUploadStates = (): ISimpleFileUploadStates => {
    const [hasFileError, setHasFileError] = React.useState<boolean>(false);
    const [uploadPackages, setUploadPackages] = React.useState<File[]>([]);

    const { validateFiles } = useUploadFilesCheckingStates();

    const simpleFileUploadProps: ISimpleFileUploadProps = {
        fileList: uploadPackages,
        setFileList: setUploadPackages,
        allowedFileTypes: ['.deb'],
        limitFileSize: __LIMIT_PACKAGE_FILE_SIZE * 1024,
        beforeUploadChecking: (currentFiles: File[], newFiles: File[]): File[] => {
            const checkFileResult = validateFiles(currentFiles, newFiles);
            return checkFileResult.validFiles;
        },
        setFileError: setHasFileError,
        multiple: true,
    };
    return {
        fileUploadProps: simpleFileUploadProps,
        hasFileError: hasFileError,
        uploadPackages: uploadPackages,
        onClear: (): void => setUploadPackages([]),
    };
};

export const CreateMetaPackageComponent = observer(() => {
    const { configurationStore, packagesStore, snapshotStore } = useStores();
    const { assureApiAccount } = useAuthStore();
    const navigator = useNavigate();

    const toReleaseTable = (): void => {
        return navigator('/software/snapshots', {
            state: {
                snapshotType: SNAPSHOT_STATUS.RELEASED
            }
        });
    };

    const [form] = useForm();
    const { SpinnerComponent, ...spinner } = useSpinnerComponent();

    const [metaPackageType, setMetaPackageType] = React.useState<METAPACKAGE_TYPE>(METAPACKAGE_TYPE.ALM);
    const [selectedPackages, setSelectedPackages] = React.useState<IPackageProps[]>([]);
    const [basedOn, setBasedOn] = React.useState<ISnapshotProps>();

    const uploadComponentStates = useSimpleFileUploadStates();
    const metaPackageName = React.useMemo(() => `${metaPackageType}_SYS`, [metaPackageType]);

    const latestSnapshotOfType = React.useMemo<ISnapshotProps>(() => {
        return snapshotStore.getLatestDPUSnapshotByType(metaPackageType);
    }, [snapshotStore.displayDPUSnapshots, metaPackageType]);

    const latestSnapshotsOfType = React.useMemo<ISnapshotProps[]>(() => {
        return snapshotStore.displayDPUSnapshots[metaPackageType] || [];
    }, [snapshotStore.displayDPUSnapshots, metaPackageType]);

    const currentVersionAndBuildNumber = React.useMemo<string>(() => latestSnapshotOfType?.version, [latestSnapshotOfType]);


    const [nextVersion, setNextVersion] = React.useState<string>();
    const [buildNumber, setBuildNumber] = React.useState<string>();

    const [clearAllForm, setClearAllForm] = React.useState<boolean>();
    React.useEffect(() => {
        setSelectedPackages([]);
        uploadComponentStates.onClear();
        form.resetFields();
    }, [clearAllForm]);

    React.useEffect(() => {
        setSelectedPackages([]);
    }, [metaPackageType]);

    React.useEffect(() => {
        let version = currentVersionAndBuildNumber;
        if (!currentVersionAndBuildNumber) {
            version = '1.0.0';
        }
        setNextVersion(toSemanticVersioning(version));
        const currentBuildNumber = nextVersionBuildNumber(currentVersionAndBuildNumber, nextVersion);
        setBuildNumber(currentBuildNumber);
        form.setFieldsValue({ buildNumber: currentBuildNumber });
    }, [currentVersionAndBuildNumber]);

    React.useEffect(() => {
        const currentBuildNumber = nextVersionBuildNumber(currentVersionAndBuildNumber, nextVersion);
        setBuildNumber(currentBuildNumber);
    }, [currentVersionAndBuildNumber, nextVersion]);

    const allPackages = React.useMemo<IPackageRecord[]>(
        () => packagesStore.displayPackages
            .filter(item => METAPACKAGE_TYPES.includes(item.type))
            .map(item => ({ ...item, inLatestSnapshot: latestSnapshotOfType?.packageIds?.includes(item.id) || false })),
        [packagesStore.displayPackages, latestSnapshotOfType]
    );

    React.useEffect(() => {
        (async (): Promise<void> => {
            spinner.onStart();
            await Promise.all([
                packagesStore.loadEntities(assureApiAccount.accountId),
                snapshotStore.loadEntities(assureApiAccount.accountId)
            ]);
            spinner.onFinish();
        })();
    }, []);

    React.useEffect(() => {
        form.setFieldsValue({ currentVersion: currentVersionAndBuildNumber });
        form.setFieldsValue({ version: nextVersion });
        form.setFieldsValue({ buildNumber: buildNumber });
        form.setFieldsValue({ name: `${metaPackageName}-${nextVersion}-${buildNumber}` });
    }, [currentVersionAndBuildNumber, metaPackageName, nextVersion, buildNumber]);

    const PackageTable = React.useMemo(() => {
        const columns: IBasicTableColumn[] = [
            {
                code: 'packageName',
                title: 'Name',
                dataIndex: 'label',
                width: '45%',
                sorter: (a: IPackageRecord, b: IPackageRecord) => a.label.localeCompare(b.label),
                render: (text: string, record: IPackageRecord): React.ReactNode => <PackageRowComponent
                    configurationStore={configurationStore} pkg={record}/>,
                onFilter: (text: string, record: IPackageRecord) => hasContainsIgnoreCase(record.label, text),
                textSearchPlaceholder: 'Package label',
                iconName: 'search',
            },
            {
                code: 'architecture',
                title: 'Architecture',
                dataIndex: 'architecture',
                width: '10%',
                sorter: (a: IPackageRecord, b: IPackageRecord) => a.architecture.localeCompare(b.architecture),
                filters: Object.keys(PACKAGE_ARCH).map((arch) => ({
                    text: PACKAGE_ARCH[arch],
                    value: PACKAGE_ARCH[arch]
                })),
                onFilter: (architecture, pkg) => pkg.architecture == architecture,
            },
            {
                code: 'version',
                dataIndex: 'version',
                sorter: (a: IPackageRecord, b: IPackageRecord) => VersionComparison.compare(a.version, b.version),
                onFilter: (text: string, record: IPackageRecord) => hasContainsIgnoreCase(record.version, text),
                textSearchPlaceholder: 'Version',
                width: '15%',
                title: 'Version'
            },
            {
                code: 'type',
                title: 'Type',
                dataIndex: 'type',
                width: '5%',
                defaultFilteredValue: [metaPackageType],
                sorter: (a: IPackageRecord, b: IPackageRecord) => a.type.localeCompare(b.type),
                filters: Object.keys(METAPACKAGE_TYPE).map((type) => ({ text: METAPACKAGE_TYPE[type], value: type })),
                onFilter: (type, device) => device.type.toLowerCase() === type.toLowerCase(),
            },
            {
                code: 'updatedAt',
                title: 'Updated date',
                dataIndex: 'updatedAt',
                width: '20%',
                sorter: (a: IPackageRecord, b: IPackageRecord) =>
                    a.updatedAtTimestamp.localeCompare(b.updatedAtTimestamp),
            },
            {
                code: 'inLatestSnapshot',
                title: 'In latest release',
                width: '5%',
                dataIndex: 'inLatestSnapshot',
                filters: [
                    { text: 'TRUE', value: true },
                    { text: 'FALSE', value: false }
                ],
                onFilter: (filterValue: boolean, record: IPackageRecord) => record.inLatestSnapshot == filterValue,
                render: (value: boolean) => <CheckboxField label="" checked={value ?? false}/>
            }
        ];

        const Table = (): JSX.Element => <BasicTable
            columns={columns}
            dataSource={allPackages}
            rowSelection={{
                defaultSelectedRowKeys: selectedPackages.map(pack => pack.id),
                type: 'checkbox',
                getCheckboxProps: (record: TDisplayPackageProps): { name: string; } => {
                    return {
                        name: record['id'].toString(),
                    };
                },
                onChange: (selectedRowKeys, selectedRows): void => {
                    setSelectedPackages(selectedRows);
                },
            }}
            rowKey="id"
        />;
        return <Table/>;
    }, [allPackages, metaPackageType, latestSnapshotOfType, clearAllForm, basedOn]);

    return (
        <SpinnerComponent
            child={
                <React.Fragment>
                    <BasicForm
                        form={form}
                        onSubmit={async (values): Promise<unknown> => {
                            const fullVersion = `${nextVersion}-${buildNumber}`;
                            spinner.onStart();
                            try {
                                const result = await snapshotStore.createMetapackage(assureApiAccount.accountId, {
                                    label: `${metaPackageName}-${fullVersion}`,
                                    description: values['description'],
                                    type: values['metapackageType'],
                                    version: nextVersion,
                                    build: buildNumber,
                                    files: uploadComponentStates.uploadPackages,
                                    packageIds: selectedPackages?.map(item => item.id) || [],
                                });

                                if (result.error) {
                                    if (result.error.type === 'DUPLICATED_PACKAGES_ERROR') {
                                        return showDuplicatePackagesModal({ packages: result.error.reason });
                                    }
                                    return showSavingErrorDialog(result.error.reason);
                                }

                                notification.success({
                                    message: `The ${result.release.label} metadata package has been created`,
                                    placement: 'topRight',
                                });

                                const missingDependencies = packagesStore.detectDependencyPackages(result.packages.map(item => item.id));
                                if (showMissingPackageDependenciesModal({ missingPackageDependencies: missingDependencies }, toReleaseTable)) {
                                    return;
                                }

                                toReleaseTable();
                            } catch (err) {
                                showSavingErrorDialog(err.message);
                            } finally {
                                spinner.onFinish();
                            }
                        }}
                        items={[
                            <TextFieldItem
                                key="metapackageName-text-field-item"
                                code="name"
                                label="Name"
                                initialValue={`${metaPackageName}-${nextVersion}`}
                                disabled={true}
                                labelAlign="right"
                                isRequired={true}
                            />,
                            <TextFieldItem
                                key="description-text-field-item"
                                code="description"
                                label="Description"
                                labelAlign="right"
                            />,
                            <SelectFormItem
                                key="metapackageType-select-form-item"
                                code="metapackageType"
                                label="Package Type"
                                isRequired={true}
                                labelAlign="right"
                                dataSource={METAPACKAGE_TYPES.map(item => ({ value: item, label: item }))}
                                selectedValue={metaPackageType}
                                onChange={(value) => {
                                    setMetaPackageType(value);
                                    setBasedOn(undefined);
                                    form.setFieldsValue({ 'based-on': '' });
                                }}
                            />,
                            <TextFieldItem
                                key="currentVersion-text-field-item"
                                code="currentVersion"
                                label="Current version"
                                initialValue={currentVersionAndBuildNumber}
                                labelAlign="right"
                                disabled={true}
                            />,
                            <TextFieldItem
                                key="version-text-field-item"
                                code="version"
                                label="Version"
                                initialValue={nextVersion}
                                labelAlign="right"
                                isRequired={true}
                                validator={(rule, value, callback): void => {
                                    if (isNullOrEmpty(nextVersion)) return callback(undefined);
                                    if (!semverValid(nextVersion)) return callback('The version format is incorrect');
                                    if (isNullOrEmpty(currentVersionAndBuildNumber)) return callback(undefined);
                                    if (VersionComparison.lt(nextVersion, toSemanticVersioning(currentVersionAndBuildNumber))) return callback('The new version is lower than current version');
                                    callback(undefined);
                                }}
                                onChange={(ev): void => {
                                    setNextVersion(ev.target.value);
                                }}
                            />,
                            <TextFieldItem
                                key="buildNumber-text-field-item"
                                code="buildNumber"
                                label="Build"
                                initialValue={buildNumber}
                                labelAlign="right"
                                disabled={false}
                                validator={(rule, value, callback): void => {
                                    if (isNullOrEmpty(buildNumber) || !isBuildValid(buildNumber, currentVersionAndBuildNumber, nextVersion)) return callback('The build format is incorrect');
                                    callback(undefined);
                                }}
                                onChange={(ev): void => {
                                    setBuildNumber(ev.target.value);
                                }}
                            />,
                            <SelectFormItem
                                key="based-on-select-form-item"
                                code="based-on"
                                label="Based on"
                                labelAlign="right"
                                dataSource={latestSnapshotsOfType.map(item => ({
                                    value: item.id,
                                    label: `${item.label} ${item.version}`,
                                    tooltip: item.description ? item.description : `${item.label} ${item.version}`,
                                    placement: 'topLeft',
                                }))}
                                selectedValue={basedOn?.id}
                                onChange={(snapshotId: number) => {
                                    const selectedSnapshot = latestSnapshotsOfType.find(snapshot => snapshot.id === snapshotId);
                                    if (selectedSnapshot) {
                                        setSelectedPackages(allPackages.filter(pack => selectedSnapshot.packageIds.includes(pack.id)));
                                    }
                                    setBasedOn(selectedSnapshot);
                                }}
                                allowClear
                            />,
                            <SimpleFileUploadItem
                                key="fileUpload-item"
                                simpleFileUploadProps={uploadComponentStates.fileUploadProps}
                                code="fileUpload"
                                labelAlign="right"
                                label="Files"
                            />,
                            PackageTable,
                            <div key="rightBottomPanel-div" className={metaPackageCss['row']}>
                                <IconTextButton key="cancel" label="Cancel"
                                                onClick={(): void => confirmCancel(() => setClearAllForm(!clearAllForm))}/>
                                <IconTextButton
                                    key="save"
                                    label="Save"
                                    type="primary"
                                    disabled={uploadComponentStates.hasFileError || (isNullOrEmpty(selectedPackages) && isNullOrEmpty(uploadComponentStates.uploadPackages)) || !isBuildValid(buildNumber, currentVersionAndBuildNumber, nextVersion)}
                                    htmlType="submit"
                                />
                            </div>
                        ]}
                    />
                </React.Fragment>
            }
        />
    );
});
