import React from 'react';
import { useDeviceGroupsStore, useRoutesStore } from 'store';
import { useNavigate } from 'react-router-dom';
import { buildNavigatePath } from '../utils';
import { IconTextButton } from 'ui-lib/components/buttons/icon-text-button';

export interface IDeviceGroupHyperlinkProps {
    deviceGroupName: string;
}

export const DeviceGroupHyperlink = (props: IDeviceGroupHyperlinkProps) => {
    const deviceGroupsStore = useDeviceGroupsStore();
    const routeStore = useRoutesStore();
    const navigate = useNavigate();
    const clientAccount = deviceGroupsStore.getClientOwnDeviceGroup(props.deviceGroupName);
    return (
        <IconTextButton
            type='primary'
            label={'Go to device group'}
            onClick={() =>
                navigate(buildNavigatePath(routeStore.getRoute('DEVICES_DEVICE_GROUPS').path), {
                    state: { clientAccountId: clientAccount.id },
                })
            }
        />
    );
};
