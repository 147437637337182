import { Tooltip as AntdTooltip, Button as AntdButton } from 'antd';
import * as React from 'react';
import type {IconName} from '@fortawesome/fontawesome-common-types';
import { Icon } from '../icon';
import {IBaseButtonProps} from './base-button';
import classnames from 'classnames';

import iconButtonCss from './icon-button.css';

export interface IIconButtonProps extends IBaseButtonProps {
    iconName: IconName;
}

export function IconButton(props: IIconButtonProps) {

    const isLarge = props.size === 'LARGE';
    const tooltip = props.label ? props.label : props.tooltip;

    return (
        <AntdTooltip title={tooltip}>
            <AntdButton
                type={props.type ? props.type : 'default'}
                shape="default"
                size={isLarge ? 'large' : null}
                icon={<Icon iconName={props.iconName} size={isLarge ? 'REGULAR' : 'SMALL'} />}
                className={classnames(iconButtonCss.iconButton, props.className)}
                loading={props.loading}
                block={props.block}
                onClick={(ev) => props.onClick(ev)}
            />
        </AntdTooltip>
    );
}
