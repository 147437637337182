// This is a modified code based on @super-js/auth-client, an open source package hosted on github.com
import { ResponseError } from './response-error';

export type ApiMethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
export type ApiClientNavigateFunction = (to: number) => void;

export interface IApiClientOptions {
    host?: string;
    port?: number;
    version?: string;
    prefix?: string;
    navigate?: ApiClientNavigateFunction;
    credentials?: RequestCredentials;
}

export interface IApiFetchOptions {
    requestKey?: string;
    successMsg?: string;
    errorMsg?: string;
    processingMsg?: string;
    errorRedirectTo?: ((data: any) => string) | string;
    successRedirectTo?: ((data: any) => string) | string;
    propagateError?: boolean;
}

export interface IApiFetchParams {
    [key: string]: any;
}

export interface IRequestProps {
    url: string;
    headers?: any;
    params?: IApiFetchParams;
    options?: IApiFetchOptions;
}

export interface IResponseResult {
    status: string;
    data: any;
}

class ErrorParser {
    get error502() {
        return {
            data: 'ALM is currently experiencing network interruption. Please wait for few seconds.',
            status: 'error',
        };
    }

    parse = (response: any) => {
        if (this.respondedWithError(response, 502, 'Bad Gateway')) {
            return this.error502;
        }

        return response;
    };

    private respondedWithError = (response: any, code?: number, messageIncludes?: string) => {
        if (!response || response.status !== 'error') {
            return false;
        }
        if (typeof response.data === 'string' && messageIncludes && response.data.includes(messageIncludes)) {
            return true;
        }
        return typeof response.data === 'object' && (
            (code && response.data.statusCode === code) ||
            (messageIncludes && response.data.message.includes(messageIncludes))
        );
    };
}

export abstract class BaseApiClient {
    private readonly _baseUrl;
    private readonly _navigate: ApiClientNavigateFunction;
    private readonly _credentials: RequestCredentials;
    private readonly _clientName: string;
    private readonly _errorParser: ErrorParser;

    protected constructor(clientName: string, options: IApiClientOptions) {
        const { host, port, version, prefix, navigate } = options;

        this._baseUrl = `${host}${port ? `:${port}` : ''}${version ? `/${version}` : ''}${prefix ? `/${prefix}` : ''}`;
        this._navigate = navigate;

        this._credentials = options.credentials || 'same-origin';
        this._clientName = clientName;
        this._errorParser = new ErrorParser();
    }

    public get clientName(): string {
        return this._clientName;
    }

    protected get = <T = any>(requestProps: IRequestProps): Promise<T> => this._fetch('GET', requestProps);

    protected post = <T = any>(requestProps: IRequestProps): Promise<T> => this._fetch('POST', requestProps);

    protected put = <T = any>(requestProps: IRequestProps): Promise<T> => this._fetch('PUT', requestProps);

    protected delete = <T = any>(requestProps: IRequestProps): Promise<T> => this._fetch('DELETE', requestProps);

    protected patch = <T = any>(requestProps: IRequestProps): Promise<T> => this._fetch('PATCH', requestProps);

    private _parseResponse = async (response: Response) => {
        if (response.status === 502) {
            return this._errorParser.error502;
        }

        const contentType = response?.headers?.get('content-type');

        if (contentType) {
            if (contentType.includes('text')) {
                return response.text();
            }
            if (contentType.includes('json')) {
                return this._errorParser.parse(
                    await response.json(),
                );
            }
        }

        return response?.blob();
    };

    private _redirectTo = (targetUrl): void => {
        if (!this._navigate) {
            console.warn(`Unable to redirect to ${targetUrl} - Missing navigate function instance.`);
            return;
        } else if (this._navigate && targetUrl) {
            this._navigate(targetUrl);
        }
    };

    private _fetch = async (type: ApiMethodType, requestProps: IRequestProps): Promise<any> => {
        const { url, params = {}, options = {}, headers = {} } = requestProps;
        const requestKey = options.requestKey ? options.requestKey : `${Date.now()}_${type}_${requestProps.url}`;
        let res: Response;

        try {
            const targetUrl: URL = new URL(`${this._baseUrl}${url}`, location.href);
            if (type === 'GET') targetUrl.search = new URLSearchParams(params).toString();
            const _isFile = (param) =>
                param instanceof File || (Array.isArray(param) && param.some((p) => p instanceof File));

            const _hasFiles = Object.keys(params).some((paramCode) => _isFile(params[paramCode]));
            const _getBody = () => {
                if (_hasFiles) {
                    const formData = new FormData();
                    Object.keys(params).forEach((paramCode) => {
                        if (Array.isArray(params[paramCode]) && _isFile(params[paramCode])) {
                            params[paramCode].forEach((fileParam, fileParamIx) => {
                                formData.append(
                                    `${paramCode}[${fileParamIx}]`,
                                    fileParam instanceof File ? fileParam : JSON.stringify(fileParam),
                                );
                            });
                        } else {
                            formData.append(paramCode, params[paramCode]);
                        }
                    });

                    return formData;
                } else {
                    return type !== 'GET' ? JSON.stringify(params) : undefined;
                }
            };

            const requestHeader = {
                ...headers,
                Accept: 'application/json',
            };

            if (!_hasFiles) requestHeader['Content-Type'] = 'application/json';

            const request = new Request(targetUrl.toString(), {
                method: type,
                headers: new Headers(requestHeader),
                mode: 'cors',
                credentials: this._credentials,
                body: _getBody(),
            });

            res = await fetch(request);

            let parsedResponse = await this._parseResponse(res);

            if (!res.ok) {
                if (typeof parsedResponse === 'string') {
                    parsedResponse = {
                        name: res.statusText,
                        message: parsedResponse,
                    };
                } else if (!parsedResponse) {
                    parsedResponse = {
                        name: 'Unknown error',
                        message: 'Unknown error',
                    };
                }

                if (options.propagateError)
                    throw new ResponseError({
                        name: parsedResponse.name,
                        message: parsedResponse.message,
                        status: res.status,
                        validationErrors: parsedResponse.validationErrors ? parsedResponse.validationErrors : {},
                    });
            } else {
                if (options.successRedirectTo) {
                    this._redirectTo(
                        typeof options.successRedirectTo === 'function'
                            ? options.successRedirectTo(parsedResponse)
                            : options.successRedirectTo,
                    );
                }
            }

            return parsedResponse;
        } catch (err) {
            if (options.propagateError && err instanceof ResponseError) throw err;

            if (options.propagateError) {
                throw new ResponseError({
                    message: err.message,
                    status: err.status || err.statusText || 500,
                });
            }

            if (options.errorRedirectTo) {
                this._redirectTo(
                    typeof options.errorRedirectTo === 'function'
                        ? options.errorRedirectTo(err)
                        : options.errorRedirectTo,
                );
            }
        }
    };
}
