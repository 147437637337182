import { action, computed, makeObservable, observable } from 'mobx';

import { RoleApiClient } from '../api/role-api-client';
import { IRole, IUpdateRolePermissionsParams, RoleType } from '../dto/access-management/user-role-dto';
import { AccountType } from '../dto/access-management/account-dto';

import { AssureBaseStore, IAssureStoreConstructorOptions, SelectDataSource } from './assure-base.store';

const roleToSelectDataSourceFn = (role: IRole): { label: string; value: number; } => ({ value: role.id, label: role.roleType });
export const isVARAdmin = (role: IRole): boolean =>
    role.accountType === AccountType.VAR && role.roleType === RoleType.ADMIN;

export class RoleStore extends AssureBaseStore<RoleApiClient, IRole> {

    @observable
    private _organisationType: AccountType | string;

    public constructor(options: IAssureStoreConstructorOptions) {
        super(options);
        makeObservable(this);
    }

    protected get apiClient(): RoleApiClient {
        return this.apiClientStore.apiClients.role;
    }

    public findRole(id: number): SelectDataSource {
        return this.selectDataSource.find((role) => role.value == id);
    }

    public async loadRoles(): Promise<void> {
        this.setEntities(await this.apiClient.getRoles());
    }

    public grantPermissionsToRole(params: IUpdateRolePermissionsParams[]): Promise<IRole[]> {
        return this.apiClient.grantPermissionsToRole(params);
    }

    @action
    public setOrganisationType(organisationType: AccountType | string): void {
        this._organisationType = organisationType;
    }

    @computed
    public get selectDataSource(): SelectDataSource[] {
        if (!this.hasEntities()) return [];
        return this.entities
            .filter((role) => role.accountType === this._organisationType)
            .map(roleToSelectDataSourceFn);
    }

    @computed
    public get allRoles(): IRole[] {
        return this.entities;
    }
}
