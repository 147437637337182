/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Spinner } from 'ui-lib';
import componentCss from './component.css';
import { getErrorMessage } from 'common-utils';

export interface ISpinnerComponentProps {
    onStart: () => void;
    onFinish: () => void;
    loading: boolean;
    SpinnerComponent: (props: { child: React.ReactNode }) => JSX.Element;
    safeCall?: (func: Function) => Promise<any>;
    errorMessage?: string;
}

export const useSpinnerComponent = (defaultState?: boolean): ISpinnerComponentProps => {
    const spinnerRef = React.useRef<Omit<ISpinnerComponentProps, 'SpinnerComponent'>>(null);
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const SpinnerComponent = React.useCallback((props: { child: React.ReactElement }): JSX.Element => {
        const [spinning, setSpinning] = React.useState<boolean>(defaultState ?? false);
        spinnerRef.current = {
            onStart: () => setSpinning(true),
            onFinish: () => setSpinning(false),
            loading: spinning
        };
        return <Spinner className={componentCss['spinning-component']} spinning={spinning}>{props.child}</Spinner>;
    }, []);

    return {
        SpinnerComponent: SpinnerComponent,
        onStart: () => spinnerRef.current?.onStart(),
        onFinish: () => spinnerRef.current?.onFinish(),
        safeCall: async (cbFunc: Function) => {
            spinnerRef.current?.onStart();
            try {
                await cbFunc();
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            } finally {
                spinnerRef.current?.onFinish();
            }
        },
        errorMessage: errorMessage,
        loading: spinnerRef.current?.loading
    };
};