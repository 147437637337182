import { chunk } from 'lodash';
import { action, computed, makeObservable, observable, reaction, when } from 'mobx';
import { IAccount as IAccountDto } from '../dto/access-management/account-dto';
import { IDeviceInfo, IDeviceInventoryStateOptions } from '../dto/device-dto';
import { AccountStore } from './account-store';
import { AssureAdminApiClientStore } from './assure-admin-api-client.store';
import { toCommissionStatus } from 'pages/private/devices/commissioning-upload';

export type IAccount = IAccountDto;
export interface IItemOptions {
    value: number | string;
    label: string;
}

interface IClientAccountsOptions<T> {
    [key: string]: T[];
}

export interface IAccountGroupOptions<T> {
    key: any;
    varAccounts: T[];
    clientAccounts: IClientAccountsOptions<T>;
}

export interface IProvisionedDeviceOptions {
    key: string;
    uid: string;
    deviceTypeName: string;
    esn?: string;
    serial?: string;
    status?: string;
}

export interface IDeviceGroupOptions {
    key: any;
    deviceGroupName: IItemOptions[];
}

export class WizardStore {
    constructor(private _accountStore: AccountStore, private _managedAccount: any[], private _apiClientStore: AssureAdminApiClientStore) {
        makeObservable(this);
        reaction(
            () => this._varClientAccountGroups,
            (accountGroups) => {
                const varAccountId = accountGroups.varAccounts[0]?.id;
                let clientSelected: IAccount;

                if (this.isClientAccountSelected) {
                    clientSelected = this._selectedAccount;
                } else if (varAccountId && accountGroups.clientAccounts[varAccountId].length > 0) {
                    clientSelected = accountGroups.clientAccounts[varAccountId][0];
                }

                this.setVARAccount(varAccountId);
                this.setClientGroup(varAccountId, clientSelected?.id);
            },
        );
        when(
            () => !!_accountStore.accountHierarchy,
            () => this.setSelectedAccount(_accountStore.accountHierarchy.id),
        );
        when(
            () => !!this.deviceGroup && this.deviceGroup.deviceGroupName?.length > 0,
            () => {
                this.updateSelectedDeviceGroupName(this.deviceGroup.deviceGroupName[0]?.value as string);
            },
        );
    }

    @observable
    private _selectedAccount: IAccount;

    @computed
    get displaySelectedAccount(): IItemOptions {
        if (this._selectedAccount == undefined) return undefined;
        return {
            value: this._selectedAccount.id,
            label: this._selectedAccount.name,
        };
    }

    @action
    setSelectedAccount(id: number) {
        this._selectedAccount = this._accountStore.findAccount(id);
    }

    @computed
    get isVarAccountSelected(): boolean {
        return this._selectedAccount?.accountType == 'VAR' || this.isClientAccountSelected;
    }

    @computed
    get isClientAccountSelected(): boolean {
        return this._selectedAccount?.accountType == 'CLIENT';
    }

    private _createAccountGroups(): IAccountGroupOptions<IAccount> {
        if (this._selectedAccount == undefined) return undefined;

        const account = this._selectedAccount;

        let clientAccounts: IClientAccountsOptions<IAccount>[];
        switch (account.accountType) {
            case 'DPU':
                clientAccounts = account.subAccounts?.map((varAccount) => ({
                    [varAccount.id]: varAccount?.subAccounts || [],
                }));
                return {
                    key: account.id,
                    varAccounts: account.subAccounts || [],
                    clientAccounts: { ...Object.assign({}, ...clientAccounts) },
                };
            case 'VAR':
                return {
                    key: account.id,
                    varAccounts: account ? [account] : [],
                    clientAccounts: { [account.id]: account.subAccounts },
                };
            case 'CLIENT': {
                const parentAccount = account.parent;
                return {
                    key: account.id,
                    varAccounts: parentAccount ? [parentAccount] : [],
                    clientAccounts: parentAccount ? { [parentAccount.id]: [account] } : {},
                };
            }
            default:
                break;
        }
    }

    public getAccountGroupsData(): IAccountGroupOptions<IItemOptions> {
        const data: IAccountGroupOptions<IAccount> = this._createAccountGroups();

        this.setAccountGroupDataSource(data);
        return this.displayVarClientAccountGroups;
    }

    @action
    setAccountGroupDataSource(groups: IAccountGroupOptions<IAccount>) {
        this._varClientAccountGroups = groups;
    }

    @observable
    private _varClientAccountGroups: IAccountGroupOptions<IAccount>;

    @computed
    get displayVarClientAccountGroups(): IAccountGroupOptions<IItemOptions> {
        if (this._varClientAccountGroups == undefined) return undefined;
        const clientArray = Object.keys(this._varClientAccountGroups.clientAccounts).map((item) => {
            const options = this._varClientAccountGroups.clientAccounts[item];
            return {
                [item]: options.map((item) => ({ value: item.id, label: item.name })),
            };
        });
        return {
            key: this._varClientAccountGroups.key,
            varAccounts: this._varClientAccountGroups.varAccounts.map((item) => ({ value: item.id, label: item.name })),
            clientAccounts: Object.assign({}, ...clientArray),
        };
    }

    @action
    setVARAccount(id) {
        this._selectedVarAccount = this._varClientAccountGroups.varAccounts.find((item) => item.id == id);
    }

    @observable
    private _selectedVarAccount: IAccount;

    @action
    getSelectedVarAccountId() {
        return this._selectedVarAccount?.id;
    }

    @computed
    get displaySelectedVarAccount(): IItemOptions {
        if (this._selectedVarAccount == undefined) return undefined;
        return {
            value: this._selectedVarAccount.id,
            label: this._selectedVarAccount.name,
        };
    }

    @action
    setClientGroup(varAccountId, clientAccountId) {
        this._selectedClientAccount = this._varClientAccountGroups.clientAccounts[varAccountId].find(
            (item) => item.id == clientAccountId,
        );
    }

    @observable
    private _selectedClientAccount: IAccount;

    @computed
    get displaySelectedClientAccount(): IItemOptions {
        if (this._selectedClientAccount == undefined) return undefined;
        return {
            value: this._selectedClientAccount.id,
            label: this._selectedClientAccount.name,
        };
    }

    public async getDevices(): Promise<IProvisionedDeviceOptions[]> {
        const devices = await this._apiClientStore.apiClients.devices.getProvisionedDevices(this._selectedVarAccount.id);

        this.setProvisionedDevices(
            devices.map(
                (device) =>
                ({
                    key: device.deviceName,
                    uid: device.deviceName,
                    deviceTypeName: device.deviceTypeName,
                    esn: device.esn || '',
                    serial: device.serialNumber || '',
                    status: toCommissionStatus(device)
                }),
            ),
        );
        return this.provisionedDevices;
    }

    @action
    setProvisionedDevices(devices: IProvisionedDeviceOptions[]) {
        this.provisionedDevices = devices;
    }

    @observable
    provisionedDevices: IProvisionedDeviceOptions[];

    @action
    setSelectedDevices(devices: IProvisionedDeviceOptions[]) {
        this.selectedDevices = devices;
    }

    @observable
    selectedDevices: IProvisionedDeviceOptions[] = [];

    public async getDeviceGroupsData(): Promise<IDeviceGroupOptions> {
        if (!this._selectedClientAccount?.id) {
            this.setDeviceGroup({ key: 'devicegroup', deviceGroupName: [] });
            return this.deviceGroup;
        }

        const devicegroup = await this._apiClientStore.apiClients.devicegroups.getDeviceGroupDetailsOfAccount(
            this._selectedClientAccount.id,
            { hierarchy: 'true' },
        );

        const dataSource =
            devicegroup?.childrenGroups?.map((group) => ({
                value: group.deviceGroupName,
                label: group.deviceGroupLabel,
            })) || [];

        const data: IDeviceGroupOptions = {
            key: 'devicegroup',
            deviceGroupName: [
                { label: 'No Device Group', value: devicegroup.deviceGroupName },
                ...dataSource.sort((a, b) => a.label.localeCompare(b.label))
            ],
        };

        this.setDeviceGroup(data);
        return this.deviceGroup;
    }

    @action
    setDeviceGroup(groups: IDeviceGroupOptions) {
        this.deviceGroup = groups;
    }

    @action
    updateSelectedDeviceGroupName(value: string) {
        this.selectedDeviceGroup = this.deviceGroup.deviceGroupName?.find((group) => group.value == value);
    }

    @observable
    deviceGroup: IDeviceGroupOptions;

    @observable
    selectedDeviceGroup: IItemOptions;

    @observable
    processPercent = 0;

    @action
    public setProcessPercent(percent) {
        this.processPercent = percent;
    }

    @observable
    unsuccessDevices: (Partial<IDeviceInfo> & { errors: string[] })[] = [];

    @action
    public setUnsuccessDevices(unsuccessDevices) {
        this.unsuccessDevices = unsuccessDevices;
    }

    @computed
    public get successDeviceUids(): string[] {
        return this.selectedDevices
            .filter((device) => !this.unsuccessDevices.find((unsuccess) => device.uid == unsuccess.UID))
            .map((device) => device.uid);
    }

    @observable
    isCommissioning = false;

    @action
    public setCommissionProcessing(isCommissioning: boolean) {
        this.isCommissioning = isCommissioning;
    }

    public async commitCommissioningWizard(): Promise<any> {
        if (this.selectedDevices.length == 0)
            throw new Error('Error: Selected devices are empty. Cannot add devices to client/device group.');

        this.setCommissionProcessing(true);
        const commissioningDevices: string[] = this.selectedDevices.map((device) => device.uid);
        const DEVICES_PER_BATCH = 3;
        const numOfDevices = commissioningDevices.length;
        const progressStep = Math.ceil((DEVICES_PER_BATCH * 100) / numOfDevices);
        const deviceBatches = chunk(commissioningDevices, DEVICES_PER_BATCH);
        let errorResults = {};
        for (let i = 0; i < deviceBatches.length; i++) {
            const result = await this._apiClientStore.apiClients.devices.commissionDevices(
                this._selectedVarAccount.id,
                this._selectedClientAccount.id,
                this.selectedDeviceGroup.value as string,
                deviceBatches[i],
            );
            errorResults = result.reduce((errors, device) => {
                if (!device.success) errors[device.identifier] = device.errors;
                return errors;
            }, errorResults);
            this.setProcessPercent((i + 1) * progressStep);
        }

        if (Object.keys(errorResults).length > 0) {
            const errorDevices: (Partial<IDeviceInfo> & { errors: string[] })[] = Object.keys(errorResults).map(
                (identifier) => {
                    const device = this.selectedDevices.find((device) => device.uid == identifier);
                    return {
                        UID: identifier,
                        serialNumber: device.serial,
                        esn: device.esn,
                        errors: errorResults[identifier],
                    };
                },
            );
            this.setUnsuccessDevices(errorDevices);
        }
        this.setCommissionProcessing(false);
    }

    public createDeviceGroupPath(): string[] {
        const path: string[] = [`${this._selectedVarAccount.id}`, `${this._selectedClientAccount.id}`];
        if (this.selectedDeviceGroup) {
            path.push(`${this.selectedDeviceGroup.value}`);
        }
        return path;
    }

    public createCommissioningDeviceSummary(): IDeviceInventoryStateOptions {
        return {
            accountId: this._selectedVarAccount?.id,
            activeKey: {
                clientDeviceGroupName: this._managedAccount.find(account => account.id === this._selectedClientAccount.id)?.deviceGroupName,
                deviceGroup: `${this.selectedDeviceGroup?.value}`,
            },
        };
    }
}
