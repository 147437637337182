import { IApiClientOptions } from 'core';
import { IGetCommandQuery } from 'dto/remote-commands';
import { IDeviceInfoProps, ISoftwareUpdateJobSummary } from 'store/devices-store';
import {
    ICommissioningUploadRequest,
    ICryptoReportResult,
    IDataTransferResult,
    IDecommissionDevicesOptions,
    IDecommissionDevicesResponse,
    IDeviceAttributesResult,
    IDeviceCommissioningResponse,
    IDevicePackageResponse,
    IDeviceTimeOptions,
    IExecutionJobSummary,
    IGetDeviceLogsOptions,
    ILogOptions,
    IMSPVersionOptions,
    IProcessHistory,
    IResetDeviceResult,
    IScrFileOptions,
    IScrInfoResult,
    ISearchDeviceInfo,
    ISearchDeviceOptions,
    IShellCommandExecuteParams,
    IShellCommandExecutionResponse,
    ITimeReportRequestParams,
    IUpdateDeviceAttributeResponse,
} from '../dto/device-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';

export interface IUpdateDeviceAttribute {
    deviceName: string;
    attributeName: string;
    attributeValue: string;
}

export class DevicesApiClient extends AssureBaseApiClient {
    constructor(options: IApiClientOptions) {
        super('devices', options);
    }

    public async getDevicesByName(accountId: number, deviceName: string): Promise<IDeviceInfoProps> {
        return this.send<IDeviceInfoProps>(HTTP_METHOD.GET, this.getRequestOptionsWithAccountId(accountId, deviceName));
    }

    public async searchDevices(accountId: number, params?: ISearchDeviceOptions): Promise<ISearchDeviceInfo[]> {
        const result = await this.send<{ devices: ISearchDeviceInfo[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, '', params),
        );
        return result.devices;
    }

    public async getProvisionedDevices(accountId: number, params?: ISearchDeviceOptions): Promise<ISearchDeviceInfo[]> {
        const result = await this.send<{ devices: ISearchDeviceInfo[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, 'provisioned', params),
        );
        return result.devices;
    }

    public async getDerivationIds(accountId: number, accountUuid: string): Promise<{ derivationIds: string[] }> {
        return this.send<{ derivationIds: string[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${accountUuid}/derivation-ids`),
        );
    }

    public async getCommissionedDevices(
        accountId: number,
        params?: ISearchDeviceOptions,
    ): Promise<ISearchDeviceInfo[]> {
        const result = await this.send<{ devices: ISearchDeviceInfo[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, 'commissioned', params),
        );
        return result.devices;
    }

    public getDevicesTimeReport(accountId: number, params?: ITimeReportRequestParams): Promise<IDeviceTimeOptions> {
        return this.send<IDeviceTimeOptions>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, 'commissioned/time-report', params),
        );
    }

    public async getCurrentPackageListing(accountId: number, deviceName: string): Promise<IDevicePackageResponse> {
        return this.send<IDevicePackageResponse>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/packages`),
        );
    }

    public async updateAttribute(
        accountId: number,
        options: IUpdateDeviceAttribute,
    ): Promise<IUpdateDeviceAttributeResponse> {
        const { deviceName, ...rest } = options;
        return this.send<IUpdateDeviceAttributeResponse>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/update-attribute`, rest),
        );
    }

    public async getDeviceJobs(
        accountId: number,
        deviceName: string,
        query?: IGetCommandQuery,
    ): Promise<IExecutionJobSummary[]> {
        const result = await this.send<{ executionJobSummaries: IExecutionJobSummary[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/jobs`, query),
        );
        return result.executionJobSummaries;
    }

    public async getDeviceSoftwareUpdates(accountId: number, deviceName: string, dateFrom?: string, dateTo?: string): Promise<ISoftwareUpdateJobSummary[]> {
        const result = await this.send<{ history: ISoftwareUpdateJobSummary[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/software-updates`, dateFrom && dateTo ? {
                dateFrom: dateFrom,
                dateTo: dateTo,
            } : {}),
        );
        return result.history;
    }

    public async getDeviceProcesses(accountId: number, deviceName: string): Promise<IProcessHistory[]> {
        const result = await this.send<{ processes: IProcessHistory[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/processes/history`),
        );
        return result.processes;
    }

    public async getCurrentDeviceServices(accountId: number, deviceName: string): Promise<string[]> {
        const result = await this.send<{ services: { service_list: string[] } }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/services`),
        );
        return result.services?.service_list || [];
    }

    public async getCurrentDeviceScrFiles(accountId: number, deviceName: string): Promise<IScrFileOptions[]> {
        const result = await this.send<{ scrFiles: IScrFileOptions[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/scr-files`),
        );
        return result.scrFiles;
    }

    public async distributeGPGKeys(accountId: number, deviceName: string): Promise<string> {
        const result = await this.send<{ excutionId: string }>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/gpg-keys/distribute`),
        );
        return result.excutionId;
    }

    public async getDeviceLogs(accountId: number, deviceName: string, logTypeName: string, options?: IGetDeviceLogsOptions): Promise<ILogOptions[]> {
        const result = await this.send<{ logEntries: ILogOptions[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/logs/${logTypeName}`, options),
        );
        return result.logEntries;
    }

    public async getMSPVersion(accountId: number, deviceName: string): Promise<IMSPVersionOptions> {
        return this.send<IMSPVersionOptions>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/msp-version`),
        );
    }

    public async getTaipanBatteryInfo(accountId: number, deviceName: string): Promise<any> {
        return this.send<any>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/taipan-battery-info`),
        );
    }

    public async uploadCommissioningDevices(accountId: number, params: ICommissioningUploadRequest): Promise<any> {
        const result = await this.send<{ devices: any }>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, 'upload-commissioning', params),
        );
        return result.devices;
    }

    public async commissionDevices(
        accountId: number,
        clientAccountId: number,
        deviceGroupName: string,
        identifiers: string[],
    ): Promise<IDeviceCommissioningResponse[]> {
        const response = await this.send<{
            result: IDeviceCommissioningResponse[]
        }>(HTTP_METHOD.POST, this.getRequestOptionsWithAccountId(accountId, 'commissioning', {
            clientAccountId,
            deviceGroupName,
            identifiers
        }));
        return response.result; // this auth always returns status=sucess
    }

    public dataTransfer(
        accountId: number,
        deviceName: string,
        deviceGroup: string,
        filePath: string,
        permissions: string,
        files: File[],
    ): Promise<IDataTransferResult> {
        return this.send<IDataTransferResult>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, 'data-transfer', {
                deviceName,
                deviceGroup,
                filePath,
                permissions,
                files,
            }),
        );
    }

    public async shellCommandExecution(
        accountId: number,
        params: IShellCommandExecuteParams,
    ): Promise<IShellCommandExecutionResponse[]> {
        const result = await this.send<{ shellCommandOutput: IShellCommandExecutionResponse[] }>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, 'shell-command', params),
        );
        return result.shellCommandOutput;
    }

    public async getDeviceCryptoReport(accountId: number, deviceName: string): Promise<ICryptoReportResult> {
        const result = await this.send<{ cryptoReport: ICryptoReportResult }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/crypto-report`),
        );
        return result.cryptoReport;
    }

    public decommissionDevices(
        accountId: number,
        params: IDecommissionDevicesOptions,
    ): Promise<IDecommissionDevicesResponse> {
        return this.send<IDecommissionDevicesResponse>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, 'decommission', params),
        );
    }

    public resetDevice(accountId: number, deviceName: string): Promise<IResetDeviceResult> {
        return this.send<IResetDeviceResult>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/reset`),
        );
    }

    public async getCurrentDeviceScrInfo(accountId: number, deviceName: string): Promise<IScrInfoResult> {
        const result = await this.send<{ scrInfo: IScrInfoResult }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/scr-info`),
        );
        return result.scrInfo;
    }

    public async getDeviceAttributes(accountId: number, deviceName: string): Promise<IDeviceAttributesResult> {
        return this.send<IDeviceAttributesResult>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deviceName}/attributes`),
        );
    }

    public async getInstalledFirmwareVersions(accountId: number): Promise<string[]> {
        const result = await this.send<{ installedFirmwareVersions: string[]; }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, 'installed-firmwares'),
        );
        return result.installedFirmwareVersions;
    }

    protected getRootPath(): string {
        return 'devices';
    }
}
