import * as React from 'react';
import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

export interface IFileUploadProps {
    label?: string;
    loading?: boolean;
    uploadURL?: string;
    fileList?: any[];
    setFileList: any;
    disabled?: boolean;
}

export const FileUpload = (config: IFileUploadProps) => {
    const beforeUpload = async (file: any): Promise<boolean> => {
        await config.setFileList(file);
        return false;
    };

    const onRemove = async (file: any): Promise<boolean> => {
        const index = config.fileList.indexOf(file);
        const newFileList = config.fileList.slice();
        newFileList.splice(index, 1);
        await config.setFileList(newFileList);
        return false;
    };

    const props = config.uploadURL
        ? {
              name: 'file',
              action: config.uploadURL,
              onChange(info) {
                  if (info.file.status === 'done') {
                      message.success(`${info.file.name} file uploaded successfully`);
                      return config.setFileList(info.fileList);
                  } else if (info.file.status === 'error') {
                      message.error(`${info.file.name} file upload failed.`);
                  }
                  config.setFileList(info.fileList);
              },
          }
        : {
              onRemove: (file) => onRemove(file),
              beforeUpload: (file) => beforeUpload(file),
              fileList: !config.fileList ? [] : config.fileList,
              multiple: false,
          };

    const disabled = 'disabled' in config ? config.disabled : config.fileList?.length >= 1;

    return (
        <Upload {...props}>
            <Button icon={<UploadOutlined />} disabled={disabled} loading={config.loading}>
                {config.label ? config.label : 'Click to Upload'}
            </Button>
        </Upload>
    );
};
