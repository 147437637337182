import { ValueOf } from 'common-utils';
import { omit } from 'lodash';

export enum RepositoryType {
    CLIENT = 'CLIENT',
    VAR = 'VAR',
    SCR = 'SCR',
    ALM = 'ALM',
    VOS = 'VOS',
    DPU = 'DPU'
}

export enum PackageArchitecture {
    ARMEL = 'armel',
    ARMHF = 'armhf',
    TAIPAN2 = 'TAIPAN2',
    ALL = 'all',
}

export const PACKAGE_ARCH = PackageArchitecture;
export type PACKAGE_ARCH = ValueOf<typeof PackageArchitecture>;

export const PACKAGE_TYPE = RepositoryType;
export type PACKAGE_TYPE = ValueOf<typeof RepositoryType>;

export const FILTER_PACKAGE_TYPE = omit(PACKAGE_TYPE, ['DPU']);

export interface IPackageDependencyProps {
    label: string;
    version?: string;
}

export interface IPackageProps {
    uuid: string;
    id: number;
    label: string;
    key: string;
    version: string;
    fileName: string;
    architecture?: string;
    dependencyPackages?: IPackageDependencyProps[];
    packageGroupId: number;
    type: PACKAGE_TYPE;
    accountUuid: string;
    updatedAt: string;
    createdAt: string;
}

export interface ICreateMultiplePackagesResponseProps {
    packages: IPackageProps[];
    errors?: string[];
}

export interface ICreatePackageRequestProps {
    files: any[];
    type: string;
}

export type IUngroupPackageRequestProps = Omit<IUpdatePackageGroupRequestProps, 'packageGroupId'>;

export interface IUpdatePackageGroupRequestProps {
    packageGroupId: number;
    packageId: number;
}

export interface IDetectPackageDependenciesResult {
    id: number;
    label: string;
    type: string;
    dependencies: IPackageDependencyProps[];
    missingDependencies: IPackageDependencyProps[];
}