import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeData, useAuthStore, useStores } from '../../../store';
import { observer } from 'mobx-react';
import { message, ILoginHeaderProps, LoginPage, Typography, IFormItemValidation } from 'ui-lib';

import { getDefaultPath } from '../../private/utils';
import { getErrorMessage } from 'common-utils';
import { ServerNotFoundError } from 'core';

const LOGO_NAME = 'Assure Lifecycle Management';
const LOGO_DETAIL = 'Login to access the lifecycle management system for Assure Validators';

export const LoginPageWrapper = observer(() => {
    const { login } = useAuthStore();
    const [resultStatus, setResultStatus] = React.useState<string>();
    const [statusMessage, setStatusMessage] = React.useState<string>();
    const navigate = useNavigate();
    const stores = useStores();
    const onLogin = async (values): Promise<void> => {
        try {
            const user = await login(values.email, values.password);
            await initializeData(stores);
            if (user.isActive)
                return navigate(getDefaultPath(stores));

            return navigate(stores.routeStore.getRoute('FIRST_LOGIN').path, {
                state: {
                    temporaryPassword: values.password,
                }
            });
        } catch (err) {
            if (err instanceof ServerNotFoundError)
                return message.error(err.message, 10);

            setStatusMessage(getErrorMessage(err));
            setResultStatus('error');
        }
    };

    const loginHeader: ILoginHeaderProps = {
        iconName: 'sensor-cloud',
        title: LOGO_NAME,
        description: LOGO_DETAIL,
    };
    const validate: IFormItemValidation = {
        status: resultStatus,
        message: statusMessage,
        resetValidation: () => {
            setResultStatus('success');
            setStatusMessage(undefined);
        },
    };
    return (
        <LoginPage
            onLogin={onLogin}
            validate={validate}
            header={loginHeader}
            forgotPassword={<Typography.Link href={'/forgot-password'}>Forgot your password?</Typography.Link>}
        />
    );
});
