export const timezones = [{
    label: 'Africa/Abidjan (UTC)',
    name: 'Africa/Abidjan'
}, {
    label: 'Africa/Algiers (UTC+1)',
    name: 'Africa/Algiers'
}, {
    label: 'Africa/Bissau (UTC)',
    name: 'Africa/Bissau'
}, {
    label: 'Africa/Cairo (UTC+2)',
    name: 'Africa/Cairo'
}, {
    label: 'Africa/Casablanca (UTC)',
    name: 'Africa/Casablanca'
}, {
    label: 'Africa/Ceuta (UTC+1)',
    name: 'Africa/Ceuta'
}, {
    label: 'Africa/El_Aaiun (UTC)',
    name: 'Africa/El_Aaiun'
}, {
    label: 'Africa/Johannesburg (UTC+2)',
    name: 'Africa/Johannesburg'
}, {
    label: 'Africa/Juba (UTC+3)',
    name: 'Africa/Juba'
}, {
    label: 'Africa/Khartoum (UTC+2)',
    name: 'Africa/Khartoum'
}, {
    label: 'Africa/Lagos (UTC+1)',
    name: 'Africa/Lagos'
}, {
    label: 'Africa/Maputo (UTC+2)',
    name: 'Africa/Maputo'
}, {
    label: 'Africa/Monrovia (UTC)',
    name: 'Africa/Monrovia'
}, {
    label: 'Africa/Nairobi (UTC+3)',
    name: 'Africa/Nairobi'
}, {
    label: 'Africa/Ndjamena (UTC+1)',
    name: 'Africa/Ndjamena'
}, {
    label: 'Africa/Sao_Tome (UTC)',
    name: 'Africa/Sao_Tome'
}, {
    label: 'Africa/Tripoli (UTC+2)',
    name: 'Africa/Tripoli'
}, {
    label: 'Africa/Tunis (UTC+1)',
    name: 'Africa/Tunis'
}, {
    label: 'Africa/Windhoek (UTC+2)',
    name: 'Africa/Windhoek'
}, {
    label: 'America/Adak (UTC-10)',
    name: 'America/Adak'
}, {
    label: 'America/Anchorage (UTC-9)',
    name: 'America/Anchorage'
}, {
    label: 'America/Araguaina (UTC-3)',
    name: 'America/Araguaina'
}, {
    label: 'America/Argentina/Buenos_Aires (UTC-3)',
    name: 'America/Argentina/Buenos_Aires'
}, {
    label: 'America/Argentina/Catamarca (UTC-3)',
    name: 'America/Argentina/Catamarca'
}, {
    label: 'America/Argentina/Cordoba (UTC-3)',
    name: 'America/Argentina/Cordoba'
}, {
    label: 'America/Argentina/Jujuy (UTC-3)',
    name: 'America/Argentina/Jujuy'
}, {
    label: 'America/Argentina/La_Rioja (UTC-3)',
    name: 'America/Argentina/La_Rioja'
}, {
    label: 'America/Argentina/Mendoza (UTC-3)',
    name: 'America/Argentina/Mendoza'
}, {
    label: 'America/Argentina/Rio_Gallegos (UTC-3)',
    name: 'America/Argentina/Rio_Gallegos'
}, {
    label: 'America/Argentina/Salta (UTC-3)',
    name: 'America/Argentina/Salta'
}, {
    label: 'America/Argentina/San_Juan (UTC-3)',
    name: 'America/Argentina/San_Juan'
}, {
    label: 'America/Argentina/San_Luis (UTC-3)',
    name: 'America/Argentina/San_Luis'
}, {
    label: 'America/Argentina/Tucuman (UTC-3)',
    name: 'America/Argentina/Tucuman'
}, {
    label: 'America/Argentina/Ushuaia (UTC-3)',
    name: 'America/Argentina/Ushuaia'
}, {
    label: 'America/Asuncion (UTC-4)',
    name: 'America/Asuncion'
}, {
    label: 'America/Bahia (UTC-3)',
    name: 'America/Bahia'
}, {
    label: 'America/Bahia_Banderas (UTC-6)',
    name: 'America/Bahia_Banderas'
}, {
    label: 'America/Barbados (UTC-4)',
    name: 'America/Barbados'
}, {
    label: 'America/Belem (UTC-3)',
    name: 'America/Belem'
}, {
    label: 'America/Belize (UTC-6)',
    name: 'America/Belize'
}, {
    label: 'America/Boa_Vista (UTC-4)',
    name: 'America/Boa_Vista'
}, {
    label: 'America/Bogota (UTC-5)',
    name: 'America/Bogota'
}, {
    label: 'America/Boise (UTC-7)',
    name: 'America/Boise'
}, {
    label: 'America/Cambridge_Bay (UTC-7)',
    name: 'America/Cambridge_Bay'
}, {
    label: 'America/Campo_Grande (UTC-4)',
    name: 'America/Campo_Grande'
}, {
    label: 'America/Cancun (UTC-5)',
    name: 'America/Cancun'
}, {
    label: 'America/Caracas (UTC-4)',
    name: 'America/Caracas'
}, {
    label: 'America/Cayenne (UTC-3)',
    name: 'America/Cayenne'
}, {
    label: 'America/Chicago (UTC-6)',
    name: 'America/Chicago'
}, {
    label: 'America/Chihuahua (UTC-7)',
    name: 'America/Chihuahua'
}, {
    label: 'America/Costa_Rica (UTC-6)',
    name: 'America/Costa_Rica'
}, {
    label: 'America/Cuiaba (UTC-4)',
    name: 'America/Cuiaba'
}, {
    label: 'America/Danmarkshavn (UTC)',
    name: 'America/Danmarkshavn'
}, {
    label: 'America/Dawson (UTC-8)',
    name: 'America/Dawson'
}, {
    label: 'America/Dawson_Creek (UTC-7)',
    name: 'America/Dawson_Creek'
}, {
    label: 'America/Denver (UTC-7)',
    name: 'America/Denver'
}, {
    label: 'America/Detroit (UTC-5)',
    name: 'America/Detroit'
}, {
    label: 'America/Edmonton (UTC-7)',
    name: 'America/Edmonton'
}, {
    label: 'America/Eirunepe (UTC-5)',
    name: 'America/Eirunepe'
}, {
    label: 'America/El_Salvador (UTC-6)',
    name: 'America/El_Salvador'
}, {
    label: 'America/Fortaleza (UTC-3)',
    name: 'America/Fortaleza'
}, {
    label: 'America/Fort_Nelson (UTC-7)',
    name: 'America/Fort_Nelson'
}, {
    label: 'America/Glace_Bay (UTC-4)',
    name: 'America/Glace_Bay'
}, {
    label: 'America/Goose_Bay (UTC-4)',
    name: 'America/Goose_Bay'
}, {
    label: 'America/Grand_Turk (UTC-4)',
    name: 'America/Grand_Turk'
}, {
    label: 'America/Guatemala (UTC-6)',
    name: 'America/Guatemala'
}, {
    label: 'America/Guayaquil (UTC-5)',
    name: 'America/Guayaquil'
}, {
    label: 'America/Guyana (UTC-4)',
    name: 'America/Guyana'
}, {
    label: 'America/Halifax (UTC-4)',
    name: 'America/Halifax'
}, {
    label: 'America/Havana (UTC-5)',
    name: 'America/Havana'
}, {
    label: 'America/Hermosillo (UTC-7)',
    name: 'America/Hermosillo'
}, {
    label: 'America/Indiana/Indianapolis (UTC-5)',
    name: 'America/Indiana/Indianapolis'
}, {
    label: 'America/Indiana/Knox (UTC-6)',
    name: 'America/Indiana/Knox'
}, {
    label: 'America/Indiana/Marengo (UTC-5)',
    name: 'America/Indiana/Marengo'
}, {
    label: 'America/Indiana/Petersburg (UTC-5)',
    name: 'America/Indiana/Petersburg'
}, {
    label: 'America/Indiana/Tell_City (UTC-6)',
    name: 'America/Indiana/Tell_City'
}, {
    label: 'America/Indiana/Vevay (UTC-5)',
    name: 'America/Indiana/Vevay'
}, {
    label: 'America/Indiana/Vincennes (UTC-5)',
    name: 'America/Indiana/Vincennes'
}, {
    label: 'America/Indiana/Winamac (UTC-5)',
    name: 'America/Indiana/Winamac'
}, {
    label: 'America/Inuvik (UTC-7)',
    name: 'America/Inuvik'
}, {
    label: 'America/Iqaluit (UTC-5)',
    name: 'America/Iqaluit'
}, {
    label: 'America/Jamaica (UTC-5)',
    name: 'America/Jamaica'
}, {
    label: 'America/Juneau (UTC-9)',
    name: 'America/Juneau'
}, {
    label: 'America/Kentucky/Louisville (UTC-5)',
    name: 'America/Kentucky/Louisville'
}, {
    label: 'America/Kentucky/Monticello (UTC-5)',
    name: 'America/Kentucky/Monticello'
}, {
    label: 'America/La_Paz (UTC-4)',
    name: 'America/La_Paz'
}, {
    label: 'America/Lima (UTC-5)',
    name: 'America/Lima'
}, {
    label: 'America/Los_Angeles (UTC-8)',
    name: 'America/Los_Angeles'
}, {
    label: 'America/Maceio (UTC-3)',
    name: 'America/Maceio'
}, {
    label: 'America/Managua (UTC-6)',
    name: 'America/Managua'
}, {
    label: 'America/Manaus (UTC-4)',
    name: 'America/Manaus'
}, {
    label: 'America/Martinique (UTC-4)',
    name: 'America/Martinique'
}, {
    label: 'America/Matamoros (UTC-6)',
    name: 'America/Matamoros'
}, {
    label: 'America/Mazatlan (UTC-7)',
    name: 'America/Mazatlan'
}, {
    label: 'America/Menominee (UTC-6)',
    name: 'America/Menominee'
}, {
    label: 'America/Merida (UTC-6)',
    name: 'America/Merida'
}, {
    label: 'America/Metlakatla (UTC-9)',
    name: 'America/Metlakatla'
}, {
    label: 'America/Mexico_City (UTC-6)',
    name: 'America/Mexico_City'
}, {
    label: 'America/Miquelon (UTC-3)',
    name: 'America/Miquelon'
}, {
    label: 'America/Moncton (UTC-4)',
    name: 'America/Moncton'
}, {
    label: 'America/Monterrey (UTC-6)',
    name: 'America/Monterrey'
}, {
    label: 'America/Montevideo (UTC-3)',
    name: 'America/Montevideo'
}, {
    label: 'America/New_York (UTC-5)',
    name: 'America/New_York'
}, {
    label: 'America/Nipigon (UTC-5)',
    name: 'America/Nipigon'
}, {
    label: 'America/Nome (UTC-9)',
    name: 'America/Nome'
}, {
    label: 'America/Noronha (UTC-2)',
    name: 'America/Noronha'
}, {
    label: 'America/North_Dakota/Beulah (UTC-6)',
    name: 'America/North_Dakota/Beulah'
}, {
    label: 'America/North_Dakota/Center (UTC-6)',
    name: 'America/North_Dakota/Center'
}, {
    label: 'America/North_Dakota/New_Salem (UTC-6)',
    name: 'America/North_Dakota/New_Salem'
}, {
    label: 'America/Nuuk (UTC-3)',
    name: 'America/Nuuk'
}, {
    label: 'America/Ojinaga (UTC-7)',
    name: 'America/Ojinaga'
}, {
    label: 'America/Panama (UTC-5)',
    name: 'America/Panama'
}, {
    label: 'America/Pangnirtung (UTC-5)',
    name: 'America/Pangnirtung'
}, {
    label: 'America/Paramaribo (UTC-3)',
    name: 'America/Paramaribo'
}, {
    label: 'America/Phoenix (UTC-7)',
    name: 'America/Phoenix'
}, {
    label: 'America/Port-au-Prince (UTC-5)',
    name: 'America/Port-au-Prince'
}, {
    label: 'America/Porto_Velho (UTC-4)',
    name: 'America/Porto_Velho'
}, {
    label: 'America/Puerto_Rico (UTC-4)',
    name: 'America/Puerto_Rico'
}, {
    label: 'America/Punta_Arenas (UTC-3)',
    name: 'America/Punta_Arenas'
}, {
    label: 'America/Rainy_River (UTC-6)',
    name: 'America/Rainy_River'
}, {
    label: 'America/Rankin_Inlet (UTC-6)',
    name: 'America/Rankin_Inlet'
}, {
    label: 'America/Recife (UTC-3)',
    name: 'America/Recife'
}, {
    label: 'America/Regina (UTC-6)',
    name: 'America/Regina'
}, {
    label: 'America/Resolute (UTC-6)',
    name: 'America/Resolute'
}, {
    label: 'America/Rio_Branco (UTC-5)',
    name: 'America/Rio_Branco'
}, {
    label: 'America/Santarem (UTC-3)',
    name: 'America/Santarem'
}, {
    label: 'America/Santiago (UTC-3)',
    name: 'America/Santiago'
}, {
    label: 'America/Santo_Domingo (UTC-4)',
    name: 'America/Santo_Domingo'
}, {
    label: 'America/Sao_Paulo (UTC-3)',
    name: 'America/Sao_Paulo'
}, {
    label: 'America/Scoresbysund (UTC-1)',
    name: 'America/Scoresbysund'
}, {
    label: 'America/Sitka (UTC-9)',
    name: 'America/Sitka'
}, {
    label: 'America/St_Johns (UTC-3:30)',
    name: 'America/St_Johns'
}, {
    label: 'America/Swift_Current (UTC-6)',
    name: 'America/Swift_Current'
}, {
    label: 'America/Tegucigalpa (UTC-6)',
    name: 'America/Tegucigalpa'
}, {
    label: 'America/Thule (UTC-4)',
    name: 'America/Thule'
}, {
    label: 'America/Thunder_Bay (UTC-5)',
    name: 'America/Thunder_Bay'
}, {
    label: 'America/Tijuana (UTC-8)',
    name: 'America/Tijuana'
}, {
    label: 'America/Toronto (UTC-5)',
    name: 'America/Toronto'
}, {
    label: 'America/Vancouver (UTC-8)',
    name: 'America/Vancouver'
}, {
    label: 'America/Whitehorse (UTC-8)',
    name: 'America/Whitehorse'
}, {
    label: 'America/Winnipeg (UTC-6)',
    name: 'America/Winnipeg'
}, {
    label: 'America/Yakutat (UTC-9)',
    name: 'America/Yakutat'
}, {
    label: 'America/Yellowknife (UTC-7)',
    name: 'America/Yellowknife'
}, {
    label: 'Antarctica/Casey (UTC+8)',
    name: 'Antarctica/Casey'
}, {
    label: 'Antarctica/Davis (UTC+7)',
    name: 'Antarctica/Davis'
}, {
    label: 'Antarctica/Macquarie (UTC+11)',
    name: 'Antarctica/Macquarie'
}, {
    label: 'Antarctica/Mawson (UTC+5)',
    name: 'Antarctica/Mawson'
}, {
    label: 'Antarctica/Palmer (UTC-3)',
    name: 'Antarctica/Palmer'
}, {
    label: 'Antarctica/Rothera (UTC-3)',
    name: 'Antarctica/Rothera'
}, {
    label: 'Antarctica/Troll (UTC)',
    name: 'Antarctica/Troll'
}, {
    label: 'Antarctica/Vostok (UTC+6)',
    name: 'Antarctica/Vostok'
}, {
    label: 'Asia/Almaty (UTC+6)',
    name: 'Asia/Almaty'
}, {
    label: 'Asia/Amman (UTC+3)',
    name: 'Asia/Amman'
}, {
    label: 'Asia/Anadyr (UTC+12)',
    name: 'Asia/Anadyr'
}, {
    label: 'Asia/Aqtau (UTC+5)',
    name: 'Asia/Aqtau'
}, {
    label: 'Asia/Aqtobe (UTC+5)',
    name: 'Asia/Aqtobe'
}, {
    label: 'Asia/Ashgabat (UTC+5)',
    name: 'Asia/Ashgabat'
}, {
    label: 'Asia/Atyrau (UTC+5)',
    name: 'Asia/Atyrau'
}, {
    label: 'Asia/Baghdad (UTC+3)',
    name: 'Asia/Baghdad'
}, {
    label: 'Asia/Baku (UTC+4)',
    name: 'Asia/Baku'
}, {
    label: 'Asia/Bangkok (UTC+7)',
    name: 'Asia/Bangkok'
}, {
    label: 'Asia/Barnaul (UTC+7)',
    name: 'Asia/Barnaul'
}, {
    label: 'Asia/Beirut (UTC+2)',
    name: 'Asia/Beirut'
}, {
    label: 'Asia/Bishkek (UTC+6)',
    name: 'Asia/Bishkek'
}, {
    label: 'Asia/Brunei (UTC+8)',
    name: 'Asia/Brunei'
}, {
    label: 'Asia/Chita (UTC+9)',
    name: 'Asia/Chita'
}, {
    label: 'Asia/Choibalsan (UTC+8)',
    name: 'Asia/Choibalsan'
}, {
    label: 'Asia/Colombo (UTC+5:30)',
    name: 'Asia/Colombo'
}, {
    label: 'Asia/Damascus (UTC+2)',
    name: 'Asia/Damascus'
}, {
    label: 'Asia/Dhaka (UTC+6)',
    name: 'Asia/Dhaka'
}, {
    label: 'Asia/Dili (UTC+9)',
    name: 'Asia/Dili'
}, {
    label: 'Asia/Dubai (UTC+4)',
    name: 'Asia/Dubai'
}, {
    label: 'Asia/Dushanbe (UTC+5)',
    name: 'Asia/Dushanbe'
}, {
    label: 'Asia/Famagusta (UTC+2)',
    name: 'Asia/Famagusta'
}, {
    label: 'Asia/Gaza (UTC+2)',
    name: 'Asia/Gaza'
}, {
    label: 'Asia/Hebron (UTC+2)',
    name: 'Asia/Hebron'
}, {
    label: 'Asia/Hong_Kong (UTC+8)',
    name: 'Asia/Hong_Kong'
}, {
    label: 'Asia/Hovd (UTC+7)',
    name: 'Asia/Hovd'
}, {
    label: 'Asia/Ho_Chi_Minh (UTC+7)',
    name: 'Asia/Ho_Chi_Minh'
}, {
    label: 'Asia/Irkutsk (UTC+8)',
    name: 'Asia/Irkutsk'
}, {
    label: 'Asia/Jakarta (UTC+7)',
    name: 'Asia/Jakarta'
}, {
    label: 'Asia/Jayapura (UTC+9)',
    name: 'Asia/Jayapura'
}, {
    label: 'Asia/Jerusalem (UTC+2)',
    name: 'Asia/Jerusalem'
}, {
    label: 'Asia/Kabul (UTC+4:30)',
    name: 'Asia/Kabul'
}, {
    label: 'Asia/Kamchatka (UTC+12)',
    name: 'Asia/Kamchatka'
}, {
    label: 'Asia/Karachi (UTC+5)',
    name: 'Asia/Karachi'
}, {
    label: 'Asia/Kathmandu (UTC+5:45)',
    name: 'Asia/Kathmandu'
}, {
    label: 'Asia/Khandyga (UTC+9)',
    name: 'Asia/Khandyga'
}, {
    label: 'Asia/Kolkata (UTC+5:30)',
    name: 'Asia/Kolkata'
}, {
    label: 'Asia/Krasnoyarsk (UTC+7)',
    name: 'Asia/Krasnoyarsk'
}, {
    label: 'Asia/Kuala_Lumpur (UTC+8)',
    name: 'Asia/Kuala_Lumpur'
}, {
    label: 'Asia/Kuching (UTC+8)',
    name: 'Asia/Kuching'
}, {
    label: 'Asia/Macau (UTC+8)',
    name: 'Asia/Macau'
}, {
    label: 'Asia/Magadan (UTC+11)',
    name: 'Asia/Magadan'
}, {
    label: 'Asia/Makassar (UTC+8)',
    name: 'Asia/Makassar'
}, {
    label: 'Asia/Manila (UTC+8)',
    name: 'Asia/Manila'
}, {
    label: 'Asia/Nicosia (UTC+2)',
    name: 'Asia/Nicosia'
}, {
    label: 'Asia/Novokuznetsk (UTC+7)',
    name: 'Asia/Novokuznetsk'
}, {
    label: 'Asia/Novosibirsk (UTC+7)',
    name: 'Asia/Novosibirsk'
}, {
    label: 'Asia/Omsk (UTC+6)',
    name: 'Asia/Omsk'
}, {
    label: 'Asia/Oral (UTC+5)',
    name: 'Asia/Oral'
}, {
    label: 'Asia/Pontianak (UTC+7)',
    name: 'Asia/Pontianak'
}, {
    label: 'Asia/Pyongyang (UTC+9)',
    name: 'Asia/Pyongyang'
}, {
    label: 'Asia/Qatar (UTC+3)',
    name: 'Asia/Qatar'
}, {
    label: 'Asia/Qostanay (UTC+6)',
    name: 'Asia/Qostanay'
}, {
    label: 'Asia/Qyzylorda (UTC+6)',
    name: 'Asia/Qyzylorda'
}, {
    label: 'Asia/Riyadh (UTC+3)',
    name: 'Asia/Riyadh'
}, {
    label: 'Asia/Sakhalin (UTC+11)',
    name: 'Asia/Sakhalin'
}, {
    label: 'Asia/Samarkand (UTC+5)',
    name: 'Asia/Samarkand'
}, {
    label: 'Asia/Seoul (UTC+9)',
    name: 'Asia/Seoul'
}, {
    label: 'Asia/Shanghai (UTC+8)',
    name: 'Asia/Shanghai'
}, {
    label: 'Asia/Singapore (UTC+8)',
    name: 'Asia/Singapore'
}, {
    label: 'Asia/Srednekolymsk (UTC+11)',
    name: 'Asia/Srednekolymsk'
}, {
    label: 'Asia/Taipei (UTC+8)',
    name: 'Asia/Taipei'
}, {
    label: 'Asia/Tashkent (UTC+5)',
    name: 'Asia/Tashkent'
}, {
    label: 'Asia/Tbilisi (UTC+4)',
    name: 'Asia/Tbilisi'
}, {
    label: 'Asia/Tehran (UTC+3:30)',
    name: 'Asia/Tehran'
}, {
    label: 'Asia/Thimphu (UTC+6)',
    name: 'Asia/Thimphu'
}, {
    label: 'Asia/Tokyo (UTC+9)',
    name: 'Asia/Tokyo'
}, {
    label: 'Asia/Tomsk (UTC+7)',
    name: 'Asia/Tomsk'
}, {
    label: 'Asia/Ulaanbaatar (UTC+8)',
    name: 'Asia/Ulaanbaatar'
}, {
    label: 'Asia/Urumqi (UTC+6)',
    name: 'Asia/Urumqi'
}, {
    label: 'Asia/Ust-Nera (UTC+10)',
    name: 'Asia/Ust-Nera'
}, {
    label: 'Asia/Vladivostok (UTC+10)',
    name: 'Asia/Vladivostok'
}, {
    label: 'Asia/Yakutsk (UTC+9)',
    name: 'Asia/Yakutsk'
}, {
    label: 'Asia/Yangon (UTC+6:30)',
    name: 'Asia/Yangon'
}, {
    label: 'Asia/Yekaterinburg (UTC+5)',
    name: 'Asia/Yekaterinburg'
}, {
    label: 'Asia/Yerevan (UTC+4)',
    name: 'Asia/Yerevan'
}, {
    label: 'Atlantic/Azores (UTC-1)',
    name: 'Atlantic/Azores'
}, {
    label: 'Atlantic/Bermuda (UTC-4)',
    name: 'Atlantic/Bermuda'
}, {
    label: 'Atlantic/Canary (UTC)',
    name: 'Atlantic/Canary'
}, {
    label: 'Atlantic/Cape_Verde (UTC-1)',
    name: 'Atlantic/Cape_Verde'
}, {
    label: 'Atlantic/Faroe (UTC)',
    name: 'Atlantic/Faroe'
}, {
    label: 'Atlantic/Madeira (UTC)',
    name: 'Atlantic/Madeira'
}, {
    label: 'Atlantic/Reykjavik (UTC)',
    name: 'Atlantic/Reykjavik'
}, {
    label: 'Atlantic/South_Georgia (UTC-2)',
    name: 'Atlantic/South_Georgia'
}, {
    label: 'Atlantic/Stanley (UTC-3)',
    name: 'Atlantic/Stanley'
}, {
    label: 'Australia/Adelaide (UTC+10:30)',
    name: 'Australia/Adelaide'
}, {
    label: 'Australia/Brisbane (UTC+10)',
    name: 'Australia/Brisbane'
}, {
    label: 'Australia/Broken_Hill (UTC+10:30)',
    name: 'Australia/Broken_Hill'
}, {
    label: 'Australia/Darwin (UTC+9:30)',
    name: 'Australia/Darwin'
}, {
    label: 'Australia/Eucla (UTC+8:45)',
    name: 'Australia/Eucla'
}, {
    label: 'Australia/Hobart (UTC+11)',
    name: 'Australia/Hobart'
}, {
    label: 'Australia/Lindeman (UTC+10)',
    name: 'Australia/Lindeman'
}, {
    label: 'Australia/Lord_Howe (UTC+11)',
    name: 'Australia/Lord_Howe'
}, {
    label: 'Australia/Melbourne (UTC+11)',
    name: 'Australia/Melbourne'
}, {
    label: 'Australia/Perth (UTC+8)',
    name: 'Australia/Perth'
}, {
    label: 'Australia/Sydney (UTC+11)',
    name: 'Australia/Sydney'
}, {
    label: 'Europe/Amsterdam (UTC+1)',
    name: 'Europe/Amsterdam'
}, {
    label: 'Europe/Andorra (UTC+1)',
    name: 'Europe/Andorra'
}, {
    label: 'Europe/Astrakhan (UTC+4)',
    name: 'Europe/Astrakhan'
}, {
    label: 'Europe/Athens (UTC+2)',
    name: 'Europe/Athens'
}, {
    label: 'Europe/Belgrade (UTC+1)',
    name: 'Europe/Belgrade'
}, {
    label: 'Europe/Berlin (UTC+1)',
    name: 'Europe/Berlin'
}, {
    label: 'Europe/Brussels (UTC+1)',
    name: 'Europe/Brussels'
}, {
    label: 'Europe/Bucharest (UTC+2)',
    name: 'Europe/Bucharest'
}, {
    label: 'Europe/Budapest (UTC+1)',
    name: 'Europe/Budapest'
}, {
    label: 'Europe/Chisinau (UTC+2)',
    name: 'Europe/Chisinau'
}, {
    label: 'Europe/Copenhagen (UTC+1)',
    name: 'Europe/Copenhagen'
}, {
    label: 'Europe/Dublin (UTC)',
    name: 'Europe/Dublin'
}, {
    label: 'Europe/Gibraltar (UTC+1)',
    name: 'Europe/Gibraltar'
}, {
    label: 'Europe/Helsinki (UTC+2)',
    name: 'Europe/Helsinki'
}, {
    label: 'Europe/Istanbul (UTC+3)',
    name: 'Europe/Istanbul'
}, {
    label: 'Europe/Kaliningrad (UTC+2)',
    name: 'Europe/Kaliningrad'
}, {
    label: 'Europe/Kiev (UTC+2)',
    name: 'Europe/Kiev'
}, {
    label: 'Europe/Kirov (UTC+3)',
    name: 'Europe/Kirov'
}, {
    label: 'Europe/Lisbon (UTC)',
    name: 'Europe/Lisbon'
}, {
    label: 'Europe/London (UTC)',
    name: 'Europe/London'
}, {
    label: 'Europe/Luxembourg (UTC+1)',
    name: 'Europe/Luxembourg'
}, {
    label: 'Europe/Madrid (UTC+1)',
    name: 'Europe/Madrid'
}, {
    label: 'Europe/Malta (UTC+1)',
    name: 'Europe/Malta'
}, {
    label: 'Europe/Minsk (UTC+3)',
    name: 'Europe/Minsk'
}, {
    label: 'Europe/Monaco (UTC+1)',
    name: 'Europe/Monaco'
}, {
    label: 'Europe/Moscow (UTC+3)',
    name: 'Europe/Moscow'
}, {
    label: 'Europe/Oslo (UTC+1)',
    name: 'Europe/Oslo'
}, {
    label: 'Europe/Paris (UTC+1)',
    name: 'Europe/Paris'
}, {
    label: 'Europe/Prague (UTC+1)',
    name: 'Europe/Prague'
}, {
    label: 'Europe/Riga (UTC+2)',
    name: 'Europe/Riga'
}, {
    label: 'Europe/Rome (UTC+1)',
    name: 'Europe/Rome'
}, {
    label: 'Europe/Samara (UTC+4)',
    name: 'Europe/Samara'
}, {
    label: 'Europe/Saratov (UTC+4)',
    name: 'Europe/Saratov'
}, {
    label: 'Europe/Simferopol (UTC+3)',
    name: 'Europe/Simferopol'
}, {
    label: 'Europe/Sofia (UTC+2)',
    name: 'Europe/Sofia'
}, {
    label: 'Europe/Stockholm (UTC+1)',
    name: 'Europe/Stockholm'
}, {
    label: 'Europe/Tallinn (UTC+2)',
    name: 'Europe/Tallinn'
}, {
    label: 'Europe/Tirane (UTC+1)',
    name: 'Europe/Tirane'
}, {
    label: 'Europe/Ulyanovsk (UTC+4)',
    name: 'Europe/Ulyanovsk'
}, {
    label: 'Europe/Uzhgorod (UTC+2)',
    name: 'Europe/Uzhgorod'
}, {
    label: 'Europe/Vienna (UTC+1)',
    name: 'Europe/Vienna'
}, {
    label: 'Europe/Vilnius (UTC+2)',
    name: 'Europe/Vilnius'
}, {
    label: 'Europe/Volgograd (UTC+3)',
    name: 'Europe/Volgograd'
}, {
    label: 'Europe/Warsaw (UTC+1)',
    name: 'Europe/Warsaw'
}, {
    label: 'Europe/Zaporozhye (UTC+2)',
    name: 'Europe/Zaporozhye'
}, {
    label: 'Europe/Zurich (UTC+1)',
    name: 'Europe/Zurich'
}, {
    label: 'Indian/Chagos (UTC+6)',
    name: 'Indian/Chagos'
}, {
    label: 'Indian/Christmas (UTC+7)',
    name: 'Indian/Christmas'
}, {
    label: 'Indian/Cocos (UTC+6:30)',
    name: 'Indian/Cocos'
}, {
    label: 'Indian/Kerguelen (UTC+5)',
    name: 'Indian/Kerguelen'
}, {
    label: 'Indian/Mahe (UTC+4)',
    name: 'Indian/Mahe'
}, {
    label: 'Indian/Maldives (UTC+5)',
    name: 'Indian/Maldives'
}, {
    label: 'Indian/Mauritius (UTC+4)',
    name: 'Indian/Mauritius'
}, {
    label: 'Indian/Reunion (UTC+4)',
    name: 'Indian/Reunion'
}, {
    label: 'Pacific/Apia (UTC+13)',
    name: 'Pacific/Apia'
}, {
    label: 'Pacific/Auckland (UTC+13)',
    name: 'Pacific/Auckland'
}, {
    label: 'Pacific/Bougainville (UTC+11)',
    name: 'Pacific/Bougainville'
}, {
    label: 'Pacific/Chatham (UTC+13)',
    name: 'Pacific/Chatham'
}, {
    label: 'Pacific/Chuuk (UTC+10)',
    name: 'Pacific/Chuuk'
}, {
    label: 'Pacific/Easter (UTC-6)',
    name: 'Pacific/Easter'
}, {
    label: 'Pacific/Efate (UTC+11)',
    name: 'Pacific/Efate'
}, {
    label: 'Pacific/Fakaofo (UTC+13)',
    name: 'Pacific/Fakaofo'
}, {
    label: 'Pacific/Fiji (UTC+12)',
    name: 'Pacific/Fiji'
}, {
    label: 'Pacific/Funafuti (UTC+12)',
    name: 'Pacific/Funafuti'
}, {
    label: 'Pacific/Galapagos (UTC-6)',
    name: 'Pacific/Galapagos'
}, {
    label: 'Pacific/Gambier (UTC-9)',
    name: 'Pacific/Gambier'
}, {
    label: 'Pacific/Guadalcanal (UTC+11)',
    name: 'Pacific/Guadalcanal'
}, {
    label: 'Pacific/Guam (UTC+10)',
    name: 'Pacific/Guam'
}, {
    label: 'Pacific/Honolulu (UTC-10)',
    name: 'Pacific/Honolulu'
}, {
    label: 'Pacific/Kanton (UTC+12)',
    name: 'Pacific/Kanton'
}, {
    label: 'Pacific/Kiritimati (UTC+14)',
    name: 'Pacific/Kiritimati'
}, {
    label: 'Pacific/Kosrae (UTC+11)',
    name: 'Pacific/Kosrae'
}, {
    label: 'Pacific/Kwajalein (UTC+12)',
    name: 'Pacific/Kwajalein'
}, {
    label: 'Pacific/Majuro (UTC+12)',
    name: 'Pacific/Majuro'
}, {
    label: 'Pacific/Marquesas (UTC-9:30)',
    name: 'Pacific/Marquesas'
}, {
    label: 'Pacific/Nauru (UTC+12)',
    name: 'Pacific/Nauru'
}, {
    label: 'Pacific/Niue (UTC-11)',
    name: 'Pacific/Niue'
}, {
    label: 'Pacific/Norfolk (UTC+11)',
    name: 'Pacific/Norfolk'
}, {
    label: 'Pacific/Noumea (UTC+11)',
    name: 'Pacific/Noumea'
}, {
    label: 'Pacific/Pago_Pago (UTC-11)',
    name: 'Pacific/Pago_Pago'
}, {
    label: 'Pacific/Palau (UTC+9)',
    name: 'Pacific/Palau'
}, {
    label: 'Pacific/Pitcairn (UTC-8)',
    name: 'Pacific/Pitcairn'
}, {
    label: 'Pacific/Pohnpei (UTC+11)',
    name: 'Pacific/Pohnpei'
}, {
    label: 'Pacific/Port_Moresby (UTC+10)',
    name: 'Pacific/Port_Moresby'
}, {
    label: 'Pacific/Rarotonga (UTC-10)',
    name: 'Pacific/Rarotonga'
}, {
    label: 'Pacific/Tahiti (UTC-10)',
    name: 'Pacific/Tahiti'
}, {
    label: 'Pacific/Tarawa (UTC+12)',
    name: 'Pacific/Tarawa'
}, {
    label: 'Pacific/Tongatapu (UTC+13)',
    name: 'Pacific/Tongatapu'
}, {
    label: 'Pacific/Wake (UTC+12)',
    name: 'Pacific/Wake'
}, {
    label: 'Pacific/Wallis (UTC+12)',
    name: 'Pacific/Wallis'
}];


export const TIMEZONES = timezones.map((item) => {
    return { value: item.name, label: item.label };
});
