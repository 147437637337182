import { AccountType } from './account-dto';
import { ApplicationType } from './application-dto';
import { IEnvironment } from './environment-dto';

export enum RoleType {
    ADMIN = 'Admin',
    DEPLOYMENT_MANAGER = 'Deployment Manager',
    KEY_MANAGER = 'Key Manager',
    MAINTENANCE = 'Maintenance',
};

export interface IRole {
    id: number;
    name: string;
    isSystem: boolean;
    permissions: IPermission[];
    environments: IEnvironment[];
    childRoles: IRole[];
    parentRole: IRole;
    accountType: AccountType;
    roleType: RoleType;
}

export interface IPermission {
    id: number;
    code: string;
    applicationType: ApplicationType;
    isSystem: boolean;
    group: string;
}

export interface IUpdateRolePermissionsParams {
    roleId: number;
    permissionIds: number[];
}
