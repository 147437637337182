import * as React from 'react';
import { observer } from 'mobx-react';

import { AdvanceTable, IBasicTableColumn, DangerAlert } from 'ui-lib';
import { IDeviceTableProps } from './device-details';
import { DeviceSCRFileListingStore } from '../../../../store/device-details/device-scr-file-listing-store';
import devicesCss from './devices.css';

export const DeviceSCRFileListingTable = observer((props: IDeviceTableProps<DeviceSCRFileListingStore>) => {
    const { store } = props;

    React.useEffect(() => {
        store.initialize();
    }, []);

    const columns: IBasicTableColumn[] = [
        { code: 'name', title: 'File Name', dataIndex: 'name' },
        { code: 'updateCount', title: 'Update Count', dataIndex: 'updateCount' },
        { code: 'crc', title: 'CRC', dataIndex: 'crc' },
    ];

    return (
        <div className={devicesCss.tabPane}>
            {store.error != undefined && <DangerAlert closable={false} message='Error' description={store.error} />}
            <AdvanceTable
                title=''
                table={{ columns, dataSource: store.displaySCRFiles }}
                loadingState={store.loading}
                toolbar={{
                    onReload: ():Promise<void> => store.fetchLatestData(),
                }}
            />
        </div>
    );
});
