import * as React from 'react';
import {Routes, Route} from 'react-router-dom';
import cx from 'classnames';
import {Layout} from 'antd';

import contentCss from './content.css';
import {Skeleton} from '../loading';

export interface IContentProps {
    isAuthenticated?: boolean;
    isAuthenticating?: boolean;
    className?: string;
    privatePages?: React.ReactNode;
    publicPages?: React.ReactNode;
}

export const Content = (props: IContentProps) => {

    return (
        <Skeleton loading={props.isAuthenticating}>
            <Layout.Content className={cx(contentCss.wrapper, props.className)}>
                <Routes>
                    <Route path="*" element={props.isAuthenticated ? props.privatePages : props.publicPages} />
                </Routes>
            </Layout.Content>
        </Skeleton>
    );

};