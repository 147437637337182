import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { orderBy } from 'lodash';
import { AuthStore } from 'core';
import { AssureAdminApiClientStore } from './assure-admin-api-client.store';
import { AccountStore } from './account-store';
import type {
    IDeviceGroupOptions,
    IUpdateDeviceResultOptions,
    TUpdateDeviceGroupOptions
} from '../dto/device-group-dto';
import { DeviceGroupsApiClient } from '../api';
import { AssureBaseStore, IAssureStoreConstructorOptions } from './assure-base.store';
import { AccountType, IAccount } from '../dto/access-management/account-dto';
import { getErrorMessage } from 'common-utils';
import { IDeviceAttributesResult } from 'dto/device-dto';

export type IDeviceGroupsStoreConstructorOptions = IAssureStoreConstructorOptions;

export enum NodeType {
    ACCOUNT = 'ACCOUNT',
    DEVICEGROUP = 'DEVICEGROUP',
}

export interface DataNode {
    title: string;
    key: string;
    accountId?: number;
    accountType?: string;
    deviceGroupName?: string;
    type: NodeType;
    isLeaf?: boolean;
    children?: DataNode[];
}

export function findRoot<T>(key, tree: T, getKey: (item: T) => string, getSubArray: (item: T) => T[]): T {
    if (!tree) return undefined;

    if (key == getKey(tree)) return tree;

    const subs = getSubArray(tree) || [];
    if (subs.length === 0) return undefined;

    for (let i = 0; i < subs.length; i++) {
        const root = findRoot(key, subs[i], getKey, getSubArray);
        if (root) return root;
    }
}

function getDeviceGroupNameOfAccount(account: IAccount) {
    // The environment of account has always one environment.
    return account?.environments[0]?.deviceGroupName && account.environments[0].deviceGroupName;
}

export class DeviceGroupsStore extends AssureBaseStore<DeviceGroupsApiClient, IDeviceGroupOptions> {
    @observable
    treeData: DataNode[] = [];
    private readonly _apiClientStore: AssureAdminApiClientStore;
    private readonly _accountStore: AccountStore;
    private readonly _authStore: AuthStore;

    public constructor(
        options: IDeviceGroupsStoreConstructorOptions,
        accountStore: AccountStore,
        authStore: AuthStore,
    ) {
        super(options);
        makeObservable(this);

        this._apiClientStore = options.apiClientStore;
        this._accountStore = accountStore;
        this._authStore = authStore;

        // Update treeData when datasource changed
        reaction(
            () => this._accountStore.accountHierarchy,
            (treeData) => {
                if (!treeData) return;

                (async () => {
                    try {
                        this.setDataLoading(true);
                        this.resetTreeData();
                        await this.loadDeviceGroupDetails(true);
                        this.initialTreeData();
                    } finally {
                        this.setDataLoading(false);
                    }
                })();
            },
            { fireImmediately: true },
        );
    }

    @computed
    public get allDeviceGroups() {
        if (!this.hasEntities() || this.dataLoading) return [];

        const getCircularReplacer = () => {
            const seen = new WeakSet();
            return (key, value) => {
                if (typeof value === 'object' && value !== null) {
                    if (seen.has(value)) return;
                    seen.add(value);
                }
                return value;
            };
        };

        return JSON.parse(JSON.stringify(this.entities, getCircularReplacer()));
    }

    protected get apiClient(): DeviceGroupsApiClient {
        return this._apiClientStore.apiClients.devicegroups;
    }

    public static updateTreeData(list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] {
        return list.map((node) => {
            if (node.key === key) return { ...node, children };
            if (node.children) return { ...node, children: this.updateTreeData(node.children, key, children) };
            return node;
        });
    }

    public async loadDeviceGroupDetails(force = false) {
        if (!force && this.hasEntities()) return;
        try {
            this.setDataLoading(true);
            this.clearEntities();
            const varOrClientAccountIds = [];
            const currentAccount = this._accountStore.findAccount(this._authStore.currentUser.accountId);
            if (currentAccount.accountType == AccountType.DPU) {
                varOrClientAccountIds.push(...currentAccount.subAccounts.map(item => item.id));
            } else {
                varOrClientAccountIds.push(currentAccount.id);
            }
            await Promise.all(varOrClientAccountIds.map(async id => {
                const deviceGroups = await this.apiClient.getDeviceGroupOfAccounts(id, { hierarchy: 'true' });
                const groups = deviceGroups.flatMap(group => {
                    const childGroups = group.childrenGroups.map(item => ({ ...item, parentGroup: group }));
                    return [group, ...childGroups];
                });
                this.addEntity(...orderBy(groups, [(item) => item.deviceGroupLabel.trim().toLowerCase()]));
            }));
        } catch (err) {
            this.setError(getErrorMessage(err));
        } finally {
            this.setDataLoading(false);
        }
    }

    public getClientDeviceGroupDetails(clientId: number): IDeviceGroupOptions {
        if (!clientId) return undefined;
        const currentAccounts = this._accountStore.managedAccounts;
        const currentAccount = currentAccounts.find(item => item.id == clientId);
        if (currentAccount.accountType != AccountType.CLIENT)
            return undefined;
        const clientDeviceGroupName = getDeviceGroupNameOfAccount(currentAccount);

        const clientDeviceGroupDetails = clientDeviceGroupName
            && this.allDeviceGroups.find(entity => clientDeviceGroupName === entity.deviceGroupName);
        if (clientDeviceGroupDetails) {
            clientDeviceGroupDetails.accountUuid = currentAccount.uuid;
        }
        return clientDeviceGroupDetails;
    }

    public getDevicesGroupDetails(accountId: number): IDeviceGroupOptions[] {
        if (!accountId) return [];
        const currentAccounts = this._accountStore.managedAccounts;
        const currentAccount = currentAccounts.find(item => item.id == accountId);
        switch (currentAccount?.accountType) {
            case AccountType.CLIENT:
                return [this.getClientDeviceGroupDetails(currentAccount.id)];
            case AccountType.VAR: {
                const clients = currentAccounts.filter(account => account.parent && account.parent.id == accountId);
                return clients.reduce((deviceGroups, client) => {
                    const clientGroup = this.getClientDeviceGroupDetails(client.id);
                    if (clientGroup) return [...deviceGroups, clientGroup];
                    return deviceGroups;
                }, []);
            }
            case AccountType.DPU: {
                const vars = currentAccounts.filter(account => account.parent && account.parent.id == accountId);
                return vars.flatMap(item => this.getDevicesGroupDetails(item.id));
            }
            default:
                return [];
        }
    }

    public getDeviceGroupByName(name: string): IDeviceGroupOptions {
        return this.getDeviceGroupByKey('deviceGroupName', name);
    }

    public getDeviceGroupById(id: number): IDeviceGroupOptions {
        return this.getDeviceGroupByKey('id', id);
    }

    public getDeviceGroupByKey(key: 'id' | 'deviceGroupName', value: unknown): IDeviceGroupOptions {
        if (!this.hasEntities()) return null;
        return this.entities.find((deviceGroup) => deviceGroup[key] === value);
    }

    public async createDeviceGroupByName(accountId: number, deviceGroupName: string, params: TUpdateDeviceGroupOptions): Promise<IDeviceGroupOptions> {
        const created = await this.apiClient.createDeviceGroup(accountId, deviceGroupName, params);
        this._addNewDeviceGroup(params.deviceParentGroupName, created);
        return created;
    }

    public async updateDeviceGroupByName(accountId: number, deviceGroupName: string, params: TUpdateDeviceGroupOptions): Promise<boolean> {
        const updated = await this.apiClient.updateDeviceGroup(accountId, deviceGroupName, params);
        this._updateDeviceGroup(deviceGroupName, params);
        return updated;
    }

    public async deleteDeviceGroupByName(accountId: number, deviceGroupName: string): Promise<boolean> {
        const deleted = await this.apiClient.deleteDeviceGroup(accountId, deviceGroupName);
        this._deleteDeviceGroup(deviceGroupName);
        return deleted;
    }

    @action
    setTreeData(treeData: DataNode[]) {
        this.treeData = treeData;
    }

    getChildNode(accountRoot: IAccount): DataNode {
        let childNode: DataNode[];

        if (accountRoot == undefined) return undefined;

        const node: DataNode = {
            title: accountRoot.name,
            key: accountRoot.id.toString(),
            accountId: accountRoot.id,
            deviceGroupName: getDeviceGroupNameOfAccount(accountRoot),
            type: accountRoot.accountType == 'CLIENT' ? NodeType.DEVICEGROUP : NodeType.ACCOUNT,
            accountType: accountRoot.accountType,
        };

        if (accountRoot.accountType == 'CLIENT') {
            const clientGroup = this.getClientDeviceGroupDetails(accountRoot.id);
            childNode = clientGroup?.childrenGroups?.map((group: IDeviceGroupOptions) => ({
                title: group.deviceGroupLabel,
                key: `${group.deviceGroupName}`,
                accountId: accountRoot.id,
                deviceGroupName: group.deviceGroupName,
                type: NodeType.DEVICEGROUP,
                children: []
            })) || [];
        } else {
            childNode = accountRoot.subAccounts.map(sub => this.getChildNode(sub));
        }
        orderBy(childNode, [(dataNode) => dataNode.title.toLowerCase()]);
        return { ...node, children: childNode };
    }

    public getClientOwnDeviceGroup(deviceGroupName: string): IAccount {
        const deviceGroup = this.getDeviceGroupByName(deviceGroupName);
        if (!deviceGroup) return undefined;
        const clientDeviceGroupName = deviceGroup.parentGroup ? deviceGroup.parentGroup.deviceGroupName : deviceGroup.deviceGroupName;
        const clientAccountId = this._authStore.currentUser.managedAccounts.find(account => account.deviceGroupName == clientDeviceGroupName)?.id;
        return clientAccountId ? this._accountStore.getAccountById(clientAccountId) : undefined;
    }

    public initialTreeData(): void {
        const accountHierarchy = this._accountStore.accountHierarchy;
        const treeData = [this.getChildNode(accountHierarchy)];
        this.setTreeData(treeData);
    }

    async timezone(accountId: number, deviceGroup: string, timezone: string): Promise<boolean> {
        return this._apiClientStore.apiClients.devicegroups.timezone(accountId, deviceGroup, timezone);
    }

    public async moveDevicesToGroup(accountId: number, groupName: string, sourceGroupName: string, deviceNames: string[]): Promise<IUpdateDeviceResultOptions> {
        const result = await this.apiClient.moveDevicesToGroup(accountId, groupName, sourceGroupName, deviceNames);
        return {
            succeeded: result.filter(item => item.success).map(item => item.identifier),
            errors: result.filter(item => !item.success),
        };
    }

    public async getDeviceAttributes(accountId: number, groupName: string): Promise<IDeviceAttributesResult[]> {
        const result = await this._apiClientStore.apiClients.devicegroups.getDeviceAtrributes(accountId, groupName);
        return result.devices;
    }

    private getDetailsByDeviceGroupName(name: string): IDeviceGroupOptions {
        return this.entities.find(item => item.deviceGroupName == name);
    }

    @action
    private _addNewDeviceGroup(clientDeviceGroupName: string, createdChildGroup: IDeviceGroupOptions): void {
        const clientGroup = this.getDetailsByDeviceGroupName(clientDeviceGroupName);
        createdChildGroup.parentGroup = clientGroup;

        this.updateEntity<Pick<IDeviceGroupOptions, 'childrenGroups'>>(clientGroup.id, {
            childrenGroups: [...clientGroup.childrenGroups, createdChildGroup]
        });
        this.addEntity(createdChildGroup);
        this.initialTreeData();
    }

    @action
    private _deleteDeviceGroup(deviceGroupName: string): void {
        const deletedChildGroup = this.getDetailsByDeviceGroupName(deviceGroupName);
        const clientGroup = this.getEntity(deletedChildGroup.parentGroup.id);

        // Remove child group in CLIENT group
        this.updateEntity<Pick<IDeviceGroupOptions, 'childrenGroups'>>(clientGroup.id, {
            childrenGroups: [...clientGroup.childrenGroups.filter(item => item.deviceGroupName != deviceGroupName)]
        });
        this.removeEntity(deletedChildGroup.id);
        this.initialTreeData();
    }

    @action
    private _updateDeviceGroup(deviceGroupName: string, params: TUpdateDeviceGroupOptions): void {
        interface IUpdatedOptions extends Pick<IDeviceGroupOptions,
            'deviceGroupLabel' | 'deviceGroupDescription' |
            'deviceGroupAttribute' | 'deviceGroupTimezone'> {
        }

        const updatedDeviceGroup = this.getDetailsByDeviceGroupName(deviceGroupName);
        const updatedInformation: IUpdatedOptions = {
            deviceGroupLabel: params.deviceGroupLabel,
            deviceGroupAttribute: params.deviceGroupAttribute,
            deviceGroupDescription: params.deviceGroupDescription,
            deviceGroupTimezone: params.deviceGroupTimezone
        };
        this.updateEntity<IUpdatedOptions>(updatedDeviceGroup.id, updatedInformation);

        const clientGroup = this.getEntity(updatedDeviceGroup.parentGroup.id);

        const child = clientGroup.childrenGroups.find(item => item.id == updatedDeviceGroup.id);
        Object.assign(child, updatedInformation);
        // Remove child group in CLIENT group
        this.updateEntity<Pick<IDeviceGroupOptions, 'childrenGroups'>>(clientGroup.id, {
            childrenGroups: [...clientGroup.childrenGroups]
        });
        this.initialTreeData();
    }

    @action
    private resetTreeData() {
        this.treeData = [];
    }
}
