import { IApiClientOptions } from 'core';
import {
    ICreateMultiplePackagesResponseProps,
    ICreatePackageRequestProps,
    IPackageProps,
    IUngroupPackageRequestProps,
    IUpdatePackageGroupRequestProps,
} from '../dto/packages-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';

const packagesRootPath = 'packages';

export class PackagesApiClient extends AssureBaseApiClient {
    protected getRootPath(): string {
        return packagesRootPath;
    }

    constructor(options: IApiClientOptions) {
        super('packages', options);
    }

    public async getAllPackagesByAccountId(accountId: number): Promise<IPackageProps[]> {
        const result = await this.send<{ packages: IPackageProps[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, ''),
        );
        return result.packages;
    }

    public async createPackages(
        accountId: number,
        params: ICreatePackageRequestProps,
    ): Promise<ICreateMultiplePackagesResponseProps> {
        return this.send<ICreateMultiplePackagesResponseProps>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, '', params),
        );
    }

    public async ungroup(accountId: number, params: IUngroupPackageRequestProps): Promise<boolean> {
        const result = await this.send<{ success: true }>(
            HTTP_METHOD.PUT,
            this.getRequestOptionsWithAccountId(accountId, 'ungroup', { packageIds: [params.packageId] }),
        );
        return result.success;
    }

    public async updateGroup(accountId: number, params: IUpdatePackageGroupRequestProps): Promise<boolean> {
        const result = await this.send<{ success: true }>(
            HTTP_METHOD.PUT,
            this.getRequestOptionsWithAccountId(accountId, 'update-group', {
                packageGroupId: params.packageGroupId,
                packageIds: [params.packageId],
            }),
        );
        return result.success;
    }

    public async deletePackage(accountId: number, packageId: number): Promise<boolean> {
        const result = await this.send<{ success: true }>(
            HTTP_METHOD.DELETE,
            this.getRequestOptionsWithAccountId(accountId, `${packageId}`),
        );
        return result.success;
    }
}
