import * as React from 'react';
import { FormItem, getDefaultFormItemOptions, IFormItem } from './form-item';
import { RangeDateTimePicker, IRangeDateTimePickerProps } from 'ui-lib/components/datetime';

export interface IRangeDatetimePickerFormItemPros extends Omit<IRangeDateTimePickerProps, 'code' | 'className'>, IFormItem {
}

export const RangeDatetimePickerFormItem = (props: IRangeDatetimePickerFormItemPros): JSX.Element => {
    const formItemProps = getDefaultFormItemOptions(props, true);

    return (
        <FormItem
            code={props.code}
            {...formItemProps}
            child={
                <RangeDateTimePicker {...props} />
            }
        />
    );
};
