import React from 'react';
import repositoryCss from './repository.css';
import {
    IconTextButton,
    SelectFormItem,
    TextFieldItem,
    AdvanceEditableTable,
    IAdvanceTableToolbarProps,
    IEditableTableColumn,
    IEditableTableProps,
    Drawer,
    BasicForm,
    useForm,
    Spinner,
    showConfirmDialog,
    Label,
    Typography,
    notification,
} from 'ui-lib';
import { useAuthStore, usePackageGroupsStore } from '../../../../store';
import { TDisplayPackageGroupProps } from '../../../../store/package-groups-store';

import { IErrorProps, useRepositoryContext, DPU_REPOSITORY_TYPES_SOURCE } from './index';
import { confirmCancel, confirmSave } from '../../admin/utils';
import { AccountType } from '../../../../dto/access-management/account-dto';
import { PACKAGE_TYPE , FILTER_PACKAGE_TYPE } from '../../../../dto/packages-dto';
import { observer } from 'mobx-react';
import { cloneDeep } from 'lodash';

export interface IPackageGroupFormProps {
    onOk?: (values) => void;
    onCancel?: () => void;
    form?: any;
    validator?: any;
    accountType: string;
}

export interface IPackgeGroupDrawerProps {
    onSave: (values) => Promise<void>;
    accountType: string;
}

function confirmDelete(
    packageGroup: TDisplayPackageGroupProps,
    action: (packageGroup: TDisplayPackageGroupProps) => Promise<void>,
) {
    showConfirmDialog({
        title: 'Confirm delete',
        content: `Do you want to remove the package group "${packageGroup.label}"?`,
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: async () => action(packageGroup),
    });
}

export const PackageGroupForm = (props: IPackageGroupFormProps) => {
    return (
        <BasicForm
            className={repositoryCss.createPackageGroupForm}
            form={props.form}
            items={[
                <TextFieldItem
                    labelColSpan={24}
                    code='Name'
                    label='Group name'
                    isRequired={true}
                    placeholder='Name'
                    validator={props.validator}
                />,
                props.accountType === AccountType.DPU ? (
                    <SelectFormItem
                        labelColSpan={24}
                        code='repositoryType'
                        label='Type'
                        isRequired={true}
                        dataSource={DPU_REPOSITORY_TYPES_SOURCE}
                        selectedValue={'DPU'}
                    />
                ) : null,
                <div className={repositoryCss.control}>
                    <IconTextButton key='cancel' onClick={props.onCancel} label='Cancel' />
                    <IconTextButton key='save' type='primary' htmlType='submit' label='Save' />
                </div>,
            ]}
            onSubmit={props.onOk}
        />
    );
};

export const PackageGroupDrawer = (props: IPackgeGroupDrawerProps) => {
    const [visible, setVisible] = React.useState(false);
    const [saving, setSavingState] = React.useState<boolean>(false);
    const [form] = useForm();
    const onShowDrawer = () => setVisible(true);
    const onSave = async (values) => {
        setSavingState(true);
        try {
            await props.onSave(values);
        } finally {
            setSavingState(false);
            form.resetFields();
            setVisible(false);
        }
    };

    const onCancel = () => {
        confirmCancel(() => setVisible(false));
    };

    return (
        <Drawer
            title='Add new package group'
            trigger={
                <IconTextButton
                    className={repositoryCss.addNewBtn}
                    key='footer'
                    onClick={onShowDrawer}
                    label='Add new'
                />
            }
            visible={visible}
            onClose={onCancel}
            component={
                <Spinner spinning={saving}>
                    <PackageGroupForm
                        form={form}
                        onCancel={onCancel}
                        onOk={onSave}
                        accountType={props.accountType}
                    />
                </Spinner>
            }
        />
    );
};

export const PackageGroupsWrapper = observer(() => {
    const context = useRepositoryContext();

    const [form] = useForm();
    const packageGroupsStore = usePackageGroupsStore();
    const { currentUser, assureApiAccount } = useAuthStore();

    const [searchText, setSearchText] = React.useState<string>('');
    const [updatedRows, setUpdatedRows] = React.useState<{ id: number; label: string }[]>([]);
    const displayDataSource = React.useMemo<TDisplayPackageGroupProps[]>(() => {
        const dataSource = cloneDeep(packageGroupsStore.displayPackageGroups);
        for (const updatedRow of updatedRows) {
            const item = dataSource.find((item) => item.id == updatedRow.id);
            if (!item) continue;
            item.label = updatedRow.label;
        }
        return dataSource;
    }, [packageGroupsStore.displayPackageGroups, updatedRows]);

    const filteredDataSource = React.useMemo<TDisplayPackageGroupProps[]>(
        () =>
            displayDataSource.filter((item) => item.label.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())),
        [displayDataSource, searchText],
    );

    const [updating, setUpdating] = React.useState<boolean>(false);

    const clearUpdatedRows = () => {
        setUpdating(true);
        setUpdatedRows([]);
        setTimeout(() => setUpdating(false), 100);
    };

    const onAddNew = async (values) => {
        try {
            await packageGroupsStore.createGroup(
                assureApiAccount.accountId,
                values.Name,
                values.repositoryType || currentUser.accountType,
            );
        } catch (err) {
            notification.error({
                message: 'Add package group error',
                description: (
                    <Typography.Text>
                        {err.key}: {err.message}
                    </Typography.Text>
                ),
            });
        }
    };

    const nameComponent = (componentProps) => {
        const record: TDisplayPackageGroupProps = componentProps.record;
        const onChange = (values) => {
            setUpdatedRows((current) => {
                const row = current.find((item) => item.id == record.id);
                const label = values.target.value.trim();
                if (row) {
                    row.label = label;
                } else {
                    current.push({ id: record.id, label });
                }
                return [...current];
            });
        };

        return (
            <TextFieldItem
                label='Group name'
                isRequired={true}
                code={String.prototype.concat('groupName', record.id as any)}
                onChange={onChange}
                initialValue={record.label}
                disabled={
                    (currentUser.accountType === AccountType.CLIENT && record.type !== PACKAGE_TYPE.CLIENT) ||
                    (currentUser.accountType === AccountType.VAR &&
                        ![PACKAGE_TYPE.CLIENT, PACKAGE_TYPE.VAR].includes(record.type))
                }
            />
        );
    };

    const deletePackageGroup = async (group: TDisplayPackageGroupProps) => {
        try {
            await packageGroupsStore.delete(assureApiAccount.accountId, group.id);
        } catch (err) {
            notification.error({
                message: 'Delete package group error',
                description: (
                    <Typography.Text>
                        {err.key}: {err.message}
                    </Typography.Text>
                ),
            });
        }
    };

    const columns: IEditableTableColumn[] = [
        {
            code: 'packageGroupName',
            title: <Label className={repositoryCss.requiredField} label='Group name' />,
            dataIndex: 'packageGroupName',
            sorter: (a: TDisplayPackageGroupProps, b: TDisplayPackageGroupProps) => a.label?.localeCompare(b.label),
            width: '60%',
            component: nameComponent,
        },
        {
            code: 'type',
            title: 'Type',
            dataIndex: 'type',
            width: '10%',
            sorter: (a, b) => a.type.localeCompare(b.type),
            filters: Object.keys(FILTER_PACKAGE_TYPE).map((type) => ({ text: FILTER_PACKAGE_TYPE[type], value: type })),
            onFilter: (type, device) => device.type.toLowerCase() === type.toLowerCase(),
        },
        ...(currentUser.accountType !== AccountType.CLIENT
            ? [
                  {
                      code: 'accountName',
                      title: 'VAR/Client name',
                      dataIndex: 'accountName',
                      sorter: (a, b) => a.accountName?.localeCompare(b.accountName),
                      width: '20%',
                  },
              ]
            : []),
        {
            code: 'action',
            title: 'Action',
            dataIndex: 'action',
            width: '10%',
            component: (props) => {
                const record: TDisplayPackageGroupProps = props.record;
                return (
                    <IconTextButton
                        key='id'
                        label='Delete'
                        type='link'
                        onClick={() => confirmDelete(record, deletePackageGroup)}
                        disabled={
                            (currentUser.accountType === AccountType.CLIENT && record.type !== PACKAGE_TYPE.CLIENT) ||
                            (currentUser.accountType === AccountType.VAR &&
                                ![PACKAGE_TYPE.CLIENT, PACKAGE_TYPE.VAR].includes(record.type))
                        }
                    />
                );
            },
        },
    ];

    const tableProps: IEditableTableProps = {
        columns: columns,
        dataSource: filteredDataSource,
        extraOptions: {
            footer: () => <PackageGroupDrawer onSave={onAddNew} accountType={currentUser.accountType} />,
        },
        notUseRowForm: true,
    };

    const toolbar: IAdvanceTableToolbarProps = {
        searchPlaceholder: 'Search package groups',
        onReload: () => packageGroupsStore.loadEntities(assureApiAccount.accountId, true),
        onSearch: setSearchText,
    };

    const confirm = (action?: any) => {
        showConfirmDialog({
            title: 'Confirm',
            content: 'Are you sure you want to navigate back? Unsaved data will be lost.',
            okText: 'OK',
            cancelText: 'Cancel',
            onOk: context.toPackagesTable,
        });
    };

    const handleSave = async () => {
        const editList = updatedRows;
        const updatedErrors: IErrorProps[] = [];
        try {
            await Promise.all(
                editList.map(async (item) => {
                    try {
                        return await packageGroupsStore.updateGroup(assureApiAccount.accountId, item.id, item.label);
                    } catch (err) {
                        updatedErrors.push({
                            key: item.label,
                            message: err.message,
                        });
                        return false;
                    }
                }),
            );
            if (updatedErrors.length > 0) {
                notification.error({
                    message: 'Update package group errors',
                    description: updatedErrors.map((err) => (
                        <Typography.Text key={err.key}>
                            {err.key}: {err.message}
                        </Typography.Text>
                    )),
                });
            }
        } finally {
            clearUpdatedRows();
        }
    };

    return (
        <BasicForm
            form={form}
            preventEnterSubmit={true}
            onSubmit={() => confirmSave(undefined, 'Do you want to save your change?', handleSave)}
            items={[
                <React.Fragment>
                    <AdvanceEditableTable
                        title='All package groups'
                        className={repositoryCss.table}
                        table={tableProps}
                        loadingState={packageGroupsStore.dataLoading || updating}
                        toolbar={toolbar}
                    />
                    <div className={repositoryCss.row}>
                        <IconTextButton type='default' label='Back to software repository' onClick={confirm} />
                        <IconTextButton
                            key='save'
                            label='Save'
                            type='primary'
                            htmlType='submit'
                            disabled={packageGroupsStore.dataLoading}
                        />
                    </div>
                </React.Fragment>,
            ]}
        />
    );
});
