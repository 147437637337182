import * as React from 'react';
import { Modal as AntdModal } from 'antd';
import { IconName } from '@fortawesome/fontawesome-common-types';

import modalCss from './modal.css';
import { OnButtonClick } from '../buttons';
import cx from 'classnames';

export interface IModalActionOptions {
    label: string;
    isPrimary?: boolean;
    iconName?: IconName;
    onClick?: OnButtonClick;
}

export interface IModalProps {
    isVisible: boolean;
    isClosable?: boolean;
    className?: string;
    actions?: IModalActionOptions[];
    children?: React.ReactNode;
    onCancel?: () => void;
    onOk?: () => void;
    width?: any;
    title?: string;
    okText?: string;
    cancelText?: string;
    confirmLoading?: boolean;
    extraProps?: any;
}

export function Modal(props: IModalProps) {
    const extraProps = props.extraProps || {};
    if (!props.onCancel && !props.onOk) extraProps['footer'] = null;

    return (
        <AntdModal
            className={cx(modalCss.modal, props.className)}
            visible={props.isVisible}
            closable={props.isClosable ?? false}
            onCancel={props.onCancel}
            onOk={props.onOk}
            width={props.width}
            title={props.title}
            cancelText={props.cancelText}
            okText={props.okText}
            confirmLoading={props.confirmLoading}
            {...extraProps}
        >
            {props.children}
        </AntdModal>
    );
}
