import * as React from 'react';
import { Row as AntdRow, Col } from 'antd';
import cx from 'classnames';
import rowCss from './row.css';

export interface IRowItemProps {
    className?: string;
    gutter?: number | [number, number];
    childItems: React.ReactNode[];
    childCols: number[];
    childOffsets?: number[];
    style?: React.CSSProperties;
}

export const RowLayout = (props: IRowItemProps) => {
    return (
        <AntdRow className={cx(rowCss.rowLayout, props.className)} style={props.style} gutter={props.gutter}>
            {props.childItems.map((item, index) => {
                const colKey = 'col' + index;
                const spanNumber = props.childCols[index];
                const offset = props.childOffsets && props.childOffsets[index] ? props.childOffsets[index] : 0;
                return (
                    <Col key={colKey} span={spanNumber} offset={offset}>
                        {item}
                    </Col>
                );
            })}
        </AntdRow>
    );
};