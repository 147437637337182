import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import { PermissionPage, Error404 } from 'ui-lib';

import { ReportsComponent } from './reports';
// import { OperationalReportComponent } from './operational';
import { DeploymentsReportComponent } from './deployments';
import { FirmwareVersionsReportComponent } from './firmware-versions';
import { useRoutesStore, useStores } from '../../../../store';

import reportsCss from './reports.css';
import { SnapshotComparisonComponent } from './snapshot-comparison';

export enum REPORT_ROUTE_CODES {
    ROOT = 'ADMIN_REPORTS',
    // OPERATIONAL = 'ADMIN_REPORTS_OPERATIONAL',
    DEPLOYMENTS = 'ADMIN_REPORTS_DEPLOYMENTS',
    FIRMWARE_VERSIONS = 'ADMIN_REPORTS_FIRMWARE_VERSIONS',
    SNAPSHOT_COMPARISON = 'ADMIN_REPORTS_SNAPSHOT_COMPARISON',
}

export function ReportsModulePages() {
    const routeStore = useRoutesStore();
    const { permissionStore } = useStores();

    const rootRoute = routeStore.getRoute(REPORT_ROUTE_CODES.ROOT);
    // const operationalRoute = routeStore.getRoute(REPORT_ROUTE_CODES.OPERATIONAL);
    const deploymentRoute = routeStore.getRoute(REPORT_ROUTE_CODES.DEPLOYMENTS);
    const firmwareVersionRoute = routeStore.getRoute(REPORT_ROUTE_CODES.FIRMWARE_VERSIONS);
    const snapshotComparisonRoute = routeStore.getRoute(REPORT_ROUTE_CODES.SNAPSHOT_COMPARISON);

    return (
        <Routes>
            <Route
                path='/'
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={rootRoute.label}
                    >
                        <ReportsComponent />
                    </PermissionPage>
                }
            />
            {/* <Route
                path={operationalRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={operationalRoute.label}
                        className={reportsCss.reports}
                    >
                        <OperationalReportComponent />
                    </PermissionPage>
                }
            /> */}
            <Route
                path={deploymentRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={deploymentRoute.label}
                        className={reportsCss.reports}
                    >
                        <DeploymentsReportComponent />
                    </PermissionPage>
                }
            />
            <Route
                path={firmwareVersionRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={firmwareVersionRoute.label}
                        className={reportsCss.reports}
                    >
                        <FirmwareVersionsReportComponent />
                    </PermissionPage>
                }
            />
            <Route
                path={snapshotComparisonRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={snapshotComparisonRoute.label}
                        className={reportsCss.reports}
                    >
                        <SnapshotComparisonComponent />
                    </PermissionPage>
                }
            />
            <Route path='*' element={<Error404 message='Page Not Found' />} />
        </Routes>
    );
}
