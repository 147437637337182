import * as React from 'react';
import { Tabs as AntdTabs } from 'antd';

import tabCss from './basic-tab.css';

const { TabPane } = AntdTabs;

export interface IBasicTabComponent {
    defaultActiveIndex?: number;
    activeKey?: string;
    onChange?: (activeKey: string) => void;
    children: IBasicTabPaneComponent[];
}

export interface IBasicTabPaneComponent {
    label: string | React.ReactNode;
    key?: string;
    pane: React.ReactNode;
}

export function BasicTab(props: IBasicTabComponent) {
    return (
        <AntdTabs className={tabCss.tabWrapper} defaultActiveKey={`${props.defaultActiveIndex}`} activeKey={props.activeKey} onChange={props.onChange} >
            {props.children.map((child, index) => {
                return (<TabPane className={tabCss.tabPane} tab={child.label} key={child.key || index}>{child.pane}</TabPane>);
            })}
        </AntdTabs>
    );
}
