import React from 'react';
import { observer } from 'mobx-react';
import { Navigate, useNavigate } from 'react-router-dom';
import wizardCss from './wizard.css';
import { WizardStore } from '../../../../store/wizard-store';
import { useAccountStore, useApiClientStore, useAuthStore, useRoutesStore } from '../../../../store';
import { HeaderCommissioningWizardWrapper } from './header-wizard';
import { CommissioningWizardWrapper } from './wizard-tables';
import { Spinner, Modal, IconTextButton } from 'ui-lib';

import { DEFAULT_DEVICE_COLUMN, DevicesTableComponent } from '../../components/devices-table-component';
import { DEVICES_ROUTE_CODES } from '../';

interface ICommissionWizardProps {
    canAccess: boolean;
}

export const CommissioningWizard = observer((props: ICommissionWizardProps) => {
    const routeStore = useRoutesStore();
    const deviceInventoryRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.INVENTORY);

    if (!props.canAccess) return <Navigate to={deviceInventoryRoute.path} />;

    const apiStore = useApiClientStore();
    const { isDPUAdmin, currentUser } = useAuthStore();
    const accountStore = useAccountStore();

    const navigate = useNavigate();

    const wizard = React.useMemo(() => new WizardStore(accountStore, currentUser.managedAccounts, apiStore), []);

    const [headerState, setHeaderState] = React.useState({
        disabled: !isDPUAdmin,
        hiddenClientSelectItem: true,
        hiddenDPUAccountComponent: false,
    });

    const onUpdateWizardHeader = (disabledHeader: boolean, hiddenClientSelectItem: boolean) => {
        setHeaderState({
            disabled: !isDPUAdmin || disabledHeader,
            hiddenClientSelectItem,
            hiddenDPUAccountComponent: disabledHeader,
        });
    };

    const UnsuccessDevicesTable = () => {
        return (
            <DevicesTableComponent
                title={`Unsuccess Commissioning Devices (${wizard.unsuccessDevices.length})`}
                defaultSortColumn={{
                    key: DEFAULT_DEVICE_COLUMN.SERIAL,
                    sortOrder: 'descend',
                }}
                dataSource={wizard.unsuccessDevices}
                hiddenDefaultColumns={[
                    DEFAULT_DEVICE_COLUMN.DEVICE_GROUP,
                    DEFAULT_DEVICE_COLUMN.LAST_UPDATE,
                ]}
                extraColumns={[
                    {
                        code: 'commissioningError',
                        title: 'Errors',
                        dataIndex: 'errors',
                        render: (errors, record) =>
                            errors.map((error, index) => <p key={`${record.UID}_error_${index}`}>{error}</p>),
                    },
                ]}
                extraItems={[
                    <IconTextButton
                        key='closeUnsuccessCommissioning'
                        type='primary'
                        label='Close'
                        onClick={() => {
                            navigate(deviceInventoryRoute.path,
                                {
                                    state: wizard.createCommissioningDeviceSummary(),
                                },
                            );
                        }}
                    />,
                ]}
            />
        );
    };

    return (
        <div className={wizardCss.wrapper}>
            <React.Fragment>
                <h2>Commissioning Devices Wizard</h2>
                <HeaderCommissioningWizardWrapper store={wizard} header={headerState} />
                <Spinner
                    spinning={wizard.isCommissioning}
                    className={wizardCss.spinning}
                >
                    <CommissioningWizardWrapper
                        store={wizard}
                        updateHeaderComponent={onUpdateWizardHeader}
                        onFinish={() => {
                            if (wizard.unsuccessDevices.length > 0) return;
                            navigate(deviceInventoryRoute.path,
                                {
                                    state: wizard.createCommissioningDeviceSummary(),
                                },
                            );
                        }}
                    />
                </Spinner>
                <Modal
                    width={'90%'}
                    isVisible={wizard.unsuccessDevices.length > 0}
                >
                    <UnsuccessDevicesTable />
                </Modal>
            </React.Fragment>
        </div>
    );
});
