import React, { MouseEvent } from 'react';
import { ButtonHTMLType, ButtonType } from 'antd/lib/button/button';
import { Button as AntButton, Tooltip as AntdTooltip } from 'antd';
export type OnButtonClick = (ev: MouseEvent) => any | Promise<any>;

export interface IBaseButtonProps {
    label?: string;
    tooltip?: string;
    type?: ButtonType;
    size?: 'LARGE' | 'REGULAR';
    onClick?: OnButtonClick;
    block?: boolean;
    className?: string;
    loading?: boolean;
    htmlType?: ButtonHTMLType;
    disabled?: boolean;
}

export function BaseButton(props: IBaseButtonProps): JSX.Element {

    const isLarge = props.size === 'LARGE';

    return (
        <AntdTooltip title={props.tooltip}>
            <AntButton
                type={props.type ? props.type : 'default'}
                htmlType={props.htmlType ? props.htmlType : 'button'}
                size={isLarge ? 'large' : null}
                loading={props.loading}
                className={props.className}
                disabled={props.disabled}
                block={props.block}
                onClick={props.onClick}>
                {props.label}
            </AntButton>
        </AntdTooltip>
    );
}
