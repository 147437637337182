import * as React from 'react';
import cx from 'classnames';
import {Layout, Typography} from 'antd';
import footerCss from './footer.css';

export interface IFooterProps {
    version?: string;
    label?: string;
    stage?: string;
    className?: string;
}

export const Footer = (props: IFooterProps) => {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <Layout.Footer className={cx(footerCss.footer, props.className)}>
            <Typography.Text type="secondary">&#169; {year}
                {props.label ? ` - ${props.label}` : ''} {props.version ? ` - Version ${props.version}` : ''} {props.stage ? ` - ${props.stage}` : ''}
            </Typography.Text>
        </Layout.Footer>
    );
};