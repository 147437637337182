import * as React from 'react';
import {Card, Steps} from 'antd';
import { IStepItem } from './horizontal-stepper';
import verticalStepperCss from './vertical-stepper.css';

export interface IVerticalStepperProps {
    items: IStepItem[];
    currentStepCode?: string;
}

export function VerticalStepper(props: IVerticalStepperProps) {

    const [currentStepCode, setCurrentStepCode] = React.useState(
        props.currentStepCode || props.items[0].code
    );
    const currentStepIndex = props.items.findIndex(item => item.code === currentStepCode);

    React.useEffect(() => {
        setCurrentStepCode(props.currentStepCode);
    }, [props.currentStepCode]);

    return (
        <Card className={verticalStepperCss.verticalStepper}>
            <Steps
                size="small"
                type="default"
                current={currentStepIndex}
                direction='vertical'
            >
                {props.items.map((stepItem) => (
                        <Steps.Step
                            className={stepItem.className}
                            key={stepItem.code}
                            title={<div className={verticalStepperCss.stepItemTitle}>{stepItem.label}</div>}
                            status={stepItem.status ? stepItem.status : 'wait'}
                            description={<div className={verticalStepperCss.stepItemDescription}>{stepItem.description}</div>}
                        />
                ))}
            </Steps>
        </Card>
    );
}