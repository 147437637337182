import * as React from 'react';
import { Card } from 'antd';
import cx from 'classnames';

import { Skeleton } from '../loading';

import advanceTableCss from './advance-table.css';
import { buildTableToolbar, IAdvanceTableProps } from './advance-table';
import { EditableTable, IEditableTableProps } from './editable-table';

export interface IAdvanceEditTableProps extends Omit<IAdvanceTableProps, 'table'> {
    table: IEditableTableProps;
}

export function AdvanceEditableTable(props: IAdvanceEditTableProps) {
    const tableToolbar = buildTableToolbar({ ... props.toolbar });
    return (
        <Card className={cx(advanceTableCss.advanceTable, props.className)} title={props.title} bordered={false}
            extra={tableToolbar.length ? <div className={advanceTableCss.toolbar}>{tableToolbar}</div> : null} >
            <Skeleton loading={props.loadingState}>
                <EditableTable {...props.table} />
            </Skeleton>
        </Card>
    );
}
