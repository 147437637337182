import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
    BasicForm,
    ICardProps,
    TextFieldItem,
    SelectFormItem,
    FormItem,
    IconTextButton,
    MultipleSelect,
    DangerAlert,
    Skeleton,
    Spinner,
} from 'ui-lib';
import { getErrorMessage } from 'common-utils';

import { deleteAccount, confirmCancel } from '../utils';
import { useAccountStore, useAuthStore, useRoutesStore } from '../../../../store';
import { SelectDataSource } from '../../../../store/assure-base.store';
import {
    IEditAccountOptions,
    ISaveAccountOptions,
    IEditSubAccountOptions,
    AccountType,
} from '../../../../dto/access-management/account-dto';
import { IEnvironment } from '../../../../dto/access-management/environment-dto';
import { ACCOUNT_ROUTE_CODES } from './';

import manageAccountCss from './account.css';
import { inputLabelRule } from 'pages/private/components/form-utils';

const toEnvironmentInViewFn = (env: IEnvironment) => ({
    id: env.id,
    value: env.environmentStage,
    label: env.environmentStage,
    apiEndpoint: env.apiEndpoint,
});

function getSubAccountType(parentType: AccountType) {
    switch (parentType) {
        case 'DPU':
            return AccountType.VAR;
        case 'VAR':
            return AccountType.CLIENT;
        default:
            return undefined;
    }
}

const ACCOUNT_TYPES = [
    { value: 'DPU', label: 'DPU' },
    { value: 'VAR', label: 'VAR' },
    { value: 'CLIENT', label: 'CLIENT' },
];

interface IAddOrEditAccountComponentProps {
    isEdit?: boolean;
    cardPros?: ICardProps;
    accountInfo: IEditAccountOptions;
}

const SaveAccount = (props: IAddOrEditAccountComponentProps) => {
    const accountStore = useAccountStore();
    const { currentUser } = useAuthStore();
    const navigate = useNavigate();

    const routeStore = useRoutesStore();
    const rootRoute = routeStore.getRoute(ACCOUNT_ROUTE_CODES.ROOT);

    const selectedSubAccountLable = props.accountInfo.type === AccountType.DPU ? 'VAR' : 'Client';

    const [allEnvironments, setAllEnvironments] = React.useState(props.accountInfo.selectedEnvironments);
    const [selectedEnvironments, setSelectedEnvironments] = React.useState(props.accountInfo.selectedEnvironments);

    const [allSubAccounts, setAllSubAccounts] = React.useState([]);
    const [selectedSubAccounts, setSelectedSubAccounts] = React.useState<IEditSubAccountOptions[]>(props.accountInfo.selectedSubAccounts);

    const [allParentAccounts, setAllParentAccounts] = React.useState<SelectDataSource[]>([]);
    const [selectedParentAccountId, setSelectedParentAccountId] = React.useState<number>();

    const [isProcessing, setIsProcessing] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const onLoad = () => {
        (() => {
            try {
                setIsProcessing(true);
                const varAccountDataSource = accountStore.getVARAccountDataSource(currentUser.accountType);
                setAllParentAccounts(varAccountDataSource);
                const varAccountId = props.accountInfo.parentAccount?.id || (varAccountDataSource[0]?.value as number);
                setSelectedParentAccountId(varAccountId);
                const parentEnvironments = accountStore
                    .findAccount(varAccountId)
                    ?.environments.map(toEnvironmentInViewFn);
                setAllEnvironments(parentEnvironments);
                setSelectedEnvironments(parentEnvironments);
                if (props.isEdit) {
                    setAllSubAccounts(accountStore.getSubAccount(getSubAccountType(props.accountInfo.type)));
                    setSelectedEnvironments(props.accountInfo.selectedEnvironments);
                }
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            } finally {
                setIsProcessing(false);
            }
        })();
    };

    React.useEffect(onLoad, []);

    const getValidatorAccountNameMessage = (parentId: number, newName: string): string => {
        if (props.accountInfo.name.toLocaleLowerCase() === newName.toLocaleLowerCase()) return undefined;
        const parentAccount = accountStore.getAccountById(parentId);
        const found = parentAccount?.subAccounts?.find(
            (sub) => sub.name.toLocaleLowerCase() === newName.toLocaleLowerCase(),
        );
        return found === undefined ? undefined : `${newName} exists in ${parentAccount.name} already`;
    };

    const onParentChange = (accountId: number) => {
        setSelectedParentAccountId(accountId);
        const environments = accountStore.findAccount(accountId)?.environments.map(toEnvironmentInViewFn);
        setAllEnvironments(environments);
        setSelectedEnvironments(environments);
    };

    const onRemoveEnvironment = (values: any[]) =>
        setSelectedEnvironments(() => allEnvironments.filter((item) => values.includes(item.value)));

    const onRemoveSubAccount = (values: any[]) =>
        setSelectedSubAccounts(() => allSubAccounts.filter((item) => values.includes(item.value)));

    const onSave = async (values: any) => {
        try {
            setIsProcessing(true);
            const accountInfo: ISaveAccountOptions = {
                id: props.accountInfo.id,
                name: values.name,
                type: props.accountInfo.type,
                parentAccountId: selectedParentAccountId,
                selectedEnvironments: selectedEnvironments.map((env) => ({
                    id: env.id,
                    stage: env.label,
                    apiEndpoint: env.apiEndpoint,
                    deviceGroupName: values.name,
                })),
                selectedSubAccounts: selectedSubAccounts.map((item) => ({
                    id: item.id,
                    type: item.type,
                    name: item.value,
                })),
            };

            await accountStore.saveAccount(props.accountInfo.id, accountInfo);

            navigate(rootRoute.path);
        } catch (err) {
            setErrorMessage(getErrorMessage(err));
        } finally {
            setIsProcessing(false);
        }
    };

    const ParentAccountField = observer(() => (
        <SelectFormItem
            code='parentAccount'
            label='Parent'
            labelAlign='right'
            isRequired={true}
            selectedValue={selectedParentAccountId}
            dataSource={allParentAccounts}
            onChange={onParentChange}
        />
    ));

    const accountTypeField = (
        <SelectFormItem
            code='type'
            label='Account Type'
            labelAlign='right'
            isRequired={true}
            disabled={true}
            selectedValue={props.accountInfo.type}
            dataSource={ACCOUNT_TYPES}
        />
    );

    const onCancel = () => {
        confirmCancel(() => navigate(rootRoute.path));
    };

    const accountNameValidator = (rule, value, callback) =>
        callback(getValidatorAccountNameMessage(selectedParentAccountId, value));
    const name = (
        <TextFieldItem
            code='name'
            label='Account Name'
            labelAlign='right'
            isRequired={true}
            initialValue={props.accountInfo.name}
            rule={inputLabelRule(128)}
            validator={accountNameValidator}
            onChange={null}
        />
    );

    const cancelButton = <IconTextButton key='cancel' label='Cancel' onClick={onCancel} />;

    const submitButton = (
        <IconTextButton key='save' label='Save' type='primary' htmlType='submit' loading={isProcessing} />
    );

    const EnvironmentsField = observer(() => (
        <FormItem
            code='environments'
            label='Environments'
            labelAlign='right'
            labelCol={{ span: 5 }}
            colon={false}
            child={
                <MultipleSelect
                    selectedValue={selectedEnvironments}
                    dataSource={allEnvironments}
                    onChange={onRemoveEnvironment}
                    disabled={true}
                />
            }
        />
    ));

    const ClientsField = observer(() => (
        <FormItem
            code='clients'
            label={`${selectedSubAccountLable}s`}
            labelAlign='right'
            labelCol={{ span: 5 }}
            colon={false}
            child={
                <div className={manageAccountCss.control}>
                    <MultipleSelect
                        selectedValue={selectedSubAccounts}
                        dataSource={allSubAccounts}
                        onChange={onRemoveSubAccount}
                        disabled={true}
                    />
                </div>
            }
        />
    ));

    const formFunctions = <div className={manageAccountCss.control}>{[cancelButton, submitButton]}</div>;
    const parentAccountField = props.isEdit ? null : <ParentAccountField />;
    const clientsField = props.accountInfo.type === AccountType.CLIENT ? null : <ClientsField />;

    return (
        <React.Fragment>
            {errorMessage ? <DangerAlert message={errorMessage} className={manageAccountCss.alert} /> : null}
            <Spinner className={manageAccountCss.spinner} spinning={isProcessing}>
                <BasicForm
                    key='addOrEdit'
                    className={manageAccountCss.save_form}
                    cardPros={props.cardPros}
                    items={[
                        name,
                        parentAccountField,
                        accountTypeField,
                        <EnvironmentsField />,
                        clientsField,
                        formFunctions,
                    ]}
                    onSubmit={onSave}
                />
            </Spinner>
        </React.Fragment>
    );
};

export function AddAccountComponent() {
    return (
        <SaveAccount
            cardPros={{ bordered: false }}
            accountInfo={{
                name: '',
                type: AccountType.CLIENT,
                selectedEnvironments: [],
                selectedSubAccounts: [],
            }}
        />
    );
}

export function EditAccountComponent() {
    const accountStore = useAccountStore();
    const navigate = useNavigate();

    const routeStore = useRoutesStore();
    const rootRoute = routeStore.getRoute(ACCOUNT_ROUTE_CODES.ROOT);

    const [editAccount, setEditAccount] = React.useState<IEditAccountOptions>(null);
    const [loadingAccount, setLoadingAccount] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const { id } = useParams();

    const onLoad = () => {
        try {
            setLoadingAccount(true);
            const accountId = parseInt(id);
            const editAccount = accountStore.getAccountById(accountId);
            if (!editAccount) {
                setErrorMessage(`Could not found account with code: ${accountId}`);
                return null;
            }

            const parentAccount = accountStore.getParentAccount(accountId);
            setEditAccount({
                id: editAccount.id,
                name: editAccount.name,
                type: editAccount.accountType,
                selectedEnvironments: !editAccount.environments
                    ? []
                    : editAccount.environments.map(toEnvironmentInViewFn),
                selectedSubAccounts: !editAccount.subAccounts
                    ? []
                    : editAccount.subAccounts.map((account) => ({
                        id: account.id,
                        value: account.name,
                        label: account.name,
                    })),
                parentAccount: parentAccount,
            });
        } catch (err) {
            setErrorMessage(getErrorMessage(err));
        } finally {
            setLoadingAccount(false);
        }
    };
    React.useEffect(onLoad, []);

    const onDelete = async (id: number) => {
        try {
            await accountStore.deleteAccount(id);
            navigate(rootRoute.path);
        } catch (err) {
            setErrorMessage(getErrorMessage(err));
        }
    };
    const deleteButton = (
        <IconTextButton
            label='Delete'
            type='link'
            htmlType='button'
            onClick={() => deleteAccount(editAccount.id, onDelete)}
        />
    );

    return (
        <React.Fragment>
            {errorMessage ? <DangerAlert message={errorMessage} className={manageAccountCss.alert} /> : null}
            <Skeleton loading={loadingAccount}>
                {!editAccount ? null : (
                    <SaveAccount
                        isEdit={true}
                        cardPros={{
                            title: `${editAccount.name} account`,
                            extra: editAccount.type === AccountType.CLIENT ? deleteButton : undefined,
                        }}
                        accountInfo={editAccount}
                    />
                )}
            </Skeleton>
        </React.Fragment>
    );
}
