import * as React from 'react';
import { Checkbox } from 'antd';
import { CheckboxOptionType, CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FormItem, getDefaultFormItemOptions, IFormItem } from '../forms/items';

export interface ICheckboxGroupProps extends IFormItem {
    options: CheckboxOptionType[];
    value: CheckboxValueType[];
    defaultValue: CheckboxValueType[];
    onChange?: (event) => void;
}

export const CheckboxGroupItem = (props: ICheckboxGroupProps) => {
    const CheckboxGroup = Checkbox.Group;
    const formItemProps = getDefaultFormItemOptions(props);
    return (
        <FormItem code={props.code} {...formItemProps} initialValue={props.defaultValue} child={
            <CheckboxGroup defaultValue={props.defaultValue} options={props.options} onChange={props.onChange} value={props.value} />
        } />
    );
};
