import * as React from 'react';
import cx from 'classnames';
import { Form, Card } from 'antd';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CardSize } from 'antd/lib/card';
import basicFormCss from './basic-form.css';

export interface ICardProps {
    title?: React.ReactNode;
    size?: CardSize;
    bordered?: boolean;
    extra?: React.ReactNode;
}
export interface IBasicFormProps {
    cardPros?: ICardProps;
    items: React.ReactNode[];
    className?: string;
    title?: React.ReactNode;
    onSubmit?: any;
    onError?: any;
    validateTrigger?: any;
    form?: any;
    preventEnterSubmit?: boolean;
    onFieldsChange?: any;
    scrollToFirstError?: boolean;
}

const preventEnterKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
    }
};

export function BasicForm(props: IBasicFormProps) {
    return (
        <Card
            className={basicFormCss.wrapper}
            bordered={props.cardPros?.bordered}
            size={props.cardPros?.size || 'small'}
            title={props.cardPros?.title}
            extra={props.cardPros?.extra}
        >
            <Form
                className={cx(basicFormCss.basicForm, props.className)}
                layout='horizontal'
                size='middle'
                scrollToFirstError={props.scrollToFirstError}
                onFinish={props.onSubmit}
                onError={props.onError}
                validateTrigger={props.validateTrigger}
                onKeyDown={props.preventEnterSubmit ? preventEnterKeyDown : undefined}
                onFieldsChange={props.onFieldsChange}
                form={props.form}
            >
                {props.items.map((item, index) => {
                    return <React.Fragment key={index}>{item}</React.Fragment>;
                })}
            </Form>
        </Card>
    );
}
export { useForm } from 'antd/lib/form/Form';
