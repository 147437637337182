import React from 'react';

import { ITreeSelectDataSource, TreeSelect, FormItem, getDefaultFormItemOptions, IFormItem } from 'ui-lib';
import { useAccountTreeSelectDataSource } from '../admin/accounts';
import { AccountType, IAccount } from 'dto/access-management/account-dto';
import { useAccountStore, useAuthStore } from 'store';
import { observer } from 'mobx-react';

export interface IOrganisationSelectProps {
    className?: string;
    placeholder?: string;
    expandAll?: boolean;
    allowClear?: boolean;
    value?: number;
    treeDataSource: ITreeSelectDataSource | ITreeSelectDataSource[];
    disabled?: boolean;
    onChange?: (value: any, labelList: React.ReactNode[], extra: any) => void;
}

export interface IOrganisationSelectItemProps extends IFormItem {
    selectProps: IOrganisationSelectProps;
}

export const OrganisationSelect = (props: IOrganisationSelectProps) => {
    return (
        <TreeSelect
            className={props.className}
            key='organisation'
            placeholder={props.placeholder || 'Organisation'}
            allowClear={props.allowClear}
            expandAll={props.expandAll}
            value={props.value}
            dataSource={props.treeDataSource}
            disabled={props.disabled}
            onChange={props.onChange}
        />
    );
};

export const OrganisationSelectItem = (props: IOrganisationSelectItemProps) => {
    const formItemProps = getDefaultFormItemOptions(props);

    return (
        <FormItem
            code={props.code}
            {...formItemProps}
            initialValue={props.selectProps.value}
            child={
                <TreeSelect
                    key='organisation'
                    placeholder={props.selectProps.placeholder}
                    allowClear={props.selectProps.allowClear}
                    expandAll={props.selectProps.expandAll}
                    value={props.selectProps.value}
                    dataSource={props.selectProps.treeDataSource}
                    disabled={props.disabled ?? props.selectProps.disabled}
                    onChange={props.selectProps.onChange}
                />
            }
        />
    );
};

interface IOrganisationTreeSelectItem {
    selectableTypes?: AccountType[];
    onSelectedAccountChange?: (account: IAccount) => unknown;
    setHiddenState?: (userAccountType: AccountType | string) => boolean;
    defaultAccountId?: number;
    
}

export const OrganisationTreeSelectItem = observer((options: IOrganisationTreeSelectItem): JSX.Element => {
    const { selectableTypes, onSelectedAccountChange, setHiddenState, defaultAccountId } = options;
    const dataSource = useAccountTreeSelectDataSource({
        selectableTypes: selectableTypes,
    });

    const { currentUser } = useAuthStore();
    const accountStore = useAccountStore();

    const [selectedAccount, setSelectedAccount] = React.useState<IAccount>(() => {
        if (currentUser.accountType === AccountType.CLIENT) {
            return accountStore.getAccountById(currentUser.accountId);
        }
        if (defaultAccountId) {
            return accountStore.getAccountById(defaultAccountId);
        }
        return undefined;
    });

    const onAccountChange = (accountId: number): void => {
        const account = accountStore.getAccountById(accountId);
        setSelectedAccount(account);
    };

    React.useEffect(() => {
        onSelectedAccountChange && onSelectedAccountChange(selectedAccount);
    }, [selectedAccount]);

    return (
        <OrganisationSelectItem
            code='account'
            label='Account'
            labelAlign='right'
            isRequired={true}
            hidden={setHiddenState && setHiddenState(currentUser.accountType)}
            selectProps={{
                treeDataSource: dataSource,
                placeholder: 'Account',
                expandAll: true,
                onChange: onAccountChange,
                value: selectedAccount?.id,
            }}
        />
    );
});