import * as React from 'react';
import { Input } from 'antd';
import { FormItem, getDefaultFormItemOptions, IFormItem } from './form-item';

export interface ITextFieldItemProps extends IFormItem {
    initialValue?: string;
    onChange?: (value: any) => void;
    placeholder?: string;
    prefixOutline?: React.ReactNode;
    onPressEnter?: (values: any) => void;
    value?: string;
    allowClear?: boolean;
}

export const TextFieldItem = (props: ITextFieldItemProps) => {
    const formItemProps = getDefaultFormItemOptions(props, true);

    return (
        <FormItem
            code={props.code}
            initialValue={props.initialValue}
            validateTrigger={props.validateTrigger}
            {...formItemProps}
            child={
                <Input
                    className={props.className}
                    name={props.code}
                    placeholder={props.placeholder}
                    allowClear={props.allowClear}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    onPressEnter={props.onPressEnter}
                />
            }
        />
    );
};
