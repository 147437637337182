import * as React from 'react';
import { Collapse } from 'antd';
import horizontalCollapseCss from './horizontal-collapse-layout.css';
import cx from 'classnames';
import { PanelProps } from '../collapse';
const { Panel } = Collapse;

export interface IHorizontalCollapseLayoutProps {
    leftPanel: React.ReactNode;
    panelClassName?:string;
    leftPanelClassName?: string;
    rightPanelClassName?: string;
    rightPanel: React.ReactNode;
    expandIconPosition?: 'start' | 'end';
    expandIcon?: (panelProps: PanelProps) => React.ReactNode;
    defaultExpand?: boolean;
}

export const HorizontalCollapseLayout = (props: IHorizontalCollapseLayoutProps): JSX.Element => {
    const { leftPanel, rightPanel, defaultExpand, panelClassName, leftPanelClassName, rightPanelClassName, expandIcon, expandIconPosition } = props;
    const [expanded, setExpand] = React.useState<boolean>(defaultExpand || false);

    const toggle = (): void => setExpand(!expanded);

    return (
        <div className={cx(panelClassName, horizontalCollapseCss['panel'])}>
            <div key='left-panel' className={cx(leftPanelClassName, horizontalCollapseCss['collapse'], horizontalCollapseCss[`horizontal-collapse${expanded ? '' : '-collapsed'}`])}>
                <Collapse className={horizontalCollapseCss['collapse']} bordered={false} activeKey={expanded ? '1' : ''} onChange={toggle} expandIcon={expandIcon} expandIconPosition={expandIconPosition}>
                    <Panel header="" key="1">
                        {expanded && leftPanel}
                    </Panel>
                </Collapse>
            </div>
            <div key='right-panel' className={cx(rightPanelClassName, horizontalCollapseCss['right-panel'])}>
                {rightPanel}
            </div>
        </div>
    );
};
