import * as React from 'react';
import cx from 'classnames';
import { Skeleton as AntdSkeleton } from 'antd';

import skeletonCss from './skeleton.css';

export interface ISkeletonProps {
    children?: React.ReactNode;
    loading: boolean;
    avatar?: boolean;
    className?: string;
    noOfRows?: number;
}

export function Skeleton(props: ISkeletonProps) {
    return (
        <AntdSkeleton
            loading={props.loading}
            active
            paragraph={props.noOfRows !== 1 ? { rows: props.noOfRows || 6 } : false}
            title
            className={cx(skeletonCss.skeleton, props.className)}
            avatar={props.avatar}
        >
            {props.children}
        </AntdSkeleton>
    );
}