import * as React from 'react';
import { Input } from 'antd';

import searchFieldCss from './search-field.css';

export type OnSearch = (value: string, ev?: any) => void;

export interface ISearchFieldProps {
    code?: string;
    allowClear?: boolean;
    placeholder: string
    onSearch?: OnSearch;
    value?: string;
    onChange?: (event) => void;
}

export const SearchField = (props: ISearchFieldProps) => {
    return (
        <Input.Search
            className={searchFieldCss.searchField}
            allowClear={props.allowClear}
            placeholder={props.placeholder}
            onSearch={props.onSearch}
            value={props.value}
            onChange={props.onChange}
        />
    );
};
