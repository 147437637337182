import * as React from 'react';
import { passwordStrength } from 'check-password-strength';

const LEADING_TRAILING_SPACES_ERROR =
    'Leading and trailing spaces are not allowed. A non-leading, non-trailing space character is also treated as a special character.';
const INVALID_PASSWORD_ERROR =
    'Password must be at least 8 characters and contain uppercase letters, lowercase letters, numbers and symbols';

const isStrengthPassword = (password: string): boolean => passwordStrength(password).id >= 2;

export const sleep = async (ms: number): Promise<any> => new Promise((resolve) => setTimeout(resolve, ms));

export const usePromiseMemo = <T>(defaultValue: T, callback: () => Promise<T>, deps: any[]) => {
    const [state, setState] = React.useState<T>(defaultValue);
    React.useEffect(() => {
        callback().then((result) => setState(result));
    }, deps);
    return state;
};

function checkPassword(password: string): string {
    const hasLeadingAndTrailingSpaces = new RegExp(/(^ +)|( +$)/).test(password);
    if (hasLeadingAndTrailingSpaces) return LEADING_TRAILING_SPACES_ERROR;
    if (password.length > 256) return 'Password is too long.';
    return undefined;
}

export const passwordValidator = (rule, value, callback) =>
    callback(checkPassword(value) ?? (isStrengthPassword(value) ? undefined : INVALID_PASSWORD_ERROR));

export const getDisplayCRC = (crcValue: string): string => {
    const crcTemplate = '00000000';
    return crcTemplate.substring(crcValue?.length || 0) + crcValue;
};