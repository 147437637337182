import React from 'react';
import { observer } from 'mobx-react';

import {
    AdvanceTable,
    IBasicTableColumn,
    IBasicTableProps,
    IconTextButton,
    Select,
    Typography,
    showInformationDialog,
} from 'ui-lib';

import { useAccountStore, useAuthStore, useKeyStore } from '../../../store';
import { IAccount, AccountType } from '../../../dto/access-management/account-dto';
import { OrganisationSelect } from '../components/organisation-select';
import { useAccountTreeSelectDataSource } from '../admin/accounts';
import { pspTypesForClient } from './key-utils';
import { IKcvPspKeyResponse, KCVType } from 'dto/key-management/key-dto';
import { isNullOrEmpty } from 'common-utils';

enum KCV_KEYS_STATE {
    CLIENTS = 'clients',
    KCV_INFORMATION = 'kcvInfo',
}

const MISSING_KEYS_ERROR_MESSAGE = 'This client have missing keys';

export const KcvPspKeyPage = observer(() => {
    const { currentUser } = useAuthStore();
    const accountStore = useAccountStore();
    const varAccountSource = useAccountTreeSelectDataSource({
        viewableTypes: [AccountType.DPU, AccountType.VAR],
        selectableTypes: [AccountType.VAR],
    });
    const [displayState, setDisplayState] = React.useState<KCV_KEYS_STATE>(KCV_KEYS_STATE.CLIENTS);
    const [selectedClientId, setSelectedClientId] = React.useState();
    const [clientSource, setClientSource] = React.useState<IAccount[]>([]);

    React.useEffect(() => {
        let accountId: number;
        switch (currentUser.accountType) {
            case AccountType.VAR:
                accountId = currentUser.accountId;
                break;
            case AccountType.CLIENT: {
                accountId = currentUser.accountId;
                break;
            }
            case AccountType.DPU:
            default:
                accountId = null;
                break;
        }

        setClientSource(accountStore.getClientsForAccount(accountId));
    }, []);

    const organisationChangeHandler = (value) => {
        setDisplayState(KCV_KEYS_STATE.CLIENTS);
        setClientSource(accountStore.getClientsForAccount(value));
    };

    const onClientSelect = (record) => {
        setSelectedClientId(record.id);
        setDisplayState(KCV_KEYS_STATE.KCV_INFORMATION);
    };

    const clientTableColumns: IBasicTableColumn[] = [
        {
            code: 'name',
            title: 'Client Name',
            dataIndex: 'name',
            render: (text: string, record: any) => (
                <IconTextButton type='link' htmlType='button' onClick={() => onClientSelect(record)} label={text} />
            ),
        },
    ];

    const table: IBasicTableProps = {
        columns: clientTableColumns,
        dataSource: clientSource,
    };

    return (
        <div>
            {currentUser.accountType === AccountType.DPU && (
                <OrganisationSelect
                    treeDataSource={varAccountSource}
                    onChange={organisationChangeHandler}
                    expandAll={true}
                    disabled={accountStore.dataLoading}
                />
            )}
            {displayState === KCV_KEYS_STATE.CLIENTS && (
                <AdvanceTable loadingState={accountStore.dataLoading} title={''} table={table} />
            )}
            {displayState === KCV_KEYS_STATE.KCV_INFORMATION && (
                <KcvKeyComponent
                    clientAccountId={selectedClientId}
                    onBack={() => setDisplayState(KCV_KEYS_STATE.CLIENTS)}
                />
            )}
        </div>
    );
});

interface IKcvKeyComponentProps {
    clientAccountId: number;
    onBack: () => void;
}
const KcvKeyComponent = observer((props: IKcvKeyComponentProps) => {
    const { clientAccountId, onBack } = props;
    const accountStore = useAccountStore();
    const keyStore = useKeyStore();
    const [loading, setLoading] = React.useState<boolean>(false);

    const { pspTypes, selectedPsp, updateSelectedPsp, kcvTypes } = pspTypesForClient(accountStore, keyStore, clientAccountId);

    const clientAccount = React.useMemo(() => accountStore.getAccountById(clientAccountId), [clientAccountId]);
    const [kcvKeys, setKcvKeys] = React.useState<IKcvPspKeyResponse>({
        kcvBDK: 'N/A',
        kcvKeyTableKey: 'N/A',
        kcvListHMAC: 'N/A',
        ksid: 'N/A',
    });

    React.useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                if (!selectedPsp) {
                    return [];
                }
                setKcvKeys(await keyStore.getKcvPspKeys(clientAccount.uuid, selectedPsp));
            } catch (error) {
                const message = error.message.match('cannot be found') ? MISSING_KEYS_ERROR_MESSAGE : error.message;
                showInformationDialog({ modalType: 'error', title: 'Error', content: message });
            } finally {
                setLoading(false);
            }
        })();
    }, [selectedPsp]);

    const kcvTableColumns: IBasicTableColumn[] = [
        {
            code: KCVType.KSID,
            title: 'Key set ID',
            dataIndex: KCVType.KSID,
        },
        {
            code: KCVType.BDK,
            title: 'KCV for BDK',
            dataIndex: KCVType.BDK,
        },
        {
            code: KCVType.LHMAC,
            title: 'KCV for List HMAC',
            dataIndex: KCVType.LHMAC,
        },
        {
            code: KCVType.KEY_TABLE_KEY,
            title: 'KCV for Key Table Key',
            dataIndex: KCVType.KEY_TABLE_KEY,
        },
    ];

    return (
        <div>
            <AdvanceTable
                title={clientAccount?.name}
                loadingState={loading}
                toolbar={{
                    extraItems: [
                        <div key='select-psp-div'>
                            <Typography.Text>PSP </Typography.Text>
                            <Select
                                key='select-psp'
                                onChange={updateSelectedPsp}
                                dataSource={pspTypes.map((item) => ({ value: item, label: item }))}
                                value={selectedPsp}
                            />
                        </div>,
                    ],
                }}
                table={{ columns: kcvTableColumns.filter(column => {
                    if (isNullOrEmpty(kcvTypes)) {
                        return true;
                    }
                    return kcvTypes.includes(column.code);
                }), dataSource: [kcvKeys], extraOptions: { pagination: false } }}
            />
            <IconTextButton label='Back' onClick={onBack} disabled={loading} />
        </div>
    );
});
