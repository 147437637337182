import * as React from 'react';
import { Select as AntdSelect } from 'antd';

import { FormItem, getDefaultFormItemOptions, IFormItem } from './form-item';

export interface IMultipleSelectItemProps extends IFormItem {
    placeholder?: string;
    dataSource: { value: number | string, label: string }[];
    selectedValue?: { value: number | string, label: string }[] | number[] | string[];
    onChange?: (value, option) => void;
    filterOption?: (inputValue, option) => boolean;
}

export const MultipleSelectFormItem = (props: IMultipleSelectItemProps) => {
    const formItemProps = getDefaultFormItemOptions(props);
    formItemProps.initialValue = props.selectedValue;
    return (
        <FormItem code={props.code} {...formItemProps} initialValue={props.selectedValue} child={
            <AntdSelect
                mode="multiple"
                allowClear={true}
                placeholder={props.placeholder}
                onChange={props.onChange}
                filterOption={props.filterOption}>
                {props.dataSource.map(item => <AntdSelect.Option key={item.value} value={item.value}>{item.label}</AntdSelect.Option>)}
            </AntdSelect>
        } />
    );
};

