import * as React from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { RangeDateTimePicker, IBasicTableColumn } from 'ui-lib';
import { DATE_TIME_FORMAT, formatDate } from 'common-utils/date-utils';
import { hasContainsIgnoreCase } from 'common-utils/string-utils';

dayjs.extend(isBetween);

/**
 * @property {string} title - The title of column
 * @property {string} displayKey - The value will be display in UI
 * @property {string} compareKey - The value use to compare (in milliseconds)
 * @property {string} columnExtraOptions - The extra options want to config or override basic config
 */
export interface IBasicFilterTimeColumnOptions {
    title: string;
    displayKey: string;
    compareKey?: string;
    columnExtraOptions?: any;
}

/**
 * Build the basic column with filter and sorter for time format
 * {@link IBasicFilterTimeColumnOptions}
 * @param options The input options to config column
 * @returns The basic table column
 */
export function createBasicFilterTimeColumn<T>(options: IBasicFilterTimeColumnOptions): IBasicTableColumn {
    const { displayKey, title, compareKey = options.displayKey } = options;
    return {
        code: displayKey,
        title: title,
        dataIndex: displayKey,
        sorter: (a: T, b: T) => a[compareKey] - b[compareKey],
        onFilter: (value, record) =>
            record[compareKey] ? dayjs(record[compareKey], 'DD/MM/YYYY HH:mm:ss').isBetween(value[0], value[1]) : false,
        filterDropdown: ({ setSelectedKeys, confirm }) => (
            <RangeDateTimePicker
                allowClear
                onChange={(e) => {
                    setSelectedKeys(e?.length ? [e as any] : []);
                    confirm();
                }}
            />
        ),
        ...options.columnExtraOptions,
    };
}

/**
 * @property {string} title - The title of column
 * @property {string} key - The value will be display in UI
 * @property {string} searchPlaceholder - The display placeholder when open search text
 * @property {string} columnExtraOptions - The extra options want to config or override basic config
 */
export interface IBasicFilterColumnOptions {
    title: string;
    key: string;
    searchPlaceholder?: string;
    columnExtraOptions?: Omit<IBasicTableColumn, 'title' | 'key' | 'code' | 'dataIndex'>;
}
/**
 * Build the basic column with filter and sorter
 * {@link IBasicFilterColumnOptions}
 * @param options The input options to config column
 * @returns The basic table column
 */
export function createBasicFilterColumn<T>(options: IBasicFilterColumnOptions): IBasicTableColumn {
    const { key, title, searchPlaceholder = 'Search' } = options;
    return {
        code: key,
        title: title,
        dataIndex: key,
        textSearchPlaceholder: searchPlaceholder,
        sorter: (a: T, b: T) => a[key]?.localeCompare(b[key]),
        onFilter: (value, record: T) => record[key]?.toLowerCase().includes(value.toLowerCase()),
        iconName: 'search',
        ...options.columnExtraOptions,
    };
}


export interface ISearchableTableDataSource<T> {
    onFilter: (searchText: string) => void;
    filteredDataSource: T[];
}

export function useSearchableTableStates<T>(dataSource: T[], filterKeys: (keyof T)[]): ISearchableTableDataSource<T> {
    const [searchText, setSearchText] = React.useState<string>('');
    const filteredDataSource = React.useMemo<T[]>(() => {
        return dataSource.filter(item => {
            return filterKeys.some(filterKey => hasContainsIgnoreCase(new String(item[filterKey])?.toString(), searchText));
        });
    }, [dataSource, searchText]);
    return {
        onFilter: setSearchText,
        filteredDataSource: filteredDataSource
    };
};

export const getRangeDateTimeFilterColumnProps = (dataIndex, format = DATE_TIME_FORMAT.DEFAULT) => ({
    filterDropdown: ({ setSelectedKeys, confirm }) => (
        <RangeDateTimePicker
            allowClear
            onChange={(e) => {
                setSelectedKeys(e?.length ? [e] : []);
                confirm();
            }}
        />
    ),
    onFilter: (value, record): boolean =>
        record[dataIndex] ? dayjs(record[dataIndex]).isBetween(dayjs(value[0]), dayjs(value[1])) : false,
    render: (text): string => formatDate(text, format),
});
