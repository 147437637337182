import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { differenceInDays } from 'date-fns';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export const DEFAULT_DATE_RANGE_FOR_DEPLOYMENTS = 7; // in days
export const NO_TIMEZONE = 'No timezone';

export enum DATE_TIME_FORMAT {
    DEFAULT = 'YYYY-MM-DD HH:mm:ss',
    DATE_ONLY = 'YYYY-MM-DD',
    DATE_AND_SHORT_TIME = 'YYYY-MM-DD HH:mm',
    DATE_TIME_WITH_TIMEZONE = 'YYYY-MM-DD HH:mm:ss Z (zzz)',
    FULL_TIME = 'HH:mm:ss',
    SHORT_TIME = 'HH:mm',
}

export const formatShortDate = (timestamp: string | number | Date): string =>
    formatDate(timestamp, DATE_TIME_FORMAT.DATE_ONLY);

export const formatDateAndShortTime = (timestamp: string | number | Date): string =>
    formatDate(timestamp, DATE_TIME_FORMAT.DATE_AND_SHORT_TIME);

export const formatDate = (timestamp: string | number | Date, format = DATE_TIME_FORMAT.DEFAULT): string =>
    timestamp ? dayjs(timestamp).format(format) : '';

export const dateCompare = (timestamp1: string | number | Date, timestamp2: string | number | Date): boolean =>
    Date.parse(formatDate(timestamp1)) >= Date.parse(formatDate(timestamp2));

export const getTimeFromServiceWindowSettings = (): string => '00:00'; // It will be implement on ANG-1154

export const addTzToDate = (date: Date) => {
    date.setSeconds(-date.getTimezoneOffset() * 60);

    return date;
};

export const getDatetimeByTZ = (strDatetime: string, format: string, tzName: string): Date => {
    return dayjs.tz(strDatetime, format, tzName).toDate();
};

export const convertDateByTZ = (date: Date, tzName: string): Date => {
    return getDatetimeByTZ(formatDate(date), DATE_TIME_FORMAT.DEFAULT, tzName);
};

export const setBeginningOfTheDay = (date: Date): Date => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
};

export const setEndOfTheDay = (date: Date): Date => {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    return date;
};

export const addOffsetToTheDate = (date: Date, offsetInDays: number): Date => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offsetInDays);

    return newDate;
};

export const getDayJsInstance = (date?: Date): Dayjs => (
    date ? dayjs(date) : null
);

export const isDeploymentDateEnabled = (currentDay: Date, today: Date, range: number, minDate?: Date, maxDate?: Date): boolean => {
    // disabled for all dates after today
    if (currentDay > today) {
        return false;
    }
    // enabled for all dates if range was set up and it is valid
    if (minDate && maxDate && isDeploymentRangeValid(range, minDate, maxDate)) {
        return true;
    }
    return minDate
        ? currentDay < setEndOfTheDay(addOffsetToTheDate(minDate, range - 1))
        : currentDay > setBeginningOfTheDay(addOffsetToTheDate(maxDate, -range));
};

export const isDeploymentRangeValid = (range: number, minDate?: Date, maxDate?: Date): boolean => {
    if (!minDate || !maxDate) {
        return false;
    }
    return differenceInDays(maxDate, minDate) <= range;
};
