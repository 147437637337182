import { observable, computed, action, makeObservable, reaction } from 'mobx';
import { unionBy, uniqBy } from 'lodash';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { AuthStore, IAuthUser } from 'core';
import { NotificationsApiClient } from '../api';
import { getErrorMessage } from 'common-utils';
import {
    formatDateAndShortTime,
    formatShortDate,
    getTimeFromServiceWindowSettings
} from 'common-utils/date-utils';
import { AssureBaseStore, IAssureStoreConstructorOptions } from './assure-base.store';
import {
    INotification,
    ICreateNotificationRequestProps,
    IUpdateNotificationRequestProps,
    MESSAGE_TYPE,
    RELEASE_NOTIFICATION_TYPE
} from '../dto/notifications-dto';
import { AccountType } from 'dto/access-management/account-dto';

dayjs.extend(localeData);
dayjs.extend(isSameOrBefore);

export interface IReleaseNotification {
    id: number;
    title: string;
    message: string;
    messageType: MESSAGE_TYPE;
    dateToDisplay: number;
    releaseType: RELEASE_NOTIFICATION_TYPE;
    releaseDate?: number;
    displayReleaseDateTimeString?: string;
    releaseNotes?: string;
}

function toReleaseNotification(notification: INotification): IReleaseNotification {
    return {
        id: notification.id,
        title: notification.content?.title || notification.title,
        message: notification.content?.text || notification.text,
        messageType: notification.content?.messageType as MESSAGE_TYPE || MESSAGE_TYPE.DPU_UPDATE,
        dateToDisplay: notification.content?.dateToDisplay || Date.now(),
        releaseType: notification.content?.data?.releaseType,
        releaseDate: notification.content?.data?.releaseDate,
        displayReleaseDateTimeString: `${formatShortDate(notification.content?.data?.releaseDate)} ${getTimeFromServiceWindowSettings()}`,
        releaseNotes: notification.content?.data?.releaseNotes,
    };
};

const RELEASE_NOTIFICATION_VALUES = Object.values(RELEASE_NOTIFICATION_TYPE);
export class NotificationsStore extends AssureBaseStore<NotificationsApiClient, INotification> {

    private readonly _authStore: AuthStore;

    public constructor(options: IAssureStoreConstructorOptions) {
        super(options);
        this._authStore = options.authStore;
        makeObservable(this);
        reaction(
            () => this._authStore.currentUser,
            async (currentUser: IAuthUser) => {
                if (!currentUser) return;
                await this.getReceiverNotifications(true);
            }
        );
    }

    @observable
    isDisplay: boolean;

    @action
    public openHub(): void {
        this.isDisplay = true;
    }

    @action
    public closeHub(): void {
        this.isDisplay = false;
    }

    protected get apiClient(): NotificationsApiClient {
        return this.apiClientStore.apiClients.notifications;
    }

    async getReceiverNotifications(force?: boolean): Promise<INotification[]> {
        if (!force && this.hasEntities()) return this.allNotifications;
        try {
            this.setDataLoading(true);
            const notifications =
                (await this.apiClient.getAllNotificationsByAccountId(this._authStore.assureApiAccount.accountId, { receiverUserUuid: this._authStore.currentUser.uuid }));
            this.setEntities(notifications);
            return this.allNotifications;
        } catch (err) {
            throw Error(getErrorMessage(err));
        } finally {
            this.setDataLoading(false);
        }
    }

    async getManagedNotifications(): Promise<INotification[]> {
        try {
            this.setDataLoading(true);
            const notifications =
                (await this.apiClient.getAllNotificationsByAccountId(this._authStore.assureApiAccount.accountId, { dpuNotification: true }));
            const allNotifications = unionBy([...notifications, ...this.entities], (notification) => notification.id);
            this.setEntities(allNotifications);
            return this.allNotifications;
        } catch (err) {
            throw Error(getErrorMessage(err));
        } finally {
            this.setDataLoading(false);
        }
    }

    public getNotificationById(id: number): INotification {
        return this.getEntity(id);
    }

    async delete(notificationId: number): Promise<boolean> {
        await this.apiClient.deleteNotification(this._authStore.assureApiAccount.accountId, notificationId);
        this.removeEntity(notificationId);
        return true;
    }

    async markRead(notificationId: number): Promise<boolean> {
        const updated = await this.apiClient.markReadNotification(this._authStore.assureApiAccount.accountId, notificationId);
        if (updated) {
            this.updateEntity<Pick<INotification, 'markRead'>>(notificationId, { markRead: true });
        }
        return true;
    }

    async create(payload: ICreateNotificationRequestProps): Promise<INotification> {
        const createdNotification = await this.apiClient.createNotification(this._authStore.assureApiAccount.accountId, {
            sender: this._authStore.currentUser.userName,
            receiverUserUuid: this._authStore.currentUser.uuid,
            ...payload,
        });
        this.addEntity(createdNotification);
        return createdNotification;
    }

    async update(notificationId: number, payload: Omit<IUpdateNotificationRequestProps, 'messageType'>): Promise<boolean> {
        const notification = this.getEntity(notificationId);
        const payloadToUpdate: IUpdateNotificationRequestProps = {
            ...payload,
            messageType: notification.content.messageType
        };
        const updated = await this.apiClient.updateNotification(this._authStore.assureApiAccount.accountId, notificationId, { content: payloadToUpdate });
        if (updated) {
            this.updateEntity<{ content: any }>(notificationId, { content: payloadToUpdate });
        }
        return updated;
    }

    @computed
    private get allNotifications(): INotification[] {
        if (!this.hasEntities()) return [];
        return this.entities.slice().sort((a: INotification, b: INotification) => b.id - a.id);
    }

    @computed
    public get managedNotifications(): INotification[] {
        if (this._authStore.currentUser.accountType != AccountType.DPU) return [];
        return this.allNotifications
            .filter(item => item.accountUuid == this._authStore.currentUser.accountUuid)
            .filter(item => item.content.messageType == MESSAGE_TYPE.DPU_UPDATE);
    }

    @computed
    private get filteredNotifications(): INotification[] {
        return this.allNotifications
        .filter(item => {
            const clientId = item.content?.data?.clientId;
            if (!clientId) return true;
            return this._authStore.currentUser.accountId == clientId;
        });
    }

    @computed
    public get recieveNotifications(): INotification[] {
        if (!this.hasEntities()) return [];
        return this.filteredNotifications.slice()
        .sort((a: INotification, b: INotification) => b.id - a.id)
        .filter(item => !item.markRead)
        .filter(item => {
            if (!item.content.dateToDisplay) return true;
            return !dayjs(item.content.dateToDisplay).isAfter(dayjs());
        });
    }

    @computed
    private get releaseNotifications(): IReleaseNotification[] {
        if (!this.hasEntities()) return [];
        return this.filteredNotifications
            .filter((notification) => RELEASE_NOTIFICATION_VALUES.includes(notification.content?.data?.releaseType as RELEASE_NOTIFICATION_TYPE))
            .map(toReleaseNotification);
    }

    @computed
    public get releaseUpcomingUpdates(): IReleaseNotification[] {
        if (!this.hasEntities()) return [];
        const todayDate = dayjs(formatDateAndShortTime(Date.now()));
        return this.releaseNotifications.filter((item) => {
            if (!item.releaseDate) return false;
            const isDisplayDate = dayjs(formatDateAndShortTime(item.dateToDisplay)).isSameOrBefore(todayDate);
            return isDisplayDate && dayjs(`${formatShortDate(item.releaseDate)} ${getTimeFromServiceWindowSettings()}`).isAfter(todayDate);
        });
    }

    @computed
    public get releaseHistory(): IReleaseNotification[] {
        if (!this.hasEntities()) return [];
        const todayDate = dayjs(formatDateAndShortTime(Date.now()));
        return this.releaseNotifications.filter((item) => {
            if (!item.releaseDate) return true;
            return dayjs(`${formatShortDate(item.releaseDate)} ${getTimeFromServiceWindowSettings()}`).isBefore(todayDate);
        });
    }

    @computed
    public get releaseTypeFilterDataSource(): { text: string; value: string }[] {
        return uniqBy(this.releaseHistory, 'releaseType').map((release) => ({
            text: release.releaseType,
            value: release.releaseType,
        }));
    }
}
