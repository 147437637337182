/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import React from 'react';
import { ISimpleFileUploadProps, SimpleFileUpload } from '../../fileupload';

import { FormItem, getDefaultFormItemOptions, IFormItem } from './form-item';

export interface ISimpleFileUploadPropsItemProps extends IFormItem {
    simpleFileUploadProps: ISimpleFileUploadProps;
}

export const SimpleFileUploadItem = <T extends unknown>(props: ISimpleFileUploadPropsItemProps) => {
    const formItemProps = getDefaultFormItemOptions(props);
    return (
        <FormItem code={props.code} {...formItemProps} child={<SimpleFileUpload {...props.simpleFileUploadProps} />} />
    );
};
