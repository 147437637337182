import * as React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { TableListingPage, IBasicTableColumn, DangerAlert, IconTextButton } from 'ui-lib';
import { getErrorMessage, isNullOrEmpty } from 'common-utils';

import {
    useAccountStore,
    useAuthStore,
    useKeyStore,
    useRoutesStore,
    useUserStore,
} from '../../../../store';
import { AccountType } from '../../../../dto/access-management/account-dto';
import { deleteAccount } from '../utils';
import { buildNavigatePath } from '../../utils';
import { ACCOUNT_ROUTE_CODES } from './index';
import { useSearchableTableStates } from '../../components/table-utils';

import manageAccountCss from './account.css';


export const ManageAccountsComponent = observer(() => {
    const { currentUser } = useAuthStore();
    const accountStore = useAccountStore();
    const keyStore = useKeyStore();
    const userStore = useUserStore();
    const navigate = useNavigate();

    const routeStore = useRoutesStore();
    const addRoute = routeStore.getRoute(ACCOUNT_ROUTE_CODES.ADD);
    const editRoute = routeStore.getRoute(ACCOUNT_ROUTE_CODES.EDIT);

    const { onFilter, filteredDataSource } = useSearchableTableStates(accountStore.displayAccounts, ['name']);
    const [errorMessage, setErrorMessage] = React.useState<string>(null);

    React.useEffect(() => {
        setErrorMessage(accountStore.error);
    }, [accountStore.error]);

    const onAdd = () => navigate(addRoute.path);
    const onDelete = async (id: number) => {
        await accountStore.deleteAccount(id).catch(err => setErrorMessage(getErrorMessage(err)));
        if (!isNullOrEmpty(keyStore.displayKeys)) await keyStore.loadKeys(true);
        if (!isNullOrEmpty(userStore.displayUsers)) await userStore.loadUsers(true);
    };

    const columns: IBasicTableColumn[] = [
        {
            code: 'name',
            title: 'Account name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text: string, record: any) => (
                <IconTextButton
                    onClick={() => navigate(buildNavigatePath(editRoute.path, { ':id': record.id }))}
                    label={text}
                    type='link'
                />
            ),
        },
        {
            code: 'type',
            title: 'Account type',
            dataIndex: 'type',
        },
        {
            code: 'displayEnvironmentNames',
            title: 'Environments',
            dataIndex: 'displayEnvironmentNames',
        },
        {
            code: 'action',
            title: 'Action',
            dataIndex: 'action',
            render: (_, record) =>
                record.type === AccountType.CLIENT && (
                    <IconTextButton onClick={() => deleteAccount(record.id, onDelete)} label='Delete' type='link' />
                ),
        },
    ];

    return (
        <React.Fragment>
            {errorMessage ? <DangerAlert message={errorMessage} className={manageAccountCss.alert} /> : null}
            <TableListingPage
                title=''
                tableProps={{
                    title: 'All accounts',
                    loadingState: accountStore.dataLoading,
                    table: {
                        columns: columns,
                        dataSource: filteredDataSource,
                    },
                    toolbar: {
                        searchPlaceholder: 'Search accounts',
                        onSearch: onFilter,
                        onAdd: currentUser.accountType !== AccountType.CLIENT ? onAdd : undefined,
                        onReload: () => accountStore.getAccounts(true),
                    },
                }}
            />
        </React.Fragment>
    );
});
