import * as React from 'react';
import { Alert } from 'antd';
import {Icon, IIconProps} from '../icon';

export interface IAlertProps {
    message: string | React.ReactNode;
    description?: string | React.ReactNode;
    closable?: boolean;
    className?: string;
}

const COMMON_PROPERTIES = {
    closable : true,
    showIcon: true
};

const COMMON_ICON_PROPERTIES: Partial<IIconProps> = {
    size: 'SMALL',
    asPrefix: true,
    danger: true
};

const getCommonProps = (props: IAlertProps) => ({
    className: props.className,
    message: props.message,
    closable: props.closable,
    description: props.description
});

export const DangerAlert = (props: IAlertProps) => (
    <Alert
        type="error"
        icon={<Icon iconName="shield-exclamation" {...COMMON_ICON_PROPERTIES} />}
        {...COMMON_PROPERTIES}
        {...getCommonProps(props)}
    />
);

export const WarningAlert = (props: IAlertProps) => (
    <Alert
        type="warning"
        icon={<Icon iconName="siren-on" {...COMMON_ICON_PROPERTIES} />}
        {...COMMON_PROPERTIES}
        {...getCommonProps(props)}
    />
);

export const SuccessAlert = (props: IAlertProps) => (
    <Alert
        type="success"
        icon={<Icon iconName="circle-check" {...COMMON_ICON_PROPERTIES} />}
        {...COMMON_PROPERTIES}
        {...getCommonProps(props)}
    />
);

export const InfoAlert = (props: IAlertProps) => (
    <Alert
        type="info"
        icon={<Icon iconName="info-circle" {...COMMON_ICON_PROPERTIES} />}
        {...COMMON_PROPERTIES}
        {...getCommonProps(props)}
    />
);