import { pick } from 'lodash';
import { IPackageProps, RepositoryType } from './packages-dto';
import { ValueOf } from 'common-utils';

export const DEFAULT_ARCHITECTURES = ['arm7hf'];

export const SNAPSHOT_TYPE = pick(RepositoryType, ['VAR', 'CLIENT']);
export type SNAPSHOT_TYPE = ValueOf<typeof SNAPSHOT_TYPE>;

export const METAPACKAGE_TYPE = pick(RepositoryType, ['ALM', 'VOS', 'SCR']);
export const METAPACKAGE_TYPES = Array.from([METAPACKAGE_TYPE.ALM, METAPACKAGE_TYPE.VOS, METAPACKAGE_TYPE.SCR]);
export type METAPACKAGE_TYPE = ValueOf<typeof METAPACKAGE_TYPE>;

export enum SNAPSHOT_STATUS {
    RELEASED = 'RELEASED',
    CREATED = 'CREATED',
}
export interface IReleaseDependenciesProps {
    almReleaseId: number;
    vosReleaseId: number;
    scrReleaseId: number;
}
export interface ISnapshotProps {
    id: number;
    label: string;
    description?: string;
    status: SNAPSHOT_STATUS;
    accountUuid: string;
    type: SNAPSHOT_TYPE;
    version: string;
    snapshotId: string;
    packageIds?: number[];
    updatedAt: string;
    createdAt: string;
    basedReleaseId: number;
    dependencies: IReleaseDependenciesProps;
}

export type TCreateMetapackageErrorType =
    | 'ADD_PACKAGES_ERROR'
    | 'DUPLICATED_PACKAGES_ERROR'
    | 'RELEASE_ERROR'
    | 'UNKNOWN_ERROR';

export interface ICreateMetaPackageErrorProps {
    type: TCreateMetapackageErrorType;
    reason: string[] | any;
}

export interface ICreateMetaPackageRequestProps {
    label: string;
    description?: string;
    type: METAPACKAGE_TYPE;
    version: string;
    build: string;
    files?: File[];
    packageIds?: number[];
}
export interface ICreateMetaPackageResponseProps {
    release?: ISnapshotProps;
    packages?: IPackageProps[];
    error?: ICreateMetaPackageErrorProps;
}

export interface ICreateSnapshotProps extends IReleaseDependenciesProps {
    label: string;
    description?: string;
    packageIds?: number[];
    basedReleaseId: number;
    accountUuid: string;
}
export interface IUpdateSnapshotProps extends Omit<Partial<ICreateSnapshotProps>, 'packageIds'> {
    removePackageIds: number[];
    addPackageIds: number[];
}
export interface IMetaPackageInReleaseProps {
    [METAPACKAGE_TYPE.ALM]: ISnapshotProps;
    [METAPACKAGE_TYPE.VOS]: ISnapshotProps;
    [METAPACKAGE_TYPE.SCR]: ISnapshotProps;
}
