import * as React from 'react';
import { Checkbox } from 'antd';

export interface ICheckboxFieldProps {
    label: string;
    className?: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: (event) => void;
}

export const CheckboxField = (props: ICheckboxFieldProps) => {
    return (
        <Checkbox className={props.className} checked={props.checked} disabled={props.disabled} onChange ={props.onChange}>{props.label}</Checkbox>
    );
};
