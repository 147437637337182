import { action, computed, makeObservable, observable } from 'mobx';
import {
    DevicesStore,
    IDeviceInfoProps as IDeviceInfoPropsDto,
    IKeyDistribution as IKeyDistributionDto,
} from '../devices-store';
import { notification } from 'ui-lib';

import { KeyType } from '../../dto/key-management/key-dto';
import { formatDate } from 'common-utils/date-utils';
import { DeviceAttributeMapping, DeviceAttributeName, ITaipanBatteryInfo as ITaipanBatteryInfoDto, IUpdateDeviceAttributeResponse, SCR_TAMPER_STATUS } from '../../dto/device-dto';
// eslint-disable-next-line no-duplicate-imports
import type { IMSPVersionOptions, IScrTamperProps, IConnectionSummary } from '../../dto/device-dto';
import {
    getDisplayDeviceStatus,
    isDecommissionedDevice,
    isOnlineDevice,
    isNullOrEmpty,
    getDeviceLastOnline
} from '../../common-utils';

type IKeyDistribution = IKeyDistributionDto;
type IDeviceInfoProps = IDeviceInfoPropsDto;
type ITaipanBatteryInfo = ITaipanBatteryInfoDto;

interface IDisplayKeyDistribution extends Omit<IKeyDistribution, 'keys'> {
    keys?: string[];
}
interface IDeviceInfosConstructorOptions {
    devicesStore: DevicesStore;
    uid: string;
    accountId: number;
}
export class DeviceInfosStore {
    protected readonly deviceStore: DevicesStore;
    protected readonly uid: string;
    protected readonly accountId: number;

    constructor(options: IDeviceInfosConstructorOptions) {
        makeObservable(this);
        this.deviceStore = options.devicesStore;
        this.uid = options.uid;
        this.accountId = options.accountId;
    }

    @observable
    loading: boolean;

    @action
    setLoading(loading: boolean) {
        this.loading = loading;
    }

    @observable
    error: string;

    @action
    setError(err: string) {
        this.error = err;
    }

    @observable
    groupLabel: string;

    @action
    setGroup(groupLabel: string) {
        this.groupLabel = groupLabel;
    }

    @observable
    keyInventory: IKeyDistribution;

    @action
    setKeyInventory(keyInventory: IKeyDistribution) {
        this.keyInventory = keyInventory;
    }

    @observable
    mspVersion: IMSPVersionOptions;

    @action
    setMspVersion(mspVersion: IMSPVersionOptions) {
        this.mspVersion = mspVersion;
    }

    @observable
    taipanBatteryInfo: ITaipanBatteryInfo;

    @action
    setTaipanBatteryInfo(taipanBatteryInfo: ITaipanBatteryInfo): void {
        this.taipanBatteryInfo = taipanBatteryInfo;
    }

    @computed
    get displayMspVersion(): any {
        if (!this.mspVersion || isNullOrEmpty(this.mspVersion.mspVersions)) return { version: undefined };
        return {
            version: this.mspVersion.mspVersions[0].version,
        };
    }

    @computed
    public get displayKeyInventory(): IDisplayKeyDistribution {
        if (!this.keyInventory) return { status: undefined, keys: [] };
        return {
            status: this.keyInventory.status,
            keys: this.keyInventory.keys.map((item) =>
                item.type === KeyType.BDK ? `IPEK: ${item.status}` : `${item.name}: ${item.status}`,
            ),
        };
    }

    @computed
    private get scrInfo() {
        return this.info?.deviceInfo?.scrInfo || {};
    }

    @computed
    private get hardware() {
        return this.scrInfo.hardware || {};
    }

    @observable
    public status: string;

    @action
    public setConnectivityStatus(connectivity: IConnectionSummary): void {
        this.status = getDisplayDeviceStatus({ status: this.info?.status, connectivity: connectivity });
    }

    @observable
    public lastOnline: string;

    @action
    public setLastOnline(connectivity: IConnectionSummary): void {
        this.lastOnline = getDeviceLastOnline(connectivity);
    }

    @computed
    public get lastUpdated(): string {
        return formatDate(this.info?.lastJobSuccessAt);
    }

    @observable
    timezone: string;

    @action
    setTimezone(timezone: string): void {
        this.timezone = timezone;
    }

    @computed
    public get serialNumber(): string {
        return this.info?.serialNumber;
    }

    @computed
    public get esn(): string {
        return this.info?.esn;
    }

    @computed
    public get name(): string {
        return this.info?.deviceLabel;
    }

    @computed
    public get location(): string {
        return this.info?.location;
    }

    @computed
    public get secureVersion(): string {
        return this.info?.firmwareVersion;
    }

    @computed
    public get emvVersion(): string {
        return this.scrInfo['emvl1-sr']?.version;
    }

    @computed
    public get tamperInfo(): IScrTamperProps {
        return this.info?.tamper;
    }

    @computed
    public get managedState(): string {
        return isOnlineDevice(this.status) && this.tamperInfo?.status == SCR_TAMPER_STATUS.SCR_TAMPER ? 'UNMANAGED' : '';
    }

    @observable
    info: IDeviceInfoProps;

    @action
    private setDeviceInfo(info: IDeviceInfoProps) {
        this.info = info;
    }

    @observable
    actionWaiting: boolean;

    @action
    setActionWaiting(updating: boolean) {
        this.actionWaiting = updating;
    }

    public hasValueChange(name: DeviceAttributeName, value: string): boolean {
        const prevValue = this.info[DeviceAttributeMapping[name]] || '';
        const currValue = value || '';

        return prevValue !== currValue;
    }

    public async editAttribute(name: DeviceAttributeName, value: string): Promise<IUpdateDeviceAttributeResponse> {
        this.setActionWaiting(true);
        try {
            const result = await this.deviceStore.updateDeviceAttribute(this.accountId, {
                deviceName: this.uid,
                attributeName: name,
                attributeValue: value,
            });

            if (result.updated) {
                const currentInfo = Object.assign({}, this.info);
                currentInfo[DeviceAttributeMapping[name]] = value;
                this.setDeviceInfo(currentInfo);
            }
            return result;
        } catch (e) {
            this.setError(e.message);
            return { updated: false };
        } finally {
            this.setActionWaiting(false);
        }
    }

    public updateConnectivityStatus(connectivity: IConnectionSummary): void {
        if (isDecommissionedDevice(this.status)) return;
        if (this.status == getDisplayDeviceStatus({ status: this.info.status, connectivity })) return;
        this.setConnectivityStatus(connectivity);
        this.setLastOnline(connectivity);
        notification['info']({
            message: `Device is ${this.status}`,
            placement: 'topRight'
        });
    }

    public async initializeData(device: IDeviceInfoProps): Promise<any> {
        this.setError(null);
        this.setLoading(true);
        try {
            this.setDeviceInfo(device);
            this.setGroup(device.deviceGroupLabel);

            const [keyInventory, mspVersion, taipanBatteryInfo, devicAttributes] = await Promise.all([
                this.deviceStore.getKeyInventory(this.accountId, this.uid),
                this.deviceStore.getMSPVersion(this.accountId, this.uid),
                this.deviceStore.getTaipanBatteryInfo(this.accountId, this.uid),
                this.deviceStore.getDeviceAttributes(this.accountId, this.uid),
            ]);
            this.setTimezone(devicAttributes?.attributes?.timezone);
            this.setConnectivityStatus(devicAttributes.connectivity);
            this.setLastOnline(devicAttributes.connectivity);
            this.setKeyInventory(keyInventory);
            this.setMspVersion(mspVersion);
            this.setTaipanBatteryInfo(taipanBatteryInfo);
        } catch (e) {
            this.setError(e.message);
        } finally {
            this.setLoading(false);
        }
    }
}
