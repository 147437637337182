import { computed, makeObservable } from 'mobx';

import { getErrorMessage } from 'common-utils';
import { UserApiClient } from '../api';
import { AssureBaseStore, IAssureStoreConstructorOptions } from './assure-base.store';
import { ISaveUserProps, IUser } from 'dto/access-management/user-dto';
import { IAccount } from 'dto/access-management/account-dto';

export interface IDisplayUser {
    id: string;
    email: string;
    name: string;
    username: string;
    account: IAccount;
    organisation: string;
    userRoles: string;
    createAt: number;
}

export class UserStore extends AssureBaseStore<UserApiClient, IUser> {

    public constructor(options: IAssureStoreConstructorOptions) {
        super(options);
        makeObservable(this);
    }

    protected get apiClient(): UserApiClient {
        return this.apiClientStore.apiClients.user;
    }

    @computed
    public get displayUsers(): IDisplayUser[] {
        if (!this.hasEntities()) return [];

        return this.entities
            .map((user) => ({
                id: user.id,
                key: user.id,
                name: user.name,
                username: user.username,
                email: user.email,
                account: user.account,
                organisation: user.account.name,
                userRoles: user.roles.map((role) => role.name).join(', '),
                createAt: user.createAt,
            }))
            .sort((a: IDisplayUser, b: IDisplayUser) => b.createAt - a.createAt);
    }

    public async loadUsers(force?: boolean) {
        if (!force && this.hasEntities()) return;
        try {
            this.setDataLoading(true);
            this.setError(undefined);
            this.setEntities(await this.apiClient.getUsers());
        } catch (err) {
            this.setError(getErrorMessage(err));
        } finally {
            this.setDataLoading(false);
        }
    }

    public isUserExisting(email: string): boolean {
        const found = this.displayUsers.find((user) => user.email.toLowerCase() === email.toLowerCase());
        return !!found;
    }

    public async getUserByEmail(email: string): Promise<IUser> {
        const user = this.entities.find(entity => entity.email == email);
        return user ? user : this.apiClient.getUserByEmail(email);
    }

    public async getUserById(id: string): Promise<IUser> {
        const user = this.getEntity(id);
        return user ? user : this.apiClient.getUser(id);
    }

    public async deleteUser(id: string): Promise<boolean> {
        await this.apiClient.deleteUser(id);
        this.removeEntity(id);
        return true;
    }

    public async saveUser(id: string, userInfo: ISaveUserProps): Promise<void> {
        if (!id) {
            const savedUser = await this.apiClient.createUser(userInfo);
            this._addOrUpdateUser(await this.apiClient.getUser(savedUser.id));
            return;
        }
        const savedUser = await this.apiClient.editUser(userInfo.id, userInfo);
        this._addOrUpdateUser(savedUser);
    }

    private _addOrUpdateUser(user: IUser): void {
        const index = this.entities.findIndex((item) => item.id == user.id);
        if (index == -1){
            this.addEntity(user);
            return;
        }
        this.entities[index] = user;
    }
}
