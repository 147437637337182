import * as React from 'react';
import { Descriptions } from 'antd';

import keyValueDisplayCss from './key-value-display.css';

export interface IKeyValueDisplayItem {
    title: string;
    content: React.ReactNode;
}

export interface IKeyValueDisplayProps {
    title?: string;
    items: IKeyValueDisplayItem[];
}

export function KeyValueDisplay(props: IKeyValueDisplayProps) {
    return (
        <Descriptions
            bordered
            title={props.title}
            size='small'
            layout='horizontal'
            className={keyValueDisplayCss.keyValueDisplay}
            column={1}
        >
            {props.items.map((item) => (
                <Descriptions.Item key={item.title} label={item.title} className={keyValueDisplayCss.item}>
                    {item.content}
                </Descriptions.Item>
            ))}
        </Descriptions>
    );
}
