import { action, computed, makeObservable, observable, reaction } from 'mobx';
// eslint-disable-next-line no-duplicate-imports
import type { IObservableArray } from 'mobx';

import { AssureBaseApiClient } from '../api/assure-base-api-client';
import { AssureAdminApiClientStore } from './assure-admin-api-client.store';
import { AuthStore } from 'core';
import { isNullOrEmpty } from 'common-utils';

export interface SelectDataSource {
    value: number | string;
    label: string;
}

export interface IAssureStoreConstructorOptions {
    apiClientStore: AssureAdminApiClientStore;
    authStore: AuthStore;
    onUpdateSource?: <Q>(entities: Q[], prev?: Q[]) => any;
}

export type IdentifierType = number | string;

export interface IDBRecord {
    id: IdentifierType;
}

export abstract class AssureBaseStore<T extends AssureBaseApiClient, Q extends IDBRecord> {
    protected readonly apiClientStore: AssureAdminApiClientStore;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    @observable
    private _entities: IObservableArray<Q> = observable.array<Q>([]);

    protected constructor(options: IAssureStoreConstructorOptions) {
        makeObservable(this);
        this.apiClientStore = options.apiClientStore;
        reaction(
            () => options.authStore.currentUser,
            (user) => {
                if (user) return;
                this.clearEntities();
            },
        );

        let isInitialRun = true; // Flag to prevent initial reaction run
        reaction(
            () => this.entities.slice(),
            (values: Q[], previous: Q[], r) => {
                if (isNullOrEmpty(values)) {
                    return;
                }
                if (isInitialRun) {
                    return isInitialRun = false;
                }
                if (options.onUpdateSource) {
                    options.onUpdateSource(values, previous);
                }
            },
        );
    }

    protected abstract get apiClient(): T;

    @computed
    protected get entities(): Q[] {
        return this._entities;
    }

    protected hasEntities(): boolean {
        return !isNullOrEmpty(this._entities);
    }

    protected getEntity(id: IdentifierType): Q {
        return this.entities.find((entity) => entity.id == id);
    }

    @action
    public setEntities(entities: Q[]) {
        this._entities.replace(entities);
    }

    private _replaceEntityWithIndex(entity: Q, index: number) {
        this._entities.splice(index, 1, entity);
    }

    @action
    protected updateEntity<T>(id: IdentifierType, updatedObject: T): void {
        const index = this.entities.findIndex((item) => item.id == id);
        const entity = this.entities.at(index);
        this._replaceEntityWithIndex(Object.assign(entity, { ...updatedObject }), index);
    }

    @action
    protected addEntity(...entity: Q[]) {
        this._entities.push(...entity);
    }

    @action
    protected removeEntity(id: IdentifierType): void {
        const entity = this.entities.find((item) => item.id === id);
        if (!entity) return;
        this._entities.remove(entity);
    }

    @action
    public clearEntities() {
        this._entities.clear();
    }

    @observable
    error: string;

    @action
    setError(err: string) {
        this.error = err;
    }

    @observable
    private _dataLoading = false;

    @action
    protected setDataLoading(dataLoading: boolean) {
        this._dataLoading = dataLoading;
    }

    public get dataLoading(): boolean {
        return this._dataLoading;
    }
}
