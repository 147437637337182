import React from 'react';
import { useNavigate } from 'react-router-dom';

import { IBasicTableColumn, IconTextButton, TableListingPage } from 'ui-lib';
import { useRoutesStore } from '../../../../store';
import { REPORT_ROUTE_CODES } from './';

export const REPORT_DATASOURCE = [
    // {
    //     id: 'operational',
    //     name: 'Operational report',
    //     description:
    //         'Device start up/restart. Time elapsed since last start up. Outage time (Down time), Availability time (Up time)',
    //     getPath: (routeStore) => routeStore.getRoute(REPORT_ROUTE_CODES.OPERATIONAL).path,
    // },
    {
        id: 'deployments',
        name: 'Deployments report',
        description: 'A list of devices based on selected deployment. Shows deployment release and packages.',
        getPath: (routeStore) => routeStore.getRoute(REPORT_ROUTE_CODES.DEPLOYMENTS).path,
    },
    {
        id: 'firmware-versions',
        name: 'Firmware versions report',
        description: 'A list of all the devices based on firmware version',
        getPath: (routeStore) => routeStore.getRoute(REPORT_ROUTE_CODES.FIRMWARE_VERSIONS).path,
    },
    {
        id: 'snapshot-comparison',
        name: 'Release comparison report',
        description: 'A list of all the packages of device compare with the published release.',
        getPath: (routeStore) => routeStore.getRoute(REPORT_ROUTE_CODES.SNAPSHOT_COMPARISON).path,
    },
];

export function ReportsComponent() {
    const navigate = useNavigate();

    const routeStore = useRoutesStore();

    const columns: IBasicTableColumn[] = [
        {
            code: 'name',
            title: 'Report',
            dataIndex: 'name',
            iconName: 'search',
            textSearchPlaceholder: 'Search name',
            onFilter: (name: string, record: any) => record.name?.toLowerCase().includes(name.toLowerCase()),
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text: string, record: any) => (
                <IconTextButton onClick={() => navigate(record.getPath(routeStore))} label={text} type="link"/>
            ),
        },
        {
            code: 'description',
            title: 'Description',
            dataIndex: 'description',
        },
    ];

    return (
        <TableListingPage
            title={''}
            tableProps={{
                title: '',
                table: {
                    columns,
                    dataSource: REPORT_DATASOURCE,
                    rowKey: 'id',
                    extraOptions: {
                        pagination: { hideOnSinglePage: true },
                    },
                },
            }}
        />
    );
}
