import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { chunk } from 'lodash';

import {
    IAdvanceTableToolbarProps,
    IBasicTableColumn,
    Progress,
    TableListingPage,
    IconTextButton,
    Modal,
} from 'ui-lib';
import { useApiClientStore, useAuthStore, useRoutesStore } from '../../../../store';
import { IDeviceInfo, IScrTamperProps } from '../../../../dto/device-dto';
import { DEFAULT_DEVICE_COLUMN, DevicesTableComponent } from '../../components/devices-table-component';
import { DEVICES_ROUTE_CODES } from '../';

import commissioningUploadCss from './commissioning-upload.css';
import { COMMISSION_STATUS } from './commissioning-upload';
import { commissionStatusTagComponent } from './index';

export interface ICommissioningDevice {
    identifier: string;
    serialNumber: string;
    esn: string;
    deviceGroupNames: string[];
    client: string;
    status: COMMISSION_STATUS;
    comment: string;
    firmwareVersion?: string;
    tamper?: IScrTamperProps;
    deviceTypeName?: string;
}

export interface ICommissioningDevicesState {
    clientAccountId: number;
    clientAccountName: string;
    deviceGroup: string;
    deviceGroupLabel: string;
    devices: Partial<ICommissioningDevice>[];
    keyType: 'serialNumber' | 'esn';
}

export function CommissioningUploadReview() {
    const navigate = useNavigate();
    const routeStore = useRoutesStore();

    const deviceInventoryRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.INVENTORY);
    const commissionUploadRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.COMMISSIONING_UPLOAD);

    const state: Partial<ICommissioningDevicesState> = useLocation().state || {};
    const authStore = useAuthStore();
    const currentUser = authStore.currentUser;
    const toInventory = React.useMemo(() => {
        const clientDeviceGroupName = currentUser.managedAccounts.find(account => account.id == state.clientAccountId)?.deviceGroupName;
        return () => navigate(deviceInventoryRoute.path, {
            state: {
                accountId: currentUser.accountId,
                activeKey: {
                    clientDeviceGroupName,
                    deviceGroup: state.deviceGroup,
                },
            }
        });
    }, []);
    const deviceApiClient = useApiClientStore().apiClients.devices;
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [processPercent, setProcessPercent] = React.useState(0);
    const [unsuccessDevices, setUnsuccessDevices] = React.useState([]);

    const commissionableDevices = React.useMemo<string[]>(() => {
        if (!state.devices) return [];
        return state.devices.filter((device) => device.status == COMMISSION_STATUS.COMMISSIONABLE).map(item => item.identifier);
    }, [state.devices]);

    const columns: IBasicTableColumn[] = [
        {
            code: 'identifier',
            title: 'UID',
            dataIndex: 'identifier',
            sorter: (a, b) => a.identifier?.localeCompare(b.identifier),
            textSearchPlaceholder: 'Search by UID',
            iconName: 'search',
            onFilter: (identifier, device) => device.identifier.toLowerCase().includes(identifier.toLowerCase()),
        },
        {
            code: 'serialNumber',
            title: 'Serial Number',
            dataIndex: 'serialNumber',
            sorter: (a, b) => a.serialNumber?.localeCompare(b.serialNumber),
            textSearchPlaceholder: 'Search by Serial Number',
            iconName: 'search',
            onFilter: (serialNumber, device) => device.serialNumber?.toLowerCase().includes(serialNumber.toLowerCase()),
        },
        {
            code: 'esn',
            title: 'eSN',
            dataIndex: 'esn',
            sorter: (a, b) => a.esn?.localeCompare(b.esn),
            textSearchPlaceholder: 'Search by eSN',
            iconName: 'search',
            onFilter: (esn, device) => device.esn?.toLowerCase().includes(esn.toLowerCase()),
        },
        {
            code: 'client',
            title: 'Client',
            dataIndex: 'client',
            sorter: (a, b) => a.client?.localeCompare(b.client),
            textSearchPlaceholder: 'Search by Client',
            iconName: 'search',
            onFilter: (client, device) => device.client?.toLowerCase().includes(client.toLowerCase()),
        },
        {
            code: 'deviceType',
            title: 'Device Type',
            dataIndex: 'deviceTypeName',
            sorter: (a, b) => a?.deviceTypeName?.localeCompare(b?.deviceTypeName),
            textSearchPlaceholder: 'Search Device Type',
            onFilter: (deviceType: string, device) =>
              device.deviceTypeName?.toLowerCase().includes(deviceType.toLowerCase()),
            iconName: 'search',
        },
        {
            code: 'status',
            title: 'Status',
            dataIndex: 'status',
            filters: [
                { text: COMMISSION_STATUS.COMMISSIONABLE, value: COMMISSION_STATUS.COMMISSIONABLE },
                { text: COMMISSION_STATUS.NOT_AVAILABLE, value: COMMISSION_STATUS.NOT_AVAILABLE },
                { text: COMMISSION_STATUS.NOT_FOUND, value: COMMISSION_STATUS.NOT_FOUND },
            ],
            sorter: (a, b) => a.status.localeCompare(b.status),
            render: commissionStatusTagComponent,
            onFilter: (status, device) => device.status.toLowerCase() == status.toLowerCase(),
        },
        {
            code: 'comment',
            title: 'Comment',
            dataIndex: 'comment',
            sorter: (a, b) => a.comment?.localeCompare(b.comment),
        },
    ];

    const onCommissionDevices = async (): Promise<void> => {
        setIsProcessing(true);
        setUnsuccessDevices([]);
        setProcessPercent(0);
        const DEVICES_PER_BATCH = 3;
        const numOfDevices = commissionableDevices.length;
        const progressStep = Math.ceil((DEVICES_PER_BATCH * 100) / numOfDevices);
        const deviceBatches = chunk(commissionableDevices, DEVICES_PER_BATCH);
        let errorResults = {};
        for (let i = 0; i < deviceBatches.length; i++) {
            const result = await deviceApiClient.commissionDevices(
                currentUser.accountId,
                state.clientAccountId,
                state.deviceGroup,
                deviceBatches[i],
            );
            errorResults = result.reduce((errors, device) => {
                if (!device.success) errors[device.identifier] = device.errors;
                return errors;
            }, errorResults);
            setProcessPercent((i + 1) * progressStep);
        }

        if (Object.keys(errorResults).length > 0) {
            const errorDevices: (Partial<IDeviceInfo> & { errors: string[] })[] = Object.keys(errorResults).map(
                (identifier) => {
                    const device = state.devices.find((device) => device.identifier == identifier);
                    return {
                        UID: identifier,
                        serialNumber: device.serialNumber,
                        esn: device.esn,
                        errors: errorResults[identifier],
                    };
                },
            );
            setUnsuccessDevices(errorDevices);
            return;
        }
        toInventory();
    };

    const UnsuccessDevicesTable = () => {
        return (
            <DevicesTableComponent
                title={`Unsuccess Commissioning Devices (${unsuccessDevices.length})`}
                defaultSortColumn={{
                    key: DEFAULT_DEVICE_COLUMN.SERIAL,
                    sortOrder: 'descend',
                }}
                dataSource={unsuccessDevices}
                hiddenDefaultColumns={[
                    DEFAULT_DEVICE_COLUMN.DEVICE_GROUP,
                    DEFAULT_DEVICE_COLUMN.LAST_UPDATE,
                ]}
                extraColumns={[
                    {
                        code: 'commissioningError',
                        title: 'Errors',
                        dataIndex: 'errors',
                        render: (errors, record) =>
                            errors.map((error, index) => <p key={`${record.UID}_error_${index}`}>{error}</p>),
                    },
                ]}
                extraItems={[
                    <IconTextButton
                        key='closeUnsuccessCommissioning'
                        type='primary'
                        label='Close'
                        onClick={() => toInventory()}
                    />,
                ]}
            />
        );
    };

    return (
        <React.Fragment>
            <TableListingPage
                className={commissioningUploadCss.devicesTable}
                title={'Review Upload'}
                tableProps={{
                    title: `Commissioning ${commissionableDevices.length} Devices to ${state.clientAccountName}`,
                    table: {
                        columns: columns,
                        dataSource: state.devices,
                        rowKey: state.keyType,
                    },
                    toolbar: {
                        extraItems: [
                            <React.Fragment key='progFrag'>
                                {isProcessing ? (
                                    <Progress
                                        key='progress'
                                        percent={processPercent}
                                        type='circle'
                                        extraProps={{ width: 40 }}
                                    />
                                ) : null}
                            </React.Fragment>,
                            <IconTextButton
                                onClick={onCommissionDevices}
                                disabled={commissionableDevices.length <= 0 || isProcessing}
                                key='btnCommission'
                                label='Commission'
                                iconName='plus'
                                type='primary'
                            />,
                            <IconTextButton
                                onClick={() => navigate(commissionUploadRoute.path)}
                                disabled={isProcessing}
                                key='btnCancel'
                                label='Cancel'
                                iconName='cancel'
                                type='default'
                            />,
                        ],
                    } as IAdvanceTableToolbarProps,
                }}
            />
            <Modal width={'90%'} isVisible={unsuccessDevices.length > 0}>
                <UnsuccessDevicesTable />
            </Modal>
        </React.Fragment>
    );
}
