import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useAuthStore,
    useAccountStore,
    useDeviceGroupsStore,
    useApiClientStore,
    useRoutesStore,
} from '../../../../store';
import { AccountType } from '../../../../dto/access-management/account-dto';
import { ManualFileUpload, DangerAlert, BasicForm, SelectFormItem, IconTextButton } from 'ui-lib';

import commissioningUploadCss from './commissioning-upload.css';
import { DEVICES_ROUTE_CODES } from '../';
import { observer } from 'mobx-react';
import { ICommissioningDevice } from './commissioning-upload-review';
import { IDeviceInfo, SCR_TAMPER_STATUS } from 'dto/device-dto';
import { toCommissionStatus } from './index';

export enum COMMISSION_STATUS {
    NOT_FOUND = 'NOT FOUND',
    NOT_AVAILABLE = 'NOT AVAILABLE',
    COMMISSIONABLE = 'COMMISSIONABLE',
}
enum IMPORT_TYPE {
    SERIAL_NUMBER = 'Serial number',
    ESN = 'eSN'
}
type IMPORT_TYPE_KEY = keyof typeof IMPORT_TYPE;
const importTypeDatasource = Object.keys(IMPORT_TYPE).map(item => ({ value: item, label: IMPORT_TYPE[item] }));


export const CommissioningUpload = observer(() => {
    const navigate = useNavigate();
    const routeStore = useRoutesStore();

    const commissionUploadReviewRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.COMMISSIONING_UPLOAD_REVIEW);
    const inventoryRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.INVENTORY);

    const authStore = useAuthStore();
    const currentUser = authStore.currentUser;
    const accountStore = useAccountStore();
    const deviceGroupStore = useDeviceGroupsStore();
    const deviceApiClient = useApiClientStore().apiClients.devices;
    const [errorMessage, setErrorMessage] = React.useState(null);
    const clientAccounts = accountStore.getSubAccount(AccountType.CLIENT).map((account) => {
        return {
            value: account.id,
            label: account.label,
        };
    });
    const [selectedClient, setSelectedClient] = React.useState<number>();
    const [deviceGroups, setDeviceGroups] = React.useState<any[]>([]);
    const [selectedDeviceGroup, setSelectedDeviceGroup] = React.useState<string>();
    const [importType, setImportType] = React.useState<IMPORT_TYPE_KEY>('ESN');
    const deviceKeyType = React.useMemo<keyof Pick<IDeviceInfo, 'serialNumber' | 'esn'>>(() => {
        if (importType == 'SERIAL_NUMBER') return 'serialNumber';
        if (importType == 'ESN') return 'esn';
    }, [importType]);

    React.useEffect(() => {
        setErrorMessage(null);
        setSelectedDeviceGroup(undefined);

        const clientDeviceGroup = deviceGroupStore.getClientDeviceGroupDetails(selectedClient);
        if (!clientDeviceGroup) return;

        const deviceGroupDatasource = [
            { value: clientDeviceGroup.deviceGroupName, label: 'No Device Group' },
            ...clientDeviceGroup.childrenGroups.map((group) => {
                return {
                    value: group.deviceGroupName,
                    label: group.deviceGroupLabel,
                };
            }),
        ];

        setDeviceGroups(deviceGroupDatasource);
        setSelectedDeviceGroup(clientDeviceGroup.deviceGroupName);
    }, [selectedClient]);

    const handleUpload = async (options: { files: File[] }): Promise<any> => {
        setErrorMessage(null);
        try {
            if (!selectedClient) return setErrorMessage('A client must be selected');
            return await deviceApiClient.uploadCommissioningDevices(currentUser.accountId, {
                importType: deviceKeyType,
                files: options.files,
                selectedDeviceGroup: selectedDeviceGroup,
            });
        } catch (err) {
            setErrorMessage(err.message);
        }
    };

    const onUploadFinish = (devices: Partial<ICommissioningDevice>[]): void => {
        const clientName = clientAccounts.find((client) => client.value == selectedClient).label;
        const deviceGroupLabel = deviceGroups
            .find((deviceGroup) => deviceGroup.value == selectedDeviceGroup)
            .label.trim();

        navigate(commissionUploadReviewRoute.path, {
            state: {
                keyType: deviceKeyType,
                clientAccountId: selectedClient,
                clientAccountName: clientName,
                deviceGroup: selectedDeviceGroup,
                deviceGroupLabel: deviceGroupLabel,
                devices: devices.map(device => {
                    const buildDisplayDeviceInfo = (status: string, comment?: string) => ({ ...device, status, comment });
                    if (!device.identifier)
                        return buildDisplayDeviceInfo(COMMISSION_STATUS.NOT_FOUND, `The ${IMPORT_TYPE[importType]} doesn’t match or device doesn’t exist in the system`);
                    if (device.client)
                        return buildDisplayDeviceInfo(COMMISSION_STATUS.NOT_AVAILABLE, `Already commissioned to "${device.client}"`);
                    const status = toCommissionStatus(device);
                    let comment: string;
                    switch (status) {
                        case COMMISSION_STATUS.COMMISSIONABLE:
                            break;
                        case SCR_TAMPER_STATUS.SCR_TAMPER:
                            comment = 'The device is tampered';
                            break;
                        case SCR_TAMPER_STATUS.INVALID_FIRMWARE:
                            comment = `The firmware with version ${device.firmwareVersion} is invalid`;
                            break;
                        case SCR_TAMPER_STATUS.SCR_BATTERY_NOT_OK:
                            comment = `The ${device.identifier} has a low battery`;
                            break;
                        default:
                            break;
                    }
                    return buildDisplayDeviceInfo(status, comment);
                }),
            },
        });
    };

    const CommissioningUploadForm = () => (
        <BasicForm
            className={commissioningUploadCss.form}
            cardPros={{ bordered: false }}
            items={[
                <div className={commissioningUploadCss.item} key='selectedClient'>
                    <SelectFormItem
                        code='selectedClient'
                        label='Client'
                        isRequired={true}
                        labelAlign='right'
                        dataSource={clientAccounts}
                        selectedValue={selectedClient}
                        onChange={setSelectedClient}
                    />
                </div>,
                <div className={commissioningUploadCss.item} key='selectedDeviceGroup'>
                    <SelectFormItem
                        disabled={deviceGroupStore.dataLoading}
                        code='selectedDeviceGroup'
                        label='Device group'
                        isRequired={false}
                        labelAlign='right'
                        dataSource={deviceGroups}
                        selectedValue={selectedDeviceGroup}
                        onChange={setSelectedDeviceGroup}
                    />
                </div>,
                <div className={commissioningUploadCss.item} key='importType'>
                    <SelectFormItem
                        code='importType'
                        label='Import type'
                        labelAlign='right'
                        dataSource={importTypeDatasource}
                        selectedValue={importType}
                        onChange={setImportType}
                    />
                </div>,
            ]}
        />
    );

    return (
        <React.Fragment>
            {errorMessage && (
                <DangerAlert className={commissioningUploadCss.uploadAlert} closable={true} message={errorMessage} />
            )}
            <h2>Commissioning Upload</h2>
            <CommissioningUploadForm />
            <ManualFileUpload
                onUploadFiles={handleUpload}
                onSuccessOrError={onUploadFinish}
                uploadLabel='Upload and Review'
                uploadingLabel='Uploading...'
                label='Upload CSV file'
                acceptFormats='.csv'
            />
            <IconTextButton
                type='primary'
                label='Back to Device Inventory'
                onClick={() => navigate(inventoryRoute.path)}
            />
        </React.Fragment>
    );
});
