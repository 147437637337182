import { IApiClientOptions } from 'core';
import {
    CreateDeploymentGroupDetailsProps,
    IDeploymentGroup,
    IDeploymentGroupQuery,
    UpdateDeploymentGroupDetailsProps,
} from '../dto/deployment/deployment-group-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';
import { IDeploymentProgressDetails } from 'dto/deployment/deployment-dto';

export class DeploymentGroupsApiClient extends AssureBaseApiClient {
    constructor(options: IApiClientOptions) {
        super('deploymentGroups', options);
    }

    protected getRootPath(): string {
        return 'deployment-groups';
    }

    public async getDeploymentGroupDetails(
        accountId: number,
        groupId: string,
        query?: IDeploymentGroupQuery,
    ): Promise<IDeploymentGroup> {
        const result = await this.send<{ deploymentGroup: IDeploymentGroup }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${groupId}`, query),
        );
        return result.deploymentGroup;
    }

    public async getDeploymentGroups(accountId: number): Promise<IDeploymentGroup[]> {
        const result = await this.send<{ deploymentGroups: IDeploymentGroup[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId),
        );
        return result.deploymentGroups;
    }

    public async updateDeploymentGroup(
        accountId: number,
        deploymentGroupId: number,
        changedOptions: UpdateDeploymentGroupDetailsProps,
    ): Promise<IDeploymentGroup> {
        const result = await this.send<{ deploymentGroup: IDeploymentGroup }>(
            HTTP_METHOD.PUT,
            this.getRequestOptionsWithAccountId(accountId, `${deploymentGroupId}`, changedOptions),
        );
        return result.deploymentGroup;
    }

    public async createDeploymentGroup(
        accountId: number,
        newDeploymentGroup: CreateDeploymentGroupDetailsProps,
    ): Promise<IDeploymentGroup> {
        const result = await this.send<{ deploymentGroup: IDeploymentGroup }>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, '', newDeploymentGroup),
        );
        return result.deploymentGroup;
    }

    public async deleteDeploymentGroup(accountId: number, deleteId: number): Promise<string> {
        const result = await this.send<{ message: string }>(
            HTTP_METHOD.DELETE,
            this.getRequestOptionsWithAccountId(accountId, `${deleteId}`),
        );
        return result.message;
    }

    public async getActiveDeploymentDetailsByGroupId(accountId: number, groupId: number): Promise<any[]> {
        const result = await this.send<{ deployments: any[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${groupId}/active-deployment-details`),
        );
        return result.deployments;
    }
}
