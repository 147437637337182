import * as React from 'react';
import { observer } from 'mobx-react';
import { AdvanceEditableTable, IEditableTableColumn, IEditableTableProps, Select, SelectFormItem } from 'ui-lib';
import { IAccountGroupOptions, IItemOptions } from '../../../../store/wizard-store';
import { IAccountTableProps } from './commission-interface';

import wizardCss from './wizard.css';

export const CreateOrSelectCustomerPartnerGroup = observer((props: IAccountTableProps) => {
    const dataSource = props.table.dataSource;

    const tableDataSource = !dataSource ? [] : [dataSource];

    const [varAccountSelected, setVarAccountSelected] = React.useState(props.store.displaySelectedVarAccount);
    const [clientAccountSelected, setClientAccountSelected] = React.useState(props.store.displaySelectedClientAccount);

    React.useEffect(
        () => setVarAccountSelected(props.store.displaySelectedVarAccount),
        [props.store.displaySelectedVarAccount],
    );
    React.useEffect(
        () => setClientAccountSelected(props.store.displaySelectedClientAccount),
        [props.store.displaySelectedClientAccount],
    );

    const organisationSelectItemComponent = (componentProps) => {
        const record: IAccountGroupOptions<IItemOptions> = componentProps.record;

        const onChange = (value) => {
            const selected = record.varAccounts.find((item) => item.value == value);
            setVarAccountSelected(selected);
            setClientAccountSelected(record.clientAccounts[selected.value][0]);
        };

        const OrganisationSelectItem = () => {
            return (
                <SelectFormItem
                    onChange={onChange}
                    disabled={props.store.isVarAccountSelected}
                    code="organisation"
                    label="Organisation"
                    dataSource={record.varAccounts || []}
                    selectedValue={varAccountSelected?.value}
                    placeholder="Organisation"
                />
            );
        };
        return <OrganisationSelectItem/>;
    };

    const customerPartnerSelectComponent = (componentProps) => {
        const record: IAccountGroupOptions<IItemOptions> = componentProps.record;
        const customerDataSource = record.clientAccounts || [];

        const onChange = (value) => (
            setClientAccountSelected(customerDataSource[varAccountSelected.value].find((item) => item.value === value))
        );

        const CustomerPartnerSelectItem = () => {
            return (
                <Select
                    code="customerPartner"
                    onChange={onChange}
                    disabled={props.store.isClientAccountSelected}
                    dataSource={
                        props.store.displayVarClientAccountGroups.clientAccounts[varAccountSelected?.value] || []
                    }
                    selectedValue={clientAccountSelected?.value}
                    value={clientAccountSelected?.value}
                    placeholder="Customer/Partner"
                    className={wizardCss.selectPartner}
                />
            );
        };
        return <CustomerPartnerSelectItem/>;
    };

    const indexesColumn: IEditableTableColumn = {
        code: 'indexes',
        title: '#',
        dataIndex: 'index',
    };

    const organisationsColumn: IEditableTableColumn = {
        code: 'organisations',
        title: 'Organisations',
        dataIndex: 'organisations',
        component: organisationSelectItemComponent,
    };

    const customerPartner: IEditableTableColumn = {
        code: 'customerPartner',
        title: 'Customer/Partner',
        dataIndex: 'customerPartner',
        component: customerPartnerSelectComponent,
    };

    const actionColumn = {
        code: 'action',
        title: 'Action',
        dataIndex: 'action',
        actions: [
            {
                label: 'Select Group',
                key: 'key',
                onClick: (value) => props.onSelectGroup(varAccountSelected, clientAccountSelected),
            },
        ],
    };

    const tableProps: IEditableTableProps = {
        columns: [indexesColumn, organisationsColumn, customerPartner, actionColumn],
        pagination: false,
        dataSource: tableDataSource?.map((item, index) => ({ index: index + 1, ...item })),
        className: wizardCss.table,
    };
    return (
        <AdvanceEditableTable title="Customer/Partner groups" loadingState={props.table.loading} table={tableProps}/>
    );
});
