import { ICryptoReportResult } from 'dto/device-dto';
import { computed, makeObservable } from 'mobx';
import { AbstractDeviceDetailWithCommandCodeStore, ICommandCodeConstructorOptions } from './device-detail-with-command-code-store';

export type ICryptoReportConstructorOptions = Omit<ICommandCodeConstructorOptions, 'code'>;

export class DeviceCryptoReportStore extends AbstractDeviceDetailWithCommandCodeStore<ICryptoReportResult> {
    constructor(options: ICryptoReportConstructorOptions) {
        super({ code: 'crypto_report', ...options });
        makeObservable(this);
    }

    /** ************************************
     * Override functions
     **************************************/
    protected name(): string {
        return 'device Crypto report';
    }

    protected async initializeData(): Promise<ICryptoReportResult[]> {
        return [await this._devicesStore.getDeviceCryptoReport(this.accountId, this.uid)];
            
    }
    /** *************************************/

    @computed
    public get displayCryptoReport() {
        if (!this.entities) return 'no data found';
        return JSON.stringify(this.entities[0],null,2);
    }

}
