import React from 'react';
import { observer } from 'mobx-react';
import { upperFirst } from 'lodash';

import {
    AdvanceTable,
    IBasicTableColumn,
    IBasicTableProps,
    IconTextButton,
    Label,
    Skeleton,
    DangerAlert,
} from 'ui-lib';
import { getErrorMessage } from 'common-utils';
import { IDeviceTableProps } from './device-details';
import { DeviceJobsStore, IDisplayJobExecutionSummary } from 'store/device-details/device-jobs-store';
import { showConfirmation, showNotification } from './utils';

import devicesCss from './devices.css';

const ALLOW_CANCEL_JOB_STATUS = ['QUEUED', 'IN_PROGRESS'];

export const DeviceJobTable = observer((props: IDeviceTableProps<DeviceJobsStore>) => {
    const { store } = props;

    React.useEffect(() => {
        (async function loadData() {
            await store.initialize();
        })();
    }, []);

    const onAction = (job: IDisplayJobExecutionSummary) =>
        showConfirmation(`Are you sure you want to ${job.canRerun ? 'Re-run' : 'Cancel'} this job?`, async () => {
            showNotification('info', `Job ${job.canRerun ? 're-run' : 'cancellation'} has been started.`);
            try {
                await store.runExecutionCommand(job);
            } catch (error) {
                showNotification('error', getErrorMessage(error));
            }
        });

    const columns: IBasicTableColumn[] = [
        { code: 'jobName', title: 'Job Name', dataIndex: 'jobName' },
        {
            code: 'jobId',
            title: 'Job ID',
            dataIndex: 'jobId',
            render: (_, record: IDisplayJobExecutionSummary) => {
                return !record.ownerJob
                    ? record.jobId
                    : (
                        <React.Fragment>
                            <Label label={record.jobId} />
                            <br />
                            <Label label={`(${record.ownerJob.jobId})`} />
                        </React.Fragment>
                    );
            },
        },
        { code: 'status', title: 'Status', dataIndex: 'status' },
        {
            code: 'displayLastUpdatedAt',
            title: 'Last Updated At',
            dataIndex: 'displayLastUpdatedAt',
            sorter: (a: IDisplayJobExecutionSummary, b: IDisplayJobExecutionSummary) =>
                a.lastUpdatedAt?.localeCompare(b.lastUpdatedAt),
        },
        {
            code: 'actions',
            title: 'Action',
            dataIndex: 'jobId',
            render: (_, record: IDisplayJobExecutionSummary) => {
                const jobStatus = record['status'];
                return (
                    <IconTextButton
                        label={record.canRerun ? 'Re-run' : 'Cancel'}
                        type='link'
                        disabled={!ALLOW_CANCEL_JOB_STATUS.includes(jobStatus) && !record.canRerun}
                        onClick={() => onAction(record)}
                    />
                );
            },
        },
    ];

    const expandedRowRender = (row: IDisplayJobExecutionSummary) => {
        const responsePayload = row.data;

        const innerColumns = Object.keys(responsePayload || [])?.map((key) => {
            return {
                code: key,
                title: upperFirst(key),
                dataIndex: key,
            };
        });
        return (
            <Skeleton
                loading={responsePayload == undefined}
                noOfRows={1}
            >
                <React.Fragment>
                    {responsePayload && innerColumns.length > 0 ? (
                        <AdvanceTable
                            title={'Job Execution Message'}
                            table={{ columns: innerColumns, dataSource: [{ ...responsePayload, key: row.key }] }}
                        />
                    ) : (
                        <Label label={'No response message'} />
                    )}
                </React.Fragment>
            </Skeleton>
        );
    };

    const table: IBasicTableProps = {
        columns,
        dataSource: store.displayJobs,
        expandable: {
            expandedRowRender,
            onExpand: async (expanded: boolean, record: any) => store.onExpand(expanded, record),
        },
    };

    return (
        <div className={devicesCss.tabPane}>
            {store.error != undefined && <DangerAlert closable={false} message='Error' description={store.error} />}
            <AdvanceTable
                title=''
                table={table}
                loadingState={store.loading}
                toolbar={{
                    onReload: () => store.fetchLatestData(),
                }}
            />
        </div>
    );
});
