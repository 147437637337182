import { IconName } from '../../ui-lib/components/icon';

export interface IRouteConstructorOptions {
    code: string;
    permissionCodes?: string[];
    hasMainMenuEntry?: boolean;
    mainMenuOnClick?: boolean;
    label: string;
    iconName?: IconName;
    path: string;
    subRouteCodes?: string[];
}

export class Route {
    private readonly _code: string;
    private readonly _permissionCodes: string[];
    private readonly _hasMainMenuEntry: boolean;
    private readonly _mainMenuOnClick: boolean;
    private readonly _label: string;
    private readonly _iconName: IconName;
    private readonly _path: string;
    private readonly _subRouteCodes: string[];
    private _subRoutes: Route[];

    constructor(options: IRouteConstructorOptions) {
        this._code = options.code;
        this._permissionCodes = options.permissionCodes;
        this._hasMainMenuEntry = options.hasMainMenuEntry;
        this._mainMenuOnClick = options.mainMenuOnClick;
        this._label = options.label;
        this._iconName = options.iconName;
        this._path = options.path;
        this._subRouteCodes = options.subRouteCodes;
    }

    public get hasMainMenuEntry(): boolean {
        return this._hasMainMenuEntry;
    }

    public get mainMenuOnClick(): boolean {
        return this._mainMenuOnClick;
    }

    public get code(): string {
        return this._code;
    }

    public hasPermission(permissionCodes: string[]): boolean {
        if (!this._permissionCodes || !permissionCodes) return false;
        return !!this._permissionCodes.find((permissionCode) => permissionCodes.includes(permissionCode));
    }

    public get label(): string {
        return this._label;
    }

    public get iconName(): IconName {
        return this._iconName;
    }

    public get path(): string {
        return this._path;
    }

    public get to(): string {
        return this._path;
    }

    public trimRootPath(rootPath: string): string {
        return this.path.startsWith(rootPath) ? this.path.substring(rootPath.length) : this.path;
    }

    public get subRouteCodes(): string[] {
        return this._subRouteCodes;
    }

    public get subRoutes(): Route[] {
        return this._subRoutes;
    }

    public set subRoutes(routes) {
        this._subRoutes = routes;
    }
}
