import React from 'react';

import { FormItem, FormListItem, TextFieldItem, IconTextButton, Space, Icon } from 'ui-lib';

import { inputAlphanumericRule } from './form-utils';
import { IDeviceGroupDataInput } from '../../../dto/device-group-dto';

export interface IAttributeDisplayProps {
    key: string;
    value: string;
}

export interface IAttributeFormItemProps {
    code: string;
    className?: string;
    label?: string;
    initialValue: IAttributeDisplayProps[];
}

export function getIoTAttribute(attributes: IAttributeDisplayProps[]): IDeviceGroupDataInput {
    if (!attributes) return {};
    return attributes.reduce((previous, current) => {
        previous[current.key] = current.value;
        return previous;
    }, {});
}

export function getDisplayAttribute(attribute: IDeviceGroupDataInput): IAttributeDisplayProps[] {
    if (!attribute) return [];
    return Object.keys(attribute).reduce((previous, key) => {
        previous.push({
            key: key,
            value: attribute[key],
        });

        return previous;
    }, []);
}

const icons = {
    plus: 'file-plus',
    minimize: 'close',
};

const inputAttributeRule = inputAlphanumericRule(25, ['UNDERSCORE', 'SPACE', 'MINUS']);

export const AttributeFormItem = (props: IAttributeFormItemProps) => {
    return (
        <FormListItem
            code={props.code}
            label={props.label}
            labelCol={{ span: 5 }}
            labelAlign={'right'}
            colon={false}
            formListProps={{
                initialValue: props.initialValue,
                name: 'deviceGroupAttribute',
                children: (fields, { add, remove }) => (
                    <React.Fragment>
                        {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} className={props.className} align='baseline'>
                                <TextFieldItem
                                    code={'attributeKey'}
                                    placeholder='Attribute key'
                                    name={[name, 'key']}
                                    rule={[
                                        {
                                            required: true,
                                            message: 'Missing the attribute key',
                                        },
                                        inputAttributeRule,
                                    ]}
                                    {...restField}
                                />
                                <TextFieldItem
                                    code={'attributeValue'}
                                    placeholder='Attribute value'
                                    name={[name, 'value']}
                                    rule={[
                                        { required: true, message: 'Missing the attribute value' },
                                        inputAttributeRule,
                                    ]}
                                    {...restField}
                                />
                                <div onClick={() => remove(name)}>
                                    <Icon iconName={icons.minimize as any} size='SMALL' />
                                </div>
                            </Space>
                        ))}
                        <FormItem
                            code={'addAttributeButton'}
                            child={
                                <IconTextButton
                                    onClick={() => add()}
                                    iconName={icons.plus as any}
                                    key='addAttribute'
                                    label='Add Attribute'
                                    type='primary'
                                />
                            }
                        />
                    </React.Fragment>
                ),
            }}
        />
    );
};
