import { action, makeObservable, observable, reaction } from 'mobx';
import { AuthStore, IAuthUser } from 'core';
import { AccountStore } from './account-store';
import type { SelectDataSource } from './assure-base.store';
import { AccountType } from '../dto/access-management/account-dto';
import { isNullOrEmpty } from 'common-utils';

export class CommonStore {
    constructor(private readonly _authStore: AuthStore, private readonly _accountStore: AccountStore) {
        makeObservable(this);
        reaction(
            () => [this._authStore.currentUser, this._accountStore.accountHierarchy],
            (values) => {
                const currentUser = values[0] as IAuthUser;
                if (!currentUser) {
                    this.setOrganisations([]);
                    this.setSelectedOrganisation(null);
                    return;
                }
                const sources = this._accountStore.getVARAccountDataSource(currentUser.accountType);
                this.setOrganisations(sources);
                this.setSelectedOrganisation(
                    currentUser.accountType === AccountType.DPU ? sources[0]?.value as number : currentUser.accountId,
                );
            },
        );
    }

    @action
    finishLoading(): boolean {
        return (this.isLoading = false);
    }

    @action
    setOrganisations(organisations: SelectDataSource[]): void {
        this.organisations = organisations;
    }

    @observable
    isLoading = true;

    @observable
    organisations: SelectDataSource[];

    @observable
    selectedOrganisation: number;

    @action
    setSelectedOrganisation(selectedOrganisationId: number): void {
        if (!selectedOrganisationId || isNullOrEmpty(this.organisations) || this.organisations.some(item => item.value == selectedOrganisationId)) {
            this.selectedOrganisation = selectedOrganisationId;
            return;
        }
        const selectedOrganisation = this._accountStore.findAccount(selectedOrganisationId);
        if (!selectedOrganisation || selectedOrganisation.accountType != AccountType.CLIENT) return;
        this.selectedOrganisation = selectedOrganisation.parent.id;
    }
}
