import { IApiClientOptions } from 'core';

import { ISaveUserProps, IUser } from '../dto/access-management/user-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';

export class UserApiClient extends AssureBaseApiClient {
    constructor(options: IApiClientOptions) {
        super('user', options);
    }

    protected getRootPath(): string {
        return '/users';
    }

    public async getUsers(): Promise<IUser[]> {
        const result = await this.getAllEntities<{ users: IUser[] }>();
        return result.users;
    }

    public async getUser(id: string): Promise<IUser> {
        const result = await this.send<{ user: IUser }>(HTTP_METHOD.GET, this.getRequestOptions('user', { uuid: id }));
        return result.user;
    }

    public async getUserByEmail(email: string): Promise<IUser> {
        const result = await this.send<{ user: IUser }>(HTTP_METHOD.GET, this.getRequestOptions('user', { email }));
        return result.user;
    }

    public async createUser(user: ISaveUserProps): Promise<IUser> {
        const result = await this.send<{ user: IUser }>(HTTP_METHOD.POST, this.getRequestOptions('add', { user }));
        return result.user;
    }

    public async editUser(id: string, user: ISaveUserProps): Promise<IUser> {
        const result = await this.send<{ user: IUser }>(HTTP_METHOD.PUT, this.getRequestOptions(`edit/${id}`, { user }));
        return result.user;
    }

    public async deleteUser(id: string): Promise<boolean> {
        return this.deleteEntity<boolean>({ uuid: id });
    }
}
