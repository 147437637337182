import * as React from 'react';
import { Typography } from 'ui-lib';

import nextVersionCss from './next-version.css';

export function VersionDescription(): JSX.Element {

    const Text = Typography.Text;

    return (
        <section className={nextVersionCss.description}>
            <p>
                A version is in form <Text code>X.Y.Z</Text> where <Text code>X</Text>, <Text code>Y</Text>, and{' '}
                <Text code>Z</Text> are non-negative integers. <Text code>X</Text> is the major version,{' '}
                <Text code>Y</Text> is the minor version, and <Text code>Z</Text> is the patch version. Given a
                version number <Text code>Major.Minor.Patch</Text>, increment the:
            </p>
            <ol>
                <li>
                    <Text code>Major</Text> version when making incompatible changes
                </li>
                <li>
                    <Text code>Minor</Text> version when adding new functionalities, still backwards compatible
                </li>
                <li>
                    <Text code>Patch</Text> version in bugs fixing
                </li>
            </ol>
        </section>
    );
}
