import * as React from 'react';
import cx from 'classnames';
import { Layout, Typography, Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Icon, Avatar, IconName } from '../icon';

import headerCss from './header.css';
import { HorizontalMenu, IMenuItem, VerticalMenu } from '../menu';
import { RowLayout } from '../grid';
import { useLocation } from 'react-router-dom';

export interface IHeaderIconTitleOptions {
    iconName: IconName;
    title: string;
    onClick?: () => void;
}

export interface IHeaderProps {
    headerIconTitle?: IHeaderIconTitleOptions;
    headerTitle?: string;
    menuItems: IMenuItem[];
    actionItems?: IMenuItem[];
    actionTitle?: string;
    isAuthenticated?: boolean;
    className?: string;
    showBadge?: boolean;
}

export const Header = (props: IHeaderProps) => {
    const { menuItems, headerTitle, isAuthenticated, headerIconTitle } = props;
    const location = useLocation();

    // const getActionOverlay = () =>
    //     Array.isArray(props.actionItems) ? <VerticalMenu menuItems={props.actionItems} /> : null;

    const parseUrlLocation = (pathname: string) => {
        const safepath = pathname.replace(/^\/?|\/?$/g, '');
        const pathArray = safepath.split('/');
        return pathArray.map((item, index) => `/${pathArray.slice(0, index + 1).join('/')}`);
    };

    return isAuthenticated ? (
        <Layout.Header className={cx(headerCss.header, props.className)}>
            <RowLayout
                childItems={[
                    <div className={headerCss.brand}>
                        {headerIconTitle ? (
                            <Icon
                                iconName={headerIconTitle.iconName}
                                iconClassName={headerCss['icon-hover']}
                                size='REGULAR'
                                text={headerIconTitle.title}
                                asPrefix
                                onClick={headerIconTitle.onClick}
                            />
                        ) : null}
                        {headerTitle ? <Typography.Text>{headerTitle}</Typography.Text> : null}
                    </div>,
                    <div className={headerCss.mainMenu}>
                        <HorizontalMenu menuItems={menuItems} selectedKeys={parseUrlLocation(location.pathname)} />
                    </div>,
                    <div className={headerCss.profile}>
                        {Array.isArray(props.actionItems) ? (
                            <Dropdown
                                overlayClassName={headerCss.profileDropdown}
                                overlay={<VerticalMenu menuItems={props.actionItems} />}
                            >
                                <a>
                                    <Avatar
                                        icon={<UserOutlined />}
                                        label={props.actionTitle}
                                        showBadge={props.showBadge}
                                    />
                                </a>
                            </Dropdown>
                        ) : (
                            <Avatar icon={<UserOutlined />} label={props.actionTitle} />
                        )}
                    </div>,
                ]}
                childCols={[6, 16, 2]}
            />
        </Layout.Header>
    ) : null;
};
