import * as React from 'react';
import { Radio, Space } from 'antd';

export interface IRadioItem {
    value: string | number;
    label: string;
}

export interface IRadioGroupProps {
    onChange: (value: string | number) => void;
    value: string | number;
    items: IRadioItem[];
    vertical?: boolean;
}

export function RadioGroup(props: IRadioGroupProps) {
    const { onChange, value, vertical, items } = props;

    return (
        <Radio.Group onChange={e => onChange(e.target.value)} value={value}>
            <Space direction={vertical ? 'vertical' : 'horizontal'}>
                {items.map(item => (
                    <Radio key={item.label} value={item.value}>
                        {item.label}
                    </Radio>
                ))}
            </Space>
        </Radio.Group>
    );
}
