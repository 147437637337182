import * as React from 'react';
import { Card } from 'antd';
import { Skeleton } from '../loading';
import basicCardCss from './basic-card.css';
export interface IBasicCardProps {
    code?: string;
    contents: any;
    footers?: any;
    className?: string;
    titleClassName?: string;
    extra?: any;
    title?: string | React.ReactNode;
    onUpdate?: any;
    onError?: any;
    loading?: boolean;
    size?: 'small' | 'default';
    actions?: any[];
    bordered?: boolean;
    onClick?: React.MouseEventHandler;
}

export function BasicCard(props: IBasicCardProps) {
    const Footer = (): JSX.Element => {
        if (props.footers == null) return <></>;
        return (
            <span key={`footer-${props.code}`} className={basicCardCss.footer}>
                {Array.isArray(props.footers)
                    ? props.footers.map((content, index) => <span key={`footer-${props.code}-${index}`}>{content}</span>)
                    : props.footers}
            </span>
        );
    };

    return (
        <section key={`section-${props.code}`} className={props.className}>
            <Card
                key={props.code}
                size={props.size}
                title={props.title}
                extra={props.extra}
                className={props.titleClassName}
                bordered={props.bordered}
                onClick={props.onClick}
            >
                <Skeleton loading={props.loading}>
                    <div key={`wrapper-${props.code}`} className={basicCardCss.wrapper}>
                        {Array.isArray(props.contents)
                            ? props.contents.map((content, index) => {
                                  return <span key={`wrapper-${props.code}-${index}`}>{content}</span>;
                            })
                            : props.contents}
                        {<Footer/>}
                    </div>
                </Skeleton>
            </Card>
        </section>
    );
}
