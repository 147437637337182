import { getErrorMessage } from 'common-utils';
import { AuthStore } from 'core';
import { AccountType } from 'dto/access-management/account-dto';
import { AssureBaseApiClient } from '../api/assure-base-api-client';
import { AccountStore } from './account-store';
import { AssureBaseStore, IAssureStoreConstructorOptions, IDBRecord } from './assure-base.store';

export abstract class SoftwareManagementStore<
    T extends AssureBaseApiClient,
    Q extends IDBRecord,
> extends AssureBaseStore<T, Q> {

    protected readonly _authStore: AuthStore;
    protected constructor(options: IAssureStoreConstructorOptions, protected readonly accountStore: AccountStore) {
        super(options);
        this._authStore = options.authStore;
    }

    protected abstract getDataByAccountId(accountId: number): Promise<Q[]>;

    async loadEntities(accountId: number, force?: boolean): Promise<Q[]> {
        if (!force && this.hasEntities()) return this.entities;
        this.setDataLoading(true);
        this.setError(undefined);
        try {
            const result = await this.getDataByAccountId(accountId);
            this.setEntities(result);
            return this.entities;
        } catch (err) {
            this.setError(getErrorMessage(err));
        } finally {
            this.setDataLoading(false);
        }
    }

    public getAccountNameByUuid(accountUuid: string): string {
        if (this._authStore.currentUser.accountType != AccountType.CLIENT)
            return this.accountStore.managedAccounts.find((account) => account.uuid == accountUuid)?.name || '';
        const currentAcount = this.accountStore.managedAccounts.find((account) => account.id == this._authStore.currentUser.accountId);
        if (currentAcount.uuid == accountUuid) return currentAcount.name;
        return currentAcount.parent.uuid == accountUuid ? currentAcount.parent.name : '';
    }
}
