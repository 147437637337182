import * as React from 'react';

import { observer } from 'mobx-react';

import { exportFile, getErrorMessage } from 'common-utils';
import { VersionComparison as vc } from 'common-utils/debian-version-utils';

import {
    AdvanceTable,
    IBasicTableColumn,
    useForm,
    DangerAlert,
    IconTextButton,
    BasicForm,
    Modal,
    showConfirmDialogWithoutCancel,
    FormItem,
    SelectFormItem,
    DateTimePicker,
} from 'ui-lib';

import { DeviceLogsStore } from '../../../../store/device-details/device-logs-store';
import { IDeviceTableProps } from './device-details';
import devicesCss from './devices.css';
import { ILogOptions, DOWNLOAD_LOG_TYPE, DEVICE_LOG_TYPE } from 'dto/device-dto';
import { hasContainsIgnoreCase } from 'common-utils/string-utils';
import { DATE_TIME_FORMAT, formatDate } from 'common-utils/date-utils';

const logTypeDataSource = Object.values(DOWNLOAD_LOG_TYPE).map((item) => ({ value: item, label: item }));
export const DeviceLogsComponent = observer((props: IDeviceTableProps<DeviceLogsStore>) => {
    const { store } = props;
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState<boolean>(false);
    const [form] = useForm();
    const handleCancel = () => {
        setModalVisible(false);
    };
    React.useEffect(() => {
        store.initialize();
    }, []);

    const columns: IBasicTableColumn[] = [
        {
            code: 'data',
            title: 'Device Logs',
            dataIndex: 'data',
            defaultSortOrder: 'descend',
            filters: [{ text: 'TRAMPOLINE', value: 'TRAMPOLINE' }],
            onFilter: (type: string, item: ILogOptions) => item.data.toLowerCase().includes(type.toLowerCase()),
            sorter: (a: ILogOptions, b: ILogOptions) => a.timestamp?.localeCompare(b.timestamp),
        },
    ];

    const downloadModal = () => {
        setModalVisible(true);
    };
    const downloadLogFile = async (values: any) => {
        const trampolineVersion = await store.getTrampolineClientVersion();
        try {
            setIsProcessing(true);
            const deviceLogs = [];
            const downloadLogName = values.logTypeField.toLowerCase();
            const logType =
                values.logTypeField.toLowerCase() === DEVICE_LOG_TYPE.TERMLOG
                    ? DEVICE_LOG_TYPE.TERMLOG
                    : DEVICE_LOG_TYPE.SYSLOGS;
            if (values.logTypeField.toLowerCase() === DEVICE_LOG_TYPE.TERMLOG && vc.lt(trampolineVersion, '3.1.12')) {
                showConfirmDialogWithoutCancel({
                    title: 'Could not download Term log',
                    content: 'Please install Trampoline client 3.1.12 or higher version to download Term logs.',
                    okText: 'OK',
                });
                return;
            } else {
                store.displayDeviceLogs(logType).map((item) => {
                    if (values.logTypeField.toLowerCase() === DEVICE_LOG_TYPE.TERMLOG) {
                        deviceLogs.push(item.data);
                    } else {
                        if (hasContainsIgnoreCase(item.data, values.logTypeField)) {
                            deviceLogs.push(item.data);
                        }
                    }
                });
            }
            if (deviceLogs.length === 0) {
                showConfirmDialogWithoutCancel({
                    title: 'No logs found',
                    content: 'No logs data found for the provided type',
                    okText: 'OK',
                });
                return;
            }
            const fileContent = deviceLogs.join('\n');

            exportFile(`deviceLogs_${downloadLogName}_${formatDate(store.date.toDate(), DATE_TIME_FORMAT.DEFAULT)}`, fileContent, 'text/plain;');
        } catch (err) {
            showConfirmDialogWithoutCancel({
                title: 'Error',
                content: getErrorMessage(err),
                okText: 'OK',
            });
        } finally {
            setIsProcessing(false);
            setModalVisible(false);
        }
    };

    const LogModal = () => {
        return (
            <Modal
                isVisible={modalVisible}
                isClosable={true}
                onCancel={handleCancel}
                width={500}
                extraProps={{ footer: null }}
                title='Provide the download log type'
            >
                <BasicForm
                    form={form}
                    key='addOrEdit'
                    cardPros={{ bordered: false }}
                    items={[
                        <SelectFormItem
                            key='logTypeField'
                            code='logTypeField'
                            isRequired={true}
                            label='Log Type'
                            labelAlign='right'
                            labelColSpan={5}
                            placeholder='Select Log Type'
                            dataSource={logTypeDataSource}
                        />,
                        <FormItem
                            key='download'
                            code='button'
                            label=' '
                            labelAlign='right'
                            labelCol={{ span: 5 }}
                            colon={false}
                            child={
                                <IconTextButton
                                    key='save'
                                    label='Download'
                                    type='primary'
                                    htmlType='submit'
                                    loading={isProcessing}
                                />
                            }
                        />,
                    ]}
                    onSubmit={downloadLogFile}
                />
            </Modal>
        );
    };

    return (
        <div className={devicesCss.tabPane}>
            {store.error != undefined && <DangerAlert closable={false} message='Error' description={store.error} />}
            <AdvanceTable
                title=''
                loadingState={store.loading}
                table={{
                    columns: columns,
                    dataSource: store.displayDeviceLogs(DEVICE_LOG_TYPE.SYSLOGS),
                }}
                toolbar={{
                    extraItems: [
                        <DateTimePicker
                            key='date-time-picker'
                            disabledDateType={'DISABLE_FUTURE_DATES'}
                            defaultValue={store.date}
                            value={store.date}
                            onChange={(value: moment.Moment): void => {
                                store.setDate(value);
                            }}
                        />,
                        <IconTextButton
                            key='download'
                            label='Download'
                            type='primary'
                            loading={isProcessing}
                            onClick={downloadModal}
                        />,
                    ],
                    onReload: () => store.fetchLatestData(),
                }}
            />
            <LogModal />
        </div>
    );
});
