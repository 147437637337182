import { showConfirmDialog } from 'ui-lib';
import { TStoresContext } from '../../store';
import { RouteStore } from 'core';
import { KEY_ROUTE_CODES } from './keys';
import { DEVICES_ROUTE_CODES } from './devices';

const CANCEL_POPCONFIRM_CONTENT = 'Your changes will be lost. Do you want to cancel your editing?';

export function hasPermission(routeCode: string, stores: Partial<TStoresContext>): boolean {
    return stores.routeStore.getRoute(routeCode)?.hasPermission(stores.permissionStore.permissionCodes);
}

export function confirmDialog(content: JSX.Element | string, action: () => void | Promise<void>) {
    showConfirmDialog({
        title: 'Confirmation',
        content: content,
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: action,
    });
}

export function confirmCancel(action: () => void | Promise<void>) {
    confirmDialog(CANCEL_POPCONFIRM_CONTENT, action);
}

export function confirmPopup(title: string, content: string, code: string | number, action: any) {
    showConfirmDialog({
        title,
        content,
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: () => action(code),
    });
}

export function confirmDelete(code: string | number, content: string, action: any) {
    confirmPopup('Confirm Delete', content, code, action);
}

export const buildNavigatePath = (path: string, options?: { [subPath: string]: string }): string => {
    if (!options) return path;

    const subPaths = Object.keys(options);
    if (subPaths.length == 0) return path;

    return subPaths.reduce((path, subPath) => {
        return path.replace(subPath, options[subPath]);
    }, path);
};

export const buildNavigateToDeviceDetailsPath = (routeStore: RouteStore, uid: string) => {
    return buildNavigatePath(routeStore.getRoute('DEVICES_DETAILS').path, { ':uid': uid });
};

export const getDefaultPath = (stores: Partial<TStoresContext>) => {
    return (hasPermission(DEVICES_ROUTE_CODES.INVENTORY, stores)
                        ? stores.routeStore.getRoute(DEVICES_ROUTE_CODES.INVENTORY).path
                        : stores.routeStore.getRoute(KEY_ROUTE_CODES.INVENTORY).path);
};
