/* eslint-disable react/no-children-prop */
import React from 'react';
import { observer } from 'mobx-react';
import {
    AdvanceTable,
    BasicCard,
    DangerAlert,
    Divider,
    IBasicTableColumn,
    Icon,
    IconTextButton,
    Modal,
    Skeleton
} from 'ui-lib';

import { getRangeDateTimeFilterColumnProps } from 'pages/private/components/table-utils';

import { useStores } from 'store';
import { IReleaseNotification } from 'store/notifications-store';

import maintenanceUpdatesCss from './maintenance-updates.css';
import {
    DATE_TIME_FORMAT,
    formatShortDate,
    getTimeFromServiceWindowSettings,
} from 'common-utils/date-utils';

export const MaintenanceUpdatesPage = (): JSX.Element => {

    const { notificationsStore } = useStores();

    const [showReleaseNotes, setShowReleaseNotes] = React.useState<boolean>(false);
    const [selectedNotifyRelease, setSelectedNotifyRelease] = React.useState<IReleaseNotification>();
    const showReleaseNotesAction = (release: IReleaseNotification): void => {
        setSelectedNotifyRelease(release);
        setShowReleaseNotes(true);
    };

    const closeDialogReleaseNotes = (): void => setShowReleaseNotes(false);

    const ReleaseNotesComponent = (): JSX.Element => (
        <div className={maintenanceUpdatesCss.modal}>
            <div className={maintenanceUpdatesCss.title}>
                <h3>Release notes</h3>
                <Icon
                    className={maintenanceUpdatesCss.rightItem}
                    iconName='close'
                    size='SMALL'
                    onClick={closeDialogReleaseNotes}
                />
            </div>
            <Divider key='divider-top' className={maintenanceUpdatesCss.dividerTop} />
            <p>{selectedNotifyRelease.releaseNotes || 'NO DATA'}</p>
            <Divider key='divider-bottom' className={maintenanceUpdatesCss.dividerBottom} />
            <IconTextButton
                className={maintenanceUpdatesCss.rightItem}
                label='OK'
                htmlType='button'
                type='primary'
                onClick={closeDialogReleaseNotes}
            />
        </div>
    );

    const UpcomingUpdatesComponent = observer((): JSX.Element => (
        <React.Fragment>
            <h2>Upcoming updates</h2>
            <Skeleton loading={notificationsStore.dataLoading}>
                {notificationsStore.releaseUpcomingUpdates.length == 0
                    ? (<BasicCard
                        key='no-upcoming-updates'
                        contents={<div className={maintenanceUpdatesCss.noData}>No data for upcoming updates.</div>}
                    />)
                    : notificationsStore.releaseUpcomingUpdates.map((item) => (
                        <BasicCard className={maintenanceUpdatesCss.notificationCard}
                            key={`notification-release-${item.id}`}
                            contents={
                                <div>
                                    <IconTextButton
                                        className={maintenanceUpdatesCss.title}
                                        type='link'
                                        htmlType='button'
                                        onClick={(): void => showReleaseNotesAction(item)}
                                        label={item.title}
                                    />
                                    <div>{item.message}</div>
                                    <div><strong>Release type: </strong>{item.releaseType}</div>
                                    <div><strong>Date: </strong>{formatShortDate(item.releaseDate)}</div>
                                    <div><strong>Time: </strong>{getTimeFromServiceWindowSettings()}</div>
                                    <div className={maintenanceUpdatesCss.footer}>
                                        <div>{formatShortDate(item.dateToDisplay)}</div>
                                        <div className={maintenanceUpdatesCss.messageType}>
                                            <Icon className={maintenanceUpdatesCss.bellIcon} iconName='bell' size='SMALL' />
                                            <div>{item.messageType}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                    ))
                }
            </Skeleton>
        </React.Fragment>
    ));

    const ReleaseHistoryComponent = observer((): JSX.Element => {
        const columns: IBasicTableColumn[] = [
            {
                code: 'title',
                title: 'Title',
                dataIndex: 'title',
                sorter: (a: IReleaseNotification, b: IReleaseNotification) => a.title?.localeCompare(b.title),
                textSearchPlaceholder: 'Search title',
                onFilter: (title: string, notification: IReleaseNotification) => notification.title?.toLowerCase().includes(title.toLowerCase()),
                iconName: 'search',
            },
            {
                code: 'releaseType',
                title: 'Type',
                dataIndex: 'releaseType',
                sorter: (a: IReleaseNotification, b: IReleaseNotification) => a.releaseType?.localeCompare(b.releaseType),
                filters: notificationsStore.releaseTypeFilterDataSource,
                onFilter: (releaseType: string, notification: IReleaseNotification) => notification.releaseType === releaseType,
            },
            {
                code: 'message',
                title: 'Message',
                dataIndex: 'message',
                sorter: (a: IReleaseNotification, b: IReleaseNotification) => a.message?.localeCompare(b.title),
                textSearchPlaceholder: 'Search title',
                onFilter: (message: string, notification: IReleaseNotification) => notification.message?.toLowerCase().includes(message.toLowerCase()),
                iconName: 'search',
            },
            {
                code: 'releaseNotes',
                title: 'Release notes',
                dataIndex: 'releaseNotes',
                render: (_, record: IReleaseNotification) => record.releaseNotes && (<IconTextButton
                    type='link'
                    htmlType='button'
                    onClick={(): void => showReleaseNotesAction(record)}
                    label='Notes'
                />),
            },
            {
                code: 'displayReleaseDateTimeString',
                title: 'Release date',
                dataIndex: 'displayReleaseDateTimeString',
                sorter: (a: IReleaseNotification, b: IReleaseNotification) => a.displayReleaseDateTimeString?.localeCompare(b.displayReleaseDateTimeString),
                ...getRangeDateTimeFilterColumnProps('displayReleaseDateTimeString', DATE_TIME_FORMAT.DATE_AND_SHORT_TIME),
            },
        ];

        return (
            <React.Fragment>
                <h2>Release history</h2>
                <AdvanceTable
                    loadingState={notificationsStore.dataLoading}
                    title=''
                    table={{
                        columns,
                        dataSource: notificationsStore.releaseHistory,
                        rowKey: 'id',
                        extraOptions: {
                            pagination: { hideOnSinglePage: true },
                        },
                    }}
                />
                <Modal width={800} isVisible={showReleaseNotes} children={<ReleaseNotesComponent />} />
            </React.Fragment>
        );
    });

    return (
        <React.Fragment>
            {notificationsStore.error ? <DangerAlert message={notificationsStore.error} className={maintenanceUpdatesCss.alert} /> : null}
            <div className={maintenanceUpdatesCss.description}>See upcoming updates and releases, release history and release notes.</div>
            <UpcomingUpdatesComponent />
            <ReleaseHistoryComponent />
        </React.Fragment>
    );
};
