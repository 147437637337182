import * as React from 'react';
import cx from 'classnames';
import { Button, Input, Space, Table as AntdTable } from 'antd';
import { FilterDropdownProps, TableRowSelection } from 'antd/lib/table/interface';
import type { IconName } from '@fortawesome/fontawesome-common-types';

import tableCss from './basic-table.css';
import { Icon } from '../icon';

export type TSortOrder = 'ascend' | 'descend' | null;
export type TCompareFn = (a, b, sortOrder?: TSortOrder) => number;
export type TFilterSearchFn = (input, record) => boolean;
export type TFilterFn = (value, record) => boolean;

export interface IFilterItem {
    text: React.ReactNode;
    value: string | number | boolean;
    children?: IFilterItem[];
}

export interface IBasicTableColumnAction {
    label?: string;
    key: string;
    onClick?: any;
}

export interface IBasicTableColumn {
    code: string;
    title: string | React.ReactNode;
    dataIndex: string;
    render?: (value: any, record: any) => React.ReactNode;
    defaultSortOrder?: TSortOrder;
    sortDirections?: TSortOrder[];
    sorter?: boolean | TCompareFn;
    filters?: IFilterItem[];
    textSearchPlaceholder?: string;
    filterDropdown?: React.ReactNode | ((props: FilterDropdownProps) => React.ReactNode);
    onFilter?: TFilterFn;
    iconName?: IconName;
    actions?: IBasicTableColumnAction[];
    width?: number | string;
    fixed?: 'left' | 'right';
    rowScope?: string;
    defaultFilteredValue?: string[];
}

export interface IBasicTableProps {
    className?: string;
    columns: IBasicTableColumn[];
    dataSource: any[];
    noData?: React.ReactNode;
    extraOptions?: any;
    rowSelection?: Pick<TableRowSelection<any>, 'defaultSelectedRowKeys' | 'onChange' | 'type' | 'getCheckboxProps'>;
    scroll?: any;
    expandable?: any;
    rowKey?: string;
    loading?: boolean;
    pagination?: boolean;
    defaultPageSize?: any;
    onTableChange?: (pagination, filters, sorter, extra) => void;
    rowClassName?: (record: any, index: number, indent: number) => string;
}

export function BasicTable(props: IBasicTableProps) {
    const { columns, dataSource, extraOptions, rowSelection, rowKey, scroll, expandable, pagination, defaultPageSize } =
        props;
    const searchInputRef = React.useRef(null);
    const renderFn = (column: IBasicTableColumn, text, record) => {
        if (column.render) {
            return column.render(text, record);
        }
        if (!column.actions) return text;

        return (
            <Space size='small'>
                {column.actions.map((action) => (
                    <Button
                        key={action.key}
                        type='link'
                        htmlType='button'
                        onClick={() => action.onClick(record[action.key])}
                    >
                        {action.label ? action.label : text}
                    </Button>
                ))}
            </Space>
        );
    };

    return (
        <AntdTable
            rowClassName={props.rowClassName}
            className={cx(tableCss.basicForm, props.className)}
            dataSource={dataSource}
            rowKey={rowKey}
            columns={columns.map((column) => {
                const isTextField = !column.filterDropdown && column.textSearchPlaceholder;
                return {
                    key: column.code,
                    title: column.title,
                    dataIndex: column.dataIndex,
                    width: column.width,
                    fixed: column.fixed,
                    defaultSortOrder: column.defaultSortOrder,
                    sortDirections: column.sortDirections,
                    sorter: column.sorter,
                    filters: column.filters,
                    filterDropdown:
                        column.filterDropdown ||
                        (isTextField
                            ? ({ setSelectedKeys, selectedKeys, confirm }) => {
                                  return (
                                      <Input.Search
                                          ref={searchInputRef}
                                          allowClear
                                          placeholder={column.textSearchPlaceholder}
                                          value={selectedKeys[0]}
                                          onChange={(e) => {
                                              setSelectedKeys(e.target.value ? [e.target.value] : []);
                                              confirm({ closeDropdown: false });
                                          }}
                                          onSearch={() => {
                                              confirm();
                                          }}
                                      />
                                  );
                              }
                            : null),
                    onFilter: column.onFilter,
                    onFilterDropdownVisibleChange: isTextField
                        ? (visible) => {
                              if (visible) {
                                  // The text box still not rendered. Need to delay before selecting.
                                  setTimeout(() => searchInputRef.current?.select(), 100);
                              }
                          }
                        : undefined,
                    filterIcon: column.iconName ? <Icon iconName={column.iconName} size='SMALL' /> : null,
                    render: (text, record) => renderFn(column, text, record),
                    defaultFilteredValue: column.defaultFilteredValue || null,
                };
            })}
            scroll={scroll}
            expandable={expandable}
            rowSelection={rowSelection}
            loading={props.loading}
            pagination={
                props.pagination != undefined
                    ? props.pagination
                    : { defaultPageSize: defaultPageSize || 20, showSizeChanger: true, hideOnSinglePage: pagination }
            }
            onChange={props.onTableChange}
            {...extraOptions}
        />
    );
}
