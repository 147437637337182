export class ResponseError {

    private readonly _message: string;
    private readonly _status: number;
    private readonly _validationErrors: any;

    constructor(props) {
        this._message = props.message;
        this._status = props.status || 500;
        this._validationErrors = props.validationErrors || {};
    }

    public toJSON() {
        return {
            status: this._status,
            validationErrors: this._validationErrors,
            message: this._message
        };
    }

    public get message(): string {
        return this._message;
    }

    public get status(): number {
        return this._status;
    }

    public get validationErrors(): any {
        return this._validationErrors;
    }
}