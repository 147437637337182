import { notification, showConfirmDialog } from 'ui-lib';
import { IDisplayJobExecutionSummary } from '../../../../store/device-details/device-jobs-store';
import { DEVICE_STATUS, IExecutionJobSummary, SCR_TAMPER_STATUS } from '../../../../dto/device-dto';
import { COMMISSION_STATUS, toCommissionStatus } from '../commissioning-upload';

export enum JobMapping {
    ping = 'Check health',
    reboot = 'Restart device',
    software_update = 'Software update',
    key_management = 'Key distribution',
    data_transfer = 'Data transfer',
    package_listing = 'Fetch package listing',
    gpg = 'GPG key distribution',
    shell = 'Shell command',
    process_listing = 'Fetch process listing',
    service_listing = 'Fetch service listing',
    stop_services = 'Stop service request',
    restart_services = 'Restart service request',
    start_services = 'Start service request',
    scr_listing = 'Fetch SCR file listing',
    service_updater = 'Service updater request',
    shell_command = 'Shell command',
    timezone = 'Timezone',
    scr_management = 'SCR management',
}

export const isCommissionable = (deviceStatus: DEVICE_STATUS, scrTamperStatus?: SCR_TAMPER_STATUS): boolean => (
    deviceStatus === DEVICE_STATUS.UNASSIGNED && toCommissionStatus({ tamper: { status: scrTamperStatus } }) === COMMISSION_STATUS.COMMISSIONABLE
);

export const getJobNameFromCode = (jobId: string): string => {
    const KEY_SEPARATOR = '_';
    const splitted = jobId.split(KEY_SEPARATOR);
    splitted.shift(); // Remove first
    splitted.splice(-1); // Remove last
    return JobMapping[splitted.join(KEY_SEPARATOR)] || jobId;
};

export const jobNameMapping = (jobs: IExecutionJobSummary[]): IDisplayJobExecutionSummary[] => {
    return jobs.map((job) => ({
        ...job,
        key: `${job.jobId}-${job.lastUpdatedAt}`,
        jobName: getJobNameFromCode(job.jobId),
    }));
};

export const showConfirmation = (content: string, action: () => any | Promise<any>): void => {
    showConfirmDialog({
        title: 'Confirmation',
        content: content,
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: action,
    });
};

export const showNotification = (type: string, message: string, description?: string): void => {
    return notification[type]({
        message,
        description,
        placement: 'topRight',
    });
};
