import React from 'react';
import { observer } from 'mobx-react';
import { getErrorMessage, isNullOrEmpty, toDeviceInfo } from 'common-utils';
import { SelectFormItem } from 'ui-lib';

import { ReportComponent } from './report-component';
import { DEFAULT_DEVICE_COLUMN, DevicesTableComponent } from '../../components/devices-table-component';
import { IDeviceInfo } from '../../../../dto/device-dto';
import { useStores } from '../../../../store';

import reportsCss from './reports.css';

const NO_FIRMWARE = { label: '<<No firmware>>', value: '' };

export const FirmwareVersionsReportComponent = observer(() => {
    const { deviceStore, commonStore } = useStores();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>(null);
    const [selectedVersion, setSelectedVersion] = React.useState<string>(null);
    const [versionDataSource, setVersionDataSource] = React.useState([]);
    const [deviceDataSource, setDeviceDataSource] = React.useState<IDeviceInfo[]>([]);
    const [isGenerateReport, setIsGenerateReport] = React.useState<boolean>(false);

    const onLoad = async (): Promise<void> => {
        try {
            setIsLoading(true);
            setErrorMessage(null);
            const availableFirmwares = await deviceStore.getInstalledFirmwareVersions(commonStore.selectedOrganisation);
            const versionsDataSource = [
                ...availableFirmwares.sort((a, b) => b?.localeCompare(a)).map((item) => {
                    if (isNullOrEmpty(item)) return NO_FIRMWARE;
                    return { label: item, value: item };
                }),
                NO_FIRMWARE,
            ];
            setVersionDataSource(versionsDataSource);

            setSelectedVersion(versionsDataSource[0].value);
        } catch (err) {
            setErrorMessage(getErrorMessage(err));
        } finally {
            setIsLoading(false);
        }
    };

    React.useEffect(() => {
        onLoad();
    }, []);

    const onFirmwareVersionChange = (value: string): void => {
        setSelectedVersion(value);
        setDeviceDataSource([]);
    };

    const onGenerateReport = async (): Promise<void> => {
        setIsGenerateReport(true);
        const result = await deviceStore.searchDevices(commonStore.selectedOrganisation, {
            firmwareVersion: selectedVersion,
        });
        setDeviceDataSource(result.map(toDeviceInfo));
        setIsGenerateReport(false);
    };

    return <ReportComponent
        code='firmware-versions-report'
        className={reportsCss.firmware_version}
        isLoading={isLoading}
        errorMessage={errorMessage}
        generateReportOptions={{
            isGenerating: isGenerateReport,
            onGenerateReport,
        }}
        items={[<SelectFormItem
            key='firmwareVersionSelectFormItem'
            code='firmware_version'
            label='Firmware version'
            labelAlign='right'
            selectedValue={selectedVersion}
            dataSource={versionDataSource}
            onChange={onFirmwareVersionChange}
        />]}
        reportTable={<DevicesTableComponent
            loadingState={isGenerateReport}
            dataSource={deviceDataSource}
            title=''
            defaultSortColumn={{
                key: DEFAULT_DEVICE_COLUMN.LAST_UPDATE,
                sortOrder: 'descend',
            }}
            buildDeviceLinkProps={() => ({ accountId: commonStore.selectedOrganisation })}
            exportOptions={{
                overrideExportItem: {
                    filename: `firmware-versions-report-${selectedVersion?.replace(/\./g, '-')}`,
                },
                hideExportItem: false,
            }}
        />}
    />;
});
