import * as React from 'react';
import { Statistic as AntdStatistic } from 'antd';

export interface IStatisticProps {
    title?: React.ReactNode;
    value?: string | number;
    className?: string;
    precision?: number;
    valueStyle?: React.CSSProperties; // CSS apply to value field only
    suffix?: React.ReactNode;
    loading?: boolean;
    groupSeparator?: string; // Group the number value with separator. Default "," when undefined
    formatter?: (value: string | number) => React.ReactNode;
    valueRender?: (node: React.ReactNode) => React.ReactNode;
}

export const Statistic = (props: IStatisticProps) => {
    return (
        <AntdStatistic {...props} />
    );
};
