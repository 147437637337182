import * as React from 'react';
import { AdvanceTable, IBasicTableColumn, IBasicTableProps, IEditableTableColumn, IconTextButton } from 'ui-lib';

import { IDeviceTableProps } from './commission-interface';
import { IProvisionedDeviceOptions } from '../../../../store/wizard-store';
import { DeviceUuidHyperlink } from 'pages/private/components/device-uuid-hyperlink-component';
import { COMMISSION_STATUS, commissionStatusTagComponent } from '../commissioning-upload';
import { SCR_TAMPER_STATUS } from 'dto/device-dto';
import { isNullOrEmpty } from 'common-utils';

const CSV_SEPARATOR = ',';
enum CSVExportColumns {
    UID = 'uid',
    ESN = 'esn',
    SerialNumber = 'serial',
    Status = 'status',
}
const onDownloadCSV = (filename: string, rows: IProvisionedDeviceOptions[], headers?: string[]): void => {
    if (!rows || !rows.length) return;

    const columHearders = !headers ? Object.keys(rows[0]) : headers;
    const csvContent =
        columHearders.join(CSV_SEPARATOR) +
        '\n' +
        rows
            .map((row) => {
                return columHearders
                    .map((k) => {
                        let cell = !row[k] ? '' : row[k];
                        cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');
                        if (cell.search(/("|,|\n)/g) >= 0) {
                            cell = `"${cell}"`;
                        }
                        return cell;
                    })
                    .join(CSV_SEPARATOR);
            })
            .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a'); // download element
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const SelectDevicesWrapper = (props: IDeviceTableProps) => {
    const { data } = props;
    const [title] = React.useState(`Provisioned Devices for ${props.data.name}`);

    const csvSource = React.useMemo(() => {
        if (!isNullOrEmpty(data.defaultSelectedRows)) {
            return data.defaultSelectedRows;
        }
        return data.dataSource;
    }, [data.dataSource, data.defaultSelectedRows]);

    const UIDsColumn: IEditableTableColumn = {
        code: 'uid',
        title: 'UID',
        dataIndex: 'uid',
        sorter: (a: IProvisionedDeviceOptions, b: IProvisionedDeviceOptions) => a?.uid?.localeCompare(b?.uid),
        textSearchPlaceholder: 'Search UID',
        render: (identifier: string, record: any) => (
            <DeviceUuidHyperlink
                uid={identifier}
                accountId={data.accountId}
            />
        ),
        onFilter: (uid: string, device: IProvisionedDeviceOptions) =>
            device.uid?.toLowerCase().includes(uid.toLowerCase()),
        iconName: 'search',
    };

    const eSNColumn: IBasicTableColumn = {
        code: 'esn',
        title: 'eSN',
        dataIndex: 'esn',
        sorter: (a: IProvisionedDeviceOptions, b: IProvisionedDeviceOptions) => a?.esn?.localeCompare(b?.esn),
        textSearchPlaceholder: 'Search eSN',
        onFilter: (esn: string, device: IProvisionedDeviceOptions) =>
            device.esn?.toLowerCase().includes(esn.toLowerCase()),
        iconName: 'search',
    };

    const serialNumberColumn: IBasicTableColumn = {
        code: 'serialNumber',
        title: 'Serial Number',
        dataIndex: 'serial',
        sorter: (a: IProvisionedDeviceOptions, b: IProvisionedDeviceOptions) => a?.serial?.localeCompare(b?.serial),
        textSearchPlaceholder: 'Search Serial Number',
        onFilter: (serialNumber: string, device: IProvisionedDeviceOptions) =>
            device.serial?.toLowerCase().includes(serialNumber.toLowerCase()),
        iconName: 'search',
    };


    const deviceTypeColumn: IBasicTableColumn = {
        code: 'deviceType',
        title: 'Device Type',
        dataIndex: 'deviceTypeName',
        sorter: (a: IProvisionedDeviceOptions, b: IProvisionedDeviceOptions) => a?.deviceTypeName?.localeCompare(b?.deviceTypeName),
        textSearchPlaceholder: 'Search Device Type',
        onFilter: (deviceType: string, device: IProvisionedDeviceOptions) =>
            device.deviceTypeName?.toLowerCase().includes(deviceType.toLowerCase()),
        iconName: 'search',
    };

    const commissionStatus: IBasicTableColumn = {
        code: 'status',
        title: 'Status',
        dataIndex: 'status',
        filters: [
            { text: COMMISSION_STATUS.COMMISSIONABLE, value: COMMISSION_STATUS.COMMISSIONABLE },
            { text: SCR_TAMPER_STATUS.SCR_TAMPER, value: SCR_TAMPER_STATUS.SCR_TAMPER },
            { text: SCR_TAMPER_STATUS.SCR_BATTERY_NOT_OK, value: SCR_TAMPER_STATUS.SCR_BATTERY_NOT_OK },
            { text: SCR_TAMPER_STATUS.INVALID_FIRMWARE, value: SCR_TAMPER_STATUS.INVALID_FIRMWARE },
        ],
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: commissionStatusTagComponent,
        onFilter: (status: COMMISSION_STATUS, device: IProvisionedDeviceOptions) => device.status.toLowerCase() == status.toLowerCase(),
    };

    const basicTable: IBasicTableProps = {
        columns: [UIDsColumn, eSNColumn, serialNumberColumn, deviceTypeColumn, commissionStatus],
        rowSelection: {
            defaultSelectedRowKeys: data.defaultSelectedRows?.map((item) => item.key),
            type: 'checkbox',
            getCheckboxProps: (record: IProvisionedDeviceOptions) => {
                return {
                    name: record['deviceName'],
                    disabled: record.status != COMMISSION_STATUS.COMMISSIONABLE
                };
            },
            onChange: (selectedRowKeys, selectedRows) => {
                props.onChange(selectedRows);
            },
        },
        dataSource: data.dataSource,
    };

    return (
        <React.Fragment>
            <AdvanceTable
                title={title}
                table={basicTable}
                loadingState={data.loading}
                toolbar={{
                    extraItems: [
                        <IconTextButton
                            key='downloadCSVButton'
                            label='Download CSV'
                            type='primary'
                            loading={data.loading}
                            onClick={() => onDownloadCSV(title, csvSource, Object.values(CSVExportColumns))}
                        />,
                    ],
                }}
            />
        </React.Fragment>
    );
};
