import { computed, makeObservable } from 'mobx';

import {
    ICreateMetaPackageRequestProps,
    ICreateMetaPackageResponseProps,
    ICreateSnapshotProps,
    IMetaPackageInReleaseProps,
    ISnapshotProps,
    IUpdateSnapshotProps,
    METAPACKAGE_TYPE,
    METAPACKAGE_TYPES,
    SNAPSHOT_STATUS
} from '../dto/software-snapshot-dto';
import { IDetectPackageDependenciesResult, IPackageProps } from '../dto/packages-dto';
import { AccountStore } from './account-store';
import { formatDate } from 'common-utils/date-utils';
import { SoftwareManagementStore } from './software-management-store';
import { SnapshotsApiClient } from '../api';
import { IAssureStoreConstructorOptions } from './assure-base.store';
import { PackagesStore, TDisplayPackageProps } from './packages-store';
import { isNullOrEmpty } from 'common-utils';
import { VersionComparison } from 'common-utils/debian-version-utils';
import { AccountType } from 'dto/access-management/account-dto';

export interface IDisplaySnapshotProps extends ISnapshotProps {
    accountName: string;
    createdAtTimestamp: string; // for sorting
    updatedAtTimestamp: string; // for sorting
}

export interface ISnapshotInformation {
    snapshot: ISnapshotProps;
    packages: IPackageProps[];
}

export const releaseComparator = (release1: IDisplaySnapshotProps, release2: IDisplaySnapshotProps): number => VersionComparison.compare(release2.version, release1.version);

export class SoftwareSnapshotStore extends SoftwareManagementStore<SnapshotsApiClient, ISnapshotProps> {
    public constructor(
        options: IAssureStoreConstructorOptions,
        private packageStore: PackagesStore,
        accountStore: AccountStore,
    ) {
        super(options, accountStore);
        makeObservable(this);
    }

    @computed
    public get displayPublishedSnapshots(): IDisplaySnapshotProps[] {
        if (isNullOrEmpty(this.displaySnapshots)) {
            return [];
        }
        return this.displaySnapshots.filter((item) => item.status === SNAPSHOT_STATUS.RELEASED).sort(releaseComparator);
    }

    @computed
    public get displayCreatedSnapshots(): IDisplaySnapshotProps[] {
        if (isNullOrEmpty(this.displaySnapshots)) {
            return [];
        }
        return this.displaySnapshots.filter((item) => item.status === SNAPSHOT_STATUS.CREATED);
    }

    @computed
    public get displayDPUSnapshots(): { [key: string]: IDisplaySnapshotProps[] } {
        if (isNullOrEmpty(this.displaySnapshots)) return {};
        return Object.fromEntries(METAPACKAGE_TYPES.map(type => [type, this.displayPublishedSnapshots.filter((item) => item.type === type).sort(releaseComparator)]));
    }

    protected get apiClient(): SnapshotsApiClient {
        return this.apiClientStore.apiClients.snapshots;
    }

    @computed
    private get displaySnapshots(): IDisplaySnapshotProps[] {
        if (!this.hasEntities()) {
            return [];
        }

        return this.entities.map((snapshot: ISnapshotProps) => ({
            ...snapshot,
            key: snapshot.id,
            value: snapshot.id,
            accountName: !snapshot.accountUuid ? '' : this.getAccountNameByUuid(snapshot.accountUuid),
            createdAt: formatDate(snapshot.createdAt),
            createdAtTimestamp: snapshot.createdAt,
            updatedAt: formatDate(snapshot.updatedAt),
            updatedAtTimestamp: snapshot.updatedAt,
        }));
    }

    public getSnapshot(snapshotId: number): ISnapshotProps {
        if (!snapshotId || !this.hasEntities()) {
            return undefined;
        }
        return this.getEntity(snapshotId);
    }

    public getPackagesBySnapshotId(snapshotId: number, includeDPUPackages?: boolean): TDisplayPackageProps[] {
        if (!snapshotId || isNullOrEmpty(this.packageStore.displayPackages)) {
            return [];
        }

        const packagesInSnapshot = this.getEntity(snapshotId);
        if (!packagesInSnapshot) {
            return [];
        }
        const allPackages = this.packageStore.displayPackages;
        const packages = [];
        if (!isNullOrEmpty(packagesInSnapshot.packageIds)) {
            packages.push(...packagesInSnapshot.packageIds.map(item => allPackages.find(pkg => pkg.id === item)).filter(pkg => !!pkg));
        }
        if (includeDPUPackages) {
            packages.push(...this.getPackagesBySnapshotId(packagesInSnapshot.dependencies.almReleaseId));
            packages.push(...this.getPackagesBySnapshotId(packagesInSnapshot.dependencies.vosReleaseId));
            packages.push(...this.getPackagesBySnapshotId(packagesInSnapshot.dependencies.scrReleaseId));
        }
        return packages;
    }

    public unmetDependencies(snapshotId: number): IDetectPackageDependenciesResult[] {
        const packages = this.getPackagesBySnapshotId(snapshotId, true);
        return this.packageStore.detectDependencyPackages(packages.map(item => item.id));
    }

    public async createMetapackage(accountId: number, props: ICreateMetaPackageRequestProps): Promise<ICreateMetaPackageResponseProps> {
        const result = await this.apiClient.createMetapackage(accountId, props);
        if (result.error) {
            return result;
        }
        this.packageStore.addNewPackages(...result.packages);
        this.addEntity(result.release);
        return result;
    }

    public async createSnapshot(accountId: number, props: ICreateSnapshotProps): Promise<ISnapshotProps> {
        const result = await this.apiClient.createSnapshot(accountId, props);
        this.addEntity(result);
        return result;
    }

    public async updateSnapshot(accountId: number, snapshotId: number, props: IUpdateSnapshotProps): Promise<boolean> {
        const release = await this.apiClient.updateSnapshot(accountId, snapshotId, props);
        this.updateEntity<Omit<ISnapshotProps, 'packageIds'>>(snapshotId, release);
        return true;
    }

    public async publishSnapshot(
        accountId: number,
        snapshotId: number,
    ): Promise<boolean> {
        await this.apiClient.publishSnapshot(accountId, snapshotId);
        const publishedSnapshot = await this.apiClient.getSnapshot(accountId, snapshotId);
        this.updateEntity<ISnapshotProps>(snapshotId, publishedSnapshot);
        return true;
    }

    public async deleteSnapshot(accountId: number, id: number): Promise<void> {
        await this.apiClient.deleteSnapshot(accountId, id);
        this.removeEntity(id);
    }

    public getLatestDPUSnapshotByType(type: string): IDisplaySnapshotProps {
        const dpuSnapshots = this.displayDPUSnapshots[type];
        if (isNullOrEmpty(dpuSnapshots)) {
            return undefined;
        }
        return dpuSnapshots[0];
    }

    public getLatestSnapshotByAccount(accountUuid: string): ISnapshotProps {
        const publishedSnapshots = this.getSnapshotsByAccountUuid(accountUuid);
        const findLatestWithAccountUuid = (accountUuid: string): ISnapshotProps => {
            return publishedSnapshots.reduce((previous, current) => {
                if (current.accountUuid != accountUuid) return previous;
                if (!previous) return current;
                return VersionComparison.gt(previous.version, current.version) ? previous : current;
            }, undefined as ISnapshotProps);
        };

        return findLatestWithAccountUuid(accountUuid);
    }

    public getSnapshotsByAccountUuid(accountUuid: string): IDisplaySnapshotProps[] {
        if (isNullOrEmpty(this.displayPublishedSnapshots)) {
            return [];
        }
        const account = this.accountStore.getAccountByUuidOrParentUuidForClientAccount(accountUuid);
        if (!account) {
            return [];
        }
        switch (account.accountType) {
            // Include the published releases of VAR account
            case AccountType.CLIENT:
                return this.displayPublishedSnapshots.filter(snapshot => (snapshot.accountUuid === account.uuid) || (snapshot.accountUuid === account.parent.uuid));
            case AccountType.VAR:
                return this.displayPublishedSnapshots.filter(snapshot => (snapshot.accountUuid === account.uuid));
            case AccountType.DPU:
                return this.displayPublishedSnapshots;
            default:
                throw new Error(`Unhandled Account Type: ${account.accountType}`);
        }
    }

    public getMetaPackagesInRelease(releaseId: number): IMetaPackageInReleaseProps {
        const release = this.getSnapshot(releaseId);
        if (!release?.dependencies) {
            return { ALM: undefined, VOS: undefined, SCR: undefined };
        }
        const metapackages = Object.values(release.dependencies).reduce((object, metapackageId) => {
            const metapackage = this.getSnapshot(metapackageId);
            if (metapackage && METAPACKAGE_TYPE[metapackage.type]) {
                object.set(METAPACKAGE_TYPE[metapackage.type], metapackage);
            }
            return object;
        }, new Map<METAPACKAGE_TYPE, ISnapshotProps>());

        return {
            ALM: metapackages.get(METAPACKAGE_TYPE.ALM),
            VOS: metapackages.get(METAPACKAGE_TYPE.VOS),
            SCR: metapackages.get(METAPACKAGE_TYPE.SCR)
        };
    }

    protected async getDataByAccountId(accountId: number): Promise<ISnapshotProps[]> {
        return this.apiClient.getAllSnapshots(accountId);
    }
}
