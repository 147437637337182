import * as React from 'react';
import { observer } from 'mobx-react';
import { CheckUserExistingForm } from './check-user-existing-form';
import {
    BasicForm,
    IFormItemValidation,
    IInformationDialogProps,
    Icon,
    IconTextButton,
    Page,
    PasswordFieldItem,
    TextFieldItem,
    Typography,
    showInformationDialog,
} from 'ui-lib';

import forgotPasswordCss from '../login.css';
import { useNavigate } from 'react-router';
import { CountdownStatistic } from 'ui-lib/components/data-display/countdown';
import { useAuthStore } from 'store';
import { getErrorMessage } from 'common-utils';
import { passwordValidator } from 'utils/common-utils';

export enum FORGOT_PASSWORD_STATE {
    GET_VERIFICATION_CODE,
    CONFIRM_VERIFY_CODE,
}

const RESEND_TIME_MILISECONDS = 60000; // 1 minutes

export const ForgotPasswordPageWrapper = observer(() => {
    const [displayState, setDisplayState] = React.useState<FORGOT_PASSWORD_STATE>(
        FORGOT_PASSWORD_STATE.GET_VERIFICATION_CODE,
    );
    const [email, setEmail] = React.useState<string>();

    return (
        <Page className={forgotPasswordCss.forgotPasswordPage}>
            {displayState === FORGOT_PASSWORD_STATE.GET_VERIFICATION_CODE && (
                <CheckUserExistingForm
                    onUpdate={(state: FORGOT_PASSWORD_STATE): void => setDisplayState(state)}
                    setEmail={setEmail}
                />
            )}
            {displayState === FORGOT_PASSWORD_STATE.CONFIRM_VERIFY_CODE && (
                <ForgotPasswordForm setDisplayState={setDisplayState} email={email} />
            )}
        </Page>
    );
});

const ForgotPasswordForm = (props: { email: string; setDisplayState: (value) => void }): JSX.Element => {
    const authStore = useAuthStore();
    const navigate = useNavigate();

    const [countdownValue, setCountdownValue] = React.useState<number>(Date.now() + RESEND_TIME_MILISECONDS);
    const [showResendButton, setShowResendButton] = React.useState<boolean>(false);
    const [confirmNewPasswordValidation, setConfirmNewPasswordValidation] = React.useState<IFormItemValidation>();

    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

    const onSubmit = async (values): Promise<void> => {
        setConfirmNewPasswordValidation(null);
        if (values.newPassword != values.confirmNewPassword) {
            setConfirmNewPasswordValidation({
                status: 'error',
                message: 'Passwords do not match!',
            });
            return;
        }
        try {
            setIsProcessing(true);
            const result = await authStore.confirmForgotPassword({
                username: props.email,
                password: values.newPassword,
                confirmationCode: values.passwordResetCode,
            });

            const modalOptions: Omit<IInformationDialogProps, 'title'> = result
                ? {
                      modalType: 'success',
                      content: 'Change password successfully. Please login with your new password',
                  }
                : {
                      modalType: 'error',
                      content: 'Failed to change password. Please contact technical support.',
                  };

            showInformationDialog({
                ...modalOptions,
                title: 'Information',
                afterClose: () => navigate('/'),
            });
        } catch (error) {
            showInformationDialog({
                modalType: 'error',
                title: 'Error',
                content: getErrorMessage(error),
            });
        } finally {
            setIsProcessing(false);
        }
    };

    const onResend = async (): Promise<void> => {
        await authStore.forgotPassword(props.email);
        setCountdownValue(Date.now() + RESEND_TIME_MILISECONDS);
        setShowResendButton(false);
    };

    const ResendCodeRow = (props: { showResendButton: boolean }): JSX.Element => {
        const ButtonSide = props.showResendButton ? (
            <IconTextButton
                className={forgotPasswordCss.resendLink}
                label='Resend code'
                type='link'
                onClick={onResend}
            />
        ) : (
            <CountdownStatistic
                format='ss'
                value={countdownValue}
                onFinish={(): void => {
                    setShowResendButton(true);
                }}
                suffix={'seconds'}
                prefix={'Resend code in'}
            />
        );

        return (
            <div className={forgotPasswordCss.resendCodeRow}>
                <Icon className={forgotPasswordCss.questionIcon} iconName='question-circle' size='SMALL' />
                <Typography.Text>Did not receive code?</Typography.Text>
                <div className={forgotPasswordCss.buttonSide}>{ButtonSide}</div>
            </div>
        );
    };

    return (
        <BasicForm
            className={forgotPasswordCss.resetPasswordForm}
            onSubmit={onSubmit}
            items={[
                <div key='text'>
                    Password reset code has been sent to <Typography.Text strong>{props.email}</Typography.Text>
                    <p>Please check your inbox and use that code to reset your password.</p>
                </div>,
                <TextFieldItem key='passwordResetCode' code='passwordResetCode' label='Reset code' isRequired={true} />,
                <PasswordFieldItem
                    key='newPassword'
                    code='newPassword'
                    label='New password'
                    isRequired={true}
                    validator={passwordValidator}
                />,
                <PasswordFieldItem
                    key='confirmNewPassword'
                    code='confirmNewPassword'
                    label='Confirm password'
                    isRequired={true}
                    onChange={(): void => setConfirmNewPasswordValidation(null)}
                    customizeValidate={confirmNewPasswordValidation}
                />,
                <ResendCodeRow key='resendCodeRow' showResendButton={showResendButton} />,
                <IconTextButton
                    key='changePasswordButton'
                    className={forgotPasswordCss.button}
                    htmlType='submit'
                    type='primary'
                    label='Change password'
                    loading={isProcessing}
                />,
            ]}
        />
    );
};
