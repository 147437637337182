import * as React from 'react';
import { configure } from 'mobx';

configure({
    enforceActions: 'observed',
});

import { AuthStore, RouteStore } from 'core';
import { AssureAdminApiClientStore } from './assure-admin-api-client.store';
import { PRIVATE_ROUTES } from './private-routes';
import { PUBLIC_ROUTES } from './public-routes';
import { AccountStore } from './account-store';
import { DeviceGroupsStore } from './device-groups.store';
import { CommonStore } from './assure-admin-common.store';
import { UserStore } from './user-store';
import { RoleStore } from './role-store';
import { PermissionStore } from './permission-store';
import { PackagesStore } from './packages-store';
import { PackageGroupsStore } from './package-groups-store';
import { DevicesStore } from './devices-store';
import { NotificationsStore } from './notifications-store';
import { SocketClientStore } from './socket/socket-client-store';
import { DeploymentGroupsStore } from './deployment-groups.store';
import { SoftwareSnapshotStore } from './snapshot.store';
import { DeploymentStore } from './deployment-store';
import { KeyStore } from './key/key-store';
import { ConfigurationStore } from './configuration-store';

export type TStoresContext = {
    commonStore: CommonStore;
    apiClientStore: AssureAdminApiClientStore;
    authStore: AuthStore;
    accountStore: AccountStore;
    userStore: UserStore;
    roleStore: RoleStore;
    permissionStore: PermissionStore;
    deviceGroupsStore: DeviceGroupsStore;
    routeStore: RouteStore;
    packagesStore: PackagesStore;
    packageGroupsStore: PackageGroupsStore;
    deviceStore: DevicesStore;
    notificationsStore: NotificationsStore;
    socketClientStore: SocketClientStore;
    deploymentGroupsStore: DeploymentGroupsStore;
    snapshotStore: SoftwareSnapshotStore;
    deploymentStore: DeploymentStore;
    keyStore: KeyStore;
    configurationStore: ConfigurationStore;
};

export const storesContext = React.createContext<TStoresContext>(null);

export const createStores = (): TStoresContext => {
    const apiClientStore = new AssureAdminApiClientStore();
    const routeStore = new RouteStore();

    const authStore = new AuthStore({
        apiClientStore,
        routeStore,
        publicRoutes: PUBLIC_ROUTES,
        privateRoutes: PRIVATE_ROUTES,
    });

    const accountStore = new AccountStore({ apiClientStore, authStore });
    const commonStore = new CommonStore(authStore, accountStore);
    const userStore = new UserStore({ apiClientStore, authStore });
    const roleStore = new RoleStore({ apiClientStore, authStore });
    const permissionStore = new PermissionStore({ apiClientStore, authStore });
    const deviceGroupsStore = new DeviceGroupsStore({ apiClientStore, authStore }, accountStore, authStore);
    const packagesStore = new PackagesStore({ apiClientStore, authStore }, accountStore);
    const packageGroupsStore = new PackageGroupsStore({ apiClientStore, authStore }, accountStore);
    const snapshotStore = new SoftwareSnapshotStore({ apiClientStore, authStore }, packagesStore, accountStore);
    const deviceStore = new DevicesStore(apiClientStore);
    const notificationsStore = new NotificationsStore({apiClientStore, authStore});
    const socketClientStore = new SocketClientStore();
    const deploymentGroupsStore = new DeploymentGroupsStore({ apiClientStore, authStore }, deviceGroupsStore, accountStore);
    const deploymentStore = new DeploymentStore({ apiClientStore, authStore });
    const keyStore = new KeyStore({ apiClientStore, authStore });
    const configurationStore = new ConfigurationStore({ apiClientStore, authStore, packagesStore });
    return {
        commonStore,
        authStore,
        routeStore,
        apiClientStore,
        accountStore,
        userStore,
        roleStore,
        permissionStore,
        deviceGroupsStore,
        packagesStore,
        packageGroupsStore,
        snapshotStore,
        deviceStore,
        notificationsStore,
        socketClientStore,
        deploymentGroupsStore,
        deploymentStore,
        keyStore,
        configurationStore,
    };
};

export const useStores = (): TStoresContext => {
    return React.useContext<TStoresContext>(storesContext);
};

export const useApiClientStore = (): AssureAdminApiClientStore => useStores().apiClientStore;
export const useAuthStore = (): AuthStore => useStores().authStore;
export const useAccountStore = (): AccountStore => useStores().accountStore;
export const useUserStore = (): UserStore => useStores().userStore;
export const useRoleStore = (): RoleStore => useStores().roleStore;
export const useDeviceGroupsStore = (): DeviceGroupsStore => useStores().deviceGroupsStore;
export const useRoutesStore = (): RouteStore => useStores().routeStore;
export const usePackagesStore = (): PackagesStore => useStores().packagesStore;
export const usePackageGroupsStore = (): PackageGroupsStore => useStores().packageGroupsStore;
export const useSnapshotStore = (): SoftwareSnapshotStore => useStores().snapshotStore;
export const useDeviceStore = (): DevicesStore => useStores().deviceStore;
export const useNotificationsStore = (): NotificationsStore => useStores().notificationsStore;
export const useSocketClientStore = (): SocketClientStore => useStores().socketClientStore;
export const useDeploymentGroupsStore = (): DeploymentGroupsStore => useStores().deploymentGroupsStore;
export const useDeploymentStore = (): DeploymentStore => useStores().deploymentStore;
export const useKeyStore = (): KeyStore => useStores().keyStore;
export const getStores = () => storesContext;
export const initializeData = async (stores: Partial<TStoresContext>) => {
    await Promise.all([stores.accountStore.getAccounts(), stores.permissionStore.loadPermissions()]);
};
