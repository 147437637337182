import * as React from 'react';
import {BaseError, IBaseErrorProps} from './base-error';

export type I500Props = Omit<IBaseErrorProps, 'iconName' | 'title'>

export const Error500 = (props: I500Props) => (
    <BaseError
        iconName="bug"
        title="500 - An unexpected error has occurred"
        message={props.message}
    />
);