import * as React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { findIndex, unionBy } from 'lodash';

import { BasicCard, Icon, IconName, RowLayout, showInformationDialog, Skeleton } from 'ui-lib';
import { getErrorMessage } from 'common-utils';
import { useAccountStore, useDeploymentStore, useSnapshotStore } from 'store';
import { IPackageProps } from 'dto/packages-dto';

import deploymentCss from '../deployments.css';
import { getReleaseOfDeployment } from 'common-utils/deployment-utils';
import { IDeploymentGroup } from 'dto/deployment/deployment-group-dto';
import { ISnapshotProps } from 'dto/software-snapshot-dto';

const _packageCompare = (a: IPackageProps, b: IPackageProps): number => a.label.localeCompare(b.label);

enum COMPARE_RELEASE_STATUS {
    ADD = 'add',
    REMOVE = 'remove',
    CHANGE = 'change',
    NO_CHANGE = 'noChange',
    NONE = 'none',
}

interface IComparePackageItem {
    index: number;
    status: COMPARE_RELEASE_STATUS;
    icon?: IconName;
    package?: IPackageProps;
}

interface ICompareRelease {
    label?: string;
    comparePackageItems: IComparePackageItem[];
}

interface IPreviousRelease {
    label?: string;
    packages: IPackageProps[];
}

export interface ICompareReleaseStepOptions {
    targetCompareRelease: ICompareRelease;
    currentCompareRelease: ICompareRelease;
}

export interface IInitDeploymentStepOptions {
    selectedDeploymentGroupId: number;
    releaseId: number;
    label?: string;
    accountId?: number;
}

interface ICompareReleaseStepProps {
    selectedDeploymentGroup: IDeploymentGroup;
    releaseId: number;
    addRemovalPackages: (removalPackages: string[]) => void;
}

const ICON_NAMES: { [key: string]: IconName; } = {
    [COMPARE_RELEASE_STATUS.ADD]: 'plus',
    [COMPARE_RELEASE_STATUS.REMOVE]: 'minus',
};

export const CompareReleasesStep = observer((props: ICompareReleaseStepProps) => {
    const { releaseId, selectedDeploymentGroup } = props;
    const deploymentStore = useDeploymentStore();
    const snapshotStore = useSnapshotStore();
    const accountStore = useAccountStore();

    const selectedRelease = React.useMemo<ISnapshotProps>(() => {
        return snapshotStore.getSnapshot(releaseId);
    }, [releaseId]);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [compareReleaseStepOptions, setCompareReleaseStepOptions] = React.useState<ICompareReleaseStepOptions>({
        targetCompareRelease: null,
        currentCompareRelease: null,
    });

    const _getReleaseInLatestInProgressDeployment = async (accountId: number, groupId: number): Promise<IPreviousRelease> => {
        const inProgessDeployment = await deploymentStore.findLatestInProgressDeploymentInGroup(accountId, groupId, true);
        if (!inProgessDeployment) {
            return {
                label: 'NO RELEASE',
                packages: [],
            };
        }

        const release = getReleaseOfDeployment(inProgessDeployment);
        if (!release) {
            return {
                label: 'NO RELEASE',
                packages: [],
            };
        }
        return {
            label: release.label,
            packages: snapshotStore.getPackagesBySnapshotId(release.id, true).sort(_packageCompare),
        };
    };

    const _buildComparePackages = (
        packageInRelease: IPackageProps[],
        allPackages: IPackageProps[],
        packageInCompareRelease: IPackageProps[],
        addOrRemoveStatus: COMPARE_RELEASE_STATUS,
    ): IComparePackageItem[] => {
        const comparePackageItems: IComparePackageItem[] = [];
        for (const item of packageInRelease) {
            const index = findIndex(allPackages, (pkg) => pkg.label === item.label);
            for (let i = comparePackageItems.length; i < index; i++) {
                comparePackageItems.push({
                    index: i,
                    status: COMPARE_RELEASE_STATUS.NONE,
                });
            }

            const existingPackage = packageInCompareRelease.find((pkg) => pkg.label === item.label);
            const status = existingPackage
                ? (existingPackage.version != item.version
                    ? COMPARE_RELEASE_STATUS.CHANGE
                    : COMPARE_RELEASE_STATUS.NO_CHANGE)
                : addOrRemoveStatus;

            comparePackageItems.push({
                index,
                status,
                icon: ICON_NAMES[status === COMPARE_RELEASE_STATUS.CHANGE ? addOrRemoveStatus : status],
                package: item,
            });
        }

        for (let i = comparePackageItems.length; i < allPackages.length; i++) {
            comparePackageItems.push({
                index: i,
                status: COMPARE_RELEASE_STATUS.NONE,
            });
        }

        return comparePackageItems;
    };

    React.useEffect(() => {
        const accountId = accountStore.getAccountByUuidOrParentUuidForClientAccount(selectedDeploymentGroup.accountUuid)?.id;
        if (!selectedDeploymentGroup || !releaseId || !accountId) {
            return;
        }
        (async (): Promise<void> => {
            try {
                setLoading(true);
                const packageInSelectedRelease = unionBy(snapshotStore.getPackagesBySnapshotId(selectedRelease.id, true), 'label').sort(_packageCompare);
                const previousRelease = await _getReleaseInLatestInProgressDeployment(accountId, selectedDeploymentGroup.id);
                const allPackages = unionBy(packageInSelectedRelease, previousRelease.packages, 'label').sort(_packageCompare);
                const [targetComparePackages, currentComparePackages] = await Promise.all([
                    Promise.resolve(_buildComparePackages(
                        packageInSelectedRelease,
                        allPackages,
                        previousRelease.packages,
                        COMPARE_RELEASE_STATUS.ADD,
                    )),
                    Promise.resolve(_buildComparePackages(
                        previousRelease.packages,
                        allPackages,
                        packageInSelectedRelease,
                        COMPARE_RELEASE_STATUS.REMOVE,
                    )),
                ]);
                setCompareReleaseStepOptions({
                    targetCompareRelease: {
                        label: selectedRelease.label,
                        comparePackageItems: targetComparePackages,
                    },
                    currentCompareRelease: {
                        label: previousRelease.label,
                        comparePackageItems: currentComparePackages,
                    },
                });
                props.addRemovalPackages(currentComparePackages.filter(item => item.status === COMPARE_RELEASE_STATUS.REMOVE).map(item => item.package?.label));
            } catch (err) {
                showInformationDialog({
                    title: 'Error',
                    content: getErrorMessage(err),
                    modalType: 'error'
                });
            } finally {
                setLoading(false);
            }
        })();
    }, [selectedDeploymentGroup, selectedRelease]);

    const _buildItemColComponent = (key: string, item: IComparePackageItem): JSX.Element => {
        const className = cx(deploymentCss.item, deploymentCss[item.status]);

        return (
            <div key={key}>
                {item.status === COMPARE_RELEASE_STATUS.NONE
                    ? (
                        <div key={item.index} className={className}>
                            &nbsp;
                        </div>
                    )
                    : (
                        <div key={item.index} className={className}>
                            <Icon key={item.index} className={deploymentCss.icon} iconName={item.icon} size="SMALL"/>
                            <div>{`${item.package.label}-${item.package.version}`}</div>
                        </div>
                    )}
            </div>
        );
    };

    const childCols = [11, 1, 11];
    const blankComponent = (className: string): JSX.Element => (
        <span className={className}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>);

    return (
        <BasicCard
            title="Compare releases"
            contents={
                <Skeleton loading={loading}>
                    <div key="title" className={deploymentCss.compareSettings}>
                        <RowLayout
                            className={deploymentCss.title}
                            childItems={[
                                <h3 key="title-target">{`Target state: ${compareReleaseStepOptions.targetCompareRelease?.label}`}</h3>,
                                <div key="none"></div>,
                                <h3 key="title-current">{`Current state: ${compareReleaseStepOptions.currentCompareRelease?.label}`}</h3>,
                            ]}
                            childCols={childCols}
                        />
                    </div>
                    <div key="compare-data" className={deploymentCss.compareSettings}>
                        <div className={deploymentCss.compareContent}>
                            {compareReleaseStepOptions.targetCompareRelease?.comparePackageItems.map((targetItem, index) => {
                                const currentItem = compareReleaseStepOptions.currentCompareRelease?.comparePackageItems[index];
                                return (
                                    <div key={index}>
                                        <RowLayout
                                            className={deploymentCss.row}
                                            key={index}
                                            childItems={[
                                                _buildItemColComponent(`target-${index}`, targetItem),
                                                <div key={`number-${index}`}
                                                     className={deploymentCss.number}>{index + 1}</div>,
                                                _buildItemColComponent(`current-${index}`, currentItem),
                                            ]}
                                            childCols={childCols}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Skeleton>
            }
            footers={
                <div key="footer" className={deploymentCss.compareSettings}>
                    <h3>Description</h3>
                    <div key="add" className={deploymentCss.footer}>
                        {blankComponent(cx(deploymentCss.text, deploymentCss.addIcon))}
                        <div>Added package</div>
                    </div>
                    <div key="remove" className={deploymentCss.footer}>
                        {blankComponent(cx(deploymentCss.text, deploymentCss.removeIcon))}
                        <div>Removed package</div>
                    </div>
                    <div key="change" className={deploymentCss.footer}>
                        {blankComponent(cx(deploymentCss.text, deploymentCss.changeIcon))}
                        <div>Updated package</div>
                    </div>
                </div>
            }
        />
    );
});
