import { IDeviceInfo } from 'dto/device-dto';
import { IDeviceGroupOptions } from 'dto/device-group-dto';
import { DeploymentStatus } from './deployment-dto';

interface IDeviceGroup extends IDeviceGroupOptions {
    devices?: Record<string, IDeviceDeploymentInformation>;
}

export interface IDeploymentDetailsResponse {
    id?: number;
    label?: string;
    status?: DeploymentStatus;
    deploymentGroupId?: number;
    sequenceNumber?: number;
    autoComplete?: boolean;
    createdAt?: string;
    unit?: {
        snapshotId?: number;
        snapshotName?: string;
    };
    targets?: {
        scheduleDateTime?: string;
        dpuOverride?: boolean;
        downloadOnly?: boolean;
        installDateTime?: string;
        executionId?: string;
        deviceGroups?: IDeviceGroup[];
    }[];
}

interface IDeviceDeploymentInformation {
    lastJobSuccessAt?: string;
    trampolineVersion?: string;
    firmwareVersion?: string;
    executionId?: string;
    executionNumber?: number;
    executionStatus?: string;
    isRerunExecution?: boolean;
    executionCreatedAt?: string;
}

export interface IDeviceDeploymentStatusRecord extends IDeviceDeploymentInformation, IDeviceInfo {
    snapshotLabel?: string;
    lastUpdatedDateTime?: Date;
}

export class DeploymentDetails {
    constructor(private readonly data: IDeploymentDetailsResponse) {

    }

    public get id(): number {
        return this.data?.id;
    }

    public get name(): string {
        return this.data?.label;
    }

    public get snapshotLabel(): string {
        return this.data?.unit?.snapshotName;
    }

    public get deviceGroups(): IDeviceGroup[] {
        return this.data?.targets?.flatMap((target) => target.deviceGroups) ?? [];
    }

    public get devices(): IDeviceDeploymentStatusRecord[] {
        return this.deviceGroups?.flatMap((group) => {
            return Object.keys(group?.devices ?? {}).map(deviceUid => {
                const lastJobSuccessAt = group.devices[deviceUid]?.lastJobSuccessAt;
                const lastUpdatedDateTime = lastJobSuccessAt ? new Date(lastJobSuccessAt) : undefined;
                return {
                    UID: deviceUid,
                    ...(group.devices[deviceUid] ?? {}),
                    lastUpdated: lastUpdatedDateTime?.getTime(),
                    lastUpdatedDateTime: lastUpdatedDateTime,
                    deviceGroupLabel: group.deviceGroupLabel,
                    snapshotLabel: this.snapshotLabel,
                };
            });
        });
    }
}