import * as React from 'react';
import { observer } from 'mobx-react';
import { cloneDeep, isEqual } from 'lodash';
import { useStores } from 'store';
import {
    AdvanceTable,
    BasicCollapse,
    CheckboxField,
    IBasicTableColumn,
    IconTextButton,
    notification,
    showInformationDialog,
    Skeleton,
} from 'ui-lib';
import { confirmDialog } from 'pages/private/utils';
import { AccountType } from 'dto/access-management/account-dto';
import { IRole, IUpdateRolePermissionsParams } from 'dto/access-management/user-role-dto';

import rolesPermissionCss from './roles-permissions.css';
import { getErrorMessage, isNullOrEmpty } from 'common-utils';

enum DISPLAY_PERMISSION_KEYS {
    // Admin
    'Accounts' = 'Accounts',
    'Add Notifications' = 'Add notifications',
    'Configuration Signing' = 'Configuration signing',
    'Create meta package' = 'Create meta package',
    'Maintenance Updates' = 'Maintenance updates',
    'Reports' = 'Reports',
    'Settings' = 'Settings',
    'User Role Permissions' = 'User role permissions',
    'Users' = 'Users',

    // Devices
    'Commissioning Upload' = 'Commissioning upload',
    'Commissioning Wizard' = 'Commissioning',
    'Decommissioning' = 'Decommissioning',
    'Device Details' = 'Device details',
    'Device Groups' = 'Device groups',
    'Device Inventory' = 'Inventory',
    'File Transfer' = 'File transfer',
    'Reset Device' = 'Device reset',
    'Set Time Zone' = 'Set time zone',
    'Shell Command' = 'Shell command',

    // Key
    'Keys Distribution' = 'Distribution',
    'Keys Inventory' = 'Inventory',
    'Verify Keys' = 'Verify keys',

    // Notifications Hub
    'Notifications Hub' = 'Notifications hub',

    // Software
    'Deployment Groups Modify' = 'Deployment groups (create, edit, delete)',
    'Deployment Groups View' = 'Deployment groups (view)',
    'Deployments Modify' = 'Deployments (create, edit, delete)',
    'Deployments View' = 'Deployments (view)',
    'Repository' = 'Repository',
    'Snapshots Modify' = 'Releases (create, edit, delete, publish)',
    'Snapshots View' = 'Releases (view)',
}

const convertRoleTypesToKey = (role: IRole): string => `${role.accountType}_${role.roleType}`;
const convertKeyToRoleTypes = (key: string): { accountType: string; roleType: string } => {
    const splitted = key.split('_');
    return {
        accountType: splitted[0],
        roleType: splitted[1],
    };
};
const roleColumnsCompare = (a: IBasicTableColumn, b: IBasicTableColumn): number => {
    const { accountType: aAccountType, roleType: aRoleType } = convertKeyToRoleTypes(a.code);
    const { accountType: bAccountType, roleType: bRoleType } = convertKeyToRoleTypes(b.code);

    const roleTypeCompareResult = aRoleType.localeCompare(bRoleType);
    if (roleTypeCompareResult != 0) return roleTypeCompareResult;

    if (
        (aAccountType === AccountType.DPU && bAccountType !== AccountType.DPU) ||
        (aAccountType === AccountType.VAR && bAccountType === AccountType.CLIENT)
    )
        return -1;

    if (aAccountType === bAccountType) return 0;

    return 1;
};

export const RolesAndPermissionsMatrixPage = observer(() => {
    const { roleStore, permissionStore } = useStores();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [displayDataSource, setDisplayDataSource] = React.useState([]);
    const [initSource, setInitSource] = React.useState([]);
    const changingSource = React.useMemo(() => new Map<number, number[]>(), []);

    const [permissionGroupKeys, setPermissionGroupKeys] = React.useState<string[]>([]);
    const [activeKeys, setActiveKeys] = React.useState<string[]>([]);

    const fetchData = (): void => {
        (async (): Promise<void> => {
            try {
                setLoading(true);
                await roleStore.loadRoles();
                const allRoles = roleStore.allRoles;
                const allPermissions = await permissionStore.getAllPermissions();
                const uniquePermissionGroupKeys = [];
                const dataSource = allPermissions.map((permission) => {
                    if (!uniquePermissionGroupKeys.includes(permission.group)) {
                        uniquePermissionGroupKeys.push(permission.group);
                    }
                    const permissionAndRolesMatrix = {};
                    allRoles.forEach((role) => {
                        permissionAndRolesMatrix[convertRoleTypesToKey(role)] = role.permissions?.some(
                            (rolePermission) => rolePermission.id === permission.id,
                        );
                    });

                    return {
                        permissionId: permission.id,
                        permissionCode: DISPLAY_PERMISSION_KEYS[permission.code] || permission.code,
                        permissionGroup: permission.group,
                        ...permissionAndRolesMatrix,
                    };
                });

                dataSource.sort((a, b) =>
                    a.permissionGroup.toLocaleLowerCase().localeCompare(b.permissionGroup.toLocaleLowerCase()),
                );
                setPermissionGroupKeys(uniquePermissionGroupKeys);
                setDisplayDataSource(
                    cloneDeep(dataSource).sort((a, b) => a.permissionCode?.localeCompare(b.permissionCode)), // cloneDeep to prevent reference between sources
                );
                setInitSource(cloneDeep(dataSource).sort((a, b) => a.permissionCode?.localeCompare(b.permissionCode)));
            } catch (error) {
                notification.error({
                    message: 'Error',
                    description: getErrorMessage(error),
                });
            } finally {
                setLoading(false);
            }
        })();
    };

    React.useEffect(fetchData, []);

    const buildRolesAndPermissionsColumns = (roles: IRole[]): IBasicTableColumn[] => {
        const columns: IBasicTableColumn[] = [
            {
                code: 'permissionRowHead',
                title: 'Permission',
                dataIndex: 'permissionCode',
                rowScope: 'permissionCode',
            },
        ];

        const roleColumns = roles.map((role) => {
            const roleKey = convertRoleTypesToKey(role);
            return {
                code: roleKey,
                title: role.name,
                dataIndex: roleKey,
                render: (value, record) => (
                    <CheckboxField
                        label=""
                        checked={value}
                        onChange={(event): void => onCheckBoxChange(role, roleKey, event, record)}
                    />
                ),
            };
        });

        roleColumns.sort(roleColumnsCompare);

        columns.push(...roleColumns);
        return columns;
    };

    const onCheckBoxChange = (role: IRole, roleKey: string, event, record): void => {
        const checked = event.target.checked;

        const currentSource = cloneDeep(displayDataSource);
        const targetRow = currentSource.find((item) => item.permissionId === record.permissionId);
        targetRow[roleKey] = checked;

        const index = currentSource.indexOf(targetRow);
        if (index !== -1) {
            currentSource[index] = targetRow;
        }

        setDisplayDataSource(currentSource);

        // prepare data to update
        const targetRoleId = role.id;
        const targetPermissionId = record.permissionId as number;
        const initRolePermissionsIds = role.permissions.map((permission) => permission.id);
        const currentRolePermissionIds = changingSource.has(targetRoleId)
            ? changingSource.get(targetRoleId)
            : initRolePermissionsIds;
        if (checked) {
            currentRolePermissionIds.push(targetPermissionId);
        } else {
            const removeIndex = currentRolePermissionIds.indexOf(targetPermissionId);
            if (index === -1) return;

            currentRolePermissionIds.splice(removeIndex, 1);
            if (isEqual(currentRolePermissionIds, initRolePermissionsIds)) {
                changingSource.delete(targetRoleId);
            }
        }
        changingSource.set(targetRoleId, currentRolePermissionIds);
    };

    const buildRolesAndPermissionsPannels = () => {
        return permissionGroupKeys.sort().map((uniqueGroupKey) => {
            return {
                header: uniqueGroupKey,
                key: uniqueGroupKey,
                content: (
                    <AdvanceTable
                        title={''}
                        table={{
                            columns: buildRolesAndPermissionsColumns(roleStore.allRoles),
                            dataSource: displayDataSource.filter(
                                (permission) => permission.permissionGroup == uniqueGroupKey,
                            ),
                            rowKey: 'permissionId',
                            pagination: false,
                        }}
                    />
                ),
            };
        });
    };

    const onSave = async (): Promise<void> => {
        try {
            const updatedRoleParams: IUpdateRolePermissionsParams[] = [];
            changingSource.forEach((value, key) => updatedRoleParams.push({ roleId: key, permissionIds: value }));
            if (isNullOrEmpty(updatedRoleParams)) return;
            await roleStore.grantPermissionsToRole(updatedRoleParams);
            await permissionStore.loadPermissions();
        } catch (err) {
            showInformationDialog({
                title: 'Save error',
                content: err.message,
                modalType: 'error',
            });
        }
    };

    return (
        <Skeleton loading={loading || permissionStore.dataLoading}>
            <BasicCollapse
                className={rolesPermissionCss.permissionGroupsCollapse}
                panels={buildRolesAndPermissionsPannels()}
                defaultActiveKey={activeKeys}
                onChange={(key: string | string[]): void =>
                    Array.isArray(key) ? setActiveKeys([...key]) : setActiveKeys([key])
                }
            />
            <div className={rolesPermissionCss.buttonsContainer}>
                <IconTextButton
                    label="Cancel"
                    onClick={(): void =>
                        confirmDialog('Do you want to clear your changes?', () => {
                            changingSource.clear();
                            setDisplayDataSource(cloneDeep(initSource));
                        })
                    }
                />
                <IconTextButton
                    label="Save"
                    type="primary"
                    onClick={(): void => {
                        confirmDialog('Do you want to save your changes?', onSave);
                    }}
                />
            </div>
        </Skeleton>
    );
});
