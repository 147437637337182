import { action, computed, makeObservable, observable } from 'mobx';

import { DeploymentGroupsApiClient } from '../api';
import { AssureBaseStore, IAssureStoreConstructorOptions } from './assure-base.store';
import { AccountStore } from './account-store';
import { CreateDeploymentGroupDetailsProps, IDeploymentGroup, IDisplayDeploymentGroup, UpdateDeploymentGroupDetailsProps } from '../dto/deployment/deployment-group-dto';
import { orderBy } from 'lodash';
import { getErrorMessage, isNullOrEmpty } from 'common-utils';
import { InformationDialogType } from 'ui-lib';
import { DeviceGroupsStore } from './device-groups.store';
import { DeploymentDetails } from 'dto/deployment/deployment-details.dto';

export class DeploymentGroupsStore extends AssureBaseStore<DeploymentGroupsApiClient, IDeploymentGroup> {

    private _isInitDeploymentGroups: boolean;
    public constructor(options: IAssureStoreConstructorOptions, private readonly deviceGroupsStore: DeviceGroupsStore, private readonly accountStore: AccountStore) {
        super({
            ...options,
            onUpdateSource: () => {
                if (this._isInitDeploymentGroups) {
                    this._isInitDeploymentGroups = false;
                    return;
                }
                this.deviceGroupsStore.loadDeviceGroupDetails(true);
            }
        });
        makeObservable(this);
    }

    protected get apiClient(): DeploymentGroupsApiClient {
        return this.apiClientStore.apiClients.deploymentGroups;
    }

    public async loadDeploymentGroups(accountId: number, force?: boolean): Promise<void> {
        if (!force && this.hasEntities()) return;
        try {
            this.setDataLoading(true);
            this._isInitDeploymentGroups = true;
            this.setEntities(await this.apiClient.getDeploymentGroups(accountId));
        } catch (err) {
            this.setError(getErrorMessage(err));
        } finally {
            this.setDataLoading(false);
        }
    }

    public async updateDeploymentGroup(accountId: number, depoymentGroupId: number, saveGroupProps: UpdateDeploymentGroupDetailsProps): Promise<void> {
        const updatedGroup = await this.apiClient.updateDeploymentGroup(accountId, depoymentGroupId, saveGroupProps);
        this.updateEntity(depoymentGroupId, updatedGroup);
    }

    public async createDeploymentGroup(accountId: number, newGroup: CreateDeploymentGroupDetailsProps): Promise<void> {
        const result = await this.apiClient.createDeploymentGroup(accountId, newGroup);
        this.addEntity(result);
    }

    public async deleteDeploymentGroup(
        accountId: number,
        deploymentGroupId: number,
        showDialogCallback?: (modalType: InformationDialogType, message: string) => void,
    ): Promise<void> {
        try {
            this.setError(null);
            const result = await this.apiClient.deleteDeploymentGroup(accountId, deploymentGroupId);
            this.removeEntity(deploymentGroupId);
            if (showDialogCallback) showDialogCallback('success', result);
        } catch (err) {
            const error = getErrorMessage(err);
            if (showDialogCallback) showDialogCallback('error', error);
            this.setError(error);
        }
    }

    public findDeploymentGroupById(id: number): IDisplayDeploymentGroup {
        if (!id || isNullOrEmpty(this.displayDeploymentGroups)) {
            return null;
        }
        return this.displayDeploymentGroups.find((item) => item.id === id);
    }

    public async getDeploymentGroupDetails(accountId: number, groupId: number): Promise<IDeploymentGroup> {
        return this.apiClient.getDeploymentGroupDetails(accountId, `${groupId}`, {
            includeDeployments: 1,
        });
    }

    public getAccountNameByUuid(accountUuid: string): string {
        return this.accountStore.managedAccounts.find((account) => account.uuid == accountUuid)?.name || '';
    }

    @computed
    public get displayDeploymentGroups(): IDisplayDeploymentGroup[] {
        if (!this.hasEntities()) return [];
        const deploymentGroups = this.entities
        .filter((item) => !!item)
        .map((group) => {
            const defaultProps = { targetDeviceGroupNames: [], deviceGroupLabels: [], deviceGroupIds: [], deviceGroupLabelAsString: '' };
            const deviceGroupProps = group.deviceGroups?.reduce((_, deviceGroupEntity) => {
                // Filter removed device groups
                const deviceGroup = this.deviceGroupsStore.getDeviceGroupById(deviceGroupEntity.id);
                if (!deviceGroup) return _;
                _.targetDeviceGroupNames.push(deviceGroup.deviceGroupName);
                _.deviceGroupLabels.push(deviceGroup.deviceGroupLabel);
                _.deviceGroupIds.push(deviceGroup.id);
                _.deviceGroupLabelAsString = _.deviceGroupLabels.join(', ');
                return _;
            }, defaultProps);

            return {
                key: group.id,
                id: group.id,
                label: group.label,
                value: group.id,
                accountUuid: group.accountUuid,
                accountName: !group.accountUuid ? '' : this.getAccountNameByUuid(group.accountUuid),
                ...deviceGroupProps,
                settings: group.settings,
            };
        });
        return orderBy(deploymentGroups, ['id'], ['desc']);
    }

    public async getActiveDeploymentDetailsByGroupId(accountId: number, id: number): Promise<DeploymentDetails[]> {
        const result = await this.apiClient.getActiveDeploymentDetailsByGroupId(accountId, id);
        return result.map(item => new DeploymentDetails(item));
    }
}
