import * as React from 'react';
import { BasicForm } from './basic-form';
import { PasswordFieldItem, EmailFieldItem, IFormItemValidation } from './items';
import { Typography } from '../labels/label';
import { IconTextButton } from '../buttons';
import { CheckboxField } from '../checkboxes';
import { RowLayout } from '../grid';
import { Icon } from '../icon';

import loginFormCss from './login-form.css';

const { Title } = Typography;

export interface ILoginFormProps {
    onLogin?: any;
    rememberMe?: boolean;
    hideRememberMe?: boolean;
    forgotPassword?: React.ReactNode;
    validate?: IFormItemValidation;
}

export function LoginForm(props: ILoginFormProps) {
    const [rememberMe, setRememberMe] = React.useState(props.rememberMe);
    const rememberMeChange = (value) => setRememberMe(value.target.checked);
    const [loadingState, setLoadingState] = React.useState(false);
    const onSubmit = async (ev) => {
        try {
            setLoadingState(true);
            await props.onLogin(ev);
        } finally {
            setLoadingState(false);
        }
    };

    const forgotPasswordLayout = () : JSX.Element => {
        return (
            <div className={loginFormCss.forgotPasswordRow}>
                {!props.hideRememberMe && (
                    <CheckboxField
                        className={loginFormCss.rememberMe}
                        label={'Remember me'}
                        checked={rememberMe}
                        onChange={rememberMeChange}
                    />
                )}
                {!!props.forgotPassword && <div className={loginFormCss.forgotPassword}>{props.forgotPassword}</div>}
            </div>
        );
    };

    const items = [
        <EmailFieldItem
            code={'email'}
            label={'Email'}
            className={loginFormCss.inputField}
            isRequired={true}
            placeHolder={'email'}
            prefixOutline={<Icon iconName='user' size='SMALL' />}
        />,
        <PasswordFieldItem
            customizeValidate={props.validate}
            code={'password'}
            label={'Password'}
            className={loginFormCss.inputField}
            isRequired={true}
            placeHolder={'password'}
            prefixOutline={<Icon iconName='lock' size='SMALL' />}
            visibilityToggle={false}
        />,
        forgotPasswordLayout(),
        <IconTextButton
            loading={loadingState}
            label={'Sign in'}
            type={'primary'}
            className={loginFormCss.button}
            htmlType={'submit'}
        />,
    ];

    return (
        <BasicForm
            title={
                <div className={loginFormCss.title}>
                    <Title level={4}>Login</Title>
                </div>
            }
            items={items}
            className={loginFormCss.loginForm}
            onSubmit={onSubmit}
        />
    );
}
