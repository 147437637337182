import * as React from 'react';
import { FormItemProps as AntdFormItemProps, Form } from 'antd';
import { FormLabelAlign } from 'antd/lib/form/interface';

import formItemCss from './form-item.css';
export interface IFormItemValidation {
    status?: any;
    message?: React.ReactNode;
    resetValidation?: () => void;
}

export interface IFormItem {
    code: string;
    name?: any;
    label?: string;
    isRequired?: boolean;
    className?: string;
    labelAlign?: FormLabelAlign;
    labelColSpan?: number;
    initialValue?: any;
    disabled?: boolean;
    validator?: any;
    rule?: any;
    showSearch?: boolean;
    hidden?: boolean;
    validateStatus?: string;
    customizeValidate?: IFormItemValidation;
    validateTrigger?: string | string[] | false;
    containerClassName?: string;
}

export const getDefaultFormItemOptions = (formItem: IFormItem, whitespace?: boolean): Partial<AntdFormItemProps> => ({
    label: formItem.label,
    name: formItem.name || formItem.code,
    initialValue: formItem.initialValue,
    colon: false,
    labelCol: { span: formItem.labelColSpan ? formItem.labelColSpan : 5 },
    labelAlign: formItem.labelAlign ? formItem.labelAlign : 'left',
    validateStatus: formItem.customizeValidate?.status,
    help: formItem.customizeValidate?.message,
    hidden: formItem.hidden,
    rules: (() => {
        const rules = [];
        if (formItem.isRequired)
            !whitespace
                ? rules.push({ required: true, message: `${formItem.label || 'Field'} is required` })
                : rules.push({ required: true, message: `${formItem.label || 'Field'} is required`, whitespace: true });
        if (formItem.validator) rules.push({ validator: formItem.validator });
        if (formItem.rule) rules.push(...(Array.isArray(formItem.rule) ? formItem.rule : [formItem.rule]));
        return rules;
    })(),
    className: formItem.containerClassName
});

export interface IFormItemProps extends Partial<AntdFormItemProps> {
    code: string;
    child: React.ReactNode;
}

export const FormItem = (props: IFormItemProps) => {
    const { code, child, validateStatus, ...formItemProps } = props;
    return (
        <Form.Item validateStatus={validateStatus} className={formItemCss.formItem} key={code} {...formItemProps}>
            {child}
        </Form.Item>
    );
};
