import { getErrorMessage } from 'common-utils';
import { RELEASE_NOTIFICATION_TYPE } from 'dto/notifications-dto';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useNotificationsStore } from 'store';
import { AdvanceTable, IBasicTableColumn, IBasicTableProps, notification, showConfirmDialog, Typography } from 'ui-lib';
import { useSpinnerComponent } from '../components/loading-component';
import { createBasicFilterColumn } from '../components/table-utils';
import { CreateOrEditNotificationComponent, ICreateOrEditNotification } from './save';
import { formatDateAndShortTime, formatShortDate } from 'common-utils/date-utils';

interface INotificationTable {
    onModify: (id?: number) => void;
}

interface IDisplayNotification {
    id: number;
    title: string;
    text: string;
    author: string;
    releaseType: RELEASE_NOTIFICATION_TYPE;
    messageType?: string;
    dateToDisplay?: string;
    releaseDate?: string;
    createdAt: string;
}

const NotificationsTableComponent = observer((props: INotificationTable) => {
    const notificationStore = useNotificationsStore();
    const { SpinnerComponent: SpiningComponent, ...spinningStates } = useSpinnerComponent(false);

    const notifications = React.useMemo<IDisplayNotification[]>(() => {
        return notificationStore.managedNotifications.map(item => ({
            id: item.id,
            title: item.content.title,
            text: item.content.text,
            dateToDisplay: formatShortDate(item.content.dateToDisplay),
            createdAt: formatDateAndShortTime(item.createdAt),
            releaseDate: formatShortDate(item.content.data?.releaseDate),
            author: item.sender,
            releaseType: item.content.data?.releaseType
        }));
    }, [notificationStore.managedNotifications]);

    const columns: IBasicTableColumn[] = [
        createBasicFilterColumn({
            title: 'Title', key: 'title', columnExtraOptions: {
                render: (title: string, record) => <Typography.Link onClick={() => props.onModify(record.id)}>{title}</Typography.Link>
            }
        }),
        {
            code: 'releaseType',
            title: 'Update Type',
            dataIndex: 'releaseType',
            filters: Object.values(RELEASE_NOTIFICATION_TYPE).map(item => ({ value: item, text: item })),
            onFilter: (type: RELEASE_NOTIFICATION_TYPE, record) => record.releaseType == type,
        },
        {
            code: 'dateToDisplay',
            title: 'Date to display',
            dataIndex: 'dateToDisplay',
            sorter: (a: IDisplayNotification, b: IDisplayNotification) => Date.parse(b.dateToDisplay) - Date.parse(a.dateToDisplay)
        },
        {
            code: 'createdAt',
            title: 'Created date',
            dataIndex: 'createdAt',
            sorter: (a: IDisplayNotification, b: IDisplayNotification) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
        },
        {
            code: 'releaseDate',
            title: 'Release date',
            dataIndex: 'releaseDate',
            sorter: (a: IDisplayNotification, b: IDisplayNotification) => Date.parse(b.releaseDate) - Date.parse(a.releaseDate)
        },
        createBasicFilterColumn({ title: 'Author', key: 'author', }),
        {
            code: 'action',
            title: 'Actions',
            dataIndex: 'action',
            actions: [{
                key: 'id',
                label: 'Delete',
                onClick: (id: number) => {
                    showConfirmDialog({
                        title: 'Confirmation',
                        content: 'Are you sure you want to delete this notification?',
                        okText: 'OK',
                        cancelText: 'Cancel',
                        onOk: async () => {
                            try {
                                spinningStates.onStart();
                                await notificationStore.delete(id);
                            } catch (err) {
                                notification.error({
                                    message: 'Delete notification error',
                                    description: getErrorMessage(err)
                                });
                            } finally {
                                spinningStates.onFinish();
                            }
                        },
                    });
                }
            }]
        }
    ];
    const tableProps: IBasicTableProps = { rowKey: 'id', columns, dataSource: notifications, extraOptions: { pagination: { hideOnSinglePage: true } } };

    return (
        <SpiningComponent
            child={<AdvanceTable
                title=''
                loadingState={notificationStore.dataLoading}
                table={tableProps}
                toolbar={{
                    onReload: () => notificationStore.getManagedNotifications(),
                    onAdd: props.onModify
                }}
            />}
        />
    );
});

enum NotificationComponentState {
    TABLE = 'TABLE',
    MODIFY = 'MODIFY'
}

interface IComponentState {
    state: NotificationComponentState;
    props?: INotificationTable | ICreateOrEditNotification;
}

type ComponentChangeAction =
    | { type: NotificationComponentState.TABLE, payload: INotificationTable }
    | { type: NotificationComponentState.MODIFY; payload: ICreateOrEditNotification };

const notificationReducer = (state: IComponentState, action: ComponentChangeAction) => {
    switch (action.type) {
        case NotificationComponentState.TABLE:
            return { state: NotificationComponentState.TABLE, props: action.payload };
        case NotificationComponentState.MODIFY:
            return { state: NotificationComponentState.MODIFY, props: action.payload };
        default:
            break;
    }
};

export const ManageNotificationComponent = (props) => {
    const toTableState = () => dispatch({ type: NotificationComponentState.TABLE, payload: { onModify: toModifyState } });
    const toModifyState = (id?: number) => dispatch({ type: NotificationComponentState.MODIFY, payload: { id, onClose: toTableState } });

    const notificationStore = useNotificationsStore();

    React.useEffect(() => {
        notificationStore.getManagedNotifications();
    }, []);

    const initialComponentState = {
        state: NotificationComponentState.TABLE,
        props: { onModify: toModifyState }
    };

    const [componentState, dispatch] = React.useReducer(notificationReducer, initialComponentState);

    return (
        <div>
            {componentState.state === NotificationComponentState.TABLE && <NotificationsTableComponent {...(componentState.props as INotificationTable)} />}
            {componentState.state === NotificationComponentState.MODIFY && <CreateOrEditNotificationComponent {...(componentState.props as ICreateOrEditNotification)} />}
        </div>
    );
};