import * as React from 'react';
import { AddDeploymentPage } from './add';
import { DeploymentsManagementTable } from './list';


export enum IDeploymentComponent {
    LIST = 'LIST',
    ADD_NEW = 'ADD_NEW',
}

export function DeploymentsManagementPage() {
    const [deploymentComponent, setDeploymentComponent] = React.useState<IDeploymentComponent>(
        IDeploymentComponent.LIST,
    );

    return (
        <React.Fragment>
            {deploymentComponent == IDeploymentComponent.LIST && <DeploymentsManagementTable navigateToAddNew={() => setDeploymentComponent(IDeploymentComponent.ADD_NEW)} />}
            {deploymentComponent == IDeploymentComponent.ADD_NEW && <AddDeploymentPage navigateToDeploymentTable={() => setDeploymentComponent(IDeploymentComponent.LIST)} />}
        </React.Fragment>
    );
}
