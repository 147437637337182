import { io, Socket } from 'socket.io-client';
import { IBuilderOptions } from './socket-builder';

type TSocketResponseCallback<T> = (response: T) => void | Promise<void>;

export class SocketClient {

    private readonly socket: Socket;

    constructor(host: string, options: IBuilderOptions) {
        this.socket = io(host, { ...options, withCredentials: true });
    }

    public connect() {
        if (this.socket.connected)
            return;
        this.socket.connect();
    }

    public reconnect(callback: (attempt: number) => void) {
        this.socket.io.on('reconnect', callback);
    }

    public listenOn<T>(name: string, callback: TSocketResponseCallback<T>) {
        this.socket.on(name, callback);
    }

    public listenTo(name: string, callback: any) {
        this.socket.on(name, callback);
    }

    public emitMessage(name: string, payload: any) {
        this.socket.emit(name, { ...payload });
    }

    public disconnect() {
        this.socket.disconnect();
    }

    public getClientId() {
        return this.socket.id;
    }
}
