import * as React from 'react';
import { Avatar as AntAvatar, Space, Badge } from 'antd';
import avatarCss from './avatar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IAvatarProps {
    icon?: React.ReactNode;
    label?: string;
    showBadge?: boolean;
}

export function Avatar(props: IAvatarProps) {
    return (
        <Space size={'small'}>
            <Badge dot={props.showBadge}>
                <AntAvatar className={avatarCss.avatarWrapper}
                icon={<FontAwesomeIcon icon={['far', 'user']} />} />
            </Badge>
            {props.label}
        </Space>
    );
}
