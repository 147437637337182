import {IRouteConstructorOptions, Route} from './route';
import {computed, makeObservable, observable} from 'mobx';

export interface IRouteOptions {
    permissionCodes?: string[];
}

export interface IRoutes {
    [routeCode: string]: Omit<IRouteConstructorOptions, 'code'> & IRouteOptions;
}

export class RouteStore {

    @observable
    private readonly _routes: Map<string, Route> = new Map();

    constructor() {
        makeObservable(this);
    }

    public registerRoutes(routes: IRoutes) {
        for(const code in routes) {
            this._routes.set(code, new Route({
                ...routes[code],
                code: code
            }));
        }
    }

    public removeRoutes(routeCodes?: string[]) {
        if(!routeCodes || (Array.isArray(routeCodes) && routeCodes.length === 0)) this._routes.clear();

        if(Array.isArray(routeCodes) && routeCodes.length > 0) {
            routeCodes.forEach(routeCode => this._routes.delete(routeCode));
        }
    }

    @computed
    get mainMenuRoutesAsArray(): Route[] {
        return Array
            .from(this._routes.values())
            .filter(route => route.hasMainMenuEntry)
            .map(route => {
                if (Array.isArray(route.subRouteCodes)) {
                    const subRoutes = route.subRouteCodes.map(routeCode => this._routes.get(routeCode));
                    route.subRoutes = subRoutes;
                }
                return route;
            });
    }

    @computed
    get routes(): Route[] {
        return Array.from(this._routes.values());
    }

    public getRoute(code: string): Route {
        return this.routes.find(route => route.code === code);
    }
}
