import * as React from 'react';
import { Form } from 'antd';
import { FormListProps } from 'antd/lib/form/FormList';

import { FormItem, IFormItemProps } from './form-item';

export interface IFormListItemProps extends Omit<IFormItemProps, 'child'> {
    formListProps: FormListProps;
}

export const FormListItem = (props: IFormListItemProps) => {
    const { formListProps, ...formItemProps } = props;
    return <FormItem {...formItemProps} child={<Form.List {...formListProps} />} />;
};
