import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Content, Footer, Header, Layout, showConfirmDialogWithoutCancel } from 'ui-lib';
import { observer } from 'mobx-react';
import { useAuthStore, useRoutesStore, useStores } from '../store';
import { PrivatePages } from './private';
import { PublicPages } from './public';
import { getDefaultPath } from './private/utils';

const EXPIRED_TOLERANCE = 30000; // unit: ms
const AssureAdminHeader = observer(() => {
    const { isAuthenticated, currentUser, logOut } = useAuthStore();

    const { mainMenuRoutesAsArray } = useRoutesStore();
    const navigate = useNavigate();
    const stores = useStores();
    const { permissionStore, notificationsStore } = useStores();

    const logOutAction = async () => {
        await logOut();
        permissionStore.clearEntities();
        navigate('');
    };

    React.useEffect(() => {
        if (!isAuthenticated) return;
        const expiryTimeMs = currentUser.exp * 1000 - Date.now();
        const expiryTimerId = setTimeout(() => {
            showConfirmDialogWithoutCancel({
                title: 'Information',
                content: 'Your session has expired. Please sign in.',
                okText: 'OK',
                onOk: logOutAction,
            });
        }, expiryTimeMs - EXPIRED_TOLERANCE); // call logout() before token expire

        return () => {
            clearTimeout(expiryTimerId);
        };
    }, [isAuthenticated]);

    return (
        <Header
            headerIconTitle={{
                iconName: 'sensor-cloud',
                title: 'ASSURE LIFECYCLE MANAGEMENT',
                onClick: () => navigate(getDefaultPath(stores))
            }}
            isAuthenticated={isAuthenticated}
            actionTitle={currentUser?.name || 'John Doe'}
            actionItems={[
                { code: 'logout', iconName: 'right-from-bracket', label: () => 'Logout', onClick: logOutAction },
            ]}
            menuItems={!currentUser?.isActive ? [] : [
                ...mainMenuRoutesAsArray
                    .filter((route) => route.hasPermission(permissionStore.permissionCodes))
                    .map((route) =>
                        Array.isArray(route.subRoutes)
                            ? {
                                code: route.path,
                                iconName: route.iconName,
                                label: () => route.label.toUpperCase(),
                                onClick: () => route.mainMenuOnClick && navigate(route.subRoutes.find((subRoute) => subRoute.hasPermission(permissionStore.permissionCodes)).path),
                                subMenuItems: route.subRoutes
                                    .filter((subRoute) => subRoute.hasPermission(permissionStore.permissionCodes))
                                    .map((subRoute) => ({
                                        code: subRoute.path,
                                        iconName: subRoute.iconName,
                                        label: () => subRoute.label,
                                        onClick: () => navigate(subRoute.to),
                                    })),
                            }
                            : {
                                code: route.path,
                                iconName: route.iconName,
                                label: () => route.label.toUpperCase(),
                                onClick: () => navigate(route.to),
                            },
                    ),
                {
                    code: 'notification-hub',
                    iconName: 'bell',
                    badge: {
                        count: notificationsStore.recieveNotifications?.length || '...',
                        overFlowCount: 99
                    },
                    onClick: () => notificationsStore.openHub(),
                }
            ]}
        />
    );
});

const AssureAdminContent = observer(() => {
    const { isAuthenticated } = useAuthStore();
    return <Content isAuthenticated={isAuthenticated} privatePages={<PrivatePages/>} publicPages={<PublicPages/>}/>;
});

const STAGE = {
    LOCAL: { url: 'localhost:9000', name: 'LOCAL DEVELOPMENT' },
    DEV: { url: 'webadmin.api.dpu.dev.assurenextgen.com', name: 'DEVELOPMENT' },
    TEST: { url: 'webadmin.api.dpu.test.assurenextgen.com', name: 'TEST' },
    CD: { url: 'webadmin.api.dpu.cd.assurenextgen.com', name: 'CLIENT DEVELOPMENT' },
};

export const stageKey: string = Object.keys(STAGE).find((key) => window.location.href.includes(STAGE[key]?.url));

export const Pages = () => (
    <Layout>
        <AssureAdminHeader/>
        <AssureAdminContent/>
        <Footer label="Assure Lifecycle Management" version={__VERSION} stage={STAGE[stageKey]?.name}/>
    </Layout>
);

