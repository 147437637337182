import { ILogOptions } from 'dto/device-dto';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { DeviceDetailEntryStore, IDeviceDetailsConstructorOptions } from './device-detail-entry-store';
import { formatDate } from 'common-utils/date-utils';
import { getErrorMessage } from 'common-utils';
import moment from 'moment';
import { isUndefined } from 'lodash';

interface IDeviceLogCodeProps {
    logName: string;
    code: string;
}

export const DEVICE_LOGS: IDeviceLogCodeProps[] = [
    { code: 'syslogs', logName: 'System Logs' },
    { code: 'termlog', logName: 'Term Logs' },
];

export interface ISystemLogs extends IDeviceLogCodeProps {
    key?: number;
    date?: string;
    logEntries: any[];
}

export interface IDeviceLogs {
    code: string;
    logEntries: ILogOptions[];
}

export interface IDisplayDeviceLog {
    key: number;
    logName: string;
    code: string;
    displayLogEntriesData: ILogOptions[];
}

export class DeviceLogsStore extends DeviceDetailEntryStore<IDeviceLogs> {
    constructor(options: Omit<IDeviceDetailsConstructorOptions, 'code'>) {
        super(options);
        makeObservable(this);

        this.setDate(moment());
        reaction(
            () => this.date,
            (currentDate, prev, r) => {
                if (isUndefined(prev) || isUndefined(currentDate)) {
                    return;
                }
                if (currentDate.endOf('day') === prev.endOf('day')) {
                    return;
                }
                this.initialize(true);
            });
    }

    /** ************************************
     * Override functions
     **************************************/
    protected name(): string {
        return 'device logs';
    }

    
    @observable
    public date: moment.Moment;

    @computed
    public get startOfDay(): moment.Moment {
        return this.date.startOf('day');
    }

    @computed
    public get endOfDay(): moment.Moment {
        return this.date.endOf('day');
    }

    @action
    public setDate(date: moment.Moment): void {
        this.date = date.clone();
    }

    protected async initializeData(): Promise<IDeviceLogs[]> {
        return Promise.all(
            DEVICE_LOGS.map(async (item) => {
                try {
                    const logEntries = await this._devicesStore.getDeviceLogs(this.accountId, this.uid, item.code, {
                        from: this.startOfDay.toLocaleString(),
                        to: this.endOfDay.toLocaleString()
                    });
                    return { code: item.code, logEntries };
                } catch (err) {
                    if (getErrorMessage(err).includes('does not exist')) return { code: item.code, logEntries: [] };
                    throw err;
                }
            }),
        );

    }
    /** *************************************/
    public displayDeviceLogs(logType: string): ILogOptions[] {
        if (!this.entities || this.entities.length == 0) return [];
        const today = new Date();
        const currentYear = today.getFullYear();
        const timestampRegex = /\w{3}\s*\d{1,2}\s*\d{2}:\d{2}:\d{2}/;
        let logEntries: ILogOptions[] = [];
        this.entities.filter(item => item.code === logType).forEach((log) => {
            logEntries = log.logEntries;
        });
        return logEntries.map((item, index) => ({
            key: `logrecord-${index}`,
            timestamp: formatDate(currentYear + item.data.match(timestampRegex)),
            data: item.data,
        }));

    }
    public async getTrampolineClientVersion():Promise<string>{
        const result = await this._devicesStore.getDeviceAttributes(this.accountId,this.uid);
        return result.trampolineVersion;
    }
}
