import { computed, makeObservable } from 'mobx';

import { PermissionApiClient } from 'api/permission-api-client';
import { AssureBaseStore, IAssureStoreConstructorOptions } from './assure-base.store';
import { IPermission } from 'dto/access-management/user-role-dto';

export class PermissionStore extends AssureBaseStore<PermissionApiClient, IPermission> {
    
    public constructor(options: IAssureStoreConstructorOptions) {
        super(options);
        makeObservable(this);
    }

    protected get apiClient(): PermissionApiClient {
        return this.apiClientStore.apiClients.permission;
    }

    public async loadPermissions(): Promise<void> {
        this.setDataLoading(true);
        this.setEntities(await this.apiClient.getPermissions());
        this.setDataLoading(false);
    }

    @computed
    public get getPermissions(): IPermission[] {
        if (!this.hasEntities()) return [];
        return this.entities;
    }

    @computed
    public get permissionCodes(): string[] {
        if (!this.hasEntities()) return [];
        return this.entities.map((permission) => permission.code);
    }

    public getAllPermissions(): Promise<IPermission[]> {
        return this.apiClient.getPermissions({ all: true });
    }
}
