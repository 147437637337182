import * as React from 'react';
import cx from 'classnames';
import { Spin, Typography } from 'antd';

import spinnerCss from './spinner.css';

export interface ISpinnerProps extends Omit<ISpinnerTitleProps, 'level'> {
    children?: React.ReactNode;
    size?: 'medium' | 'large' | 'x-large'
    subTitle?: string;
    spinning?: boolean;
    className?: string;
}

export interface ISpinnerTitleProps {
    title?: string;
    level: number;
}

export interface ISpinnerSubTitleProps {
    subTitle: string;
}

const SpinnerTitle = (props: ISpinnerTitleProps) => (
    <Typography.Title level={props.level as any}>
        {props.title}
    </Typography.Title>
);

const SpinnerSubTitle = (props: ISpinnerSubTitleProps) => (
    <Typography.Text type="secondary">
        {props.subTitle}
    </Typography.Text>
);

export function Spinner(props: ISpinnerProps) {

    let titleLevel = null;

    switch (props.size) {
        case 'medium':
            titleLevel = 4;
            break;
        case 'large':
            titleLevel = 3;
            break;
        case 'x-large':
            titleLevel = 2;
            break;
        default:
            titleLevel = 5;
            break;
    }

    return (
        <div className={cx(spinnerCss.spinnerWrapper, props.className)}>
            <Spin spinning={props.spinning}
                className={cx(spinnerCss.spinner, {
                    [spinnerCss.medium]: props.size === 'medium',
                    [spinnerCss.large]: props.size === 'large',
                    [spinnerCss.xLarge]: props.size === 'x-large'
                })}
                size={titleLevel < 3 ? 'large' : 'default'}
            >
                {props.children ? props.children : null}
            </Spin>
            <div className={spinnerCss.spinnerBody}>
                {props.title ? <SpinnerTitle title={props.title} level={titleLevel}/> : null}
                {props.subTitle ? <SpinnerSubTitle subTitle={props.subTitle} /> : null}
            </div>
        </div>
    );
}