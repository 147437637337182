/* eslint-disable react/jsx-key */
import React from 'react';
import { differenceBy } from 'lodash';

import {
    BasicForm,
    IconTextButton,
    IExtraValidatorResult,
    showInformationDialog,
    SimpleFileUploadItem,
    TextAreaFieldItem,
    TextFieldItem,
    useForm,
} from 'ui-lib';

import { useAuthStore } from 'store';
import { isNullOrEmpty } from 'common-utils';
import { TConfigurationUploadFile } from './index';
import { AccountType } from 'dto/access-management/account-dto';

import configurationCss from './configuration.css';

export interface IConfigurationUploadFilesProps {
    selectedFile: TConfigurationUploadFile;
    setSelectedFile: (file: TConfigurationUploadFile) => void;
    fileList: TConfigurationUploadFile[];
    setFileList: (files: TConfigurationUploadFile[]) => void;
    onClick: () => void;
}

const REQUIRED_FIELD_NAMES = ['description', 'releaseNotes'];
const CA_KEY_FILE_NAME = 'ca_keys.bin';
const REQUIRED_FIELD_ERROR = 'Please fill required field(s).';

interface IInvalidFileProps {
    error?: string;
    files?: Partial<TConfigurationUploadFile>[];
}

const showInvalidFilesErrorDialog = (invalidFiles: IInvalidFileProps[]): boolean => {
    if (isNullOrEmpty(invalidFiles)) return false;
    showInformationDialog({
        title: 'Error',
        content: (
            <div>
                {invalidFiles.map((invalidItem, index) => (
                    <div key={`error-div-${index}`}>
                        <div key={`error-label-${index}`}>{invalidItem.error}</div>
                        <ul key={`error-ul-${index}`}>
                            {invalidItem.files?.map((file: TConfigurationUploadFile) => <li key={`error-${index}`}>{file.name}</li>)}
                        </ul>
                    </div>
                ))}
            </div>
        ),
        modalType: 'error',
        className: configurationCss.infoDialog,
    });
    return true;
};

export const ConfigurationUploadFiles = (props: IConfigurationUploadFilesProps): JSX.Element => {
    const { currentUser } = useAuthStore();
    const [form] = useForm();
    const [selectedFile, setSelectedFile] = [props.selectedFile, props.setSelectedFile];
    const [invalidFiles, setInvalidFiles] = React.useState<IInvalidFileProps[]>([]);

    React.useEffect(() => {
        if (isNullOrEmpty(props.fileList)) {
            form.resetFields();
            setSelectedFile(null);
            return;
        }
        onPreview(selectedFile || props.fileList.at(0));
    }, [props.fileList]);

    React.useEffect(() => {
        if (!showInvalidFilesErrorDialog(invalidFiles)) return;
        setInvalidFiles([]);
    }, [invalidFiles]);

    const _isValidMetaData = (currentFile: TConfigurationUploadFile): boolean => {
        const fields = form.getFieldsValue(REQUIRED_FIELD_NAMES);
        if (Object.values(fields).some(value => !value)) {
            form.submit();
            return false;
        }

        currentFile.description = fields.description;
        currentFile.releaseNotes = fields.releaseNotes;
        currentFile.status = null;
        currentFile.response = null;

        return true;
    };

    const onPreview = (file: TConfigurationUploadFile): void => {
        if (selectedFile && !_isValidMetaData(selectedFile))
            return;

        setSelectedFile(file);
        form.setFieldsValue({
            fileName: file?.name,
            description: file?.description,
            releaseNotes: file?.releaseNotes,
        });
    };

    const onNext = (): void => {
        if (!_isValidMetaData(selectedFile))
            return;
        onPreview(selectedFile);
        const invalidFiles = props.fileList.filter((file: TConfigurationUploadFile) => file.status === 'error');
        if (isNullOrEmpty(invalidFiles))
            return props.onClick();

        showInvalidFilesErrorDialog([{
            error: 'All fields are required for:',
            files: invalidFiles
        }]);
    };

    const onTextChanged = (): void => {
        if (!selectedFile)
            return;

        const cloneFiles = props.fileList.slice();
        const fieldValues = form.getFieldsValue(REQUIRED_FIELD_NAMES);
        const file = cloneFiles.find((file) => file.name === selectedFile.name);
        const hasError = isNullOrEmpty(fieldValues.description) || isNullOrEmpty(fieldValues.releaseNotes);
        file.status = hasError ? 'error' : null;
        file.response = hasError ? REQUIRED_FIELD_ERROR : null;
        props.setFileList(cloneFiles);
    };

    return (
        <BasicForm
            form={form}
            key='uploadFiles'
            className={configurationCss.formUploadFiles}
            cardPros={{ bordered: false }}
            items={[
                <TextFieldItem
                    code='fileName'
                    label='File name'
                    labelAlign='right'
                    isRequired={true}
                    initialValue={selectedFile?.name}
                    disabled={true}
                />,
                <TextFieldItem
                    code='description'
                    label='Description'
                    labelAlign='right'
                    isRequired={true}
                    initialValue={selectedFile?.description}
                    onChange={onTextChanged}
                />,
                <TextAreaFieldItem
                    code='releaseNotes'
                    label='Release notes'
                    labelAlign='right'
                    isRequired={true}
                    initialValue={selectedFile?.releaseNotes}
                    textAreaProps={{
                        autoSize: { minRows: 5, maxRows: 10 },
                        onChange: onTextChanged,
                    }}
                />,
                <SimpleFileUploadItem
                    code='fileUpload'
                    label=' '
                    simpleFileUploadProps={{
                        className: configurationCss.simpleFileUpload,
                        allowedFileTypes: ['.bin','.json'],
                        fileList: props.fileList,
                        setFileList: props.setFileList,
                        button: {
                            label: 'Click to Upload',
                            type: 'primary',
                        },
                        multiple: currentUser.accountType !== AccountType.DPU,
                        onPreview,
                        onRemove: (file, newFileList) => {
                            if (newFileList.length === 0) {
                                form.resetFields();
                            }

                            if (file === selectedFile) {
                                const newSelectedFile = newFileList[0];
                                setSelectedFile(newSelectedFile);
                                form.setFieldsValue({
                                    fileName: newSelectedFile?.name,
                                    description: newSelectedFile?.description,
                                    releaseNotes: newSelectedFile?.releaseNotes,
                                });
                            }
                        },
                        extraValidator: (file: TConfigurationUploadFile): IExtraValidatorResult => {
                            const hasMetaData = file.description && file.releaseNotes;
                            return hasMetaData ? null : { status: 'error', response: REQUIRED_FIELD_ERROR };
                        },
                        beforeUploadChecking: (currentFiles: File[], newFiles: File[]): File[] => {
                            if (currentUser.accountType === AccountType.DPU) {
                                if (!isNullOrEmpty(currentFiles)) {
                                    setInvalidFiles([{
                                        error: 'DPU Admin can upload only one file',
                                        files: [],
                                    }]);
                                    return [];
                                }

                                if (newFiles.at(0).name.toLowerCase() !== CA_KEY_FILE_NAME) {
                                    setInvalidFiles([{
                                        error: `The DPU Admin is only allowed to select "${CA_KEY_FILE_NAME}" file.`,
                                        files: newFiles,
                                    }]);
                                    return [];
                                }
                                return newFiles;
                            }

                            let validFiles = newFiles;
                            const errorFiles = [];
                            const invalidFiles = newFiles.filter((file) => !file.name.match(/^.*\.(bin|json)$/i));
                            if (!isNullOrEmpty(invalidFiles)) {
                                errorFiles.push({
                                    error: 'The VAR or Client Admin is only allowed to select "*.bin or .json" files.',
                                    files: invalidFiles,
                                });
                                validFiles = differenceBy(newFiles, invalidFiles, 'name');
                            }

                            const currentFileNames = currentFiles?.map((file) => file.name) || [];
                            const duplicateFiles = validFiles.filter((file) => currentFileNames.includes(file.name));
                            if (!isNullOrEmpty(duplicateFiles)) {
                                errorFiles.push({
                                    error: 'The file name{s) are duplicated.',
                                    files: duplicateFiles,
                                });
                                validFiles = differenceBy(validFiles, duplicateFiles, 'name');
                            }
                            setInvalidFiles(errorFiles);
                            return validFiles;
                        },
                    }}
                />,
                isNullOrEmpty(props.fileList) ? null : (
                    <IconTextButton
                        className={configurationCss.control}
                        label='Next'
                        type='primary'
                        onClick={onNext}
                    />
                )
            ]}
        />
    );
};
