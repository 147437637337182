import * as React from 'react';
import wizardCss from './wizard.css';
import { IDeviceGroupOptions } from '../../../../store/wizard-store';
import { IDeviceGroupTableProps } from './commission-interface';
import {
    AdvanceEditableTable,
    SelectFormItem,
    IEditableTableColumn,
    IEditableTableProps,
    EditableContext,
} from 'ui-lib';

export const SelectDeviceGroupsWrapper = (props: IDeviceGroupTableProps) => {
    const dataSource = props.data.dataSource;
    const tableDataSource = !dataSource ? [] : [dataSource];

    const deviceGroupNameSelectItem = (componentProps) => {
        const code = 'deviceGroupName';
        const record: IDeviceGroupOptions = componentProps.record;
        const onChange = (value, option) => props.onDeviceGroupChange(value);
        const dataSource = record.deviceGroupName || [];
        const selected = dataSource.length > 0 ? dataSource[0].value : undefined;
        const form = React.useContext(EditableContext);
        React.useEffect(() => {
            onChange(selected, {});
            form.validateFields([code]);
        }, []);
        return (
            <SelectFormItem
                isRequired={true}
                onChange={onChange}
                code={code}
                label='Device group name'
                dataSource={dataSource}
                selectedValue={selected}
                placeholder='Device Group'
            />
        );
    };

    const indexesColumn = {
        code: 'indexes',
        title: '#',
        dataIndex: 'index',
    };

    const deviceGroupNameColumn: IEditableTableColumn = {
        code: 'deviceGroupName',
        title: 'Device group name',
        dataIndex: 'deviceGroupName',
        component: deviceGroupNameSelectItem,
    };

    const tableProps: IEditableTableProps = {
        columns: [indexesColumn, deviceGroupNameColumn],
        pagination: false,
        dataSource: tableDataSource?.map((item, index) => ({ index: index + 1, ...item })),
        className: wizardCss.table,
    };

    return (
        <React.Fragment>
            <AdvanceEditableTable title='Device group' loadingState={props.data.loading} table={tableProps} />
        </React.Fragment>
    );
};
