import * as React from 'react';

import {Error400} from './400';
import { Error401 } from './401';
import { Error404 } from './404';
import { Error500 } from './500';

export * from './base-error';

export const NETWORK_ERROR = (error: any): React.ReactNode => {

    if(!error) return null;

    switch (error.status) {
        case 400:
            return <Error400 message={error.message} />;
        case 401:
            return <Error401 message={error.message} />;
        case 404:
            return <Error404 message={error.message} />;
        default:
            return <Error500 message={error.message} />;
    }
};

export {
    Error400, Error401, Error404, Error500
};