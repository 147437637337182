import * as React from 'react';
import {
    getDayJsInstance,
    isDeploymentDateEnabled,
    isDeploymentRangeValid,
    setBeginningOfTheDay,
    setEndOfTheDay,
} from '../../../../common-utils/date-utils';
import { RangeDateTimePicker } from '../../../../ui-lib';
import { IDeviceTableProps } from '../../devices/devices-inventory/device-details';
import { DeviceDeploymentsStore } from '../../../../store/device-details/device-deployments-store';

interface IDeploymentsDateRangePickerProps extends IDeviceTableProps<DeviceDeploymentsStore> {
    range: number;
}

export const DeploymentsDateRangePicker: React.FC<IDeploymentsDateRangePickerProps> = ({
    range,
    store,
}) => {
    const [error, setError] = React.useState(false);
    const [minDate, setMinDate] = React.useState<Date>(store.filterByDateFrom);
    const [maxDate, setMaxDate] = React.useState<Date>(store.filterByDateTo);

    const today = new Date();

    const onCalendarChange = (range: 'start' | 'end', startDate: Date, endDate: Date): void => {
        if (range === 'start') {
            setMinDate(setBeginningOfTheDay(startDate));
        } else {
            setMaxDate(setEndOfTheDay(endDate));
        }
    };

    const onOpenChange = (isOpened: boolean): void => {
        if (isOpened) {
            return;
        }
        if (isDeploymentRangeValid(range, minDate, maxDate)) {
            setError(false);
            if (
                store.filterByDateFrom?.toDateString() !== minDate?.toDateString() ||
                store.filterByDateTo?.toDateString() !== maxDate?.toDateString()
            ) {
                store.setFilterByDateFrom(minDate);
                store.setFilterByDateTo(maxDate);
                store.fetchLatestData(true);
            }
        } else {
            setError(true);
        }
    };

    return (
        <RangeDateTimePicker
            disabledDate={(date): boolean => (
                !isDeploymentDateEnabled(date.toDate(), today, range, minDate, maxDate)
            )}
            disabled={store.loading}
            defaultValues={[getDayJsInstance(minDate), getDayJsInstance(maxDate)]}
            onCalendarChange={onCalendarChange}
            status={error ? 'error' : undefined}
            onOpenChange={onOpenChange}
        />
    );
};
