import * as React from 'react';
import { observer } from 'mobx-react';
import { DangerAlert, IconButton } from 'ui-lib';
import devicesCss from './devices.css';
import { useDeviceStore } from 'store';
import { getErrorMessage } from 'common-utils';

interface IDeviceScrInfoComponentProps {
    accountId: number;
    uid: string;
}

export const DeviceScrInfoComponent = observer((props: IDeviceScrInfoComponentProps) => {
    const devicesStore = useDeviceStore();
    const [scrInfo, setScrInfo] = React.useState<string>();
    const [error, setError] = React.useState<string>();
    React.useEffect(() => {
        loadScrInfo();
    }, []);

    const loadScrInfo = async () => {
        setScrInfo('Loading scr info......');
        try {
            const scrInfo = await devicesStore.getCurrentDeviceScrInfo(props.accountId, props.uid);
            setScrInfo(JSON.stringify(scrInfo, null, 2));
        } catch (e) {
            setError(getErrorMessage(e));
            setScrInfo(undefined);
        }
    };

    const ReloadComponent = (
        <div className={devicesCss['reload']}>
            <IconButton
                className={devicesCss['reload-btn']}
                iconName='arrow-rotate-right'
                onClick={() => loadScrInfo()}
            />
        </div>
    );

    return (
        <div className={devicesCss.tabPane}>
            {error && <DangerAlert closable={false} message='Error' description={error} />}
            <div className={devicesCss['reload']}>
                <IconButton
                    className={devicesCss['reload-btn']}
                    iconName='arrow-rotate-right'
                    onClick={() => loadScrInfo()}
                />
            </div>
            <pre>{scrInfo}</pre>
        </div>
    );
});
