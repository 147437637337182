import { ApiClientStore } from 'core';

import {
    AccountApiClient,
    DeploymentApiClient,
    DeviceGroupsApiClient,
    DevicesApiClient,
    DeploymentGroupsApiClient,
    RemoteCommandsAPiClient,
    PackagesApiClient,
    SnapshotsApiClient,
    PackageGroupsApiClient,
    KeyApiClient,
    RoleApiClient,
    PermissionApiClient,
    UserApiClient,
    ConfigurationApiClient,
    NotificationsApiClient,
} from '../api';

export interface IAssureAdminApiClientEndpoints {
    account: AccountApiClient;
    deployment: DeploymentApiClient;
    role: RoleApiClient;
    permission: PermissionApiClient;
    user: UserApiClient;
    devices: DevicesApiClient;
    devicegroups: DeviceGroupsApiClient;
    snapshots: SnapshotsApiClient;
    packages: PackagesApiClient;
    deploymentGroups: DeploymentGroupsApiClient;
    remoteCommand: RemoteCommandsAPiClient;
    packageGroups: PackageGroupsApiClient;
    key: KeyApiClient;
    configuration: ConfigurationApiClient;
    notifications: NotificationsApiClient;
}

export interface IServerConfig {
    host: string;
    port: number;
    prefix: string;
    isProduction: boolean;
    version?: string;
}

export const SERVER_CONFIG: IServerConfig = {
    host: __API_HOST || '',
    port: __API_PORT || null,
    prefix: __API_PREFIX || null,
    isProduction: __IS_PRODUCTION,
};

export class AssureAdminApiClientStore extends ApiClientStore<IAssureAdminApiClientEndpoints> {
    constructor() {
        super(SERVER_CONFIG);

        this.registerEndpoints([
            AccountApiClient,
            DeploymentApiClient,
            RoleApiClient,
            PermissionApiClient,
            UserApiClient,
            DevicesApiClient,
            DeviceGroupsApiClient,
            SnapshotsApiClient,
            PackagesApiClient,
            DeploymentGroupsApiClient,
            RemoteCommandsAPiClient,
            PackagesApiClient,
            PackageGroupsApiClient,
            KeyApiClient,
            ConfigurationApiClient,
            NotificationsApiClient,
        ]);
    }
}
