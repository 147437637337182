import * as React from 'react';
import { Input } from 'antd';
import { FormItem, getDefaultFormItemOptions, IFormItem } from './form-item';

export interface IEmailItemProps extends IFormItem {
    prefixOutline?: React.ReactNode;
    placeHolder?: any;
    value?: string;
    onChange?: (value: any) => void;
    onBlur?: React.FocusEventHandler;
}

export const EmailFieldItem = (props: IEmailItemProps) => {
    const formItemProps = getDefaultFormItemOptions(props);
    formItemProps.rules.push({ type: 'email', message: 'Must be a valid email address' });

    return (
        <FormItem
            code={props.code}
            {...formItemProps}
            child={
                <Input
                    autoComplete='new-email'
                    className={props.className}
                    value={props.value}
                    placeholder={props.placeHolder}
                    prefix={props.prefixOutline}
                    disabled={props.disabled}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                />
            }
        />
    );
};
