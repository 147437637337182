import { AdvanceTable, BasicTable, DangerAlert, IBasicTableColumn, IconTextButton, Modal, } from 'ui-lib';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
    DeviceDeploymentsStore,
    IDisplayDeploymentProps,
} from '../../../../store/device-details/device-deployments-store';
import { IDeviceTableProps } from './device-details';
import { useSnapshotStore } from 'store';
import { IPackageProps } from 'dto/packages-dto';
import devicesCss from './devices.css';
import { DEFAULT_DATE_RANGE_FOR_DEPLOYMENTS } from '../../../../common-utils/date-utils';
import { DeploymentsDateRangePicker } from '../../software/deployments/daployment-date-range-picker';
import { TDisplayPackageProps } from '../../../../store/packages-store';

interface IDeviceDeploymentsTableProps extends IDeviceTableProps<DeviceDeploymentsStore> {
    accountId: number;
}

export const DeviceDeploymentsTable = observer((props: IDeviceDeploymentsTableProps) => {
    const { store } = props;
    const snapshotStore = useSnapshotStore();

    const loading = React.useMemo(
        () => store.loading || snapshotStore.dataLoading,
        [snapshotStore.dataLoading, store.loading],
    );

    React.useEffect(() => {
        (async function loadData(): Promise<void> {
            await Promise.all([snapshotStore.loadEntities(props.accountId), store.initialize()]);
        })();
    }, []);

    const [modalVisible, setModalVisible] = React.useState<boolean>(false);
    const [modalSource, setModalSource] = React.useState<IDisplayDeploymentProps>();

    const handleCancel = () => {
        setModalVisible(false);
    };

    const collectData = (record: IDisplayDeploymentProps) => {
        setModalVisible(true);
        setModalSource(record);
    };

    const [packageModalVisible, setPackageModalVisible] = React.useState<boolean>(false);
    const [packages, setPackages] = React.useState<IPackageProps[]>([]);
    const handlePackageModalCancel = () => {
        setPackageModalVisible(false);
    };

    const collectPackageModalData = (snapshotId: number) => {
        setPackageModalVisible(true);
        setPackages(snapshotStore.getPackagesBySnapshotId(snapshotId, true));
    };

    const columns: IBasicTableColumn[] = [
        {
            code: 'jobName',
            title: 'Deployment Name',
            dataIndex: 'jobName',
            actions: [
                {
                    key: 'key',
                    onClick: (key: string) => collectData(store.displayDeployments.find((item) => item.key == key)),
                },
            ],
        },
        {
            code: 'snapshots',
            title: 'Releases',
            dataIndex: 'snapshots',
            render: (snapshots: any[], record: any) => {
                if (snapshots?.length === 0) return null;
                const separator = <p className={devicesCss.separator}>, </p>;
                return (
                    <div className={devicesCss.snapshots}>
                        {snapshots
                            ?.map((snapshot) => (
                                <IconTextButton
                                    className={devicesCss.snapshotNameButton}
                                    type="link"
                                    label={snapshot.label}
                                    onClick={() => collectPackageModalData(snapshot.id)}
                                    key={snapshot.id}
                                />
                            ))
                            .reduce(
                                (prev, current, index) =>
                                    index === 0 ? prev.concat(current) : prev.concat(separator, current),
                                [],
                            )}
                    </div>
                );
            },
        },
        {
            code: 'jobId',
            title: 'Job Id',
            dataIndex: 'jobId',
            render: (jobId: string) => (
                <span className={devicesCss.jobId}>{jobId}</span>
            ),
        },
        { code: 'status', title: 'Status', dataIndex: 'status' },
        { code: 'displayTimestamp', title: 'Created', dataIndex: 'displayTimestamp', width: '200px' },
    ];

    const PackagesModal = (): JSX.Element => {
        const columns: IBasicTableColumn[] = [
            {
                code: 'label',
                title: 'Package name',
                dataIndex: 'label',
                sorter: (a, b) => a.label.localeCompare(b.label),
            },
            { code: 'version', title: 'Version', dataIndex: 'version' },
            {
                code: 'architecture',
                title: 'Architecture',
                dataIndex: 'architecture',
                sorter: (a: TDisplayPackageProps, b: TDisplayPackageProps) => a.architecture?.localeCompare(b.architecture),
            },
        ];

        return (
            <Modal
                isVisible={packageModalVisible}
                isClosable={true}
                onCancel={handlePackageModalCancel}
                width={1000}
                extraProps={{ footer: null }}
            >
                <div className={devicesCss['log-content']}>
                    <BasicTable columns={columns} dataSource={packages} rowKey="id"/>
                </div>
            </Modal>
        );
    };

    const LogModal = () => {
        const columns: IBasicTableColumn[] = [
            { code: 'name', title: 'Name', dataIndex: 'name' },
            { code: 'error', title: 'Error', dataIndex: 'error' },
            { code: 'notice', title: 'Notice', dataIndex: 'notice' },
            { code: 'warning', title: 'Warning', dataIndex: 'warning' },
            { code: 'output', title: 'Output', dataIndex: 'output' },
        ];

        return (
            <Modal
                isVisible={modalVisible}
                isClosable={true}
                onCancel={handleCancel}
                width={1000}
                extraProps={{ footer: null }}
            >
                <div className={devicesCss['log-content']}>
                    <BasicTable
                        className={devicesCss.deploymentData}
                        columns={columns}
                        dataSource={modalSource?.deploymentLogDataRecords || []}
                        rowKey="name"
                    />
                </div>
            </Modal>
        );
    };

    return (
        <div className={devicesCss.tabPane}>
            {store.error != undefined && <DangerAlert closable={false} message="Error" description={store.error}/>}
            <AdvanceTable
                title=""
                table={{
                    dataSource: store.displayDeployments,
                    columns: columns,
                }}
                loadingState={loading}
                toolbar={{
                    onReload: () => store.fetchLatestData(),
                    extraItems: [
                        <DeploymentsDateRangePicker
                            key="deployments-date-range-picker"
                            range={DEFAULT_DATE_RANGE_FOR_DEPLOYMENTS}
                            store={store}
                        />,
                    ],
                }}
            />
            <PackagesModal/>
            <LogModal/>
        </div>
    );
});
