import { action, computed, makeObservable, observable } from 'mobx';
import { JobMapping } from '../../pages/private/devices/devices-inventory/utils';
import { formatDate } from 'common-utils/date-utils';
import { DeviceDetailEntryStore, IDeviceDetailsConstructorOptions } from './device-detail-entry-store';
import {
    addOffsetToTheDate,
    addTzToDate,
    DEFAULT_DATE_RANGE_FOR_DEPLOYMENTS,
    setBeginningOfTheDay,
    setEndOfTheDay,
} from '../../common-utils/date-utils';
import { MAX_RETRIES_WHEN_SERVICE_UNAVAILABLE } from '../../common-utils/deployment-utils';

enum DEPLOYMENT_STATUS_DETAIL {
    success = 'SUCCESS',
    downloaded = 'DOWNLOADED',
    failure = 'ERROR',
}

export interface IProcessProps {
    device_id: string;
    install_result: any[];
    job_id: string;
    packages: any[];
    remove_result: any[];
    sourcelists: any[];
    type: string;
    update_result: any[];
}

export interface IDisplayDeploymentProps
    extends Omit<IOuterProcessListingProps, 'software_updates_listing' | 'snapshots'> {
    key: string;
    jobId: string;
    jobName: string;
    status: string;
    displayTimestamp: string;
    deploymentLogDataRecords: IDeploymentDataRecord[];
    snapshots: any[];
}

export interface IOuterProcessListingProps {
    jobName: string;
    timestamp: string;
    software_updates_listing: IProcessProps;
    snapshots?: any[];
}

export interface IDeploymentDataRecord {
    name: string;
    error: string;
    notice: string;
    output: string;
    warning: string;
}

export class DeviceDeploymentsStore extends DeviceDetailEntryStore<IOuterProcessListingProps> {
    constructor(options: IDeviceDetailsConstructorOptions) {
        super(options);
        makeObservable(this);
    }

    @observable
    private _filterByDateFrom: Date;

    get filterByDateFrom(): Date {
        return setBeginningOfTheDay(
            this._filterByDateFrom
            || addOffsetToTheDate(new Date(), -DEFAULT_DATE_RANGE_FOR_DEPLOYMENTS + 1)
        );
    }

    @observable
    private _filterByDateTo: Date;

    get filterByDateTo(): Date {
        return setEndOfTheDay(this._filterByDateTo || new Date());
    }

    /** *************************************/
    @computed
    public get displayDeployments(): IDisplayDeploymentProps[] {
        if (!this.entities) return [];
        return this.entities.map<IDisplayDeploymentProps>((item) => {
            const displayedDeploymentDataResult: IDeploymentDataRecord[] = [];
            const parseResult = (name: string, result: any): IDeploymentDataRecord => ({
                name,
                error: result.error?.join('\n'),
                notice: result.notice?.join('\n'),
                warning: result.warning?.join('\n'),
                output: result.output.replace(/(?:\r)/g, '\n'),
            });

            item?.software_updates_listing?.install_result && displayedDeploymentDataResult.push(parseResult('install', item.software_updates_listing.install_result));
            item?.software_updates_listing?.remove_result && displayedDeploymentDataResult.push(parseResult('remove', item.software_updates_listing.remove_result));
            item?.software_updates_listing?.update_result && displayedDeploymentDataResult.push(parseResult('update', item.software_updates_listing.update_result));

            return {
                key: `${item.software_updates_listing.job_id}-${item.timestamp}`,
                jobName: item.jobName ? item.jobName : JobMapping.software_update,
                jobId: item.software_updates_listing.job_id,
                status: DEPLOYMENT_STATUS_DETAIL[item.software_updates_listing.type] || DEPLOYMENT_STATUS_DETAIL.failure,
                displayTimestamp: formatDate(item.timestamp),
                timestamp: item.timestamp,
                deploymentLogDataRecords: displayedDeploymentDataResult,
                snapshots: item.snapshots,
            };
        }).sort((prev, next) => (
            prev.timestamp < next.timestamp ? 1 : prev.timestamp > next.timestamp ? -1 : 0
        ));
    }

    @action
    setFilterByDateFrom(filterByDateFrom: Date) {
        this._filterByDateFrom = filterByDateFrom;
    }

    @action
    setFilterByDateTo(filterByDateTo: Date) {
        this._filterByDateTo = filterByDateTo;
    }

    /** ************************************
     * Override functions
     **************************************/
    protected name(): string {
        return 'deployment';
    }

    protected async initializeData(retryTimes = 0): Promise<any[]> {
        if (retryTimes > MAX_RETRIES_WHEN_SERVICE_UNAVAILABLE) {
            throw new Error('Service is unavailable at the moment. Please try to refresh the page later.');
        }
        try {
            return await this._devicesStore.getDeviceSoftwareUpdates(
                this.accountId,
                this.uid,
                addTzToDate(this.filterByDateFrom).toISOString(),
                addTzToDate(this.filterByDateTo).toISOString(),
            );
        } catch (error) {
            if (typeof error !== 'string' && error?.message.toLowerCase().includes('service unavailable')) {
                return this.initializeData(++retryTimes);
            }
            throw error;
        }
    }
}
