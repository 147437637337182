import React from 'react';
import cx from 'classnames';

import { Modal, Select } from 'ui-lib';
import { useAccountStore, useAuthStore } from '../../../../store';

import modalCss from './var-select-modal.css';

export interface IVARSelectModalProps {
    className?: string;
    onChange: (value: any) => void;
}

export function VARSelectModal(props: IVARSelectModalProps) {
    const accountStore = useAccountStore();
    const { currentUser } = useAuthStore();

    return (
        <Modal
            className={cx(props.className, modalCss['var-select-modal'])}
            isVisible={true}
            title='Select VAR Account'
        >
            <Select
                placeholder='Select VAR account'
                dataSource={accountStore.getVARAccountDataSource(currentUser.accountType)}
                onChange={props.onChange}
            />
        </Modal>
    );
}
