import React from 'react';

import { FormItem, getDefaultFormItemOptions, IconTextButton, IFormItem, IIconTextButtonProps } from 'ui-lib';

export interface IIconTextButtonFormProps extends IFormItem {
    buttonProps: IIconTextButtonProps;
}

export const IconTextButtonFormItem = (props: IIconTextButtonFormProps) => {
    const formItemProps = getDefaultFormItemOptions(props);
    return (
        <FormItem
            code={props.code}
            labelAlign="right"
            {...formItemProps}
            child={<IconTextButton {...props.buttonProps} />}
        />
    );
};

export interface IInputRule {
    pattern: RegExp;
    message: React.ReactNode;
}

const SYMBOLS = {
    'SPACE': { label: 'space', regPattern: ' ', special: false },
    'APOSTROPHE': { label: '\'', regPattern: '\'', special: false },
    'MINUS': { label: '-', regPattern: '\\-', special: true },
    'UNDERSCORE': { label: '_', regPattern: '_', special: true },
} as const;

type TSymbol = keyof typeof SYMBOLS;

export const inputLabelRule = (length: number): IInputRule => {
    return inputAlphanumericRule(length, ['MINUS', 'SPACE', 'UNDERSCORE']);
};

export const inputAlphanumericRule = (length: number, acceptedSymbols: TSymbol[] = []): IInputRule => {
    const sorted = Object.keys(SYMBOLS).filter((item: TSymbol) => acceptedSymbols.includes(item));
    const isLast = (index: number): boolean => index === sorted.length - 1;

    const [pattern, message] = sorted.reduce(
        (rule, value, index) => {
            const symbol = SYMBOLS[value];
            if (!symbol) {
                return rule;
            }

            const displayChar = symbol.special ? `"${symbol.label}"` : symbol.label;
            if (!isLast(index)) {
                rule[1] += `, ${displayChar}`;
            } else {
                rule[1] += ` and ${displayChar} characters`;
            }
            rule[0] += symbol.regPattern;
            return rule;
        },
        ['0-9A-Za-z', 'Accepted characters are letters, numbers'],
    );

    return buildInputRule(
        `(?=.*[A-Za-z0-9])^([${pattern}])`,
        { max: length },
        `${message}. At least one alphanumeric character should be present.`,
    );
};

export const maxLengthRule = (length: number): IInputRule => {
    return buildInputRule(
        `.{1,${length}}`,
        {},
        `Please enter not more than ${length} symbols`,
        false,
    );
};

export const buildInputRule = (
    pattern: string,
    lengthOptions: {
        min?: number,
        max?: number
    },
    message: string,
    useLengthRule = true
): IInputRule => {
    let lengthMessage = '';
    if (lengthOptions.min && lengthOptions.max) {
        lengthMessage = `Length must be between ${lengthOptions.min} and ${lengthOptions.max} characters.`;
    } else if (lengthOptions.min) {
        lengthMessage = `At least ${lengthOptions.min} characters.`;
    } else if (lengthOptions.max) {
        lengthMessage = `Max length is ${lengthOptions.max} characters.`;
    }
    const lengthRule = useLengthRule ? `{${lengthOptions.min ?? 1},${lengthOptions.max ?? ''}}` : '';
    return {
        pattern: new RegExp(`^(${pattern}${lengthRule})$`),
        message: (
            <React.Fragment>
                <p style={{ margin: '0px' }}>{message}</p>
                {lengthMessage && <p>{lengthMessage}</p>}
            </React.Fragment>
        ),
    };
};
