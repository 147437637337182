import * as React from 'react';
import { Collapse } from 'antd';
import { CollapsibleType } from 'antd/lib/collapse/CollapsePanel';

const { Panel } = Collapse;
export interface PanelProps {
    isActive?: boolean;
    header?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    showArrow?: boolean;
    forceRender?: boolean;
    /** @deprecated Use `collapsible="disabled"` instead */
    disabled?: boolean;
    extra?: React.ReactNode;
    collapsible?: CollapsibleType;
}
interface IBasicCollapse {
    defaultActiveKey?: string[];
    ghost?: boolean;
    panels: { header: any; key: string; content: any; extra?: React.ReactNode}[];
    className?: string;
    expandIconPosition?: 'start' | 'end';
    expandIcon?: (panelProps: PanelProps) => React.ReactNode;
    onChange?: (key: string | string[]) => void;
}

export function BasicCollapse(props: IBasicCollapse) {
    const { panels, defaultActiveKey, className } = props;
    return (
        <Collapse
            ghost
            className={className}
            defaultActiveKey={defaultActiveKey}
            expandIconPosition={props.expandIconPosition}
            expandIcon={props.expandIcon}
            onChange={props.onChange}
        >
            {panels.map((panel) => (
                <Panel header={panel.header} key={panel.key} extra={panel.extra}>
                    {panel.content}
                </Panel>
            ))}
        </Collapse>
    );
}
