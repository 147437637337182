import * as React from 'react';

import {
    BasicForm,
    IBasicFormProps,
    EmailFieldItem,
    IconTextButton,
    RowLayout,
    Icon,
    showInformationDialog,
    notification,
} from 'ui-lib';

import formCss from '../login.css';
import { useAuthStore } from '../../../../store';
import { FORGOT_PASSWORD_STATE } from './forgot-password';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from 'common-utils';

export interface ICheckUserExistingFormProps extends Omit<IBasicFormProps, 'items'> {
    onUpdate: (options: FORGOT_PASSWORD_STATE) => void;
    setEmail: (email: string) => void;
}

export function CheckUserExistingForm(props: ICheckUserExistingFormProps): JSX.Element {
    const authStore = useAuthStore();
    const navigate = useNavigate();

    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

    const onCheckUserExistingHandler = async (values): Promise<void> => {
        try {
            setIsProcessing(true);
            await authStore.forgotPassword(values.email);
            notification['success']({
                placement: 'topRight',
                message: 'Code has been sent',
            });

            props.setEmail(values.email);
            props.onUpdate(FORGOT_PASSWORD_STATE.CONFIRM_VERIFY_CODE);
        } catch (error) {
            showInformationDialog({
                modalType: 'error',
                title: 'Error',
                content: getErrorMessage(error),
            });
        } finally {
            setIsProcessing(false);
        }
    };

    const ButtonsRow = (): JSX.Element => {
        const CancelButton = (
            <IconTextButton
                className={formCss.button}
                label={'Cancel'}
                type={'primary'}
                block={true}
                htmlType={'button'}
                onClick={(): void => navigate('/')}
            />
        );
        const SendCodeButton = (
            <IconTextButton
                className={formCss.button}
                label={'Send code'}
                type={'primary'}
                block={true}
                htmlType={'submit'}
                loading={isProcessing}
            />
        );

        return <RowLayout childItems={[CancelButton, SendCodeButton]} childCols={[6, 6]} childOffsets={[11, 1]} />;
    };
    return (
        <BasicForm
            title={props.title}
            items={[
                <EmailFieldItem
                    key='email'
                    code='email'
                    label='Email'
                    isRequired={true}
                    className={formCss.label}
                    placeHolder={'email'}
                    prefixOutline={<Icon iconName='user' size='SMALL' />}
                />,
                <ButtonsRow key={'buttonsRow'} />,
            ]}
            className={formCss.userExistingForm}
            onSubmit={onCheckUserExistingHandler}
            onError={props.onError}
        />
    );
}
