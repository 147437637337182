import React from 'react';
import { ITreeSelectDataSource, ITreeSelectProps, TreeSelect } from '../../select/tree-select';
import { FormItem, getDefaultFormItemOptions, IFormItem } from './form-item';
export interface ITreeSelectItemProps extends IFormItem {
    selectProps: ITreeSelectProps;
    selectedValue?: ITreeSelectDataSource[];
}

export const TreeSelectItem = (props: ITreeSelectItemProps) => {
    const formItemProps = getDefaultFormItemOptions(props);
    return <FormItem code={props.code} {...formItemProps} child={<TreeSelect {...props.selectProps} />} />;
};
