import * as React from 'react';
import cx from 'classnames';
import { Typography } from 'antd';
import { AdvanceTable, IAdvanceTableProps } from '../components';
import listingPageCss from './listing-page.css';
import { isString } from 'lodash';
export interface ITableToolbarProps {
    searchPlaceholder?: string;
    onSearch?: (searchText: string) => void;
    onAdd?: (obj?: any) => void;
    onReload?: () => void;
}

export interface IListingPageProps {
    className?: string;
    title?: React.ReactNode | string;
    tableProps: IAdvanceTableProps;
}

export function TableListingPage(props: IListingPageProps) {
    return (
        <div className={cx(listingPageCss.listingPage, props.className)}>
            <div className={listingPageCss.title}>
                {isString(props.title) ? <Typography.Title level={2}>{props.title}</Typography.Title> : props.title}
            </div>
            <AdvanceTable {...props.tableProps} />
        </div>
    );
}
