import { action, computed, override } from 'mobx';
import { ITreeSelectDataSource as ITreeSelectDataSourceLib } from 'ui-lib';

import { AccountApiClient } from '../api';
// eslint-disable-next-line no-duplicate-imports
import type { IAccount } from '../dto/access-management/account-dto';
import { AccountType, IEditSubAccountOptions, ISaveAccountOptions, } from '../dto/access-management/account-dto';
import { IEnvironment } from '../dto/access-management/environment-dto';
import { AssureBaseStore, IAssureStoreConstructorOptions, SelectDataSource } from './assure-base.store';
import { getErrorMessage } from 'common-utils';

export type ITreeSelectDataSource = ITreeSelectDataSourceLib;

interface IDisplayUserOptions {
    id: number;
    code: string;
    key: number;
    name: string;
    type: AccountType;
    displayEnvironmentNames: string;
}

export class AccountStore extends AssureBaseStore<AccountApiClient, IAccount> {
    public constructor(options: IAssureStoreConstructorOptions) {
        super(options);
    }

    @computed
    public get managedAccounts(): IAccount[] {
        if (!this.hasEntities()) return [];
        return this.entities;
    }

    @computed
    public get treeDataSource(): ITreeSelectDataSource {
        return this._buildTreeDataSource(this.accountHierarchy);
    }

    @computed
    public get accountHierarchy(): IAccount {
        if (!this.hasEntities()) return undefined;
        const [root, ...accounts] = this.entities;
        const accountHierarchy = this._buildHierarchy(
            JSON.parse(JSON.stringify(root)),
            JSON.parse(JSON.stringify(accounts)),
        );
        return accountHierarchy;
    }

    @computed
    public get displayAccounts(): IDisplayUserOptions[] {
        if (!this.hasEntities()) return [];
        return this.entities
            .map((account) => {
                return {
                    id: account.id,
                    code: account.code,
                    key: account.id,
                    name: account.name,
                    type: account.accountType,
                    displayEnvironmentNames: this._buildEnvironments(account.environments)
                        .map((env) => env.label)
                        .join(', '),
                };
            })
            .sort((a: IDisplayUserOptions, b: IDisplayUserOptions) => b.id - a.id);
    }

    protected get apiClient(): AccountApiClient {
        return this.apiClientStore.apiClients.account;
    }

    public async getAccounts(force = false): Promise<IAccount[]> {
        if (!force && this.hasEntities()) return this.entities;
        try {
            this.clearEntities();
            this.setDataLoading(true);
            const accounts = await this.apiClient.getAccounts();
            const orderedSubAccounts = accounts.map((account) => ({
                ...account,
                subAccounts: account.subAccounts?.sort((a, b) => a.name.localeCompare(b.name)),
            }));
            this.setEntities(orderedSubAccounts);
            return this.entities;
        } catch (err) {
            this.setError(getErrorMessage(err));
        } finally {
            this.setDataLoading(false);
        }
    }

    public findParentAccount(id: number): IAccount {
        if (!this.hasEntities()) return undefined;
        return this.entities.find((account) => account.subAccounts.map((subAccount) => subAccount.id).includes(id));
    }

    public getParentAccount(subId: number): IAccount {
        const found = this.findParentAccount(subId);
        if (!found) return undefined;
        return this.getAccountById(found.id);
    }

    public async deleteAccount(id: number): Promise<boolean> {
        const deleted = await this.apiClient.deleteAccount(id);
        if (deleted) {
            this.removeEntity(id);
        }
        return deleted;
    }

    @override
    public addEntity(account: IAccount) {
        super.addEntity(account);
        const parentAccount = this.findAccount(account.parent.id);
        if (!parentAccount) return;
        // Re-update to parent account
        super.updateEntity<Pick<IAccount, 'subAccounts'>>(parentAccount.id, {
            subAccounts: [...parentAccount.subAccounts, account].sort((a, b) => a.name.localeCompare(b.name))
        });
    }

    @override
    public removeEntity(id: number) {
        super.removeEntity(id);
        const parentAccount = this.findParentAccount(id);
        if (!parentAccount) return;
        // Re-update to parent account
        super.updateEntity<Pick<IAccount, 'subAccounts'>>(parentAccount.id, {
            subAccounts: parentAccount.subAccounts.filter(sub => sub.id != id)
        });
    }

    public async saveAccount(id: number, accountInfo: ISaveAccountOptions): Promise<IAccount> {
        if (!id) {
            const accountId = await this.apiClient.createAccount(accountInfo);
            const createdAccount = await this.apiClient.getAccountById(accountId);
            this.addEntity(createdAccount);
            return createdAccount;
        }
        await this.apiClient.editAccount(id, accountInfo);
        const updatedAccount = await this.apiClient.getAccountById(id);
        this.updateEntity<IAccount>(id, updatedAccount);
        return updatedAccount;
    }

    public getVARAccountDataSource(currentAccountType: AccountType | string): SelectDataSource[] {
        if (!this.accountHierarchy || currentAccountType === AccountType.CLIENT) return [];
        if (currentAccountType === AccountType.DPU)
            return this.accountHierarchy.subAccounts?.map((account) => ({
                value: account.id,
                label: account.name,
            }));

        return [
            {
                value: this.accountHierarchy.id,
                label: this.accountHierarchy.name,
            },
        ];
    }

    public getAccountById(accountId: number): IAccount {
        if (!this.hasEntities()) return undefined;
        return this.entities.find((account) => account.id === accountId);
    }

    public getAccountByUuid(accountUuid: string): IAccount {
        if (!this.hasEntities()) return undefined;
        return this.entities.find((account) => account.uuid === accountUuid);
    }

    public getAccountByUuidOrParentUuidForClientAccount(accountUuid: string): IAccount {
        if (!this.hasEntities()) return undefined;
        return this.entities.find((account) => (
            account.accountType === AccountType.CLIENT ? (
                account.uuid === accountUuid || account.parent.uuid === accountUuid
            ) : (
                account.uuid === accountUuid
            )
        ));
    }

    @action
    public getSelectableTreeDataSource(
        viewableTypes: AccountType[],
        selectableTypes: AccountType[],
        root: IAccount = this.accountHierarchy,
    ): ITreeSelectDataSource {
        return this._buildTreeDataSource(root, viewableTypes, selectableTypes);
    }

    public getSubAccount(type: string): IEditSubAccountOptions[] {
        if (!this.hasEntities()) return [];
        return this.entities
            .filter((account) => account.accountType === type)
            .map((account) => {
                return {
                    id: account.id,
                    code: account.code,
                    type: account.accountType,
                    value: account.name,
                    label: account.name,
                };
            });
    }

    public findAccount(id: number, account: IAccount = this.accountHierarchy): IAccount {
        if (account == undefined) return undefined;

        if (id == account.id) return account;

        const subs = account.subAccounts;
        if (!subs || subs.length == 0) return undefined;

        for (let i = 0; i < subs.length; i++) {
            const matchedAccount = this.findAccount(id, subs[i]);
            if (matchedAccount) return matchedAccount;
        }
    }

    public getClientsForAccount(accountId: number): IAccount[] {
        const account = this.getAccountById(accountId);
        if (!account) return [];

        switch (account.accountType) {
            case AccountType.CLIENT:
                return [account];
            case AccountType.VAR:
                return account.subAccounts;
            default:
                return [];
        }
    }

    public getVarForAccount(accountId: number): IAccount {
        const account = this.getAccountById(accountId);
        if (!account) return undefined;
        switch (account.accountType) {
            case AccountType.CLIENT:
                return this.getAccountById(account.parent.id);
            case AccountType.VAR:
                return account;
            default:
                return undefined;
        }
    }

    @override
    protected updateEntity<T>(id: number, updatedAccount: T) {
        super.updateEntity<T>(id, updatedAccount);
        const parentAccount = this.findParentAccount(id);
        if (!parentAccount) return;
        // Re-update to parent account
        const sub = parentAccount.subAccounts.find(item => item.id == id);
        Object.assign(sub, updatedAccount);
        super.updateEntity<Pick<IAccount, 'subAccounts'>>(parentAccount.id, {
            subAccounts: [...parentAccount.subAccounts.sort((a, b) => a.name.localeCompare(b.name))]
        });
    }

    private _buildEnvironments(environments: IEnvironment[]): any[] {
        const envs = [];
        for (let i = 0; i < environments.length; i++) {
            const env = environments[i];
            envs[envs.length] = { id: env.id, label: env.environmentStage };
        }
        return envs;
    }

    private _buildTreeDataSource(
        root: IAccount,
        accountTypes?: AccountType[],
        selectableTypes?: AccountType[],
    ): ITreeSelectDataSource {
        if (!root || (accountTypes !== undefined && !accountTypes.includes(root.accountType))) return undefined;
        const types = selectableTypes ? selectableTypes : Object.values(AccountType);
        const value = { value: root.id, label: root.name, disabled: !types.includes(root.accountType) };
        const child = root.subAccounts
            ?.map((sub) => this._buildTreeDataSource(sub, accountTypes, types))
            .filter((sub) => !!sub);
        if (child == undefined || child.length == 0) return { treeDataNode: value };
        return { treeDataNode: value, subTreeDataNode: child };
    }

    private _buildHierarchy(root: IAccount, accounts: IAccount[]): IAccount {
        const child = root?.subAccounts?.map((sub) => {
            const rootSub = accounts.find((account) => account.id == sub.id);
            const index = accounts.indexOf(rootSub);
            if (index > -1) {
                accounts.splice(index, 1);
            }
            return this._buildHierarchy(rootSub, accounts);
        });
        if (child == undefined || child.length == 0) return root;
        return { ...root, subAccounts: child };
    }
}
