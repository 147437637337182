import React, { useEffect, useState } from 'react';
import { FormItem, getDefaultFormItemOptions, IFormItem, TreeSelect } from 'ui-lib';

export enum AvailablePsp {
    LittlePay = 'LittlePay',
    Pulse = 'Pulse',
    Unicard = 'Unicard',
    Vault = 'Vault',
}

interface PspSelectProps extends IFormItem {
    code: string;
    values: AvailablePsp[];
    onChange: (values: AvailablePsp[]) => void;
}

const getDataSource = (values: AvailablePsp[]) => {
    return Object.values(AvailablePsp).map(psp => ({
        treeDataNode: {
            value: psp,
            label: psp,
            disabled: (
                (values.includes(AvailablePsp.LittlePay) && psp !== AvailablePsp.LittlePay) ||
                (values.length > 1 && !values.includes(psp)) ||
                (values.length === 1 && [AvailablePsp.Vault, AvailablePsp.Unicard].includes(values[0]) && ![AvailablePsp.Pulse, values[0]].includes(psp)) ||
                (values.length === 1 && [AvailablePsp.Pulse].includes(values[0]) && psp === AvailablePsp.LittlePay)
            ),
        },
    }));
};

export const PspSelect: React.FC<PspSelectProps> = ({
    onChange,
    values,
    ...restProps
}) => {
    const formItemProps = getDefaultFormItemOptions(restProps);
    const [dataSource, setDataSource] = useState(getDataSource(values));

    useEffect(() => {
        setDataSource(getDataSource(values));
    }, [values]);

    return (
        <FormItem
            code={restProps.code}
            {...formItemProps}
            initialValue={values}
            child={
                <TreeSelect
                    key="psp"
                    placeholder="PSP"
                    dataSource={dataSource}
                    onChange={onChange}
                    treeCheckable
                    allowClear
                    multiple
                />
            }
        />
    );
};
