import React from 'react';
import { observer } from 'mobx-react';
import packagesCss from './packages.css';

import {
    AdvanceTable,
    BasicCard,
    BasicForm,
    FormItem,
    IBasicTableColumn,
    IBasicTableProps,
    IconTextButton,
    IIconTextButtonProps,
    LabelFieldItem,
    Typography,
} from 'ui-lib';

import { useSnapshotStore } from '../../../../store';
import { IPackageProps, PACKAGE_ARCH } from '../../../../dto/packages-dto';
import { formatDate } from 'common-utils/date-utils';
import { AVAILABLE_PACKAGE_TYPES } from '.';
import { showPackageDetailsModal } from '../../components/package-modal-components';
import { ISnapshotProps, METAPACKAGE_TYPE, METAPACKAGE_TYPES } from 'dto/software-snapshot-dto';
import { DescriptionTextComponent } from '../repository/package-utils';

export interface IViewSnapshotPackageProps {
    title?: string;
    loadingState?: boolean;
    snapshotId: number;
    cancelButtonProps: IIconTextButtonProps;
}

interface IReleaseDetailsProps {
    packages: IPackageProps[];
    basedRelease?: ISnapshotProps;
    almSnapshot?: ISnapshotProps;
    vosSnapshot?: ISnapshotProps;
    scrSnapshot?: ISnapshotProps;
    filterPackageTypes?: string[];
    isDPUSnapshot?: boolean;
}

export const ViewSnapshotPackageComponent = observer((props: IViewSnapshotPackageProps) => {
    const snapshotStore = useSnapshotStore();

    const snapshot = React.useMemo(() => snapshotStore.getSnapshot(props.snapshotId), [props.snapshotId]);

    const releaseDetails = React.useMemo<IReleaseDetailsProps>(() => {
        const dependencies = snapshot.dependencies;
        const details: IReleaseDetailsProps = {
            packages: snapshotStore.getPackagesBySnapshotId(snapshot.id, false),
            basedRelease: snapshotStore.getSnapshot(snapshot.basedReleaseId),
            isDPUSnapshot: METAPACKAGE_TYPE[snapshot.type],
            filterPackageTypes: METAPACKAGE_TYPE[snapshot.type] ? METAPACKAGE_TYPES : AVAILABLE_PACKAGE_TYPES,
        };
        if (!dependencies) return details;

        return {
            ...details,
            packages: snapshotStore.getPackagesBySnapshotId(snapshot.id, false),
            almSnapshot: snapshotStore.getSnapshot(dependencies.almReleaseId),
            vosSnapshot: snapshotStore.getSnapshot(dependencies.vosReleaseId),
            scrSnapshot: snapshotStore.getSnapshot(dependencies.scrReleaseId),
        };
    }, [snapshot]);

    const snapshotPackagesViewColumns: IBasicTableColumn[] = [
        {
            code: 'label',
            title: 'Package name',
            dataIndex: 'label',
            width: '35%',
            sorter: (a: IPackageProps, b: IPackageProps) => a.label.localeCompare(b.label),
        },
        {
            code: 'version',
            title: 'Version',
            dataIndex: 'version',
            width: '20%',
        },
        {
            code: 'architecture',
            title: 'Architecture',
            dataIndex: 'architecture',
            width: '10%',
            sorter: (a: IPackageProps, b: IPackageProps) => a.architecture.localeCompare(b.architecture),
            filters: Object.keys(PACKAGE_ARCH).map((arch) => ({ text: PACKAGE_ARCH[arch], value: PACKAGE_ARCH[arch] })),
            onFilter: (architecture, pkg) => pkg.architecture == architecture,
        },
        {
            code: 'type',
            title: 'Type',
            dataIndex: 'type',
            width: '15%',
            filters: Object.keys(releaseDetails.filterPackageTypes).map((type) => ({
                text: releaseDetails.filterPackageTypes[type],
                value: releaseDetails.filterPackageTypes[type]
            })),
            onFilter: (type, pkg) => pkg.type == type,
            sorter: (a: IPackageProps, b: IPackageProps) => a.type.localeCompare(b.type),
        },
        {
            code: 'createdAt',
            title: 'Created date',
            dataIndex: 'createdAt',
            defaultSortOrder: 'descend',
            sorter: (a: IPackageProps, b: IPackageProps) => a.createdAt.localeCompare(b.createdAt),
            render: (_, record) => formatDate(record.createdAt),
        },
    ];

    const snapshotPackagesViewTable: IBasicTableProps = {
        columns: snapshotPackagesViewColumns,
        dataSource: releaseDetails.packages,
    };

    const onReleaseClick = (release: ISnapshotProps): void => {
        const packages = snapshotStore.getPackagesBySnapshotId(release?.id);
        return showPackageDetailsModal(packages, release?.description);
    };

    const buildReleaseLabel = (releaseLabel: string, releaseVersion: string): string => {
        const label = releaseLabel ? releaseLabel : 'N/A';
        const version = releaseVersion ? releaseVersion : 'N/A';

        return (label === 'N/A') ? 'N/A' : `${label} - ${version}`;
    };

    const releaseComponents = !releaseDetails.isDPUSnapshot ? [
        <FormItem label="Based on" code="basedReleaseLabel" labelAlign="right" labelCol={{ span: 5 }}
                  child={<Typography.Link
                      onClick={(): unknown => onReleaseClick(releaseDetails.basedRelease)}>{releaseDetails.basedRelease?.label ? releaseDetails.basedRelease?.label : 'N/A'}</Typography.Link>}/>,
        <FormItem label="ALM Release" code="almSnapshot" labelAlign="right" labelCol={{ span: 5 }}
                  child={
                      <div>
                          <Typography.Link
                          onClick={(): unknown => onReleaseClick(releaseDetails.almSnapshot)}>{releaseDetails.almSnapshot?.version}
                        </Typography.Link>
                        <Typography.Text
                            key="description"
                            className={packagesCss['description-center']}> {releaseDetails.almSnapshot?.description ? releaseDetails.almSnapshot.description : buildReleaseLabel(releaseDetails.almSnapshot?.label, releaseDetails.almSnapshot?.version)}
                        </Typography.Text>
                     </div>
        }/>,
        <FormItem label="VOS Release" code="vosSnapshot" labelAlign="right" labelCol={{ span: 5 }}
                  child={
                      <div>
                          <Typography.Link
                              onClick={(): unknown => onReleaseClick(releaseDetails.vosSnapshot)}>{releaseDetails.vosSnapshot?.version}
                          </Typography.Link>
                          <Typography.Text
                              key="description"
                              className={packagesCss['description-center']}> {releaseDetails.vosSnapshot?.description ? releaseDetails.vosSnapshot.description : buildReleaseLabel(releaseDetails.vosSnapshot?.label, releaseDetails.vosSnapshot?.version)}
                          </Typography.Text>
                      </div>
                  } />,
        <FormItem label="SCR Release" code="scrSnapshot" labelAlign="right" labelCol={{ span: 5 }}
                  child={
                      <div>
                          <Typography.Link
                              onClick={(): unknown => onReleaseClick(releaseDetails.scrSnapshot)}>{releaseDetails.scrSnapshot?.version}
                          </Typography.Link>
                          <Typography.Text
                              key="description"
                              className={packagesCss['description-center']}> {releaseDetails.scrSnapshot?.description ? releaseDetails.scrSnapshot.description : buildReleaseLabel(releaseDetails.scrSnapshot?.label, releaseDetails.scrSnapshot?.version)}
                        </Typography.Text>
                    </div>
                }/>,
    ] : [];

    return (
        <BasicCard
            className={packagesCss.createdView}
            title={props.title}
            loading={props.loadingState}
            bordered={true}
            contents={
                <React.Fragment>
                    <BasicForm
                        cardPros={{ bordered: false }}
                        items={[
                            <LabelFieldItem code="createdName" label="Name:" labelAlign="right"
                                            initialValue={snapshot.label}/>,
                            <LabelFieldItem code="description" label="Description:" labelAlign="right"
                                            initialValue={<DescriptionTextComponent
                                                description={snapshot.description}/>}/>,
                            ...releaseComponents,
                            <AdvanceTable title="Selected packages" table={snapshotPackagesViewTable}/>,
                        ]}
                    />
                    <IconTextButton className={packagesCss.backButton} {...props.cancelButtonProps} />
                </React.Fragment>
            }
        />
    );
});
