import * as React from 'react';
import cx from 'classnames';
import { Progress as AntProgress } from 'antd';
import progressCss from './progress.css';

export interface IProgressProps {
    className?: string;
    type?: 'line' | 'circle' | 'dashboard';
    percent: number;
    extraProps?: any
}

export function Progress(props: IProgressProps) {
    return (<AntProgress className={cx(progressCss.progress, props.className)}
        type={props.type}
        percent={props.percent}
        {...props.extraProps} />);
}