/* eslint-disable no-await-in-loop */
import * as React from 'react';
import { IAdvanceTableToolbarProps, IBasicTableColumn, TableListingPage } from 'ui-lib';
import { getAssignDeviceColumns } from './assign-devices';
import { IImportDeviceProps, ImportDeviceStatus } from 'dto/device-dto';
import { localeCompare } from 'common-utils/string-utils';

interface IAssignDeviceSummaryProps {
    clientAccountId: number;
    header?: React.ReactNode | string;
    title?: string;
    dataSource?: IImportDeviceProps[];
    extraItems?: React.ReactNode[];
    statusColumn: {
        filterKeys: string[];
        renderTag: (status: string) => React.ReactNode;
    };
}

export const AssignDeviceSummaryTable = (props: IAssignDeviceSummaryProps): JSX.Element => {
    const { clientAccountId, header, title, dataSource, extraItems, statusColumn } = props;
    const columns: IBasicTableColumn[] = [
        ...getAssignDeviceColumns({ accountId: clientAccountId, includeESNColumn: true, includeSerialNumberColumn: true }),
        {
            code: 'status',
            title: 'Status',
            dataIndex: 'status',
            render: statusColumn.renderTag,
            filters: statusColumn.filterKeys.map((status) => ({ text: status, value: status })),
            onFilter: (status: ImportDeviceStatus, row: IImportDeviceProps) => row.status === status,
            sorter: (a: IImportDeviceProps, b: IImportDeviceProps) => localeCompare(a.status, b.status)
        },
        {
            code: 'comment',
            title: 'Comment',
            dataIndex: 'comment',
            width: '20%'
        }
    ];

    return (
        <div>
            <TableListingPage
                title={header}
                tableProps={{
                    title: title,
                    table: {
                        columns: columns,
                        dataSource: dataSource,
                        rowKey: 'UID',
                    },
                    toolbar: {
                        extraItems: extraItems,
                    } as IAdvanceTableToolbarProps,
                }}
            />
        </div>
    );
};
