import * as React from 'react';
import { FormItem, getDefaultFormItemOptions, IFormItem } from './form-item';
import { ISwitchSelectProps, SwitchSelect } from 'ui-lib/components/switch';

export interface ISwitchSelectFormItemPros extends Omit<IFormItem, 'initialValue'> {
    switchSelectProps: Omit<ISwitchSelectProps, 'code' | 'className'>;
}

export const SwitchSelectFormItem = (props: ISwitchSelectFormItemPros): JSX.Element => {
    const formItemProps = getDefaultFormItemOptions(props, true);

    return (
        <FormItem
            code={props.code}
            {...formItemProps}
            child={
                <SwitchSelect {...props.switchSelectProps} />
            }
        />
    );
};
