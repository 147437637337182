import * as React from 'react';
import { Input } from 'antd';

export interface ITextAreaProps {
    className?: string;
    name?: string;
    placeholder?: string;
    value?: string;
    allowClear?: boolean;
    showCount?: boolean;
    autoSize?: boolean | { minRows: number; maxRows: number; };
    maxLength?: number;
    onChange?: (value: any) => void;
    disabled?: boolean;
}

export const TextArea = (props: ITextAreaProps) => {
    return (
        <Input.TextArea {...props} />
    );
};
