import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import {
    HorizontalStepper,
    IExtraValidatorResult,
    IStepItem,
} from 'ui-lib';

import { IFileMetadata, ISignAndCreateConfigurationFileResult } from 'dto/configuration-dto';

import { ConfigurationUploadFiles } from './upload-files';
import { ConfigurationSigning } from './signing';
import { ConfigurationSigningFinished } from './finished';

import configurationCss from './configuration.css';

enum CONFIGURATION_STATE {
    UPLOAD_FILES = '1',
    SIGN = '2',
    FINISHED = '3',
}

export type TConfigurationSigningFile = Omit<IFileMetadata, 'crc'> & File;
export type TConfigurationUploadFile = TConfigurationSigningFile & IExtraValidatorResult;

export const ConfigurationSigningWizard = observer((): JSX.Element => {

    const [stepCodeState, setStepCodeState] = React.useState(CONFIGURATION_STATE.UPLOAD_FILES);
    const [fileList, setFileList] = React.useState<TConfigurationUploadFile[]>([]);
    const [selectedFile, setSelectedFile] = React.useState<TConfigurationUploadFile>(null);
    const [signedInfo, setSignedInfo] = React.useState<ISignAndCreateConfigurationFileResult>(null);

    useEffect(() => setStepCodeState(signedInfo ? CONFIGURATION_STATE.FINISHED : CONFIGURATION_STATE.UPLOAD_FILES), [signedInfo]);

    const stepItems: IStepItem[] = [
        {
            code: CONFIGURATION_STATE.UPLOAD_FILES,
            label: 'UPLOAD CONFIGURATION',
            description: 'Set description and release notes',
            content: (
                <ConfigurationUploadFiles
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    fileList={fileList}
                    setFileList={setFileList}
                    onClick={(): void => setStepCodeState(CONFIGURATION_STATE.SIGN)}
                />
            ),
            disableNextButton: true,
        },
        {
            code: CONFIGURATION_STATE.SIGN,
            label: 'SIGN CONFIGURATION',
            description: 'Generate the signed configuration',
            content: (
                <ConfigurationSigning
                    onBackClick={(): void => setStepCodeState(CONFIGURATION_STATE.UPLOAD_FILES)}
                    files={fileList}
                    setSignedFileResult={setSignedInfo}
                />
            ),
            disableNextButton: true,
        },
        {
            code: CONFIGURATION_STATE.FINISHED,
            label: 'FINISHED SIGN AND UPLOAD CONFIGURATION',
            description: 'The configuration file is signed and uploaded',
            content: (
                <ConfigurationSigningFinished
                    signedInfo={signedInfo}
                    onClick={(): void => {
                        setSignedInfo(null);
                        setSelectedFile(null);
                        setFileList([]);
                        setStepCodeState(CONFIGURATION_STATE.UPLOAD_FILES);
                    }}
                />
            ),
            disableNextButton: true,
        },
    ];

    const onStepCodeChange = (previous: IStepItem, current: IStepItem) => {
        if (!current) return;
        setStepCodeState(current.code as CONFIGURATION_STATE);
    };

    return (
        <div className={configurationCss.configurationSign} >
            <HorizontalStepper
                items={stepItems}
                currentStepCode={stepCodeState}
                onStepCodeChange={onStepCodeChange}
                canGoBack={false}
                hideFinish={true}
            />
        </div>
    );
});
