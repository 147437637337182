import React from 'react';
import { observer } from 'mobx-react';
import { chunk, cloneDeep } from 'lodash';

import { IBasicTableColumn, IBasicTableProps, toSelectItemFn, useForm } from 'ui-lib';

import { useAuthStore, useDeploymentGroupsStore, useDeploymentStore, } from '../../../../store';

import { ReportComponent } from './report-component';
import { DEFAULT_DEVICE_COLUMN, DevicesTableComponent } from '../../components/devices-table-component';
import { ReportTable } from '../../components/report-table';
import { EXPORT_TYPES, exportData, IHeaders, isNullOrEmpty } from 'common-utils';

import reportCss from './reports.css';
import { SearchableSelectFormItem } from '../../components/device-groups-select';
import { useDeploymentStates } from '../../software/deployments/common';
import { createBasicFilterColumn } from 'pages/private/components/table-utils';
import { DEVICE_JOB_EXECUTION_STATUS } from 'dto/remote-commands';
import { IDeviceDeploymentStatusRecord } from 'dto/deployment/deployment-details.dto';
import { OrganisationTreeSelectItem } from 'pages/private/components/organisation-select';
import { AccountType, IAccount } from 'dto/access-management/account-dto';

interface IDeploymentReport {
    key: number;
    deploymentName?: string;
    deploymentGroupName?: string;
    devices?: IDeviceDeploymentStatusRecord[];
}

const DEPLOYMENT_CSV_COLUMNS: IHeaders = {
    accountName: 'Account name',
    deploymentGroupName: 'Deployment group name',
    deploymentName: 'Deployment name',
    UID: 'UID',
    esn: 'eSN',
    serialNumber: 'Serial number',
    deviceGroupLabel: 'Device group',
    lastUpdatedDateTime: 'Last updated',
    trampolineVersion: 'Trampoline version',
    firmwareVersion: 'Firmware version',
    snapshotLabel: 'Software release',
    executionStatus: 'Deployment status'
};

function flattenWithCommonProperties<COMMON_PROPERTIES, DATASOURCE>(
    dataSource: DATASOURCE[],
    childSourcePropertyName: keyof DATASOURCE,
    commonPropertiesFn: (source: DATASOURCE) => COMMON_PROPERTIES,
): any[] {
    if (isNullOrEmpty(dataSource)) {
        return [];
    }
    return dataSource.flatMap((source: DATASOURCE) => {
        const result: any[] = source[childSourcePropertyName] as any;
        const commonProperties = commonPropertiesFn(source) ?? {};
        return result.map((item) => ({ ...commonProperties, ...item }));
    });
}

const ALL_DEPLOYMENT_DEFAULT_VALUE = { key: -1, value: -1, label: 'All' };
const ALL_GROUPS_DEFAULT_VALUE = cloneDeep(ALL_DEPLOYMENT_DEFAULT_VALUE);
const DEPLOYMENT_SELECT_CODE = 'deploymentSelect';

export const DeploymentsReportComponent = observer(() => {
    const authStore = useAuthStore();
    const { assureApiAccount } = authStore;
    const deploymentGroupStore = useDeploymentGroupsStore();
    const deploymentStore = useDeploymentStore();

    const { selectedGroupId, onGroupChange, deployments } = useDeploymentStates();

    const deploymentGroup = React.useMemo(
        () => deploymentGroupStore.findDeploymentGroupById(selectedGroupId),
        [selectedGroupId],
    );
    const isDeploymentGroupLevel = React.useMemo(() => selectedGroupId === -1, [selectedGroupId]);
    const [selectedAccount, setCurrentSelectedAccount] = React.useState<IAccount>();

    const filteredDeploymentGroups = React.useMemo(() => {
        if (!selectedAccount) {
            return [];
        }
        return deploymentGroupStore.displayDeploymentGroups.filter(
            (group) => group.accountUuid === selectedAccount.uuid,
        );
    }, [selectedAccount, deploymentGroupStore.displayDeploymentGroups]);

    // loading data
    const [loadingReport, setLoadingReport] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>(null);

    // data source
    const [reportDataSource, setReportDataSource] = React.useState<IDeploymentReport[]>(null);
    const [exportCSVReportDataSource, setExportCSVReportDataSource] = React.useState<IDeploymentReport[]>([]);

    const [form] = useForm();

    React.useEffect(() => {
        (async (): Promise<void> => {
            try {
                setErrorMessage(null);
                await deploymentGroupStore.loadDeploymentGroups(assureApiAccount.accountId);
            } catch (err) {
                setErrorMessage(err.message);
            }
        })();
    }, []);

    function onDeploymentGroupChange(value: number): void {
        try {
            onGroupChange(value);
            form.setFieldsValue({
                [DEPLOYMENT_SELECT_CODE]: ALL_GROUPS_DEFAULT_VALUE.value
            });
            setReportDataSource([]);
            setExportCSVReportDataSource([]);
            setErrorMessage(null);
        } catch (err) {
            setErrorMessage(err.message);
        }
    }

    async function onGenerateReportForGroup(accountId: number, ids: number[]): Promise<IDeploymentReport[]> {
        const allSources: IDeploymentReport[] = [];
        const groupsToReportBatches = chunk(ids, 3);
        for (const batch of groupsToReportBatches) {
            // eslint-disable-next-line no-await-in-loop
            const newSource = await Promise.all(
                batch?.map(async (id) => {
                    const deploymentGroup = deploymentGroupStore.findDeploymentGroupById(id);
                    const details = await deploymentGroupStore.getActiveDeploymentDetailsByGroupId(accountId, id);
                    return details.map(deployment => ({
                        key: deployment.id,
                        deploymentName: deployment.name,
                        deploymentGroupName: deploymentGroup?.label,
                        devices: deployment.devices,
                    }));
                }),
            );
            allSources.push(...newSource.flat());
        }
        return allSources;
    }

    async function onGenerateReportForDeployment(accountId: number, deploymentsToReport: number[]): Promise<IDeploymentReport[]> {
        const allSources: IDeploymentReport[] = [];
        const deploymentsToReportBatches = chunk(deploymentsToReport, 3);
        for (const deploymentsToReportInBatch of deploymentsToReportBatches) {
            // eslint-disable-next-line no-await-in-loop
            const newSource = await Promise.all(
                deploymentsToReportInBatch?.map(async (deploymentId) => {
                    const deploymentDetail = await deploymentStore.getDeploymentDetails(
                        accountId,
                        deploymentId,
                    );

                    const sourceElement: IDeploymentReport = {
                        key: deploymentId,
                        deploymentName: deploymentDetail.name,
                        deploymentGroupName: deploymentGroup?.label,
                        devices: deploymentDetail.devices,
                    };
                    return sourceElement;
                }),
            );
            allSources.push(...newSource);
        }
        return allSources;
    }

    function onTableChange(pagination: any, filters: any, sorter: any, extra: any, deploymentName: string): void {
        const source = exportCSVReportDataSource.find((report) => report.deploymentName === deploymentName);
        if (!source) return;
        source.devices = extra?.currentDataSource;
    }

    const deploymentReportColumns: IBasicTableColumn[] = [
        {
            code: 'deploymentName',
            title: 'Deployment Name',
            dataIndex: 'deploymentName',
            render: (text, row: IDeploymentReport): React.ReactNode => {
                return (
                    <DevicesTableComponent
                        hiddenDefaultColumns={[DEFAULT_DEVICE_COLUMN.TYPE]}
                        className={reportCss['devices-table']}
                        extraColumns={[
                            createBasicFilterColumn({
                                key: 'trampolineVersion',
                                title: 'Trampoline version',
                                searchPlaceholder: 'Trampoline Version',
                            }),
                            createBasicFilterColumn({
                                key: 'firmwareVersion',
                                title: 'Firmware version',
                                searchPlaceholder: 'Firmware Version',
                            }),
                            createBasicFilterColumn({
                                key: 'snapshotLabel',
                                title: 'Software release',
                                searchPlaceholder: 'Software release',
                            }),
                            createBasicFilterColumn({
                                key: 'executionStatus',
                                title: 'Deployment status',
                                searchPlaceholder: null,
                                columnExtraOptions: {
                                    filters: Object.keys(DEVICE_JOB_EXECUTION_STATUS).map((item) => ({
                                        text: item,
                                        value: item,
                                    })),
                                    onFilter: (type: DEVICE_JOB_EXECUTION_STATUS, row) => row.executionStatus === type,
                                },
                            }),
                        ]}
                        buildDeviceLinkProps={(record: any) => ({ accountId: selectedAccount?.id })}
                        dataSource={row.devices}
                        title={row.deploymentName}
                        onTableChange={(pagination: any, filters: any, sorter: any, extra: any) =>
                            onTableChange(pagination, filters, sorter, extra, row.deploymentName)
                        }
                        tableScroll={{
                            x: true
                        }}
                        pagination={row.devices?.length > 10}
                    />
                );
            },
        },
    ];

    const deploymentReportTableProps: IBasicTableProps = {
        columns: deploymentReportColumns,
        rowKey: 'id',
        dataSource: reportDataSource || [],
        loading: loadingReport,
        extraOptions: {
            pagination: reportDataSource?.length > 10,
        },
    };

    return (
        <ReportComponent
            className={reportCss.deployment}
            code="deploymentsReport"
            form={form}
            isLoading={deploymentGroupStore.dataLoading || deploymentStore.dataLoading}
            errorMessage={errorMessage}
            warningMessage={isDeploymentGroupLevel ? 'Only deployments that are currently in progress will be included in the report' : null}
            items={[
                <OrganisationTreeSelectItem
                    key="clientAccountSelect"
                    selectableTypes={[AccountType.VAR, AccountType.CLIENT]}
                    setHiddenState={(currentAccountType: AccountType): boolean => currentAccountType === AccountType.CLIENT}
                    onSelectedAccountChange={(account: IAccount): void => {
                        setCurrentSelectedAccount(account);
                        form.resetFields();
                    }}
                />,
                <SearchableSelectFormItem
                    key="deploymentGroupSelect"
                    code="deploymentGroupSelect"
                    isRequired={true}
                    label="Deployment Group"
                    dataSource={[
                        ALL_GROUPS_DEFAULT_VALUE,
                        ...(filteredDeploymentGroups?.map((item) => {
                            return { value: item.id, label: item.label };
                        }) || []),
                    ]}
                    onChange={onDeploymentGroupChange}
                    disabled={deploymentStore.dataLoading || loadingReport}
                    labelAlign="right"
                />,
                <SearchableSelectFormItem
                    key={DEPLOYMENT_SELECT_CODE}
                    code={DEPLOYMENT_SELECT_CODE}
                    isRequired={true}
                    label="Deployment"
                    dataSource={[ALL_DEPLOYMENT_DEFAULT_VALUE, ...deployments.map(toSelectItemFn('id', 'label'))]}
                    disabled={deploymentStore.dataLoading || loadingReport || isDeploymentGroupLevel}
                    labelAlign="right"
                />,
            ]}
            generateReportOptions={{
                isGenerating: loadingReport,
                onGenerateReport: async (event: any): Promise<void> => {
                    setLoadingReport(true);
                    setErrorMessage(null);
                    const reports: IDeploymentReport[] = [];
                    try {
                        const accountId = selectedAccount?.id;
                        if (!accountId) {
                            throw new Error('No account has been selected. Please select an account to proceed');
                        }

                        if (isDeploymentGroupLevel) {
                            reports.push(...(await onGenerateReportForGroup(accountId, filteredDeploymentGroups.map(item => item.id))));
                        } else {
                            const selectedDeploymentId = event[DEPLOYMENT_SELECT_CODE];
                            const deploymentsToReport =
                                selectedDeploymentId === -1
                                    ? deployments
                                    : [deployments.find((item) => item.id === selectedDeploymentId)];
                            reports.push(...(await onGenerateReportForDeployment(accountId, deploymentsToReport.map(item => item.id))));
                        }
                        setReportDataSource(reports);
                        setExportCSVReportDataSource(cloneDeep(reports));
                    } catch (err) {
                        setErrorMessage(err.message);
                    } finally {
                        setLoadingReport(false);
                    }
                },
            }}
            reportTable={
                <ReportTable
                    title=""
                    table={deploymentReportTableProps}
                    loadingState={loadingReport}
                    exportOptions={{
                        overrideExportItem: {
                            componentProps: {
                                label: 'Export to CSV',
                                type: 'primary',
                                disabled:
                                    deploymentReportTableProps.loading ||
                                    deploymentReportTableProps.dataSource.length == 0,
                                onClick: (): void => {
                                    const flattenDataSource = flattenWithCommonProperties(
                                        exportCSVReportDataSource,
                                        'devices',
                                        (deployment: IDeploymentReport) => ({
                                            deploymentGroupName: deployment.deploymentGroupName,
                                            deploymentName: deployment.deploymentName,
                                            accountName: selectedAccount?.name
                                        }),
                                    );

                                    exportData(
                                        `Report of ${isDeploymentGroupLevel ? selectedAccount?.name : deploymentGroup?.label}.csv`,
                                        EXPORT_TYPES.CSV,
                                        flattenDataSource,
                                        DEPLOYMENT_CSV_COLUMNS,
                                    );
                                },
                            },
                        },
                    }}
                />
            }
        />
    );
});
