import { SERVER_CONFIG } from '../store/assure-admin-api-client.store';
import { SocketClient } from './socket-client';

export type TSocketQuery = { [key: string]: any };
export interface IBuilderOptions {
    path?: string;
    query?: TSocketQuery;
    autoConnect?: boolean;
    reconnectionAttempts?: number;
}

export class SocketClientBuilder {
    private readonly _host: string;
    private readonly _prefix: string;

    public constructor() {
        const { host, port, version, prefix } = SERVER_CONFIG;
        this._host = `${host}${port ? `:${port}` : ''}`;
        this._prefix = `${version ? `/${version}` : ''}${prefix ? `/${prefix}` : ''}`;
    }

    private builderOptions: IBuilderOptions = {};

    public setAutoConnect(autoConnect: boolean): SocketClientBuilder {
        this.builderOptions['autoConnect'] = autoConnect;
        return this;
    }

    public setReconnectionAttempts(max: number): SocketClientBuilder {
        this.builderOptions['reconnectionAttempts'] = max;
        return this;
    }

    public setPath(path: string): SocketClientBuilder {
        this.builderOptions['path'] = String.prototype.concat(this._prefix, path);
        return this;
    }

    public setQuery(options: TSocketQuery): SocketClientBuilder {
        this.builderOptions['query'] = options;
        return this;
    }

    public create(): SocketClient {
        return new SocketClient(this._host, this.builderOptions);
    }
}
