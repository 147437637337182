import { IApiClientOptions } from 'core';
import { IPackageProps } from '../dto/packages-dto';
import {
    ICreateMetaPackageResponseProps,
    ISnapshotProps,
    ICreateSnapshotProps,
    IUpdateSnapshotProps,
    ICreateMetaPackageRequestProps
} from '../dto/software-snapshot-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';

export class SnapshotsApiClient extends AssureBaseApiClient {
    protected getRootPath(): string {
        return 'deployments/releases';
    }

    constructor(options: IApiClientOptions) {
        super('snapshots', options);
    }

    public async getSnapshot(accountId: number, snapshotId: number): Promise<ISnapshotProps> {
        const result = await this.send<{ release: ISnapshotProps }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${snapshotId}`),
        );
        return result.release;
    }

    public async getLatestSnapshot(accountId: number, accountUuid: string): Promise<ISnapshotProps> {
        const result = await this.send<{ release: ISnapshotProps }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, 'latest-published', { accountUuid }),
        );
        return result.release;
    }

    public async getAllSnapshots(accountId: number): Promise<ISnapshotProps[]> {
        const result = await this.send<{ releases: ISnapshotProps[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, 'history'),
        );
        return result.releases;
    }

    public async getPackagesBySnapshotId(accountId: number, snapshotId: number): Promise<IPackageProps[]> {
        const result = await this.send<{ packages: IPackageProps[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${snapshotId}/packages`),
        );
        return result.packages;
    }

    public async createSnapshot(accountId: number, props: ICreateSnapshotProps): Promise<ISnapshotProps> {
        const result = await this.send<{ release: ISnapshotProps }>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, '', { ...props }),
        );
        return result.release;
    }

    public async createMetapackage(accountId: number, props: ICreateMetaPackageRequestProps): Promise<ICreateMetaPackageResponseProps> {
        return this.send<ICreateMetaPackageResponseProps>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, 'metapackage', { ...props }),
        );
    }

    public async updateSnapshot(accountId: number, snapshotId: number, props: IUpdateSnapshotProps): Promise<ISnapshotProps> {
        const result = await this.send<any>(
            HTTP_METHOD.PUT,
            this.getRequestOptionsWithAccountId(accountId, `${snapshotId}`, props),
        );
        return result.release;
    }

    public async publishSnapshot(
        accountId: number,
        snapshotId: number,
    ): Promise<boolean> {
        await this.send<any>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, `${snapshotId}/published`),
        );
        return true;
    }

    public async deleteSnapshot(accountId: number, id: number): Promise<boolean> {
        const result = await this.send<{ deleteResult: true }>(
            HTTP_METHOD.DELETE,
            this.getRequestOptionsWithAccountId(accountId, `${id}`),
        );
        return result.deleteResult;
    }
}
