import { Modal } from 'antd';
import { IconName } from '@fortawesome/fontawesome-common-types';

export interface IConfirmDialogProps {
    title: string;
    content: JSX.Element | string;
    iconName?: IconName;
    okText: string;
    cancelText?: string;
    onOk?: any;
    onCancel?: any;
}

export type InformationDialogType = 'info' | 'success' | 'warning' | 'error';

export interface IInformationDialogProps {
    title: React.ReactNode;
    width?: string;
    icon?: React.ReactNode;
    content: React.ReactNode;
    modalType: InformationDialogType;
    className?: string;
    afterClose?: any;
};

export function showInformationDialog(props: IInformationDialogProps) {
    switch (props.modalType) {
        case 'info': return Modal.info(props);
        case 'success': return Modal.success(props);
        case 'warning': return Modal.warning(props);
        case 'error': return Modal.error(props);
        default: return null;
    }
}

export function showConfirmDialog(props: IConfirmDialogProps) {
    Modal.confirm(props);
}

export function showConfirmDialogWithoutCancel(props: IConfirmDialogProps) {
    Modal.confirm({ ...props, cancelButtonProps: { style: { display: 'none' } } });
}
