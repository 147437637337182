import React, { ReactNode } from 'react';
import { Badge } from 'antd';

export interface IBasicBadgeProps {
    status: 'success' | 'processing' | 'default' | 'error' | 'warning';
    className?: string;
    color?: string;
    count?: ReactNode;
    dot?: boolean;
    offset?: [number, number];
    overflowCount?: number;
    showZero?: boolean;
    size?: 'default' | 'small'; //	If count is set, size sets the size of badge
    text?: ReactNode;
    title?: string;
}

export function BasicBadge(props: IBasicBadgeProps) {
    return (
        <Badge
            className={props.className}
            color={props.color}
            status={props.status}
            text={props.text}
            count={props.count}
            dot={props.dot}
            offset={props.offset}
            overflowCount={props.overflowCount}
            showZero={props.showZero}
            size={props.size}
            title={props.title}
        />
    );
}
