import { IScrFileOptions } from 'dto/device-dto';
import { computed, makeObservable } from 'mobx';
import { AbstractDeviceDetailWithCommandCodeStore, ICommandCodeConstructorOptions } from './device-detail-with-command-code-store';

export interface IDisplaySCRFileOptions
    extends Omit<IScrFileOptions, 'version' | 'location' | 'secure' | 'size' | 'valid'> {
    key: string;
    updateCount: number;
}

export type ISCRListingConstructorOptions = Omit<ICommandCodeConstructorOptions, 'code'>;

export class DeviceSCRFileListingStore extends AbstractDeviceDetailWithCommandCodeStore<IScrFileOptions> {
    constructor(options: ISCRListingConstructorOptions) {
        super({ code: 'scr_listing', ...options });
        makeObservable(this);
    }

    /** ************************************
     * Override functions
     **************************************/
    protected name(): string {
        return 'device SCR file listing';
    }

    protected async initializeData(): Promise<IScrFileOptions[]> {
        return this._devicesStore.getCurrentDeviceScrFiles(this.accountId, this.uid);
    }
    /** *************************************/

    @computed
    public get displaySCRFiles(): IDisplaySCRFileOptions[] {
        if (!this.entities) return [];
        return this.entities.map((item) => ({
            key: item.name + item.version,
            name: item.name,
            updateCount: Number.parseInt(item.version.substring(1)),
            crc: item.crc,
        }));
    }
}
