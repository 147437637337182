import { IApiClientOptions } from 'core';
import { IPermission } from '../dto/access-management/user-role-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';

export class PermissionApiClient extends AssureBaseApiClient {
    constructor(options: IApiClientOptions) {
        super('permission', options);
    }

    protected getRootPath(): string {
        return '/permissions';
    }

    public async getPermissions(params?: { all: boolean }): Promise<IPermission[]> {
        const result = await this.send<{ permissions: IPermission[] }>(HTTP_METHOD.GET, this.getRequestOptions('', params));
        return result.permissions;
    }
}
