import { IEditEnvironmentOptions, IEnvironment, ISaveEnvironmentOptions } from './environment-dto';

export enum AccountType {
    VAR = 'VAR',
    CLIENT = 'CLIENT',
    DPU = 'DPU',
}

export interface IAccount {
    id: number;
    name: string;
    accountType: AccountType;
    code: string;
    applications: any[];
    environments: IEnvironment[];
    subAccounts: IAccount[];
    parent: IAccount;
    uuid?: string;
}

interface IBaseSaveUserProps {
    id?: number;
    name: string;
    type: AccountType;
}

export interface ISaveAccountOptions extends IBaseSaveUserProps {
    parentAccountId: number;
    selectedEnvironments: ISaveEnvironmentOptions[];
    selectedSubAccounts: IBaseSaveUserProps[];
}

export interface IEditAccountOptions extends IBaseSaveUserProps {
    selectedEnvironments: IEditEnvironmentOptions[];
    selectedSubAccounts: IEditSubAccountOptions[];
    parentAccount?: IAccount;
}

export interface IEditSubAccountOptions {
    id?: number;
    type?: AccountType;
    value: string;
    label: string;
}
