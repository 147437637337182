import { IDeploymentOptions } from 'dto/deployment/deployment-dto';
import { isNullOrEmpty } from './export-utils';
import { ISnapshotProps } from 'dto/software-snapshot-dto';

export const MAX_RETRIES_WHEN_SERVICE_UNAVAILABLE = 2;

export const getReleaseOfDeployment = (deployment: IDeploymentOptions): ISnapshotProps => {
    if (isNullOrEmpty(deployment?.deploymentUnits)) {
        return undefined;
    }
    return deployment.deploymentUnits[0].releases[0];
};