import * as React from 'react';

import { FormItem, getDefaultFormItemOptions, IFormItem } from './form-item';
import { ITextAreaProps, TextArea } from '../../labels/text-area';

export interface ITextAreaFieldItemProps extends IFormItem {
    textAreaProps: ITextAreaProps;
}

export const TextAreaFieldItem = (props: ITextAreaFieldItemProps) => {
    const formItemProps = getDefaultFormItemOptions(props, true);

    return (
        <FormItem
            code={props.code}
            initialValue={props.initialValue}
            validateTrigger={props.validateTrigger}
            {...formItemProps}
            child={<TextArea name={props.code} {...props.textAreaProps} />}
        />
    );
};
