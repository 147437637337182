import React from 'react';

import repositoryCss from './repository.css';
import { useAuthStore, usePackagesStore } from '../../../../store';
import {
    IManualFileUploadProps,
    ManualFileUpload,
    DangerAlert,
    SuccessAlert,
    BasicForm,
    SelectFormItem,
    IconTextButton,
} from 'ui-lib';
import { AccountType } from '../../../../dto/access-management/account-dto';
import { DPU_REPOSITORY_TYPES_SOURCE,useRepositoryContext } from './index';
import { ICreatePackageRequestProps } from '../../../../dto/packages-dto';
import { useUploadFilesCheckingStates } from './package-utils';

interface IFileUploadResult {
    successFiles?: any[];
    errors?: any[];
}

export const UploadPackagesWrapper = () => {
    const context = useRepositoryContext();

    const packagesStore = usePackagesStore();
    const { currentUser, assureApiAccount } = useAuthStore();
    const [errors, setErrors] = React.useState<string[]>([]);
    const [successFiles, setSuccessFiles] = React.useState<string[]>([]);
    const [repositoryType, setRepositoryType] = React.useState(currentUser.accountType == 'DPU' ? 'ALM': currentUser.accountType);

    const { validateFiles } = useUploadFilesCheckingStates();

    const draggerProps: IManualFileUploadProps<IFileUploadResult> = {
        limitFileSize: __LIMIT_PACKAGE_FILE_SIZE,
        onUploadFiles: async (params: { files: any[] }): Promise<IFileUploadResult> => {
            const options: ICreatePackageRequestProps = {
                ...params,
                type: repositoryType,
            };
            const result = await packagesStore.createPackages(assureApiAccount.accountId, options);
            return {
                successFiles: result.packages.map((item) => item.fileName),
                errors: result.errors,
            };
        },
        onSuccessOrError: (response: IFileUploadResult) => {
            setSuccessFiles(response?.successFiles || []);
            setErrors(response?.errors || []);
        },
        beforeUploadChecking: (currentFiles: File[], newFiles: File[]): File[] => {
            const checkFileResult = validateFiles(currentFiles, newFiles);
            return checkFileResult.validFiles;
        },
    };

    return (
        <React.Fragment>
            {successFiles.length != 0 && (
                <SuccessAlert
                    className={repositoryCss.uploadAlert}
                    closable={true}
                    message='Imported files'
                    description={
                        <React.Fragment>
                            <p key='success-files'>Success files: {successFiles.join(', ')}</p>
                        </React.Fragment>
                    }
                />
            )}
            {errors.length != 0 && (
                <DangerAlert
                    className={repositoryCss.uploadAlert}
                    closable={true}
                    message='Errors'
                    description={
                        <React.Fragment>
                            {errors.map((error, index) => (
                                <p key={`error-${index}`}>{error}</p>
                            ))}
                        </React.Fragment>
                    }
                />
            )}
           <div className={repositoryCss.upload}>
                <h2>Upload Packages</h2>
                {currentUser.accountType === AccountType.DPU ? (
                    <div>
                        <BasicForm
                            cardPros={{ bordered: false }}
                            items={[
                                <React.Fragment key='select-form-item-fragment'>
                                    <SelectFormItem
                                        labelColSpan={24}
                                        code='repositoryType'
                                        label='Type'
                                        isRequired={true}
                                        dataSource={DPU_REPOSITORY_TYPES_SOURCE}
                                        selectedValue={repositoryType}
                                        onChange={(value) => setRepositoryType(value)}
                                    />
                                </React.Fragment>,
                            ]}
                        />
                    </div>
                ) : null}
                <ManualFileUpload {...draggerProps} />
                <IconTextButton type='primary' label='Back to Software Repository' onClick={context.toPackagesTable} />
            </div>
        </React.Fragment>
    );
};
