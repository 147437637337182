import { action, makeObservable, observable } from 'mobx';
import { DevicesStore } from '../devices-store';
import { notification } from 'ui-lib';
import { isNullOrEmpty } from 'common-utils';

export interface IDeviceDetailsConstructorOptions {
    devicesStore: DevicesStore;
    uid: string;
    serialNumber: string;
    accountId: number;
}

export abstract class DeviceDetailEntryStore<T> {
    protected readonly _devicesStore: DevicesStore;
    protected readonly uid: string;
    protected readonly serialNumber: string;
    protected readonly accountId: number;
    private abortController: AbortController | null = null;

    constructor(options: IDeviceDetailsConstructorOptions) {
        makeObservable(this);
        this._devicesStore = options.devicesStore;
        this.uid = options.uid;
        this.serialNumber = options.serialNumber;
        this.accountId = options.accountId;
    }

    @observable
    loading: boolean;

    @action
    setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @observable
    error: string;

    @action
    setError(err: string): void {
        this.error = err;
    }

    protected abstract name(): string;
    protected abstract initializeData(): Promise<T[]>;

    protected async triggeredCommand(): Promise<unknown> {
        return Promise.resolve();
    }

    @observable
    private _entities: T[];

    protected get entities(): T[] {
        return this._entities;
    }

    protected hasEntities(): boolean {
        return !isNullOrEmpty(this._entities);
    }

    public async processing<T>(callback: (signal: AbortSignal) => Promise<T>): Promise<T | void> {
        if (this.abortController) {
            this.abortController.abort();
        }

        this.abortController = new AbortController();
        const { signal } = this.abortController;

        this.setLoading(true);
        this.setError(undefined);

        try {
            return await callback(signal);
        } catch (e) {
            if (signal.aborted) {
                // Ignore when the process is interrupted
            } else {
                this.setError(e.message);
            }
        } finally {
            if (!signal.aborted) {
                this.setLoading(false);
            }
        }
    }

    public async initialize(force?: boolean): Promise<void> {
        await this.processing((signal) => {
            return this.loadEntities(signal, force);
        });
    }

    public async loadEntities(signal: AbortSignal | null, force?: boolean): Promise<void> {
        if (!force && this.hasEntities()) return;
        const data = await this.initializeData();

        signal?.throwIfAborted();
        this.setEntities(data);
    }

    @action
    public setEntities(entities: T[]): void {
        this._entities = entities;
    }

    public async fetchLatestData(ignoreNotification?: boolean): Promise<void> {
        await this.processing(async (signal) => {
            !ignoreNotification &&
                notification['info']({
                    message: `Get ${this.name()} for serial ${this.serialNumber}`,
                    description: 'Please wait...',
                    placement: 'topRight',
                });
            await this.triggeredCommand();
            await this.loadEntities(signal, true);

            !ignoreNotification &&
                notification['info']({
                    message: `Fetch ${this.name()} for serial ${this.serialNumber}`,
                    description: 'Latest data has been fetched',
                    placement: 'topRight',
                });
        });
    }
}
