import * as React from 'react';
import { uniq } from 'lodash';
import { Error404, PermissionPage } from 'ui-lib';
import {
    useAuthStore,
    useRoutesStore,
    useStores
} from '../../../store';
import { Route, Routes } from 'react-router-dom';
import { SoftwareRepositoryPage } from './repository';
import { DeploymentsManagementPage } from './deployments';
import { ManageSoftwareSnapshotsComponent } from './software-snapshots';
import { ManageDeploymentGroupPage } from './deployment-group';

export enum SOFTWARE_MANAGEMENT_ROUTES {
    ROOT = 'SOFTWARE_MANAGEMENT',
    DEPLOYMENTS_VIEW = 'SOFTWARE_MANAGEMENT_DEPLOYMENTS_VIEW',
    DEPLOYMENTS_MODIFY = 'SOFTWARE_MANAGEMENT_DEPLOYMENTS_MODIFY',
    REPOSITORY = 'SOFTWARE_MANAGEMENT_REPOSITORY',
    SNAPSHOTS_VIEW = 'SOFTWARE_MANAGEMENT_SNAPSHOTS_VIEW',
    SNAPSHOTS_MODIFY = 'SOFTWARE_MANAGEMENT_SNAPSHOTS_MODIFY',
    DEPLOYMENTS_GROUPS_VIEW = 'SOFTWARE_MANAGEMENT_DEPLOYMENTS_GROUPS_VIEW',
}

const NO_TIMEZONE = 'No timezone';

export const getTimezones = (deviceGroupNames: string[], callback: (deviceGroupName: string) => string): string =>
    uniq(deviceGroupNames?.map((name: string) => callback(name) || NO_TIMEZONE)).join(', ');

export function SoftwareManagementPage() {
    const routeStore = useRoutesStore();
    const { permissionStore, packagesStore, packageGroupsStore, deploymentGroupsStore, snapshotStore } = useStores();

    const authStore = useAuthStore();

    const onLoad = () => {
        (async () => {
            const currentAccountId = authStore?.assureApiAccount?.accountId;
            await Promise.all([
                deploymentGroupsStore.loadDeploymentGroups(currentAccountId),
                packagesStore.loadEntities(currentAccountId),
                packageGroupsStore.loadEntities(currentAccountId),
                snapshotStore.loadEntities(currentAccountId),
            ]);
        })();
    };

    React.useEffect(onLoad, []);

    const rootRoute = routeStore.getRoute(SOFTWARE_MANAGEMENT_ROUTES.ROOT);
    const deploymentRoute = routeStore.getRoute(SOFTWARE_MANAGEMENT_ROUTES.DEPLOYMENTS_VIEW);
    const repositoryRoute = routeStore.getRoute(SOFTWARE_MANAGEMENT_ROUTES.REPOSITORY);
    const snapshotRoute = routeStore.getRoute(SOFTWARE_MANAGEMENT_ROUTES.SNAPSHOTS_VIEW);
    const deploymentGroupsRoute = routeStore.getRoute(SOFTWARE_MANAGEMENT_ROUTES.DEPLOYMENTS_GROUPS_VIEW);

    return (
        <Routes>
            <Route
                path={deploymentRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={deploymentRoute.label}
                    >
                        <DeploymentsManagementPage />
                    </PermissionPage>
                }
            />
            <Route
                path={repositoryRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={repositoryRoute.label}
                    >
                        <SoftwareRepositoryPage />
                    </PermissionPage>
                }
            />
            <Route
                path={snapshotRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={snapshotRoute.label}
                    >
                        <ManageSoftwareSnapshotsComponent />
                    </PermissionPage>
                }
            />
            <Route
                path={`${deploymentGroupsRoute.trimRootPath(rootRoute.path)}/*`}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={deploymentGroupsRoute.label}
                    >
                        <ManageDeploymentGroupPage />
                    </PermissionPage>
                }
            />
            <Route path='*' element={<Error404 message='Page Not Found' />} />
        </Routes>
    );
}
