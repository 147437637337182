/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable react/display-name */
import * as React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AccountType } from '../../../../dto/access-management/account-dto';
import { useAuthStore } from '../../../../store';
import { ITreeSelectDataSource } from '../../../../store/account-store';
import { DeviceGroupsStore } from '../../../../store/device-groups.store';
import { VARSelectModal } from './var-select-modal';
import { toNumber } from 'lodash';

export interface IVarAccountProps {
    /** Always is VAR or CLIENT account id */
    varOrClientAccountId: number;
}

export interface IVarDeviceGroupsProps extends IVarAccountProps {
    clientDataSource: ITreeSelectDataSource;
    /** The device group store has valid device groups for selected VAR. */
    deviceGroupStore: DeviceGroupsStore;
}
export interface IVARSelectModalProps {
    className?: string;
    onChange: (value: any) => void;
}

/**
 * If current user is DPU, the user must be select a VAR to continue work on component
 * @param Component The input component require to select VAR first
 * @returns The function component with valid accountId (VAR or CLIENT)
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const VarModalComponent = (Component) => {
    return <T extends any, U extends IVarAccountProps>(props: T): React.ReactElement<U> => {
        const authStore = useAuthStore();
        const [searchParams, setSearchParams] = useSearchParams();
        const state = useLocation().state;
        const [varOrClientAccountId, setVAROrClientAccountId] = React.useState<number>(() => (
            toNumber(searchParams.get('accountId')) || (
                authStore.currentUser.accountType === AccountType.DPU ? null : authStore.currentUser.accountId
            )
        )); // accountId to get API endpoint

        React.useEffect(() => {
            if (!varOrClientAccountId) return;
            setSearchParams({ accountId: `${varOrClientAccountId}` }, { state });
        }, [varOrClientAccountId]);

        if (!varOrClientAccountId) return <VARSelectModal onChange={setVAROrClientAccountId} />;
        return <Component {...(props || {})} varOrClientAccountId={varOrClientAccountId} />;
    };
};
