import * as React from 'react';
import { observer } from 'mobx-react';
import { AdvanceTable, IBasicTableColumn, IBasicTableProps, DangerAlert, CheckboxField} from 'ui-lib';
import { DevicePackagesStore, IDisplayDevicePackage } from '../../../../store/device-details/device-packages-store';
import { IDeviceTableProps } from './device-details';
import devicesCss from './devices.css';
import { PACKAGE_TYPE } from 'dto/packages-dto';
import { getRangeDateTimeFilterColumnProps } from '../../components/table-utils';

const INSTALLED_PACKAGE_STATUS = {
    INSTALLED: true,
    DOWNLOAD_ONLY: false,
};
export const DevicePackagesTableComponent = observer((props: IDeviceTableProps<DevicePackagesStore>) => {
    const { store } = props;
    React.useEffect(() => {
        store.initialize();
    }, []);

    const columns: IBasicTableColumn[] = [
        {
            code: 'packageName',
            title: 'Package',
            dataIndex: 'packageName',
            width: '35%',
            sorter: (a: IDisplayDevicePackage, b: IDisplayDevicePackage) => a.packageName?.localeCompare(b.packageName),
            textSearchPlaceholder: 'Search Package Name',
            onFilter: (packageName: string, pkg: IDisplayDevicePackage) =>
                pkg.packageName?.toLowerCase().includes(packageName.toLowerCase()),
            iconName: 'search',
        },
        {
            code: 'version',
            title: 'Version',
            dataIndex: 'version',
            width: '20%',
            sorter: (a: IDisplayDevicePackage, b: IDisplayDevicePackage) => a.version?.localeCompare(b.version),
            textSearchPlaceholder: 'Search Version',
            onFilter: (version: string, pkg: IDisplayDevicePackage) =>
                pkg.version?.toLowerCase().includes(version.toLowerCase()),
            iconName: 'search',
        },
        {
            code: 'type',
            title: 'Type',
            dataIndex: 'type',
            width: '10%',
            sorter: (a: IDisplayDevicePackage, b: IDisplayDevicePackage) => a.type?.localeCompare(b.type),
            defaultSortOrder: 'ascend',
            filters: Object.keys(PACKAGE_TYPE).map((type) => ({ text: PACKAGE_TYPE[type], value: PACKAGE_TYPE[type] })),
            onFilter: (type: string, item: IDisplayDevicePackage) => item.type == type,
        },
        {
            code: 'arch',
            title: 'Architecture',
            dataIndex: 'arch',
            width: '10%',
            filters: store.archFilterTypes,
            onFilter: (type: string, item: IDisplayDevicePackage) => item.arch == type,
            sorter: (a: IDisplayDevicePackage, b: IDisplayDevicePackage) => a.arch?.localeCompare(b.arch),
        },
        {
            code: 'installedAtTimestamp',
            title: 'Last Updated',
            dataIndex: 'installedAtTimestamp',
            width: '20%',
            defaultSortOrder: 'descend',
            ...getRangeDateTimeFilterColumnProps('installedAtTimestamp'),
            sorter: (a: IDisplayDevicePackage, b: IDisplayDevicePackage) =>
                a.installedAtTimestamp?.localeCompare(b.installedAtTimestamp),
        },
        {
            code: 'installed',
            title: 'Installed',
            dataIndex: 'installed',
            width: '5%',
            filters: Object.keys(INSTALLED_PACKAGE_STATUS).map(status => ({ text: status, value: INSTALLED_PACKAGE_STATUS[status] })),
            onFilter: (status: boolean, devicePackage: IDisplayDevicePackage) => devicePackage.installed === status,
            render: (value: boolean, record: IDisplayDevicePackage) => <CheckboxField label='' checked={value}/>
        },
    ];

    const table: IBasicTableProps = { columns, dataSource: store.displayPackages };

    return (
        <div className={devicesCss.tabPane}>
            {store.error != undefined && <DangerAlert closable={false} message='Error' description={store.error} />}
            <AdvanceTable
                title=''
                table={table}
                loadingState={store.loading}
                toolbar={{
                    onReload: () => store.fetchLatestData(),
                }}
            />
        </div>
    );
});
