import { IApiClientOptions } from 'core';
import {
    ICommandExecutionDetailsResponse,
    IExecuteCommandBodyParameters,
    IExecuteCommandStatusResponse,
    IGetCommandExecutionDetailsRequest,
    IRerunExecutionProps,
    ITriggerCommandResult,
} from '../dto/remote-commands';
import { chunk } from 'lodash';
import { sleep } from 'common-utils';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';

export interface IGetCommandStatusRequest {
    targetDeviceNames: string[];
    executionNumber?: number;
}

export interface ICancelRemoteCommandRequest {
    targetDeviceNames?: string[];
    targetGroupNames?: string[];
}
export class RemoteCommandsAPiClient extends AssureBaseApiClient {

    constructor(options: IApiClientOptions) {
        super('remoteCommand', options);
    }

    protected getRootPath(): string {
        return 'commands';
    }

    async getCommandStatusByExecutionId(
        accountId: number,
        executionId: string,
        query?: IGetCommandStatusRequest,
    ): Promise<IExecuteCommandStatusResponse> {
        if (!query?.targetDeviceNames) return this.send<any>(HTTP_METHOD.GET, this.getRequestOptionsWithAccountId(accountId, `${executionId}`, query));

        const getResponse = async (targetDeviceNames: string[]) => {
            return this.send<any>(HTTP_METHOD.GET, this.getRequestOptionsWithAccountId(accountId, `${executionId}`, { ...query, targetDeviceNames }));
        };

        const DEVICES_PER_BATCH = 10;
        const deviceBatches = chunk(query.targetDeviceNames, DEVICES_PER_BATCH);
        const [firstDevicesBatch, ...rest] = deviceBatches;
        const commandResponse: IExecuteCommandStatusResponse = await getResponse(firstDevicesBatch);
        for (const devicesQuery of rest) {
            await sleep(500); // Prevent catching the limit per second
            const result = await getResponse(devicesQuery);
            commandResponse.devices.push(...result.devices);
        }
        return commandResponse;
    }

    getCommandExecutionDetailsByExecutionId(
        accountId: number,
        executionId: string,
        query: IGetCommandExecutionDetailsRequest,
    ): Promise<ICommandExecutionDetailsResponse> {
        return this.send<ICommandExecutionDetailsResponse>(HTTP_METHOD.GET, this.getRequestOptionsWithAccountId(accountId, `${executionId}/details`, query));
    }

    public async triggerRemoteCommand(accountId: number, params: IExecuteCommandBodyParameters): Promise<ITriggerCommandResult> {
        return this.send<ITriggerCommandResult>(HTTP_METHOD.POST, this.getRequestOptionsWithAccountId(accountId, 'execute', params));
    }

    public async cancelRemoteCommandByExecutionId(
        accountId: number,
        executionId: string,
        params: ICancelRemoteCommandRequest,
    ): Promise<void> {
        return this.send(HTTP_METHOD.POST, this.getRequestOptionsWithAccountId(accountId, `${executionId}/cancel`, params));
    }

    public async rerunExecutionCommand(
        accountId: number,
        executionId: string,
        params: IRerunExecutionProps,
    ): Promise<ITriggerCommandResult> {
        return this.send<ITriggerCommandResult>(HTTP_METHOD.POST, this.getRequestOptionsWithAccountId(accountId, `${executionId}/rerun`, params));
    }
}
