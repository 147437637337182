import * as React from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    BasicForm,
    ICardProps,
    FormItem,
    TextFieldItem,
    EmailFieldItem,
    SelectFormItem,
    MultipleSelectFormItem,
    IconTextButton,
    Skeleton,
    Spinner,
    DangerAlert,
    showInformationDialog,
} from 'ui-lib';

import { getErrorMessage, isNullOrEmpty } from 'common-utils';

import { useAccountStore, useAuthStore, useRoleStore, useRoutesStore, useUserStore } from '../../../../store';
import { IEditUserProps, ISaveUserProps, IUser } from '../../../../dto/access-management/user-dto';
import { OrganisationSelect } from '../../components/organisation-select';
import { confirmCancel, deleteUser } from '../utils';
import { USER_ROUTE_CODES } from './';

import manageUserCss from './user.css';

const INVALID_EMAIL_ERROR = 'This email address is already being used';

function validateEmail(email: string) {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
}

interface IAddOrEditUserComponentProps {
    isEdit?: boolean;
    cardPros?: ICardProps;
    userInfo: IEditUserProps;
}

const SaveUser = (props: IAddOrEditUserComponentProps): JSX.Element => {
    const { currentUser, isDPUAdmin } = useAuthStore();
    const accountStore = useAccountStore();
    const userStore = useUserStore();
    const roleStore = useRoleStore();
    const navigate = useNavigate();

    const routeStore = useRoutesStore();
    const rootRoute = routeStore.getRoute(USER_ROUTE_CODES.ROOT);

    const [userInView, setUserInView] = React.useState(props.userInfo);
    const [disableComponent, setDisableComponent] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [isProcessing, setIsProcessing] = React.useState(false);

    const [selectedOrganisation, setSelectedOrganisation] = React.useState(
        !props.userInfo.organisationId ? currentUser.accountId : props.userInfo.organisationId,
    );

    const [selectedRole, setSelectedRole] = React.useState(props.userInfo.userRoleId);

    const [allApplications, setAllApplications] = React.useState(props.userInfo.selectedApplications);
    const [selectedApplications, setSelectedApplications] = React.useState(props.userInfo.selectedApplications);

    const onLoad = () => {
        (async () => {
            try {
                setIsProcessing(true);
                await roleStore.loadRoles();
                roleStore.setOrganisationType(currentUser.accountType);
                onSelectedOrganisationChange(selectedOrganisation);
            } catch (error) {
                setErrorMessage(getErrorMessage(error));
            } finally {
                setIsProcessing(false);
            }
        })();
    };

    React.useEffect(onLoad, []);

    const onSelectedOrganisationChange = (organisationId: number) => {
        try {
            setIsProcessing(true);
            const organisation = accountStore.getAccountById(organisationId);
            if (organisation) {
                const allApplicationsForOrg = organisation.applications.map((app) => ({
                    id: app.id,
                    value: app.accessId,
                    label: app.label,
                }));
                roleStore.setOrganisationType(organisation.accountType);
                setSelectedRole((current) => roleStore.findRole(current)?.value as number);
                setSelectedOrganisation(organisation.id);
                setAllApplications(allApplicationsForOrg);
                setSelectedApplications((currentApps) => {
                    const appValues = currentApps.map((item) => item.value);
                    return allApplicationsForOrg.filter((item) => appValues.includes(item.value));
                });
            } else {
                setAllApplications([]);
                setSelectedApplications([]);
            }
        } finally {
            setIsProcessing(false);
        }
    };

    const onSave = async (values: any) => {
        try {
            setIsProcessing(true);
            setErrorMessage('');
            const saveUserInfo: ISaveUserProps = {
                id: userInView.id,
                name: values.name,
                email: values.email,
                username: values.email,
                organisationId: selectedOrganisation,
                userRoleId: values.userRole,
                oldUserRoleId: values.userRole === userInView.oldUserRoleId ? undefined : userInView.oldUserRoleId,
                selectedApplicationAccessIds: selectedApplications.map((item) => item.value),
            };

            await userStore.saveUser(userInView.id, saveUserInfo);
            navigateToList();
        } catch (error) {
            setErrorMessage(getErrorMessage(error));
        } finally {
            setIsProcessing(false);
        }
    };

    const onEmailBlur = async (event: any): Promise<void> => {
        setErrorMessage('');
        const email = event.target.value;
        if (props.isEdit || email.toLowerCase() === userInView.email.toLowerCase())
            return;

        try {
            setIsProcessing(true);
            if (!validateEmail(email) || userStore.isUserExisting(email))
                return;

            setUserInView({ ...userInView, email });
            const existingUser = await userStore.getUserByEmail(email);

            if (existingUser) {
                if (!isDPUAdmin) {
                    setDisableComponent(true);
                    showInformationDialog({
                        title: 'Add new user',
                        content: INVALID_EMAIL_ERROR,
                        modalType: 'error',
                    });
                    return;
                }
                setUserInView(createIEditUserProps({ ...existingUser, roles: undefined }));
                setSelectedOrganisation(existingUser.account?.id);
                roleStore.setOrganisationType(existingUser.account.accountType);
            } else if (disableComponent) {
                setUserInView({ ...props.userInfo, email });
            }
            setDisableComponent(!!existingUser);
        } catch (error) {
            setErrorMessage(getErrorMessage(error));
        } finally {
            setIsProcessing(false);
        }
    };

    const navigateToList = (): void => {
        navigate(rootRoute.path);
    };

    const onCancel = (): void => {
        confirmCancel(navigateToList);
    };

    const onNameChange = (event: any) => (userInView.name = event.target.value);
    const onUserRoleChange = (value: any) => setSelectedRole(value);
    const onApplicationChange = (values: any[]) =>
        setSelectedApplications(() => allApplications.filter((item) => values.includes(item.value)));

    const emailValidator = (rule, value, callback) =>
        callback(props.isEdit || !userStore.isUserExisting(value) ? undefined : INVALID_EMAIL_ERROR);

    const email = (
        <EmailFieldItem
            code='email'
            label='Email'
            labelAlign='right'
            isRequired={true}
            initialValue={userInView.email}
            disabled={props.isEdit}
            onBlur={onEmailBlur}
            validator={emailValidator}
        />
    );
    const name = (
        <TextFieldItem
            code='name'
            label='Name'
            labelAlign='right'
            isRequired={true}
            initialValue={userInView.name}
            disabled={disableComponent}
            onChange={onNameChange}
        />
    );

    const organisation = (
        <FormItem
            code='organisation'
            label='Organisation'
            labelAlign='right'
            labelCol={{ span: 5 }}
            colon={false}
            required={true}
            child={
                <OrganisationSelect
                    value={selectedOrganisation}
                    expandAll={true}
                    treeDataSource={accountStore.treeDataSource}
                    onChange={onSelectedOrganisationChange}
                />
            }
        />
    );
    const userRole = (
        <SelectFormItem
            code='userRole'
            label='User Role'
            labelAlign='right'
            isRequired={true}
            selectedValue={selectedRole}
            dataSource={roleStore.selectDataSource}
            onChange={onUserRoleChange}
        />
    );
    const applications = (
        <MultipleSelectFormItem
            isRequired={true}
            code='applications'
            label='Applications'
            labelAlign='right'
            selectedValue={selectedApplications}
            dataSource={allApplications}
            onChange={onApplicationChange}
        />
    );
    const cancelButton = <IconTextButton key='cancel' label='Cancel' onClick={onCancel} />;
    const submitButton = (
        <IconTextButton key='save' label='Save' type='primary' htmlType='submit' loading={isProcessing} />
    );
    const formFunctions = <div className={manageUserCss.control}>{[cancelButton, submitButton]}</div>;

    const SaveForm = observer(() => {
        return (
            <BasicForm
                key='addOrEdit'
                className={manageUserCss.save_form}
                cardPros={props.cardPros}
                items={[
                    email,
                    name,
                    organisation,
                    userRole,
                    applications,
                    formFunctions,
                ]}
                onSubmit={onSave}
            />
        );
    });

    return (
        <React.Fragment>
            {!errorMessage ? null : <DangerAlert message={errorMessage} className={manageUserCss.alert} />}
            <Spinner
                className={manageUserCss.spinner}
                spinning={isProcessing}
            >
                <SaveForm />
            </Spinner>
        </React.Fragment>
    );
};

export function AddUserComponent(): JSX.Element {
    const { currentUser } = useAuthStore();
    const searchParams = new URLSearchParams(location.search);
    const orgId = parseInt(searchParams.get('org'));
    const activeApplication = currentUser.applications[0];
    return (
        <SaveUser
            cardPros={{ bordered: false }}
            userInfo={{
                name: '',
                username: '',
                email: '',
                organisationId: Number.isNaN(orgId) ? undefined : orgId,
                userRoleId: undefined,
                selectedApplications: [
                    {
                        id: activeApplication?.id,
                        value: activeApplication?.accessId,
                        label: activeApplication?.label,
                    },
                ],
            }}
        />
    );
}

function createIEditUserProps(user: IUser): IEditUserProps {
    const userRoleId = isNullOrEmpty(user.roles) ? undefined : user.roles[0].id;
    return {
        id: user.id,
        username: user.username || user.email,
        name: user.name,
        email: user.email,
        organisationId: user.account.id,
        userRoleId,
        oldUserRoleId: userRoleId,
        selectedApplications: user.applications?.map((app) => ({
            id: app.id,
            accessId: app.accessId,
            value: app.accessId,
            label: app.label,
        })),
    };
}

export function EditUserComponent(): JSX.Element {
    const userStore = useUserStore();
    const navigate = useNavigate();

    const routeStore = useRoutesStore();
    const rootRoute = routeStore.getRoute(USER_ROUTE_CODES.ROOT);

    const [editUser, setEditUser] = React.useState(undefined);
    const [loadingUser, setLoadingUser] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const { id } = useParams();

    const onLoad = (): void => {
        (async (): Promise<void> => {
            try {
                setLoadingUser(true);
                setErrorMessage(null);
                const editUser = await userStore.getUserById(id);
                if (!editUser || editUser == null) {
                    navigate(rootRoute.path);
                    return null;
                }
                setEditUser(createIEditUserProps(editUser));
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            } finally {
                setLoadingUser(false);
            }
        })();
    };
    React.useEffect(onLoad, []);

    const onDelete = async (id: string): Promise<void> => {
        await userStore.deleteUser(id).catch(err => setErrorMessage(getErrorMessage(err)));
        navigate(rootRoute.path);
    };
    const deleteButton = (
        <IconTextButton
            label='Delete'
            type='link'
            htmlType='button'
            onClick={(): void => deleteUser(editUser.id, onDelete)}
        />
    );

    return errorMessage ? (
        <DangerAlert message={errorMessage} className={manageUserCss.alert} />
    ) : (
        <Skeleton loading={loadingUser}>
            {!editUser ? null : (
                <SaveUser isEdit={true} cardPros={{ title: editUser.name, extra: deleteButton }} userInfo={editUser} />
            )}
        </Skeleton>
    );
}
