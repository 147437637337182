import * as React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { Route } from 'core';
import { Error401, Error404 } from '../errors';

import pageCss from './page.css';

export interface IPageProps {
    className?: string;
    title?: React.ReactNode;
    children?: React.ReactNode;
}

export const Page = (props: IPageProps) => {
    return <div className={cx(pageCss.wrapper, props.className)}>{props.children}</div>;
};

export interface IPermissionPageProps extends IPageProps {
    routes: Route[];
    permissionCodes: string[];
}

export const PermissionPage = (props: IPermissionPageProps) => {
    const errorPage = getErrorPage(props);
    return errorPage != null ? (
        errorPage
    ) : (
        <div className={cx(pageCss.wrapper, props.className)}>
            {props.title ? <h1>{props.title}</h1> : null}
            {props.children}
        </div>
    );
};

function getErrorPage(props: IPermissionPageProps) {
    const path = useLocation().pathname;
    const matchedRoute = props.routes?.find((route) => matchPath(route, path));
    if (!matchedRoute) return <Error404 message='Page Not Found' />;
    if (!matchedRoute.hasPermission(props.permissionCodes)) return <Error401 message='No permission to access' />;
    return null;
}
