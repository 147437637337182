import * as React from 'react';
import { observer } from 'mobx-react';
import { DangerAlert } from 'ui-lib';
import devicesCss from './devices.css';
import { DeviceCryptoReportStore } from '../../../../store/device-details/device-crypto-report-store';

export const DeviceCryptoReportComponent = observer((props: { store: DeviceCryptoReportStore }) => {
    const { store } = props;
    const [cryptoReport, setCryptoReport] = React.useState<string>();
    React.useEffect(() => {
        (async () => {
            setCryptoReport('Loading the report......');
            await store.initialize();
            setCryptoReport(store.displayCryptoReport);
        })();
    }, []);

    return (
        <div className={devicesCss.tabPane}>
            {store.error != undefined && <DangerAlert closable={false} message='Error' description={store.error} />}
           <pre>{cryptoReport}</pre>
           
        </div>
    );
});
