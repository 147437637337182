export interface IGetNotificationsRequestProps {
    sender?: string;
    receiverUserUuid?: string;
    dpuNotification?: boolean;
    messageType?: string;
}

export enum MESSAGE_TYPE {
    DEVICE = 'Device',
    REMOTE_COMMAND = 'Remote Command',
    SOFTWARE_UPDATE = 'Software Update',
    FIRMWARE_UPDATE = 'Firmware Update',
    CONFIGURATION_UPDATE = 'Configuration Update',
    KEY_DISTRIBUTION = 'Key Distribution',
    GPG_KEYS = 'GPG Keys',
    DPU_UPDATE = 'DPU Update',
}

export enum RELEASE_NOTIFICATION_TYPE {
    ALM = 'ALM',
    VOS = 'VOS',
    SCR = 'SCR',
    CONFIGURATION = 'Configuration',
}

export interface IContentData {
    releaseType: RELEASE_NOTIFICATION_TYPE;
    releaseDate: number;
    releaseNotes?: string;
    clientId?: number;
    [key: string]: any;
}

export interface INotificationContent {
    title: string;
    messageType: string;
    text: string;
    dateToDisplay?: number;
    data?: IContentData;
}

export enum NOTIFICATION_TYPE {
    BROADCAST = 'broadcast',
    PERSONAL = 'personal',
}

export interface INotification {
    id: number;
    type: NOTIFICATION_TYPE;
    title: string;
    text: string;
    receiverUserUuid: string;
    sender: string;
    createdAt: string;
    accountUuid?: string;
    content: INotificationContent;
    markRead?: boolean;
}

export interface ICreateNotificationRequestProps {
    type: NOTIFICATION_TYPE;
    receiverUserUuid?: string;
    sender: string;
    content: INotificationContent;
}

export interface IUpdateNotificationRequestProps {
    title: string;
    text: string;
    dateToDisplay: number;
    messageType: MESSAGE_TYPE | string;
    data?: IContentData;
}
