import React from 'react';

import { observer } from 'mobx-react';
import { action, computed, makeObservable, observable } from 'mobx';
import { IBasicTableColumn, SearchField, BasicBadge, Empty, Spinner } from 'ui-lib';
import { DEVICE_TYPE, DEVICE_CONNECTION_STATUS, IDeviceInfo, ISearchDeviceInfo } from '../../../dto/device-dto';
import { IReportTableProps, ReportTable } from './report-table';
import { getRangeDateTimeFilterColumnProps } from './table-utils';
import { DISPLAY_DEVICE_STATUS, DISPLAY_STATUS_LIST, isOnlineDevice } from 'common-utils';
import { formatDate } from 'common-utils/date-utils';
import { DeviceUuidHyperlink } from './device-uuid-hyperlink-component';

export interface ISelectedRows {
    selectedKeys: string[] | number[];
    selectedValues: any[];
}

export interface IDevicesProps<T> extends Omit<IReportTableProps, 'table' | 'toolbar'> {
    dataSource: T[];
    buildDeviceLinkProps?: (record: T) => { accountId: number; state?: { [key: string]: any; } };
    displayStatus?: boolean;
    extraColumns?: IBasicTableColumn[];
    defaultSortColumn?: {
        key: DEFAULT_DEVICE_COLUMN | string;
        sortOrder?: TSortOrder;
    };
    hiddenDefaultColumns?: DEFAULT_DEVICE_COLUMN[];
    rowSelection?: {
        selectedRows: ISelectedRows;
        onChange: (selectedRows: ISelectedRows) => any | Promise<any>;
    };
    extraItems?: React.ReactNode[];
    defaultEmptyText?: string;
    searchOptions?: {
        placeholder?: string;
        columns?: SEARCH_COLUMN[];
        emptyText?: string;
    };
    tableScroll?: {
        x?: number | true | string;
        y?: number | string;
    };
    onTableChange?: (pagination: any, filters: any, sorter: any, extra: any) => void;
    pagination?: boolean;
    defaultFilters?: {
        [key: string]: string[],
    };
}

export enum DEFAULT_DEVICE_COLUMN {
    TYPE = 'type',
    UID = 'UID',
    SERIAL = 'serialNumber',
    ESN = 'esn',
    DEVICE_GROUP = 'deviceGroupLabel',
    STATUS = 'status',
    LAST_UPDATE = 'lastUpdated',
}

export enum SEARCH_COLUMN {
    UID = DEFAULT_DEVICE_COLUMN.UID,
    SERIAL = DEFAULT_DEVICE_COLUMN.SERIAL,
    ESN = DEFAULT_DEVICE_COLUMN.ESN,
}

type TSortOrder = 'ascend' | 'descend' | null;

const DEFAULT_EMPTY_TEXT = 'No data available';
export const DEFAULT_EMPTY_TEXT_FOR_SEARCH = 'The search didn\'t return any results.';

class DevicesTableStore<T extends Partial<IDeviceInfo>> {
    @observable private _message: string;
    @observable private _dataSource: T[];
    @observable private _selectedRows: ISelectedRows;
    @observable private _searchText = '';

    private readonly _emptyMessage: string;
    private readonly _emptyMessageForSearch: string;
    private readonly _searchColumns: SEARCH_COLUMN[];

    constructor(
        dataSource: T[],
        selectedRows: ISelectedRows,
        searchColumns: SEARCH_COLUMN[],
        emptyMessage: string,
        emptyMessageForSearch: string,
    ) {
        this._message = emptyMessage;
        this._dataSource = dataSource;
        this._selectedRows = selectedRows;

        this._searchColumns = searchColumns;
        this._emptyMessage = emptyMessage;
        this._emptyMessageForSearch = emptyMessageForSearch;

        makeObservable(this);
    }

    @computed
    public get searchText(): string {
        return this._searchText;
    }

    @action.bound
    public setSearchText(searchText: string) {
        this._searchText = searchText;
    }

    @computed
    public get message(): string {
        return this._message;
    }

    @action
    private setMessage(message: string) {
        this._message = message;
    }

    @computed
    public get dataSource(): T[] {
        const searchColumns = this._searchColumns || Object.values(SEARCH_COLUMN);
        const data = this._dataSource?.filter((item) =>
            searchColumns?.some((columnKey) => item[columnKey]?.toLowerCase().includes(this.searchText?.toLowerCase())),
        );
        if (!data || data.length === 0)
            this.setMessage(!this._searchText ? this._emptyMessage : this._emptyMessageForSearch);
        return data;
    }

    @computed
    public get selectedRows(): ISelectedRows {
        return this._selectedRows;
    }

    @action
    public setSelectedRows(selectedRows: ISelectedRows) {
        this._selectedRows = selectedRows;
    }
}

export const DevicesTableComponent = observer(<T extends Partial<IDeviceInfo>>(props: IDevicesProps<T>) => {
    const searchPlaceholder = props.searchOptions?.placeholder || 'Search Devices';
    const defaultFilters = props.defaultFilters || {};

    const devicesTableStore = React.useMemo(
        () =>
            new DevicesTableStore(
                props.dataSource,
                props.rowSelection?.selectedRows,
                props.searchOptions?.columns,
                props.defaultEmptyText || DEFAULT_EMPTY_TEXT,
                props.searchOptions?.emptyText || DEFAULT_EMPTY_TEXT_FOR_SEARCH,
            ),
        [props.dataSource],
    );

    const getDefaultSortOrder = (key: DEFAULT_DEVICE_COLUMN | string): TSortOrder =>
        props.defaultSortColumn?.key !== key ? null : props.defaultSortColumn.sortOrder || 'descend';

    const rowSelection = !props.rowSelection
        ? null
        : {
            selectedRowKeys: devicesTableStore.selectedRows?.selectedKeys,
            selectedRowValue: devicesTableStore.selectedRows?.selectedValues,
            onChange: (selectedKeys: string[] | number[], selectedValues: T[]) => {
                const currentSelectedRows = { selectedKeys, selectedValues };
                devicesTableStore.setSelectedRows(currentSelectedRows);
                props.rowSelection.onChange(currentSelectedRows);
            },
        };

    const defaultColumns: IBasicTableColumn[] = [
        {
            code: DEFAULT_DEVICE_COLUMN.TYPE,
            title: 'Type',
            dataIndex: DEFAULT_DEVICE_COLUMN.TYPE,
            defaultSortOrder: getDefaultSortOrder(DEFAULT_DEVICE_COLUMN.TYPE),
            sorter: (a: T, b: T) => a.type?.toString().localeCompare(b.type.toString()),
            filters: Object.values(DEVICE_TYPE).map(type => ({ text: type, value: type })),
            onFilter: (type: DEVICE_TYPE, device: T) => device.type === type,
            defaultFilteredValue: defaultFilters[DEFAULT_DEVICE_COLUMN.TYPE],
        },
        {
            code: DEFAULT_DEVICE_COLUMN.UID,
            title: 'UID',
            width: '300px',
            dataIndex: DEFAULT_DEVICE_COLUMN.UID,
            render: (text: string, record: T): React.ReactNode => {
                if (!props.buildDeviceLinkProps) return text;
                const navigateOptions = props.buildDeviceLinkProps(record);

                return (
                    <DeviceUuidHyperlink
                        uid={text}
                        accountId={navigateOptions.accountId}
                        locationState={navigateOptions.state}
                    />
                );
            },
            defaultSortOrder: getDefaultSortOrder(DEFAULT_DEVICE_COLUMN.UID),
            sorter: (a: T, b: T) => a.UID.localeCompare(b.UID),
            textSearchPlaceholder: 'Search UID',
            onFilter: (uid: string, device: T) => device.UID.toLowerCase().includes(uid.toLowerCase()),
            iconName: 'search',
            defaultFilteredValue: defaultFilters[DEFAULT_DEVICE_COLUMN.UID],
        },
        {
            code: DEFAULT_DEVICE_COLUMN.SERIAL,
            title: 'Serial Number',
            dataIndex: DEFAULT_DEVICE_COLUMN.SERIAL,
            defaultSortOrder: getDefaultSortOrder(DEFAULT_DEVICE_COLUMN.SERIAL),
            sorter: (a: T, b: T) => a.serialNumber?.localeCompare(b.serialNumber),
            textSearchPlaceholder: 'Search Serial Number',
            onFilter: (serialNumber: string, device: T) =>
                device.serialNumber?.toLowerCase().includes(serialNumber.toLowerCase()),
            iconName: 'search',
            defaultFilteredValue: defaultFilters[DEFAULT_DEVICE_COLUMN.SERIAL],
        },
        {
            code: DEFAULT_DEVICE_COLUMN.ESN,
            title: 'eSN',
            dataIndex: DEFAULT_DEVICE_COLUMN.ESN,
            defaultSortOrder: getDefaultSortOrder(DEFAULT_DEVICE_COLUMN.ESN),
            sorter: (a: T, b: T) => a.esn?.localeCompare(b.esn),
            textSearchPlaceholder: 'Search eSN',
            onFilter: (esn: string, device: T) => device.esn?.toLowerCase().includes(esn.toLowerCase()),
            iconName: 'search',
            defaultFilteredValue: defaultFilters[DEFAULT_DEVICE_COLUMN.ESN],
        },
        ...(props.displayStatus ? [{
            code: DEFAULT_DEVICE_COLUMN.STATUS,
            title: 'Status',
            dataIndex: DEFAULT_DEVICE_COLUMN.STATUS,
            defaultSortOrder: getDefaultSortOrder(DEFAULT_DEVICE_COLUMN.STATUS),
            sorter: (a: T, b: T) => a.status?.toString().localeCompare(b.status.toString()),
            filters: DISPLAY_STATUS_LIST.map(status => ({ text: status, value: status })),
            onFilter: (status: DISPLAY_DEVICE_STATUS, device: T) => device.status === status,
            render: (text: string, record: T) => {
                if (record.status == DEVICE_CONNECTION_STATUS.LOADING)
                    return <Spinner size='medium' />;
                const status = isOnlineDevice(record.status) ? 'success' : 'warning';
                return <BasicBadge status={status} text={text} />;
            },
            defaultFilteredValue: defaultFilters[DEFAULT_DEVICE_COLUMN.STATUS],
        }] : []),
        {
            code: DEFAULT_DEVICE_COLUMN.DEVICE_GROUP,
            title: 'Device Group',
            dataIndex: DEFAULT_DEVICE_COLUMN.DEVICE_GROUP,
            defaultSortOrder: getDefaultSortOrder(DEFAULT_DEVICE_COLUMN.DEVICE_GROUP),
            sorter: (a: T, b: T) => a.deviceGroupLabel?.localeCompare(b.deviceGroupLabel),
            textSearchPlaceholder: 'Search Device Group',
            onFilter: (deviceGroupLabel: string, device: T) =>
                device.deviceGroupLabel?.toLowerCase().includes(deviceGroupLabel.toLowerCase()),
            iconName: 'search',
            defaultFilteredValue: defaultFilters[DEFAULT_DEVICE_COLUMN.DEVICE_GROUP],
        },
        {
            code: DEFAULT_DEVICE_COLUMN.LAST_UPDATE,
            title: 'Last Updated',
            dataIndex: DEFAULT_DEVICE_COLUMN.LAST_UPDATE,
            defaultSortOrder: getDefaultSortOrder(DEFAULT_DEVICE_COLUMN.LAST_UPDATE),
            sorter: (a: T, b: T) => a.lastUpdated - b.lastUpdated,
            ...getRangeDateTimeFilterColumnProps('lastUpdated'),
            render: (text: string, record: T): React.ReactNode => {
                if (record.lastUpdated == Number.MIN_VALUE)
                    return <Spinner size='medium' />;
                return formatDate(record.lastUpdated);
            },
            defaultFilteredValue: defaultFilters[DEFAULT_DEVICE_COLUMN.LAST_UPDATE],
        },
        ...(props.extraColumns || []),
    ];

    const buildTableToolbar = (): React.ReactNode[] => {
        const tableToolbar = [];
        if (props.searchOptions)
            tableToolbar.push(
                <SearchField
                    key='searchItems'
                    allowClear={true}
                    placeholder={searchPlaceholder}
                    onSearch={devicesTableStore.setSearchText}
                />,
            );
        props.extraItems && tableToolbar.push(props.extraItems);

        return tableToolbar;
    };

    const hideExportItem =
        props.exportOptions?.hideExportItem === undefined ? true : props.exportOptions?.hideExportItem; // Default is hidden for this component

    return <ReportTable
        {...props}
        className={props.className}
        exportOptions={{ ...props.exportOptions, hideExportItem }}
        toolbar={{ extraItems: buildTableToolbar() }}
        table={{
            rowSelection,
            rowKey: 'UID',
            columns: defaultColumns.filter(
                (column) => !props.hiddenDefaultColumns?.includes(column.code as DEFAULT_DEVICE_COLUMN),
            ),
            dataSource: devicesTableStore.dataSource,
            scroll: props.tableScroll,
            extraOptions: {
                locale: { emptyText: <Empty image='simple' description={devicesTableStore.message} /> },
                pagination: props.pagination,
            },
            onTableChange: props.onTableChange,
        }}
    />;
});
