import * as React from 'react';
import { Tag } from 'antd';
import tagsCss from './tags.css';

export interface IBaseBadgeProps {
    value: React.ReactNode;
}

export const SuccessTag = (props: IBaseBadgeProps) => (
    <Tag
        className={tagsCss.success}
        color="success"
    >
        {props.value}
    </Tag>
);

export const InfoTag = (props: IBaseBadgeProps): JSX.Element => (
    <Tag
        className={tagsCss.success}
        color="default"
    >
        {props.value}
    </Tag>
);

export const DangerTag = (props: IBaseBadgeProps) => (
    <Tag
        className={tagsCss.danger}
        color="error"
    >
        {props.value}
    </Tag>
);

export const WarningTag = (props: IBaseBadgeProps) => (
    <Tag
        className={tagsCss.warning}
        color="warning"
    >
        {props.value}
    </Tag>
);