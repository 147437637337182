import React from 'react';
import { uniq, filter, map, reduce, differenceWith } from 'lodash';

import {
    AdvanceTable,
    DangerTag,
    IBasicTableColumn,
    SuccessTag,
    Typography,
    showInformationDialog,
} from 'ui-lib';
import { isNullOrEmpty } from 'common-utils';
import {
    IDetectPackageDependenciesResult,
    IPackageDependencyProps,
    IPackageProps, PACKAGE_ARCH,
    PACKAGE_TYPE,
} from 'dto/packages-dto';
import { hasContainsIgnoreCase } from 'common-utils/string-utils';
import componentCss from './component.css';

export interface IDuplicatePackageProps {
    label: string;
    version: string;
}
export const showDuplicatePackagesModal = <T extends IDuplicatePackageProps>(props: { packages: T[] }): boolean => {
    const { packages = [] } = props;
    const duplicates = reduce(filter(
        uniq<T>(
            map(packages, function (item) {
                if (filter(packages, { label: item.label }).length > 1) {
                    return item;
                }
                return undefined;
            })),
        value => !!value),
        (prev, current) => {
            const index = prev.findIndex(item => item.label == current.label);
            if (index == -1) {
                prev.push({ label: current.label, versions: [<DangerTag key={current.version} value={`${current.version}`} />] });
            } else {
                const existedItem = prev[index];
                prev[index] = { label: existedItem.label, versions: [...existedItem.versions, <DangerTag key={current.version} value={`${current.version}`} />] };
            }
            return prev;
        }, []);

    if (isNullOrEmpty(duplicates)) {
        return false;
    }
    return !!showInformationDialog({
        title: 'Duplicated packages',
        width: '1000px',
        content: (
            <AdvanceTable
                title=''
                table={{
                    columns: [
                        {
                            code: 'label',
                            dataIndex: 'label',
                            title: 'Package',
                        },
                        {
                            code: 'versions',
                            dataIndex: 'versions',
                            title: 'Versions',
                        },
                        {
                            code: 'architecture',
                            title: 'Architecture',
                            dataIndex: 'architecture',
                        },
                    ],
                    dataSource: duplicates,
                    rowKey: 'label',
                    extraOptions: {
                        pagination: { hideOnSinglePage: true },
                    },
                }}
            />
        ),
        modalType: 'error',
    });
};

interface IMissingPackageDependenciesTableProps {
    missingPackageDependencies: IDetectPackageDependenciesResult[];
    closeButton?: React.ReactNode;
}

const MissingPackageDependenciesTable = (props: IMissingPackageDependenciesTableProps): JSX.Element => {
    const _buildDependencyValue = (dependency: IPackageDependencyProps, isSuccess: boolean): JSX.Element => isSuccess
        ? <SuccessTag key={`${dependency.label}_${dependency.version}`} value={`${dependency.label}${dependency.version ? ` (${dependency.version})` : ''}`} />
        : <DangerTag key={`${dependency.label}_${dependency.version}`} value={`${dependency.label}${dependency.version ? ` (${dependency.version})` : ''}`} />;
    const _buildDependencyValues = (dependencyPackage: IDetectPackageDependenciesResult): JSX.Element[] => {
        const comparator = (a: IPackageDependencyProps, b: IPackageDependencyProps): boolean => {
            return a.label === b.label && a.version == b.version;
        };
        const validDependencies = differenceWith(dependencyPackage.dependencies, dependencyPackage.missingDependencies, comparator);
        return validDependencies
            .map((item) => _buildDependencyValue(item, true))
            .concat(dependencyPackage
                .missingDependencies
                .map((item) => _buildDependencyValue(item, false)));
    };

    return (
        <AdvanceTable
            title={'Missing package dependencies'}
            table={{
                columns: [
                    {
                        code: 'packageLabel',
                        dataIndex: 'packageLabel',
                        title: 'Package',
                    },
                    {
                        code: 'type',
                        dataIndex: 'type',
                        title: 'Type',
                    },
                    {
                        code: 'dependencies',
                        dataIndex: 'dependencies',
                        title: 'Dependencies',
                    },
                ],
                dataSource: props.missingPackageDependencies.map((missingPackage) => {
                    return {
                        id: missingPackage.id,
                        packageLabel: missingPackage.label,
                        dependencies: _buildDependencyValues(missingPackage),
                        type: missingPackage.type,
                    };
                }),
                rowKey: 'id',
                defaultPageSize: 10,
                extraOptions: { pagination: { hideOnSinglePage: true } },
            }}
            toolbar={{
                extraItems: props.closeButton ? [props.closeButton] : []
            }}
        />
    );
};

const defaultWidth = 1000;

interface IMissingPackageDependenciesComponentProps extends Omit<IMissingPackageDependenciesTableProps, 'closeButton'> {
    width?: number;
}

export const showMissingPackageDependenciesModal = (props: IMissingPackageDependenciesComponentProps, onClose?: () => void): boolean => {
    if (isNullOrEmpty(props.missingPackageDependencies)) {
        return false;
    }
    return !!showInformationDialog({
        title: '',
        icon: null,
        width: `${props.width || defaultWidth}px`,
        content: <MissingPackageDependenciesTable {...props} />,
        modalType: 'error',
        afterClose: onClose
    });
};

interface IPackageDetailsProps {
    label: string;
    version: string;
    type: PACKAGE_TYPE;
}

export const showPackageDetailsModal = (packages: IPackageDetailsProps[], description: string): void => {
    if (isNullOrEmpty(packages)) {
        return;
    }
    const columns: IBasicTableColumn[] = [
        {
            code: 'label',
            title: 'Package Name',
            dataIndex: 'label',
            width: '45%',
            sorter: (a: IPackageDetailsProps, b: IPackageDetailsProps) => a.label.localeCompare(b.label),
            onFilter: (text: string, record: IPackageDetailsProps) => hasContainsIgnoreCase(record.label, text),
            textSearchPlaceholder: 'Package label',
            iconName: 'search',
        },
        {
            code: 'version',
            title: 'Version',
            dataIndex: 'version',
            width: '20%',
        },
        {
            code: 'architecture',
            title: 'Architecture',
            dataIndex: 'architecture',
            width: '10%',
            sorter: (a: IPackageProps, b: IPackageProps) => a.architecture.localeCompare(b.architecture),
            filters: Object.keys(PACKAGE_ARCH).map((arch) => ({ text: PACKAGE_ARCH[arch], value: PACKAGE_ARCH[arch] })),
            onFilter: (architecture, pkg) => pkg.architecture == architecture,
        },
        {
            code: 'type',
            title: 'Type',
            dataIndex: 'type',
            width: '20%',
            filters: Object.keys(PACKAGE_TYPE).map((type) => ({ text: PACKAGE_TYPE[type], value: PACKAGE_TYPE[type] })),
            onFilter: (type, pkg) => pkg.type == type,
            sorter: (a: IPackageDetailsProps, b: IPackageDetailsProps) => a.type.localeCompare(b.type),
        },
    ];

    showInformationDialog({
        title: '',
        icon: null,
        width: '1000px',
        modalType: 'info',
        content: (
            <AdvanceTable
                className={componentCss['package-component']}
                title={
                    <div className={componentCss['title']}>
                            Packages
                        <div className={componentCss['description']}>
                            <Typography.Text strong>Description: </Typography.Text><Typography.Text>{description}</Typography.Text>
                        </div>
                    </div>
                }
                table={{
                    columns: columns,
                    dataSource: packages,
                    rowKey: 'id',
                    defaultPageSize: 10,
                    extraOptions: { pagination: { hideOnSinglePage: true } },
                }}
            />
        )
    });
};