const DEFAULT_MSP_PACKAGES: string[] = [
    'busybox',
    'gmp',
    'libiconv',
    'coreutils',
    'diffutils',
    'procps',
    'tar',
    'bzip2',
    'xz',
    'zlib-linux',
    'dpkg',
    'uhubctl',
    'freetype',
    'libnl',
    'coalesce',
    'libgpg-error',
    'libgcrypt',
    'libxxhash',
    'libnettle',
    'gnutls',
    'gnupg',
    'ca-certificates',
    'deb-keyring-sample',
    'apt',
    'alsa',
    'dams-ival',
    'directfb',
    'dropbear-dpu',
    'fdt-taipan',
    'gpsd',
    'iw',
    'libid3tag',
    'libjpeg',
    'libmad',
    'libpng',
    'libshared-process',
    'linux-kernel-taipan',
    'madplay',
    'ncurses',
    'openssl',
    'rfkill',
    'rs9116n',
    'sktcat',
    'support-serpent',
    'tf-a-taipan',
    'u-boot-taipan',
    'wireless-tools',
];

export const isMSPPackage = (packageName: string): boolean => {
    return DEFAULT_MSP_PACKAGES.includes(packageName);
};
