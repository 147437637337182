import { DISPLAY_DEVICE_STATUS } from '../common-utils/device-utils';
import { DEVICE_JOB_EXECUTION_STATUS } from './remote-commands';

export interface IScrFileOptions {
    crc: string;
    location: string;
    name: string;
    secure: boolean;
    size: number;
    valid: boolean;
    version: string;
}

export enum DEVICE_CONNECTION_STATUS {
    ONLINE = 'Online',
    OFFLINE = 'Offline',
    LOADING = 'Loading'
}

export enum DEVICE_STATUS {
    UNASSIGNED = 'UNASSIGNED',
    ASSIGNED = 'ASSIGNED',
    DECOMMISSIONED = 'DECOMMISSIONED',
}

export enum DEVICE_TYPE {
    SCR = 'SCR',
    ADEPT = 'Adept',
    COMPACT = 'Compact',
    RUGGED = 'Rugged',
}

export interface IDeviceInfo {
    UID: string;
    serialNumber?: string;
    esn?: string;
    deviceGroupLabel?: string;
    status?: DISPLAY_DEVICE_STATUS;
    lastUpdated?: number;
    type?: string;
}

export interface ISearchDeviceOptions {
    searchValue?: string;
    searchFields?: string[]; // 'uid', 'serialNumber', 'esn'
    deviceGroupNames?: string;
    deviceTypeName?: string;
    firmwareVersion?: string;
    lastJobSuccessAt?: number;
    exactSearch?: boolean;
    tamper?: IScrTamperProps;
}

export interface IConnectionSummary {
    connected?: boolean;
    disconnectReason?: string;
    timestamp?: number;
}

export enum SCR_TAMPER_STATUS {
    SECURE = 'Secure',
    SCR_TAMPER = 'SCR Tamper',
    SCR_BATTERY_NOT_OK = 'SCR Battery not OK',
    INVALID_FIRMWARE = 'Firmware v0.0.0000',
}
export interface IScrTamperProps {
    status: SCR_TAMPER_STATUS;
    tamperedList?: string[];
}
export interface ISearchDeviceInfo {
    deviceName: string;
    deviceGroupNames: string[];
    deviceGroupLabel: string;
    status: DEVICE_STATUS;
    esn: string;
    serialNumber: string;
    deviceTypeName: string;
    firmwareVersion: string;
    lastJobSuccessAt?: number;
    tamper?: IScrTamperProps;
    assignedToGroupAt?: string;
}

export interface IShellCommandExecutionResponse {
    deviceName: string;
    status: string;
    output: string;
}

export interface IShellCommandExecuteParams {
    deviceName?: string;
    deviceGroup?: string;
    command: string;
}

export type DeviceAttributeName = 'name' | 'location' | 'esn' | 'serialNumber' | 'timezone';
export interface ITimeReportRequestParams {
    deviceGroupName: string;
}

export interface ITimeReport {
    /** in seconds */
    restartedAt?: number;
    /** in seconds */
    timeElapsed?: number;
    uptime?: string;
    downtime?: string;
}

export interface IDeviceTimeOptions {
    devices: {
        [uid: string]: ITimeReport & ISearchDeviceInfo;
    };
    errors: { uid: string; error: string }[];
}

export interface IDevicePackageInformation {
    version: string;
    arch: string;
    installedAtTimestamp: string;
    installed: boolean;
}
export interface IDevicePackageResponse {
    [packageName: string]: IDevicePackageInformation | IDevicePackageInformation[];
}
export enum DeviceAttributeMapping {
    timezone = 'timezone',
    name = 'deviceLabel',
    location = 'location',
    esn = 'esn',
    serialNumber = 'serialNumber',
}

export interface IUpdateDeviceAttribute {
    deviceName: string;
    attributeName: DeviceAttributeName;
    attributeValue: string;
}

export interface IUpdateDeviceAttributeResponse {
    updated: boolean;
    reason?: string; // The reason when attribute doesn't updated
}

export enum DECOMMISSION_TYPE {
    PARTIAL = 'Partial',
    FULL = 'Full',
}

export interface IDecommissionDevicesOptions {
    type: DECOMMISSION_TYPE;
    deviceGroupName?: string;
    deviceName?: string;
}

/**
 * @property {string[]} data - The device's names are decommission successfully
 * @property {Object[]} errors - The list of devices were failed when decommission
 */
export interface IDecommissionDevicesResponse {
    devices: {
        identifier: string;
        success: boolean;
        error?: string;
    }[];
}

export interface IDeviceInventoryStateOptions {
    accountId?: number;
    activeKey?: {
        clientDeviceGroupName: string;
        deviceGroup: string;
    };
    filters?: { [key: string]: string[] | undefined },
}

export interface ICommissioningUploadRequest {
    importType: string;
    files: File[];
    selectedDeviceGroup: string;
}
export interface IDeviceCommissioningResponse {
    identifier: string;
    success: boolean;
    errors?: string[];
}

export interface ICryptoReportResult {
    atr: any;
    found: string;
    device: string;
    keysPresent: any[];
    cryptoModuleInfo: any;
    reportToolVersion: string;
}

export interface IDataTransferResult {
    deviceUids: string[];
    success: { fileName: string; executionId: string }[];
    error: { fileName: string; errorMessage: string }[];
}

export interface IGetDeviceLogsOptions {
    from?: string;
    to?: string;
}

export interface ILogOptions {
    timestamp: string;
    data: any;
}

export enum DOWNLOAD_LOG_TYPE {
    TAIPAN = 'TAIPAN',
    TRAMPOLINE = 'TRAMPOLINE',
    TERMLOG = 'TERMLOG'
}

export enum DEVICE_LOG_TYPE{
    SYSLOGS = 'syslogs',
    TERMLOG = 'termlog'
}

export interface IProcessProps {
    command: string;
    cpu: number;
    key: number;
    memory: number;
    pid: number;
    rss: number;
    start: string;
    time: string;
    vsz: number;
}

export interface IProcessHistory {
    timestamp: string;
    process_listing: IProcessProps[];
}

export interface IMSPVersionOptions {
    mspVersions: {
        name: string;
        version: string;
        timestamp: string;
    }[];
}

export interface ITaipanBatteryInfo {
    batteryInfo: {
        voltage: string;
    };
}
export interface IDevicePackageOptions extends IDevicePackageInformation{
    packageName: string;
}

export interface IResetDeviceResult {
    result: {
        deviceIdentifiers: string[];
        executionIds: string[];
    };
}

export interface IScrInfoResult {
    rct: any;
    emvl2: any;
    sr_app: any;
    emvl1_sr: any;
    hardware: any;
    software: any;
    format_version: string;
    sr_flashloader: any;
}

export enum ImportDeviceStatus {
    IMPORTABLE = 'IMPORTABLE',
    NONIMPORTABLE = 'NON IMPORTABLE',
    NOT_FOUND = 'NOT FOUND',
    ERROR = 'ERROR',
    EXISTING = 'EXISTING',
}

export enum MoveDeviceStatus {
    UPDATED = 'UPDATED',
    UPDATING = 'UPDATING',
    REMOVE_FROM_GROUP = 'REMOVE FROM GROUP',
    ADD_TO_GROUP = 'ADD TO GROUP',
    ERROR = 'ERROR',
}
export interface IImportCSVResponseOptions {
    error?: string;
    devices: IImportDeviceProps[];
}
export interface IImportDeviceProps {
    UID: string;
    serialNumber?: string;
    esn?: string;
    deviceType?: string;
    deviceGroupName?: string;
    deviceGroupLabel?: string;
    status?: MoveDeviceStatus | ImportDeviceStatus;
    comment?: string;
}

export interface IDependencyJob {
    jobId: string;
    type: string;
}
export interface IExecutionJobSummary {
    jobId: string;
    status?: string;
    executionNumber: number;
    queuedAt?: number;
    statusDetails: any;
    lastUpdatedAt?: string;
    commandCode: string;
    dependencyJobs: { rerunJobs: IDependencyJob[]; };
    ownerJob?: {
        id: number;
        jobId: string;
    };
}

export interface IJobExecutionsStatus {
    jobId: string;
    status: DEVICE_JOB_EXECUTION_STATUS;
}
export interface IDependencyJobExecution {
    parentJobId: string;
    reruns: IDependencyJob[];
    current: IExecutionJobSummary;
}

export interface IDeviceAttributesResult {
    deviceName: string;
    attributes: {
        [key: string]: string;
    };
    connectivity: IConnectionSummary;
    deviceGroupNames: string[];
    trampolineVersion: string;
}

export interface IDeviceAttributesResponse {
    devices: IDeviceAttributesResult[];
}
