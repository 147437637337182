import { AccountType } from 'dto/access-management/account-dto';
import { observable, makeObservable, action, computed } from 'mobx';
import type { ApiClientStore } from '../api-client';
import { RouteStore, IRoutes } from '../routes';
import { AuthApiClient, IConfirmForgotPasswordOptions } from './auth.client';

export interface IAuthStoreConstructorOptions {
    apiClientStore: ApiClientStore;
    routeStore: RouteStore;
    privateRoutes: IRoutes;
    publicRoutes: IRoutes;
}

export interface IManagedAccountProps {
    id: number;
    accountUuid: string;
    accountType: AccountType;
    deviceGroupName: string;
    apiEndPoint: string;
    parent?: any;
}
export interface IAuthUser {
    uuid: string;
    userName: string;
    email: string;
    name: string;
    isActive: boolean;
    roles: any[];
    accountId: number;
    accountUuid: string;
    accountType: string;
    exp: number;
    managedAccounts: IManagedAccountProps[];
    applications?: any[];
}

export interface IAssureApiAccountOptions {
    accountId: number;
}

export class ServerNotFoundError extends Error {}

export class AuthStore {
    private readonly _apiClientStore: ApiClientStore;
    private readonly _routeStore: RouteStore;
    private readonly _privateRoutes: IRoutes;
    private readonly _publicRoutes: IRoutes;

    private readonly _isAuthenticatedPromise: Promise<void>;

    constructor(options: IAuthStoreConstructorOptions) {
        makeObservable(this);

        this._apiClientStore = options.apiClientStore;
        this._routeStore = options.routeStore;

        this._privateRoutes = options.privateRoutes;
        this._publicRoutes = options.publicRoutes;

        this._isAuthenticatedPromise = (async (): Promise<void> => {
            try {
                const user = await this.getUserAuthenticated();
                this.setIsAuthenticated(!!user, user);
            } finally {
                this.setIsAuthenticating(false);
            }
        })();
    }

    protected get apiClient(): AuthApiClient {
        return this._apiClientStore.apiClients.auth;
    }

    public login = async (email: string, password: string): Promise<IAuthUser> => {
        const loginResult = await this.apiClient.login(email, password);
        if (!loginResult) throw new ServerNotFoundError('ALM Server not found!!!. Please contact technical support.');
        if (loginResult.status == 'error')
            throw loginResult.data;

        const userLogin = loginResult.data?.user;
        this.setIsAuthenticated(!!userLogin, userLogin);
        return userLogin;
    };

    public logOut = async (): Promise<void> => {
        await this.apiClient.logout();
        this.setIsAuthenticated(false, null);
    };

    public firstLogin = async (password: string): Promise<void> => {
        const result = await this.apiClient.firstLogin(password);
        if (result.status == 'error') throw new Error(result.data);
    };

    public forgotPassword = async (email: string): Promise<boolean> => {
        const result = await this.apiClient.forgotPassword(email);
        if (result.status == 'error') throw Error(result.data);
        return result?.data?.result;
    };

    public confirmForgotPassword = async (params: IConfirmForgotPasswordOptions): Promise<boolean> => {
        const result = await this.apiClient.confirmForgotPassword(params);
        if (result.status == 'error') throw Error(result.data);
        return result.data.result;
    };

    public getUserAuthenticated = async (): Promise<IAuthUser> => {
        const result = await this.apiClient.getUserAuthenticated();
        return result?.data?.user;
    };

    @action.bound
    setIsAuthenticated(isAuthenticated: boolean, user?: IAuthUser): void {
        this._routeStore.removeRoutes();
        this._routeStore.registerRoutes(isAuthenticated ? this._privateRoutes : this._publicRoutes);
        this.isAuthenticated = isAuthenticated;
        this.setAuthUser(user);
        const accountId = !user
            ? undefined
            : user.accountType != 'DPU'
                ? user.accountId
                : user.managedAccounts?.find((item) => item.accountType == 'VAR')?.id;
        this.setAssureApiAccount({ accountId });
    }

    @action.bound
    setIsAuthenticating(isAuthenticating: boolean): void {
        this.isAuthenticating = isAuthenticating;
    }

    @action.bound
    setAuthUser(user: IAuthUser): void {
        this.currentUser = user;
    }

    @action.bound
    setAssureApiAccount(options: IAssureApiAccountOptions): void {
        this.assureApiAccount = options;
    }

    @computed
    public get isDPUAdmin(): boolean {
        return this.currentUser?.roles?.some(
            (role) => role.name == 'DPU Admin' || (role.name == 'Admin' && this.currentUser?.accountType == 'DPU'),
        );
    }

    @observable
    currentUser: IAuthUser;

    @observable
    assureApiAccount: IAssureApiAccountOptions;

    @observable
    isAuthenticated = false;

    @observable
    isAuthenticating = true;
}
