import * as React from 'react';
import { Tree } from 'antd';

export type OnClick = (ev: any) => any | Promise<any>;
export interface IBasicTreeProps {
    treeData?: DataNode[];
    loadData?: OnClick; // loading node
    onSelect?: OnClick; // left click the node
    selectedKeys?: string[];
    defaultExpandedKeys?: string[];
    defaultExpandAll?: boolean;
    titleRender?: (node: DataNode) => React.ReactNode;
}

export interface IBasicDirectoryTreeProps extends IBasicTreeProps {
    showIcon?: boolean;
}

interface DataNode {
    title: string;
    key: string;
    isLeaf?: boolean;
    children?: DataNode[];
}

export function BasicTree(props: IBasicTreeProps) {
    return (
        <Tree
            titleRender={props.titleRender}
            loadData={props.loadData}
            onSelect={props.onSelect}
            defaultExpandedKeys={props.defaultExpandedKeys}
            defaultExpandAll={props.defaultExpandAll}
            selectedKeys={props.selectedKeys}
            treeData={props.treeData}
        />
    );
}

export function BasicDirectoryTree(props: IBasicDirectoryTreeProps) {
    return (
        <Tree.DirectoryTree
            titleRender={props.titleRender}
            showIcon={!!props.showIcon}
            loadData={props.loadData}
            onSelect={props.onSelect}
            defaultExpandAll={props.defaultExpandAll}
            selectedKeys={props.selectedKeys}
            treeData={props.treeData}
        />
    );
}
