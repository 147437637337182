export const isNullOrEmpty = (value: string | any[]): boolean => !value || value == null || value.length == 0;

export const EXPORT_TYPES = {
    CSV: { separator: ',', type: 'text/csv;charset=utf-8;' },
    TSV: { separator: '/t', type: 'text/tsv;charset=utf-8;' },
};

export interface IHeaders {
    [propertyName: string]: string;
}

export interface IExportType {
    separator: string;
    type: string;
}

export function exportFile(filename: string, exportContent: string, exportType: string): void {
    const blob = new Blob([exportContent], { type: exportType });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const exportData = (filename: string, exportType: IExportType, exportData: any[], headers?: IHeaders): void => {
    if (isNullOrEmpty(exportData)) return;

    const columHearders = headers
        ? headers
        : Object.keys(exportData[0]).reduce((p, c) => {
              p[c] = c;
              return p;
          }, {});

    const rows = exportData.map((row) =>
        Object.keys(columHearders)
            .map((k) => {
                let cell = row[k] ? row[k] : '';
                cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');
                cell = cell.search(/("|,|\n)/g) >= 0 ? `"${cell}"` : cell;
                return cell;
            })
            .join(exportType.separator),
    );

    const exportContent = [].concat(Object.values(columHearders).join(exportType.separator), ...rows).join('\n');

    exportFile(filename, exportContent, exportType.type);
};
