import * as React from 'react';
import {BaseError, IBaseErrorProps} from './base-error';

export type I401Props = Omit<IBaseErrorProps, 'iconName' | 'title'>

export const Error401 = (props: I401Props) => (
    <BaseError
        iconName="shield-exclamation"
        title="401 - Unauthorized"
        message={props.message}
    />
);