import * as React from 'react';
import { DangerTag, SuccessTag, WarningTag } from 'ui-lib';
import { COMMISSION_STATUS } from './commissioning-upload';
import { IScrTamperProps, SCR_TAMPER_STATUS } from 'dto/device-dto';

export const commissionStatusTagComponent = (status: string): JSX.Element => {
    if (status == COMMISSION_STATUS.COMMISSIONABLE) return <SuccessTag value={status} />;
    if (status == COMMISSION_STATUS.NOT_AVAILABLE) return <WarningTag value={status} />;
    if (status == COMMISSION_STATUS.NOT_FOUND || status == SCR_TAMPER_STATUS.INVALID_FIRMWARE ||
        status == SCR_TAMPER_STATUS.SCR_TAMPER || status == SCR_TAMPER_STATUS.SCR_BATTERY_NOT_OK)
        return <DangerTag value={status} />;
};

export const toCommissionStatus = <T extends { tamper?: IScrTamperProps; }>(device: T): string => {
    if (!device) return COMMISSION_STATUS.NOT_FOUND;
    return (!device.tamper?.status || device.tamper.status == SCR_TAMPER_STATUS.SECURE) ? COMMISSION_STATUS.COMMISSIONABLE : device.tamper.status;
};

export * from './commissioning-upload';
export * from './commissioning-upload-review';
