import {IBadgeProps, IconName} from '../icon';

export interface IMenuItem {
    code: string;
    iconName?: IconName;
    badge?: IBadgeProps;
    label?: (menuItem?: IMenuItem) => string;
    onClick?: (menuItem?: IMenuItem) => void | Promise<any>;
    onTitleClick?: (menuItem?: IMenuItem) => void | Promise<any>;
    subMenuItems?: IMenuItem[];
}

export interface IBaseMenuItemProps {
    menuItems: IMenuItem[];
}