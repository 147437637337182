import type { AuthApiClient } from './auth.client';

export * from './base-api.client';
export * from './auth.client';
export * from './response-error';
export * from './auth.store';

export interface IBaseApiClients {
    auth: AuthApiClient;
}
