import * as React from 'react';
import reportCss from './reports.css';
import { intersectionWith, uniqWith } from 'lodash';

import { IBasicTableColumn, RowLayout, Skeleton, TextFieldItem, Typography } from 'ui-lib';

import { IDevicePackageOptions, ISearchDeviceInfo } from '../../../../dto/device-dto';
import { FILTER_PACKAGE_TYPE, IPackageProps, PACKAGE_ARCH, PACKAGE_TYPE } from '../../../../dto/packages-dto';
import {
    useAccountStore,
    useAuthStore,
    useDeviceGroupsStore,
    useDeviceStore,
    usePackagesStore,
    useSnapshotStore,
} from '../../../../store';
import { createBasicFilterColumn } from '../../components/table-utils';
import { ReportComponent } from './report-component';
import { ISnapshotProps } from '../../../../dto/software-snapshot-dto';
import { ReportTable } from '../../components/report-table';
import { observer } from 'mobx-react';
import { getErrorMessage } from 'common-utils';
import { isMSPPackage } from 'common-utils/msp-packages-data';
import { AccountType } from 'dto/access-management/account-dto';

enum PACKAGE_STATUS {
    UNCHANGED = 'Unchanged',
    NOT_ON_DEVICE = 'Not on device',
    DIFFERENT_VERSION = 'Different version',
    NOT_IN_SNAPSHOT = 'Not in release',
}

interface IPackageReport {
    packageName: string;
    version: string;
    type?: PACKAGE_TYPE;
}

interface IDisplayPackageReport extends IPackageReport {
    status: PACKAGE_STATUS;
}

interface IPackageComparison {
    unchangedList: IPackageReport[];
    notOnDeviceList: IPackageReport[];
    notInSnapshotList: IPackageReport[];
    differentVersionList: IPackageReport[];
}

export const SnapshotComparisonComponent = observer(() => {
    const defaultPackageComparision = (): IPackageComparison => ({
        unchangedList: [],
        notInSnapshotList: [],
        notOnDeviceList: [],
        differentVersionList: [],
    });
    const { currentUser } = useAuthStore();
    const deviceStore = useDeviceStore();
    const deviceGroupsStore = useDeviceGroupsStore();
    const snapshotStore = useSnapshotStore();
    const packageStore = usePackagesStore();
    const accountStore = useAccountStore();

    const deviceSearchTextRef = React.useRef<string>();
    const [device, setDevice] = React.useState<ISearchDeviceInfo>();
    const [tableLoading, setTableLoading] = React.useState<boolean>(false);

    const [latestPublishedSnapshot, setSnapshot] = React.useState<ISnapshotProps>();
    const [packageComparision, setPackageComparision] = React.useState<IPackageComparison>(defaultPackageComparision);

    const [errorMessage, setErrorMessage] = React.useState<string>(null);

    const packageDataSource = React.useMemo<IDisplayPackageReport[]>(() => {
        function _buildDisplayPackages(packageList: IPackageReport[], status: PACKAGE_STATUS) {
            return packageList.map((item) => ({ ...item, status, key: `${item.packageName}-${item.version}` }));
        }

        return [
            ..._buildDisplayPackages(packageComparision.unchangedList, PACKAGE_STATUS.UNCHANGED),
            ..._buildDisplayPackages(packageComparision.differentVersionList, PACKAGE_STATUS.DIFFERENT_VERSION),
            ..._buildDisplayPackages(packageComparision.notInSnapshotList, PACKAGE_STATUS.NOT_IN_SNAPSHOT),
            ..._buildDisplayPackages(packageComparision.notOnDeviceList, PACKAGE_STATUS.NOT_ON_DEVICE),
        ];
    }, [packageComparision]);

    async function getDevicePackages(device: ISearchDeviceInfo): Promise<IDevicePackageOptions[]> {
        return deviceStore.getCurrentPackageListing(currentUser.accountId, device.deviceName);
    }

    async function getSnapshotPackages(deviceGroupName: string): Promise<{
        snapshot: ISnapshotProps;
        packages: IPackageProps[]
    }> {
        const currentAccount = accountStore.getAccountById(currentUser.accountId);
        let clientAccountUuid = currentAccount.uuid;
        if (currentAccount.accountType != AccountType.CLIENT) {
            clientAccountUuid = deviceGroupsStore.getClientOwnDeviceGroup(deviceGroupName)?.uuid;
        }
        const latestSnapshot = snapshotStore.getLatestSnapshotByAccount(clientAccountUuid);
        if (!latestSnapshot) return { snapshot: undefined, packages: [] };
        const packages = snapshotStore.getPackagesBySnapshotId(latestSnapshot.id, true);
        return {
            snapshot: latestSnapshot,
            packages: uniqWith(packages, (a, b) => a.label == b.label && a.version == b.version)
        };
    }

    React.useEffect(() => {
        if (!errorMessage) return;
        setDevice(undefined);
        setSnapshot(undefined);
        setPackageComparision(defaultPackageComparision);
    }, [errorMessage]);

    React.useEffect(() => {
        Promise.all([
            snapshotStore.loadEntities(currentUser.accountId),
            packageStore.loadEntities(currentUser.accountId),
        ]);
    }, []);

    const columns: IBasicTableColumn[] = [
        createBasicFilterColumn({
            key: 'packageName',
            title: 'Package',
            searchPlaceholder: 'Package Name',
            columnExtraOptions: {
                sorter: (a: IDisplayPackageReport, b: IDisplayPackageReport) =>
                    a.packageName.localeCompare(b.packageName),
            },
        }),
        {
            code: 'version',
            title: 'Version',
            dataIndex: 'version',
        },
        {
            code: 'architecture',
            title: 'Architecture',
            dataIndex: 'architecture',
            width: '10%',
            sorter: (a: IPackageProps, b: IPackageProps) => a.architecture.localeCompare(b.architecture),
            filters: Object.keys(PACKAGE_ARCH).map((arch) => ({ text: PACKAGE_ARCH[arch], value: PACKAGE_ARCH[arch] })),
            onFilter: (architecture, pkg) => pkg.architecture == architecture,
        },
        {
            code: 'status',
            title: 'Status',
            dataIndex: 'status',
            sorter: (a: IDisplayPackageReport, b: IDisplayPackageReport) => a.status.localeCompare(b.status),
            filters: Object.keys(PACKAGE_STATUS).map((key) => ({ text: PACKAGE_STATUS[key], value: key })),
            onFilter: (status: PACKAGE_STATUS, packageRow: IDisplayPackageReport) =>
                packageRow.status == PACKAGE_STATUS[status],
        },
        {
            code: 'type',
            title: 'Type',
            dataIndex: 'type',
            sorter: (a: IDisplayPackageReport, b: IDisplayPackageReport) => a.type?.localeCompare(b.type),
            filters: Object.keys(FILTER_PACKAGE_TYPE).map((type) => ({ text: FILTER_PACKAGE_TYPE[type], value: type })),
            onFilter: (type: PACKAGE_TYPE, packageRow: IDisplayPackageReport) => packageRow.type == PACKAGE_TYPE[type],
        },
    ];
    return (
        <ReportComponent
            code="snapshotComparision"
            isLoading={false}
            errorMessage={errorMessage}
            items={[
                <TextFieldItem
                    code="device-text-field"
                    label="Device"
                    labelAlign="right"
                    isRequired={true}
                    placeholder="UID, serial number or eSN"
                    disabled={snapshotStore.dataLoading || packageStore.dataLoading}
                    onChange={(ev) => (deviceSearchTextRef.current = ev.target.value)}
                />,
            ]}
            generateReportOptions={{
                isGenerating: tableLoading,
                onGenerateReport: async (values) => {
                    setTableLoading(true);
                    setErrorMessage(undefined);
                    const deviceName = values['device-text-field'];
                    try {
                        const devices = await deviceStore.searchDevices(currentUser.accountId, {
                            searchValue: deviceName,
                            exactSearch: true,
                        });
                        const device = devices[0];
                        setDevice(device);
                        const [devicePackages, { snapshot, packages }] = await Promise.all([
                            getDevicePackages(device),
                            getSnapshotPackages(device.deviceGroupNames.at(-1)),
                        ]);
                        setSnapshot(snapshot);

                        const devicePackagesWithoutMSP = devicePackages.filter(item => !isMSPPackage(item.packageName));
                        const publishedPackages = packages.filter(item => !isMSPPackage(item.label));

                        const unchangedList = intersectionWith(publishedPackages, devicePackagesWithoutMSP, (sPkg, dPkg) => {
                            return (sPkg.label == dPkg.packageName && sPkg.version == dPkg.version);
                        });

                        const differentVersionList = intersectionWith(publishedPackages, devicePackagesWithoutMSP, (sPkg, dPkg) => {
                            return sPkg.label == dPkg.packageName && sPkg.version != dPkg.version;
                        });

                        const pkgNotOnDeviceList = publishedPackages.filter(
                            (item) => !devicePackagesWithoutMSP.some((pkg) => pkg.packageName == item.label),
                        );
                        const pkgNotInSnapshotList = devicePackagesWithoutMSP.filter(
                            (item) => !publishedPackages.some((pkg) => pkg.label == item.packageName && pkg.version == item.version),
                        );
                        const mspPackages = devicePackages.filter(item => isMSPPackage(item.packageName));
                        setPackageComparision({
                            unchangedList: [
                                ...mspPackages.map(item => ({
                                    packageName: item.packageName,
                                    version: item.version,
                                    type: PACKAGE_TYPE.VOS
                                })),
                                ...unchangedList.map((item) => ({
                                    packageName: item.label,
                                    version: item.version,
                                    type: item.type
                                }))
                            ],
                            differentVersionList: differentVersionList.map((item) => ({
                                packageName: item.label,
                                version: item.version,
                                type: item.type,
                            })),
                            notInSnapshotList: pkgNotInSnapshotList.map((item) => ({
                                packageName: item.packageName,
                                version: item.version,
                                type: packageStore.getPackage({
                                    name: item.packageName,
                                    version: item.version
                                })?.type
                            })),
                            notOnDeviceList: pkgNotOnDeviceList.map((item) => ({
                                packageName: item.label,
                                version: item.version,
                                type: item.type,
                            })),
                        });
                    } catch (err) {
                        setErrorMessage(getErrorMessage(err));
                    } finally {
                        setTableLoading(false);
                    }
                },
            }}
            reportTable={
                <div className={reportCss['snapshot-comparison']}>
                    <Typography.Title level={4}>Report output</Typography.Title>
                    <Skeleton loading={tableLoading}>
                        <RowLayout
                            childItems={[
                                <Typography.Text className={reportCss['label-right-align']}>Device: </Typography.Text>,
                                <Typography.Text strong>{device ? deviceSearchTextRef.current : ''}</Typography.Text>,
                            ]}
                            childCols={[5, 19]}
                        />
                        <RowLayout
                            childItems={[
                                <Typography.Text className={reportCss['label-right-align']}>
                                    Published Snapshot:{' '}
                                </Typography.Text>,
                                <Typography.Text strong>{latestPublishedSnapshot?.label || ''}</Typography.Text>,
                            ]}
                            childCols={[5, 19]}
                        />
                        <ReportTable
                            title=""
                            table={{
                                rowClassName: (record: IDisplayPackageReport, index) => {
                                    if (record.status == PACKAGE_STATUS.UNCHANGED) return reportCss['default-row'];
                                    if (record.status == PACKAGE_STATUS.DIFFERENT_VERSION)
                                        return reportCss['warning-row'];
                                    return reportCss['danger-row'];
                                },
                                loading: tableLoading,
                                columns: columns,
                                dataSource: packageDataSource,
                            }}
                            exportOptions={{
                                overrideExportItem: {
                                    filename: 'Release comparison',
                                },
                            }}
                        />
                    </Skeleton>
                </div>
            }
        />
    );
});
