import * as React from 'react';
import { FormItem, getDefaultFormItemOptions, IFormItem } from './form-item';
import { IManualRangeTimePickerProps } from 'ui-lib/components/datetime/manual-range-time-picker';
import { ManualRangeTimePicker } from 'ui-lib/components/datetime';

export interface IManualRangeTimePickerFormItemPros extends Omit<IManualRangeTimePickerProps, 'code' | 'className'>, IFormItem {
}

export const ManualRangeTimePickerFormItem = (props: IManualRangeTimePickerFormItemPros): JSX.Element => {
    const formItemProps = getDefaultFormItemOptions(props, true);

    return (
        <FormItem
            code={props.code}
            {...formItemProps}
            child={
                <ManualRangeTimePicker {...props} />
            }
        />
    );
};
