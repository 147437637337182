export interface IExecuteCommandBodyParameters {
    commandCode: string;
    targetDeviceNames: string[];
    description?: string;
    commandPayload?: object;
    timeoutInMinutes?: number;
}

export interface ITriggerCommandResult {
    executionId: string;
}

export enum DEVICE_JOB_EXECUTION_STATUS {
    QUEUED = 'QUEUED',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
    TIMED_OUT = 'TIMED_OUT',
    REJECTED = 'REJECTED',
    REMOVED = 'REMOVED',
    CANCELED = 'CANCELED'
}
export interface IExecuteCommandStatusResponse {
    status: string;
    devices: {
        status: DEVICE_JOB_EXECUTION_STATUS;
        deviceName: string;
        responsePayload: { [key: string]: string; };
        commandExecutionNotFound?: boolean;
    }[];
}

export interface IGetCommandQuery {
    jobId?: string;
    commandCode?: string;
}

export interface IRerunExecutionProps {
    deviceName: string;
}

export interface IGetCommandExecutionDetailsRequest {
    deviceName?: string;
    executionNumber?: number;
}

export interface ICommandExecutionDetailsResponse {
    jobId: string;
    status: string;
    statusDetails: {
        detailsMap: {
            [key: string]: string;
        };
    };
    thingArn?: string;
    queuedAt?: Date;
    startedAt?: Date;
    lastUpdatedAt?: Date;
    executionNumber?: number;
    versionNumber?: number;
    approximateSecondsBeforeTimedOut?: number;
}
