import * as React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { FormItem, getDefaultFormItemOptions, IFormItem } from '../forms';
import { DATE_TIME_FORMAT } from 'common-utils/date-utils';

dayjs.extend(weekday);
dayjs.extend(localeData);

export interface IDateTimePickerProps {
    name?: string;
    className?: string;
    placeholder?: string;
    showTime?: boolean;
    minuteStep?: number;
    dateFormat?: string;
    alowClear?: boolean;
    showNow?: boolean;
    allowClear?: boolean;
    onChange?: (value, option) => void;
    onOk?: (value) => void;
    defaultValue?: any;
    value?: any;
    disabledDateType?: 'DISABLE_PAST_DATES' | 'DISABLE_FUTURE_DATES' | 'NO_RESTRICTION';
}

function disabledPastDate(current: any): boolean {
    return current && current <= dayjs().subtract(1, 'day').endOf('day');
}
function disabledFutureDate(current: any): boolean {
    return current && current > dayjs().endOf('day');
}

const DisabledDateFn = {
    DISABLE_PAST_DATES: disabledPastDate,
    DISABLE_FUTURE_DATES: disabledFutureDate,
    NO_RESTRICTION: (): boolean => false
};

export const DateTimePicker = (props: IDateTimePickerProps) => {
    return (
        <DatePicker
            name={props.name}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
            showTime={props.showTime}
            minuteStep={props.minuteStep}
            format={props.dateFormat}
            showNow={props.showNow}
            allowClear={props.allowClear}
            onChange={props.onChange}
            value={props.value}
            onOk={props.onOk}
            disabledDate={DisabledDateFn[props.disabledDateType ?? 'DISABLE_PAST_DATES']}
        />
    );
};

export interface IDateTimePickerFormItemProps extends Omit<IFormItem, 'child' | 'name'>, IDateTimePickerProps {

}
export const DateTimePickerFormItem = (props: IDateTimePickerFormItemProps) => {
    const formItemProps = getDefaultFormItemOptions(props, true);

    const formatDate = (value) => dayjs(value)?.format(props.dateFormat ?? DATE_TIME_FORMAT.DATE_ONLY);
    return (
        <FormItem
            code={props.code}
            {...formItemProps}
            required={props.isRequired}
            initialValue={formatDate(props.initialValue)}
            getValueFromEvent={(onChange) => formatDate(onChange)}
            getValueProps={(i) => ({ value: dayjs(i) })}
            child={
                <DateTimePicker
                    {...props}
                    alowClear={false}
                    name={props.code}
                />
            }
        />
    );
};