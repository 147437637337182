/* eslint-disable no-await-in-loop */
import * as React from 'react';
import { AssignDevicesStore } from 'store/assign-devices-store';
import { DangerTag, IconTextButton, SuccessTag, Typography, WarningTag } from 'ui-lib';
import { IDeviceGroupOptions } from 'dto/device-group-dto';
import { IImportDeviceProps, ImportDeviceStatus } from 'dto/device-dto';
import { AssignDeviceSummaryTable } from './assign-devices-summary-table';
import { observer } from 'mobx-react';

export interface IImportDevicesSummaryProps {
    organisationId: number;
    devices: TDisplayImportDeviceProps[];
    assignDeviceStore: AssignDevicesStore;
    deviceGroup: IDeviceGroupOptions;
    onClose: () => void;
}

export type TDisplayImportDeviceProps = IImportDeviceProps & { deviceGroupLabel?: string };

const IMPORT_STATUSES = [ImportDeviceStatus.IMPORTABLE, ImportDeviceStatus.NONIMPORTABLE, ImportDeviceStatus.EXISTING ,ImportDeviceStatus.NOT_FOUND];
export const ImportDevicesSummaryTable = observer((props: IImportDevicesSummaryProps): JSX.Element => {
    const { devices, assignDeviceStore } = props;
    const importableDevices = React.useMemo<string[]>(
        () => devices
            .filter(item => item.status == ImportDeviceStatus.IMPORTABLE)
            .map(item => item.UID),
        devices);

    const onImport = (): void => {
        assignDeviceStore.addTargetDevices(importableDevices);
        props.onClose();
    };

    return (
        <AssignDeviceSummaryTable
            clientAccountId={props.organisationId}
            header={<Typography.Title level={3}>Import devices to {props.deviceGroup?.deviceGroupLabel ?? 'new'} group</Typography.Title>}
            title={`Importing ${importableDevices.length} Devices`}
            dataSource={devices}
            statusColumn={{
                filterKeys: IMPORT_STATUSES,
                renderTag: (text: string): React.ReactNode => {
                    if (text === ImportDeviceStatus.NOT_FOUND || text === ImportDeviceStatus.ERROR || text === ImportDeviceStatus.NONIMPORTABLE) {
                        return <DangerTag value={text} />;
                    }
                    if (text === ImportDeviceStatus.EXISTING) {
                        return <WarningTag value={text} />;
                    }
                    return <SuccessTag value={text} />;
                }
            }}
            extraItems={[
                <IconTextButton
                    key='import-btn'
                    label='Import'
                    onClick={onImport}
                    iconName='plus'
                    type='primary'
                />,
                <IconTextButton
                    key='import-btn'
                    label='Close'
                    onClick={(): void => props.onClose()}
                    iconName='cancel'
                    type='default'
                />
            ]}
        />
    );
});
