import { BaseApiClient, IApiClientOptions, IBaseApiClients, AuthApiClient } from './auth';

export interface IApiClientStore {
    host: string;
    port: number;
    prefix?: string;
    isProduction?: boolean;
}

// TODO TYPE PROPERLY
export type TApiClient = any;

export abstract class ApiClientStore<E = any> {
    private _clients: E & IBaseApiClients;

    protected readonly _config: IApiClientOptions;

    protected constructor(options: IApiClientStore) {
        this._config = {
            host: options.host,
            port: options.port,
            prefix: options.prefix,
            credentials: options.isProduction ? 'same-origin' : 'include',
        };
    }

    protected registerEndpoints(apiClients: TApiClient[]): void {
        this._clients = apiClients.reduce(
            (_, ApiEndpoint) => {
                const apiClient: BaseApiClient = new (ApiEndpoint as any)(this._config);
                _[apiClient.clientName] = apiClient;

                return _;
            },
            {
                auth: new AuthApiClient(this._config),
            } as E & IBaseApiClients,
        );
    }

    public get apiClients(): E & IBaseApiClients {
        return this._clients;
    }
}
