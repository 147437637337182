import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';
import { DevicePages, DEVICES_ROUTE_CODES } from './devices';
import { SoftwareManagementPage } from './software';
import { KEY_ROUTE_CODES, KeyManagementPage } from './keys';
import { AdminPages } from './admin';
import { FirstLoginPageWrapper } from './login/first-login';
import { NotificationsPage } from './notifications';
import { useAuthStore, useNotificationsStore, useRoutesStore, useStores } from '../../store';
import { hasPermission } from '../private/utils';
import { DevicesInventoryDashboard } from './devices/devices-inventory/devices-inventory-dashboard';
import { KeyInventoryPage } from './keys/inventory';
import { Drawer, Error404 } from 'ui-lib';
import css from './private-page.css';

function initSocketConnection() {
    const { isAuthenticated, currentUser } = useAuthStore();
    const { socketClientStore } = useStores();

    if (isAuthenticated) {
        const accountId = currentUser.accountType != 'DPU' ? currentUser.accountId : undefined;

        React.useEffect(() => {
            (() => {
                socketClientStore.buildAndConnect(accountId);
            })();
        }, []);
    }
}
enum MAIN_ROUTE_CODES {
    DEVICES = 'DEVICES',
    SOFTWARE_MANAGEMENT = 'SOFTWARE_MANAGEMENT',
    KEYS = 'KEYS',
    ADMIN = 'ADMIN',
    FIRST_LOGIN = 'FIRST_LOGIN',
    NOTIFICATIONS = 'NOTIFICATIONS',
}

export function PrivatePages() {
    const routeStore = useRoutesStore();
    const stores = useStores();
    const { currentUser } = useAuthStore();

    initSocketConnection();

    const PrivateRoutePages = observer(() => {
        if (!currentUser.isActive)
            return (
                <Routes>
                    <Route
                        path='*'
                        element={<FirstLoginPageWrapper />}
                    />
                </Routes>
            );

        return (
            <Routes>
                <Route
                    path='/'
                    element={hasPermission(DEVICES_ROUTE_CODES.INVENTORY, stores)
                        ? <DevicesInventoryDashboard />
                        : hasPermission(KEY_ROUTE_CODES.INVENTORY, stores)
                            ? <KeyInventoryPage />
                            : null}
                />
                <Route
                    path={`${routeStore.getRoute(MAIN_ROUTE_CODES.DEVICES).trimRootPath('/')}/*`}
                    element={<DevicePages />}
                />
                <Route
                    path={`${routeStore.getRoute(MAIN_ROUTE_CODES.SOFTWARE_MANAGEMENT).trimRootPath('/')}/*`}
                    element={<SoftwareManagementPage />}
                />
                <Route
                    path={`${routeStore.getRoute(MAIN_ROUTE_CODES.KEYS).trimRootPath('/')}/*`}
                    element={<KeyManagementPage />}
                />
                <Route
                    path={`${routeStore.getRoute(MAIN_ROUTE_CODES.ADMIN).trimRootPath('/')}/*`}
                    element={<AdminPages />}
                />
                <Route path='*' element={<Error404 message='Page Not Found' />} />
            </Routes>
        );
    });

    return (
        <div>
            <PrivateRoutePages />
            <NotificationDrawer />
        </div>
    );
}

const NotificationDrawer = observer((props) => {
    const notificationStore = useNotificationsStore();
    initSocketConnection();

    return (
        <Drawer
            className={css['notification-drawer']}
            title='Notification hub'
            onClose={() => notificationStore.closeHub()}
            visible={notificationStore.isDisplay}
            component={<NotificationsPage />}
        />
    );
});
