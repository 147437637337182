import * as React from 'react';
import cx from 'classnames';
import { Button, Space, Table as AntdTable, Form } from 'antd';
import tableCss from './basic-table.css';
import { IBasicTableColumn, IBasicTableProps } from './basic-table';

declare type Component<P> =
    | React.ComponentType<P>
    | React.ForwardRefExoticComponent<P>
    | React.FC<P>
    | keyof React.ReactHTML;
export declare type CustomizeComponent = Component<any>;

export interface IEditableTableColumn extends IBasicTableColumn {
    component?: CustomizeComponent;
}

export interface IEditableTableProps extends Omit<IBasicTableProps, 'columns'> {
    columns: IEditableTableColumn[];
    notUseRowForm?: boolean;
}

export const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({ children, dataIndex, record, component, ...restProps }) => {
    let childNode = children;

    if (component) {
        childNode = component({
            record: record,
            key: dataIndex,
        });
    }

    return <td {...restProps}>{childNode}</td>;
};

export function EditableTable(props: IEditableTableProps) {
    const { columns, extraOptions, rowSelection, rowKey, scroll, expandable, pagination, defaultPageSize } = props;

    const renderFn = (column: IEditableTableColumn, text, record) => {
        if (!column.actions) return text;

        return (
            <Space size='small'>
                {column.actions.map((action) => (
                    <Button
                        key={action.key}
                        type='link'
                        htmlType='button'
                        onClick={() => action.onClick(record[action.key])}
                    >
                        {action.label ? action.label : text}
                    </Button>
                ))}
            </Space>
        );
    };

    const getComponents = (notUseRowForm: boolean) => {
        if (!notUseRowForm) {
            return {
                body: {
                    row: EditableRow,
                    cell: EditableCell,
                },
            };
        }

        return {
            body: {
                cell: EditableCell,
            },
        };
    };

    const editableColumns = columns.map((column) => {
        const col = {
            key: column.code,
            title: column.title,
            dataIndex: column.dataIndex,
            defaultSortOrder: column.defaultSortOrder,
            sortDirections: column.sortDirections,
            sorter: column.sorter,
            filters: column.filters,
            onFilter: column.onFilter,
            filterDropdown: column.filterDropdown,
            width: column.width,
            render: (text, record) => renderFn(column, text, record),
        };

        if (!column.component) return col;

        return {
            ...col,
            onCell: (record) => ({
                record,
                actions: column.actions,
                dataIndex: column.dataIndex,
                title: column.title,
                component: column.component,
            }),
        };
    });

    return (
        <AntdTable
            className={cx(tableCss.basicForm, props.className)}
            dataSource={props.dataSource}
            rowKey={rowKey}
            rowSelection={rowSelection}
            columns={editableColumns}
            components={getComponents(props.notUseRowForm)}
            scroll={scroll}
            expandable={expandable}
            pagination={
                props.pagination != undefined
                    ? props.pagination
                    : { defaultPageSize: defaultPageSize || 20, showSizeChanger: true, hideOnSinglePage: pagination }
            }
            {...extraOptions}
        />
    );
}
