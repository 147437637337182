import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
    BasicForm,
    IconTextButton,
    Modal,
    notification,
    SelectFormItem,
    showInformationDialog,
    SimpleFileUploadItem,
    TextFieldItem
} from 'ui-lib';

import { IDeviceGroupDataInput, TUpdateDeviceGroupOptions } from '../../../../dto/device-group-dto';
import { getErrorMessage, isNullOrEmpty, TIMEZONES } from 'common-utils';
import { inputLabelRule } from '../../components/form-utils';
import { confirmCancel } from '../../admin/utils';
import { AssignDevicesComponent } from './assign-devices';
import { useManageDeviceGroupContext } from './';
import { useApiClientStore, useDeviceGroupsStore } from '../../../../store';
import { AttributeFormItem, getDisplayAttribute, getIoTAttribute } from '../../components/attribute-form-item';
import { observer } from 'mobx-react';
import { ImportDevicesSummaryTable, TDisplayImportDeviceProps } from './import-devices-summary-table';
import { MoveDevicesSummaryTable } from './move-devices-summary-table';
import { useNavigate } from 'react-router-dom';
import { PRIVATE_ROUTES } from 'store/private-routes';

import manageDeviceGroupCss from './manage-device-group.css';

interface IDeviceGroupComponentOptions {
    title: string;
    deviceGroupLabel?: string;
    deviceGroupDescription?: string;
    deviceGroupTimezone?: string;
    deviceGroupAttribute?: IDeviceGroupDataInput;
    onSave: (params: TUpdateDeviceGroupOptions) => any;
    onCancel?: () => void;
    extra: React.ReactNode | null;
}

export const ModifyDeviceGroupComponent = () => {
    const { assignDevicesStore, clientAccountId, clientDeviceGroup, childGroup, deleteGroup, onUpdating, navigation } =
        useManageDeviceGroupContext();
    const navigate = useNavigate();

    const [assignDeviceSummaryModalProps, setAssignDeviceSummaryModalProps] = React.useState({
        visible: false,
        deviceGroupName: undefined,
        onSave: undefined,
        redirect: undefined,
    });

    const onDisplayModal = async (deviceGroupName: string, onSave: () => Promise<unknown>, redirect: (forceToDeviceGroupsTable?: boolean) => void): Promise<void> => {
        if (isNullOrEmpty(assignDevicesStore.changeGroupDevices)) {
            await onSave();
            return redirect(true);
        }
        setAssignDeviceSummaryModalProps({
            ...assignDeviceSummaryModalProps,
            onSave,
            visible: true,
            deviceGroupName,
            redirect
        });
    };

    const deviceGroupsStore = useDeviceGroupsStore();

    async function updateTimezone(deviceGroupName: string, currentTimezone: string, newTimezone: string): Promise<boolean> {
        if (currentTimezone == newTimezone) return true;
        return deviceGroupsStore.timezone(clientAccountId, deviceGroupName, newTimezone);
    }

    const deviceGroupActions = React.useMemo<IDeviceGroupComponentOptions>(() => {
        if (childGroup) {
            return {
                title: `Edit ${childGroup.deviceGroupLabel} device group`,
                ...childGroup,
                onSave: (params: TUpdateDeviceGroupOptions): unknown => {
                    const initialTimezone = childGroup.deviceGroupTimezone || '';
                    const newTimezone = params.deviceGroupTimezone || '';
                    params.deviceGroupLabel = params.deviceGroupLabel || '';
                    params.deviceGroupDescription = params.deviceGroupDescription || '';
                    if (initialTimezone && !newTimezone) {
                        onUpdating(false);
                        return showInformationDialog({
                            modalType: 'error',
                            title: 'Error',
                            content: 'Cannot set an empty timezone.',
                        });
                    }

                    const updateDeviceGroup = async (): Promise<unknown> => {
                        return Promise.all([
                            deviceGroupsStore.updateDeviceGroupByName(clientAccountId, childGroup.deviceGroupName, {
                                ...params,
                                deviceGroupTimezone: newTimezone,
                            }),
                            updateTimezone(childGroup.deviceGroupName, initialTimezone, newTimezone),
                        ]);
                    };

                    return onDisplayModal(childGroup.deviceGroupName, updateDeviceGroup, (forceToDeviceGroupsTable?: boolean): void => {
                        if (!forceToDeviceGroupsTable && childGroup.deploymentGroupId) {
                            navigate(PRIVATE_ROUTES.SOFTWARE_MANAGEMENT_DEPLOYMENTS_VIEW.path, {
                                state: {
                                    clientAccountId: clientAccountId,
                                    deploymentGroupId: childGroup.deploymentGroupId,
                                },
                            });
                        } else {
                            navigation.toDeviceGroupsTable();
                        }
                    });
                },
                extra: (
                    <IconTextButton
                        label="Delete"
                        type="primary"
                        onClick={async () => {
                            onUpdating(true);
                            try {
                                await deleteGroup(clientAccountId, childGroup);
                                navigation.toDeviceGroupsTable();
                            } catch (err) {
                                notification.error({
                                    message: 'Delete device group error',
                                    description: getErrorMessage(err),
                                });
                            } finally {
                                onUpdating(false);
                            }
                        }}
                    />
                ),
            };
        }

        return {
            title: `Create device group under ${clientDeviceGroup.deviceGroupLabel}`,
            onSave: function (params: TUpdateDeviceGroupOptions): Promise<void> {
                const deviceGroupName = uuidv4().replace(/-/g, '');
                const createDeviceGroup = async (): Promise<void> => {
                    await deviceGroupsStore.createDeviceGroupByName(clientAccountId, deviceGroupName, {
                        ...params,
                        deviceParentGroupName: clientDeviceGroup.deviceGroupName,
                    });
                    if (params.deviceGroupTimezone) {
                        await deviceGroupsStore.timezone(clientAccountId, deviceGroupName, params.deviceGroupTimezone);
                    }
                };

                return onDisplayModal(deviceGroupName, createDeviceGroup, () => {
                    navigation.toDeviceGroupsTable();
                });
            },
            extra: null,
        };
    }, []);

    const onSave = async (params: TUpdateDeviceGroupOptions) => {
        onUpdating(true);
        try {
            await deviceGroupActions.onSave(params);
        } catch (err) {
            notification.error({
                message: 'Save device group error',
                description: getErrorMessage(err),
            });
        } finally {
            onUpdating(false);
        }
    };

    return (
        <React.Fragment>
            <CreateOrEditDeviceGroupComponent
                {...deviceGroupActions}
                onSave={onSave}
                onCancel={() => {
                    assignDevicesStore.setCurrentDeviceGroup(undefined);
                    navigation.toDeviceGroupsTable();
                }}
            />
            <Modal
                width={1400}
                isVisible={assignDeviceSummaryModalProps.visible}>
                <MoveDevicesSummaryTable
                    assignDeviceStore={assignDevicesStore}
                    deviceGroupName={assignDeviceSummaryModalProps.deviceGroupName}
                    onSave={assignDeviceSummaryModalProps.onSave}
                    onClose={(hasChange?: boolean): void => {
                        setAssignDeviceSummaryModalProps((prevProps) => ({ ...prevProps, visible: false }));
                        if (hasChange) {
                            assignDevicesStore.setCurrentDeviceGroup(undefined);
                            assignDeviceSummaryModalProps.redirect();
                        }
                    }}
                />
            </Modal>
        </React.Fragment>

    );
};

export const CreateOrEditDeviceGroupComponent = observer((props: IDeviceGroupComponentOptions) => {
    const { assignDevicesStore: store, clientAccountId, clientDeviceGroup, onUpdating } = useManageDeviceGroupContext();
    const deviceGroupsStore = useDeviceGroupsStore();
    const deviceGroupApiClient = useApiClientStore().apiClients.devicegroups;

    const [importDeviceProps, setImportDeviceProps] = React.useState<{
        visible: boolean;
        devices: TDisplayImportDeviceProps[];
    }>({ visible: false, devices: undefined, });

    const [fileList, setFileList] = React.useState<File[]>([]);

    const onSubmit = async (values): Promise<void> => {
        if (values.deviceGroupAttribute) {
            values.deviceGroupAttribute = getIoTAttribute(values.deviceGroupAttribute);
        }
        await props.onSave(values);
    };

    const handleUpload = async (files: File[]): Promise<void> => {
        onUpdating(true);
        try {
            const result = await deviceGroupApiClient.importDevices(clientAccountId, clientDeviceGroup.deviceGroupName, store.currentGroupName, files);
            if (!isNullOrEmpty(result.error)) throw new Error(result.error);
            setImportDeviceProps({
                visible: true,
                devices: result.devices
            });
        } catch (err) {
            notification.error({
                message: 'Import Error',
                description: getErrorMessage(err)
            });
        } finally {
            setFileList([]);
            onUpdating(false);
        }
    };

    return (
        <React.Fragment>
            <Modal
                width={1400}
                isVisible={importDeviceProps.visible}>
                <ImportDevicesSummaryTable
                    organisationId={clientAccountId}
                    devices={importDeviceProps.devices}
                    assignDeviceStore={store}
                    deviceGroup={deviceGroupsStore.getDeviceGroupByName(store.currentGroupName)}
                    onClose={(): void => setImportDeviceProps({ visible: false, devices: undefined })}
                />
            </Modal>
            <BasicForm
                cardPros={{
                    title: <h3>{props.title}</h3>,
                    extra: props.extra,
                }}
                onSubmit={onSubmit}
                items={[
                    <TextFieldItem
                        key="deviceGroupLabel"
                        code="deviceGroupLabel"
                        label="Device group label"
                        labelAlign="right"
                        isRequired={true}
                        rule={inputLabelRule(128)}
                        initialValue={props.deviceGroupLabel}
                    />,
                    <TextFieldItem
                        key="deviceGroupDescription"
                        code="deviceGroupDescription"
                        label="Device group description"
                        labelAlign="right"
                        isRequired={false}
                        initialValue={props.deviceGroupDescription}
                    />,
                    <SelectFormItem
                        key="deviceGroupTimezone"
                        code="deviceGroupTimezone"
                        label="Timezone"
                        dataSource={[{ value: '', label: '' }, ...TIMEZONES]}
                        placeholder="Please select timezone"
                        selectedValue={props.deviceGroupTimezone}
                        initialValue={props.deviceGroupTimezone}
                        showSearch={true}
                        labelAlign="right"
                    />,
                    <AttributeFormItem
                        key="deviceGroupAttibute"
                        code="deviceGroupAttibute"
                        label="Device group attribute"
                        className={manageDeviceGroupCss.attribute}
                        initialValue={getDisplayAttribute(props.deviceGroupAttribute)}
                    />,
                    <SimpleFileUploadItem
                        key="csv-upload"
                        simpleFileUploadProps={{
                            fileList, setFileList,
                            className: manageDeviceGroupCss['csv-import-box'],
                            afterUploadChecking: handleUpload,
                            button: {
                                label: 'Select file',
                                type: 'primary'
                            }
                        }}
                        code="csv-upload"
                        label="Import devices"
                    />,
                    <AssignDevicesComponent key="assign-devices-component"/>,
                    <div className={manageDeviceGroupCss.buttons} key="action-buttons">
                        <IconTextButton key="cancel" label="Cancel" onClick={() => confirmCancel(props.onCancel)}/>
                        <IconTextButton key="save" label="Save" type="primary" htmlType="submit"/>
                    </div>,
                ]}
            />
        </React.Fragment>
    );
});
