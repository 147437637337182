import * as React from 'react';
import cx from 'classnames';
import { Drawer as AntdDrawer } from 'antd';

import drawerCss from './drawer.css';

export interface IDrawerProps {
    className?: string;
    title: string;
    trigger?: React.ReactNode;
    component: React.ReactNode;
    visible: boolean;
    width?: number;
    onClose: (event) => void;
}

export const Drawer = (props: IDrawerProps) => {
    return <React.Fragment>
        {props.trigger}
        <AntdDrawer className={cx(drawerCss.drawer, props.className)} width={props.width} title={props.title} onClose={props.onClose} visible={props.visible} >
            {props.component}
        </AntdDrawer>
    </React.Fragment>;
};
