import { IConnectionSummary, IJobExecutionsStatus, ISearchDeviceInfo } from '../../dto/device-dto';
import { ISnapshotProps } from '../software-snapshot-dto';
import { IDeploymentTargetDeviceGroupOptions, ITargetOptions } from './target-dto';

export interface IDeploymentUnit {
    type: string;
    data: {
        commandCode: string;
        commandPayload?: any;
    };
    releaseIds: number[];
}

export interface IDeploymentTarget {
    type: string;
    scheduleDateTime: Date;
    targetNames: string[];
    deviceGroupIds: number[];
    dpuOverride?: boolean;
    downloadOnly?: boolean;
    installDateTime?: Date;
}

export interface ISaveDeploymentProps {
    groupId: number;
    label: string;
    releaseId: number;
    removalPackages: string[];
    deploymentTargets: IDeploymentTarget[];
}

export enum DeploymentStatus {
    CREATED = 'CREATED',
    SCHEDULED = 'SCHEDULED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    FAILED = 'FAILED',
}

export interface ICreatedDeploymentOptions {
    uuid: string;
    id: number;
    label: string;
    targetDeviceNames: string[];
    targetDeviceGroupNames: string[];
}

export interface IDeploymentUnitDto {
    id: number;
    label: string;
    sequenceNumber: number;
    deploymentId: number;
    type: string;
    executionId: string;
    data?: {
        commandCode: string;
        commandPayload?: any;
    };
    releases?: ISnapshotProps[]; // Can't change `releases` -> `snapshots`, because this is database table
}
export interface IDeploymentOptions {
    id: number;
    label: string;
    accountUuid: string;
    sequenceNumber: number;
    autoComplete: boolean;
    status: DeploymentStatus;
    createdAt: string;
    deploymentGroupId?: number;
    deploymentUnits?: IDeploymentUnitDto[];
    deploymentTargets?: ITargetOptions[];
    jobExecutionsRolloutConfig?: any;
    abortConfig?: any;
}

export enum PROGRESS_STATUS {
    PLAY = 'PLAY',
    PAUSE = 'PAUSE',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
}

export enum PROGRESS_STAGE {
    QUEUE = 'QUEUE',
    IN_PROGRESS = 'IN_PROGRESS',
    DOWNLOADED = 'DOWNLOADED',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
}

export interface IProgressStatusProps {
    progressStatus?: PROGRESS_STATUS;
    progressStage?: PROGRESS_STAGE;
}

export type TSearchDeviceInfoProgressDetails = ISearchDeviceInfo & {
    jobInformation?: IJobExecutionsStatus;
    progressStatus?: IProgressStatusProps;
    connectivity?: IConnectionSummary;
}

interface TDeploymentTargetProgressDetails extends ITargetOptions {
    deviceGroups: IDeploymentTargetDeviceGroupOptions[];
}

export interface IDeploymentProgressDetails extends IDeploymentOptions {
    deploymentTargets?: TDeploymentTargetProgressDetails[];
}
