import * as React from 'react';
import cx from 'classnames';
import { TreeSelect as AntdTreeSelect } from 'antd';

import selectCss from './select.css';

export interface ITreeSelectDataNode {
    value: number | string;
    label: string;
    disabled?: boolean;
}

export interface ITreeSelectDataSource {
    treeDataNode: ITreeSelectDataNode;
    subTreeDataNode?: ITreeSelectDataSource[];
}

export interface ITreeSelectProps {
    code?: string;
    className?: string;
    disabled?: boolean;
    allowClear?: boolean;
    treeCheckable?: boolean;
    showCheckedStrategy?: typeof AntdTreeSelect.SHOW_ALL | typeof AntdTreeSelect.SHOW_PARENT | typeof AntdTreeSelect.SHOW_CHILD;
    placeholder?: string;
    expandAll?: boolean;
    multiple?: boolean;
    value?: ITreeSelectDataNode | string | number;
    dataSource: ITreeSelectDataSource | ITreeSelectDataSource[];
    onChange?: (value: any, labelList: React.ReactNode[], extra: any) => void;
    filterTreeNode?: (inputValue: string, treeNode) => boolean;
}

const createTreeNodes = (dataSource: ITreeSelectDataSource) => {
    if (!dataSource) return null;
    return (
        <AntdTreeSelect.TreeNode
            key={dataSource.treeDataNode.value}
            value={dataSource.treeDataNode.value}
            title={dataSource.treeDataNode.label}
            disabled={dataSource.treeDataNode.disabled}
        >
            {!dataSource.subTreeDataNode ? null : dataSource.subTreeDataNode.map((item) => createTreeNodes(item))}
        </AntdTreeSelect.TreeNode>
    );
};

export const TreeSelect = (props: ITreeSelectProps) => {
    return (
        <AntdTreeSelect
            key={props.code}
            className={cx(selectCss.treeSelection, props.className)}
            disabled={props.disabled}
            allowClear={props.allowClear}
            placeholder={props.placeholder}
            defaultValue={props.value}
            treeDefaultExpandAll={props.expandAll}
            onChange={props.onChange}
            filterTreeNode={props.filterTreeNode}
            multiple={props.multiple}
            treeCheckable={props.treeCheckable}
            showCheckedStrategy={props.showCheckedStrategy}
        >
            {Array.isArray(props.dataSource)
                ? props.dataSource.map((source) => createTreeNodes(source))
                : createTreeNodes(props.dataSource)}
        </AntdTreeSelect>
    );
};
