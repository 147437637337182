import React, { useEffect, useState } from 'react';
import { IIconButtonProps, IconButton } from './icon-button';

import styles from './copy-to-clipboard.css';

const copyTextToClipboard = async (text: string): Promise<void> => {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(text);
  } else {
    // Deprecated but required for IE11 and older browsers
    document.execCommand('copy', true, text);
  }
};

type IconButtonProps = Omit<IIconButtonProps, 'onClick' | 'iconName'>;

interface CopyToClipboardButtonProps extends IconButtonProps {
    text: string;
}

export const CopyToClipboardButton = ({ text, ...iconButtonProps }: CopyToClipboardButtonProps): JSX.Element => {
    const [tooltip, setTooltip] = useState('Copy');

    const onClick = async (): Promise<void> => {
        await copyTextToClipboard(text);
        setTooltip(() => 'Copied');
    };

    useEffect(() => {
        if (tooltip === 'Copied') {
            setTimeout(() => {
                setTooltip('Copy');
            }, 1000);
        }
    }, [tooltip]);

    return (
        <IconButton
            className={styles.copyToClipboardButton}
            iconName='copy'
            onClick={onClick}
            tooltip={tooltip}
            block={false}
            type='ghost'
            {...iconButtonProps}
        />
    );
};
