import * as React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'store';
import { AdvanceTable, CheckboxField, IBasicTableColumn, notification } from 'ui-lib';
import { AccountType } from 'dto/access-management/account-dto';

import rolesPermissionCss from './roles-permissions.css';
import { getErrorMessage } from '../../../../common-utils';

interface IDisplayLevelRoleMatrix {
    roleType: string;

    [key: string]: any;
}

export const LevelsAndRolesMatrixPage = observer(() => {
    const { roleStore } = useStores();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [displayDataSource, setDisplayDataSource] = React.useState<IDisplayLevelRoleMatrix[]>([]);

    const fetchData = (): void => {
        (async (): Promise<void> => {
            try {
                setLoading(true);
                await roleStore.loadRoles();

                const allRoles = roleStore.allRoles;
                const uniqueRoleTypes = new Set<string>();
                allRoles.forEach((role) => uniqueRoleTypes.add(role.roleType));
                const dataSource = Array.from(uniqueRoleTypes).map((roleType) => {
                    return {
                        roleType,
                        [AccountType.DPU]: allRoles.find(
                            (role) => role.accountType === AccountType.DPU && role.roleType === roleType,
                        ),
                        [AccountType.VAR]: allRoles.find(
                            (role) => role.accountType === AccountType.VAR && role.roleType === roleType,
                        ),
                        [AccountType.CLIENT]: allRoles.find(
                            (role) => role.accountType === AccountType.CLIENT && role.roleType === roleType,
                        ),
                    };
                });
                setDisplayDataSource(dataSource);
            } catch (error) {
                notification.error({
                    message: 'Error',
                    description: getErrorMessage(error),
                });
            } finally {
                setLoading(false);
            }
        })();
    };

    React.useEffect(fetchData, []);

    const table: IBasicTableColumn[] = [
        {
            code: 'rowHead',
            title: 'Role',
            dataIndex: 'roleType',
            rowScope: 'roleType',
        },
        {
            code: 'dpu',
            title: AccountType.DPU,
            dataIndex: AccountType.DPU,
            render: (value, record) => <CheckboxField label={value?.name} checked={!!value}/>,
        },
        {
            code: 'var',
            title: AccountType.VAR,
            dataIndex: AccountType.VAR,
            render: (value, record) => <CheckboxField label={value?.name} checked={!!value}/>,
        },
        {
            code: 'client',
            title: AccountType.CLIENT,
            dataIndex: AccountType.CLIENT,
            render: (value, record) => <CheckboxField label={value?.name} checked={!!value}/>,
        },
    ];
    return (
        <AdvanceTable
            className={rolesPermissionCss.levelMatrixTable}
            loadingState={loading}
            title={''}
            table={{
                columns: table,
                dataSource: displayDataSource,
                rowKey: 'roleType',
                extraOptions: {
                    pagination: {
                        hideOnSinglePage: true,
                    },
                },
            }}
        />
    );
});
