import { action, computed, makeObservable, observable } from 'mobx';
import { JobMapping, jobNameMapping } from '../../pages/private/devices/devices-inventory/utils';
import { IExecutionJobSummary as IJobExecutionSummary} from '../../dto/device-dto';
import { DeviceDetailEntryStore, IDeviceDetailsConstructorOptions } from './device-detail-entry-store';
import { formatDate } from 'common-utils/date-utils';
import { sleep } from 'common-utils';

interface IJobStatusDetailsProps {
    [key: string]: string;
}

export interface IDisplayJobExecutionSummary extends IJobExecutionSummary {
    key: string;
    jobName: string;
    displayLastUpdatedAt?: string;
    data?: IJobStatusDetailsProps;
    canRerun?: boolean;
}
interface IResponseProps {
    [jobId: string]: IJobStatusDetailsProps;
}

const ALLOW_RERUN_JOB_STATUS = ['FAILED', 'TIMED_OUT'];

export class DeviceJobsStore extends DeviceDetailEntryStore<IJobExecutionSummary> {
    constructor(options: IDeviceDetailsConstructorOptions) {
        super(options);

        makeObservable(this);
    }

    /** ************************************
     * Override functions
     **************************************/
    protected name(): string {
        return 'device jobs';
    }

    protected async initializeData(): Promise<IJobExecutionSummary[]> {
        return this._devicesStore.getDeviceJobs(this.accountId, this.uid);
    }
    /** *************************************/

    @computed
    public get keyJobs(): IDisplayJobExecutionSummary[] {
        return this.displayJobs.filter((item) => item.jobId.includes('key_management')).slice(0, 2);
    }

    @observable
    jobsData: IResponseProps = {};

    @action
    setJobsData(jobsData: IResponseProps) {
        this.jobsData = jobsData;
    }

    @computed
    public get displayJobs(): IDisplayJobExecutionSummary[] {
        if (!this.entities) return [];
        const jobs = jobNameMapping(this.entities);
        const jobsData = this.jobsData;
        return jobs.map((job) => ({
            ...job,
            displayLastUpdatedAt: formatDate(job.lastUpdatedAt),
            data: jobsData[`${job.key}`],
            canRerun: !job.ownerJob && job.jobName == JobMapping.software_update && ALLOW_RERUN_JOB_STATUS.includes(job.status),
        }));
    }

    public async onExpand(expanded: boolean, record: IDisplayJobExecutionSummary): Promise<void> {
        if (!expanded || this.jobsData[record.key]) return;

        const jobInfo = await this._devicesStore.getRemoteCommandExecutionDetailsByExecutionId(this.accountId, record.jobId, {
            deviceName: this.uid,
            executionNumber: record.executionNumber,
        });
        const newState = Object.assign({}, this.jobsData);
        newState[record.key] = jobInfo?.statusDetails?.detailsMap || {};
        this.setJobsData(newState);
    }

    public async runExecutionCommand(job: IDisplayJobExecutionSummary) {
        if (job.canRerun) await this._devicesStore.rerunExecutionCommand(this.accountId, job.jobId, { deviceName: this.uid });
        else await this._devicesStore.cancelRemoteCommandByExecutionId(this.accountId, job.jobId, { targetDeviceNames: [this.uid] });
        await sleep(3000);
        await this.initialize(true);
    }
}
