/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { PermissionPage, Error404 } from 'ui-lib';

import { ManageAccountPages } from './accounts';
import { ManageUserPages } from './users';
import { ReportsModulePages } from './reports';
import { useRoutesStore, useStores } from '../../../store';
import { ManageNotificationComponent } from '../notifications/manage-notifications';
import { MaintenanceUpdatesPage } from './maintenance-updates';
import { ConfigurationSigningWizard } from './configuration-signing';
import { UserRolePermissionsPage } from './role-permission';
import { CreateMetaPackageComponent } from './metapackage';

export enum ADMIN_ROUTE_CODES {
    ROOT = 'ADMIN',
    ACCOUNTS = 'ADMIN_ACCOUNTS',
    USERS = 'ADMIN_USERS',
    CONFIGURATION_SIGN = 'ADMIN_CONFIGURATION_SIGN',
    REPORTS = 'ADMIN_REPORTS',
    MAINTENANCE_UPDATES = 'ADMIN_MAINTENANCE_UPDATES',
    ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS',
    USER_ROLE_PERMISSIONS = 'ADMIN_USER_ROLE_PERMISSIONS',
    ADMIN_CREATE_META_PACKAGE = 'ADMIN_CREATE_META_PACKAGE',
}

export function AdminPages(): JSX.Element {
    const routeStore = useRoutesStore();
    const { permissionStore } = useStores();

    const rootRoute = routeStore.getRoute(ADMIN_ROUTE_CODES.ROOT);
    const configurationSignRoute = routeStore.getRoute(ADMIN_ROUTE_CODES.CONFIGURATION_SIGN);
    const maintenanceUpdatesRoute = routeStore.getRoute(ADMIN_ROUTE_CODES.MAINTENANCE_UPDATES);
    const addNotificationRoute = routeStore.getRoute(ADMIN_ROUTE_CODES.ADD_NOTIFICATIONS);
    const createMetaPackageRoute = routeStore.getRoute(ADMIN_ROUTE_CODES.ADMIN_CREATE_META_PACKAGE);
    const rolePermissionsRoute = routeStore.getRoute(ADMIN_ROUTE_CODES.USER_ROLE_PERMISSIONS);
    return (
        <Routes>
            <Route
                path={`${routeStore.getRoute(ADMIN_ROUTE_CODES.ACCOUNTS).trimRootPath(rootRoute.path)}/*`}
                element={
                    <PermissionPage
                        routes={routeStore.routes}
                        permissionCodes={permissionStore.permissionCodes}
                    >
                        <ManageAccountPages />
                    </PermissionPage>
                }
            />
            <Route
                path={`${routeStore.getRoute(ADMIN_ROUTE_CODES.USERS).trimRootPath(rootRoute.path)}/*`}
                element={
                    <PermissionPage
                        routes={routeStore.routes}
                        permissionCodes={permissionStore.permissionCodes}
                    >
                        <ManageUserPages />
                    </PermissionPage>
                }
            />
            <Route
                path={configurationSignRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        routes={routeStore.routes}
                        permissionCodes={permissionStore.permissionCodes}
                        title={`${configurationSignRoute.label} wizard`}
                    >
                        <ConfigurationSigningWizard />
                    </PermissionPage>
                }
            />
            <Route
                path={`${routeStore.getRoute(ADMIN_ROUTE_CODES.REPORTS).trimRootPath(rootRoute.path)}/*`}
                element={
                    <PermissionPage
                        routes={routeStore.routes}
                        permissionCodes={permissionStore.permissionCodes}
                    >
                        <ReportsModulePages />
                    </PermissionPage>
                }
            />
            <Route
                path={`${addNotificationRoute.trimRootPath(rootRoute.path)}/*`}
                element={
                    <PermissionPage
                        routes={routeStore.routes}
                        title={addNotificationRoute.label}
                        permissionCodes={permissionStore.permissionCodes}
                    >
                        <ManageNotificationComponent />
                    </PermissionPage>
                }
            />
            <Route
                path={`${maintenanceUpdatesRoute.trimRootPath(rootRoute.path)}/*`}
                element={
                    <PermissionPage
                        routes={routeStore.routes}
                        permissionCodes={permissionStore.permissionCodes}
                        title={maintenanceUpdatesRoute.label}
                    >
                        <MaintenanceUpdatesPage />
                    </PermissionPage>
                }
            />
            <Route
                path={`${rolePermissionsRoute.trimRootPath(rootRoute.path)}/*`}
                element={
                    <PermissionPage
                        routes={routeStore.routes}
                        permissionCodes={permissionStore.permissionCodes}
                        title={rolePermissionsRoute.label}
                    >
                        <UserRolePermissionsPage />
                    </PermissionPage>
                }
            />
            <Route
                path={`${createMetaPackageRoute.trimRootPath(rootRoute.path)}/*`}
                element={
                    <PermissionPage
                        routes={routeStore.routes}
                        permissionCodes={permissionStore.permissionCodes}
                        title={createMetaPackageRoute.label}
                    >
                        <CreateMetaPackageComponent />
                    </PermissionPage>
                }
            />
            <Route path='*' element={<Error404 message='Page Not Found' />} />
        </Routes>
    );
}
