import React from 'react';
import { observer } from 'mobx-react';
import snapshotsCss from './snapshots.css';

import {
    AdvanceEditableTable,
    BasicTab,
    IconTextButton,
    IEditableTableColumn,
    IEditableTableProps,
    ITableToolbarProps,
    Label,
    message,
    RangeDateTimePicker,
    showConfirmDialog,
    Typography,
} from 'ui-lib';
import { PublishSnapshotDrawer } from './publish-snapshot-drawer';
import { IDisplaySnapshotProps } from '../../../../store/snapshot.store';
import { useAuthStore, useSnapshotStore } from '../../../../store';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SNAPSHOT_STATUS } from '../../../../dto/software-snapshot-dto';
import { manageSnapshotPermission, useSnapshotContext } from '.';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

function confirmDelete(code: string, action: any) {
    showConfirmDialog({
        title: 'Confirmation',
        content: 'Do you want to delete this release?',
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: () => action(code),
    });
}

export interface ISnapshotTableProps {
    snapshotType: SNAPSHOT_STATUS;
    setSnapshotType: (type: SNAPSHOT_STATUS) => void;
}

export const ManageSnapshotsTable = observer((props: ISnapshotTableProps) => {
    const context = useSnapshotContext();

    const { assureApiAccount } = useAuthStore();
    const { canModify: hasModifyPermission, canEditSnapshot } = manageSnapshotPermission();

    const snapshotStore = useSnapshotStore();

    const [createdDataSource, setCreatedSnapshots] = React.useState<IDisplaySnapshotProps[]>([]);
    const [publishedDataSource, setPublishedSnapshots] = React.useState<IDisplaySnapshotProps[]>([]);

    React.useEffect(() => {
        setCreatedSnapshots(snapshotStore.displayCreatedSnapshots);
    }, [snapshotStore.displayCreatedSnapshots]);

    React.useEffect(() => {
        setPublishedSnapshots(snapshotStore.displayPublishedSnapshots);
    }, [snapshotStore.displayPublishedSnapshots]);

    const reloadData = async () => {
        try {
            await snapshotStore.loadEntities(assureApiAccount.accountId, true);
        } catch (err) {
            message.error(err.message);
        }
    };

    const onDeleteSnapshot = async (snapshotId: number) => {
        try {
            await snapshotStore.deleteSnapshot(assureApiAccount.accountId, snapshotId);
        } catch (err) {
            message.error(err.message);
        }
    };

    const onCreatedSnapshotsSearch = (search = '') => {
        setCreatedSnapshots(
            snapshotStore.displayCreatedSnapshots.filter((item: IDisplaySnapshotProps) =>
                item.label.toLowerCase().includes(search.toLowerCase()),
            ),
        );
    };

    const onPublishedSnapshotsSearch = (search = '') => {
        setPublishedSnapshots(
            snapshotStore.displayPublishedSnapshots.filter((item: IDisplaySnapshotProps) =>
                item.label.toLowerCase().includes(search.toLowerCase()),
            ),
        );
    };

    const onDeleteRow = (snapshotId: number) => {
        confirmDelete('onDelete', async () => onDeleteSnapshot(snapshotId));
    };

    const SnapshotNameButton = (props) => {
        const snapshot: IDisplaySnapshotProps = props.record;

        const handler = () => {
            if (snapshot.status == SNAPSHOT_STATUS.RELEASED) return context.toPublishedSnapshotDetails(snapshot.id);
            return context.toModifySnapshot(snapshot.id);
        };

        return <Typography.Link key={snapshot.id} onClick={handler}>{snapshot.label}</Typography.Link>;
    };

    const SnapshotActionComponent = (props: { record: IDisplaySnapshotProps }): JSX.Element => {
        const snapshot: IDisplaySnapshotProps = props.record;
        return (
            <div className={snapshotsCss.actionButtons}>
                <PublishSnapshotDrawer
                    code={`${snapshot.id}`}
                    title="Publishing Software Release"
                    actionButton={{
                        htmlType: 'button',
                        type: 'link',
                        label: 'Publish',
                        disabled: !canEditSnapshot(snapshot.accountUuid),
                    }}
                    snapshot={snapshot}
                    submitAction={(values, id: number): void => context.toPreviewSnapshot(id)}
                />
                <IconTextButton
                    className={snapshotsCss.linkButton}
                    key={snapshot.id}
                    type="link"
                    onClick={(): void => onDeleteRow(snapshot.id)}
                    label="Delete"
                    disabled={!canEditSnapshot(snapshot.accountUuid)}
                />
            </div>
        );
    };

    const softwareSnapshotNameColumn: IEditableTableColumn = {
        code: 'softwareSnapshotName',
        title: 'Software release name',
        dataIndex: 'label',
        width: '45%',
        component: SnapshotNameButton,
        sorter: (a: IDisplaySnapshotProps, b: IDisplaySnapshotProps) => a.label.localeCompare(b.label),
    };

    const softwareSnapshotActionsColumn: IEditableTableColumn = {
        code: 'softwareSnapshot',
        title: 'Actions',
        dataIndex: 'id',
        width: '20%',
        component: SnapshotActionComponent,
    };

    const softwareSnapshotVersionColumn: IEditableTableColumn = {
        code: 'softwareSnapshotVersion',
        title: 'Version',
        dataIndex: 'version',
        width: '20%',
        sorter: (a: IDisplaySnapshotProps, b: IDisplaySnapshotProps) =>
            a.version.localeCompare(b.version, undefined, { numeric: true }),
    };

    const softwareSnapshotAccountColumn: IEditableTableColumn = {
        code: 'softwareSnapshotAccount',
        title: 'VAR/Client name',
        dataIndex: 'accountName',
        width: '15%',
        sorter: (a: IDisplaySnapshotProps, b: IDisplaySnapshotProps) => a.accountName.localeCompare(b.accountName),
    };

    const softwareSnapshotCreatedDateColumn: IEditableTableColumn = {
        code: 'softwareSnapshotCreatedDate',
        title: 'Created date',
        dataIndex: 'createdAt',
        width: '20%',
        defaultSortOrder: 'descend',
        sorter: (a: IDisplaySnapshotProps, b: IDisplaySnapshotProps) =>
            Date.parse(a.createdAtTimestamp) - Date.parse(b.createdAtTimestamp),
        onFilter: (value, record) =>
            record?.createdAt ? dayjs(record.createdAt, 'YYYY-MM-DD HH:mm:ss').isBetween(value[0], value[1]) : false,
        filterDropdown: ({ setSelectedKeys, confirm }) => (
            <RangeDateTimePicker
                allowClear
                onChange={(e) => {
                    setSelectedKeys(e?.length ? [e as any] : []);
                    confirm();
                }}
            />
        ),
    };

    const defaultSnapshotColumns: IEditableTableColumn[] = [
        softwareSnapshotNameColumn,
        softwareSnapshotAccountColumn,
        softwareSnapshotCreatedDateColumn,
    ];

    const createdTable: IEditableTableProps = {
        dataSource: createdDataSource,
        columns: [...defaultSnapshotColumns, ...(hasModifyPermission ? [softwareSnapshotActionsColumn] : [])],
    };

    const publishedTable: IEditableTableProps = {
        dataSource: publishedDataSource,
        columns: [...defaultSnapshotColumns, softwareSnapshotVersionColumn],
    };

    const onAdd = () => {
        context.toModifySnapshot(undefined);
    };

    const createdTableToolbar: ITableToolbarProps = {
        onSearch: onCreatedSnapshotsSearch,
        onAdd: hasModifyPermission ? onAdd : undefined,
        onReload: reloadData,
        searchPlaceholder: 'Search for a software release',
    };

    const publishedTabletoolbar: ITableToolbarProps = {
        onSearch: onPublishedSnapshotsSearch,
        onReload: reloadData,
        searchPlaceholder: 'Search for a software release',
    };

    return (
        <React.Fragment>
            <Typography.Text>
                Software Releases can be of two types, Created or Published. Created Software Releases can be edited
                (packages added and removed) or the whole release deleted. Once a Software Release has been published,
                it can no longer be edited.
            </Typography.Text>
            <br/>
            <br/>
            <Label label="Software Releases" strong/>
            <BasicTab
                defaultActiveIndex={0}
                activeKey={props.snapshotType}
                onChange={(activeKey: SNAPSHOT_STATUS) => props.setSnapshotType(activeKey)}
                children={[
                    {
                        label: 'Created',
                        key: SNAPSHOT_STATUS.CREATED,
                        pane: (
                            <AdvanceEditableTable
                                toolbar={createdTableToolbar}
                                title=""
                                loadingState={snapshotStore.dataLoading}
                                table={createdTable}
                            />
                        ),
                    },
                    {
                        label: 'Published',
                        key: SNAPSHOT_STATUS.RELEASED,
                        pane: (
                            <AdvanceEditableTable
                                toolbar={publishedTabletoolbar}
                                title=""
                                loadingState={snapshotStore.dataLoading}
                                table={publishedTable}
                            />
                        ),
                    },
                ]}
            />
        </React.Fragment>
    );
});
