import * as React from 'react';
import { Divider, Typography } from 'antd';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { Icon } from '../icon';

import baseErrorCss from './base-error.css';

export interface IBaseErrorProps {
    iconName: IconName;
    title: string;
    message: string;
    stackTrace?: string;
    children?: React.ReactNode;
}

export const BaseError = (props: IBaseErrorProps) => (
    <div className={baseErrorCss.baseErrorWrapper}>
        <div className={baseErrorCss.baseError}>
            <Icon iconName={props.iconName} size='X-LARGE' />
            <div className={baseErrorCss.baseErrorContent}>
                <Typography.Title level={4}>{props.title}</Typography.Title>
                <Typography.Text>{props.message}</Typography.Text>
                <div>
                    {!__IS_PRODUCTION && props.stackTrace ? (
                        <>
                            <Divider />
                            <Typography.Text type='secondary'>{props.stackTrace}</Typography.Text>
                        </>
                    ) : null}
                </div>
                <div className={baseErrorCss.baseErrorFooter}></div>
            </div>
            {/* {props.children}*/}
        </div>
    </div>
);
