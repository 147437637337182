import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import {
    UserNotFoundWrapper as UserNotFoundPageWrapper,
    UserNotHaveAccessWrapper as UserNotHaveAccessPageWrapper,
} from '../errors';
import { ForgotPasswordPageWrapper } from './login/forgot-password/forgot-password';
import { LoginPageWrapper } from './login/login';

export function PublicPages() {
    return (
        <Routes>
            <Route path='*' element={<LoginPageWrapper />} />
            <Route path='/forgot-password' element={<ForgotPasswordPageWrapper />}></Route>
            <Route path='/not-found' element={<UserNotFoundPageWrapper />}></Route>
            <Route path='/not-have-access' element={<UserNotHaveAccessPageWrapper />}></Route>
        </Routes>
    );
}
