import { computed, makeObservable } from 'mobx';
import { PackagesStore } from '../packages-store';
import { PACKAGE_TYPE } from 'dto/packages-dto';
import { formatDate } from 'common-utils/date-utils';
import { AbstractDeviceDetailWithCommandCodeStore, ICommandCodeConstructorOptions } from './device-detail-with-command-code-store';

export interface IDevicePackage {
    packageName: string;
    version: string;
    type: string;
    arch: string;
    installedAtTimestamp: string;
    installed: boolean;
}

export interface IDisplayDevicePackage extends IDevicePackage {
    key: string;
}

type IPackageListingConstructorOptions = Omit<ICommandCodeConstructorOptions, 'code'>;

export class DevicePackagesStore extends AbstractDeviceDetailWithCommandCodeStore<IDevicePackage> {
    protected readonly packagesStore: PackagesStore;
    constructor(options: IPackageListingConstructorOptions, packagesStore: PackagesStore) {
        super({ code: 'package_listing', ...options });
        makeObservable(this);
        this.packagesStore = packagesStore;
    }

    /** ************************************
     * Override functions
     **************************************/
    protected name(): string {
        return 'device package listing';
    }

    protected async initializeData(): Promise<IDevicePackage[]> {
        const dPackages = await this._devicesStore.getCurrentPackageListing(this.accountId, this.uid);
        const packages = this.packagesStore.displayPackages;

        return dPackages.map((item) => {
            const currentPackage = packages.find(
                (obj) => obj.label === item.packageName && obj.version === item.version,
            );
            return {
                ...item,
                type: currentPackage?.type || PACKAGE_TYPE.VOS,
                installedAtTimestamp: item.installedAtTimestamp ? formatDate(Number(item.installedAtTimestamp) * 1000) : currentPackage?.updatedAt ? currentPackage?.updatedAt : '',
            };
        });
    }
    /** *************************************/

    @computed
    public get displayPackages(): IDisplayDevicePackage[] {
        if (!this.entities) return [];
        return this.entities.map((item) => ({ key: `${item.packageName}-${item.version}-${item.arch}`, ...item }));
    }

    @computed
    public get archFilterTypes(): { text: string; value: string }[] {
        return this.displayPackages.reduce((initial, item) => {
            if (!initial.find((filterType) => item.arch == filterType.value)) {
                initial.push({ text: item.arch, value: item.arch });
            }
            return initial;
        }, []);
    }
}
