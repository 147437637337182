import {computed, makeObservable } from 'mobx';
import { DeviceDetailEntryStore, IDeviceDetailsConstructorOptions } from './device-detail-entry-store';
import { DEVICE_JOB_EXECUTION_STATUS } from 'dto/remote-commands';
import { sleep } from 'common-utils';

export interface ICommandProps<T> extends ICommonCommandCode {
    commandPayload: T;
}
export interface ICommonCommandCode {
    commandCode: string;
    targetDeviceNames: string[];
    description?: string;
}

export interface ICommandCodeConstructorOptions extends IDeviceDetailsConstructorOptions {
    code: string; // Command code for payload
}

export abstract class AbstractDeviceDetailWithCommandCodeStore<T> extends DeviceDetailEntryStore<T> {
    protected readonly commandCode: string;

    constructor(options: ICommandCodeConstructorOptions) {
        super(options);
        this.commandCode = options.code;
        makeObservable(this);
    }

    @computed
    protected get commandPayload(): ICommonCommandCode {
        return { commandCode: this.commandCode, targetDeviceNames: [this.uid] };
    }

    protected async triggeredCommand(): Promise<unknown> {
        const MAX_RETRY_ATTEMPTS = 2;
        const commandResult = await this._devicesStore.triggerRemoteCommand(this.accountId, this.commandPayload);
        const checkStatus = async (retryAttempts = 0): Promise<{ [deviceName: string]: DEVICE_JOB_EXECUTION_STATUS; }> => {
            await sleep(5000);
            const result = await this._devicesStore.getRemoteCommandStatusByExecutionId(this.accountId, commandResult.executionId, { targetDeviceNames: [this.uid] });
            const triggeredCommandResult = {};
            let isPending = false;
            for (const device of result.devices) {
                if (device.status === DEVICE_JOB_EXECUTION_STATUS.IN_PROGRESS) {
                    isPending = true;
                }
                triggeredCommandResult[device.deviceName] = device.status;
            }
            if (retryAttempts >= MAX_RETRY_ATTEMPTS || !isPending) {
                return triggeredCommandResult;
            }
            return checkStatus(++retryAttempts);
        };
        return checkStatus();
    }
}
