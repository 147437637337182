import { computed } from 'mobx';
import { PackagesApiClient } from '../api/packages-api-client';
import {
    ICreateMultiplePackagesResponseProps,
    ICreatePackageRequestProps as ICreatePackagesRequestProps,
    IDetectPackageDependenciesResult,
    IPackageProps,
    IUpdatePackageGroupRequestProps,
    PACKAGE_TYPE,
} from '../dto/packages-dto';
import { formatDate } from 'common-utils/date-utils';
import { IAssureStoreConstructorOptions } from './assure-base.store';
import { AccountStore } from './account-store';
import { SoftwareManagementStore } from './software-management-store';
import { uniqWith, differenceWith } from 'lodash';
import { isNullOrEmpty } from 'common-utils';
import { packageDependencyComparator } from 'common-utils/package-utils';


export type TDisplayPackageProps = IPackageProps & {
    accountName: string;
    updatedAtTimestamp: string; // for sorting
    createdAtTimestamp: string; // for sorting
};

export interface IDisplayPackageWithGroupProps {
    id: number;
    label: string;
    packageGroupId: number;
    packageGroupLabel: string;
}

export interface IGetPackageProps {
    name: string;
    version?: string;
}

export class PackagesStore extends SoftwareManagementStore<PackagesApiClient, IPackageProps> {

    public constructor(options: IAssureStoreConstructorOptions, accountStore: AccountStore) {
        super(options, accountStore);
    }

    protected get apiClient(): PackagesApiClient {
        return this.apiClientStore.apiClients.packages;
    }

    protected getDataByAccountId(accountId: number): Promise<IPackageProps[]> {
        return this.apiClient.getAllPackagesByAccountId(accountId);
    }

    public addNewPackages(...newPackages: IPackageProps[]): Promise<void> {
        if (!this.hasEntities()) {
            return;
        }

        const uniqPackages = uniqWith([...this.entities, ...(newPackages ?? [])], (currentPkg, newPkg) => currentPkg.id == newPkg.id);
        this.setEntities(uniqPackages);
    }

    async createPackages(
        accountId: number,
        options: ICreatePackagesRequestProps,
    ): Promise<ICreateMultiplePackagesResponseProps> {
        const result = await this.apiClient.createPackages(accountId, options);
        this.addEntity(...result.packages);
        return result;
    }

    async updateGroup(accountId: number, options: IUpdatePackageGroupRequestProps): Promise<boolean> {
        const isUngroup: boolean = options.packageGroupId == undefined || options.packageGroupId === -1;
        if (isUngroup) {
            await this.apiClient.ungroup(accountId, { packageId: options.packageId });
        } else {
            await this.apiClient.updateGroup(accountId, options);
        }
        this.updateEntity<Pick<IPackageProps, 'packageGroupId'>>(options.packageId, { packageGroupId: isUngroup ? undefined : options.packageGroupId });
        return true;
    }

    async deletePackage(accountId: number, packageId: number): Promise<boolean> {
        await this.apiClient.deletePackage(accountId, packageId);
        this.removeEntity(packageId);
        return true;
    }

    public getPackage(options: IGetPackageProps): TDisplayPackageProps {
        return this.displayPackages.find(item => {
            const matched = item.label == options.name;
            if (!matched) return false;
            return options.version ? item.version == options.version : true;
        });
    }
    public getPackagesByIds(packageIds: number[]): TDisplayPackageProps[] {
        if (isNullOrEmpty(packageIds)) {
            return [];
        }
        return this.displayPackages.filter((pkg) => packageIds.includes(pkg.id));
    }

    public detectDependencyPackages(packageIds: number[]): IDetectPackageDependenciesResult[] {
        if (isNullOrEmpty(packageIds)) {
            return [];
        }
        const packages = this.displayPackages.filter((pkg) => packageIds.includes(pkg.id));
        if (isNullOrEmpty(packages)) {
            return [];
        }

        return packages.map((pkg: IPackageProps): IDetectPackageDependenciesResult => {
            const dependencyPackageLabels = pkg.dependencyPackages?.map((item) => item.label);
            if (isNullOrEmpty(dependencyPackageLabels)) {
                return null;
            }

            const differenceDependencyPackages = differenceWith(pkg.dependencyPackages, packages, packageDependencyComparator);

            return isNullOrEmpty(differenceDependencyPackages) ? null : {
                id: pkg.id,
                label: pkg.label,
                type: pkg.type,
                dependencies: pkg.dependencyPackages,
                missingDependencies: differenceDependencyPackages,
            };
        }).filter((item) => !!item);
    }

    @computed
    get displayPackages(): TDisplayPackageProps[] {
        if (!this.hasEntities()) return [];
        return this.entities.map((item) => ({
            ...item,
            key: item.id.toString(),
            type: item.type == PACKAGE_TYPE.DPU ? PACKAGE_TYPE.ALM : item.type,
            accountName: !item.accountUuid ? '' : this.getAccountNameByUuid(item.accountUuid),
            createdAt: formatDate(item.createdAt),
            updatedAt: formatDate(item.updatedAt), // for displaying on UI
            updatedAtTimestamp: item.updatedAt, // for sorting
            createdAtTimestamp: item.createdAt,
        }));
    }
}
