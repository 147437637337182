import { IPackageProps } from "./packages-dto";

export enum CONFIGURATION_TYPES {
    GLOBAL = 'SCR',
    VAR = 'VAR',
    CLIENT = 'CLIENT',
}

export interface IFileMetadata {
    description: string;
    releaseNotes: string;
    crc: string;
}

export interface IConfigurationMetadata {
    [fileName: string]: IFileMetadata;
}

export interface IConfiguration {
    id: number;
    version: string;
    name: string;
    accountUuid?: string;
    clientAccountUuid?: string;
    metadata?: IConfigurationMetadata;
}

export interface IListConfigurations {
    configurations?: IConfiguration[];
}

export interface IGetConfigurationParams {
    accountId: number;
    type: CONFIGURATION_TYPES;
    clientAccountUuid?: string;
}

export interface IGetLatestVersionFile {
    type: string;
    clientAccountUuid?: string;
}

export interface IVersionResponse {
    version: string;
}

export interface ISignAndCreateConfigurationFile {
    type: string;
    nextVersionLevel: string;
    files: File[];
    metadata: string;
    clientAccountUuid?: string;
}

export interface ISignAndCreateConfigurationFileResult {
    message: string;
    package: IPackageProps;
    debianFile: {
        name: string;
        version: string;
    }
}