import { IApiClientOptions } from 'core';
import {
    IConfiguration,
    IGetLatestVersionFile,
    IVersionResponse,
    IListConfigurations,
    ISignAndCreateConfigurationFile,
} from 'dto/configuration-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';
import { IPackageProps } from 'dto/packages-dto';

type TGetConfiguration = IGetLatestVersionFile;

interface ISignAndCreateConfigurationResponse {
    message: string;
    package: IPackageProps;
    configuration: IConfiguration;
}

export class ConfigurationApiClient extends AssureBaseApiClient {

    constructor(options: IApiClientOptions) {
        super('configuration', options);
    }

    protected getRootPath(): string {
        return 'configuration';
    }

    public getLatestVersion(
        accountId: number,
        params: IGetLatestVersionFile,
    ): Promise<IVersionResponse> {
        return this.send<IVersionResponse>(HTTP_METHOD.GET, this.getRequestOptionsWithAccountId(accountId, 'version', params));
    }

    public getConfiguration(
        accountId: number,
        params: TGetConfiguration,
    ): Promise<IConfiguration> {
        return this.send<IConfiguration>(HTTP_METHOD.GET, this.getRequestOptionsWithAccountId(accountId, 'current', params));
    }

    public getListConfigurations(
        accountId: number,
        params: TGetConfiguration,
    ): Promise<IListConfigurations> {
        return this.send<IListConfigurations>(HTTP_METHOD.GET, this.getRequestOptionsWithAccountId(accountId, 'list', params));
    }

    public signAndCreateConfigurationFile(
        accountId: number,
        params: ISignAndCreateConfigurationFile,
    ): Promise<ISignAndCreateConfigurationResponse> {
        return this.send<ISignAndCreateConfigurationResponse>(HTTP_METHOD.POST, this.getRequestOptionsWithAccountId(accountId, 'sign', params));
    }
}
