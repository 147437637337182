import { Menu } from 'antd';
import cx from 'classnames';
import * as React from 'react';
import { Icon } from '../icon';

import verticalMenuCss from './vertical-menu.css';
import { IBaseMenuItemProps } from './menu-item';

export interface IVerticalMenuProps extends IBaseMenuItemProps {
    className?: string;
}

export function VerticalMenu(props: IVerticalMenuProps) {
    const listOfMenuItems = props.menuItems.map((menuItem) => (
      {
          key : menuItem.code,
          icon: <Icon iconName={menuItem.iconName} size="SMALL" asPrefix />,
          onClick: ()=> menuItem.onClick(menuItem),
          label: menuItem.label(menuItem),
          className: verticalMenuCss.menuItem
      }
    ));

    return (
        <Menu mode='vertical' className={cx(verticalMenuCss.verticalMenu, props.className)} items={listOfMenuItems} />
    );
}
