import * as React from 'react';

import { IListState, ManageDeploymentGroupsComponent } from './list';
import { ISaveState, SaveDeploymentGroupComponent } from './save';

export enum DEPLOYMENT_GROUP_ROUTE_CODES {
    ROOT = 'SOFTWARE_MANAGEMENT_DEPLOYMENTS_GROUPS_VIEW',
    MODIFY = 'SOFTWARE_MANAGEMENT_DEPLOYMENTS_GROUPS_MODIFY',
}

export enum ACTION_TYPES {
    ADD,
    EDIT,
    VIEW,
}

export enum PAGE_TYPES {
    LIST,
    MODIFY,
}

export function ManageDeploymentGroupPage(): JSX.Element {

    const [pageGroupType, setPageGroupType] = React.useState<PAGE_TYPES>(PAGE_TYPES.LIST);
    const [listState, setListState] = React.useState<IListState>();
    const [modifyState, setModifyState] = React.useState<ISaveState>();

    return (
        <React.Fragment>
            {pageGroupType == PAGE_TYPES.LIST && (
                <ManageDeploymentGroupsComponent
                    setNavigateState={setModifyState}
                    state={listState}
                    setPageType={setPageGroupType}
                />
            )}
            {pageGroupType == PAGE_TYPES.MODIFY && (
                <SaveDeploymentGroupComponent
                    setNavigateState={setListState}
                    state={modifyState}
                    setPageType={setPageGroupType}
                />
            )}
        </React.Fragment>
    );
}
