import { IApiClientOptions } from 'core';
import { IPackageGroupProps } from '../dto/package-groups-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';

const packageGroupsRootPath = 'package-groups';

export class PackageGroupsApiClient extends AssureBaseApiClient {
    protected getRootPath(): string {
        return packageGroupsRootPath;
    }

    constructor(options: IApiClientOptions) {
        super('packageGroups', options);
    }

    public async getAllPackageGroupsByAccountId(accountId: number): Promise<IPackageGroupProps[]> {
        const result = await this.send<{ packageGroups: IPackageGroupProps[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, ''),
        );
        return result.packageGroups;
    }

    public async createGroup(accountId: number, groupLabel: string, type: string): Promise<IPackageGroupProps> {
        const params = { label: groupLabel, type };
        const result = await this.send<{ packageGroup: IPackageGroupProps }>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, '', params),
        );
        return result.packageGroup;
    }

    public async updateGroup(accountId: number, packageGroupId: number, groupLabel: string): Promise<boolean> {
        const result = await this.send<{ success: true }>(
            HTTP_METHOD.PUT,
            this.getRequestOptionsWithAccountId(accountId, `${packageGroupId}`, { label: groupLabel }),
        );
        return result.success;
    }

    public async deletePackageGroup(accountId: number, packageGroupId: number): Promise<boolean> {
        const result = await this.send<{ success: true }>(
            HTTP_METHOD.DELETE,
            this.getRequestOptionsWithAccountId(accountId, `${packageGroupId}`),
        );
        return result.success;
    }
}
