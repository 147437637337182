import * as React from 'react';
import { BasicTab } from 'ui-lib/components/tab/basic-tab';
import { LevelsAndRolesMatrixPage } from './levels-roles-matrix';
import { RolesAndPermissionsMatrixPage } from './roles-permissions-matrix';

export enum DISPLAY_MATRIX_STAGE {
    LEVELS_ROLES = 'levelRole',
    ROLES_PERMISSIONS = 'rolePermissions',
}

export const UserRolePermissionsPage = (): JSX.Element => {
    return (
        <BasicTab
            defaultActiveIndex={0}
            children={[
                {
                    label: 'Levels vs Roles Matrix',
                    key: DISPLAY_MATRIX_STAGE.LEVELS_ROLES,
                    pane: <LevelsAndRolesMatrixPage />,
                },
                {
                    label: 'User Roles Permissions Matrix',
                    key: DISPLAY_MATRIX_STAGE.ROLES_PERMISSIONS,
                    pane: <RolesAndPermissionsMatrixPage />,
                },
            ]}
        />
    );
};
