import { IRoutes } from 'core';

export const PUBLIC_ROUTES: IRoutes = {
    LOGIN: {
        path: '/',
        label: 'LOGIN',
        iconName: 'sign-in',
    },
    FORGOT_PASSWORD: {
        path: '/forgot-password',
        label: 'FORGOT PASSWORD',
    },
};
