import * as React from 'react';
import { Card, Steps, Divider, Button } from 'antd';
import { IconName } from '@fortawesome/fontawesome-common-types';
import horizontalStepperCss from './horizontal-stepper.css';
import { showConfirmDialog } from '../dialogs/confirm-dialog';
import cx from 'classnames';

export interface IStepItem {
    code: string;
    className?: string;
    iconName?: IconName;
    label: string | React.ReactNode;
    description?: string | React.ReactNode | any[];
    content?: React.ReactNode;
    disableNextButton?: boolean;
    beforeNext?: () => unknown;
    beforeBack?: () => unknown;
    status?: 'wait' | 'process' | 'finish' | 'error';
}

export interface IHorizontalStepperProps {
    items: IStepItem[];
    currentStepCode?: string;
    onStepCodeChange?: (previous: IStepItem, current: IStepItem) => void;
    confirmBack?: boolean;
    canGoBack?: boolean;
    onFinish?: () => void;
    hideFinish?: boolean;
    finishLabel?: string | 'FINISH';
    onCancel?: () => void;
    className?: string;
}

function confirm(action?: any) {
    showConfirmDialog({
        title: 'Confirm',
        content: 'Are you sure you want to navigate back? Data will be lost.',
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: () => action(),
    });
}

export function HorizontalStepper(props: IHorizontalStepperProps) {
    const [currentStepCode, setCurrentStepCode] = React.useState(props.currentStepCode || props.items[0].code);
    const currentStepIndex = props.items.findIndex((item) => item.code === currentStepCode);

    React.useEffect(() => {
        setCurrentStepCode(props.currentStepCode);
    }, [props.currentStepCode]);

    const onGoBack = () => {
        const currentItem = props.items[currentStepIndex];
        if (currentItem.beforeBack) {
            currentItem.beforeBack();
        }

        const previousStepItem = props.items[currentStepIndex - 1];
        if (previousStepItem) setCurrentStepCode(previousStepItem.code);

        if (props.onStepCodeChange) props.onStepCodeChange(currentItem, previousStepItem);
    };

    const onNext = async () => {
        const currentItem = props.items[currentStepIndex];
        if (currentItem.beforeNext) {
            await currentItem.beforeNext();
        }

        const nextStepItem = props.items[currentStepIndex + 1];
        if (nextStepItem) setCurrentStepCode(nextStepItem.code);
        if (props.onStepCodeChange) props.onStepCodeChange(currentItem, nextStepItem);
    };

    return (
        <Card className={cx(horizontalStepperCss.horizontalStepper, props.className)}>
            <Steps size='small' type='navigation' current={currentStepIndex}>
                {props.items.map((stepItem) => (
                    <Steps.Step
                        key={stepItem.code}
                        title={`${stepItem.label.toString().toUpperCase()}`}
                        status='wait'
                        description={stepItem.description}
                    />
                ))}
            </Steps>
            <div className={horizontalStepperCss.stepContent}>
                {props.items[currentStepIndex].content ? props.items[currentStepIndex].content : <div />}
            </div>

            <Divider />
            <div className={horizontalStepperCss.actions}>
                {props.canGoBack && currentStepIndex > 0 ? (
                    <Button type='default' onClick={() => (props.confirmBack ? confirm(onGoBack) : onGoBack())}>
                        BACK
                    </Button>
                ) : props.onCancel && props.canGoBack && currentStepIndex === 0 ? (
                    <Button type='default' onClick={() => (props.onCancel ? confirm(props.onCancel) : null)}>
                        CANCEL
                    </Button>
                ) : (
                    <div />
                )}
                {Number(props.items[currentStepIndex].code) === props.items.length ? (
                    !props.hideFinish && <Button htmlType='submit' type='primary' onClick={props.onFinish}>
                        {props.finishLabel || 'FINISH'}
                    </Button>
                ) : !props.items[currentStepIndex].disableNextButton ? (
                    <Button type='primary' onClick={onNext}>
                        NEXT
                    </Button>
                ) : (
                    <div></div>
                )}
            </div>
        </Card>
    );
}
