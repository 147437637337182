import { IApiClientOptions } from 'core';
import { IRole, IUpdateRolePermissionsParams } from '../dto/access-management/user-role-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';

export class RoleApiClient extends AssureBaseApiClient {
    constructor(options: IApiClientOptions) {
        super('role', options);
    }

    protected getRootPath(): string {
        return '/roles';
    }

    public async getRoles(): Promise<IRole[]> {
        const result = await this.send<{ roles: IRole[] }>(HTTP_METHOD.GET, this.getRequestOptions());
        return result.roles;
    }

    public async grantPermissionsToRole(params: IUpdateRolePermissionsParams[]): Promise<IRole[]> {
        return this.send<IRole[]>(HTTP_METHOD.POST, this.getRequestOptions('grant-permissions', params));
    }
}
