import { computed } from 'mobx';
import { SocketClient } from '../../socket/socket-client';
import { SocketClientBuilder } from '../../socket/socket-builder';

export class SocketClientStore {
    private _socketClient: SocketClient;

    public buildAndConnect(accountId: number) {
        if (this._socketClient) {
            this._socketClient.disconnect();
        }

        const builder = new SocketClientBuilder()
            .setAutoConnect(true)
            .setReconnectionAttempts(5)
            .setPath('/ws/main')
            .setQuery({ accountId });

        this._socketClient = builder.create();
    }

    @computed
    public get socketClient(): SocketClient {
        return this._socketClient;
    }
}
