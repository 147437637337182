import * as React from 'react';
import { observer } from 'mobx-react';

import {
    BasicForm,
    DangerAlert,
    IconTextButton,
    ITreeSelectDataSource,
    Label,
    LabelFieldItem,
    ManualRangeTimePickerFormItem,
    SwitchSelectFormItem,
    TextFieldItem,
    useForm,
} from 'ui-lib';
import { confirmCancel } from '../../utils';
import { useAccountStore, useAuthStore, useDeploymentGroupsStore, useDeviceGroupsStore } from 'store';
import { ACTION_TYPES, PAGE_TYPES } from '.';
import { IListState } from './list';

import { useDeviceGroupCommonStates } from 'pages/private/devices/device-groups';
import { DeviceGroupMultipleSelectItem } from 'pages/private/components/device-groups-select';
import { OrganisationSelectItem } from 'pages/private/components/organisation-select';
import {
    IDisplayDeploymentGroup,
    IOutOfServiceSettings,
    CreateDeploymentGroupDetailsProps,
} from 'dto/deployment/deployment-group-dto';
import { AccountType } from 'dto/access-management/account-dto';
import { getTimezones } from '../index';

import deploymentGroupCss from './group.css';
import { IDeviceGroupOptions } from 'dto/device-group-dto';
import { useSpinnerComponent } from 'pages/private/components/loading-component';

interface ISaveDeploymentGroupComponentProps {
    setNavigateState: (value: IListState) => void;
    setPageType: (type: PAGE_TYPES) => void;
    state: ISaveState;
}

export interface ISaveState {
    groupId?: number;
    actionType: ACTION_TYPES;
}

const emptyOutOfServiceSettings = {
    enableServiceWindow: true,
    serviceWindowStart: null,
    serviceWindowEnd: null,
    allowSendLogs: true,
};

const commonValidationMessage = 'At least one alphanumeric character should be present.';

const fieldRules = {
    label: {
        rule: '^(?=.*[A-Za-z0-9])^([A-Za-z0-9_+ -.()]{4,})$',
        errorMessage: `Deployment group should be at least 4 characters long, and contain only alphanumeric, space and "+", "-", "_", ".", "(", ")" characters. ${commonValidationMessage}`,
    },
};

export const SaveDeploymentGroupComponent = observer((props: ISaveDeploymentGroupComponentProps): JSX.Element => {
    const accountStore = useAccountStore();
    const { currentUser } = useAuthStore();
    const deploymentGroupStore = useDeploymentGroupsStore();
    const deviceGroupStore = useDeviceGroupsStore();
    const { state } = props;
    const groupId = state.groupId;
    const viewOnly = state.actionType === ACTION_TYPES.VIEW;

    const [deploymentSaveForm] = useForm();
    const { SpinnerComponent, loading, errorMessage, safeCall } = useSpinnerComponent();

    const existingGroup = React.useMemo<IDisplayDeploymentGroup>(() => {
        if (!groupId) {
            return undefined;
        }
        return deploymentGroupStore.findDeploymentGroupById(groupId);
    }, [groupId]);

    const { onAccountChanged, treeSelectableDataSource } = useDeviceGroupCommonStates({
        renderNode: (deviceGroup: IDeviceGroupOptions) => {
            if (deviceGroup.deviceGroupType === 'isClient') {
                return { disabled: true };
            }
            if (!existingGroup) {
                return { disabled: !!deviceGroup.deploymentGroupId };
            }
            const disabled = !!deviceGroup.deploymentGroupId && deviceGroup.deploymentGroupId !== existingGroup.id;
            return { disabled };
        },
    });

    const selectAccountSource = React.useMemo<ITreeSelectDataSource>(
        () =>
            accountStore.getSelectableTreeDataSource(Object.values(AccountType), [AccountType.CLIENT, AccountType.VAR]),
        [currentUser],
    );

    const [selectedAccount, setSelectedAccount] = React.useState(() => {
        return currentUser.accountType !== AccountType.CLIENT ? null : accountStore.getAccountById(currentUser.accountId);
    });

    const [label, setLabel] = React.useState<string>();
    const [selectedTargetDeviceGroupNames, setSelectedTargetDeviceGroupNames] = React.useState<string[]>([]);
    const [outOfServiceSettings, setOutOfServiceSettings] =
        React.useState<IOutOfServiceSettings>(emptyOutOfServiceSettings);

    React.useEffect(() => {
        deploymentSaveForm.setFieldsValue({ selectAccount: selectedAccount?.id });
        if (!selectedAccount) return;
        onAccountChanged(selectedAccount.id);
    }, [selectedAccount]);

    React.useEffect(() => {
        deploymentSaveForm.setFieldsValue({ deviceGroupsField: selectedTargetDeviceGroupNames ?? [] });
    }, [selectedTargetDeviceGroupNames]);

    React.useEffect(() => {
        deploymentSaveForm.resetFields();
    }, [treeSelectableDataSource]);

    const navigateToList = (): void => {
        props.setNavigateState({ accountId: selectedAccount?.id });
        props.setPageType(PAGE_TYPES.LIST);
    };

    React.useEffect(() => {
        if (!existingGroup) {
            return;
        }
        setLabel(existingGroup.label);
        setSelectedAccount(accountStore.getAccountByUuid(existingGroup.accountUuid));
        setSelectedTargetDeviceGroupNames(existingGroup.targetDeviceGroupNames);
        setOutOfServiceSettings(existingGroup.settings?.outOfService ?? emptyOutOfServiceSettings);
    }, [existingGroup]);

    const selectedTimezones = React.useMemo(
        () =>
            getTimezones(
                selectedTargetDeviceGroupNames,
                (name: string) => deviceGroupStore.getDeviceGroupByName(name)?.deviceGroupTimezone,
            ),
        [selectedTargetDeviceGroupNames],
    );

    const varName = React.useMemo(
        () => {
            const account = accountStore.getVarForAccount(selectedAccount?.id);
            return account?.name ?? '';
        },
        [selectedAccount],
    );

    const OutOfServiceSettingsComponent = observer((): JSX.Element => {
        return (
            <React.Fragment>
                <ManualRangeTimePickerFormItem
                    code='outOfServiceWindowRangeField'
                    label='Out of service window'
                    labelAlign='right'
                    minuteStep={5}
                    format='HH:mm'
                    allowClear={true}
                    showNow={false}
                    startTimeProps={{
                        timeStringValue: outOfServiceSettings.serviceWindowStart,
                        onChange: (_, timeStringValue): void => {
                            outOfServiceSettings.serviceWindowStart = timeStringValue;
                        },
                    }}
                    endTimeProps={{
                        timeStringValue: outOfServiceSettings.serviceWindowEnd,
                        onChange: (_, timeStringValue): void => {
                            outOfServiceSettings.serviceWindowEnd = timeStringValue;
                        },
                    }}
                    disabled={viewOnly}
                />
                <SwitchSelectFormItem
                    className={deploymentGroupCss.group_switch}
                    code='allowSendLogsField'
                    switchSelectProps={{
                        defaultChecked: outOfServiceSettings.allowSendLogs,
                        onChange: (value): void => {
                            outOfServiceSettings.allowSendLogs = value;
                        },
                    }}
                    label='Send logs'
                    labelAlign='right'
                    disabled={viewOnly}
                    containerClassName={deploymentGroupCss.hidden}
                />
            </React.Fragment>
        );
    });

    // eslint-disable-next-line react/display-name
    const FormActionsPanel = React.memo(({ viewOnly, isProcessing }: any) => {
        const cancelButton = (
            <IconTextButton
                key='cancel'
                label='Cancel'
                onClick={(): void => (viewOnly ? navigateToList() : confirmCancel(navigateToList))}
            />
        );
        const submitButton = (
            <IconTextButton
                key='save'
                label='Save'
                type='primary'
                htmlType='submit'
                loading={isProcessing}
                disabled={viewOnly}
            />
        );
        return <div className={deploymentGroupCss.control}>{[cancelButton, submitButton]}</div>;
    });

    const onSave = (): void => {
        const saveGroupInfo: CreateDeploymentGroupDetailsProps = {
            label: label,
            accountUuid: selectedAccount?.uuid,
            deviceGroupIds: selectedTargetDeviceGroupNames
                .map((targetgroupName) => deviceGroupStore.getDeviceGroupByName(targetgroupName)?.id)
                .filter((id) => !!id),
            outOfServiceSettings: {
                ...outOfServiceSettings,
                enableServiceWindow:
                    !!outOfServiceSettings.serviceWindowStart && !!outOfServiceSettings.serviceWindowEnd,
            },
        };

        Object.keys(fieldRules).forEach((key) => {
            const field = key.split('.').reduce((o, i) => o[i], saveGroupInfo);
            if (!field.match(fieldRules[key].rule)) {
                throw new Error(fieldRules[key].errorMessage);
            }
        });

        safeCall(async () => {
            state.actionType === ACTION_TYPES.ADD
                ? await deploymentGroupStore.createDeploymentGroup(selectedAccount.id, saveGroupInfo)
                : await deploymentGroupStore.updateDeploymentGroup(selectedAccount.id, existingGroup.id, saveGroupInfo);
            navigateToList();
        });
    };

    return (
        <React.Fragment>
            {errorMessage ? <DangerAlert message={errorMessage} className={deploymentGroupCss.alert} /> : null}
            <Label
                label={
                    'Set the device groups to target for deployments and set when a device is out of service so that software updates and deployments and be executed on a device.'
                }
            />
            <SpinnerComponent
                child={
                    <BasicForm
                        key='addOrEdit'
                        className={deploymentGroupCss.save_form}
                        form={deploymentSaveForm}
                        cardPros={{
                            bordered: true,
                            title: `${label || ''}`,
                        }}
                        items={[
                            <React.Fragment key={'baseDeploymentGroupComponent'}>
                                <TextFieldItem
                                    code='nameField'
                                    label='Deployment group'
                                    labelAlign='right'
                                    isRequired={true}
                                    initialValue={label}
                                    onChange={(value): void => setLabel(value.target.value)}
                                    disabled={viewOnly}
                                />
                                <OrganisationSelectItem
                                    code='selectAccount'
                                    label='Account'
                                    labelAlign='right'
                                    isRequired={true}
                                    disabled={state.actionType !== ACTION_TYPES.ADD}
                                    hidden={currentUser.accountType == AccountType.CLIENT}
                                    selectProps={{
                                        treeDataSource: selectAccountSource,
                                        value: selectedAccount?.id,
                                        placeholder: 'Select Account',
                                        expandAll: true,
                                        onChange: (value): void => {
                                            setSelectedAccount(accountStore.getAccountById(value));
                                            setSelectedTargetDeviceGroupNames([]);
                                        },
                                        disabled: viewOnly,
                                    }}
                                />
                                <DeviceGroupMultipleSelectItem
                                    code='deviceGroupsField'
                                    label='Device groups'
                                    labelAlign='right'
                                    isRequired={true}
                                    initialValue={selectedTargetDeviceGroupNames}
                                    selectProps={{
                                        dataSource: treeSelectableDataSource,
                                        expandAll: true,
                                        filterTreeNode: (inputValue, item) =>
                                            item.title.toLowerCase().includes(inputValue.toLowerCase()),
                                        onChange: setSelectedTargetDeviceGroupNames,
                                        disabled:
                                            viewOnly ||
                                            (currentUser.accountType == AccountType.DPU && !selectedAccount),
                                    }}
                                />
                                <LabelFieldItem
                                    code='timezonesField'
                                    label='Timezones'
                                    labelAlign='right'
                                    strong={true}
                                    hidden={!selectedTimezones}
                                    initialValue={selectedTimezones}
                                />
                                <LabelFieldItem code='varField' label='VAR' labelAlign='right' initialValue={varName} />
                                <OutOfServiceSettingsComponent key='outOfServiceSettingsComponent' />
                            </React.Fragment>,
                            <FormActionsPanel key='form-actions' viewOnly={viewOnly} isProcessing={loading} />,
                        ]}
                        onSubmit={onSave}
                    />
                }
            />
        </React.Fragment>
    );
});
