import React from 'react';

import { IconTextButton, IIconTextButtonProps, Drawer, BasicForm, useForm, TextFieldItem } from 'ui-lib';

import { ISnapshotProps } from '../../../../dto/software-snapshot-dto';

import drawerCss from './publish-snapshot-drawer.css';
import { confirmCancel } from '../../admin/utils';

export interface IPublishSnapshotDrawerProps {
    code: string;
    title: string;
    actionButton: IIconTextButtonProps;
    validator?: any;
    submitAction: (values, id: number) => any;
    snapshot: ISnapshotProps;
}

export interface IPublishSnapshotComponentProps {
    form: any;
    snapshot: ISnapshotProps;
    publishSnapshotHandler: (values, id: number) => Promise<boolean>;
    onCancel: () => void;
}

const PublishComponent = (props: IPublishSnapshotComponentProps) => {
    const { form } = props;

    React.useEffect(() => {
        form.setFieldsValue({
            snapshotName: props.snapshot.label,
        });
    }, []);

    const submitHandler = async (values) => {
        await props.publishSnapshotHandler(values, props.snapshot.id);
    };

    const onCancel = () => {
        confirmCancel(() => props.onCancel());
    };

    return (
        <React.Fragment>
            <BasicForm
                className={drawerCss.basicForm}
                form={form}
                onSubmit={submitHandler}
                items={[
                    <div key='snapshot-div'>
                        <TextFieldItem
                            code='snapshotName'
                            label='Name'
                            initialValue={props.snapshot.label}
                            isRequired={true}
                            disabled={true}
                        />
                        <div className={drawerCss.actions}>
                            <IconTextButton label='Cancel' onClick={onCancel} />
                            <IconTextButton label='Save' htmlType='submit' type='primary' />
                        </div>
                    </div>,
                ]}
            />
        </React.Fragment>
    );
};

export const PublishSnapshotDrawer = (props: IPublishSnapshotDrawerProps) => {
    const [visible, setVisible] = React.useState(false);
    const [componentForm] = useForm();
    const onShowDrawer = () => setVisible(true);
    const onClose = () => {
        componentForm.resetFields();
        setVisible(false);
    };

    return (
        <Drawer
            className={drawerCss.drawer}
            title={props.title}
            trigger={
                <IconTextButton
                    label={props.actionButton.label}
                    iconName={props.actionButton.iconName}
                    disabled={props.actionButton.disabled}
                    onClick={onShowDrawer}
                    htmlType={props.actionButton.htmlType}
                    type={props.actionButton.type}
                />
            }
            visible={visible}
            onClose={onClose}
            component={
                <PublishComponent
                    form={componentForm}
                    snapshot={props.snapshot}
                    publishSnapshotHandler={props.submitAction}
                    onCancel={onClose}
                />
            }
        />
    );
};
