import * as React from 'react';
import { ITimePickerProps, TimePicker } from './time-picker';
import { Dayjs } from 'dayjs';
import { Label } from '../labels';

interface ITimeOptions {
    timeStringValue?: string;
    onChange?: (value?: Dayjs, timeStringValue?: string) => void;
}

export interface IManualRangeTimePickerProps extends Omit<ITimePickerProps, 'timeStringValue' | 'onChange'> {
    startTimeProps?: ITimeOptions;
    endTimeProps?: ITimeOptions;
}

export const ManualRangeTimePicker = (props: IManualRangeTimePickerProps): JSX.Element => {
    return (
        <React.Fragment>
            <TimePicker
                key={`${props.code}-start-time`}
                className={props.className}
                allowClear={props.allowClear}
                format={props.format}
                minuteStep={props.minuteStep}
                use12Hours={props.use12Hours}
                showNow={props.showNow}
                timeStringValue={props.startTimeProps?.timeStringValue}
                onChange={props.startTimeProps?.onChange}
                disabled={props.disabled}
            />
            <Label label=' ~ ' />
            <TimePicker
                key={`${props.code}-end-time`}
                className={props.className}
                allowClear={props.allowClear}
                format={props.format}
                minuteStep={props.minuteStep}
                use12Hours={props.use12Hours}
                showNow={props.showNow}
                timeStringValue={props.endTimeProps?.timeStringValue}
                onChange={props.endTimeProps?.onChange}
                disabled={props.disabled}
            />
        </React.Fragment>
    );
};
