import React from 'react';
import { orderBy } from 'lodash';
import { AdvanceTable, IAdvanceTableProps, IBasicTableColumn, IconTextButton, IIconTextButtonProps } from 'ui-lib';
import { EXPORT_TYPES, exportData, IExportType, IHeaders } from 'common-utils';

export interface IReportTableProps extends IAdvanceTableProps {
    exportOptions?: {
        overrideExportItem?: {
            disabled?: boolean;
            componentProps?: IIconTextButtonProps;
            filename?: string;
            exportType?: IExportType;
            headers?: IHeaders;
        };
        hideExportItem?: boolean;
    };
}

function applyDefaultSort(columns: IBasicTableColumn[], dataSource: any[]): any[] {
    const defaultSortOrder = columns.reduce((p, c) => {
        if (!c.defaultSortOrder) return p;
        p[c.dataIndex] = c.defaultSortOrder === 'descend' ? 'desc' : 'asc';
        return p;
    }, {});

    const keys = Object.keys(defaultSortOrder);
    if (keys.length == 0) return dataSource;
    return orderBy(dataSource, keys, Object.values(defaultSortOrder));
}

export const ReportTable = (props: IReportTableProps) => {
    const {table, toolbar, exportOptions} = props;

    const [exportDatasource, setExportDatasource] = React.useState([]);

    React.useEffect(() => {
        setExportDatasource(applyDefaultSort(table.columns, table.dataSource));
    }, [table.dataSource]);

    const onTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        if (table.onTableChange) table.onTableChange(pagination, filters, sorter, extra);
        setExportDatasource(extra?.currentDataSource);
    };

    const {overrideExportItem, hideExportItem} = exportOptions || {};

    const onExportClick = () => {
        const headers = table.columns.reduce((p, c) => {
            p[c.dataIndex] = c.title;
            return p;
        }, {});

        exportData(
            overrideExportItem?.filename || props.title.toString(),
            overrideExportItem?.exportType || EXPORT_TYPES.CSV,
            exportDatasource,
            overrideExportItem?.headers || headers,
        );
    };

    const extraItems = hideExportItem
        ? []
        : !overrideExportItem?.componentProps
            ? [
                <IconTextButton
                    key="exportButton"
                    label="Export to CSV"
                    type="primary"
                    onClick={onExportClick}
                    disabled={table.loading || table.dataSource.length == 0}
                />,
            ]
            : [
                <IconTextButton
                    key="exportButton"
                    {...overrideExportItem?.componentProps}
                    onClick={overrideExportItem?.componentProps.onClick || onExportClick}
                    disabled={overrideExportItem?.componentProps.disabled}
                />,
            ];

    return (
        <AdvanceTable
            {...props}
            table={{...table, onTableChange}}
            toolbar={{...toolbar, extraItems: [].concat(toolbar?.extraItems?.slice() || [], extraItems)}}
        />
    );
};
