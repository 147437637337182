import * as React from 'react';
import { inc as increaseVersion } from 'semver';
import { IRadioItem, RadioGroup, Skeleton } from 'ui-lib';

import nextVersionCss from './next-version.css';

export enum VersionLevel {
    MAJOR = 'major',
    MINOR = 'minor',
    PATCH = 'patch',
}

export interface INextVersionProps {
    loading: boolean;
    currentVersion: string;
    onChange: (level: VersionLevel, nextVersion?: string) => void;
}

export function NextVersion(props: INextVersionProps): JSX.Element {
    const [versionLevel, setVersionLevel] = React.useState(VersionLevel.PATCH);
    const [nextVersion, setNextVersion] = React.useState('');

    React.useEffect(() => {
        const nextVer = increaseVersion(props.currentVersion, versionLevel);
        setNextVersion(nextVer);
        props.onChange(versionLevel, nextVersion);
    }, [props.currentVersion, versionLevel]);

    const versionLevelList: IRadioItem[] = [
        { value: VersionLevel.MAJOR, label: 'Major' },
        { value: VersionLevel.MINOR, label: 'Minor' },
        { value: VersionLevel.PATCH, label: 'Patch' },
    ];

    const VersionRow = ({ label, value }): JSX.Element => (
        <div className={nextVersionCss.itemContainer}>
            <label>{label}</label>
            <Skeleton loading={props.loading} noOfRows={1}>
                <p>{value}</p>
            </Skeleton>
        </div>
    );

    return (
        <section className={nextVersionCss.nextVersion}>
            <div className={nextVersionCss.itemContainer}>
                <label>Select level</label>
                <RadioGroup
                    items={versionLevelList}
                    value={versionLevel}
                    onChange={(value: VersionLevel): void => setVersionLevel(value)}
                />
            </div>
            <VersionRow label='Current version' value={props.currentVersion} />
            <VersionRow label='Next version' value={nextVersion} />
        </section>
    );
}
