import { isNullOrEmpty } from 'common-utils';
import { differenceBy } from 'lodash';
import { ConfigurationStore } from 'store/configuration-store';
import { TDisplayPackageProps } from 'store/packages-store';
import { Typography, BasicTable, showInformationDialog } from 'ui-lib';
import { InfoTag } from 'ui-lib/components/data-display/tags';
import * as React from 'react';
import { Modal } from 'antd';
import { getDisplayCRC } from 'utils/common-utils';

const CONFIG_FILE_NAMES = ['scr-ca-keys', 'scr-config'];

export interface IUploadFileCheckingResult {
    validFiles: File[];
    errorFiles: {
        error: string,
        files: File[],
    }[];
}
const fileNameValidator = (fileName: string): boolean => {
    if (!fileName) return false;
    return CONFIG_FILE_NAMES.some(notAllowFileName => fileName.toLowerCase().includes(notAllowFileName));
};

interface IUploadFileCheckingStates {
    validateFiles: (currentFiles: File[], newFiles: File[]) => { validFiles: File[], errorFiles: File[] };
}

export const useUploadFilesCheckingStates = (): IUploadFileCheckingStates => {
    const [invalidFiles, setInvalidFiles] = React.useState<{
        error?: string;
        files?: File[];
    }[]>([]);

    React.useEffect(() => {
        if (isNullOrEmpty(invalidFiles))
            return;
        showInformationDialog({
            title: 'Error',
            content: (
                <div>
                    {invalidFiles.map((invalidItem) => (
                        <div key='invalidItem-div'>
                            <div>{invalidItem.error}</div>
                            <ul>
                                {invalidItem.files?.map((file: File, index: number) => <li key={`${file.name}-${index}`}>{file.name}</li>)}
                            </ul>
                        </div>
                    ))}
                </div>
            ),
            modalType: 'error',
        });
        setInvalidFiles([]);
    }, [invalidFiles]);

    return {
        validateFiles: (currentFiles: File[], newFiles: File[]): { validFiles: File[], errorFiles: File[] } => {
            let validFiles = newFiles;
            const errorFiles = [];
            const invalidFiles = newFiles.filter((file) => fileNameValidator(file.name));
            if (!isNullOrEmpty(invalidFiles)) {
                errorFiles.push({
                    error: 'You cannot upload this file(s).',
                    files: invalidFiles,
                });
                validFiles = differenceBy(newFiles, invalidFiles, 'name');
            }
            const currentFileNames = currentFiles?.map((file) => file.name) || [];
            const duplicateFiles = validFiles.filter((file) => currentFileNames.includes(file.name));
            if (!isNullOrEmpty(duplicateFiles)) {
                errorFiles.push({
                    error: 'The file name{s) are duplicated.',
                    files: duplicateFiles,
                });
                validFiles = differenceBy(validFiles, duplicateFiles, 'name');
            }
            return { validFiles, errorFiles };
        }
    };
};

interface IPackageRowComponentOptions {
    configurationStore: ConfigurationStore;
    pkg: TDisplayPackageProps;
}

export const PackageRowComponent = (props: IPackageRowComponentOptions): JSX.Element => {
    const { configurationStore, pkg } = props;
    const isConfigurationPackage = CONFIG_FILE_NAMES.includes(pkg.label);
    const packageTitle = `${pkg.label}-${pkg.version}`;

    return (
        <Typography.Link
            onClick={(): void => {
                Modal.info({
                    title: packageTitle,
                    width: 1000,
                    visible: true,
                    content: (
                        !isConfigurationPackage ? <PackageDependenciesTable pkg={pkg} />
                            : <ConfigurationTable configurationStore={configurationStore} pkg={pkg} />
                    )
                });
            }}>
            {pkg.label}
        </Typography.Link>
    );
};

const PackageDependenciesTable = ({ pkg }: { pkg: TDisplayPackageProps }): JSX.Element => {
    const dataSource = React.useMemo(() => {
        return pkg.dependencyPackages?.reduce((_, current) => {
            const index = _.findIndex(item => item.label == current.label);
            if (index == -1) {
                _.push({ label: current.label, versions: [current.version] });
            } else {
                const existedItem = _[index];
                _[index] = { label: existedItem.label, versions: [...existedItem.versions, current.version] };
            }
            return _;
        }, []);
    }, [pkg]);

    return (
        <React.Fragment>
            <BasicTable
                columns={[
                    { code: 'label', title: 'Name', dataIndex: 'label' },
                    {
                        code: 'versions',
                        title: 'Version',
                        dataIndex: 'versions',
                        render: (versions: string[]): JSX.Element => {
                            return (
                                <div>
                                    {versions.map(version => <InfoTag key={version} value={version} />)}
                                </div>
                            );
                        }
                    },
                ]}
                dataSource={dataSource}
                extraOptions={{ pagination: { hideOnSinglePage: true } }}
            />
        </React.Fragment>
    );
};

const ConfigurationTable = ({ configurationStore, pkg }: { configurationStore: ConfigurationStore; pkg: TDisplayPackageProps }): JSX.Element => {
    const configuration = configurationStore.findConfiguration(pkg.label, pkg.version);
    return (
        <BasicTable
            columns={[
                { code: 'filename', title: 'File name', dataIndex: 'filename' },
                { code: 'description', title: 'Description', dataIndex: 'description' },
                { code: 'releaseNotes', title: 'Release notes', dataIndex: 'releaseNotes' },
                { code: 'crc', title: 'CRC', dataIndex: 'crc', render: (crcValue: string): string => getDisplayCRC(crcValue) },
            ]}
            dataSource={!configuration?.metadata ? [] : Object.keys(configuration.metadata).map((key) => ({
                filename: key,
                ...configuration.metadata[key],
            }))}
            extraOptions={{ pagination: { hideOnSinglePage: true } }}
        />
    );
};
export const DescriptionTextComponent = ({ description }): JSX.Element => <Typography.Text style={{ whiteSpace: 'break-spaces' }}>{description}</Typography.Text>;