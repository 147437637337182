import * as React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { IBasicTableColumn, IAdvanceTableToolbarProps, TableListingPage, notification } from 'ui-lib';
import { OrganisationSelect } from '../../components/organisation-select';

import { useAccountStore, useRoutesStore, useUserStore } from '../../../../store';
import { deleteUser } from '../utils';
import { buildNavigatePath } from '../../utils';
import { USER_ROUTE_CODES } from './';
import { useSearchableTableStates } from '../../components/table-utils';
import { IDisplayUser } from 'store/user-store';
import { isNullOrEmpty, getErrorMessage } from 'common-utils';
import userCss from './user.css';

export const ManageUsersComponent = observer(() => {
    const accountStore = useAccountStore();
    const userStore = useUserStore();
    const navigate = useNavigate();

    const routeStore = useRoutesStore();
    const addRoute = routeStore.getRoute(USER_ROUTE_CODES.ADD);
    const editRoute = routeStore.getRoute(USER_ROUTE_CODES.EDIT);

    const { onFilter, filteredDataSource } = useSearchableTableStates(userStore.displayUsers, ['name', 'email']);

    const [selectedOrganisationKey, setSelectedOrganisationKey] = React.useState<number>(undefined);

    const filteredUser = React.useMemo<IDisplayUser[]>(() => {
        if (!selectedOrganisationKey) return filteredDataSource;
        return filteredDataSource.filter((user) => {
            if (!user.account) return false;
            return user.account.id === selectedOrganisationKey;
        });
    }, [filteredDataSource, selectedOrganisationKey]);

    const onAdd = () =>
    navigate(addRoute.path.concat(!selectedOrganisationKey ? '' : `?org=${selectedOrganisationKey}`));
    const onSelectedOrganisationChange = (id: number) => setSelectedOrganisationKey(id);
    const onDelete = async (id: string) => {
        try {
            await userStore.deleteUser(id);
        } catch (err) {
            notification.error({
                message: 'Delete user error',
                description: getErrorMessage(err),
            });
        }
    };

    const columns: IBasicTableColumn[] = [
        {
            code: 'name',
            title: 'User',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            actions: [
                {
                    key: 'id',
                    onClick: (id: number) => navigate(buildNavigatePath(editRoute.path, { ':id': `${id}` })),
                },
            ],
        },
        {
            code: 'email',
            title: 'Email',
            dataIndex: 'email',
        },
        {
            code: 'organisation',
            title: 'Organisation',
            dataIndex: 'organisation',
        },
        {
            code: 'userRoles',
            title: 'User role',
            dataIndex: 'userRoles',
        },
        {
            code: 'action',
            title: 'Action',
            dataIndex: 'action',
            actions: [
                {
                    key: 'id',
                    label: 'Delete',
                    onClick: (id: string) => deleteUser(id, onDelete),
                },
            ],
        },
    ];

    return (
        <TableListingPage
            title=''
            tableProps={{
                title: 'All users',
                loadingState: userStore.dataLoading,
                table: {
                    columns: columns,
                    dataSource: filteredUser,
                },
                toolbar: {
                    searchPlaceholder: 'Search users',
                    onSearch: onFilter,
                    onAdd: onAdd,
                    onReload: () => userStore.loadUsers(true),
                    extraItems: [
                        <OrganisationSelect
                            key='organisation'
                            placeholder='Organisation'
                            allowClear={true}
                            expandAll={true}
                            treeDataSource={accountStore.treeDataSource}
                            onChange={onSelectedOrganisationChange}
                            className={userCss.extraSpacing}
                        />,
                    ],
                } as IAdvanceTableToolbarProps,
            }}
        />
    );
});
