import React from 'react';
import { useRoutesStore } from 'store';
import { useNavigate, Link } from 'react-router-dom';
import { buildNavigateToDeviceDetailsPath } from '../utils';
import { IconTextButton } from 'ui-lib/components/buttons/icon-text-button';
import { ButtonType } from 'antd/lib/button';
import { CopyToClipboardButton } from 'ui-lib';

import styles from './component.css';

export interface IDeviceLinkProps {
    [key: string]: any;
}

export interface IDeviceUuidHyperlinkProps {
    uid: string;
    type?: ButtonType;
    label?: string;
    locationState?: IDeviceLinkProps;
    accountId?: number;
}

export const DeviceUuidHyperlink = (props: IDeviceUuidHyperlinkProps): JSX.Element => {
    const routeStore = useRoutesStore();
    const navigate = useNavigate();
    const label = props.label ? props.label : props.uid;
    const navigatePathname = buildNavigateToDeviceDetailsPath(routeStore, props.uid);
    const navigateSearchParameters = props.accountId ? `?accountId=${props.accountId}` : undefined;

    if (!props.type || props.type === 'link') {
        return (
            <div className={styles.deviceUuidHyperlinkBlock}>
                <Link
                    to={{ pathname: navigatePathname, search: navigateSearchParameters }}
                    state={props.locationState}
                >
                    {label}
                </Link>
                <CopyToClipboardButton text={label} />
            </div>
        );
    }

    return (
        <IconTextButton
            type={props.type}
            htmlType='button'
            onClick={(): void =>
                navigate({ pathname: navigatePathname, search: navigateSearchParameters }, {
                    state: props.locationState,
                })
            }
            label={label}
        />
    );
};

