import { Menu } from "antd";
import * as React from "react";
import cx from "classnames";
import { BadgeIcon, Icon } from "../icon";

import horizontalMenuCss from "./horizontal-menu.css";
import { IBaseMenuItemProps, IMenuItem } from "./menu-item";
import { ItemType } from "antd/lib/menu/hooks/useItems";

export interface IHorizontalMenuProps extends IBaseMenuItemProps {
    selectedKeys?: string[];
    className?: string;
}

export function HorizontalMenu(props: IHorizontalMenuProps) {
    const IconMenuItem = (props: { menuItem: IMenuItem }) => {
        const { menuItem } = props;
        const iconSize = menuItem.label ? "SMALL" : "REGULAR";
        return (
          menuItem.badge
            ? <BadgeIcon className={horizontalMenuCss["icon-wrapper"]} iconClassName={horizontalMenuCss["icon-item"]}
                         iconName={menuItem.iconName} size={iconSize} asPrefix badge={{ ...menuItem.badge }} />
            : <Icon className={horizontalMenuCss["icon-wrapper"]} iconClassName={horizontalMenuCss["icon-item"]}
                    iconName={menuItem.iconName} size={iconSize} asPrefix />
        );
    };

    const listOfMenuItems = props.menuItems.map(menuItem => (Array.isArray(menuItem.subMenuItems) ?
        {
            key: menuItem.code,
            label: menuItem.label ? menuItem.label(menuItem) : null,
            className: cx(props.className, horizontalMenuCss.menuItem),
            icon: <IconMenuItem menuItem={menuItem} />,
            onTitleClick: () => menuItem.onClick(menuItem),
            children: menuItem.subMenuItems.map(subMenuItem => ({
                key: subMenuItem.code,
                label: subMenuItem.label ? subMenuItem.label(subMenuItem) : null,
                className: horizontalMenuCss.subMenuItem,
                icon: <Icon iconName={subMenuItem.iconName} size="SMALL" />,
                onClick: () => subMenuItem.onClick(subMenuItem)
            }))
        }
        : {
            key: menuItem.code,
            label: menuItem.label ? menuItem.label(menuItem) : null,
            icon: <IconMenuItem menuItem={menuItem} />,
            onClick: () => menuItem.onClick(menuItem)
        }
    ));

    return (
      <Menu mode="horizontal" className={horizontalMenuCss.horizontalMenu} selectedKeys={props.selectedKeys} items={listOfMenuItems} />
    );
}
