/* eslint-disable no-await-in-loop */
import { ISearchDeviceInfo } from 'dto/device-dto';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { AssureAdminApiClientStore } from './assure-admin-api-client.store';
import { DeviceGroupsStore } from './device-groups.store';
import { IUpdateDeviceResultOptions } from 'dto/device-group-dto';
import { isNullOrEmpty } from 'common-utils';
import { chunk, uniq } from 'lodash';

export interface IDeviceGroupInfoProperties {
    key: string;
    id: number;
    deviceGroupName?: string;
    deviceGroupLabel?: string;
}

export interface IDeviceInfoProperties {
    key: string;
    UID: string;
    esn?: string;
    serialNumber?: string;
    deviceType: string;
    deviceGroupName: string;
}

export interface IDisplayDeviceProperties extends IDeviceInfoProperties {
    deviceGroupLabel: string;
}

export interface IAssignDeviceStoreConstructorOptions {
    apiClientStore: AssureAdminApiClientStore;
    deviceGroupsStore: DeviceGroupsStore;
}

export interface IClientGroupOptions {
    accountId: number;
    deviceGroupName: string;
}

export type DEVICE_GROUP_ACTION = 'ADD' | 'REMOVE' | 'EDIT';
export interface IDeviceGroupTreeActionProps {
    action: DEVICE_GROUP_ACTION;
    name: string;
    label: string;
}

export class AssignDevicesStore {
    private readonly deviceGroupsStore: DeviceGroupsStore;

    constructor(options: IAssignDeviceStoreConstructorOptions) {
        makeObservable(this);

        this.deviceGroupsStore = options.deviceGroupsStore;

        // Rebuild assigned devices when datasource changed
        reaction(
            () => this.deviceDataSource,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (devices) => {
                // Prevent rebuild the assigned devices when having an action is working
                if (this.actionWaiting) return;
                this.clearSelectedDevices();
                this.loadAssignedDevices(this.currentGroupName);
            },
        );
    }

    @observable
    clientAccount: IClientGroupOptions;

    @observable
    actionWaiting: boolean;

    @action
    setActionWaiting(actionWaiting: boolean) {
        this.actionWaiting = actionWaiting;
    }

    @observable
    loading = false;

    @action
    setLoading(loading: boolean) {
        this.loading = loading;
    }

    @action
    setClientAccount(clientAccount: IClientGroupOptions) {
        this.clientAccount = clientAccount;
    }

    @observable
    currentGroupName: string;

    @action
    public setCurrentDeviceGroup(group: string): void {
        this.currentGroupName = group;
        // Update assigned devices when selected device group changed.
        this.clearSelectedDevices();
        this.loadAssignedDevices(group);
    }

    // Available devices of CLIENT
    @observable
    deviceDataSource: IDeviceInfoProperties[] = [];

    @computed
    public get displayDeviceDataSource(): IDisplayDeviceProperties[] {
        return this.deviceDataSource.map((item) => ({
            ...item,
            deviceGroupLabel: item.deviceGroupName == this.clientAccount.deviceGroupName
                ? ''
                : this.deviceGroupsStore.getDeviceGroupByName(item.deviceGroupName)?.deviceGroupLabel || ''
        }));
    }

    @action
    public setDeviceDataSource(dataSource: IDeviceInfoProperties[]): void {
        this.deviceDataSource = dataSource || [];
    }

    // Devices in selected device group
    @observable
    targetDevices: string[] = [];

    @action
    public setTargetDevices(keys: string[]): void {
        this.targetDevices = keys || [];
    }

    // observable for selected available devices
    @observable
    sourceSelectedDevices: string[] = [];

    @action
    public setSourceSelectedDevices(keys: string[]): void {
        this.sourceSelectedDevices = keys || [];
    }

    // observable for selected devices in dg
    @observable
    targetSelectedDevices: string[] = [];

    @observable
    progressPercent: number;

    @action
    updateProgressPercent(percent: number): void {
        this.progressPercent = percent;
    }

    @computed
    get inProgress(): boolean {
        return !!this.progressPercent;
    }

    @computed
    get toClientGroupDevicesList(): IDisplayDeviceProperties[] {
        return this.displayDeviceDataSource
            .filter((item) => item.deviceGroupName == this.currentGroupName && !this.targetDevices.includes(item.key));
    }

    @computed
    get toSelectedGroupDevicesList(): IDisplayDeviceProperties[] {
        return this.displayDeviceDataSource
            .filter((item) => item.deviceGroupName != this.currentGroupName && this.targetDevices.includes(item.key));
    }

    @computed
    get changeGroupDevices(): IDisplayDeviceProperties[] {
        return [...this.toClientGroupDevicesList, ...this.toSelectedGroupDevicesList];
    }

    @action
    public setTargetSelectedDevices(keys: string[]): void {
        this.targetSelectedDevices = keys || [];
    }

    @action
    public addTargetDevices(keys: string[]): void {
        if (isNullOrEmpty(keys)) return;
        this.targetDevices = uniq([...this.targetDevices, ...keys]);
    }

    public clearSelectedDevices(): void {
        this.setSourceSelectedDevices([]);
        this.setTargetSelectedDevices([]);
    }

    private loadAssignedDevices(deviceGroupName: string) {
        if (!deviceGroupName) {
            this.setTargetDevices([]);
            return;
        }
        const targetKeys = this.deviceDataSource
            .filter((item) => item.deviceGroupName == deviceGroupName)
            .map((item) => item.key);
        this.setTargetDevices(targetKeys);
    }

    public initialize(clientAccountId: number, devices: ISearchDeviceInfo[]) {
        this.clearSelectedDevices();
        this.setLoading(true);
        try {
            const clientDeviceGroup = this.deviceGroupsStore.getClientDeviceGroupDetails(clientAccountId);

            this.setClientAccount({ accountId: clientAccountId, deviceGroupName: clientDeviceGroup.deviceGroupName });
            this.setDeviceDataSource(devices.map((device) => ({
                key: device.deviceName,
                UID: device.deviceName,
                esn: device.esn,
                serialNumber: device.serialNumber,
                deviceType: device.deviceTypeName,
                deviceGroupName: device.deviceGroupNames[0],
            })));
        } finally {
            this.setLoading(false);
        }
    }

    private updateGroupNameForDevices(deviceNames: string[], groupName: string): void {
        const devices = this.deviceDataSource.map((device) => {
            if (deviceNames.includes(device.UID)) return { ...device, deviceGroupName: groupName };
            return device;
        });
        this.setDeviceDataSource(devices);
    }

    public async moveDevicesToGroup(accountId: number, groupName: string, sourceGroupName: string, deviceNames: string[]): Promise<IUpdateDeviceResultOptions> {
        if (isNullOrEmpty(deviceNames)) {
            return { succeeded: [], errors: [] };
        }

        try {
            const result = await this.deviceGroupsStore.moveDevicesToGroup(accountId, groupName, sourceGroupName, deviceNames);
            // Update device datasource with new group
            this.updateGroupNameForDevices(result.succeeded, groupName);
            return result;
        } catch (err) {
            return {
                succeeded: [],
                errors: deviceNames.map(item => ({ identifier: item, error: err.message }))
            };
        }
    }

    // force: Delete devices in current group
    public async moveDevicesToClientGroup(sourceGroupName: string, force = false): Promise<IUpdateDeviceResultOptions> {
        const removedDevices: IDeviceInfoProperties[] = this.deviceDataSource
            .filter((item) => item.deviceGroupName == sourceGroupName)
            .filter((item) => (force ? this.targetDevices.includes(item.key) : !this.targetDevices.includes(item.key)));
        return this.moveDevicesToGroup(this.clientAccount.accountId, this.clientAccount.deviceGroupName, sourceGroupName, removedDevices?.map((item) => item.UID));
    }

    // Save the changed devices
    public async updateDeviceGroupForDevices(destGroupName: string = this.currentGroupName): Promise<IUpdateDeviceResultOptions> {
        this.updateProgressPercent(0);
        this.setActionWaiting(true);
        const updatedDevices: IUpdateDeviceResultOptions = { succeeded: [], errors: [] };
        try {
            const allChangeDevices = this.changeGroupDevices;
            const DEVICES_PER_BATCH = 3;
            const numOfDevices = allChangeDevices.length;
            const progressStep = Math.ceil((DEVICES_PER_BATCH * 100) / numOfDevices);
            const deviceBatches = chunk(allChangeDevices, DEVICES_PER_BATCH);
            for (let i = 0; i < deviceBatches.length; i++) {
                const batch = deviceBatches[i];
                const devicesByOriginalGroup = batch.reduce<Map<string, IDisplayDeviceProperties[]>>(
                    (entryMap, item) =>
                        entryMap.set(item.deviceGroupName, [...(entryMap.get(item.deviceGroupName) || []), item]),
                    new Map(),
                );
                const moveToClientGroup = devicesByOriginalGroup.get(destGroupName);
                if (!isNullOrEmpty(moveToClientGroup)) {
                    const moveDevicesResponse = await this.moveDevicesToGroup(this.clientAccount.accountId, this.clientAccount.deviceGroupName, destGroupName, moveToClientGroup.map(item => item.UID));
                    updatedDevices.succeeded.push(...moveDevicesResponse.succeeded);
                    updatedDevices.errors.push(...moveDevicesResponse.errors);
                    devicesByOriginalGroup.delete(this.currentGroupName);
                }

                await Promise.all(Array.from(devicesByOriginalGroup).map(async (item) => {
                    const originalGroupName = item[0];
                    const devices = item[1];
                    const deviceNames = devices?.map((item) => item.UID);
                    if (deviceNames.length > 0) {
                        const moveDevicesResponse = await this.moveDevicesToGroup(this.clientAccount.accountId, destGroupName, originalGroupName, deviceNames);
                        updatedDevices.succeeded.push(...moveDevicesResponse.succeeded);
                        updatedDevices.errors.push(...moveDevicesResponse.errors);
                    }
                }));
                this.updateProgressPercent((i + 1) * progressStep);
            }
            this.updateProgressPercent(100);
            return updatedDevices;
        } finally {
            this.setActionWaiting(false);
        }
    }
}
