import * as React from 'react';
import cx from 'classnames';
import { Select as AntdSelect, Tooltip } from 'antd';

import selectCss from './select.css';
import { TooltipPlacement } from 'antd/lib/tooltip';

export interface ISelectProps {
    code?: string,
    className?: string,
    mode?: 'multiple',
    disabled?: boolean;
    allowClear?: boolean;
    placeholder?: string;
    showSearch?: boolean;
    labelInValue?: boolean;
    selectedValue?: { value: string, label: string }[] | string | number;
    value?: string | number;
    dataSource: {
        value: number | string;
        label: string;
        key?: string | number;
        tooltip?: string;
        disabled?: boolean;
        placement?: TooltipPlacement;
    }[];
    onChange?: (value, option) => void;
    filterOption?: (inputValue, option) => boolean;
}

export const Select = (props: ISelectProps) => {
    return (
        <AntdSelect className={cx(selectCss.selection, props.className)}
                    mode={props.mode}
                    disabled={props.disabled}
                    allowClear={props.allowClear}
                    placeholder={props.placeholder}
                    defaultValue={props.selectedValue}
                    value={props.value}
                    showSearch={props.showSearch}
                    labelInValue={props.labelInValue}
                    onChange={props.onChange}
                    filterOption={props.filterOption}>
            {props.dataSource.map(item => <AntdSelect.Option key={item.key ? item.key : item.value} value={item.value} disabled= {item.disabled}>
                {!item.tooltip ? item.label : <Tooltip title={item.tooltip} placement={item.placement || 'top'}>
                    <div>{item.label}</div>
                </Tooltip>}
            </AntdSelect.Option>)}
        </AntdSelect>
    );
};

export const MultipleSelect = (props: ISelectProps) => {

    return (
        <AntdSelect className={cx(selectCss.multipleSelection, props.className)}
                    mode="multiple"
                    disabled={props.disabled}
                    allowClear={props.allowClear}
                    placeholder={props.placeholder}
                    showSearch={props.showSearch}
                    defaultValue={props.selectedValue}
                    filterOption={props.filterOption}
                    onChange={props.onChange}>
            {props.dataSource.map(item => <AntdSelect.Option key={item.key ? item.key : item.value}
                                                             value={item.value}>{item.label}</AntdSelect.Option>)}
        </AntdSelect>
    );
};
