import * as React from 'react';
import cx from 'classnames';
import { List as AntdList, Divider, Typography } from 'antd';
import verticalListCss from './vertical-list.css';
import { IListItem } from './list-item';
import { Icon } from '../icon';

export interface IVerticalListProps {
    listItems: IListItem[];
    noData?: React.ReactNode;
    className?: string;
    title?: string;
}

export function VerticalList(props: IVerticalListProps) {
    const { listItems } = props;

    return (
        <div className={verticalListCss.wrapper}>
            {props.title ? (
                <Typography.Title level={5} className={verticalListCss.title}>
                    {props.title}
                </Typography.Title>
            ) : null}
            {props.title ? <Divider className={verticalListCss.divider} /> : null}
            <AntdList
                className={cx(verticalListCss.verticalList, props.className)}
                itemLayout='vertical'
                bordered
                dataSource={listItems}
                locale={{
                    emptyText: props.noData ? (
                        <div className={verticalListCss.noDataWrapper}>{props.noData}</div>
                    ) : undefined,
                }}
                renderItem={(listItem) => (
                    <AntdList.Item key={listItem.key}>
                        <div className={verticalListCss.verticalListItem}>
                            <div className={verticalListCss.verticalListItemTitle}>
                                <Typography.Title level={5}>{listItem.title}</Typography.Title>
                            </div>
                            <div className={verticalListCss.verticalListItemBody}>
                                {listItem.iconName ? (
                                    <div className={verticalListCss.verticalListItemAvatar}>
                                        <Icon iconName={listItem.iconName} />
                                    </div>
                                ) : null}
                                {listItem.content ? (
                                    <div className={verticalListCss.verticalListItemContent}>{listItem.content}</div>
                                ) : null}
                                {listItem.secondaryContent ? (
                                    <div className={verticalListCss.secondaryContent}>{listItem.secondaryContent}</div>
                                ) : null}
                            </div>
                        </div>
                    </AntdList.Item>
                )}
            />
        </div>
    );
}
