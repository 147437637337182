import { ConfigurationApiClient } from 'api';
import { IAuthUser } from 'core';
import { computed, makeObservable, reaction } from 'mobx';

import { AccountType } from 'dto/access-management/account-dto';
import { CONFIGURATION_TYPES, IConfiguration, IGetConfigurationParams, IGetLatestVersionFile, ISignAndCreateConfigurationFile, ISignAndCreateConfigurationFileResult, IVersionResponse } from 'dto/configuration-dto';

import { AssureBaseStore, IAssureStoreConstructorOptions } from './assure-base.store';
import { PackagesStore } from './packages-store';
import { IPackageProps } from 'dto/packages-dto';

export interface IConfigurationStoreConstructorOptionps extends IAssureStoreConstructorOptions {
    packagesStore: PackagesStore;
}
export class ConfigurationStore extends AssureBaseStore<ConfigurationApiClient, IConfiguration> {

    private readonly _packagesStore: PackagesStore;
    public constructor(options: IConfigurationStoreConstructorOptionps) {
        super(options);
        makeObservable(this);
        this._packagesStore = options.packagesStore;
        reaction(
            () => options.authStore.currentUser,
            async (currentUser: IAuthUser) => {
                if (!currentUser) return;
                const params = this._getConfigurationParams(currentUser);
                this.setEntities((await this.apiClient.getListConfigurations(params.accountId, params)).configurations);
            }
        );
    }

    private _getConfigurationParams(currentUser: IAuthUser): IGetConfigurationParams {
        switch (currentUser?.accountType) {
            case AccountType.DPU:
                return {
                    type: CONFIGURATION_TYPES.GLOBAL,
                    accountId: currentUser.managedAccounts.find((account) => account.accountType == AccountType.VAR)?.id,
                };
            case AccountType.VAR:
                return {
                    type: CONFIGURATION_TYPES.VAR,
                    accountId: currentUser.accountId,
                };
            case AccountType.CLIENT: {
                return {
                    accountId: currentUser.accountId,
                    type: CONFIGURATION_TYPES.CLIENT,
                    clientAccountUuid: currentUser?.accountUuid,
                };
            }
        }
    }

    protected get apiClient(): ConfigurationApiClient {
        return this.apiClientStore.apiClients.configuration;
    }

    @computed
    public get getConfigurations(): IConfiguration[] {
        if (!this.hasEntities()) return [];
        return this.entities;
    }

    public findConfiguration(label: string, version: string): IConfiguration {
        return !this.hasEntities() ? null : this.entities.find((item) => item.name == `${label}-${version}.deb` && item.version == version);
    }

    public getLatestVersion(accountId: number, params: IGetLatestVersionFile): Promise<IVersionResponse> {
        return this.apiClient.getLatestVersion(accountId, params);
    }

    public async signAndCreateConfigurationFile(
        accountId: number,
        params: ISignAndCreateConfigurationFile,
    ): Promise<ISignAndCreateConfigurationFileResult> {
        const result = await this.apiClient.signAndCreateConfigurationFile(accountId, params);

        this.addEntity(result.configuration);
        this._packagesStore.addNewPackages(result.package);

        return {
            message: result.message,
            package: result.package,
            debianFile: {
                name: result.configuration.name,
                version: result.configuration.version,
            }
        };
    }
}
