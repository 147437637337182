/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import * as React from 'react';
import { Upload, message } from 'antd';
import { IconTextButton } from '../buttons';
import { Icon } from '../icon';
import uploadCss from './file-manual-upload.css';

const { Dragger } = Upload;

export interface IManualFileUploadProps<T> {
    onUploadFiles: (options: { files: any[] }) => Promise<T>;
    onSuccessOrError?: (uploadResponse: T) => void;
    uploadLabel?: string;
    uploadingLabel?: string;
    limitFileSize?: number; // Megabytes
    beforeUploadChecking?: (currentFiles: File[], newFiles: File[]) => File[]; // return valid files
    label?: string;
    acceptFormats?: string;
}

const LIMIT_SIZE_WARNING = (megabytes: number) => `Maximum upload limit is ${megabytes}MB`;
const LIMIT_SIZE_ERROR = (megabytes: number) => `The maximum upload limit is ${megabytes}MB`;
const convertMBtoBytes = (megabytes: number) => {
    return megabytes * 1024 * 1024;
};

export const ManualFileUpload = <T extends unknown>(config: IManualFileUploadProps<T>) => {
    const limitFileSizeInBytes = config.limitFileSize ? convertMBtoBytes(config.limitFileSize) : undefined;
    const [fileList, setFileList] = React.useState([]);
    const [uploading, setUploading] = React.useState(false);
    const [hasOverLimitFileSize, setHasOverLimitFileSize] = React.useState<boolean>(false);

    const handleUpload = async () => {
        setUploading(true);
        let result: T;
        try {
            result = await config.onUploadFiles({ files: fileList });
            updateFileList([]);
        } catch (error) {
            message.error('upload failed.');
        } finally {
            setUploading(false);
            if (config.onSuccessOrError) config.onSuccessOrError(result);
        }
    };

    const updateFileList = (files: File[]) => {
        const checkedFiles = files?.map((file) => {
            if (limitFileSizeInBytes && file.size > limitFileSizeInBytes)
                return Object.assign(file, { status: 'error', response: LIMIT_SIZE_ERROR(config.limitFileSize) });
            return Object.assign(file, { status: undefined, response: undefined });
        });
        setHasOverLimitFileSize(checkedFiles.find((file) => file.status == 'error') != undefined);
        setFileList(checkedFiles);
    };

    const beforeUpload = (_file, _fileList): boolean => {
        if (config.beforeUploadChecking) {
            _fileList = config.beforeUploadChecking(fileList, _fileList);
        }

        updateFileList([...fileList, ..._fileList]);
        return false;
    };

    const onRemove = (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);

        updateFileList(newFileList);
        return false;
    };

    const uploadProps = {
        onRemove: (file) => onRemove(file),
        beforeUpload: (file, fileList) => beforeUpload(file, fileList),
        fileList,
        multiple: true,
        accept: config.acceptFormats,
    };

    const draggerProps = {
        beforeUpload: (file, fileList) => beforeUpload(file, fileList),
        fileList,
        showUploadList: false,
        multiple: true,
        accept: config.acceptFormats,
    };

    const uploadButtonLabel = config.uploadLabel || 'Start upload';
    const uploadingButtonLabel = config.uploadingLabel || 'Uploading';

    return (
        <div className={uploadCss.uploadBox}>
            <Dragger {...draggerProps} className={uploadCss.dragger}>
                <Icon iconName={'inbox'} size='LARGE' />
                <p className='ant-upload-text'> Click or drag file to this area to upload </p>
                <p className='ant-upload-hint'> Support for a single or bulk upload. </p>
                {limitFileSizeInBytes && (
                    <p className='ant-upload-hint' key='limitWarning'>
                        <i>{LIMIT_SIZE_WARNING(config.limitFileSize)}</i>
                    </p>
                )}
            </Dragger>
            <Upload {...uploadProps} className={uploadCss.spacer}>
                <IconTextButton iconName={'upload'} label={config.label || 'Select files'} />
            </Upload>
            <div className={uploadCss.uploadButton}>
                <IconTextButton
                    type='primary'
                    onClick={handleUpload}
                    disabled={fileList.length === 0 || hasOverLimitFileSize}
                    loading={uploading}
                    label={uploading ? uploadingButtonLabel : uploadButtonLabel}
                />
            </div>
        </div>
    );
};
