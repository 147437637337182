import * as React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

export interface ILabelProps {
    code?: string;
    strong?: boolean;
    underline?: boolean;
    label: string;
    className?: string;
}

export const Label = (props: ILabelProps) => {
    return (
        <Text key={props.code} className={props.className} strong={props.strong} underline={props.underline}>
            {props.label}
        </Text>
    );
};

export { Typography };
