import * as React from 'react';
import { Popover as AntdPopover } from 'antd';

export type TPopoverTrigger = 'click' | 'focus' | 'hover'

export interface IPopoverProps {
    code?: string;
    title?: string;
    content?: React.ReactNode;
    trigger?: TPopoverTrigger | string;
    visible?: boolean;
    onVisibleChange?: (visible: boolean) => void;
    afterVisibleChange?: (visible: boolean) => void;
    children: React.ReactNode;
}

export const Popover = (props: IPopoverProps) => {
    return <AntdPopover {...props} />;
};
