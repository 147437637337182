import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import { PermissionPage, Error404 } from 'ui-lib';

import { useRoutesStore, useStores, useUserStore } from '../../../../store';
import { ManageUsersComponent } from './list';
import { AddUserComponent, EditUserComponent } from './save';

import userCss from './user.css';

export enum USER_ROUTE_CODES {
    ROOT = 'ADMIN_USERS',
    ADD = 'ADMIN_USERS_ADD',
    EDIT = 'ADMIN_USERS_EDIT',
}

export function ManageUserPages() {
    const routeStore = useRoutesStore();
    const userStore = useUserStore();
    const { permissionStore } = useStores();

    const rootRoute = routeStore.getRoute(USER_ROUTE_CODES.ROOT);
    const addRoute = routeStore.getRoute(USER_ROUTE_CODES.ADD);
    const editRoute = routeStore.getRoute(USER_ROUTE_CODES.EDIT);

    React.useEffect(() => {
        userStore.loadUsers();
    }, []);

    return (
        <Routes>
            <Route
                path='/'
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={`Manage ${rootRoute.label}`}
                    >
                        <ManageUsersComponent />
                    </PermissionPage>
                }
            />
            <Route
                path={addRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={addRoute.label}
                        className={userCss.save}
                    >
                        <AddUserComponent />
                    </PermissionPage>
                }
            />
            <Route
                path={editRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={editRoute.label}
                        className={userCss.save}
                    >
                        <EditUserComponent />
                    </PermissionPage>
                }
            />
            <Route path='*' element={<Error404 message='Page Not Found' />} />
        </Routes>
    );
}
