import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Error404, Error401 } from 'ui-lib';

export function UserNotFoundWrapper() {
    const locationState = useLocation().state as any;
    return <Error404 message={locationState.message} />;
}

export const UserNotHaveAccessWrapper = observer(() => {
    const locationState = useLocation().state as any;
    return <Error401 message={locationState.message} />;
});
