import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Spinner, BasicForm, DangerAlert, IconTextButton, OnButtonClick, WarningAlert } from 'ui-lib';

import { IconTextButtonFormItem } from '../../components/form-utils';
import { useRoutesStore } from '../../../../store';
import { REPORT_ROUTE_CODES } from './';

import reportsCss from './reports.css';

export interface IReportComponentProps {
    code: string;
    form?: any; // FormInstance antd
    className?: string;
    isLoading: boolean;
    errorMessage?: string;
    warningMessage?: string;
    items: React.ReactNode[];
    generateReportOptions: {
        isGenerating?: boolean;
        onGenerateReport: OnButtonClick;
    };
    reportTable: React.ReactNode;
}

export const ReportComponent = (props: IReportComponentProps) => {
    const navigate = useNavigate();
    const routeStore = useRoutesStore();

    const generateReportButton = (
        <IconTextButtonFormItem
            code='generateReportButton'
            label=' '
            buttonProps={{
                label: 'Generate Report',
                type: 'primary',
                htmlType: 'submit',
                loading: props.generateReportOptions.isGenerating,
            }}
        />
    );

    return (
        <div className={props.className}>
            {props.errorMessage ? <DangerAlert message={props.errorMessage} className={reportsCss.alert} /> : null}
            {props.warningMessage ? <WarningAlert message={props.warningMessage} className={reportsCss.alert} /> : null}
            <Spinner className={reportsCss.spinner} spinning={props.isLoading}>
                <BasicForm
                    key={props.code}
                    form={props.form}
                    className={reportsCss.form}
                    cardPros={{ bordered: false }}
                    items={[...props.items, generateReportButton]}
                    onSubmit={props.generateReportOptions.onGenerateReport}
                />
            </Spinner>
            {props.reportTable && props.reportTable}
            <IconTextButton
                label='Back to Report Module'
                type='primary'
                onClick={() => navigate(routeStore.getRoute(REPORT_ROUTE_CODES.ROOT).path)}
            />
        </div>
    );
};
