/* eslint-disable react/jsx-key */
import React from 'react';
import { useStores } from '../../../../store';
import { AccountType } from '../../../../dto/access-management/account-dto';
import {
    DangerAlert,
    BasicForm,
    SelectFormItem,
    RowLayout,
    IconTextButton,
    Icon,
    Spinner,
} from 'ui-lib';
import { getErrorMessage } from 'common-utils';
import { NextVersion, VersionLevel, VersionDescription } from '../../components/next-version';

import {
    CONFIGURATION_TYPES,
    IGetConfigurationParams,
    ISignAndCreateConfigurationFileResult
} from 'dto/configuration-dto';
import { TConfigurationSigningFile } from '.';

import configurationCss from './configuration.css';

export interface IConfigurationSigningProps {
    onBackClick: () => void;
    files: TConfigurationSigningFile[];
    setSignedFileResult: (info: ISignAndCreateConfigurationFileResult) => void;
}

const CONFIGURATION_TYPES_SOURCE = [
    {
        value: CONFIGURATION_TYPES.GLOBAL,
        label: CONFIGURATION_TYPES.GLOBAL,
    },
    {
        value: CONFIGURATION_TYPES.VAR,
        label: CONFIGURATION_TYPES.VAR,
    },
];

export const ConfigurationSigning = (props: IConfigurationSigningProps): JSX.Element => {
    const { authStore, commonStore, accountStore, configurationStore } = useStores();
    const currentUser = authStore.currentUser;

    const [isSigning, setIsSigning] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [selectedConfigurationType, setSelectedConfigurationType] = React.useState(() => {
        if (currentUser.accountType == AccountType.DPU)
            return CONFIGURATION_TYPES.GLOBAL;
        if (currentUser.accountType == AccountType.VAR)
            return CONFIGURATION_TYPES.VAR;
        if (currentUser.accountType == AccountType.CLIENT)
            return CONFIGURATION_TYPES.CLIENT;
        return null;
    });
    const [selectedVAR, setSelectedVAR] = React.useState<number>();

    const [versionLevel, setVersionLevel] = React.useState<VersionLevel>();
    const [currentVersion, setCurrentVersion] = React.useState({ isLoading: true, value: '' });

    const getConfigurationParams = (): IGetConfigurationParams => {
        if (selectedConfigurationType === CONFIGURATION_TYPES.GLOBAL)
            return {
                accountId: commonStore.selectedOrganisation || authStore.assureApiAccount.accountId,
                type: CONFIGURATION_TYPES.GLOBAL,
            };

        switch (currentUser.accountType) {
            case AccountType.DPU:
                return {
                    accountId: selectedVAR,
                    type: selectedConfigurationType,
                };
            case AccountType.VAR:
                return {
                    accountId: currentUser.accountId,
                    type: CONFIGURATION_TYPES.VAR,
                };
            case AccountType.CLIENT: {
                const clientAccount = accountStore.getAccountById(currentUser.accountId);
                return {
                    accountId: clientAccount?.parent?.id,
                    type: CONFIGURATION_TYPES.CLIENT,
                    clientAccountUuid: clientAccount?.uuid,
                };
            }
        }

        return null;
    };

    React.useEffect(() => {
        (async (): Promise<void> => {
            setErrorMessage(null);
            setCurrentVersion({ isLoading: true, value: '' });
            const { accountId, ...configurationParams } = getConfigurationParams();
            const resp = await configurationStore.getLatestVersion(accountId, configurationParams);
            setCurrentVersion({ isLoading: false, value: resp.version });
        })();
    }, [selectedConfigurationType, selectedVAR]);

    const handleUpload = async (): Promise<void> => {
        setErrorMessage(null);
        setIsSigning(true);
        try {
            const { accountId, ...configurationParams } = getConfigurationParams();
            if (!accountId) return setErrorMessage('The VAR is not selected.');

            const result = await configurationStore.signAndCreateConfigurationFile(accountId, {
                ...configurationParams,
                nextVersionLevel: versionLevel,
                files: props.files,
                metadata: JSON.stringify(props.files.reduce((previous, currentFile: TConfigurationSigningFile) => {
                    previous[currentFile.name] = {
                        description: currentFile.description,
                        releaseNotes: currentFile.releaseNotes,
                    };
                    return previous;
                }, {})),
            });

            props.setSignedFileResult(result);
        } catch (err) {
            setErrorMessage(getErrorMessage(err));
        } finally {
            setIsSigning(false);
        }
    };

    return (
        <div className={configurationCss.formSigning}>
            {errorMessage && (
                <DangerAlert className={configurationCss.uploadAlert} closable={true} message={errorMessage} />
            )}

            {currentUser.accountType === AccountType.DPU ? (
                <BasicForm
                    cardPros={{ bordered: false }}
                    items={[
                        <SelectFormItem
                            labelColSpan={24}
                            code='configurationType'
                            label='Type'
                            isRequired={true}
                            dataSource={CONFIGURATION_TYPES_SOURCE}
                            selectedValue={selectedConfigurationType}
                            onChange={setSelectedConfigurationType}
                        />,
                        selectedConfigurationType === 'VAR' && (
                            <SelectFormItem
                                labelColSpan={24}
                                code='varType'
                                label='Select VAR'
                                isRequired={true}
                                dataSource={accountStore.getVARAccountDataSource(currentUser.accountType)}
                                selectedValue={selectedVAR}
                                onChange={setSelectedVAR}
                            />
                        ),
                    ]}
                />
            ) : null}

            <RowLayout
                childItems={[
                    <Spinner spinning={isSigning} className={configurationCss.version}>
                        <NextVersion
                            loading={currentVersion.isLoading}
                            currentVersion={currentVersion.value}
                            onChange={setVersionLevel}
                        />
                        <div className={configurationCss.files}>
                            {props.files.map((file: TConfigurationSigningFile) => (
                                <div className={configurationCss.item} key={file.name}>
                                    <Icon className={configurationCss.icon} iconPrefix='fas' iconName='paperclip' size='SMALL' />
                                    <div>{file.name}</div>
                                </div>
                            ))}
                        </div>
                        <div className={configurationCss.control}>
                            <IconTextButton
                                label='Back'
                                onClick={props.onBackClick}
                            />
                            <IconTextButton
                                className={configurationCss.signFiles}
                                label='Sign files'
                                type='primary'
                                onClick={handleUpload}
                                disabled={currentVersion.isLoading}
                            />
                        </div>
                    </Spinner>,
                    <VersionDescription />
                ]}
                childCols={[9, 14]}
                childOffsets={[0, 1]}
            />
        </div>
    );
};
