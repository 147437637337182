import * as React from 'react';
import { Select } from '../../select/select';

import { FormItem, getDefaultFormItemOptions, IFormItem } from './form-item';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { RowLayout } from 'ui-lib/components/grid';

export const toSelectItemFn = (valueKey: string | number, labelKey: string) => {
    return (item: any): IItemProps => ({ value: item[valueKey], label: item[labelKey] });
};

export interface IItemProps {
    value: number | string;
    label: string;
    tooltip?: string;
    disabled?: boolean;
    placement?: TooltipPlacement;
}

export interface ISelectItemProps extends IFormItem {
    placeholder?: string;
    dataSource: IItemProps[];
    selectedValue?: number | string;
    labelInValue?: boolean;
    onChange?: (value, option) => void;
    filterOption?: (inputValue, option) => boolean;
    allowClear?: boolean;
    extraComponent?: React.ReactNode;
}

export const SelectFormItem = (props: ISelectItemProps) => {
    const formItemProps = getDefaultFormItemOptions(props);
    formItemProps.initialValue = props.selectedValue;
    const { ...selectProps } = props;
    return (
        <FormItem
            code={props.code}
            {...formItemProps}
            child={
                !props.extraComponent ? (
                    <Select {...selectProps} />
                ) : (
                    <RowLayout
                        style={{marginBottom: '0px'}}
                        childItems={[<Select key={'main-component'} {...selectProps} />, props.extraComponent]}
                        childCols={[12, 12]}
                    />
                )
            }
        />
    );
};
