import { Switch, SwitchProps } from 'antd';
import * as React from 'react';

export interface ISwitchSelectProps extends SwitchProps {
}

export function SwitchSelect(props: ISwitchSelectProps): JSX.Element {

    return (
        <Switch {...props} />
    );
}
