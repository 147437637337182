import {Layout as AntdLayout} from 'antd';
import * as React from 'react';
import cx from 'classnames';

import layoutCss from './layout.css';


export interface ILayoutProps {
    className?: string;
    children?: React.ReactNode;
}

export const Layout = (props) => {
    return (
        <AntdLayout className={cx(layoutCss.wrapper, props.className)}>
            {props.children}
        </AntdLayout>
    );
};