import { IApiClientOptions } from 'core';
import {
    DeploymentStatus,
    ICreatedDeploymentOptions,
    IDeploymentOptions,
    IDeploymentProgressDetails,
    ISaveDeploymentProps,
} from '../dto/deployment/deployment-dto';
import { AssureBaseApiClient, HTTP_METHOD } from '../api/assure-base-api-client';
import { ITargetOptions } from '../dto/deployment/target-dto';

export interface IDeploymentQuery {
    id?: number;
    units?: string; // '0' or '1'
    targets?: string; // '0' or '1'
}

export class DeploymentApiClient extends AssureBaseApiClient {
    constructor(options: IApiClientOptions) {
        super('deployment', options);
    }

    protected getRootPath(): string {
        return 'deployments';
    }

    public async createDeployment(
        accountId: number,
        deployment: ISaveDeploymentProps,
    ): Promise<ICreatedDeploymentOptions> {
        const result = await this.send<{ deployment: ICreatedDeploymentOptions }>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, '', deployment),
        );
        return result.deployment;
    }

    public async deleteDeploymentById(accountId: number, deploymentId: number): Promise<boolean> {
        const result = await this.send<{ deletedResult: true }>(
            HTTP_METHOD.DELETE,
            this.getRequestOptionsWithAccountId(accountId, `${deploymentId}`),
        );
        return result.deletedResult;
    }

    public async cancelExecutionSchedule(accountId: number, deploymentId: number): Promise<DeploymentStatus> {
        const result = await this.send<{ deploymentStatus: DeploymentStatus }>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, `${deploymentId}/execution/cancel`),
        );
        return result.deploymentStatus;
    }

    public async getExecutionStatus(accountId: number, deploymentId: number): Promise<DeploymentStatus> {
        const result = await this.send<{ deploymentStatus: DeploymentStatus }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deploymentId}/execution`),
        );
        return result.deploymentStatus;
    }

    public async getDeployments(
        accountId: number,
        groupId: number,
        params?: { units: string; targets: string },
    ): Promise<IDeploymentOptions[]> {
        const response = await this.send<{ deployments: IDeploymentOptions[] }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, 'list', { ...params, groupId }),
        );
        return response.deployments;
    }

    public async getDeploymentById(accountId: number, params?: IDeploymentQuery): Promise<IDeploymentOptions> {
        const result = await this.send<{ deployment: IDeploymentOptions }>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, '', params),
        );
        return result.deployment;
    }

    public async getDeploymentProgressDetails(accountId: number, deploymentId: number, params?: IDeploymentQuery): Promise<IDeploymentProgressDetails> {
        const result = await this.send<{deployment: IDeploymentProgressDetails}>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deploymentId}/progress-details`, params),
        );
        return result.deployment;
    }

    public async getDeploymentDetails(accountId: number, deploymentId: number): Promise<any> {
        const result = await this.send<{deployment: any}>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${deploymentId}/details`),
        );
        return result.deployment;
    }
}
