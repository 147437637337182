import { computed } from 'mobx';
import { PackageGroupsApiClient } from '../api';
import { IPackageGroupProps } from '../dto/package-groups-dto';
import { IAssureStoreConstructorOptions } from './assure-base.store';
import { AccountStore } from './account-store';
import { SoftwareManagementStore } from './software-management-store';

export type TDisplayPackageGroupProps = Omit<IPackageGroupProps, 'uuid'> & {
    value: number;
    key: number;
    accountName: string;
};

export class PackageGroupsStore extends SoftwareManagementStore<PackageGroupsApiClient, IPackageGroupProps> {
    public constructor(options: IAssureStoreConstructorOptions, accountStore: AccountStore) {
        super(options, accountStore);
    }

    protected get apiClient(): PackageGroupsApiClient {
        return this.apiClientStore.apiClients.packageGroups;
    }

    public async createGroup(accountId: number, groupLabel: string, type: string): Promise<IPackageGroupProps> {
        const result = await this.apiClient.createGroup(accountId, groupLabel, type);
        this.addEntity(result);
        return result;
    }

    public async updateGroup(accountId: number, packageGroupId: number, groupLabel: string): Promise<boolean> {
        await this.apiClient.updateGroup(accountId, packageGroupId, groupLabel);
        this.updateEntity<Pick<IPackageGroupProps, 'label'>>(packageGroupId, { label: groupLabel });
        return true;
    }

    protected getDataByAccountId(accountId: number): Promise<IPackageGroupProps[]> {
        return this.apiClient.getAllPackageGroupsByAccountId(accountId);
    }

    async delete(accountId: number, packageGroupId: number): Promise<boolean> {
        await this.apiClient.deletePackageGroup(accountId, packageGroupId);
        this.removeEntity(packageGroupId);
        return true;
    }

    @computed
    get displayPackageGroups(): TDisplayPackageGroupProps[] {
        if (!this.hasEntities()) return [];
        return this.entities
            .map((item) => ({
                id: item.id,
                value: item.id,
                label: item.label,
                type: item.type,
                accountName: !item.accountUuid ? '' : this.getAccountNameByUuid(item.accountUuid),
                accountUuid: item.accountUuid,
                key: item.id,
            }))
            .filter((item) => !!item)
            .sort((a: TDisplayPackageGroupProps, b: TDisplayPackageGroupProps) => b.id - a.id);
    }
}
