/* eslint-disable react/jsx-key */
import * as React from 'react';
import { observer } from 'mobx-react';
import { BasicCard, BasicForm, DateTimePickerFormItem, IconTextButton, notification, SelectFormItem, TextAreaFieldItem, TextFieldItem, useForm } from 'ui-lib';
import { IContentData, IUpdateNotificationRequestProps, MESSAGE_TYPE, NOTIFICATION_TYPE, RELEASE_NOTIFICATION_TYPE } from '../../../dto/notifications-dto';
import { getErrorMessage } from 'common-utils';
import { useAuthStore, useNotificationsStore } from 'store';
import { useSpinnerComponent } from '../components/loading-component';
import notificationCss from './notification.css';
import { useAccountTreeSelectDataSource } from '../admin/accounts';
import { AccountType } from 'dto/access-management/account-dto';
import { OrganisationSelectItem } from '../components/organisation-select';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DATE_TIME_FORMAT, formatShortDate } from 'common-utils/date-utils';

dayjs.extend(utc);
interface ISaveNotificationOptions {
    title?: string;
    text?: string;
    dateToDisplay?: number;
    releaseDate?: number;
    releaseType?: string;
    releaseNotes?: string;
    clientId?: number;
    onSave: (values) => Promise<any>;
    onClose: () => void;
}

const releaseTypeDataSource = Object.values(RELEASE_NOTIFICATION_TYPE).map(item => ({ value: item, label: item }));

const buildNofiticationContent = (values: any): Omit<IUpdateNotificationRequestProps, 'messageType'> => {
    const data: IContentData = {
        releaseType: values.releaseType,
        releaseDate: dayjs(values.releaseDate) as any,
        releaseNotes: values.releaseNotes,
    };
    if (data.releaseType == RELEASE_NOTIFICATION_TYPE.CONFIGURATION) {
        data.clientId = values.clientId;
    }

    return {
        title: trimText(values.title),
        text: trimText(values.message),
        data: data,
        dateToDisplay: dayjs(values.dateToDisplay) as any,
    };
};

const trimText = (text: string) => text?.trim();

export const SaveNotification = (props: ISaveNotificationOptions) => {
    const { title, text: message, clientId, releaseNotes, dateToDisplay, releaseDate, releaseType } = props;
    const { SpinnerComponent: SpiningComponent, ...spinningStates } = useSpinnerComponent();
    const [form] = useForm();
    const selectAccountSource = useAccountTreeSelectDataSource({ selectableTypes: [AccountType.CLIENT] });
    const [selectedReleaseType, setSelectedReleaseType] = React.useState<string>(releaseType);
    return (
        <SpiningComponent
            child={
                <BasicForm
                    form={form}
                    items={[
                        <TextFieldItem
                            code='title'
                            isRequired={true}
                            label='Title'
                            labelAlign='right'
                            initialValue={title}
                        />,
                        <DateTimePickerFormItem
                            code='dateToDisplay'
                            isRequired={true}
                            label='Date to display'
                            labelAlign='right'
                            dateFormat={DATE_TIME_FORMAT.DATE_ONLY}
                            showTime={true}
                            validator={(_, value) => {
                                const dateToDisplay = dayjs(value);
                                const releaseDateString = formatShortDate(form.getFieldValue('releaseDate'));
                                if (releaseDateString && dateToDisplay.isAfter(dayjs(releaseDateString)))
                                    return Promise.reject(new Error(`Date to display cannot be later than Release date '${releaseDateString}'`));
                                return Promise.resolve();
                            }}
                            initialValue={dateToDisplay}
                        />,
                        <TextAreaFieldItem
                            textAreaProps={{}}
                            code='message'
                            isRequired={true}
                            label='Message'
                            labelAlign='right'
                            initialValue={message}
                        />,
                        <DateTimePickerFormItem
                            code='releaseDate'
                            isRequired={true}
                            label='Release date'
                            labelAlign='right'
                            initialValue={releaseDate}
                        />,
                        <SelectFormItem
                            code='releaseType'
                            isRequired={true}
                            dataSource={releaseTypeDataSource}
                            label='Update type'
                            labelAlign='right'
                            onChange={(value) => setSelectedReleaseType(value)}
                            selectedValue={selectedReleaseType ?? RELEASE_NOTIFICATION_TYPE.ALM}
                        />,
                        (selectedReleaseType == RELEASE_NOTIFICATION_TYPE.CONFIGURATION
                            ? <OrganisationSelectItem
                                code='clientId'
                                label='Client'
                                labelAlign='right'
                                selectProps={{
                                    treeDataSource: selectAccountSource,
                                    value: clientId,
                                    placeholder: 'Organisation',
                                    expandAll: true
                                }}
                            />
                            : null),
                        <TextAreaFieldItem
                            textAreaProps={{}}
                            code='releaseNotes'
                            label='Release notes'
                            labelAlign='right'
                            initialValue={releaseNotes}
                        />,
                        <div className={notificationCss['save-footer']}>
                            <IconTextButton
                                key='cancel'
                                label='Cancel'
                                onClick={props.onClose}
                            />
                            <IconTextButton key='save' label='Save' type='primary' htmlType='submit' />
                        </div>
                    ]}
                    onSubmit={async (values) => {
                        try {
                            spinningStates.onStart();
                            await props.onSave(values);
                        } catch (err) {
                            notification.error({
                                message: 'Save notification error',
                                description: getErrorMessage(err)
                            });
                        } finally {
                            spinningStates.onFinish();
                            props.onClose();
                        }
                    }}
                />
            } />
    );
};

export interface ICreateOrEditNotification {
    id?: number;
    onClose: () => void;
}
export const CreateOrEditNotificationComponent = observer((props: ICreateOrEditNotification) => {
    const notificationStore = useNotificationsStore();
    const { currentUser } = useAuthStore();

    interface ISaveOptions extends Omit<ISaveNotificationOptions, 'onClose'> {
        cardTitle: string;
    }

    const saveNotificationOptions = React.useMemo((): ISaveOptions => {
        const notification = notificationStore.getNotificationById(props.id);

        if (!notification)
            return {
                cardTitle: 'Add a new notification',
                onSave: async (values) => notificationStore.create({
                    type: NOTIFICATION_TYPE.BROADCAST,
                    receiverUserUuid: currentUser.uuid,
                    content: {
                        ...buildNofiticationContent(values),
                        messageType: MESSAGE_TYPE.DPU_UPDATE
                    },
                    sender: currentUser.email
                }),
            };
        return {
            cardTitle: 'Update notification',
            ...notification.content,
            ...notification.content.data,
            onSave: async (values) => notificationStore.update(notification.id, buildNofiticationContent(values))
        };
    }, [props.id]);

    return (
        <BasicCard
            title={saveNotificationOptions.cardTitle}
            contents={
                <SaveNotification
                    {...saveNotificationOptions}
                    onClose={props.onClose}
                />
            }
        />
    );
});