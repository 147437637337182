import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';

import {
    AdvanceTable,
    HorizontalMenu,
    IBasicTableColumn,
    IBasicTableProps,
    IMenuItem,
    ITableToolbarProps,
    ITreeSelectDataNode,
    ITreeSelectDataSource,
    notification,
    showInformationDialog,
    Spinner,
    Typography,
} from 'ui-lib';

import { Route } from 'core';

import { useAuthStore, useDeviceGroupsStore, useStores } from '../../../../store';
import { AssignDevicesStore, IDeviceInfoProperties } from '../../../../store/assign-devices-store';
import { AccountType } from '../../../../dto/access-management/account-dto';
import { IDeviceGroupOptions } from '../../../../dto/device-group-dto';
import { ModifyDeviceGroupComponent } from './device-group';
import { DEVICES_ROUTE_CODES } from '..';
import { NOTIFICATION_TYPE } from '../../../../dto/notifications-dto';
import { hasPermission } from '../../utils';
import { deleteDeviceGroupConfirm } from '../../admin/utils';
import { OrganisationSelect } from '../../components/organisation-select';

import manageDeviceGroupCss from './manage-device-group.css';
import { orderBy } from 'lodash';
import { useAccountTreeSelectDataSource } from '../../admin/accounts';
import { isNullOrEmpty } from 'common-utils';
import { useLocation } from 'react-router-dom';

export const enum MANAGE_DEVICE_GROUP_STATE {
    LIST,
    CREATE_OR_EDIT,
}

interface IDisplayDeviceGroup extends IDeviceGroupOptions {
    numberOfDevices: number;
}

interface IDeviceGroupCommonStates {
    onAccountChanged: (accountId: number) => void;
    treeSelectableDataSource: ITreeSelectDataSource[];
    deviceGroupLoading: boolean;
}

export const useDeviceGroupCommonStates = (
    options: {
        renderNode?: (group: IDeviceGroupOptions) => Partial<ITreeSelectDataNode>;
    } = {},
): IDeviceGroupCommonStates => {
    const [selectedAccountId, setSelectedAccountId] = React.useState<number>();
    const deviceGroupsStore = useDeviceGroupsStore();
    const { renderNode: renderDeviceGroupNode } = options;
    const _buildDeviceGroupTreeNode = (group: IDeviceGroupOptions): ITreeSelectDataSource => {
        if (!group) return undefined;

        const value = {
            value: group.deviceGroupName,
            label: group.deviceGroupLabel.trim() != '' ? group.deviceGroupLabel : group.deviceGroupName,
            ...(renderDeviceGroupNode ? renderDeviceGroupNode(group) : {}),
        };
        if (isNullOrEmpty(group.childrenGroups)) return { treeDataNode: value };
        const childGroups = orderBy(group.childrenGroups, [(item) => item.deviceGroupLabel.trim().toLowerCase()]);
        const childNodes = childGroups.map(_buildDeviceGroupTreeNode);
        if (isNullOrEmpty(childNodes)) return { treeDataNode: value };

        return { treeDataNode: value, subTreeDataNode: childNodes };
    };

    React.useEffect(() => {
        deviceGroupsStore.loadDeviceGroupDetails();
    }, []);

    const deviceGroupsTree = React.useMemo<ITreeSelectDataSource[]>(() => {
        if (!selectedAccountId) return [];
        const devicegroups = deviceGroupsStore.getDevicesGroupDetails(selectedAccountId);
        return devicegroups.map(_buildDeviceGroupTreeNode);
    }, [selectedAccountId, deviceGroupsStore.allDeviceGroups]);

    return {
        onAccountChanged: setSelectedAccountId,
        treeSelectableDataSource: deviceGroupsTree,
        deviceGroupLoading: deviceGroupsStore.dataLoading,
    };
};

interface IManageDeviceGroupContext {
    assignDevicesStore: AssignDevicesStore;
    clientAccountId: number;
    clientDeviceGroup: IDeviceGroupOptions;
    childGroup?: IDeviceGroupOptions;
    deleteGroup: (clientAccountId: number, group: IDeviceGroupOptions) => Promise<void>;
    navigation: {
        toDeviceGroupsTable: () => void;
    };
    onUpdating: (updating: boolean) => void;
}

const ManageDeviceGroupContext = React.createContext<IManageDeviceGroupContext>(null);

export const useManageDeviceGroupContext = () => React.useContext(ManageDeviceGroupContext);

export const ManageDeviceGroupsPage = observer(() => {
    const { currentUser } = useAuthStore();
    const store = useStores();
    const { apiClientStore, accountStore, deviceStore, notificationsStore, routeStore, commonStore } = store;
    const deviceGroupsStore = useDeviceGroupsStore();
    const navigate = useNavigate();
    const state: Partial<{ clientAccountId: number }> = useLocation().state || {};
    const assignDevicesStore = React.useMemo(
        () =>
            new AssignDevicesStore({
                deviceGroupsStore,
                apiClientStore: apiClientStore,
            }),
        [],
    );

    React.useEffect(() => {
        deviceGroupsStore.loadDeviceGroupDetails(true);
    }, []);

    const fileTransferRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.FILE_TRANSFER);
    const shellCommandRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.SHELL_COMMAND);
    const decommissioningRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.DECOMMISSIONING);
    const bulkRemoteCommandRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.BULK_REMOTE_COMMAND);

    const selectAccountSource = useAccountTreeSelectDataSource({ selectableTypes: [AccountType.CLIENT] });

    const [manageState, setManageState] = React.useState<MANAGE_DEVICE_GROUP_STATE>(MANAGE_DEVICE_GROUP_STATE.LIST);

    const [spinning, setSpinning] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const [selectedDeviceGroup, setSelectedDeviceGroup] = React.useState<IDeviceGroupOptions>();

    const [clientAccountId, setClientAccountId] = React.useState<number>(() =>
        currentUser.accountType === AccountType.CLIENT ? currentUser.accountId : state ? state.clientAccountId : null,
    );

    const clientDeviceGroup = React.useMemo<IDeviceGroupOptions>(() => {
        const result = deviceGroupsStore.getClientDeviceGroupDetails(clientAccountId);
        return result ? { ...result } : undefined;
    }, [clientAccountId, deviceGroupsStore.allDeviceGroups]);

    React.useEffect(() => {
        (async () => {
            if (!clientAccountId || !clientDeviceGroup) return;
            try {
                setLoading(true);
                const devices = await deviceStore.searchDevices(clientAccountId, {
                    deviceGroupNames: clientDeviceGroup.deviceGroupName,
                });
                assignDevicesStore.initialize(clientAccountId, devices);
            } catch (err) {
                showInformationDialog({
                    modalType: 'error',
                    content: err.message,
                    title: 'Error',
                });
            } finally {
                setLoading(false);
            }
        })();
    }, [clientAccountId]);

    const displayDevicesOfClient = React.useMemo<IDeviceInfoProperties[]>(() => assignDevicesStore.deviceDataSource, [assignDevicesStore.deviceDataSource]);

    const managedDeviceGroupDetails = React.useMemo(() => {
        if (!clientDeviceGroup) return undefined;
        return clientDeviceGroup.childrenGroups?.map((child) => {
            const numberOfDevices = displayDevicesOfClient.filter(
                (device) => device.deviceGroupName === child.deviceGroupName,
            ).length;
            return { ...child, numberOfDevices } as IDisplayDeviceGroup;
        });
    }, [clientDeviceGroup, displayDevicesOfClient]);

    const buildSubMenuItems = (record: IDeviceGroupOptions): IMenuItem[] => {
        const _createSubMenuItem = (
            subMenuItems: IMenuItem[],
            route: Route,
            onClick: () => void,
            label?: string,
        ): void => {
            if (!hasPermission(route.code, store)) return;

            subMenuItems.push({
                code: route.code,
                label: () => label || route.label,
                iconName: route.iconName,
                onClick,
            });
        };

        const subMenuItem = [];
        commonStore.setSelectedOrganisation(
            currentUser.accountType === AccountType.CLIENT
                ? clientAccountId
                : accountStore.getAccountById(clientAccountId).parent.id,
        );

        _createSubMenuItem(subMenuItem, shellCommandRoute, () => {
            navigate(shellCommandRoute.path, {
                state: {
                    deviceGroupName: record.deviceGroupName,
                },
            });
        });
        _createSubMenuItem(subMenuItem, fileTransferRoute, () => {
            navigate(fileTransferRoute.path, {
                state: {
                    deviceGroupName: record.deviceGroupName,
                },
            });
        });
        _createSubMenuItem(subMenuItem, decommissioningRoute, () => {
            navigate(decommissioningRoute.path, {
                state: {
                    deviceGroupName: record.deviceGroupName,
                },
            });
        });

        return subMenuItem;
    };

    const ActionsMenu = (props: { record: IDeviceGroupOptions }) => {
        return (
            <HorizontalMenu
                menuItems={[
                    {
                        code: 'actionsMenu',
                        label: () => 'Settings',
                        subMenuItems: buildSubMenuItems(props.record),
                        onClick: () => undefined,
                    },
                ]}
            />
        );
    };

    const deleteGroupByName = async (accountId: number, deviceGroup: IDeviceGroupOptions) => {
        try {
            setLoading(true);
            const deviceGroupName = deviceGroup.deviceGroupName;

            // get devices for group
            assignDevicesStore.setCurrentDeviceGroup(deviceGroupName);

            // Move devices to CLIENT account first
            await assignDevicesStore.moveDevicesToClientGroup(deviceGroupName, true);
            await deviceGroupsStore.deleteDeviceGroupByName(accountId, deviceGroupName);

            await notificationsStore.create({
                content: {
                    title: 'Device groups',
                    text: `The device group ${deviceGroup.deviceGroupLabel} has been deleted successfully.`,
                    messageType: 'Device group',
                },
                type: NOTIFICATION_TYPE.PERSONAL,
                receiverUserUuid: currentUser.uuid,
                sender: currentUser.userName,
            });

            notification['info']({
                message: `The device group ${deviceGroup.deviceGroupLabel} has been deleted successfully.`,
                placement: 'topRight',
            });
        } catch (err) {
            showInformationDialog({
                modalType: 'error',
                content: err.message,
                title: 'Delete error',
            });
        } finally {
            setLoading(false);
        }
    };

    const tableColumns: IBasicTableColumn[] = [
        {
            code: 'deviceGroupLabel',
            title: 'Name',
            dataIndex: 'deviceGroupLabel',
            width: '40%',
            defaultSortOrder: 'ascend',
            onFilter: (searchValue: string, group: IDisplayDeviceGroup) =>
                group.deviceGroupLabel?.toLowerCase().includes(searchValue.toLowerCase()),
            sorter: (a: IDisplayDeviceGroup, b: IDisplayDeviceGroup) =>
                a.deviceGroupLabel?.toLowerCase().localeCompare(b.deviceGroupLabel?.toLowerCase()),
            textSearchPlaceholder: 'Search device group',
            iconName: 'search',
            render: (text: string, record: IDisplayDeviceGroup) => (
                <Typography.Link
                    onClick={() => {
                        setSelectedDeviceGroup(record);
                        assignDevicesStore.setCurrentDeviceGroup(record.deviceGroupName);
                        setManageState(MANAGE_DEVICE_GROUP_STATE.CREATE_OR_EDIT);
                    }}
                >
                    {text}
                </Typography.Link>
            ),
        },
        {
            code: 'numberOfDevices',
            title: 'No. of devices',
            dataIndex: 'numberOfDevices',
            width: '15%',
            sorter: (a: IDisplayDeviceGroup, b: IDisplayDeviceGroup) => a.numberOfDevices - b.numberOfDevices,
        },
        {
            code: 'deviceGroupDescription',
            title: 'Description',
            dataIndex: 'deviceGroupDescription',
            width: '30%',
            sorter: (a: IDisplayDeviceGroup, b: IDisplayDeviceGroup) =>
                a.deviceGroupDescription?.toLowerCase().localeCompare(b.deviceGroupDescription?.toLowerCase()),
            onFilter: (searchValue: string, group: IDisplayDeviceGroup) =>
                group.deviceGroupDescription?.toLowerCase().includes(searchValue.toLowerCase()),
            textSearchPlaceholder: 'Search description',
            iconName: 'search',
        },
        {
            code: 'action',
            title: 'Action',
            dataIndex: 'action',
            width: '15%',
            render: (text: string, record: IDisplayDeviceGroup) => (
                <div className={manageDeviceGroupCss.tableActions}>
                    <ActionsMenu record={record}/>
                    <Typography.Link
                        className={manageDeviceGroupCss.deleteButton}
                        onClick={() => deleteDeviceGroupConfirm(deleteGroupByName, clientAccountId, record)}
                    >
                        Delete
                    </Typography.Link>
                </div>
            ),
        },
    ];

    const tableProps: IBasicTableProps = {
        columns: tableColumns,
        dataSource: managedDeviceGroupDetails,
        rowKey: 'deviceGroupName',
    };

    const tableToolbar: ITableToolbarProps = {
        onAdd: clientAccountId ? () => {
            assignDevicesStore.setCurrentDeviceGroup(undefined);
            setManageState(MANAGE_DEVICE_GROUP_STATE.CREATE_OR_EDIT);
        } : null,
    };

    const contextProps: IManageDeviceGroupContext = {
        assignDevicesStore: assignDevicesStore,
        clientAccountId: clientAccountId,
        clientDeviceGroup: clientDeviceGroup,
        childGroup: selectedDeviceGroup,
        deleteGroup: deleteGroupByName,
        navigation: {
            toDeviceGroupsTable() {
                setSelectedDeviceGroup(undefined);
                setManageState(MANAGE_DEVICE_GROUP_STATE.LIST);
            },
        },
        onUpdating: setSpinning
    };

    return (
        <Spinner
            className={manageDeviceGroupCss.spinning}
            spinning={loading || spinning || deviceGroupsStore.dataLoading}
        >
            <div>
                {manageState === MANAGE_DEVICE_GROUP_STATE.LIST && (
                    <div>
                        <div className={manageDeviceGroupCss.label}>
                            <Typography.Text>
                                The inventory lists all the devices that you can manage.
                            </Typography.Text>
                        </div>
                        {currentUser.accountType != AccountType.CLIENT && (
                            <OrganisationSelect
                                placeholder="Organisation"
                                treeDataSource={selectAccountSource}
                                expandAll={true}
                                onChange={setClientAccountId}
                                value={clientAccountId}
                                className={manageDeviceGroupCss.orgSelect}
                            />
                        )}
                        <AdvanceTable
                            loadingState={loading || deviceGroupsStore.dataLoading}
                            title={'Device groups'}
                            table={tableProps}
                            toolbar={tableToolbar}
                        />
                    </div>
                )}
                {manageState === MANAGE_DEVICE_GROUP_STATE.CREATE_OR_EDIT && (
                    <ManageDeviceGroupContext.Provider value={contextProps}>
                        <ModifyDeviceGroupComponent/>
                    </ManageDeviceGroupContext.Provider>
                )}
            </div>
        </Spinner>
    );
});
