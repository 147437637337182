import React from 'react';

import { ViewOrModifySnapshot } from './packages';
import { ManageSnapshotsTable } from './snapshots';

import { ISnapshotPublishViewProps, PreviewPublishSnapshot } from './preview-publish-snapshot';
import { ViewSnapshotPackageComponent } from './snapshot-details';

import { SNAPSHOT_STATUS } from '../../../../dto/software-snapshot-dto';
import { useLocation } from 'react-router-dom';

import { PACKAGE_TYPE } from 'dto/packages-dto';
import { SOFTWARE_MANAGEMENT_ROUTES } from '..';
import { hasPermission } from 'pages/private/utils';
import { AccountType } from 'dto/access-management/account-dto';
import { useAuthStore, useStores } from 'store';

export const AVAILABLE_PACKAGE_TYPES = [PACKAGE_TYPE.VAR, PACKAGE_TYPE.CLIENT];

export enum SoftwareSnapshotState {
    SNAPSHOTS_TABLE,
    ADD_OR_EDIT_SNAPSHOT,
    PREVIEW_PUBLISH_SNAPSHOT,
    PUBLISHED_VIEW,
}

interface ISofwareSnapshotContext {
    toModifySnapshot: (snapshotId: number) => void;
    toCreatedSnapshotTable: () => void;
    toPublishedSnapshotTable: () => void;
    toPreviewSnapshot: (snapshotId: number) => void;
    toPublishedSnapshotDetails: (snapshotId: number) => void;
}

const softwareSnapshotContext = React.createContext<ISofwareSnapshotContext>(null);

export const useSnapshotContext = () => React.useContext(softwareSnapshotContext);

export const ManageSoftwareSnapshotsComponent = () => {

    const [previewPublishSnapshot, setPreviewPublishSnapshot] = React.useState<ISnapshotPublishViewProps>();

    const locationState: { snapshotType?: SNAPSHOT_STATUS } = useLocation().state || {};

    const [snapshotType, setSnapshotType] = React.useState<SNAPSHOT_STATUS>(locationState.snapshotType ?? SNAPSHOT_STATUS.CREATED);
    const [selectedSnapshotId, setSelectedSnapshotId] = React.useState<number>(undefined);

    const [softwareSnapshotState, setSoftwareSnapshotState] = React.useState<SoftwareSnapshotState>(
        SoftwareSnapshotState.SNAPSHOTS_TABLE,
    );

    React.useEffect(() => {
        return (): void => {
            window.history.replaceState({}, '');
        };
    }, []);

    const onShowSnapshotsTableHandler = (selectedTab: SNAPSHOT_STATUS = SNAPSHOT_STATUS.CREATED): void => {
        setSnapshotType(selectedTab);
        setSelectedSnapshotId(undefined);
        setSoftwareSnapshotState(SoftwareSnapshotState.SNAPSHOTS_TABLE);
    };

    const navigateProps: ISofwareSnapshotContext = {
        toModifySnapshot: (snapshotId: number) => {
            setSelectedSnapshotId(snapshotId);
            setSoftwareSnapshotState(SoftwareSnapshotState.ADD_OR_EDIT_SNAPSHOT);
        },
        toCreatedSnapshotTable: () => onShowSnapshotsTableHandler(SNAPSHOT_STATUS.CREATED),
        toPublishedSnapshotTable: () => onShowSnapshotsTableHandler(SNAPSHOT_STATUS.RELEASED),
        toPreviewSnapshot: (snapshotId: number) => {
            setSelectedSnapshotId(snapshotId);
            setPreviewPublishSnapshot({
                snapshotId,
            });
            setSoftwareSnapshotState(SoftwareSnapshotState.PREVIEW_PUBLISH_SNAPSHOT);
        },
        toPublishedSnapshotDetails: (snapshotId: number) => {
            setSelectedSnapshotId(snapshotId);
            setSoftwareSnapshotState(SoftwareSnapshotState.PUBLISHED_VIEW);
        },
    };

    return (
        <softwareSnapshotContext.Provider value={navigateProps}>
            {softwareSnapshotState == SoftwareSnapshotState.SNAPSHOTS_TABLE &&
                <ManageSnapshotsTable snapshotType={snapshotType} setSnapshotType={setSnapshotType}/>}
            {softwareSnapshotState == SoftwareSnapshotState.ADD_OR_EDIT_SNAPSHOT &&
                <ViewOrModifySnapshot snapshotId={selectedSnapshotId}/>}
            {softwareSnapshotState == SoftwareSnapshotState.PREVIEW_PUBLISH_SNAPSHOT &&
                <PreviewPublishSnapshot {...previewPublishSnapshot} />}
            {softwareSnapshotState == SoftwareSnapshotState.PUBLISHED_VIEW && (
                <ViewSnapshotPackageComponent
                    snapshotId={selectedSnapshotId}
                    title="View published release"
                    cancelButtonProps={{
                        type: 'primary',
                        label: 'Back to published releases',
                        onClick: navigateProps.toPublishedSnapshotTable,
                    }}
                />
            )}
        </softwareSnapshotContext.Provider>
    );
};

export interface IManageSnapshotPermission {
    canModify: boolean;
    canEditSnapshot: (accountUuid: string) => boolean;
}

export const manageSnapshotPermission = (): IManageSnapshotPermission => {
    const { currentUser } = useAuthStore();
    const stores = useStores();

    const hasModifyPermission = hasPermission(SOFTWARE_MANAGEMENT_ROUTES.SNAPSHOTS_MODIFY, stores);
    const hasSnapshotActionsPermission = React.useCallback((accountUuid: string): boolean => {
        if (currentUser.accountType !== AccountType.CLIENT) {
            return hasModifyPermission;
        }
        if (currentUser.accountUuid !== accountUuid) {
            return false;
        }
        return hasModifyPermission;
    }, [currentUser, hasModifyPermission]);
    return {
        canModify: hasModifyPermission,
        canEditSnapshot: hasSnapshotActionsPermission
    };
};