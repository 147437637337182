import * as React from 'react';
import { Row as AntdRow, Col } from 'antd';


export interface IHorizontalElementsProps {
    fistElementSpan: number;
    offsets?: number[];
}

export const horizontalTwoElements = ( firstElement: JSX.Element, secondElement: JSX.Element, props: IHorizontalElementsProps = {fistElementSpan: 12, offsets:[0, 0]}) => {
    return (
        <AntdRow>
            <Col span={props.fistElementSpan} offset={props.offsets[0]} >
                {firstElement}
            </Col>
            <Col span={24-props.fistElementSpan} offset={props.offsets[1]} >
                {secondElement}
            </Col>
        </AntdRow>
    );
};