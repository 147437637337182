import React from 'react';
import { observer } from 'mobx-react';
import {
    BasicForm,
    DangerAlert,
    FormItem,
    IconTextButton,
    Label,
    SelectFormItem,
    Spinner,
    TextAreaFieldItem,
    TextFieldItem,
    useForm,
} from 'ui-lib';
import { getErrorMessage } from 'common-utils';
import { confirmCancel } from '../admin/utils';
import { ERROR_CODES } from '../../../dto/error-codes';
import { useAccountStore, useAuthStore, useKeyStore } from '../../../store';
import { OrganisationSelectItem } from '../components/organisation-select';
import { AccountType } from '../../../dto/access-management/account-dto';
import manageKeyCss from './keys.css';
import { useAccountTreeSelectDataSource } from '../admin/accounts';
import { pspTypesForClient } from './key-utils';

const UPLOAD_KEYS_DESCRIPTION = 'Copy and paste the Key Content provided by your Payment Service Provider';

export interface IUploadKeysComponentProps {
    onCancel: () => void;
    onOk: () => void;
}

export const UploadKeysComponent = observer((props: IUploadKeysComponentProps) => {
    const accountStore = useAccountStore();
    const {currentUser} = useAuthStore();
    const keyStore = useKeyStore();
    const clientDataSource = useAccountTreeSelectDataSource({selectableTypes: [AccountType.CLIENT]});
    const [selectedClient, setSelectedClient] = React.useState<number>(currentUser.accountType == 'CLIENT' && !!clientDataSource ? clientDataSource.treeDataNode.value as number : undefined);
    const [form] = useForm();
    const { pspTypes, updateSelectedPsp, selectedPsp, supportedKeyTypes } = pspTypesForClient(accountStore, keyStore, selectedClient);

    React.useEffect(() => {
        form.setFieldsValue({ pspTypes: selectedPsp, keyTypeField: null });
    }, [selectedPsp]);

    const getVarAccountId = (selectedClientId: number) => {
        switch (currentUser.accountType) {
            case AccountType.VAR:
                return currentUser.accountId;
            case AccountType.DPU:
            case AccountType.CLIENT:
                return accountStore.getAccountById(selectedClientId)?.parent?.id;
        }
    };

    const [isUploadKeys, setIsUploadKeys] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const onCancel = () => {
        confirmCancel(() => {
            setErrorMessage(null);
            props.onCancel();
        });
    };

    const onSave = async (values) => {
        try {
            setErrorMessage(null);
            setIsUploadKeys(true);
            await keyStore.createKey({
                psp: selectedPsp,
                varAccountId: getVarAccountId(values.clientField),
                clientAccountId: values.clientField,
                keyName: values.keyNameField,
                keyType: values.keyTypeField,
                keyContent: values.keyContentField,
            });
            props.onOk();
        } catch (error) {
            if (error?.message === ERROR_CODES.EXISTS) {
                setErrorMessage(
                    `You cannot save a new ${keyStore.getKeyTypeLabel(
                        values.keyTypeField,
                    )} for this Client. There are already ${selectedPsp} keys for this Client.`,
                );
                return null;
            }
            setErrorMessage(getErrorMessage(error));
        } finally {
            setIsUploadKeys(false);
        }
    };

    const ErrorField = observer(() =>
        errorMessage ? <DangerAlert className={manageKeyCss.alert} message={errorMessage}/> : null,
    );
    const descriptionField = (
        <FormItem
            code="description"
            child={<Label className={manageKeyCss.description} label={UPLOAD_KEYS_DESCRIPTION}/>}
        />
    );
    const keyNameField = <TextFieldItem labelColSpan={24} code="keyNameField" label="Key Name" isRequired={true}/>;
    const clientField = (
        <OrganisationSelectItem
            labelColSpan={24}
            code='clientField'
            label='Client'
            initialValue={selectedClient}
            isRequired={true}
            selectProps={{
                treeDataSource: clientDataSource,
                expandAll: true,
                onChange: setSelectedClient,
            }}
        />
    );
    const pspField = (
        <SelectFormItem
            labelColSpan={24}
            code='pspTypes'
            label='PSP'
            isRequired={true}
            selectedValue={selectedPsp}
            onChange={updateSelectedPsp}
            dataSource={pspTypes.map((item) => ({ value: item, label: item }))}
        />
    );

    const keyTypeField = (
        <SelectFormItem
            labelColSpan={24}
            code='keyTypeField'
            label='Key Type'
            isRequired={true}
            dataSource={keyStore.uploadKeyTypes.map(keyType => ({...keyType, disabled: !supportedKeyTypes.includes(keyType.value)}))}
        />
    );
    const keyContentField = (
        <TextAreaFieldItem
            labelColSpan={24}
            code="keyContentField"
            label="Key Content"
            isRequired={true}
            textAreaProps={{autoSize: {minRows: 2, maxRows: 5}}}
        />
    );
    const actionsField = (
        <div className={manageKeyCss.control}>
            <IconTextButton onClick={onCancel} label="Cancel"/>
            <IconTextButton type="primary" htmlType="submit" label="Save"/>
        </div>
    );

    return (
        <Spinner
            className={manageKeyCss.spinner}
            spinning={isUploadKeys}
        >
            <BasicForm
                cardPros={{title: 'Upload Keys', bordered: false}}
                form={form}
                className={manageKeyCss.uploadKeys_form}
                items={[
                    <ErrorField key="error-field"/>,
                    descriptionField,
                    keyNameField,
                    clientField,
                    pspField,
                    keyTypeField,
                    keyContentField,
                    actionsField,
                ]}
                onSubmit={onSave}
            />
        </Spinner>
    );
});
