import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PermissionPage, Error404 } from 'ui-lib';

import { ManageAccountsComponent } from './list';
import { AddAccountComponent, EditAccountComponent } from './save';
import { useAccountStore, useAuthStore, useRoutesStore, useStores } from '../../../../store';

import accountCss from './account.css';
import { ITreeSelectDataSource } from '../../../../store/account-store';
import { AccountType, IAccount } from '../../../../dto/access-management/account-dto';

export enum ACCOUNT_ROUTE_CODES {
    ROOT = 'ADMIN_ACCOUNTS',
    ADD = 'ADMIN_ACCOUNTS_ADD',
    EDIT = 'ADMIN_ACCOUNTS_EDIT',
}

interface ISelectableDataSource {
    viewableTypes?: AccountType[];
    selectableTypes: AccountType[];
    defaultAccountHierarchy?: IAccount;
}
export const useAccountTreeSelectDataSource = (options: ISelectableDataSource, otherDeps: any[] = []): ITreeSelectDataSource => {
    const { currentUser } = useAuthStore();
    const accountStore = useAccountStore();
    const dataSource = React.useMemo<ITreeSelectDataSource>(
        () => accountStore.getSelectableTreeDataSource(options.viewableTypes || Object.values(AccountType), options.selectableTypes, options.defaultAccountHierarchy),
        [currentUser, accountStore.accountHierarchy, ...otherDeps],
    );
    return dataSource;
};

export function ManageAccountPages() {
    const routeStore = useRoutesStore();
    const { permissionStore } = useStores();

    const rootRoute = routeStore.getRoute(ACCOUNT_ROUTE_CODES.ROOT);
    const addRoute = routeStore.getRoute(ACCOUNT_ROUTE_CODES.ADD);
    const editRoute = routeStore.getRoute(ACCOUNT_ROUTE_CODES.EDIT);

    return (
        <Routes>
            <Route
                path='/'
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={`Manage ${rootRoute.label}`}
                    >
                        <ManageAccountsComponent />
                    </PermissionPage>
                }
            />
            <Route
                path={addRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={addRoute.label}
                        className={accountCss.save}
                    >
                        <AddAccountComponent />
                    </PermissionPage>
                }
            />
            <Route
                path={editRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title={editRoute.label}
                        className={accountCss.save}
                    >
                        <EditAccountComponent />
                    </PermissionPage>
                }
            />
            <Route path='*' element={<Error404 message='Page Not Found' />} />
        </Routes>
    );
}
