import dpkgCompare from 'dpkg-compare-versions';

export class VersionComparison {

    public static compare(v1: string, v2: string): number {
        try {
            return dpkgCompare(v1, v2);
        } catch (err) {
            console.log(`Something went wrong while comparing versions ${v1} and ${v2}: ${err.message}`);
            return 0;
        }
    };

    public static eq(a: string, b: string): boolean {
        return this.compare(a, b) == 0;
    }

    public static lt(a: string, b: string): boolean {
        return this.compare(a, b) < 0;
    }

    public static gt(a: string, b: string): boolean {
        return this.compare(a, b) > 0;
    }

    public static neq(a: string, b: string): boolean {
        return !this.eq(a, b);
    }

    public static gte(a: string, b: string): boolean {
        return this.gt(a, b) || this.eq(a, b);
    }

    public static lte(a: string, b: string): boolean {
        return this.lt(a, b) || this.eq(a, b);
    }

    public static comparator(aVersion: string, operator: string, bVersion: string): boolean {
        switch (operator) {
            case '':
            case '=':
            case '==':
            case '===':
                return this.eq(aVersion, bVersion);
            case '!==':
            case '!=':
                return this.neq(aVersion, bVersion);
            case '>>':
            case '>':
                return this.gt(aVersion, bVersion);
            case '>=':
                return this.gte(aVersion, bVersion);
            case '<<':
            case '<':
                return this.lt(aVersion, bVersion);
            case '<=':
                return this.lte(aVersion, bVersion);
            default: {
                console.log(`Invalid operator: ${operator}`);
                return false;
            };
        }
    };
}
