import React from 'react';
import { useNotificationsStore, useStores } from '../../../store';
import { message, Skeleton, RowLayout, Icon } from 'ui-lib';
import { Card, Typography } from 'antd'; // temp import, will be replaced with a proper component from MUI
import { observer } from 'mobx-react';
import { IContentData, INotification, MESSAGE_TYPE } from 'dto/notifications-dto';
import css from './notification.css';
import { ISpinnerComponentProps, useSpinnerComponent } from '../components/loading-component';
import { formatShortDate, getTimeFromServiceWindowSettings } from 'common-utils/date-utils';

export const NotificationsPage = observer(() => {
    const { notificationsStore } = useStores();

    const {SpinnerComponent, ...spinningStates} = useSpinnerComponent();

    return (
        <SpinnerComponent
            child={
                <Skeleton loading={notificationsStore.dataLoading}>
                    <div className={css['notification-hub']}>
                        <ul style={{ listStyleType: 'none', padding: '0px' }}>
                            {
                                notificationsStore.recieveNotifications.map((item, index) => (
                                    <li key={index}>
                                        <NotificationCardItem 
                                            onFinish={spinningStates.onFinish}
                                            loading={notificationsStore.dataLoading}
                                            onStart={spinningStates.onStart}
                                            {...item} />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </Skeleton>
            }
        />
    );
});

const NotificationCardItem = (props: INotification & Omit<ISpinnerComponentProps, 'SpinnerComponent'>) => {
    const notificationStore = useNotificationsStore();

    const deleteNotification = async (id: number) => {
        try {
            props.onStart();
            await notificationStore.markRead(id);
        } catch (err) {
            message.error(err.message);
        } finally {
            props.onFinish();
        }
    };

    function buildItemDetails(notificationItem: INotification) {
        switch (notificationItem.content.messageType) {
            case MESSAGE_TYPE.SOFTWARE_UPDATE:
            case MESSAGE_TYPE.DPU_UPDATE: {
                const content: IContentData = notificationItem.content.data;
                return (
                    <div>
                        <Typography.Text key='text'>{notificationItem.content.text}</Typography.Text><br />
                        <Typography.Text key='release-type' strong>Release Type: </Typography.Text><Typography.Text key='releaseType'>{content.releaseType}</Typography.Text><br />
                        <Typography.Text key='date' strong>Date: </Typography.Text><Typography.Text key='releaseDate'>{formatShortDate(content.releaseDate)}</Typography.Text><br />
                        {/* TODO: Hard-code service window time */}
                        <Typography.Text key='time' strong>Time: </Typography.Text><Typography.Text key='serviceWindowTime'>{getTimeFromServiceWindowSettings()}</Typography.Text><br />
                    </div>
                );
            }
            default:
                return (<p>{notificationItem.content.text}</p>);
        }
    }
    return (
        <Card className={css['notification-item']}>
            <RowLayout
                className={css['row-layout-center']}
                childItems={[
                    <Typography.Text strong>{props.content.title}</Typography.Text>,
                    <span className={css['delete-notification-span']} onClick={() => deleteNotification(props.id)}>
                        <Icon iconClassName={css['delete-notification-icon']} iconName='xmark' />
                    </span>
                ]}
                childCols={[
                    23, 1
                ]}
            />
            {buildItemDetails(props)}
            <RowLayout
                className={css['notification-footer']}
                childItems={[
                    <Typography.Text className={css['left']}>{formatShortDate(props.content.dateToDisplay)}</Typography.Text>,
                    <div className={css['right']}>
                        <Typography.Text>
                            <Icon iconName='bell' className={css['icon-wrapper']} /> {props.content.messageType}
                        </Typography.Text>
                    </div>
                ]}
                childCols={[12, 12]}
            />
        </Card>
    );
};
