import * as React from 'react';
import { observer } from 'mobx-react';
import { BasicForm, SelectFormItem } from 'ui-lib/components/forms';
import { useStores } from 'store';
import { DeviceIdentifierItem } from 'pages/private/components/device-groups-select';
import { IconTextButton, Spinner, notification, showInformationDialog, showConfirmDialog } from 'ui-lib';
import { MESSAGE_TYPE, NOTIFICATION_TYPE } from 'dto/notifications-dto';
import { OrganisationSelectFormItem } from '../../components/organisation-component';

import deviceCss from './device-reset.css';

interface IDeviceResetFormValues {
    organisationId: number;
    deviceIdentifierField: string;
}

export const DeviceReset = observer(() => {
    const stores = useStores();
    const { deviceStore, notificationsStore } = stores;
    const { currentUser } = stores.authStore;

    const [loading, setLoading] = React.useState<boolean>(false);

    const notificationReset = async (type: string, message: string, description: string) => {
        notification[type]({
            message,
            description,
            placement: 'topRight',
        });
        await notificationsStore.create({
            content: {
                title: 'Reset device',
                text: message,
                messageType: MESSAGE_TYPE.DEVICE,
            },
            type: NOTIFICATION_TYPE.PERSONAL,
            receiverUserUuid: currentUser.uuid,
            sender: currentUser.userName,
        });
    };

    const resetHandle = async (values: IDeviceResetFormValues): Promise<void> => {
        try {
            setLoading(true);
            await deviceStore.resetDevice(stores.commonStore.selectedOrganisation, values.deviceIdentifierField);
            await notificationReset(
                'info',
                'Reset device action has been issued.',
                'Please refresh and check the Device Jobs table',
            );
        } catch (error) {
            showInformationDialog({
                modalType: 'error',
                title: 'Error',
                content: error.message,
            });
        } finally {
            setLoading(false);
        }
    };

    function confirmReset(values: IDeviceResetFormValues) {
        showConfirmDialog({
            title: 'Confirmation',
            content: 'Are you sure you want to reset this device?',
            okText: 'OK',
            cancelText: 'Cancel',
            onOk: () => resetHandle(values),
        });
    }

    return (
        <Spinner
            className={deviceCss.spinner}
            spinning={loading}
            children={
                <BasicForm
                    key='deviceResetForm'
                    items={[
                        <OrganisationSelectFormItem
                            key='organisationSelect'
                            code='organisationId'
                            isRequired={true}
                            labelAlign='right'
                            accountType={currentUser.accountType}
                            selectedValue={stores.commonStore.selectedOrganisation}
                            onChange={(value) => stores.commonStore.setSelectedOrganisation(value)}
                        />,
                        <DeviceIdentifierItem key='deviceIdentifierItem' labelAlign='right' />,
                        <IconTextButton
                            className={deviceCss.control}
                            key='submitButton'
                            label='Device Reset'
                            htmlType='submit'
                            type='primary'
                        />,
                    ]}
                    onSubmit={confirmReset}
                />
            }
        />
    );
});
