import * as React from 'react';
import { Empty as AntdEmpty } from 'antd';

export interface IEmptyProps {
    className?: string;
    image?: React.ReactNode | 'simple' | 'default';
    description?: React.ReactNode;
}

export const Empty = (props: IEmptyProps) => {
    let image = props.image;
    if (props.image === 'simple') {
        image = AntdEmpty.PRESENTED_IMAGE_SIMPLE;
    } else if (props.image === 'default') {
        image = AntdEmpty.PRESENTED_IMAGE_DEFAULT;
    }
    return (
        <AntdEmpty {...props} image={image} />
    );
};
