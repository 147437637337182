import { IPackageDependencyProps } from 'dto/packages-dto';
import { VersionComparison } from './debian-version-utils';

const SYMBOL_PATTERN = /[<<|>>|>=|<=|=]+/g;

const buildVersionAndSymbol = (version: string): { symbol: string; version: string; } => {
    const symbolExpResult = new RegExp(SYMBOL_PATTERN).exec(version);
    if (!symbolExpResult) {
        return { symbol: '', version: version };
    }
    const symbol = symbolExpResult[0];
    const validVersion = version.replace(symbol, '').trim();
    return {
        symbol: symbol,
        version: validVersion,
    };
};

export const packageDependencyComparator = (a: IPackageDependencyProps, b: IPackageDependencyProps): boolean => {
    try {
        const aRange = buildVersionAndSymbol(a.version);
        const bRange = buildVersionAndSymbol(b.version);

        return a.label === b.label && ((!aRange.version || !bRange.version) ? true : VersionComparison.comparator(bRange.version, aRange.symbol, aRange.version));
    } catch (err) {
        console.log(`Unable to compare two versions of ${a.label} and ${b.label} due to: ${err.message}`);
        return false;
    }
};
