import * as React from 'react';
import { TimePicker as AntdTimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

export interface ITimePickerProps {
    code?: string;
    className?: string;
    allowClear?: boolean;
    format?: string;
    minuteStep?: number;
    use12Hours?: boolean;
    showNow?: boolean;
    timeStringValue?: string;
    onChange?: (value?: Dayjs, timeStringValue?: string) => void;
    disabled?: boolean;
}

export const TimePicker = (props: ITimePickerProps): JSX.Element => {
    const initialTime = !props.timeStringValue
        ? null
        : dayjs(props.timeStringValue, props.format);

    const [timeValue, setTimeValue] = React.useState<any>(initialTime);

    const handleChange = (time: any, timeStringValue: string): void => {
        if (!time) {
            setTimeValue(time);
            props.onChange(time);
        } else {
            const time = dayjs(timeStringValue, props.format);
            setTimeValue(time);
            props.onChange(time, timeStringValue);
        }
    };

    return (
        <AntdTimePicker
            key={props.code}
            className={props.className}
            allowClear={props.allowClear}
            format={props.format}
            minuteStep={props.minuteStep}
            use12Hours={props.use12Hours}
            showNow={props.showNow}
            onChange={handleChange}
            value={timeValue}
            disabled={props.disabled}
        />
    );
};
