import * as React from 'react';
import { observer } from 'mobx-react';

import {
    IconTextButton,
    IAdvanceTableToolbarProps,
    DangerAlert,
    IBasicTableColumn,
    AdvanceTable,
    ITreeSelectDataSource,
    InformationDialogType,
    showInformationDialog,
} from 'ui-lib';

import { OrganisationSelect } from '../../components/organisation-select';
import { hasContainsIgnoreCase, localeCompare } from 'common-utils/string-utils';
import { confirmDialog, hasPermission } from '../../utils';

import {
    useAccountStore,
    useAuthStore,
    useDeploymentGroupsStore,
    useDeploymentStore,
    useDeviceGroupsStore,
    useStores
} from '../../../../store';
import { IDeploymentGroup, IDisplayDeploymentGroup } from '../../../../dto/deployment/deployment-group-dto';
import { AccountType } from '../../../../dto/access-management/account-dto';

import { ACTION_TYPES, DEPLOYMENT_GROUP_ROUTE_CODES, PAGE_TYPES } from '.';
import { ISaveState } from './save';

import deploymentGroupCss from './group.css';
import { DeploymentStatus } from "../../../../dto/deployment/deployment-dto";

export interface IManageDeploymentGroupsComponentProps {
    setNavigateState: (value: ISaveState) => void;
    setPageType: (type: PAGE_TYPES) => void;
    state: IListState;
}

export interface IListState {
    accountId?: number;
}

export const ManageDeploymentGroupsComponent = observer((props: IManageDeploymentGroupsComponentProps) => {

    const stores = useStores();
    const accountStore = useAccountStore();
    const deviceGroupStore = useDeviceGroupsStore();
    const deploymentGroupStore = useDeploymentGroupsStore();
    const deploymentStore = useDeploymentStore();
    const { assureApiAccount, currentUser } = useAuthStore();
    const { state } = props;

    const [displayDeploymentGroups, setDisplayDeploymentGroups] = React.useState<IDisplayDeploymentGroup[]>([]);

    const selectAccountSource = React.useMemo<ITreeSelectDataSource>(
        () => accountStore.getSelectableTreeDataSource(Object.values(AccountType), [AccountType.CLIENT, AccountType.VAR]),
        [currentUser],
    );

    const [selectedAccount, setSelectedAccount] = React.useState(() => {
        return state?.accountId
            ? accountStore.getAccountById(state?.accountId)
            : (currentUser.accountType !== AccountType.CLIENT)
                ? null
                : accountStore.getAccountById(currentUser.accountId);
    });

    const hasModifyPermission = hasPermission(DEPLOYMENT_GROUP_ROUTE_CODES.MODIFY, stores);

    React.useEffect(() => {
        setDisplayDeploymentGroups(() => {
            if (!selectedAccount || (selectedAccount.accountType === currentUser.accountType))
                return deploymentGroupStore.displayDeploymentGroups;

            const uuids = accountStore.getClientsForAccount(selectedAccount.id).map(account => account.uuid);
            return deploymentGroupStore.displayDeploymentGroups.filter((group) => group.accountUuid === selectedAccount.uuid || uuids.includes(group.accountUuid));
        });
    }, [selectedAccount, deploymentGroupStore.displayDeploymentGroups]);

    React.useEffect(() => {
        reloadData(false);
    }, []);

    const reloadData = async (force?: boolean): Promise<void> => {
        await Promise.all([
            deploymentGroupStore.loadDeploymentGroups(assureApiAccount?.accountId, force),
        ]);
    };

    const deleteDeploymentGroup = async (record) => deploymentGroupStore.deleteDeploymentGroup(
      accountStore.getAccountByUuid(record.accountUuid)?.id,
      record.id,
      (modalType: InformationDialogType, message: string) => showInformationDialog({
          modalType,
          title: 'Deployment group',
          content: message
      })
    );

    const hasScheduledOrInProgressDeployments = (record) => {
        const groupDeployments = deploymentStore.getDeploymentsByGroupId(record.id);
        return groupDeployments.filter((deployment) => [DeploymentStatus.SCHEDULED, DeploymentStatus.IN_PROGRESS].includes(deployment.status)).length > 0;
    };

    const hasCancelledDeployments = (record) => {
        const groupDeployments = deploymentStore.getDeploymentsByGroupId(record.id);
        return groupDeployments.filter((deployment) => [DeploymentStatus.CANCELLED].includes(deployment.status)).length > 0;
    };

    const columns: IBasicTableColumn[] = [
        {
            code: 'label',
            title: 'Deployment group',
            dataIndex: 'label',
            sorter: (a: IDisplayDeploymentGroup, b: IDisplayDeploymentGroup) => localeCompare(a.label, b.label),
            textSearchPlaceholder: 'Search Deployment group',
            onFilter: (searchString: string, group: IDisplayDeploymentGroup) =>
                hasContainsIgnoreCase(group.label, searchString),
            iconName: 'search',
            render: (text: string, record: IDisplayDeploymentGroup) => (
                <IconTextButton
                    onClick={(): void => {
                        props.setNavigateState({
                            groupId: record.id,
                            actionType: hasModifyPermission ? ACTION_TYPES.EDIT : ACTION_TYPES.VIEW,
                        });
                        props.setPageType(PAGE_TYPES.MODIFY);
                    }}
                    label={text}
                    type='link'
                />
            ),
        }, {
            code: 'accountName',
            title: 'Account',
            dataIndex: 'accountName',
            sorter: (a: IDisplayDeploymentGroup, b: IDisplayDeploymentGroup) => localeCompare(b.accountName, a.accountName),
            textSearchPlaceholder: 'Search Account',
            onFilter: (searchString: string, group: IDisplayDeploymentGroup) => hasContainsIgnoreCase(group.accountName, searchString),
        }, {
            code: 'deviceGroups',
            title: 'Device groups',
            dataIndex: 'deviceGroupLabelAsString',
            sorter: (a: IDisplayDeploymentGroup, b: IDisplayDeploymentGroup) => localeCompare(b.deviceGroupLabelAsString, a.deviceGroupLabelAsString),
            textSearchPlaceholder: 'Search Device group',
            onFilter: (searchString: string, group: IDisplayDeploymentGroup) => hasContainsIgnoreCase(group.deviceGroupLabelAsString, searchString),
        },
        {
            code: 'action',
            title: 'Action',
            dataIndex: 'action',
            render: (_, record: IDeploymentGroup) => (
                <IconTextButton
                  onClick={async (): Promise<void> => {
                      await deploymentStore.loadDeploymentsByGroupId(accountStore.getAccountByUuidOrParentUuidForClientAccount(record.accountUuid).id, record.id, true);
                      if (hasScheduledOrInProgressDeployments(record)) {
                          const message = 'Deployment group has deployments IN PROGRESS or SCHEDULED. Please cancel the deployments first, and try to delete a group then.';
                          showInformationDialog({
                              modalType: 'warning',
                              title: 'Deployment group',
                              content: message
                          });
                      } else if (hasCancelledDeployments(record)) {
                          confirmDialog(
                            <div>
                                Any CANCELLED deployments associated with this deployment group will be deleted
                                also so you will lose the history of deployments.<br />
                                Do you want to delete this deployment group?
                            </div>,
                            () => deleteDeploymentGroup(record)
                          );
                      } else {
                          await deleteDeploymentGroup(record);
                      }
                  }}
                  label="Delete"
                  type="link"
                  disabled={!hasModifyPermission}
                />
            ),
        },
    ];

    const toolbar: IAdvanceTableToolbarProps = {
        onReload: () => reloadData(true),
        extraItems: hasModifyPermission
            ? [
                <IconTextButton
                    iconName='plus'
                    key='addDeploymentGroup'
                    type='primary'
                    onClick={(): void => {
                        props.setNavigateState({
                            actionType: ACTION_TYPES.ADD,
                        });
                        props.setPageType(PAGE_TYPES.MODIFY);
                    }}
                    label={'Add New'}
                />,
            ]
            : [],
    };

    const selectAccountComponent = currentUser.accountType != AccountType.CLIENT && (
        <OrganisationSelect
            placeholder='Select Account'
            treeDataSource={selectAccountSource}
            expandAll={true}
            onChange={(value): void => {
                setSelectedAccount(accountStore.getAccountById(value));
            }}
            value={selectedAccount?.id}
            disabled={deploymentGroupStore.dataLoading || deviceGroupStore.dataLoading}
            className={deploymentGroupCss.orgSelect}
        />
    );

    return (
        <React.Fragment>
            {deploymentGroupStore.error ? (
                <DangerAlert message={deploymentGroupStore.error} className={deploymentGroupCss.alert} />
            ) : null}
            <AdvanceTable
                title={selectAccountComponent}
                loadingState={deploymentGroupStore.dataLoading || deviceGroupStore.dataLoading || deploymentGroupStore.dataLoading}
                table={{
                    columns: columns,
                    dataSource: displayDeploymentGroups,
                }}
                toolbar={toolbar}
            />
        </React.Fragment>
    );
});
