import * as React from 'react';
import type { Moment } from 'moment';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);

export interface IRangeDateTimePickerProps {
    className?: string;
    allowClear?: boolean;
    dateFormat?: string;
    showTime?: boolean;
    minuteStep?: number;
    picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
    onChange?: (values?: Dayjs[]) => void;
    disabledDate?: (values: Moment) => boolean;
    disabled?: boolean;
    defaultValues?: [Dayjs, Dayjs];
    onCalendarChange?: (info: 'start' | 'end', dateFrom?: Date, dateTo?: Date) => void;
    onOpenChange?: (isOpened: boolean) => void;
    status?: 'warning' | 'error';
}

export const RangeDateTimePicker = (props: IRangeDateTimePickerProps) => {
    const [dateValues, setDateValues] = React.useState<any>(props.defaultValues);

    const handleChange = (dates, dateStrings) => {
        if (props.showTime || !dates) {
            setDateValues(dates);
            props.onChange && props.onChange(dates);
        } else {
            const startDate = dayjs(dateStrings[0], props.dateFormat).startOf('d');
            const endDate = dayjs(dateStrings[1], props.dateFormat).endOf('d');
            setDateValues([startDate, endDate]);
            props.onChange && props.onChange([startDate, endDate]);
        }
    };

    const onCalendarChange = (_, dateStrings, {range}) => {
        if (props.onCalendarChange) {
            const startDate = dateStrings[0] ? new Date(dateStrings[0]) : undefined;
            const endDate = dateStrings[1] ? new Date(dateStrings[1]) : undefined;
            props.onCalendarChange(range, startDate, endDate);
        }
    };

    return (
        <DatePicker.RangePicker
            className={props.className}
            allowClear={props.allowClear}
            format={props.dateFormat}
            showTime={props.showTime}
            minuteStep={props.minuteStep}
            picker={props.picker}
            onChange={handleChange}
            value={dateValues}
            disabledDate={props.disabledDate}
            disabled={props.disabled}
            onCalendarChange={onCalendarChange}
            onOpenChange={props.onOpenChange}
            status={props.status}
        />
    );
};
