import { IApiClientOptions } from 'core';
import { IAccount, ISaveAccountOptions } from '../dto/access-management/account-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';

export class AccountApiClient extends AssureBaseApiClient {
    constructor(options: IApiClientOptions) {
        super('account', options);
    }

    protected getRootPath(): string {
        return '/accounts';
    }

    public async getAccounts(): Promise<IAccount[]> {
        const response = await this.getAllEntities<{ accounts: IAccount[] }>();
        return response.accounts;
    }

    public async getAccountById(id: number): Promise<IAccount> {
        const response = await this.send<{ account: IAccount }>(HTTP_METHOD.GET, this.getRequestOptions('account', { id }));
        return response.account;
    }

    public async getAccountByUuid(accountUuid: string): Promise<IAccount> {
        const response = await this.send<{ account: IAccount }>(HTTP_METHOD.GET, this.getRequestOptions('account', { accountUuid }));
        return response.account;
    }

    public async createAccount(account: ISaveAccountOptions): Promise<number> {
        const response = await this.send<{ result: number }>(HTTP_METHOD.POST, this.getRequestOptions('add', { account }));
        return response.result;
    }

    public async editAccount(id: number, account: ISaveAccountOptions): Promise<number> {
        const response = await this.send<{ result: number }>(HTTP_METHOD.PUT, this.getRequestOptions(`edit/${id}`, { account }));
        return response.result;
    }

    public async deleteAccount(id: number): Promise<boolean> {
        const response = await this.deleteEntity<{ result: boolean }>({ id });
        return response.result;
    }
}
