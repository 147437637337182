import * as React from 'react';
import { Transfer as AntdTransfer } from 'antd';

import cx from 'classnames';
import transferCss from './transfer.css';
import { ListStyle, TransferDirection } from 'antd/lib/transfer';

export interface ITransferBodyProps<T> {
    direction: TransferDirection;
    filteredItems: T[];
    onItemSelectAll: (dataSource: string[], checkAll: boolean) => void;
    onItemSelect: (key: string, check: boolean) => void;
    selectedKeys: string[];
    disabled?: boolean;
  }

export interface ITransferProps<T> {
    code: string;
    className?: string;
    dataSource: T[];
    targetKeys: string[];
    selectedKeys?: string[];
    onChange?: (targetKeys: string[], direction: string, moveKeys: string[]) => void;
    onSelectChange?: (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => void;
    titles?: React.ReactNode[];
    showSearch?: boolean;
    filterOption?: (inputValue: string, item: T) => boolean;
    disabled?: boolean;
    oneWay?: boolean | false;
    pagination?: boolean;
    operations?: string[]; // Custom the left/right arrow text
    listStyle?: ((style: ListStyle) => React.CSSProperties) | React.CSSProperties;
    render?: (item: T) => React.ReactElement;
    children?: (props: ITransferBodyProps<T>) => React.ReactNode;
}

export function Transfer<T>(props: ITransferProps<T>) {
    return <AntdTransfer
        {...props}
        key={props.code}
        className={cx(transferCss.basicTransfer, props.className)}
        />;
}
