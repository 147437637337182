import { isNullOrEmpty } from 'common-utils';
import { IDetectPackageDependenciesResult } from 'dto/packages-dto';
import { ISnapshotProps } from 'dto/software-snapshot-dto';
import { toNumber } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { useSnapshotStore } from 'store';
import { IDisplaySnapshotProps } from 'store/snapshot.store';
import { AdvanceTable, IBasicTableColumn, SelectFormItem, Typography } from 'ui-lib';

const deploymentUnitTypes = ['SOFTWARE_UPDATE']; // full list is 'REMOTE_COMMAND', 'SOFTWARE_UPDATE', 'CONFIGURATION_UPDATE'
const deploymentUnitTypeOptions = deploymentUnitTypes.map((item) => ({ label: item, value: item }));

export interface ISnapshotWithUnmetDependencies extends ISnapshotProps {
    unmetDependencies: IDetectPackageDependenciesResult[];
}

export interface IAddReleaseStepProps {
    accountUuid: string;
    selectedReleaseId?: number;
    addReleaseToDeployment: (releaseId: number) => void;
}

export const AddReleaseStep = observer((props: IAddReleaseStepProps): JSX.Element => {
    const { accountUuid, selectedReleaseId, addReleaseToDeployment } = props;

    const snapshotStore = useSnapshotStore();
    const releaseList = React.useMemo<ISnapshotWithUnmetDependencies[]>(() => {
        if (!accountUuid) {
            return undefined;
        }
        return snapshotStore.getSnapshotsByAccountUuid(accountUuid).map(snapshot => {
            return { ...snapshot, unmetDependencies: snapshotStore.unmetDependencies(snapshot.id) };
        });
    }, [accountUuid]);

    const releasesColumns: IBasicTableColumn[] = [
        {
            code: 'label',
            title: 'Software release name',
            dataIndex: 'label',
            sorter: (a, b) => a.label.localeCompare(b.label),
            render: (text: string, record: ISnapshotWithUnmetDependencies): React.ReactNode => {
                if (!(isNullOrEmpty(record.unmetDependencies))) {
                    return <Typography.Text style={{ color: 'red' }}>{text}</Typography.Text>;
                }
                return <Typography.Text>{text}</Typography.Text>;
            }
        },
        {
            code: 'type',
            title: 'Release type',
            dataIndex: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type),
            render: (_, record: IDisplaySnapshotProps) => (record.accountUuid ? record.type : ''),
        },
        {
            code: 'version',
            title: 'Version',
            dataIndex: 'version',
            sorter: (a, b) => a.version.localeCompare(b.version),
        },
        {
            code: 'id',
            title: 'Release ID',
            dataIndex: 'snapshotId',
            sorter: (a, b) => toNumber(a.id) - toNumber(b.id),
        },
        {
            code: 'updatedAt',
            title: 'Last updated',
            dataIndex: 'updatedAt',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.updatedAtTimestamp.localeCompare(b.updatedAtTimestamp),
        },
    ];

    return (
        <div>
            <SelectFormItem
                code="4"
                label="Type"
                placeholder="Select a deployment unit type"
                selectedValue={deploymentUnitTypes[0]}
                dataSource={deploymentUnitTypeOptions}
            />
            <AdvanceTable
                title="Add deployment units"
                loadingState={!releaseList}
                table={{
                    columns: releasesColumns,
                    dataSource: releaseList,
                    rowSelection: {
                        type: 'radio',
                        defaultSelectedRowKeys: selectedReleaseId ? [selectedReleaseId] : [],
                        getCheckboxProps: (record: any) => {
                            return {
                                name: record['label'],
                                disabled: record.disabled
                            };
                        },
                        onChange: (selectedRowKeys: number[]): void => {
                            if (isNullOrEmpty(selectedRowKeys)) {
                                return;
                            }
                            // selectedRowKeys is always 1
                            addReleaseToDeployment(selectedRowKeys[0]);
                        }
                    },
                    rowKey: 'id',
                }}
            />
        </div>
    );
});