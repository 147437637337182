import * as React from 'react';
import { observer } from 'mobx-react';
import {
    BasicCollapse,
    BasicTable,
    Button,
    IBasicTableColumn,
    Icon,
    IconButton,
    IconName,
    IconTextButton,
    IStepItem,
    Label,
    notification,
    Progress,
    RowLayout,
    Skeleton,
    Space,
    TableListingPage,
    VerticalStepper,
} from 'ui-lib';
import cx from 'classnames';
import {
    useAccountStore,
    useAuthStore,
    useDeploymentGroupsStore,
    useDeploymentStore,
    useDeviceGroupsStore,
    useStores,
} from '../../../../store';
import { TDisplayDeploymentProps } from '../../../../store/deployment-store';
import { useDeploymentStates } from './common';

import { confirmAction } from '../../admin/utils';
import { hasPermission } from '../../utils';
import { getTimezones, SOFTWARE_MANAGEMENT_ROUTES } from '../index';
import { SearchableSelectFormItem } from '../../components/device-groups-select';

import { NOTIFICATION_TYPE } from '../../../../dto/notifications-dto';

import { DEVICE_JOB_EXECUTION_STATUS } from 'dto/remote-commands';
import { DeviceUuidHyperlink } from 'pages/private/components/device-uuid-hyperlink-component';
import {
    DeploymentStatus,
    IProgressStatusProps,
    PROGRESS_STAGE,
    PROGRESS_STATUS,
    TSearchDeviceInfoProgressDetails,
} from 'dto/deployment/deployment-dto';
import {
    EXPORT_TYPES,
    exportData,
    getErrorMessage,
    getMovingToDeviceGroupMessage,
    isNullOrEmpty,
    MAX_TIMES_TO_RE_FETCH_DATA,
    TIME_BEFORE_RE_FETCH_IN_SECONDS
} from 'common-utils';
import { dateCompare, formatDate } from 'common-utils/date-utils';
import { IDeploymentTargetDeviceGroupOptions } from 'dto/deployment/target-dto';
import { useLocation } from 'react-router-dom';

import deploymentCss from './deployments.css';

export interface IDeploymentsManagementTableProps {
    navigateToAddNew: () => void;
}

type LocationStateType = { clientAccountId: number; deploymentGroupId?: number; deploymentId?: number; } | undefined;

interface IDeviceGroupDetailsCollapseProps {
    selectedDeployment: TDisplayDeploymentProps;
}

enum PROGRESS_BAR_COLOR {
    RED = '#D61346',
    GREEN = '#52C41A',
}

const PROGRESS_BAR_PROPS_MAPPING: { [key: string]: number } = {
    [PROGRESS_STAGE.QUEUE]: 10,
    [PROGRESS_STAGE.IN_PROGRESS]: 50,
    [PROGRESS_STAGE.DOWNLOADED]: 70,
    [PROGRESS_STAGE.SUCCEEDED]: 100,
    [PROGRESS_STAGE.FAILED]: 100,
};

const isErrorStatus = (status: PROGRESS_STATUS) => {
    return status == PROGRESS_STATUS.ERROR || status == PROGRESS_STATUS.PAUSE;
};

function buildProgressStatus(
    device: TSearchDeviceInfoProgressDetails,
    isCanceledDeployment = false,
): IProgressStatusProps {
    const jobInformation = device.jobInformation;
    if (!jobInformation) return undefined;
    const status = jobInformation.status;
    const canceledStatus = isCanceledDeployment ? PROGRESS_STATUS.ERROR : null;
    switch (status) {
        case DEVICE_JOB_EXECUTION_STATUS.QUEUED: {
            return {
                progressStatus: PROGRESS_STATUS.PAUSE,
                progressStage: PROGRESS_STAGE.QUEUE,
            };
        }
        case DEVICE_JOB_EXECUTION_STATUS.IN_PROGRESS: {
            return {
                progressStatus:
                    canceledStatus || (device.connectivity?.connected ? PROGRESS_STATUS.PLAY : PROGRESS_STATUS.PAUSE),
                progressStage: PROGRESS_STAGE.IN_PROGRESS,
            };
        }
        case DEVICE_JOB_EXECUTION_STATUS.SUCCEEDED: {
            if (jobInformation.status === 'SUCCEEDED') {
                return {
                    progressStatus: PROGRESS_STATUS.SUCCESS,
                    progressStage: PROGRESS_STAGE.SUCCEEDED,
                };
            }

            return {
                progressStatus: canceledStatus || (device.connectivity?.connected ? PROGRESS_STATUS.PLAY : PROGRESS_STATUS.PAUSE),
                progressStage: PROGRESS_STAGE.DOWNLOADED,
            };
        }
        default: {
            return {
                progressStatus: PROGRESS_STATUS.ERROR,
                progressStage: PROGRESS_STAGE.FAILED,
            };
        }
    }
}

const ICON_MAPPING: { [key: string]: IconName } = {
    [PROGRESS_STATUS.ERROR]: 'xmark-circle',
    [PROGRESS_STATUS.PAUSE]: 'pause-circle',
    [PROGRESS_STATUS.PLAY]: 'play-circle',
    [PROGRESS_STATUS.SUCCESS]: 'check-circle',
};

export const DeploymentsManagementTable = observer((props: IDeploymentsManagementTableProps) => {
    const location = useLocation();
    const locationState = location.state as LocationStateType;
    const stores = useStores();
    const accountStore = useAccountStore();
    const { assureApiAccount, currentUser } = useAuthStore();
    const deploymentGroupStore = useDeploymentGroupsStore();
    const deploymentStore = useDeploymentStore();

    const { onGroupChange, loadDeployments, deployments, onFilter, selectedGroupId } = useDeploymentStates();
    const currentAccount = React.useMemo(() => accountStore.getAccountById(currentUser.accountId), [currentUser]);

    const hasModifyPermission = hasPermission(SOFTWARE_MANAGEMENT_ROUTES.DEPLOYMENTS_MODIFY, stores);

    const [downloading, setDownloading] = React.useState(false);

    // Needed to load data for opened by default tab
    React.useEffect(() => {
        if (locationState?.deploymentGroupId) {
            onGroupChange(locationState.deploymentGroupId);
        }
    }, [locationState]);

    const onDelete = async (id: number) => {
        try {
            await deploymentStore.deleteDeploymentById(assureApiAccount.accountId, id);
            notification.success({
                message: 'Selected deployment has been deleted',
                placement: 'topRight',
            });
            await stores.notificationsStore.create({
                content: {
                    title: 'Deployments',
                    text: 'Selected deployment has been deleted',
                    messageType: 'Deployment',
                },
                type: NOTIFICATION_TYPE.PERSONAL,
                receiverUserUuid: currentUser.uuid,
                sender: currentUser.userName,
            });
        } catch (err) {
            notification.error({
                message: getErrorMessage(err),
                placement: 'topRight',
            });
        }
    };

    const onCancelExecutionSchedule = async (payload: any) => {
        try {
            await deploymentStore.cancelExecutionSchedule(assureApiAccount.accountId, payload.deploymentId);
            notification['success']({
                message: 'Selected deployment execution has been cancelled',
                placement: 'topRight',
            });
            await stores.notificationsStore.create({
                content: {
                    title: 'Deployments',
                    text: 'Selected deployment execution has been cancelled',
                    messageType: 'Deployment',
                },
                type: NOTIFICATION_TYPE.PERSONAL,
                receiverUserUuid: currentUser.uuid,
                sender: currentUser.userName,
            });
        } catch (err) {
            return notification.error({
                message: getErrorMessage(err),
                placement: 'topRight',
            });
        }
    };

    const onExportDeploymentProcess = async (record: TDisplayDeploymentProps): Promise<void> => {
        try {
            setDownloading(true);
            const deploymentGroup = deploymentGroupStore.displayDeploymentGroups.find(group => group.id === record.deploymentGroupId);
            const deploymentProgressDetails = await deploymentStore.getDeploymentProgressDetails(
                accountStore.getAccountByUuid(deploymentGroup?.accountUuid)?.id || assureApiAccount.accountId,
                record.id,
            );
            exportData(
                `${record.label}.csv`,
                EXPORT_TYPES.CSV,
                deploymentProgressDetails.deploymentTargets.flatMap(target => {
                    return target.deviceGroups.flatMap(group => {
                        return group.devices.map(device => ({
                            uid: device.deviceName,
                            serialNumber: device.serialNumber,
                            esn: device.esn,
                            firmwareVersion: device.firmwareVersion,
                            softwareUpdateStatus: device.jobInformation?.status,
                            deviceGroup: device.deviceGroupLabel,
                            deploymentGroup: deploymentGroup?.label,
                            deploymentName: deploymentProgressDetails.label,
                        }));
                    });
                }),
                {
                    uid: 'UID',
                    serialNumber: 'Serial number',
                    esn: 'eSN',
                    firmwareVersion: 'Firmware version',
                    softwareUpdateStatus: 'Status',
                    deviceGroup: 'Device group',
                    deploymentGroup: 'Deployment group',
                    deploymentName: 'Deployment name',
                },
            );
        } catch (err) {
            return notification.error({
                message: getErrorMessage(err),
                placement: 'topRight',
            });
        } finally {
            setDownloading(false);
        }
    };

    const columns: IBasicTableColumn[] = [
        {
            code: 'label',
            title: 'Deployment name',
            dataIndex: 'label',
            sorter: (a, b) => a.label.localeCompare(b.label),
        },
        {
            code: 'status',
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
            code: 'createdAtFormatted',
            title: 'Created date',
            dataIndex: 'createdAtFormatted',
            sorter: (a, b) => a.createdAtTimestamp?.localeCompare(b.createdAtTimestamp),
        },
        {
            code: 'scheduleDateTimeFormatted',
            title: 'Scheduled date',
            dataIndex: 'scheduleDateTimeFormatted',
            sorter: (a, b) => a.scheduleDateTimestamp?.localeCompare(b.scheduleDateTimestamp),
        },
        {
            code: 'action',
            title: 'Action',
            dataIndex: 'action',
            render: (_, record: TDisplayDeploymentProps): JSX.Element => {
                return !hasModifyPermission ? null : (
                    <Space>
                        {['SCHEDULED', 'IN_PROGRESS'].includes(record.status) ? (
                            <Button
                                type="link"
                                htmlType="button"
                                disabled={record.accountUuid !== currentAccount.uuid}
                                onClick={(e) =>
                                    confirmAction(
                                        { status: record.status, deploymentId: record.id },
                                        `${e.target['outerText']}`,
                                        `Do you want to ${e.target['outerText']} this deployment task?`,
                                        onCancelExecutionSchedule,
                                    )
                                }
                            >
                                Cancel
                            </Button>
                        ) : null}
                        {record.status === 'IN_PROGRESS' ? (
                            <IconTextButton
                                key="exportInProgressDeployments"
                                label="Export to CSV"
                                type="primary"
                                onClick={(): Promise<void> => onExportDeploymentProcess(record)}
                                loading={downloading}
                            />
                        ) : null}
                        {['SCHEDULED', 'IN_PROGRESS', 'CANCELLED'].includes(record.status) ? null : (
                            <Button
                                type="link"
                                htmlType="button"
                                disabled={record.accountUuid !== currentAccount.uuid}
                                danger
                                onClick={() =>
                                    confirmAction(
                                        record.id,
                                        'Delete deployment',
                                        'Do you want to delete this deployment task?',
                                        onDelete,
                                    )
                                }
                            >
                                Delete
                            </Button>
                        )}
                    </Space>
                );
            },
        },
    ];

    return (
        <div className={deploymentCss.tabChild}>
            <Skeleton
                loading={deploymentGroupStore.dataLoading}
                noOfRows={1}
            >
                <SearchableSelectFormItem
                    code="deploymentGroupsItem"
                    label="Deployment groups"
                    labelInValue={true}
                    placeholder="Select a deployment group"
                    dataSource={deploymentGroupStore.displayDeploymentGroups}
                    onChange={(item) => {
                        onGroupChange(item.value);
                    }}
                    selectedValue={selectedGroupId}
                />
            </Skeleton>

            <TableListingPage
                title={''}
                tableProps={{
                    title: 'Deployments',
                    loadingState: deploymentStore.dataLoading,
                    table: {
                        columns: columns,
                        dataSource: deployments,
                        expandable: {
                            expandedRowClassName: () => deploymentCss.deploymentDetailCollapse,
                            expandedRowRender: (row: TDisplayDeploymentProps) => (
                                <DeviceGroupDetailsCollapse selectedDeployment={row}/>
                            ),
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <Icon iconName={'angle-down'} size={'LG'} onClick={(e) => onExpand(record, e)}/>
                                ) : (
                                    <Icon iconName={'angle-right'} size={'LG'} onClick={(e) => onExpand(record, e)}/>
                                ),
                            defaultExpandedRowKeys: locationState?.deploymentId ? [locationState.deploymentId] : [],
                        },
                        extraOptions: {
                            pagination: { hideOnSinglePage: true },
                        },
                    },
                    toolbar: {
                        onSearch: onFilter,
                        searchPlaceholder: 'Deployment name',
                        onReload: () => {
                            loadDeployments(true);
                        },
                        onAdd: hasModifyPermission ? props.navigateToAddNew : null,
                    },
                }}
            />
        </div>
    );
});

const DeviceGroupDetailsCollapse = (props: IDeviceGroupDetailsCollapseProps): JSX.Element => {
    const { selectedDeployment } = props;
    const { assureApiAccount } = useAuthStore();
    const deploymentStore = useDeploymentStore();
    const accountStore = useAccountStore();
    const deviceGroupStore = useDeviceGroupsStore();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [deviceGroupDetails, setDeviceGroupDetails] = React.useState<IDeploymentTargetDeviceGroupOptions[]>([]);
    const [expandedKeys, setExpanedKeys] = React.useState<string[]>(
        selectedDeployment ? [String(selectedDeployment.id)] : []
    );

    const [reFetchAfterTimeoutCount, setReFetchAfterTimeoutCount] = React.useState(1);

    const buildDeviceTable = (title: string, accountId: number, dataSource: any): JSX.Element => {
        return (
            <BasicTable
                className={deploymentCss.emptyComponent}
                columns={[
                    {
                        code: 'deviceName',
                        title,
                        dataIndex: 'deviceName',
                        width: '60%',
                        render: (uid: string, record: any) => (
                            <DeviceUuidHyperlink uid={uid} accountId={accountId}/>
                        ),
                        sorter: (a: any, b: any) => (
                            a.deviceName < b.deviceName ? 1 : a.deviceName > b.deviceName ? -1 : 0
                        ),
                        textSearchPlaceholder: 'Search',
                        onFilter: (title: string, device: any) => device.deviceName?.toLowerCase().includes(title.toLowerCase()),
                        iconName: 'search',
                    },
                    {
                        code: 'progressStatus',
                        title: 'Progress status',
                        dataIndex: 'progressStatus',
                        render: (progressStatusProps: IProgressStatusProps, record: any) => {
                            if (!progressStatusProps) return null;
                            const status = progressStatusProps.progressStatus;
                            const statusName = ICON_MAPPING[status];
                            const progressStage = progressStatusProps.progressStage;
                            const progressBarPercent = PROGRESS_BAR_PROPS_MAPPING[progressStage];
                            return (
                                <div className={deploymentCss['progress-status-div']}>
                                    {progressBarPercent && (
                                        <Progress
                                            type="line"
                                            percent={progressBarPercent}
                                            extraProps={{
                                                strokeColor:
                                                    status === PROGRESS_STATUS.PLAY ||
                                                    progressStage === PROGRESS_STAGE.SUCCEEDED
                                                        ? PROGRESS_BAR_COLOR.GREEN
                                                        : PROGRESS_BAR_COLOR.RED,
                                                showInfo: progressBarPercent !== 100,
                                            }}
                                        />
                                    )}
                                    {statusName && (
                                        <Icon
                                            iconPrefix="fas"
                                            iconName={statusName}
                                            size="SMALL"
                                            className={cx(
                                                deploymentCss['progress-status-icon-div'],
                                                isErrorStatus(status)
                                                    ? deploymentCss['progress-error-status']
                                                    : deploymentCss['progress-success-status'],
                                            )}
                                        />
                                    )}
                                </div>
                            );
                        },
                        sorter: (prev: any, curr: any) => {
                            const stagePrev = prev.progressStatus?.progressStage;
                            const stageCurr = curr.progressStatus?.progressStage;

                            if (!stageCurr || !stagePrev) {
                                return stageCurr === stagePrev ? 0 : !stageCurr ? -1 : 1;
                            }

                            return stageCurr === PROGRESS_STAGE.SUCCEEDED ? 1
                                : stageCurr === PROGRESS_STAGE.FAILED ? -1
                                    : stagePrev === PROGRESS_STAGE.SUCCEEDED ? -1
                                        : stagePrev === PROGRESS_STAGE.FAILED ? 1
                                            : 0;
                        },
                        filters: [
                            { text: 'Queued/In-Progress', value: PROGRESS_STAGE.IN_PROGRESS },
                            { text: 'Succeeded', value: PROGRESS_STAGE.SUCCEEDED },
                            { text: 'Failed', value: PROGRESS_STAGE.FAILED },
                        ],
                        onFilter: (value: string, record: any) => {
                            const stage = record.progressStatus?.progressStage;
                            // Add PROGRESS_STAGE.SCHEDULED to the array in the below statement when it is added to the enum
                            if (value === PROGRESS_STAGE.IN_PROGRESS && [PROGRESS_STAGE.QUEUE, PROGRESS_STAGE.IN_PROGRESS, PROGRESS_STAGE.DOWNLOADED].includes(stage)) {
                                return true;
                            }
                            return value === stage;
                        },
                    },
                ]}
                dataSource={dataSource.slice().sort((a, b) => {
                    if (a.assignedToGroupAt && b.assignedToGroupAt) {
                        return a.assignedToGroupAt > b.assignedToGroupAt ? -1 : a.assignedToGroupAt < b.assignedToGroupAt ? 1 : 0;
                    }
                    if (!a.assignedToGroupAt && !b.assignedToGroupAt) {
                        return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
                    }
                    return a.assignedToGroupAt ? -1 : b.assignedToGroupAt ? 1 : 0;
                })}
                rowKey="deviceName"
                extraOptions={{
                    pagination: { hideOnSinglePage: true },
                }}
            />
        );
    };

    const fetchData = async (): Promise<void> => {
        try {
            setLoading(true);
            const accountIdOfDeployment =
                accountStore.getAccountByUuid(selectedDeployment.accountUuid)?.id || assureApiAccount.accountId;
            if (!accountIdOfDeployment) {
                return;
            }

            const deploymentProgressDetails = await deploymentStore.getDeploymentProgressDetails(
                accountIdOfDeployment,
                selectedDeployment.id,
            );
            let devicesWithoutAttributesCount = 0;
            const deviceGroupDetails = deploymentProgressDetails.deploymentTargets.flatMap((target) => {
                const targetDeviceGroupDetails = target.deviceGroups.flatMap((deviceGroup) => {
                    deviceGroup.downloadOnly = target.downloadOnly;
                    deviceGroup.installDateTime = target.installDateTime;
                    deviceGroup.scheduleDateTime = target.scheduleDateTime;
                    const devices = deviceGroup.devices;
                    if (isNullOrEmpty(devices))
                        return {
                            ...deviceGroup,
                            numberOfDeployedDevices: 0,
                        };
                    let numberOfDeployedDevices = devices.length;
                    const devicesWithProgress = devices.map((device) => {
                        const deviceWithProgressStatus = {
                            ...device,
                            progressStatus: buildProgressStatus(
                                device,
                                selectedDeployment.status === DeploymentStatus.CANCELLED,
                            ),
                        };

                        const currentJobStatus = device.jobInformation?.status;
                        if (currentJobStatus != DEVICE_JOB_EXECUTION_STATUS.SUCCEEDED) {
                            numberOfDeployedDevices -= 1;
                        }

                        return deviceWithProgressStatus;
                    });

                    deviceGroup.devices = devicesWithProgress;

                    devicesWithoutAttributesCount += deviceGroup.devices.filter((device) => !device.connectivity).length;

                    return {
                        ...deviceGroup,
                        numberOfDeployedDevices,
                    };
                });

                return targetDeviceGroupDetails;
            });

            if (devicesWithoutAttributesCount > 0) {
                if (reFetchAfterTimeoutCount <= MAX_TIMES_TO_RE_FETCH_DATA) {
                    notification.info({
                        message: `${getMovingToDeviceGroupMessage(devicesWithoutAttributesCount)} Waiting...`,
                        placement: 'topRight',
                        duration: TIME_BEFORE_RE_FETCH_IN_SECONDS,
                    });
                    setTimeout(() => {
                        setReFetchAfterTimeoutCount(prev => ++prev);
                    }, TIME_BEFORE_RE_FETCH_IN_SECONDS * 1000);
                } else {
                    notification.error({
                        message: `${getMovingToDeviceGroupMessage(devicesWithoutAttributesCount)} Please try the operation again later`,
                        placement: 'topRight',
                    });
                    setLoading(false);
                }
            } else {
                setDeviceGroupDetails(deviceGroupDetails);
                setLoading(false);
            }
        } catch (error) {
            notification.error({
                message: getErrorMessage(error),
                placement: 'topRight',
            });
            setLoading(false);
        }
    };

    React.useEffect(() => {
        (async () => {
            try {
                await fetchData();
            } catch (error) {
                notification.error({
                    message: getErrorMessage(error),
                    placement: 'topRight',
                });
            }
        })();
    }, [selectedDeployment, reFetchAfterTimeoutCount]);

    const DeviceGroupCollpase = () => {
        return (
            <BasicCollapse
                defaultActiveKey={['deviceGroupPanelHeader']}
                panels={[
                    {
                        header: <span className={deploymentCss.collapseHeader}>Device group</span>,
                        key: 'deviceGroupPanelHeader',
                        content: (
                            <BasicCollapse
                                className={deploymentCss.deviceGroupCollapseContain}
                                defaultActiveKey={expandedKeys}
                                onChange={(key) => {
                                    setExpanedKeys([...key]);
                                }}
                                panels={deviceGroupDetails.map((groupDetail) => {
                                    const onlineDevicesList = [];
                                    const offlineDevicesList = [];
                                    groupDetail.devices?.forEach((item) => {
                                        item.connectivity?.connected
                                            ? onlineDevicesList.push(item)
                                            : offlineDevicesList.push(item);
                                    });
                                    const DeviceGroupHeader = () => {
                                        return (
                                            <div className={deploymentCss.collapseHeader}>
                                                <Icon iconName="object-ungroup" size="SMALL"/>
                                                <div className={deploymentCss.text}>
                                                    <span>{groupDetail.label}</span>
                                                    {groupDetail.devices.length ? (
                                                        <Label
                                                            className={deploymentCss.subText}
                                                            label={
                                                                '(Deployed to ' +
                                                                groupDetail.numberOfDeployedDevices +
                                                                '/' +
                                                                groupDetail.devices.length +
                                                                ' Devices)'
                                                            }
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                        );
                                    };

                                    const DeploymentStatusComponent = (
                                        deviceGroup: IDeploymentTargetDeviceGroupOptions,
                                    ): JSX.Element => {
                                        const timezone = getTimezones(
                                            [deviceGroup.name],
                                            (deviceGroupName: string) =>
                                                deviceGroupStore.getDeviceGroupByName(deviceGroupName)
                                                    ?.deviceGroupTimezone,
                                        );
                                        const displayTimezone = isNullOrEmpty(timezone) ? '' : `(${timezone})`;
                                        const stepItems: IStepItem[] = [];
                                        if (deviceGroup.downloadOnly) {
                                            const scheduleDateTime = deviceGroup.scheduleDateTime;
                                            const isFinish = dateCompare(Date.now(), scheduleDateTime);
                                            stepItems.push({
                                                code: '1',
                                                label: (
                                                    <strong className={deploymentCss.stepHeadder}>
                                                        Downloaded packages
                                                    </strong>
                                                ),
                                                description: (
                                                    <div>
                                                        Download date: {formatDate(scheduleDateTime)} {displayTimezone}
                                                    </div>
                                                ),
                                                status: isFinish ? 'finish' : 'wait',
                                                className: isFinish ? deploymentCss.stepsItem : undefined,
                                            });
                                        }

                                        const installDateTime = deviceGroup.downloadOnly
                                            ? deviceGroup.installDateTime
                                            : deviceGroup.scheduleDateTime;
                                        const isFinish = dateCompare(Date.now(), installDateTime);
                                        stepItems.push({
                                            code: deviceGroup.downloadOnly ? '2' : '1',
                                            label: (
                                                <strong className={deploymentCss.stepHeadder}>Install packages</strong>
                                            ),
                                            description: (
                                                <div>
                                                    Installation date: {formatDate(installDateTime)} {displayTimezone}
                                                </div>
                                            ),
                                            status: isFinish ? 'finish' : 'wait',
                                            className: isFinish ? deploymentCss.stepsItem : undefined,
                                        });

                                        return isNullOrEmpty(stepItems) ? null : <VerticalStepper items={stepItems}/>;
                                    };

                                    return {
                                        key: groupDetail.name,
                                        header: <DeviceGroupHeader/>,
                                        content: (
                                            <div>
                                                <DeploymentStatusComponent {...groupDetail} />
                                                <RowLayout
                                                    childItems={[
                                                        buildDeviceTable(
                                                            'Online',
                                                            groupDetail.accountId,
                                                            onlineDevicesList,
                                                        ),
                                                        buildDeviceTable(
                                                            'Offline',
                                                            groupDetail.accountId,
                                                            offlineDevicesList,
                                                        ),
                                                    ]}
                                                    childCols={[12, 12]}
                                                    gutter={12}
                                                />
                                            </div>
                                        ),
                                    };
                                })}
                            />
                        ),
                        extra: <IconButton
                            key="reloadItems"
                            className={deploymentCss['reload-device-group-btn']}
                            iconName="arrow-rotate-right"
                            onClick={fetchData}
                        />,
                    },
                ]}
            />
        );
    };

    return (
        <Skeleton loading={loading} noOfRows={1}>
            <DeviceGroupCollpase/>
        </Skeleton>
    );
};
