import React from 'react';
import { useAuthStore, useDeviceStore, useStores } from '../../../../store';
import {
    TextFieldItem,
    CheckboxGroupItem,
    SimpleFileUploadItem,
    ISimpleFileUploadPropsItemProps,
    ISimpleFileUploadProps,
    BasicForm,
    useForm,
    IconTextButton,
    notification,
    Spinner,
    ITreeSelectProps,
} from 'ui-lib';

import { getErrorMessage } from 'common-utils';
import { DeviceGroupSelectItem, DeviceIdentifierItem } from '../../components/device-groups-select';
import { OrganisationSelectFormItem } from '../../components/organisation-component';
import { confirmAction } from '../../admin/utils';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import fileTransferCss from './file-transfer.css';
import { useDeviceGroupCommonStates } from '../../devices/device-groups';
import { DeviceUuidHyperlink } from 'pages/private/components/device-uuid-hyperlink-component';
import { DeviceGroupHyperlink } from 'pages/private/components/device-group-hyperlink-component';

interface IFileTransferStateProps {
    uid?: string;
    deviceGroupName?: string;
}

export const FileTransfer = observer(() => {
    const { currentUser } = useAuthStore();
    const devicesStore = useDeviceStore();
    const stores = useStores();
    const [form] = useForm();
    
    const {
        treeSelectableDataSource: deviceGroupsTree,
        deviceGroupLoading,
        onAccountChanged,
    } = useDeviceGroupCommonStates();

    const state: Partial<IFileTransferStateProps> = useLocation().state || {};
    const [selectedDeviceGroup, setSelectedDeviceGroup] = React.useState(state.deviceGroupName || '');
    const [selectedDevice, setSelectedDevice] = React.useState(state.uid || '');
    const [destination, setFolderPath] = React.useState('');
    const permissionsOptions = [
        { value: 1, label: 'Execute' },
        { value: 2, label: 'Write' },
        { value: 4, label: 'Read' },
    ];
    const [checkedList, setCheckedList] = React.useState<CheckboxValueType[]>([4]);
    const [fileList, setFileList] = React.useState([]);
    const [hasFileError, setHasFileError] = React.useState(false);
    const [deviceUidToLink, setDeviceUidToLink] = React.useState('');
    const [deviceGroupToLink, setDeviceGroupToLink] = React.useState('');
    const TARGET_REQUIRED = 'Must input data for device or device group';
    const MAX_SIZE_FILE_TRANSFER = 22; // Kilobytes

    React.useEffect(() => {
        if (!stores.commonStore.selectedOrganisation) return;
        onAccountChanged(stores.commonStore.selectedOrganisation);
    }, [stores.commonStore.selectedOrganisation]);

    const deviceGroupsSelectProps: ITreeSelectProps = {
        dataSource: deviceGroupsTree,
        expandAll: true,
        allowClear: true,
        onChange: (value) => {
            clearProcessedData();
            setSelectedDeviceGroup(value || '');
        },
    };

    const clearProcessedData = () => {
        setDeviceUidToLink('');
        setDeviceGroupToLink('');
    };

    const targetValidator = (rule, value, callback) =>
        callback(selectedDevice || selectedDeviceGroup ? undefined : TARGET_REQUIRED);

    const _notificationInfos = (nodes: React.ReactNode[]) => {
        if (nodes.length == 0) return;

        notification['info']({
            message: <div>{...nodes}</div>,
            placement: 'topRight',
            duration: 10,
        });
    };

    const transferFile = async () => {
        try {
            const permissionsCode = checkedList.reduce((p: number, c) => p + Number(c), 0);
            const permissionsCodeLinux = `${permissionsCode}${permissionsCode}${permissionsCode}`;
            const result = await devicesStore.dataTransfer(
                stores.commonStore.selectedOrganisation,
                selectedDevice,
                selectedDeviceGroup,
                destination,
                permissionsCodeLinux,
                fileList,
            );

            setFileList([]);
            const successMessages = result.success?.map((item) => {
                return item ? (
                    <p>
                        {'Success to transfer file: '}
                        <strong>{`${item.fileName}`}</strong>.
                    </p>
                ) : null;
            });

            const errorMessages = result.error?.map((item) => {
                return item ? (
                    <p>
                        {'Failed to transfer file: '}
                        <strong>{`${item.fileName}`}</strong>.
                    </p>
                ) : null;
            });

            if (selectedDevice) {
                setDeviceUidToLink(result.deviceUids[0]);
            } else if(selectedDeviceGroup) {
                setDeviceGroupToLink(selectedDeviceGroup);
            }

            _notificationInfos(successMessages);
            _notificationInfos(errorMessages);
        } catch (err) {
            notification['error']({
                message: getErrorMessage(err),
                placement: 'topRight',
            });
        }
    };

    const handleTransfer = () => {
        if (selectedDevice && selectedDeviceGroup) {
            return notification['error']({
                message: 'Can not use the device field and device group field at the same time',
                placement: 'topRight',
                duration: 10,
            });
        }
        confirmAction(undefined, 'Transfer File', 'Do you want to transfer this file?', transferFile);
    };

    const fileUploadProps: ISimpleFileUploadProps = {
        fileList: fileList,
        setFileList: setFileList,
        allowedFileTypes: ['text/plain', '.conf', '.css', '.xml', '.json', '.jpg', '.png','.list'],
        limitFileSize: MAX_SIZE_FILE_TRANSFER,
        setFileError: setHasFileError,
        multiple: true,
        button: {
            label: 'Select files',
            type: 'primary',
        },
    };

    const simpleFileUploadPropsItemProps: ISimpleFileUploadPropsItemProps = {
        simpleFileUploadProps: fileUploadProps,
        code: 'simpleFileUpload',
    };

    const DeviceGroupField = observer(() => {
        return (
            <DeviceGroupSelectItem
                code='deviceGroup'
                label='Device group:'
                labelAlign='right'
                validator={targetValidator}
                initialValue={selectedDeviceGroup}
                selectProps={deviceGroupsSelectProps}
            />
        );
    });

    return (
        <Spinner spinning={deviceGroupLoading} className={fileTransferCss.spinner}>
            <BasicForm
                form={form}
                className={fileTransferCss['transfer-form']}
                onSubmit={handleTransfer}
                items={[
                    <OrganisationSelectFormItem
                        key='organisation'
                        code='organisation'
                        isRequired={true}
                        labelAlign='right'
                        accountType={currentUser.accountType}
                        selectedValue={stores.commonStore.selectedOrganisation}
                        onChange={(value) => {
                            setSelectedDeviceGroup('');
                            form.setFieldsValue({ ['deviceGroup']: '' });
                            clearProcessedData();
                            stores.commonStore.setSelectedOrganisation(value);
                        }}
                    />,

                    <DeviceIdentifierItem
                        key='deviec-identifier-item'
                        initialValue={selectedDevice}
                        label='Device:'
                        labelAlign='right'
                        onChange={(value) => {
                            clearProcessedData();
                            setSelectedDevice(value.target.value || '');
                        }}
                    />,
                    <DeviceGroupField key='device-group' />,

                    <TextFieldItem
                        initialValue={destination}
                        key='folderPath'
                        code='folderPath'
                        label='Folder path:'
                        isRequired={true}
                        labelAlign='right'
                        onChange={(value) => setFolderPath(value.target.value)}
                    />,

                    <CheckboxGroupItem
                        labelAlign='right'
                        key='permissions'
                        code='permissions'
                        defaultValue={checkedList}
                        label='Permissions:'
                        options={permissionsOptions}
                        value={checkedList}
                        onChange={(value) => setCheckedList(value)}
                    />,

                    <SimpleFileUploadItem
                        key='fileUpload'
                        simpleFileUploadProps={simpleFileUploadPropsItemProps.simpleFileUploadProps}
                        code='fileUpload'
                        label=' '
                    />,

                    <IconTextButton
                        key='transfer-submit-button'
                        className={fileTransferCss.button}
                        htmlType='submit'
                        label='Transfer'
                        disabled={!fileList.length || hasFileError}
                    />,

                    deviceUidToLink && (
                        <DeviceUuidHyperlink
                            uid={deviceUidToLink}
                            type='primary'
                            label='Go to Device Details'
                            accountId={stores.commonStore.selectedOrganisation}
                        />
                    ),
                    deviceGroupToLink && (
                        <DeviceGroupHyperlink deviceGroupName={deviceGroupToLink} />
                    ),
                ]}
            />
        </Spinner>
    );
});
