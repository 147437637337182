import { computed, makeObservable, override } from 'mobx';
import { DeploymentStatus, IDeploymentOptions, IDeploymentProgressDetails, ISaveDeploymentProps } from '../dto/deployment/deployment-dto';
import { formatDate } from 'common-utils/date-utils';
import { AssureBaseStore, IAssureStoreConstructorOptions } from './assure-base.store';
import { DeploymentApiClient } from '../api';
import { getErrorMessage } from 'common-utils';
import { minBy } from 'lodash';
import { DeploymentDetails } from 'dto/deployment/deployment-details.dto';

export type TDisplayDeploymentProps = IDeploymentOptions & {
    scheduleDateTimeFormatted: string;
    scheduleDateTimestamp: string;
    createdAtFormatted: string;
    createdAtTimestamp: string;
};

export class DeploymentStore extends AssureBaseStore<DeploymentApiClient, IDeploymentOptions> {
    constructor(options: IAssureStoreConstructorOptions) {
        super(options);
        makeObservable(this);
    }

    protected get apiClient(): DeploymentApiClient {
        return this.apiClientStore.apiClients.deployment;
    }

    private readonly groupWithDeployments: Map<number, IDeploymentOptions[]> = new Map();

    @override
    public clearEntities(): void {
        super.clearEntities();
        this.groupWithDeployments.clear(); // Need to clear the map when the entities have clean up
    }

    public async loadDeploymentsByGroupId(accountId: number, groupId: number, force?: boolean): Promise<void> {
        if (!force && this.groupWithDeployments.get(groupId)) return;
        try {
            this.setDataLoading(true);
            this.groupWithDeployments.delete(groupId);
            const loadedDeployments = await this.apiClient.getDeployments(accountId, groupId, {
                units: '1',
                targets: '1',
            });
            this.groupWithDeployments.set(groupId, loadedDeployments);
            const withoutLoadedDeployments = this.entities.filter((item) => item.deploymentGroupId != groupId);
            this.setEntities([...withoutLoadedDeployments, ...loadedDeployments]);
        } catch (err) {
            this.setError(getErrorMessage(err));
        } finally {
            this.setDataLoading(false);
        }
    }

    public findDeploymentById(deploymentId: number): IDeploymentOptions {
        return this.entities.find((deployment) => deployment.id == deploymentId);
    }

    public async getDeploymentById(accountId: number, deploymentId: number, fromServer = false): Promise<IDeploymentOptions> {
        const deployment = this.entities.find(deployment => deployment.id == deploymentId);
        if (deployment) return deployment;
        if (fromServer) return this.apiClient.getDeploymentById(accountId, { id: deploymentId, units: '1', targets: '1' });
    }

    public getDeploymentsByGroupId(groupId: number): IDeploymentOptions[] {
        return this.entities.filter((deployment) => deployment.deploymentGroupId === groupId);
    }

    public async findLatestInProgressDeploymentInGroup(accountId: number, groupId: number, force?: boolean): Promise<IDeploymentOptions> {
        await this.loadDeploymentsByGroupId(accountId, groupId, force);
        return this.groupWithDeployments.get(groupId)?.filter((deployment) => deployment.status === DeploymentStatus.IN_PROGRESS).at(-1);
    }

    @computed
    public get displayDeployments(): TDisplayDeploymentProps[] {
        if (!this.hasEntities()) return [];
        return this.entities
            .map((deployment) => {
                const firstTarget = minBy(deployment.deploymentTargets, 'scheduleDateTime');
                return {
                    ...deployment,
                    key: deployment.id,
                    scheduleDateTimeFormatted: formatDate(firstTarget?.scheduleDateTime), // for display
                    scheduleDateTimestamp: firstTarget?.scheduleDateTime, // for sorting
                    createdAtFormatted: formatDate(deployment.createdAt), // for display
                    createdAtTimestamp: deployment.createdAt, // for sorting
                };
            })
            .sort((a, b) => new Date(b.createdAtTimestamp).getTime() - new Date(a.createdAtTimestamp).getTime()); // display latest deployment on top of list
    }

    public async cancelExecutionSchedule(accountId: number, deploymentId: number): Promise<boolean> {
        const status = await this.apiClient.cancelExecutionSchedule(accountId, deploymentId);
        this.updateEntity<Pick<TDisplayDeploymentProps, 'status'>>(deploymentId, { status });
        return true;
    }

    public async createDeployment(accountId: number, deployment: ISaveDeploymentProps): Promise<IDeploymentOptions> {
        const result = await this.apiClient.createDeployment(accountId, deployment);
        // Get new deployment from server
        const addedDeployment = await this.apiClient.getDeploymentById(accountId, {
            id: result.id,
            units: '1',
            targets: '1',
        });
        this.addEntity(addedDeployment);
        return addedDeployment;
    }

    public async deleteDeploymentById(accountId: number, id: number): Promise<void> {
        await this.apiClient.deleteDeploymentById(accountId, id);
        this.removeEntity(id);
    }

    public getDeploymentProgressDetails(accountId: number, id: number): Promise<IDeploymentProgressDetails> {
        return this.apiClient.getDeploymentProgressDetails(accountId, id);
    }

    public async getDeploymentDetails(accountId: number, id: number): Promise<DeploymentDetails> {
        const result = await this.apiClient.getDeploymentDetails(accountId, id);
        return new DeploymentDetails(result);
    }
}
