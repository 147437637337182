import { BaseApiClient, IApiClientOptions, IRequestProps } from 'core';
import { isSocketHangUpError, MAX_RE_FETCHES } from '../utils/api-utils';
import { SocketHangUpError } from '../errors/socket-hang-up';

export interface IResponseOptions {
    status: 'error' | 'success';
    data: any;
}

export enum HTTP_METHOD {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
    PATCH = 'patch',
}

export abstract class AssureBaseApiClient extends BaseApiClient {
    constructor(clientName: string, options: IApiClientOptions) {
        super(clientName, options);
    }

    public async deleteEntity<T>(params?: any): Promise<T> {
        return this.send<T>(HTTP_METHOD.DELETE, this.getRequestOptions('delete', params));
    }

    protected abstract getRootPath(): string;

    protected getRequestOptions(subPathName?: string, params?: any): IRequestProps {
        return {
            url: this._getRequestPath(subPathName),
            params,
            options: {
                propagateError: true,
            },
        };
    }

    protected getRequestOptionsWithAccountId(accountId: number, subPathName?: string, params?: any): IRequestProps {
        return {
            url: `/account/${accountId}/${this._getRequestPath(subPathName)}`,
            params,
            options: {
                propagateError: true,
            },
        };
    }

    protected send<T>(httpMethod: HTTP_METHOD, requestProps: IRequestProps): Promise<T> {
        return this._responseResult(this[httpMethod], requestProps);
    }

    protected async getAllEntities<T>(params?: any): Promise<T> {
        return this.send<T>(HTTP_METHOD.GET, this.getRequestOptions('', params));
    }

    private _getRequestPath(subPathName?: string): string {
        const rootPath = this.getRootPath();
        if (!subPathName || subPathName.length == 0) return rootPath;
        return `${rootPath}/${subPathName}`;
    }

    private async _responseResult<T>(
        httpMethodFn: (request: IRequestProps) => Promise<IResponseOptions>,
        request: IRequestProps,
        reFetches = 0,
    ): Promise<T> {
        const response = await httpMethodFn(request);
        if (response.status === 'error') {
            if (isSocketHangUpError(response)) {
                if (reFetches < MAX_RE_FETCHES) {
                    return this._responseResult(httpMethodFn, request, reFetches + 1);
                }
                throw new SocketHangUpError(response.data);
            }
            throw Error(response.data);
        }
        return response.data;
    }
}
