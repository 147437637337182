import * as React from 'react';
import {BaseError, IBaseErrorProps} from './base-error';

export type I400Props = Omit<IBaseErrorProps, 'iconName' | 'title'>

export const Error400 = (props: I400Props) => (
    <BaseError
        iconName="otter"
        title="400 - Bad Request"
        message={props.message}
    />
);