import React from 'react';
import { SelectFormItem, IFormItem } from 'ui-lib';
import { AccountType } from '../../../dto/access-management/account-dto';
import { useStores } from '../../../store';
export interface IOrganisationSelectFormItemProps extends IFormItem {
    accountType: string;
    placeholder?: string;
    selectedValue?: number;
    onChange?: (value: any) => void;
}
export const OrganisationSelectFormItem = (props: IOrganisationSelectFormItemProps) => {
    const stores = useStores();
    return (
        props.accountType === AccountType.DPU && (
            <SelectFormItem
                {...props}
                placeholder={props.placeholder || 'Organisation'}
                label={props.label || 'Organisation'}
                dataSource={stores.commonStore.organisations}
                selectedValue={stores.commonStore.selectedOrganisation}
            />
        )
    );
};
