import React from 'react';
import { observer } from 'mobx-react';
import { HorizontalStepper, IStepItem, message } from 'ui-lib';
import { CreateOrSelectCustomerPartnerGroup } from './customer-partner-group-table';
import { SelectDevicesWrapper } from './devices-table';
import { SelectDeviceGroupsWrapper } from './device-groups-table';
import { IItemOptions, WizardStore } from '../../../../store/wizard-store';
import { useNavigate } from 'react-router-dom';
import { useRoutesStore } from '../../../../store';
import { DEVICES_ROUTE_CODES } from '../';

interface IWizardBodyOptions {
    store: WizardStore;
    updateHeaderComponent: (disabledHeader: boolean, hiddenCustomerComponent: boolean) => void;
    onFinish: () => void;
}

export const CommissioningWizardWrapper = observer((props: IWizardBodyOptions) => {
    const wizard = props.store as WizardStore;
    const navigate = useNavigate();
    const routeStore = useRoutesStore();
    const deviceInventoryRoute = routeStore.getRoute(DEVICES_ROUTE_CODES.INVENTORY);
    const [stepCodeState, setStepCodeState] = React.useState('1');

    const [customerPartnerTableState, setCustomerPartnerTableState] = React.useState({
        loading: true,
        dataSource: wizard.displayVarClientAccountGroups,
    });

    const [deviceGroupsTableState, setDeviceGroupsTableState] = React.useState({
        loading: true,
        dataSource: wizard.deviceGroup,
    });

    const [devicesTableState, setDevicesTableState] = React.useState({
        loading: true,
        name: undefined,
        dataSource: wizard.provisionedDevices
    });

    React.useEffect(() => props.updateHeaderComponent(stepCodeState != '1', stepCodeState != '3'), [stepCodeState]);

    const loadAccounts = () => {
        setCustomerPartnerTableState({ ...customerPartnerTableState, loading: true });
        setTimeout(() => {
            const dataSource = wizard.getAccountGroupsData();
            setCustomerPartnerTableState({
                dataSource: dataSource,
                loading: false,
            });
        }, 1000);
    };

    React.useEffect(() => {
        if (wizard.displaySelectedAccount == undefined) return;

        loadAccounts();
    }, [wizard.displaySelectedAccount]);

    const loadDevices = async () => {
        setDevicesTableState(previous => ({ ...previous, loading: true }));

        const devices = [];
        try {
            devices.push(...(await wizard.getDevices()));
        } finally {
            setDevicesTableState(previous => ({
                ...previous,
                dataSource: devices,
                loading: false,
            }));
        }
    };

    const selectCustomerPartnerGroupHandler = async (organisation: IItemOptions, customer: IItemOptions) => {
        if (!organisation || !customer) {
            message.error('Error: Var/Client account is not selected.');
            return;
        }

        wizard.setVARAccount(organisation.value);
        wizard.setClientGroup(organisation.value, customer.value);

        setStepCodeState('2');

        setDevicesTableState(previous => ({
            ...previous,
            name: customer.label,
        }));
        await loadDevices();
    };

    const finishHandler = async () => {
        if (wizard.selectedDeviceGroup == undefined) return;

        await wizard.commitCommissioningWizard();
        props.onFinish();
    };

    const loadDeviceGroups = () => {
        setDeviceGroupsTableState({ ...deviceGroupsTableState, loading: true });
        setTimeout(() => {
            (async () => {
                const dataSource = await wizard.getDeviceGroupsData();
                setDeviceGroupsTableState({
                    dataSource: dataSource,
                    loading: false,
                });
            })();
        }, 1000);
    };

    const stepItems: IStepItem[] = [
        {
            code: '1',
            label: 'Create or Select Customer/Partner Group',
            description: 'Assign devices to a Customer/Partner',
            content: (
                <CreateOrSelectCustomerPartnerGroup
                    store={wizard}
                    organisationDisabled={wizard.isVarAccountSelected}
                    table={customerPartnerTableState}
                    onSelectGroup={selectCustomerPartnerGroupHandler}
                    onReload={loadAccounts}
                />
            ),
            disableNextButton: true,
        },
        {
            code: '2',
            label: 'Select Devices',
            description: 'Choose from provisioned devices',
            content: (
                <SelectDevicesWrapper
                    data={{ ...devicesTableState, accountId: wizard.getSelectedVarAccountId(), defaultSelectedRows: wizard.selectedDevices || [] }}
                    onChange={(selectedRows) => wizard.setSelectedDevices(selectedRows)}
                    onReload={loadDevices}
                />
            ),
            beforeBack: () => {
                wizard.setSelectedDevices([]);
            },
            beforeNext: loadDeviceGroups,
            disableNextButton: wizard.selectedDevices.length == 0
        },
        {
            code: '3',
            label: 'Add to a Device Group',
            description: 'Choose an existing group or add a new one',
            content: (
                <SelectDeviceGroupsWrapper
                    data={deviceGroupsTableState}
                    onDeviceGroupChange={(value: string) => wizard.updateSelectedDeviceGroupName(value)}
                    onReload={loadDeviceGroups}
                />
            ),
            beforeBack: () => {
                wizard.updateSelectedDeviceGroupName(undefined);
            },
        },
    ];

    const onStepCodeChange = (previous: IStepItem, current: IStepItem) => {
        if (current == undefined) return;
        setStepCodeState(current.code);
    };

    return (
        <React.Fragment>
            <HorizontalStepper
                items={stepItems}
                onFinish={finishHandler}
                currentStepCode={stepCodeState}
                onStepCodeChange={onStepCodeChange}
                canGoBack={true}
                onCancel={() => navigate(deviceInventoryRoute.path)}
                confirmBack={true}
            />
        </React.Fragment>
    );
});
