import * as React from 'react';
import { Divider as AntdDivider } from 'antd';

export interface IDividerProps {
    key: string;
    className?: string;
    type?: 'horizontal' | 'vertical';
    orientation?: 'left' | 'right' | 'center';
    orientationMargin?: string | number;
    children?: React.ReactNode;
    dashed?: boolean;
    style?: React.CSSProperties;
    plain?: boolean;
}

export const Divider = (props: IDividerProps): JSX.Element => {
    return <AntdDivider {...props} />;
};
