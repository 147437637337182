import React from 'react';
import { IconTextButton } from 'ui-lib';

import { ISignAndCreateConfigurationFileResult } from 'dto/configuration-dto';

import configurationCss from './configuration.css';

export interface IConfigurationSigningFinishedProps {
    signedInfo: ISignAndCreateConfigurationFileResult;
    onClick: () => void;
}

export const ConfigurationSigningFinished = (props: IConfigurationSigningFinishedProps): JSX.Element => {

    const buildValue = (property): JSX.Element => {
        if (property instanceof Array) {
            return (<div>
                  {property.map(item => {
                      return <ul>{<span>{item.label} - {item.version}</span>}</ul>;
                  })}</div>
            );
        } else {
            return (<span>{property}</span>);
        }
    };

    const buildDetails = (title: string, info): JSX.Element => {
        return (
            <div>
                <strong>{title}</strong>
                <ul>
                    {Object.keys(info || {}).map((key) => (
                        <li key={key}>
                            <strong>{key}: </strong>
                            {buildValue(info[key])}
                        </li>
                    ))}
                </ul>
            </div>

        );
    };

    return (
        <div className={configurationCss.formFinished}>
            <div className={configurationCss.message}>{props.signedInfo?.message}</div>
            {buildDetails('Configuration file', props.signedInfo?.debianFile)}
            {buildDetails('Package', props.signedInfo?.package)}

            <IconTextButton
                label='Back to Upload files'
                type='primary'
                onClick={props.onClick}
            />
        </div>
    );
};
