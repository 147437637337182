import { SocketHangUpError } from '../errors/socket-hang-up';

export * from './device-utils';
export * from './export-utils';
export * from './timezone-data';

export type ValueOf<T> = T[keyof T];

export const sleep = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};
export const getErrorMessage = (error: { status: number; message: string } | string): string => {
    const errorMessage = typeof error === 'string' ? error : error.message;
    const errorStatus = typeof error === 'string' ? 400 : error.status;
    if (errorMessage) {
        if (error instanceof SocketHangUpError) {
            return 'Something wrong happened. Please reload the page and try again';
        }
        return errorMessage;
    }
    switch (errorStatus) {
        case 401:
            return 'The session has been expired.';
        case 404:
            return 'Path not found.';
        default:
            return 'Unknown error.';
    }
};
