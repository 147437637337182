import * as React from 'react';
import { FormItem, getDefaultFormItemOptions, IFormItem } from './form-item';
import { Label } from 'ui-lib/components/labels';

export interface ILabelFieldProps extends IFormItem {
    strong?: boolean;
}

export const LabelFieldItem = (props: ILabelFieldProps): JSX.Element => {
    const formItemProps = getDefaultFormItemOptions(props, true);

    return (
        <FormItem
            code={props.code}
            {...formItemProps}
            child={
                <Label
                    className={props.className}
                    code={props.code}
                    label={props.initialValue}
                    strong={props.strong}
                />
            }
        />
    );
};
