import * as React from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';

import {
    IStepItem,
    VerticalStepper,
    DangerAlert,
    Typography,
    Label,
} from 'ui-lib';

import { isNullOrEmpty } from 'common-utils';
import { DATE_TIME_FORMAT, NO_TIMEZONE, convertDateByTZ, formatDate } from 'common-utils/date-utils';
import { useAuthStore, useSnapshotStore } from '../../../../../store';

import { IDeviceGroupOptions } from 'dto/device-group-dto';
import { AccountType } from 'dto/access-management/account-dto';

import deploymentCss from '../deployments.css';
import { TNewDeploymentProps } from '../add';

const Text = Typography.Text;

export interface IReviewAndSaveStepProps {
    newDeployment: TNewDeploymentProps;
    selectedDeviceGroups: IDeviceGroupOptions[];
    allowDPUOverride: boolean;
    allowDownloadOnly: boolean;
    selectedScheduleDate: Date;
    selectedInstallationDate: Date;
    targetDateError?: string[];
    errorMessage: string;
}

export const ReviewAndSaveStep = observer((props: IReviewAndSaveStepProps) => {

    const { currentUser } = useAuthStore();
    const snapshotStore = useSnapshotStore();
    const {
        newDeployment,
        selectedDeviceGroups,
        allowDPUOverride,
        allowDownloadOnly,
        selectedScheduleDate,
        selectedInstallationDate,
        targetDateError,
        errorMessage,
    } = props;

    const deploymentToReview = React.useMemo(() => {
        return {
            label: newDeployment.label,
            releaseLabel: snapshotStore.getSnapshot(newDeployment.releaseId)?.label,
            removalPackages: newDeployment.removalPackages
        };
    }, [newDeployment]);
    const dateByTZ = (date: Date, tz: string): Date => (!tz || tz === NO_TIMEZONE)
        ? dayjs(date).toDate()
        : convertDateByTZ(date, tz);

    const Step4Description = React.useMemo(() => {
        if (!isNullOrEmpty(targetDateError)) {
            return null;
        }

        return (
            <div>
                {selectedDeviceGroups?.map((group: IDeviceGroupOptions, i) => (
                    <div key={`tz_${i}`}>
                        <Label strong={true} label={`${group.deviceGroupLabel} (${group.deviceGroupTimezone || NO_TIMEZONE})`} />
                    </div>
                ))}
                <Label strong={true} label='Settings:' />
                <ul>
                    {currentUser.accountType === AccountType.DPU && (<li key='settings_dpu_override'> DPU Override: <Text code>{`${allowDPUOverride}`}</Text></li>)}
                    <li key='settings_download'> Download only: <Text code>{`${allowDownloadOnly}`}</Text></li>
                    <li key='settings_schedule'> Scheduled date: <Text code>{formatDate(selectedScheduleDate)}</Text></li>
                    {allowDownloadOnly && <li key='settings_install_date'> Installation date:  <Text code>{formatDate(selectedInstallationDate)}</Text></li>}
                </ul>
            </div>
        );
    }, [selectedDeviceGroups, targetDateError, allowDownloadOnly, allowDPUOverride, selectedScheduleDate, selectedInstallationDate]);

    const Step5Description = React.useMemo(() => {
        if (!isNullOrEmpty(targetDateError)) {
            return null;
        }

        return (
            <div>
                {selectedDeviceGroups?.map((group: IDeviceGroupOptions) => (
                    <div key={group.id} >
                        <Label strong={true} label={`${group.deviceGroupLabel} (${group.deviceGroupTimezone || NO_TIMEZONE})`} />
                        <ul>
                            {!allowDownloadOnly &&
                                <li>
                                    The deployment will be executed at <Text code>{formatDate(dateByTZ(selectedScheduleDate, group.deviceGroupTimezone), DATE_TIME_FORMAT.DATE_TIME_WITH_TIMEZONE)}</Text>
                                </li>}
                            {allowDownloadOnly &&
                                <li>
                                    The packages will be downloaded at <Text code>{formatDate(dateByTZ(selectedScheduleDate, group.deviceGroupTimezone), DATE_TIME_FORMAT.DATE_TIME_WITH_TIMEZONE)}</Text>
                                </li>}
                            {allowDownloadOnly &&
                                <li>
                                    The installation will be executed at <Text code>{formatDate(dateByTZ(selectedInstallationDate, group.deviceGroupTimezone), DATE_TIME_FORMAT.DATE_TIME_WITH_TIMEZONE)}</Text>
                                </li>}
                        </ul>
                    </div>
                ))}
            </div>
        );
    }, [selectedDeviceGroups, targetDateError, selectedScheduleDate, selectedInstallationDate]);

    const deploymentSummary: IStepItem[] = [
        {
            code: '1',
            label: 'Deployment name',
            description: deploymentToReview.label,
            status: 'finish',
        },
        {
            code: '2',
            label: 'Release name',
            description: deploymentToReview.releaseLabel,
            status: 'finish',
        },
        {
            code: '3',
            label: 'Remove packages',
            description: (
                <div>
                    <ul>{newDeployment.removalPackages.map((item, index) => <li key={`${item}-${index}`}>{item}</li>)}</ul>
                </div>
            ),
            status: 'finish',
        },
        {
            code: '4',
            label: 'Deployment targets',
            description: Step4Description,
            status: 'finish',
        },
        {
            code: '5',
            label: 'Finish',
            description: Step5Description,
            status: 'wait',
        },
    ];

    return (
        <React.Fragment>
            {errorMessage ? <DangerAlert message={errorMessage} className={deploymentCss.alert} /> : null}
            <VerticalStepper items={deploymentSummary} currentStepCode={'5'} />
        </React.Fragment>
    );
});
