import * as React from 'react';
import cx from 'classnames';
import { Card } from 'antd';
import { Skeleton } from '../loading';
import { BasicTable, IBasicTableProps } from './basic-table';
import { SearchField } from '../search';
import { IconButton, IconTextButton } from '../buttons';

import advanceTableCss from './advance-table.css';

export interface IAdvanceTableToolbarProps {
    searchPlaceholder?: string;
    onSearch?: (searchText: string) => void;
    onAdd?: (obj?: any) => void;
    onReload?: () => void;
    extraItems?: React.ReactNode[];
}

export interface IAdvanceTableProps {
    title: React.ReactNode;
    className?: string;
    toolbar?: IAdvanceTableToolbarProps;
    table: IBasicTableProps;
    loadingState?: boolean;
}

export const buildTableToolbar = (props: IAdvanceTableToolbarProps) => {
    const tableToolbar = [];
    props?.extraItems && tableToolbar.push(props.extraItems);
    props?.onSearch && tableToolbar.push(
        <SearchField
            key="searchItems"
            allowClear={true}
            placeholder={props.searchPlaceholder}
            onSearch={props.onSearch}
        />,
    );
    props?.onAdd && tableToolbar.push(
        <IconTextButton
            key="addItem"
            label="Add New"
            iconName="plus"
            type="primary"
            onClick={props.onAdd}
            className={advanceTableCss.extraSpacing}
        />,
    );
    props?.onReload && tableToolbar.push(
        <IconButton
            key="reloadItems"
            label="Reload"
            iconName="arrow-rotate-right"
            onClick={props.onReload}
            className={advanceTableCss.extraSpacing}
        />,
    );

    return tableToolbar;
};

export function AdvanceTable(props: IAdvanceTableProps) {
    const tableToolbar = buildTableToolbar(props.toolbar);
    return (
        <Card
            className={cx(advanceTableCss.advanceTable, props.className)}
            title={props.title}
            bordered={false}
            extra={tableToolbar.length ? <div className={advanceTableCss.toolbar}>{tableToolbar}</div> : null}
        >
            <Skeleton loading={props.loadingState}>
                <BasicTable {...props.table} />
            </Skeleton>
        </Card>
    );
}
