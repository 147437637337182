import { IApiClientOptions } from 'core';
import {
    IGetNotificationsRequestProps,
    INotification,
    ICreateNotificationRequestProps,
    IUpdateNotificationRequestProps,
} from '../dto/notifications-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';

const notificationsRootPath = 'notifications';

export class NotificationsApiClient extends AssureBaseApiClient {
    protected getRootPath(): string {
        return notificationsRootPath;
    }

    constructor(options: IApiClientOptions) {
        super('notifications', options);
    }

    public async getAllNotificationsByAccountId(
        accountId: number,
        params: IGetNotificationsRequestProps,
    ): Promise<INotification[]> {
        const response = await this.send<{ notifications: INotification[] }>(HTTP_METHOD.GET, this.getRequestOptionsWithAccountId(accountId, '', params));
        return response.notifications;
    }

    public async createNotification(
        accountId: number,
        params: ICreateNotificationRequestProps,
    ): Promise<INotification> {
        const response = await this.send<{ notification: INotification }>(HTTP_METHOD.POST, this.getRequestOptionsWithAccountId(accountId, '', params));
        return response.notification;
    }

    public async updateNotification(
        accountId: number,
        notificationId: number,
        params: { content: IUpdateNotificationRequestProps },
    ): Promise<boolean> {
        const response = await this.send<{ updated: boolean }>(HTTP_METHOD.PUT, this.getRequestOptionsWithAccountId(accountId, `${notificationId}`, params));
        return response.updated;
    }

    public async deleteNotification(accountId: number, notificationId: number): Promise<boolean> {
        const response = await this.send<{ success: boolean }>(HTTP_METHOD.DELETE, this.getRequestOptionsWithAccountId(accountId, `${notificationId}`));
        return response.success;
    }

    public async markReadNotification(accountId: number, notificationId: number): Promise<boolean> {
        const response = await this.send<{ updated: boolean }>(HTTP_METHOD.PUT, this.getRequestOptionsWithAccountId(accountId, `${notificationId}/mark-read`));
        return response.updated;
    }
}
