import * as React from 'react';
import { Input } from 'antd';
import { FormItem, getDefaultFormItemOptions, IFormItem } from './form-item';

export interface IPasswordItemProps extends IFormItem {
    prefixOutline?: React.ReactNode;
    placeHolder?: string;
    visibilityToggle?: boolean;
    onChange?: (value: any) => void;
}

export const PasswordFieldItem = (props: IPasswordItemProps) => {

    const formItemProps = getDefaultFormItemOptions(props);
    const onChange = async (e) => {
        if (props.onChange) await props.onChange(e);
        if (!props.customizeValidate?.status)
            return;

        if (props.customizeValidate?.status != 'success' && props.customizeValidate?.resetValidation) {
            props.customizeValidate.resetValidation();
        }
    };
    return (
        <FormItem code={props.code} {...formItemProps} child={
            <Input.Password autoComplete="new-password" onChange={onChange} className={props.className} placeholder={props.placeHolder} prefix={props.prefixOutline} visibilityToggle={props.visibilityToggle} disabled={props.disabled} />
        } />
    );
};