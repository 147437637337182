import * as React from 'react';
import { Page, ILoginFormProps, LoginForm, IHeaderIconTitleOptions, Icon, Typography } from '../components';

import loginPageCss from './login.css';
import { Space } from 'antd';

const { Title } = Typography;

export interface ILoginHeaderProps extends IHeaderIconTitleOptions {
    description?: string;
}

export interface ILoginPageProps extends ILoginFormProps {
    header?: ILoginHeaderProps;
}

export function LoginPage(props: ILoginPageProps) {
    return (
        <Page className={loginPageCss.loginPage}>
            {props.header && (
                <div className={loginPageCss.header}>
                    <Space size='middle'>
                        <Icon iconName={props.header?.iconName} size='LARGE' />
                        <Title className={loginPageCss.title} level={2}>
                            {props.header?.title}
                        </Title>
                    </Space>
                    {props.header?.description ? (
                        <Space className={loginPageCss.description}>{props.header?.description}</Space>
                    ) : null}
                </div>
            )}
            <LoginForm {...props} />
        </Page>
    );
}
