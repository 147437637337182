import React from 'react';
import wizardCss from './wizard.css';
import { observer } from 'mobx-react';
import { Label, RowLayout, Select, Progress } from 'ui-lib';

import {
    IWizardHeaderProps,
    IWizardOrganisationTreeSelectItemProps,
    IWizardSelectItemProps,
} from './commission-interface';
import { OrganisationSelect } from '../../components/organisation-select';
import { useAccountStore } from '../../../../store';

const WizardSelectItem = (props: IWizardSelectItemProps) => {
    return (
        <div hidden={props.hidden}>
            <Label label={props.label} />
            <br />
            <Select
                className={wizardCss.headerSelectItem}
                onChange={props.onChange}
                code={props.code}
                disabled={props.disabled}
                dataSource={props.dataSource}
                selectedValue={props.selectedValue}
            />
        </div>
    );
};

const WizardTreeSelectItem = (props: IWizardOrganisationTreeSelectItemProps) => {
    return (
        <div hidden={props.hidden}>
            <Label label={props.label} />
            <br />
            <OrganisationSelect
                expandAll={true}
                treeDataSource={props.dataSource}
                disabled={props.disabled}
                onChange={props.onChange}
                value={props.value as number}
            />
        </div>
    );
};

export const HeaderCommissioningWizardWrapper = observer((props: IWizardHeaderProps) => {
    const wizard = props.store;
    const accountStore = useAccountStore();
    const onTopOrganisationChange = (value: number) => wizard.setSelectedAccount(value);

    const [organisationState, setOrganisationState] = React.useState({
        label: 'Organisation',
        dataSource: accountStore.treeDataSource,
        onChange: onTopOrganisationChange,
        value: wizard.displaySelectedAccount?.value,
    });

    React.useEffect(
        () =>
            setOrganisationState({
                ...organisationState,
                dataSource: accountStore.treeDataSource,
                value: wizard.displaySelectedAccount?.value,
            }),
        [wizard.displaySelectedAccount],
    );

    const [varAccountState, setVarAccountState] = React.useState({
        label: 'Organisation',
        code: 'varAccount',
        dataSource: !wizard.displaySelectedVarAccount ? [] : [wizard.displaySelectedVarAccount],
        selectedValue: wizard.displaySelectedVarAccount?.value,
    });

    React.useEffect(
        () =>
            setVarAccountState({
                ...varAccountState,
                dataSource: !wizard.displaySelectedVarAccount ? [] : [wizard.displaySelectedVarAccount],
                selectedValue: wizard.displaySelectedVarAccount?.value,
            }),
        [wizard.displaySelectedVarAccount, props.header.hiddenDPUAccountComponent],
    );

    const [clientAccountState, setClientAccountState] = React.useState({
        label: 'Customer/Partner',
        code: 'clientAccount',
        dataSource: !wizard.displaySelectedClientAccount ? [] : [wizard.displaySelectedClientAccount],
        hidden: props.header.hiddenClientSelectItem,
        selectedValue: wizard.displaySelectedClientAccount?.value,
    });

    React.useEffect(
        () =>
            setClientAccountState({
                ...clientAccountState,
                dataSource: !wizard.displaySelectedClientAccount ? [] : [wizard.displaySelectedClientAccount],
                hidden: props.header.hiddenClientSelectItem,
                selectedValue: wizard.displaySelectedClientAccount?.value,
            }),
        [wizard.displaySelectedClientAccount, props.header.hiddenClientSelectItem],
    );

    const OrganisationSelectItem = () => {
        return (
            <React.Fragment>
                {!props.header.hiddenDPUAccountComponent ? (
                    <WizardTreeSelectItem
                        {...organisationState}
                        dataSource={organisationState.dataSource}
                        disabled={props.header.disabled}
                    />
                ) : (
                    <WizardSelectItem
                        {...varAccountState}
                        dataSource={varAccountState.dataSource}
                        disabled={props.header.disabled}
                    />
                )}
            </React.Fragment>
        );
    };

    const HeaderWizard = observer(() => {
        return (
            <RowLayout
                childItems={[
                    <OrganisationSelectItem />,
                    <WizardSelectItem
                        {...clientAccountState}
                        dataSource={clientAccountState.dataSource}
                        disabled={props.header.disabled}
                    />,
                    <div className={wizardCss.progress}>
                        {wizard.isCommissioning ? (
                            <Progress
                                key='progress'
                                percent={wizard.processPercent}
                                type='circle'
                                extraProps={{ width: 40 }}
                            />
                        ) : null}
                    </div>,
                ]}
                childCols={[6, 6]}
                childOffsets={[0, 1]}
            />
        );
    });
    return <HeaderWizard />;
});
