import { makeAutoObservable } from 'mobx';
import { ICancelRemoteCommandRequest, IGetCommandStatusRequest, IUpdateDeviceAttribute } from '../api';

import { IAccount as IAccountDto } from '../dto/access-management/account-dto';
import {
    DEVICE_STATUS,
    ICryptoReportResult,
    IDataTransferResult,
    IDecommissionDevicesOptions,
    IDecommissionDevicesResponse,
    IDeviceAttributesResult,
    IDevicePackageInformation,
    IDevicePackageOptions,
    IDeviceTimeOptions,
    IExecutionJobSummary,
    IGetDeviceLogsOptions,
    ILogOptions,
    IMSPVersionOptions,
    IProcessHistory,
    IResetDeviceResult,
    IScrFileOptions,
    IScrInfoResult,
    IScrTamperProps,
    ISearchDeviceInfo,
    ISearchDeviceOptions,
    IShellCommandExecuteParams,
    IShellCommandExecutionResponse,
    ITaipanBatteryInfo,
    ITimeReportRequestParams,
    IUpdateDeviceAttributeResponse,
} from '../dto/device-dto';
import {
    ICommandExecutionDetailsResponse,
    IExecuteCommandBodyParameters,
    IExecuteCommandStatusResponse,
    IGetCommandExecutionDetailsRequest,
    IGetCommandQuery,
    IRerunExecutionProps,
    ITriggerCommandResult,
} from '../dto/remote-commands';
import { AssureAdminApiClientStore } from './assure-admin-api-client.store';

export type IAccount = IAccountDto;

export interface ICommissionedDeviceProps {
    key: string;
    UID: string;
    deviceType: string;
    deviceGroupName: string;
    deviceGroupLabel: string;
}

export interface ICertificateOptions {
    status: 'ACTIVE' | string;
    certificatePem: string;
}

export interface ISoftwareUpdateJobSummary extends IExecutionJobSummary {
    jobName?: string;
    deploymentId: number;
}

export interface IDeviceInfoProps {
    id: number;
    deviceName: string;
    deviceTypeName?: string;
    deviceLabel: string;
    serialNumber: string;
    status: DEVICE_STATUS;
    esn: string;
    location: string;
    firmwareVersion: string;
    lastJobSuccessAt: number;
    deviceGroupLabel?: string;
    deviceGroupNames?: string[];
    timezone?: string;
    tamper?: IScrTamperProps;
    deviceInfo?: {
        scrInfo?: {
            format_version?: number;
            hardware?: {
                uid?: string;
                board_revision?: string;
                'PCD-ID'?: string;
                tamper?: any;
                battery_state?: string;
                battery_mV?: number;
                temperature_C?: number;
            };
            software?: any;
            'sr-app'?: any;
            'emvl1-sr'?: any;
            'sr-flashloader'?: any;
            rtc?: any;
        };
    };
}

export interface IDeviceKey {
    name: string;
    type: string;
    status: string;
}

export interface IKeyDistribution {
    status: 'FAILED' | 'SUCCESS';
    keys: IDeviceKey[];
}

export type SERVICE_STATUS = 'RUNNING';

export interface IServiceOptions {
    name: string;
    status: SERVICE_STATUS;
}

export class DevicesStore {
    constructor(private _apiClientStore: AssureAdminApiClientStore) {
        makeAutoObservable(this);
    }

    async getAllDevices(accountId: number, params?: ISearchDeviceOptions & {
        includeProvisionedDevices?: boolean
    }): Promise<{
        provisioned?: ISearchDeviceInfo[];
        commissioned: ISearchDeviceInfo[];
    }> {
        if (!accountId) return { provisioned: [], commissioned: [] };
        const { includeProvisionedDevices, ...rest } = params;
        const [provisioned, commissioned] = await Promise.all([
            includeProvisionedDevices ? this.getProvisionedDevices(accountId, rest) : undefined,
            this.searchDevices(accountId, rest)
        ]);
        return { provisioned, commissioned };
    }

    public async getDerivationIds(accountId: number, accountUuid: string): Promise<{ derivationIds: string[] }> {
        return this._apiClientStore.apiClients.devices.getDerivationIds(accountId, accountUuid);
    }

    public async getProvisionedDevices(accountId: number, params?: ISearchDeviceOptions): Promise<ISearchDeviceInfo[]> {
        return this._apiClientStore.apiClients.devices.getProvisionedDevices(accountId, params);
    }

    getCommissionedDevices(accountId: number, params?: ISearchDeviceOptions): Promise<ISearchDeviceInfo[]> {
        return this._apiClientStore.apiClients.devices.getCommissionedDevices(accountId, params);
    }

    getDevicesTimeReport(accountId: number, params?: ITimeReportRequestParams): Promise<IDeviceTimeOptions> {
        return this._apiClientStore.apiClients.devices.getDevicesTimeReport(accountId, params);
    }

    getDevicesByName(accountId: number, deviceName: string): Promise<IDeviceInfoProps> {
        return this._apiClientStore.apiClients.devices.getDevicesByName(accountId, deviceName);
    }

    revokeDevices(accountId: number, device: ICommissionedDeviceProps): Promise<boolean> {
        return this._apiClientStore.apiClients.devicegroups.revokeDevicesFromGroup(accountId, {
            deviceNames: [device.UID],
            originDeviceGroupName: device.deviceGroupName,
        });
    }

    getKeyInventory(accountId: number, deviceIdentifier: string): Promise<IKeyDistribution> {
        return this._apiClientStore.apiClients.key.getKeyInventory(accountId, deviceIdentifier);
    }

    triggerRemoteCommand(accountId: number, payload: IExecuteCommandBodyParameters): Promise<ITriggerCommandResult> {
        return this._apiClientStore.apiClients.remoteCommand.triggerRemoteCommand(accountId, payload);
    }

    getRemoteCommandStatusByExecutionId(
        accountId: number,
        executionId: string,
        queryParams?: IGetCommandStatusRequest,
    ): Promise<IExecuteCommandStatusResponse> {
        return this._apiClientStore.apiClients.remoteCommand.getCommandStatusByExecutionId(
            accountId,
            executionId,
            queryParams,
        );
    }

    getRemoteCommandExecutionDetailsByExecutionId(
        accountId: number,
        executionId: string,
        queryParams: IGetCommandExecutionDetailsRequest,
    ): Promise<ICommandExecutionDetailsResponse> {
        return this._apiClientStore.apiClients.remoteCommand.getCommandExecutionDetailsByExecutionId(
            accountId,
            executionId,
            queryParams,
        );
    }

    async cancelRemoteCommandByExecutionId(
        accountId: number,
        executionId: string,
        params: ICancelRemoteCommandRequest,
    ): Promise<any> {
        return this._apiClientStore.apiClients.remoteCommand.cancelRemoteCommandByExecutionId(
            accountId,
            executionId,
            params,
        );
    }

    async rerunExecutionCommand(
        accountId: number,
        executionId: string,
        params: IRerunExecutionProps,
    ): Promise<ITriggerCommandResult> {
        return this._apiClientStore.apiClients.remoteCommand.rerunExecutionCommand(accountId, executionId, params);
    }

    updateDeviceAttribute(accountId: number, options: IUpdateDeviceAttribute): Promise<IUpdateDeviceAttributeResponse> {
        return this._apiClientStore.apiClients.devices.updateAttribute(accountId, options);
    }

    async getDeviceJobs(
        accountId: number,
        deviceName: string,
        query?: IGetCommandQuery,
    ): Promise<IExecutionJobSummary[]> {
        return this._apiClientStore.apiClients.devices.getDeviceJobs(accountId, deviceName, query);
    }

    getDeviceSoftwareUpdates(accountId: number, deviceName: string, dateFrom?: string, dateTo?: string): Promise<ISoftwareUpdateJobSummary[]> {
        return this._apiClientStore.apiClients.devices.getDeviceSoftwareUpdates(accountId, deviceName, dateFrom, dateTo);
    }

    async getCurrentPackageListing(accountId: number, deviceName: string): Promise<IDevicePackageOptions[]> {
        const packages = await this._apiClientStore.apiClients.devices.getCurrentPackageListing(accountId, deviceName);
        return Object.keys(packages).flatMap((item) => {
            const packageDetails: IDevicePackageInformation[] = [];
            const itemPackages = packages[item];
            if (Array.isArray(itemPackages)) {
                packageDetails.push(...itemPackages);
            } else {
                packageDetails.push(itemPackages);
            }
            return packageDetails.map((pkg) => ({
                packageName: item,
                version: pkg.version,
                arch: pkg.arch,
                installedAtTimestamp: pkg.installedAtTimestamp,
                installed: pkg['installed'] === undefined ? true : pkg['installed'],
            }));
        });
    }

    getDeviceProcesses(accountId: number, deviceName: string): Promise<IProcessHistory[]> {
        return this._apiClientStore.apiClients.devices.getDeviceProcesses(accountId, deviceName);
    }

    async getCurrentDeviceServiceListing(accountId: number, deviceName: string): Promise<IServiceOptions[]> {
        const services = await this._apiClientStore.apiClients.devices.getCurrentDeviceServices(accountId, deviceName);
        // TODO: remove hard coded mock data return status -
        // this will return from: const status = await devicesStore.getRemoteCommandStatusByExecutionId(accountId, result.data.executionId);
        return services
            .filter((service) => service != 'trampoline')
            .map((service) => ({ name: service, status: 'RUNNING' }));
    }

    getCurrentDeviceScrFiles(accountId: number, deviceName: string): Promise<IScrFileOptions[]> {
        return this._apiClientStore.apiClients.devices.getCurrentDeviceScrFiles(accountId, deviceName);
    }

    getDeviceLogs(accountId: number, deviceName: string, logTypeName: string, options?: IGetDeviceLogsOptions): Promise<ILogOptions[]> {
        return this._apiClientStore.apiClients.devices.getDeviceLogs(accountId, deviceName, logTypeName, options);
    }

    dataTransfer(
        accountId: number,
        deviceName: string,
        deviceGroup: string,
        filePath: string,
        permissions: string,
        files: File[],
    ): Promise<IDataTransferResult> {
        return this._apiClientStore.apiClients.devices.dataTransfer(
            accountId,
            deviceName,
            deviceGroup,
            filePath,
            permissions,
            files,
        );
    }

    getMSPVersion(accountId: number, deviceName: string): Promise<IMSPVersionOptions> {
        return this._apiClientStore.apiClients.devices.getMSPVersion(accountId, deviceName);
    }

    getTaipanBatteryInfo(accountId: number, deviceName: string): Promise<ITaipanBatteryInfo> {
        return this._apiClientStore.apiClients.devices.getTaipanBatteryInfo(accountId, deviceName);
    }

    distributeGPGKeys(accountId: number, deviceName: string): Promise<string> {
        return this._apiClientStore.apiClients.devices.distributeGPGKeys(accountId, deviceName);
    }

    shellCommandExecution(
        accountId: number,
        params: IShellCommandExecuteParams,
    ): Promise<IShellCommandExecutionResponse[]> {
        return this._apiClientStore.apiClients.devices.shellCommandExecution(accountId, params);
    }

    getDeviceCryptoReport(accountId: number, deviceName: string): Promise<ICryptoReportResult> {
        return this._apiClientStore.apiClients.devices.getDeviceCryptoReport(accountId, deviceName);
    }

    decommissionDevices(accountId: number, params: IDecommissionDevicesOptions): Promise<IDecommissionDevicesResponse> {
        return this._apiClientStore.apiClients.devices.decommissionDevices(accountId, params);
    }

    public searchDevices(accountId: number, params: ISearchDeviceOptions): Promise<ISearchDeviceInfo[]> {
        return this._apiClientStore.apiClients.devices.searchDevices(accountId, params);
    }

    async resetDevice(accountId: number, deviceName: string): Promise<IResetDeviceResult> {
        return this._apiClientStore.apiClients.devices.resetDevice(accountId, deviceName);
    }

    getCurrentDeviceScrInfo(accountId: number, deviceName: string): Promise<IScrInfoResult> {
        return this._apiClientStore.apiClients.devices.getCurrentDeviceScrInfo(accountId, deviceName);
    }

    getDeviceAttributes(accountId: number, deviceName: string): Promise<IDeviceAttributesResult> {
        return this._apiClientStore.apiClients.devices.getDeviceAttributes(accountId, deviceName);
    }

    getInstalledFirmwareVersions(accountId: number): Promise<string[]> {
        return this._apiClientStore.apiClients.devices.getInstalledFirmwareVersions(accountId);
    }
}
